import { useCallback, useMemo } from 'react';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { useProductRows } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/hooks/useProductRows';
import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { format, parseISO } from 'date-fns';
import { useAdminRegistryTelemetry } from '@apps/registry/admin/AdminRegistry.telemetry';
import { BurgerMenuProps } from './components/BurgerMenu/BurgerMenu';
import { useGetUniversalBurgerMenuDataQuery } from '@graphql/generated';

export const GLOBAL_NAV_MENU = 'GlobalNavMenu';

export function useUniversalBurgerMenuController() {
  const { provideLogout } = useAuth();
  const telemetry = useAdminRegistryTelemetry();
  const { eventName } = useEventNameMatch();
  const { data } = useGetUniversalBurgerMenuDataQuery({
    variables: {
      eventHandle: eventName || ''
    },
    batchMode: 'fast'
  });

  const eventTimestamp = data?.eventByName?.info.tentativeDate?.timestamp;

  const productRows = useProductRows(data?.eventByName?.pages ?? [], data?.eventByName?.productVerticals ?? {}, data?.eventByName?.id, false);

  const { t2 } = useTranslation('sharedRegistry');
  const tMenuTranslations = t2('registryNavigationMenu');

  const eventDate = useMemo(() => {
    return eventTimestamp ? format(parseISO(eventTimestamp), 'MMMM dd, yyyy') : undefined;
  }, [eventTimestamp]);

  const trackAndProvideLogout = useCallback(() => {
    telemetry.trackRegistryNavMenuItemClicked('accountSignOut');
    provideLogout({});
  }, [telemetry, provideLogout]);

  const items: BurgerMenuProps['items'] = useMemo(() => {
    const ret: BurgerMenuProps['items'] = productRows
      .filter(i => i.isEnabled)
      .map(row => {
        return {
          headerText: row.title,
          items: row.children.flatMap(rowChild => [
            {
              text: rowChild.title,
              icon: rowChild.icon,
              onClick: () => rowChild?.onClick?.(GLOBAL_NAV_MENU),
              newPillText: rowChild.hasNewPill ? tMenuTranslations.newBadge : undefined,
              notificationPill: rowChild.notificationPill,
              isDisabled: rowChild.isDisabled,
              path: rowChild.path,
              pathIsInJoyWeb: rowChild.pathIsInJoyWeb,
              subItems: rowChild.subItems && [
                {
                  headerText: rowChild.title,
                  items: rowChild.subItems?.map(subItem => {
                    return {
                      text: subItem.title,
                      icon: subItem.icon,
                      newPillText: subItem.hasNewPill ? tMenuTranslations.newBadge : undefined,
                      onClick: () => subItem?.onClick?.(GLOBAL_NAV_MENU),
                      isDisabled: subItem.isDisabled,
                      path: subItem.path,
                      pathIsInJoyWeb: rowChild.pathIsInJoyWeb,
                      topDivider: subItem.hasDivider
                    };
                  })
                }
              ]
            }
          ])
        };
      });
    return ret;
  }, [productRows, tMenuTranslations.newBadge]);

  return {
    items,
    trackAndProvideLogout,
    tMenuTranslations,
    eventDate,
    location: data?.eventByName?.info.location,
    photoUrl: data?.eventByName?.photo?.url,
    eventId: data?.eventByName?.id,
    eventHandle: eventName
  };
}
