/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'External Registry',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M13.491 12.886a1.13 1.13 0 0 0-1.131 1.131v2.562a1.13 1.13 0 0 0 1.131 1.131h4.045a1.13 1.13 0 0 0 1.131-1.131v-2.562a1.13 1.13 0 0 0-1.131-1.131h-4.045zm.069 1.2v2.424h3.908v-2.424H13.56zM5.364 2.5h13.244a1.13 1.13 0 0 1 .88.42l2.344 2.902c.292.362.36.974.36 1.416a3.57 3.57 0 0 1-.553 1.914h.004V20.37a1.13 1.13 0 0 1-1.131 1.131H3.774a1.13 1.13 0 0 1-1.131-1.131V9.537a3.57 3.57 0 0 1-.834-2.299c0-.44.064-1.05.353-1.411L4.48 2.925a1.13 1.13 0 0 1 .884-.425zM3.189 6.464L5.397 3.7h3.795l-.838 2.764H3.189zm-.142 1.2a2.39 2.39 0 0 0 2.348 1.96c1.172 0 2.147-.845 2.348-1.96H3.047zm6.603 0a2.39 2.39 0 0 0 2.348 1.96c1.172 0 2.147-.845 2.348-1.96H9.65zm6.608 0a2.39 2.39 0 0 0 2.348 1.96c1.172 0 2.147-.845 2.348-1.96h-4.696zM3.842 10.471V20.3h1.791v-6.283a1.13 1.13 0 0 1 1.131-1.131h2.61a1.13 1.13 0 0 1 1.131 1.131V20.3h9.937v-9.982a3.57 3.57 0 0 1-1.837.506 3.59 3.59 0 0 1-3.304-2.189c-.545 1.287-1.819 2.189-3.304 2.189s-2.756-.9-3.302-2.184c-.546 1.284-1.819 2.184-3.302 2.184a3.58 3.58 0 0 1-1.552-.352zM10.446 3.7h3.111l.838 2.764H9.608l.838-2.764zm10.362 2.764h-5.16L14.811 3.7h3.764l2.233 2.764zM6.834 20.3v-6.214h2.472V20.3H6.834z"
            fill="currentColor"
            stroke="none"
            fillRule="evenodd"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M16.058 16.282a1.31 1.31 0 0 0-1.31 1.31v4.15a1.31 1.31 0 0 0 1.31 1.31h7.535a1.31 1.31 0 0 0 1.31-1.31v-4.15a1.31 1.31 0 0 0-1.31-1.31h-7.535zm7.535 1.2h-7.535a.11.11 0 0 0-.11.11v4.15a.11.11 0 0 0 .11.11h7.535a.11.11 0 0 0 .11-.11v-4.15a.11.11 0 0 0-.11-.11zM8.359 4.999a1.31 1.31 0 0 0-1.07.555L4.36 9.704c-.431.611-.778 1.241-.778 2.014a2.82 2.82 0 0 0 1.577 2.532v11.441a1.31 1.31 0 0 0 1.31 1.31H25.85a1.31 1.31 0 0 0 1.31-1.31V14.067a2.82 2.82 0 0 0 1.259-2.349c0-.785-.354-1.404-.794-2.02l-2.962-4.15a1.31 1.31 0 0 0-1.066-.549H8.359zm1.817 1.2H8.359c-.623 0-2.605 3.61-3.019 4.196a.11.11 0 0 0 .09.173h2.777l1.97-4.37zM13.054 25.8H25.85a.11.11 0 0 0 .11-.11V14.515a2.87 2.87 0 0 1-.362.023 2.82 2.82 0 0 1-2.398-1.334 2.82 2.82 0 0 1-4.8-.009 2.82 2.82 0 0 1-4.801.008 2.82 2.82 0 0 1-4.797-.002c-.504.814-1.419 1.352-2.443 1.336V25.69a.11.11 0 0 0 .11.11h.949v-8.209a1.31 1.31 0 0 1 1.308-1.31h3.022a1.31 1.31 0 0 1 1.308 1.31V25.8zm-1.306 0H8.723a.11.11 0 0 1-.108-.11v-8.099a.11.11 0 0 1 .11-.11h3.022a.11.11 0 0 1 .11.11v8.099a.11.11 0 0 1-.108.11zm15.465-14.204a1.3 1.3 0 0 1-.655.173h-2.581a1.62 1.62 0 0 0 1.62 1.569 1.62 1.62 0 0 0 1.621-1.621 1.63 1.63 0 0 0-.005-.122zm-8.031.173a1.62 1.62 0 0 0 1.62 1.569 1.62 1.62 0 0 0 1.62-1.569h-3.24zm-1.566 0h-3.24a1.62 1.62 0 0 0 1.62 1.569 1.62 1.62 0 0 0 1.62-1.569zm-8.036 0a1.62 1.62 0 0 0 1.62 1.569 1.62 1.62 0 0 0 1.62-1.569H9.58zm-1.558 0H5.43a1.3 1.3 0 0 1-.644-.166 1.64 1.64 0 0 0-.004.115 1.62 1.62 0 0 0 1.621 1.621 1.62 1.62 0 0 0 1.62-1.569zm6.942-5.57h1.977l.676 4.37h-3.212l.56-4.37zm7.458 4.37h-3.32l-.935-4.37h2.285l1.97 4.37zm-8.685-4.37l-.683 4.37H9.523l1.97-4.37h2.245zm8.032 0h1.827a.11.11 0 0 1 .089.046l2.962 4.15a.11.11 0 0 1-.089.174h-2.819l-1.969-4.37z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M16.15 8.453h-3.817a.75.75 0 0 0-.613.318l-4.472 6.336a.75.75 0 0 0 .613 1.182h4.757l3.532-7.836zm-3.817-1.25a2 2 0 0 0-1.634.847l-4.472 6.336a2.07 2.07 0 0 0-.179.304 4.29 4.29 0 0 0-1.01 2.771c0 1.697.982 3.165 2.408 3.867v17.469a2 2 0 0 0 2 2H39.04a2 2 0 0 0 2-2V21.048c1.159-.772 1.922-2.09 1.922-3.587a4.29 4.29 0 0 0-1.052-2.82 2.09 2.09 0 0 0-.16-.264l-4.522-6.337a2 2 0 0 0-1.628-.838H12.333zm7.027 32.343h19.68a.75.75 0 0 0 .75-.75V21.617c-.362.099-.742.151-1.135.151-1.546 0-2.901-.814-3.661-2.037-.76 1.223-2.115 2.037-3.661 2.037-1.551 0-2.911-.82-3.669-2.05-.758 1.23-2.118 2.05-3.669 2.05-1.546 0-2.902-.815-3.662-2.038-.76 1.224-2.116 2.038-3.662 2.038s-2.904-.816-3.663-2.041c-.759 1.225-2.116 2.041-3.663 2.041a4.34 4.34 0 0 1-.649-.049v17.077a.75.75 0 0 0 .75.75h1.591c-.094-.232-.145-.485-.145-.75V26.431a2 2 0 0 1 2-2h4.614a2 2 0 0 1 2 2v12.366c0 .265-.052.518-.145.75zm-1.845 0h-4.634a.75.75 0 0 1-.74-.75V26.431a.75.75 0 0 1 .75-.75h4.614a.75.75 0 0 1 .75.75v12.366a.75.75 0 0 1-.74.75zm24.132-22.712c-.356.423-.888.705-1.526.705H35.64l-.017.313a3.06 3.06 0 0 0 3.032 2.666 3.06 3.06 0 0 0 3.057-3.057c0-.215-.022-.424-.064-.627zm-7.299.705h-6.047l-.01.225a3.06 3.06 0 0 0 6.074.088l-.017-.313zm-7.321 0h-6.049a4.34 4.34 0 0 1-.016.303 3.06 3.06 0 0 0 6.075-.078l-.01-.225zm-7.338 0h-6.037l-.015.29a3.06 3.06 0 0 0 6.068.014 4.34 4.34 0 0 1-.016-.303zm-7.323 0H7.861c-.628 0-1.156-.275-1.511-.688a3.07 3.07 0 0 0-.061.61 3.06 3.06 0 0 0 3.057 3.057 3.06 3.06 0 0 0 3.035-2.689l-.015-.29zm14.508-1.25h-5.823l1.676-7.836h2.471l1.676 7.836zm-7.101 0l1.676-7.836h-3.928l-3.532 7.836h5.784zm8.379 0l-1.676-7.836h3.99l3.532 7.836h-5.846zm7.217 0h4.753a.75.75 0 0 0 .611-1.186L36.21 8.768a.75.75 0 0 0-.611-.314h-3.763l3.532 7.836zm.225 9.391H24.09a.75.75 0 0 0-.75.75v6.337a.75.75 0 0 0 .75.75h11.505a.75.75 0 0 0 .75-.75v-6.337a.75.75 0 0 0-.75-.75zm-11.505-1.25a2 2 0 0 0-2 2v6.337a2 2 0 0 0 2 2h11.505a2 2 0 0 0 2-2v-6.337a2 2 0 0 0-2-2H24.09z"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
