import { createGlobalStyle, css } from '@withjoy/joykit';

export const baseState = css`
  transition: background-color 0.2s, outline 0.2s, color 0.2s, opacity 0.2s, border 0.2s;
  outline: 0.125rem solid ${props => props.theme.colors.transparent};
  outline-offset: -0.125rem;
`;

export const buttonStates = css`
  ${baseState};

  &:hover {
    background-color: ${({ theme }) => theme.colors.mono2};
  }

  &:focus-visible {
    outline: 0.125rem solid ${({ theme }) => theme.colors.primary7};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.mono5};
    outline-color: ${props => props.theme.colors.transparent};
  }
`;

export const filledButtonStates = css`
  ${baseState};

  &:hover {
    background-color: ${({ theme }) => theme.colors.mono3};
  }

  &:focus-visible {
    outline: 0.125rem solid ${({ theme }) => theme.colors.primary7};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.mono5};
    outline-color: transparent;
  }
`;

export const ellipsis = (lines: number = 2) => css`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const outlineButtonStates = css`
  ${baseState};
  border-color: ${props => props.theme.colors.mono14};
  color: ${props => props.theme.colors.mono14};

  svg {
    color: ${props => props.theme.colors.mono14};
  }

  &:hover {
    border-color: ${props => props.theme.colors.mono12};
    background-color: ${props => props.theme.colors.mono13}19;
    color: ${props => props.theme.colors.mono12};
  }

  &:focus-visible {
    outline: 0.125rem solid ${({ theme }) => theme.colors.primary7};
  }

  &:active {
    background-color: ${props => props.theme.colors.mono13}33;
    border-color: ${props => props.theme.colors.mono13};
    color: ${props => props.theme.colors.mono13};
    outline-color: ${props => props.theme.colors.transparent};
  }
`;

export const linkStates = css`
  ${baseState};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.primary7};
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary6};
  }

  &:active {
    color: ${({ theme }) => theme.colors.primary8};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.colors.primary7};
    outline: 0.125rem solid ${({ theme }) => theme.colors.primary7};
  }
`;

export const listButtonStates = css`
  ${baseState};
  border: 0.125rem solid ${props => props.theme.colors.mono3};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.black};

  &[aria-selected='true'] {
    border-color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.mono2};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary6};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.gray5};
      border-color: ${({ theme }) => theme.colors.primary8};
    }

    &:focus-visible {
      outline-color: ${({ theme }) => theme.colors.primary7};
    }
  }
`;

export const dropdownStates = css`
  ${baseState};

  &:hover {
    background-color: ${({ theme }) => theme.colors.mono2};
    color: ${({ theme }) => theme.colors.primary6};

    svg:last-child {
      color: ${({ theme }) => theme.colors.black};
    }

    svg:first-child {
      color: ${({ theme }) => theme.colors.primary6};
    }
  }

  &:focus-visible {
    color: ${({ theme }) => theme.colors.black};
    outline: 0.125rem solid ${({ theme }) => theme.colors.primary7};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.mono3};
    color: ${({ theme }) => theme.colors.primary8};
    outline-color: ${props => props.theme.colors.transparent};

    svg {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    position: relative;
  }
  /*
    Allow percentage-based heights in the application
  */
  html,
  body {
    height: 100%;
  }
`;
