import React from 'react';
import { DigitalDesignTemplate } from '../../usePdpData';
import { Box, Flex } from '@withjoy/joykit';

interface DigitalPreviewPaneProps {
  digitalDesignTemplate: DigitalDesignTemplate;
}

const PREVIEW_DEVICE_IMAGE_URL = 'https://withjoy.com/assets/public/ecard-device-preview.png?v=2';
export const DigitalPreviewPane = ({ digitalDesignTemplate }: DigitalPreviewPaneProps) => {
  return (
    <Box
      backgroundColor="mono1"
      aspectRatio={'1'}
      height="100%"
      width="100%"
      paddingY={{ _: 6, sm4: 80 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Flex maxHeight="100%" overflow="hidden" position="relative" justifyContent="center">
        <Box as="img" position="absolute" src={digitalDesignTemplate.thumbnailUrl} aspectRatio={'5 / 7'} width="100%" maxWidth="68%" top={'27%'} left={'16.5%'} />

        <Box as="img" height="100%" src={PREVIEW_DEVICE_IMAGE_URL} maxWidth="100%" />
      </Flex>
    </Box>
  );
};
