export enum BookingDialogStep {
  Intro = 1,
  Reminder = 2,
  Success = 3
}

export interface OptionReminder {
  id: string;
  value: string;
  label: string;
}
