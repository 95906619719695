import { PPI } from '@apps/card/routes/CardCustomizer/steps/CardDesign/config';

const baseWidth = 5 * PPI; // + 24 = shadow.
const baseHeight = 7 * PPI; // + 24 = shadow.
const desiredPadding = 40 / 2;
const desiredPaddingMobile = 16 / 2;

const desiredRowSpacing = 64;

const pageEdgeMargin = 64 - desiredPadding;
const pageEdgeMarginMobile = 24 - desiredPaddingMobile;

const mobileCols = 2;
const desktopCols = 4;

const responsiveSettings = { values: { desktop: 4, tablet: 2, mobile: 1, '0rem': 1 } };

export { responsiveSettings, baseWidth, baseHeight, desiredPadding, desiredPaddingMobile, mobileCols, desktopCols, pageEdgeMargin, pageEdgeMarginMobile, desiredRowSpacing };
