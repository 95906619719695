import { useMemo } from 'react';
import { NavItemProps } from '@withjoy/joykit';
import { PostOfficeRoutePaths, PostOfficeRouteNames } from '../../PostOffice.route.paths';
import { useRouteMatch } from '@shared/core';
import { useRouterHelper } from '@shared/core';
import { PostOfficeMessageType } from '@graphql/generated';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

const createTabToProp = (section: PostOfficeRouteNames, index: number, pathname: string, search: string): NavItemProps['to'] => {
  return {
    pathname,
    state: {
      index,
      section
    },
    search
  };
};

interface UseNavControllerArgs
  extends Readonly<{
    routes: PostOfficeRoutePaths;
    draftType: PostOfficeMessageType;
    eventHandle: string;
    fromDashboard?: string;
  }> {}

type TabItem = Pick<NavItemProps, 'to' | 'label'> & { nextPath?: NavItemProps['to'] };

export const useNavController = (args: UseNavControllerArgs) => {
  const isMobile = useIsMobileScreen();
  const { routes, draftType, eventHandle, fromDashboard } = args;
  const tabItems: TabItem[] = [
    {
      to: createTabToProp('create', 0, routes.create.path, `?draftType=${draftType}${fromDashboard === 'true' ? `&fromDashboard=true}` : ''}`),
      label: 'Edit Email',
      nextPath: `confirm`
    },
    {
      to: createTabToProp('confirm', 1, routes.confirm.path, `?draftType=${draftType}${fromDashboard === 'true' ? `&fromDashboard=true` : ''}`),
      label: 'Confirm & Send'
    }
  ];
  const postOfficeRouteMatch = useRouteMatch<{ eventHandle: string; page: string }>(`/${eventHandle}/edit/email/:page`);
  const showPostOfficeNavBar = useMemo(() => {
    return !!(postOfficeRouteMatch?.params.page === 'create' || postOfficeRouteMatch?.params.page === 'confirm') && !isMobile;
  }, [postOfficeRouteMatch?.params.page, isMobile]);

  const routerHelper = useRouterHelper();

  const goToNextPath = (path: string) => {
    routerHelper.goToExactRoute(path, `?draftType=${draftType}`);
  };

  return {
    tabItems,
    postOfficeRouteMatch,
    showPostOfficeNavBar,
    goToNextPath
  };
};
