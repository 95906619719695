import { getEcardPayload } from './Design.utils';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useSaveTheDateDispatch, useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SaveTheDateDesignInputFields, SaveTheDateEditorType } from '@apps/saveTheDate/SaveTheDate.types';
import { useTranslation } from '@shared/core';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { getFormattedCoupleNames, getFormattedLocation } from '@apps/saveTheDate/SaveTheDate.utils';
import { getFormattedDate } from '@apps/ecard/Ecard.utils';
import { COUPLE_NAME_FORMATS, DISPLAY_DATE_FORMATS, LOCATION_FORMATS } from '@apps/saveTheDate/constants';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useToast } from '@withjoy/joykit';
import { useUpdatePhotoEcardDesignFromQueryParam } from './hooks/useUpdatePhotoEcardDesignFromQueryParam';

export const useDesignController = () => {
  const { toast } = useToast();
  const { toggleIsNavBarOpen, saveEcard, toggleEditPanel } = useSaveTheDateDispatch();
  const { eventDisplayName, eCardDataQuery, eCardDraft, hasInitializedOnce, eventId, eventHandle, isEditPanelOpen } = useSaveTheDateState();
  const eventById = eCardDataQuery?.eventById;
  const eventInfo = eventById?.info;
  const locationInfo = eventInfo?.locationInfo;
  const location = eventInfo?.location;
  const date = eventInfo?.date;
  const { ownerFirstName = '', ownerFullName = '', fianceeFirstName = '', fianceeFullName = '' } = eventInfo || {};
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation('emailsAndEcards');
  const translations = t('ecardEditorPhotoSaveTheDate');
  const tEcardEditPanel = t('ecardEditor', 'ecardEditPanel');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { launchEcardEditor, eCardEditorTabClick, editECardDoneClick, editECardCancelClick, editCardContentTrack, editCardDesignTrack } = useSaveTheDateTelemetry();
  const { setParentViewState } = useBottomSheetState();

  const initialValues = useMemo(
    () => ({
      coupleNameFormat: eCardDraft.coupleNameFormat || COUPLE_NAME_FORMATS[0],
      locationStringFormat: eCardDraft.locationStringFormat || LOCATION_FORMATS[1],
      dateFormat: eCardDraft.displayDateFormat || DISPLAY_DATE_FORMATS[1],
      additionalLink1: eCardDraft.linkOptionPrimary!,
      additionalLink2: eCardDraft.linkOptionSecondary!,
      additionalMessage: eCardDraft?.message || '',
      displayEventLink: !!eCardDraft?.displayEventLink,
      inviteToFollow: eCardDraft?.subTitle || '',
      themeSelected: eCardDraft?.photoEcardTheme || 'goldBorderCursive',

      photoId: eCardDraft?.photo?.id || eCardDataQuery?.eventById?.pages?.[0]?.photo?.id,
      photoUrl: eCardDraft?.photo?.url || eCardDataQuery?.eventById?.pages?.[0]?.photo?.url,
      photoAssetId: eCardDraft?.photo?.assetId,

      // layout
      photoWidth: eCardDraft?.photo?.width || eCardDataQuery?.eventById?.pages?.[0]?.photo?.width,
      photoHeight: eCardDraft?.photo?.height || eCardDataQuery?.eventById?.pages?.[0]?.photo?.height,
      photoX: typeof eCardDraft?.photoEcardThemePositionX === 'number' ? eCardDraft.photoEcardThemePositionX : null,
      photoY: typeof eCardDraft?.photoEcardThemePositionY === 'number' ? eCardDraft.photoEcardThemePositionY : null,
      photoScale: eCardDraft?.photoEcardThemeScale || 1
    }),
    [eCardDraft, eCardDataQuery?.eventById]
  );

  const saveTheDateFormik = useFormik<SaveTheDateDesignInputFields>({
    initialValues,
    validationSchema: Yup.object<SaveTheDateDesignInputFields>({
      coupleNameFormat: Yup.string(),
      locationStringFormat: Yup.string(),
      dateFormat: Yup.string(),
      inviteToFollow: Yup.string(),
      additionalLink1: Yup.string(),
      additionalLink2: Yup.string(),
      additionalMessage: Yup.string(),
      displayEventLink: Yup.boolean(),
      themeSelected: Yup.string(),
      photoId: Yup.string(),
      photoAssetId: Yup.string(),
      photoUrl: Yup.string(),
      photoWidth: Yup.number(),
      photoHeight: Yup.number(),
      photoX: Yup.number().nullable(),
      photoY: Yup.number().nullable(),
      photoScale: Yup.number()
    }),
    enableReinitialize: true,
    onSubmit: values => {
      const eCardPayload = getEcardPayload(
        values,
        getFormattedCoupleNames({ ownerFirstName, ownerFullName, fianceeFirstName, fianceeFullName, format: values.coupleNameFormat }),
        getFormattedLocation({ locationInfo, location, format: values.locationStringFormat }),
        getFormattedDate(date, values.dateFormat)
      );
      return saveEcard(eCardPayload, {
        id: values.photoId || '',
        assetId: values.photoAssetId || '',
        url: values.photoUrl || '',
        width: values.photoWidth || 0,
        height: values.photoHeight || 0
      });
    }
  });

  useUpdatePhotoEcardDesignFromQueryParam({ saveTheDateFormik });

  const openEditPanel = (openType: SaveTheDateEditorType) => {
    toggleEditPanel();
    toggleIsNavBarOpen();
    setSelectedTabIndex(openType === SaveTheDateEditorType.CONTENT ? 1 : 0);
    launchEcardEditor(openType);

    if (!isEditPanelOpen) {
      if (openType === SaveTheDateEditorType.CONTENT) {
        editCardContentTrack.enter();
      } else {
        editCardDesignTrack.enter();
      }
    }
  };

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, openECardEditor: openEditPanel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedTabIndexChange = (index: number) => {
    setSelectedTabIndex(index);
    eCardEditorTabClick(!index ? SaveTheDateEditorType.DESIGN : SaveTheDateEditorType.CONTENT);

    if (index) {
      editCardContentTrack.enter();
    } else {
      editCardDesignTrack.enter();
    }
  };

  const onCancel = () => {
    saveTheDateFormik.resetForm();
    toggleEditPanel();
    toggleIsNavBarOpen();
  };

  const handleUnsavedChangesDialogClose = () => {
    setIsUnsavedChangesDialogOpen(false);
  };

  const handleUnsavedChangesDialogConfirm = () => {
    handleUnsavedChangesDialogClose();
    onCancel();
  };

  const handleCancel = () => {
    editECardCancelClick(!selectedTabIndex ? SaveTheDateEditorType.CONTENT : SaveTheDateEditorType.DESIGN);

    if (saveTheDateFormik.dirty) {
      setIsUnsavedChangesDialogOpen(true);
      return;
    }

    onCancel();
  };

  const handleDone = async () => {
    editECardDoneClick(!selectedTabIndex ? SaveTheDateEditorType.CONTENT : SaveTheDateEditorType.DESIGN);
    const wasDirty = saveTheDateFormik.dirty;

    if (wasDirty) {
      await saveTheDateFormik.submitForm();
      toggleEditPanel();
      toggleIsNavBarOpen();
    }

    toast(tEcardEditPanel.doneTooltipText(), {
      icon: '✅'
    });

    if (wasDirty) {
      return;
    }

    handleCancel();
  };

  const previewFrontRef = useRef<HTMLDivElement>(null);
  const previewBackRef = useRef<HTMLDivElement>(null);

  const handleScrollToFront = useCallback(() => {
    if (previewFrontRef && previewFrontRef.current) {
      previewFrontRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [previewFrontRef]);

  const handleScrollToBack = useCallback(() => {
    if (previewBackRef && previewBackRef.current) {
      previewBackRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [previewBackRef]);

  const extraData = useMemo(
    () => ({
      hasInitializedOnce,
      eventId,
      eventHandle,
      eventDisplayName,
      eventById,
      eventPassword: eCardDataQuery?.eventById?.info.eventPassword,
      privacyMode: eCardDataQuery?.eventById?.settings?.privacyMode,
      guidId: eCardDataQuery?.getMostRecentEcardByEventId?.guidId || '',
      website: eCardDataQuery?.eventById?.website,
      domainName: eCardDataQuery?.eventById?.domain?.domainName,
      date: eCardDataQuery?.eventById?.info?.date || '',
      location: eCardDataQuery?.eventById?.info?.location || '',
      accentId: eCardDataQuery?.eventById?.eventDesign?.accent || '',
      linkOptions: eCardDataQuery?.getEcardLinkOptionsByEventId || []
    }),
    [eCardDataQuery, eventById, eventDisplayName, eventHandle, eventId, hasInitializedOnce]
  );

  const savePosition = (x: number, y: number, scale: number) => {
    saveTheDateFormik.setFieldValue('photoX', x);
    saveTheDateFormik.setFieldValue('photoY', y);
    saveTheDateFormik.setFieldValue('photoScale', scale);
  };

  return {
    saveTheDateFormik,
    handleCancel,
    handleDone,
    isEditShown: isEditPanelOpen,
    openEditPanel,
    isUnsavedChangesDialogOpen,
    handleUnsavedChangesDialogClose,
    handleUnsavedChangesDialogConfirm,
    translations,
    selectedTabIndex,
    handleSelectedTabIndexChange,
    handleScrollToFront,
    handleScrollToBack,
    previewFrontRef,
    previewBackRef,
    extraData,
    savePosition
  };
};
