import React from 'react';
import { generateComponentDisplayName, safeInvoke } from '../../utils';
import { TextV1 } from '../TextV1';
import { Delete, Container } from './';
import { Intent } from '../../common/props';

export const TagVariant = {
  OUTLINED: 'outlined' as 'outlined',
  SOLID: 'solid' as 'solid'
};

export type TagVariant = typeof TagVariant[keyof typeof TagVariant];
export type TagIntent = typeof Intent.PRIMARY;

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  a11yLabel?: string;
  disabled?: boolean;
  intent?: TagIntent;

  /**
   * @default 'solid'
   */
  variant?: TagVariant;

  onClick?: (e: React.MouseEvent) => void;

  /**
   * Click handler for the remove button.
   */
  onRemove?: (e: React.MouseEvent, tagProps: TagProps) => void;
}

const Tag: React.FC<TagProps> = React.memo(props => {
  const { a11yLabel, children, disabled, intent = Intent.PRIMARY, onClick, onRemove, variant = TagVariant.SOLID, ...restProps } = props;

  const onRemoveClickHandler: React.MouseEventHandler<HTMLElement> = e => {
    safeInvoke(onRemove, e, props);
  };

  const isInteractive = !disabled && !!onClick;

  const htmlProps: Partial<React.HTMLAttributes<HTMLSpanElement>> = {
    'aria-label': a11yLabel
  };

  if (isInteractive) {
    htmlProps.tabIndex = 0;
  }

  if (!disabled) {
    htmlProps.onClick = onClick;
  }

  return (
    <Container {...restProps} {...htmlProps} isInteractive={isInteractive} intent={intent} variant={variant}>
      <TextV1 variant="label2" color="currentColor">
        {children}
      </TextV1>
      {onRemove && <Delete tabIndex={0} onClick={onRemoveClickHandler} />}
    </Container>
  );
});

Tag.displayName = generateComponentDisplayName('Tag');

export default Tag;
