import { styled } from '@withjoy/joykit';

export const HeaderContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  gap: ${props => (props.mobile ? '16px' : '40px')};

  vertical-align: middle;

  padding: ${props => (props.mobile ? '15px 24px 15px 24px' : '24px 64px 24px 64px')};

  border-bottom: 1px solid #d9d9d9;
`;

export const Underline = styled.div`
  border: 1px solid #333333;
  position: absolute;
  width: 100%;
  top: calc(100%);
`;
