import { Flex, styled, TextV2 } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';
import { StyledTextArea } from '@shared/components/EmailsAndEcards/components/shared';
export { StyledTextArea };

export const StyledLabel = styled.span`
  ${InterFonts.subhead}
`;

export const InputLengthLabel = styled.p`
  ${InterFonts.caption}
  color: ${props => props.theme.colors.mono8};
  margin-top: 9px;
  text-align: end;
`;

export const ToggleWrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 4px;
`;

export const ToggleTitle = styled(TextV2)`
  ${InterFonts.bodyTitle}
`;

export const ToggleDesc = styled(TextV2)`
  ${InterFonts.caption}
  color: ${props => props.theme.colors.mono12};
`;

export const SubTitle = styled(TextV2)`
  ${InterFonts.title}
  padding-top: 30px;
`;

export const YourURLBox = styled(Flex)`
  ${InterFonts.body}
  width: 100%;
  max-width: 360px;
  height: 48px;
  padding: 0 16px;
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.colors.mono5};
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 4px;
  color: ${props => props.theme.colors.mono10};
`;

export const YourURLText = styled(TextV2)`
  color: ${props => props.theme.colors.black};
`;

export const LineBreak = styled.br``;

export const RevertToJoy = styled(TextV2)`
  ${InterFonts.button}
  margin-top: 13px;
  cursor: pointer;
  color: ${props => props.theme.colors.linkText};

  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }
`;
