import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledAssetBackground, DetailsContainer } from './SideCursive.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetArchUrl = getThemeInfo('sideCursive')?.assetUrl || '';

export const SideCursive: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage />
      <StyledAssetBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetArchUrl, renditionSize: 'medium' })}>
        <Flex height="180px" width="100%" padding="30px" flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
          <TextV2 color="white" textAlign="left" fontSize={12} fontFamily="Lora" fontWeight={500} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName ? ' & ' : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="flex-start">
              {formattedDate ? (
                <TextV2 color="white" textAlign="left" fontSize={12} fontFamily="Lora" fontWeight={500} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="white" textAlign="left" fontSize={12} fontFamily="Lora" fontWeight={500} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="white" textAlign="left" fontSize={12} fontFamily="Lora" fontWeight={500} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledAssetBackground>
    </>
  );
};
