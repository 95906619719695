import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { StyledDialog, Scrollable, StyledHeader, ContentWrapper, Container } from './EditPanel.styles';
import { IconV2 } from '@withjoy/joykit';
import { DesignEmail } from '@withjoy/joykit/icons';

interface EditPanelProps
  extends Readonly<{
    title: string;
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
  }> {}

export const EditPanel = ({ title, isOpen, onClose, children }: EditPanelProps) => {
  return (
    <Container>
      <Transition unmount={false} show={isOpen} as={Fragment} enter="transition" enterFrom="right" enterTo="left" leave="transition" leaveFrom="left" leaveTo="right">
        <StyledDialog>
          <button className="overlay" onClick={onClose} />
          <ContentWrapper>
            <Scrollable>
              <StyledHeader>
                <IconV2 size="md" marginRight="12px" display={['block', 'block', 'none !important']}>
                  <DesignEmail />
                </IconV2>{' '}
                {title}
              </StyledHeader>
              {children}
            </Scrollable>
          </ContentWrapper>
        </StyledDialog>
      </Transition>
    </Container>
  );
};
