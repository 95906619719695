import { addMonths } from 'date-fns';

export const getTelemetryDateLabel = (date: Date | null) => {
  if (!date) {
    return 'dateUndecided';
  }

  if (date.toDateString() === new Date().toDateString()) {
    return 'today';
  } else if (date.toDateString() === addMonths(new Date(), 9).toDateString()) {
    return 'default';
  } else {
    return 'custom';
  }
};
