import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { useCallback, useState } from 'react';

interface Args
  extends Readonly<{
    ownsEvents: boolean;
  }> {}

export const useDeleteUser = (args: Args) => {
  const { ownsEvents } = args;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [mustLeaveEventsDialogOpen, setMustLeaveEventsDialogOpen] = useState<boolean>(false);
  const actions = useAccountMeTelemetry();

  const handleDialogClose = useCallback(() => {
    setDeleteDialogOpen(false);
    setMustLeaveEventsDialogOpen(false);
  }, []);

  const handleShowDialog = useCallback(() => {
    actions.trackDeleteAccountClicked();
    if (ownsEvents) {
      setMustLeaveEventsDialogOpen(true);
    } else {
      setDeleteDialogOpen(true);
    }
  }, [ownsEvents, actions]);

  return {
    handleDialogClose,
    handleShowDialog,
    deleteDialogOpen,
    mustLeaveEventsDialogOpen
  };
};
