/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chevron Up',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M8 4L14 10.1091L13.215 11L8 5.65454L2.78505 11L2 10.1091L8 4Z" fill="currentColor" />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 25',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M12 6.05176L22 15.6518L20.6916 17.0518L12 8.65176L3.30841 17.0518L2 15.6518L12 6.05176Z" fill="currentColor" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
