import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';

export const disabledButtonOverride: ButtonOverrides = {
  Root: {
    props: {
      color: 'mono8',
      backgroundColor: 'mono3',
      _hover: {
        color: 'mono8',
        backgroundColor: 'mono3'
      },
      _active: {
        color: 'mono8',
        backgroundColor: 'mono3'
      }
    }
  }
};
