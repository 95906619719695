import { Box, ButtonV2, Flex, LinkV2, styled, StyleSystemProps } from '@withjoy/joykit';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';
import { ECARD_DEFAULT_WIDTH, ECARD_DEFAULT_HEIGHT } from '@apps/ecard/Ecard.constants';

export const GenericEcardContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.03));
  border-radius: 4px;
  width: ${ECARD_DEFAULT_WIDTH}px;
  overflow: hidden;
  padding: 10px;
  aspect-ratio: ${ECARD_DEFAULT_WIDTH} / ${ECARD_DEFAULT_HEIGHT};
`;

export const GenericEcardContent = styled(Flex)``;
export const GenericEcardEditButton = styled(ButtonV2)``;
export const GenericEcardOverlay = styled(Flex)``;
export const GenericEcardLink = styled(LinkV2)``;
export const GenericEcardMessageContainer = styled(Box)`
  a {
    color: inherit;
    &:hover {
      color: inherit;
      opacity: 0.8;
    }
    &:active {
      color: inherit;
      opacity: 1;
    }
  }
`;

const genericEcardContent: StyleSystemProps = {
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1,
  width: '100%'
};

export const genericEcardEditButtonOverrides: ButtonOverrides = {
  Root: {
    props: {
      display: ['none', 'inline-flex'],
      fontFamily: 'Inter UI',
      color: 'black',
      fontWeight: '600',
      border: '2px solid',
      borderColor: 'mono5',
      boxShadow: '0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07)',
      backgroundColor: 'white',
      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity 0.2s',
      _hover: {
        backgroundColor: 'mono1'
      },
      _active: {
        backgroundColor: 'mono2'
      }
    }
  }
};

const genericEcardOverlay: StyleSystemProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  zIndex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  _hover: {
    [`${GenericEcardEditButton}`]: {
      opacity: 1
    }
  }
};

const inhreitedColorLink: StyleSystemProps = {
  color: 'inherit',
  textDecoration: 'none',
  marginTop: 9,
  borderBottom: 'solid 1px',
  _hover: {
    color: 'inherit',
    opacity: 0.8,
    _active: {
      opacity: 1,
      color: 'inherit'
    }
  },
  _active: {
    color: 'inherit'
  },
  _visited: {
    color: 'inherit'
  }
};

const genericEcardMessageContainer: StyleSystemProps = {
  paddingX: 9,
  textAlign: 'center',
  marginTop: 9,
  whiteSpace: 'pre-wrap'
};

export const styles = {
  genericEcardContent,
  genericEcardOverlay,
  inhreitedColorLink,
  genericEcardMessageContainer
};
