/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Story',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fill="currentColor"
            d="M10.2 7.512a.6.6 0 0 1 .6-.6h5.5a.6.6 0 1 1 0 1.2h-5.5a.6.6 0 0 1-.6-.6zm.6 3.86a.6.6 0 0 0 0 1.2h4.173a.6.6 0 0 0 0-1.2H10.8zm-.6 5.068a.6.6 0 0 1 .6-.6h5.5a.6.6 0 0 1 0 1.2h-5.5a.6.6 0 0 1-.6-.6z"
          />{' '}
          <path
            fillRule="evenodd"
            fill="currentColor"
            d="M8.047 2.442h-3.23l-.02.001a1.42 1.42 0 0 0-.557.153 1.45 1.45 0 0 0-.45.359c-.124.148-.218.32-.277.504-.056.176-.08.36-.07.545v15.99c-.01.184.012.37.07.545.06.184.152.355.277.504a1.45 1.45 0 0 0 .45.359 1.42 1.42 0 0 0 .557.153l.02.001h11.5v-.047h2.81c.81 0 1.427-.674 1.427-1.458V3.9c0-.784-.62-1.458-1.427-1.458H8.055zm11.08 17.868c.105 0 .227-.095.227-.258V3.9c0-.163-.122-.258-.227-.258H8.052c-.104.001-.225.096-.225.258v16.152c0 .163.122.258.227.258h11.073zM6.65 3.642c-.015.084-.023.17-.023.258v16.152a1.48 1.48 0 0 0 .032.305H4.862c-.025-.003-.05-.01-.074-.023a.25.25 0 0 1-.077-.062c-.023-.027-.04-.06-.053-.097s-.016-.077-.014-.116l.002-.022L4.644 3.94c-.003-.04.002-.08.014-.116s.03-.07.053-.097a.25.25 0 0 1 .077-.062c.024-.012.05-.02.074-.023H6.65z"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
