import React from 'react';
import Media from 'react-media';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { AbstractPureComponent } from '../../common';
import { IconV1 } from '../IconV1';
import { BundledIcon } from '../../../icons';
import { Stack } from '../Stack';
import { TextV1 } from '../TextV1';
import { Box } from '../Box';
import { safeInvoke, generateComponentDisplayName } from '../../utils';
import { Flex } from '../Flex';

interface PageHeaderProps {
  /**
   * Additional elements to be placed at the end of the title line.
   *
   * Note:
   * - this will be hidden on mobile devices - screens with a min-width as defined by `theme.breakpoints.sm`.
   * - This will take precedence over the `extra` prop.
   */
  children?: React.ReactNode;
  /**
   * Instead of `children`, you can pass the `extra` items to be displayed at the end of the title line.
   */
  extra?: React.ReactNode;

  /**
   * Custom back icon.
   *
   * if false, the back icon will not be displayed.
   */
  backIcon: boolean | BundledIcon;

  onBackClick?: () => void;

  title: string;
}

const StyledPageHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconMarkup = styled(Box)`
  cursor: pointer;
  border: 0;
  padding: 0.5rem;
  margin-left: -1rem;
  background: transparent;
`;

const StyledExtraContent = styled(Stack)`
  align-items: center;
`;

export class PageHeader extends AbstractPureComponent<PageHeaderProps> {
  static displayName = generateComponentDisplayName(PageHeader);
  static defaultProps = {
    backIcon: true
  };

  handleOnBackClick = () => {
    safeInvoke(this.props.onBackClick);
  };

  render(): React.ReactNode {
    return (
      <Media query={`(max-width: 768px)`}>
        {isMobile => (
          <StyledPageHeaderContainer height={[74, null, 92]}>
            {this.renderHeader()}
            {!isMobile && this.renderExtraContent()}
          </StyledPageHeaderContainer>
        )}
      </Media>
    );
  }

  private renderHeader = () => {
    const { backIcon, title } = this.props;

    const iconMarkup =
      backIcon === false ? undefined : (
        <StyledIconMarkup component="button" onClick={this.handleOnBackClick} tabIndex={0} padding={'.5rem'} marginLeft={'-.5rem'} marginRight={3}>
          <IconV1 iconSize={24} source={backIcon === true ? 'chevronLeft' : backIcon} />
        </StyledIconMarkup>
      );

    return (
      <Flex alignItems="center">
        {iconMarkup}
        <TextV1 component="h1" variant="hed6">
          {title}
        </TextV1>
      </Flex>
    );
  };

  private renderExtraContent = () => {
    const { extra, children } = this.props;
    if (children || extra) {
      return (
        <Box>
          <StyledExtraContent vertical={false} spacing={6}>
            {children || extra}
          </StyledExtraContent>
        </Box>
      );
    }
    return null;
  };
}
