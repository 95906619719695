import React, { useContext } from 'react';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { PeopleContext } from '@shared/context/people';
import { ButtonV2, DrawerV2, TextV2 } from '@withjoy/joykit';
import { ReactComponent as EmailSentSvg } from '@assets/icons/sent-plane.svg';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { darkButtonOverride } from '../Shared.styles';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';

interface ConfirmSheetProps {}

const ConfirmSheet: React.FC<ConfirmSheetProps> = () => {
  const isMobile = useIsMobileScreen();
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tConfirm = tBottomSheet.confirm;
  const { selectedPeople } = useContext(PeopleContext);
  const { parentViewState, setParentViewState } = useBottomSheetState();

  const onBackToDashboardClick = () => {
    withWindow(() => {
      window.location.href = window.location.origin + `/${parentViewState?.eventHandle}/edit/dashboard`;
    });
  };

  if (!isMobile) return null;

  return (
    <DrawerV2
      overrides={{ Content: { props: { borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: 'var(--full-screen-height)' } } }}
      anchor="bottom"
      isOpen={!!parentViewState?.isConfirmSheetOpen}
      onClose={() => setParentViewState(prev => ({ ...prev, isConfirmSheetOpen: false }))}
      useBackdrop={false}
    >
      <DrawerV2.CloseButton top="24px" right="24px" padding={0} color="mono14" />
      <DrawerV2.Body display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingX={6}>
        <EmailSentSvg />
        <TextV2 marginBottom="16px" textAlign="center" color="mono14" typographyVariant="hed6">
          {tConfirm.title()}
        </TextV2>
        <TextV2 marginBottom="40px" fontWeight={500} textAlign="center" fontSize="15px" lineHeight="22px" color="mono12">
          {tConfirm.description({ count: selectedPeople.length })}
        </TextV2>
        <ButtonV2 width="100%" overrides={darkButtonOverride} onClick={onBackToDashboardClick}>
          {tConfirm.button()}
        </ButtonV2>
      </DrawerV2.Body>
    </DrawerV2>
  );
};

export default ConfirmSheet;
