import { useAuth } from '@shared/components/AuthProvider';
import { useTranslation } from '@shared/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import { SwitchFormHandler } from '../AccountForm.types';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

type ForgotPasswordValues = {
  email: string;
};

type useForgotPasswordControllerProps = {
  switchForm: SwitchFormHandler;
};

export const useForgotPasswordController = ({ switchForm }: useForgotPasswordControllerProps) => {
  const auth = useAuth();
  const { t2 } = useTranslation('createWedding');
  const errorTranslations = t2('signIn', 'errors');

  const switchToSignInForm = useEventCallback(() => switchForm('signIn'));

  const formik = useFormik<ForgotPasswordValues>({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(errorTranslations.emailValid).required(errorTranslations.emailRequired)
    }).required(),
    onSubmit: async ({ email }) => {
      await auth.loginManager.changePassword(email);
      switchToSignInForm();
    }
  });
  const getFieldErrorProps = useFieldErrors<ForgotPasswordValues>(formik);

  return { formik, getFieldErrorProps };
};
