import { styled } from '../../common';
import { Box } from '../Box';

export const StyledNavWrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
`;

export const StyledNavContainer = styled(Box)`
  display: inline-flex;
  overflow: hidden;
  position: relative;
`;

export const StyledNavIndicator = styled.span`
  bottom: 0;
  background-color: ${props => props.theme.colors.fillDark};
  height: 4px;
  left: 0;
  position: absolute;
  transition: left 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  width: 0;
  border-radius: 2px;
`;

export const StyledNavContent = styled.div`
  flex: 1 1 auto;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
  width: 100%;
  white-space: nowrap;
  position: relative;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 32px;

    ${props => props.theme.mediaQueries.sm} {
      margin-right: 16px;
    }
  }
`;
