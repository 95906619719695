import React from 'react';
import { SubTitle, StyledLabel, InputLengthLabel } from '../Shared.styles';
import { FormControl, InputV2, TextV2, CheckboxV2, TooltipV2, Box, Flex } from '@withjoy/joykit';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';
import { FormikProps } from 'formik';
import { StyledTextArea } from '@shared/components/EmailsAndEcards/components/shared';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import { useSaveTheDateState } from '@apps/saveTheDate/state/context';
import PhotoSelector from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector';
import { useTranslation } from '@shared/core';
import { SaveTheDateEmailInputFields } from '@apps/saveTheDate/SaveTheDate.types';
import { filterTidbitsPage } from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector/PhotoSelector.controller';

interface EditEmailFormProps {
  formik: FormikProps<SaveTheDateEmailInputFields>;
}

export const EditEmailForm: React.FC<EditEmailFormProps> = ({ formik }) => {
  const { includeGuestNames, buttonText, message, salutation, headline, subheadline, subject } = formik.values;
  const { eCardDataQuery } = useSaveTheDateState();
  const eventById = eCardDataQuery?.eventById;
  const pages = eventById?.pages;
  const { t } = useTranslation('emailsAndEcards');
  const tEcardGuestToolTip = t('ecardGuest', 'toolTip');
  const translations = t('emailEditorPhotoSaveTheDate');

  return (
    <>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.emailSubjectLabel()}</StyledLabel>}
        error={formik.errors.subject}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('subject')} maxLength={50} placeholder={translations.emailSubjectLabel()} />
          <InputLengthLabel>{subject.length} / 50</InputLengthLabel>
          {formik.getFieldMeta('subject').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('subject').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.replyToLabel()}</StyledLabel>}
        error={formik.errors.replyToEmail}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('replyToEmail')} placeholder="info@withjoy.com" />
          {formik.getFieldMeta('replyToEmail').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('replyToEmail').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <SubTitle>{translations.emailInformationLabel()}</SubTitle>
      <FormControl marginTop="32px" marginBottom="20px" label={<StyledLabel>{translations.photoSourceLabel()}</StyledLabel>}>
        <PhotoSelector
          pages={filterTidbitsPage(pages)}
          handlePhotoSelect={({ previewUrl }) => formik.setFieldValue('imageUrl', previewUrl)}
          photo={{ url: formik.values.imageUrl || '' }}
        />
      </FormControl>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.headlineLabel()}</StyledLabel>}
        error={formik.errors.headline}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('headline')} maxLength={50} placeholder={translations.headlineLabel()} />
          <InputLengthLabel>{headline.length} / 50</InputLengthLabel>
          {formik.getFieldMeta('headline').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('headline').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.subheadlineLabel()}</StyledLabel>}
        error={formik.errors.subheadline}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('subheadline')} maxLength={60} placeholder={translations.subheadlineLabel()} />
          <InputLengthLabel>{subheadline?.length} / 60</InputLengthLabel>
          {formik.getFieldMeta('subheadline').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('subheadline').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.salutationLabel()}</StyledLabel>}
        error={formik.errors.salutation}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('salutation')} maxLength={30} placeholder={translations.salutationPlaceholder()} />
          <InputLengthLabel>{salutation?.length} / 30</InputLengthLabel>
          {formik.getFieldMeta('salutation').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('salutation').error}
            </TextV2>
          )}
          <Flex alignItems="center">
            <CheckboxV2 id="includeGuestNames" isChecked={includeGuestNames} onChange={() => formik.setFieldValue('includeGuestNames', !includeGuestNames)}>
              {translations.useGuestNamesLabel()}
            </CheckboxV2>
            <TooltipV2
              content={
                <Box width="256px" padding={3}>
                  <TextV2 typographyVariant="label2">
                    <b>{tEcardGuestToolTip.firstPart()}</b> <br /> {tEcardGuestToolTip.secondPart()}
                  </TextV2>
                </Box>
              }
            >
              <Box marginLeft="9px">
                <HelpIcon />
              </Box>
            </TooltipV2>
          </Flex>
        </>
      </FormControl>
      <FormControl paddingTop="24px" label={<StyledLabel>{translations.messageLabel()}</StyledLabel>} error={formik.errors.message} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <StyledTextArea
            {...formik.getFieldProps('message')}
            resize={false}
            minRows={4}
            maxRows={14}
            tabIndex={0}
            minimal={true}
            maxLength={10000}
            placeholder={translations.messagePlaceholder()}
          />
          <InputLengthLabel>{message?.length} / 10000</InputLengthLabel>
          {formik.getFieldMeta('message').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('message').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.buttonTextLabel()}</StyledLabel>}
        error={formik.errors.buttonText}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('buttonText')} maxLength={30} placeholder={translations.buttonTextPlaceholder()} />
          <InputLengthLabel>{buttonText?.length} / 30</InputLengthLabel>
          {formik.getFieldMeta('buttonText').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('buttonText').error}
            </TextV2>
          )}
        </>
      </FormControl>
    </>
  );
};
