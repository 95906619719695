import { Box, styled } from '@withjoy/joykit';

export const EmailPreviewWrapper = styled.div<{ $isEmailEditPanelShown: boolean }>`
  height: 100%;
  width: ${({ $isEmailEditPanelShown }) => ($isEmailEditPanelShown ? `calc(100% - 376px)` : `100%`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  // TODO: adjust bottom padding after Bottom Sheet View is integrated
  padding: 0 0 180px 0;
  transition: width 0.4s;
  will-change: width;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const EmailPreviewContainer = styled(Box)`
  width: 100%;
  max-width: 450px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.03), 0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  position: relative;

  @media (max-width: 767px) {
    border-radius: 0px;
    max-width: 100%;
  }
`;
