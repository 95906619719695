import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { ApolloError } from '@apollo/client';
import { ElectronicCardInput, ElectronicCardEmailDraftInput, ElectronicCardEmailMessageInput } from '@graphql/generated';
import { ECardDraft, LinkLabel, SaveTheDateEditorType, SaveTheDateEmailInputFields } from './SaveTheDate.types';

type PagesForTelemetry =
  | 'designCardTrack'
  | 'editEmailTrack'
  | 'confirmAndSendTrack'
  | 'confirmSelectGuestsTrack'
  | 'confirmTestEmailTrack'
  | 'editEmailContentTrack'
  | 'eCardLandingPageTrack'
  | 'editCardDesignTrack'
  | 'editCardContentTrack'
  | 'premiumDigitalUpsellDialogTrack';

interface CardDraftCreatedArgs {
  page: 'modernEcardEditor';
  draftId: string;
  themeId: string;
  stationeryTemplateCategory: 'saveTheDate' | 'invitation';
  isFavorite: boolean;
  isDigital: true;
  isPremiumDesign: boolean;
  format: 'premiumDigital';
}

const emailCategory = `admin.eCardEmail`;

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  eCardLandingPageTrack: () => ({
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard',
    action: 'ECardLandingPageViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  designCardTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard.design',
    action: 'DesignCardViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  editCardDesignTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard.design.edit',
    action: 'EditCardDesignViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  editCardContentTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard.design.editcontent',
    action: 'EditCardContentViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  editEmailTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.savethedatephoto.email`,
    action: 'EditEmailViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  editEmailContentTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.email.edit`,
    action: 'EditCardContentViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  confirmAndSendTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.confirm`,
    action: 'ConfirmAndSendViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  confirmSelectGuestsTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.confirm.selectguests`,
    action: 'ConfirmSelectGuestsViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  confirmTestEmailTrack: () => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.confirm.test`,
    action: 'ConfirmTestEmailViewed',
    extraPageInfo: {
      type: 'savethedatephoto'
    }
  }),
  premiumDigitalUpsellDialogTrack: (themeId: string, category: 'saveTheDate' | 'invitation') => ({
    type: 'enter',
    category: 'admin.messaging.ecard',
    pagePrefix: 'admin',
    page: 'premiumDigitalUpsellDialog',
    extraPageInfo: {
      themeId,
      stationeryTemplateCategory: category
    }
  })
};

export const saveTheDateTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category: 'admin.messaging',
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { type: 'savethedatephoto', error, mutation, ...extra }
    }),
    // don't have print options
    // viewMatchingPrintsFromVendor: (themeId: string) => ({
    //   action: 'ViewMatchingPrintsFromVendor',
    //   actionType: undefined,
    //   category: 'admin.savethedatephoto',
    //   label: themeId
    // }),
    // don't have saveTheDate landing page
    // previewTabClick: (label: EcardLandingRightPanelTabItem) => ({
    //   action: 'EcardPreviewTab',
    //   actionType: 'click',
    //   category: 'admin.savethedatephoto',
    //   label
    // }),
    // don't have print options
    // explorePrintsClick: (themeId: string) => ({
    //   action: 'ExplorePrints',
    //   actionType: 'click',
    //   category: 'admin.savethedatephoto',
    //   label: themeId
    // }),
    // don't have saveTheDate landing page
    // designAndSendClick: () => ({
    //   action: 'DesignAndSend',
    //   actionType: 'click',
    //   category: 'admin.savethedatephoto'
    // }),
    // don't have saveTheDate landing page
    // viewGuestListClick: () => ({
    //   action: 'ViewGuestList',
    //   actionType: 'click',
    //   category: 'admin.savethedatephoto'
    // }),
    // don't have print options
    // explorePrintParnterClick: (partnerName: string) => ({
    //   action: 'ExplorePrintParnter',
    //   actionType: 'click',
    //   category: 'admin.savethedatephoto',
    //   label: partnerName
    // }),

    // TODO: add this even when ecard preview is ready
    // editEcardClick: () => ({
    //   action: 'EditEcard',
    //   actionType: 'click',
    //   category: 'admin.savethedatephoto'
    // }),

    eCardAdminLinkClick: (linkLabel: LinkLabel) => ({
      action: 'ECardAdminLink',
      actionType: 'click',
      category: 'admin.messaging',
      label: linkLabel,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    launchEcardEditor: (saveTheDateEditorType: SaveTheDateEditorType) => ({
      action: 'LaunchEcardEditor',
      actionType: 'click',
      category: 'admin.messaging',
      label: saveTheDateEditorType,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    eCardEditorTabClick: (saveTheDateEditorType: SaveTheDateEditorType) => ({
      action: 'EcardEditorTab',
      actionType: 'click',
      category: 'admin.messaging',
      label: saveTheDateEditorType,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    editECardDoneClick: (saveTheDateEditorType: SaveTheDateEditorType) => ({
      action: 'Done',
      actionType: 'click',
      category: 'admin.messaging',
      label: `saveTheDate editor - ${saveTheDateEditorType}`,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    editECardCancelClick: (saveTheDateEditorType: SaveTheDateEditorType) => ({
      action: 'Cancel',
      actionType: 'click',
      category: 'admin.messaging',
      label: `saveTheDate editor - ${saveTheDateEditorType}`,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    eCardSave: (eCardData: ElectronicCardInput, customPhotoUpload: boolean) => ({
      action: 'ECardSave',
      actionType: undefined,
      category: 'admin.messaging',
      extraInfo: {
        type: 'savethedatephoto',
        eCardData: {
          ...eCardData,
          additionalMessage: eCardData.message,
          message: eCardData.subTitle,
          customPhotoUpload
        }
      }
    }),

    // TODO: add this even when ecard design is ready
    // eCardDesignSave: (eCardDesignData: EcardDesignInput, themeId?: string) => ({
    //   action: 'ECardDesignSave',
    //   actionType: undefined,
    //   category: 'admin.savethedatephoto',
    //   extraInfo: {
    //     eCardDesignData: {
    //       ...eCardDesignData,
    //       themeId
    //     }
    //   }
    // }),

    launchEmailEditor: () => ({
      action: 'LaunchEmailEditor',
      actionType: undefined,
      category: emailCategory
    }),
    editEmailDoneClick: () => ({
      action: 'Done',
      actionType: 'click',
      category: emailCategory,
      label: 'email editor'
    }),
    editEmailCancelClick: () => ({
      action: 'Cancel',
      actionType: 'click',
      category: emailCategory,
      label: 'email editor'
    }),
    emailSave: (emailData: ElectronicCardEmailDraftInput) => ({
      action: 'EmailSave',
      actionType: undefined,
      category: 'admin.messaging',
      extraInfo: {
        type: 'savethedatephoto',
        emailData
      }
    }),
    sendEmail: (emailData: ElectronicCardEmailMessageInput) => ({
      action: 'SendEmail',
      actionType: undefined,
      category: 'admin.messaging',
      label: (emailData.recipientIds?.length || 0).toString(),
      extraInfo: {
        type: 'savethedatephoto',
        emailData: {
          ...emailData,
          totalRecipients: emailData.recipientIds?.length || []
        }
      }
    }),
    sendTestEmail: (name: string, email: string) => ({
      action: 'SendTest',
      actionType: 'click',
      category: 'admin.messaging',
      extraInfo: {
        type: 'savethedatephoto',
        name,
        email
      }
    }),
    sendECardClick: (payload: { eCardData: ECardDraft; emailData: SaveTheDateEmailInputFields }, label: string, adminReferrer: string) => ({
      action: 'SendECard',
      actionType: 'click',
      category: 'admin.messaging',
      label,
      extraInfo: {
        type: 'savethedatephoto',
        ecardMessageFrontContent: payload.eCardData.message,
        ecardMessageBackContent: payload.eCardData.additionalMessage,
        theme: payload.eCardData.photoEcardTheme,
        eCardData: payload.eCardData,
        adminReferrer
      }
    }),
    eCardLinkClick: (linkName: string, eventId: string) => ({
      action: 'eCardLink',
      actionType: 'click',
      category: 'wedding',
      label: linkName,
      extraInfo: {
        eventId
      }
    }),
    selectGuestsClicked: () => ({
      action: 'SelectGuests',
      actionType: 'click',
      category: 'admin.messaging',
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    selectThemeClicked: (themeSelected: string) => ({
      action: 'TriedStyle',
      actionType: 'click',
      category: 'admin.messaging',
      label: themeSelected,
      extraInfo: {
        type: 'savethedatephoto',
        key: themeSelected
      }
    }),
    aboutShareableLinksClick: (label: 'Cancel' | 'I Understand') => ({
      action: 'AboutShareableCards',
      actionType: 'click',
      category: 'admin.messaging',
      label,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    copyShareableLinkClicked: (link: string) => ({
      action: 'CopyShareableLink',
      actionType: 'click',
      category: 'admin.messaging',
      label: link,
      extraInfo: {
        type: 'savethedatephoto'
      }
    }),
    tryPremiumClicked: ({ from, themeId, templateCategory }: { from: 'banner' | 'dialog'; templateCategory: 'saveTheDate' | 'invitation'; themeId: string }) => ({
      action: 'ButtonInteracted',
      category: 'admin.messaging.ecard',
      extraInfo: {
        name: `premiumUpsell${from}_tryPremiumCta`,
        themeId,
        templateCategory
      }
    }),
    cardDraftCreated: ({ page, ...extraInfo }: CardDraftCreatedArgs) => ({
      category: 'admin.messaging.ecard',
      action: 'CardDraftCreated',
      page,
      extraInfo: extraInfo
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry } = createTelemetry(saveTheDateTelemetry, { eventId: '' });

const useSaveTheDateTelemetry = useTelemetry;

export { TelemetryProvider, useSaveTheDateTelemetry };
