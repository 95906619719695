import { css } from '@withjoy/joykit';

const page = css`
  font-family: 'Inter UI';
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.015em;
`;

const cardHeader = css`
  font-family: 'Inter UI';
  font-weight: 300;
  font-style: normal;
  font-size: 56px;
  line-height: 45px;
  letter-spacing: -0.02em;
`;

const header = css`
  font-family: 'Inter UI';
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.015em;
`;

const hed6 = css`
  font-family: 'Inter UI';
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.015em;
`;

const titleLarge = css`
  font-family: 'Inter UI';
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.015em;
`;

const title = css`
  font-family: 'Inter UI';
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.01em;
`;

const head = css`
  font-family: 'Inter UI';
  font-weight: 600;
  font-style: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

const bodyLarge = css`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

const body = css`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.01em;
`;

const bodyTitle = css`
  font-family: 'Inter UI';
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
`;

const button = css`
  font-family: 'Inter UI';
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.013em;
`;

const subhead = css`
  font-family: 'Inter UI';
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 18.2px;
  letter-spacing: -0.01em;
`;

const caption = css`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18.2px;
  letter-spacing: -0.02em;
`;

const tiny = css`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.015em;
`;

export const InterFonts = {
  cardHeader,
  page,
  header,
  hed6,
  titleLarge,
  title,
  head,
  bodyLarge,
  body,
  bodyTitle,
  button,
  subhead,
  caption,
  tiny
};

export type InterFontsKey = keyof typeof InterFonts;
