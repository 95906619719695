import React from 'react';
import { EmailInputFields, EmailEditorTranslations } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormikProps } from 'formik';
import { FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import { StyledLabel, StyledTextArea } from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/EmailEditPanel.styles';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import PhotoSelector from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector';
import { EventPageFragment } from '@graphql/generated';
import { filterTidbitsPage } from '../PhotoSelector/PhotoSelector.controller';

export interface ThankYouEmailFormProps
  extends Readonly<{
    formik: FormikProps<EmailInputFields>;
    tEmailEditor: EmailEditorTranslations;
    handlePhotoSelect: (photoUrl: string, containerId?: string, assetId?: string) => void;
    pages?: ReadonlyArray<EventPageFragment>;
  }> {}

export const ThankYouEmailForm: React.FC<ThankYouEmailFormProps> = ({ formik, tEmailEditor, handlePhotoSelect, pages }) => {
  return (
    <>
      <FormControl label={<StyledLabel>{tEmailEditor.emailSubjectLabel}</StyledLabel>} error={formik.errors.subject} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('subject')} placeholder={tEmailEditor.emailSubjectPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('subject').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.replyToLabel}</StyledLabel>} error={formik.errors.replyToEmail} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('replyToEmail')} placeholder={tEmailEditor.emailSubjectPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('replyToEmail').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.photoLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <PhotoSelector
            allowCustomUpload
            containerId={formik.values.eventId}
            pages={filterTidbitsPage(pages)}
            handlePhotoSelect={({ previewUrl, assetId }) => handlePhotoSelect(previewUrl, formik.values.eventId, assetId)}
            photo={{ url: formik.values.imageUrl || '' }}
          />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('imageUrl').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.messageLabel}</StyledLabel>} error={formik.errors.message} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <StyledTextArea {...formik.getFieldProps('message')} placeholder={tEmailEditor.messagePlaceholder} resize={false} minRows={8} maxRows={8} tabIndex={0} minimal={true} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('message').error}
          </TextV2>
        </>
      </FormControl>
    </>
  );
};
