/**
 * Sort an array of DOM nodes according to the HTML tree order
 * @see http://www.w3.org/TR/html5/infrastructure.html#tree-order
 */
export function sortNodes(nodes: Node[]) {
  return [...nodes].sort((a, b) => {
    const compare = a.compareDocumentPosition(b);

    if (compare & Node.DOCUMENT_POSITION_FOLLOWING || compare & Node.DOCUMENT_POSITION_CONTAINED_BY) {
      // a < b
      return -1;
    }

    if (compare & Node.DOCUMENT_POSITION_PRECEDING || compare & Node.DOCUMENT_POSITION_CONTAINS) {
      // a > b
      return 1;
    }

    if (compare & Node.DOCUMENT_POSITION_DISCONNECTED || compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC) {
      throw Error('Cannot sort the given nodes.');
    } else {
      return 0;
    }
  });
}

export class DescendantsManager<T extends HTMLElement> {
  private descendants = new Map<T, { node: T; index: number }>();
  register = (node: T | null): void => {
    if (!node || this.descendants.has(node)) {
      return;
    }
    const nodeList = Array.from(this.descendants.keys()).concat(node);
    const sortedNodeList = sortNodes(nodeList);
    this.descendants.set(node, { node, index: -1 });
    this.assignIndices(sortedNodeList);
  };

  unregister = (node: T): void => {
    this.descendants.delete(node);
    const sortedNodeList = sortNodes(Array.from(this.descendants.keys()));
    this.assignIndices(sortedNodeList);
  };

  destroy = (): void => {
    this.descendants.clear();
  };

  private assignIndices = (nodeList: Node[]) => {
    this.descendants.forEach(descendant => {
      const nextIndex = nodeList.indexOf(descendant.node);
      descendant.index = nextIndex;
      descendant.node.dataset.index = nextIndex.toString();
    });
  };
}
