import { Box } from '@withjoy/joykit';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import React from 'react';
import { StyledEmailPhoto } from '../components';
import { addRendition } from '@shared/utils/photoRendition';
import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormikProps } from 'formik';
import { isValidUrl } from '@shared/utils/urlHelpers';
import MessageLoader from '../components/MessageLoader/MessageLoader';

interface Props
  extends Readonly<{
    formik: FormikProps<EmailInputFields>;
    isMessageLoading?: boolean;
  }> {}

export const ThankYouEmail: React.FC<Props> = props => {
  const { formik, isMessageLoading } = props;
  const { message, imageUrl } = formik.values;
  const hasImage = imageUrl && isValidUrl(imageUrl);

  /**
   * NOTE: This component looks funky, but it is coded in a way to closely resemble the way ReactMjml's layout components operate
   * In this way it should be easier to get to visual parity between the preview and the actual email
   */
  return (
    <>
      {hasImage && <StyledEmailPhoto src={addRendition({ url: imageUrl, renditionSize: 'medium' })} alt="email photo" />}
      {message && !isMessageLoading ? (
        <Box padding={hasImage ? '40px' : '16px 40px 40px'}>
          <Box color="brandWarmGray6" fontSize={15} lineHeight="24px" fontFamily={EmailFontFamilies.ibmPlexSans} style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </Box>
        </Box>
      ) : null}
      {isMessageLoading && <MessageLoader withParticles />}
    </>
  );
};
