import { ApplicatorConfigVariantsEcard, LayoutConfigEcard, LayoutThemeOverrides } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import chroma from 'chroma-js';
import { ColorSetFragment } from '@graphql/generated';
import { Theme, createTheme } from '@withjoy/joykit';
import { Colors, CreateThemeArgs } from '@withjoy/joykit/theme';
import { isInIframe } from '@shared/utils/isInIframe';
import memoizeOne from 'memoize-one';
import { googleFontFamilies } from '@apps/guest/packages/layout-engine/common/fonts';
import { mergeTypograhyThemeOverrides } from '@apps/guest/packages/layout-engine/common/theming';
import globalWindow from '@shared/core/globals';
import { parseColorPalette } from '@shared/utils/websiteDesign';

const isIframed = isInIframe();

const BREAKPOINT_DEFAULTS: CreateThemeArgs['defaults'] = {
  breakpointWidths: [isIframed ? 375 : 480, 960, 1024, 1440, 1920]
};

const _darkenColor = (color: string) => {
  // taken from monorepo
  return chroma(
    chroma(color)
      .rgb()
      .map(x => x * 0.8)
  ).hex();
};

const generateThemableColorValues = memoizeOne(
  (color?: string): DeepPartial<Colors> => {
    if (color) {
      const baseColorDarkened = _darkenColor(color);
      const colorHover = chroma(baseColorDarkened).brighten(0.2).hex();
      const colorActive = chroma(baseColorDarkened).darken(0.2).hex();

      return {
        linkVisited: chroma(baseColorDarkened).darken(0.3).hex(),
        linkText: baseColorDarkened,
        linkHover: colorHover,
        linkActive: colorActive,

        buttonPrimaryText: '#FFFFFF',
        buttonPrimaryFill: baseColorDarkened,
        buttonPrimaryHover: colorHover,
        buttonPrimaryActive: colorActive
      };
    }
    return {};
  }
);

export const getOverrides = (overrides: LayoutThemeOverrides = {}): DeepPartial<Theme> => {
  const { eventFontFamily = googleFontFamilies.greatVibes, textTransform } = overrides.typography || {};
  const { primaryFillColor } = overrides.colors || {};

  const themableColors: DeepPartial<Colors> = generateThemableColorValues(primaryFillColor);
  const typography = mergeTypograhyThemeOverrides({
    variants: {
      display1: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display2: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display3: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display4: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display5: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display6: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display7: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display8: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display9: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display10: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display11: {
        fontFamily: eventFontFamily,
        textTransform
      }
    }
  });
  return {
    typography,
    colors: {
      ...themableColors
    }
  };
};

export const ecardConfig: LayoutConfigEcard = {
  applicatorHtmlProps: {
    simple: {
      prop: { 'data-layer': 'simple' },
      selector: '[data-layer="simple"]'
    }
  },
  theme: createTheme({ defaults: BREAKPOINT_DEFAULTS, overrides: getOverrides() }), // extendTheme(getOverrides()),
  configureThemeOverrides: (eventDesign, currentTheme, useEventColor) => {
    const { font, colorPalette } = eventDesign;
    const [primaryFill] = colorPalette;
    const overrides = getOverrides({ typography: { eventFontFamily: font.fontFamily, textTransform: font.textTransform }, colors: { primaryFillColor: primaryFill.color.hex } });
    return createTheme({ defaults: BREAKPOINT_DEFAULTS, overrides });
  },
  fonts: {
    google: ['greatVibes']
  },
  previewEventHandlers: {
    onLayoutChange: () => {
      globalWindow.scrollTo?.(0, 0);
    },
    onThemeChange: () => {
      globalWindow.scrollTo?.(0, 0);
    },
    onFontChange: history => {
      globalWindow.scrollTo?.(0, 0);
    },
    onGraphicAccentChange: () => {
      const el = globalWindow.document?.getElementById('graphic-accent');
      el?.scrollIntoView();
    },
    onPrimaryFillColorChange: ({ history, eventPageRoutes }) => {
      if (eventPageRoutes.schedule) {
        const historySub = history.listen(() => {
          globalWindow.setTimeout(() => {
            const el = globalWindow.document?.getElementById('schedule');
            el?.scrollIntoView();
          }, 500);
          historySub();
        });
        history.push({ pathname: eventPageRoutes.schedule.path });
        return [historySub];
      }
      return [];
    }
  }
};

export const getEcardApplicatorConfig = (colorPalette: ReadonlyArray<ColorSetFragment>, useEventColor: boolean): ApplicatorConfigVariantsEcard => {
  const { primaryFillColor, primaryTextColor } = parseColorPalette(colorPalette);

  return {
    simple: {
      customBackgroundColor:
        primaryTextColor && primaryFillColor
          ? {
              isEnabled: useEventColor,
              textColor: chroma(primaryTextColor.color.hex).rgba(),
              backgroundColor: chroma(primaryFillColor.color.hex).rgba()
            }
          : undefined
    }
  };
};
