/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Invitation',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.00002 0.399994C3.11637 0.399994 2.40002 1.11634 2.40002 1.99999V22C2.40002 22.8836 3.11637 23.6 4.00002 23.6H20C20.8837 23.6 21.6 22.8836 21.6 22V1.99999C21.6 1.11634 20.8837 0.399994 20 0.399994H4.00002ZM3.60002 1.99999C3.60002 1.77908 3.77911 1.59999 4.00002 1.59999H20C20.2209 1.59999 20.4 1.77908 20.4 1.99999V22C20.4 22.2209 20.2209 22.4 20 22.4H4.00002C3.77911 22.4 3.60002 22.2209 3.60002 22V1.99999ZM15.2527 6.60417C14.3691 5.72053 13.0076 5.60589 12 6.26027C10.9924 5.60589 9.63097 5.72053 8.74733 6.60417C7.73197 7.61953 7.73197 9.26576 8.74733 10.2811L12 13.5338L15.2527 10.2811C16.2681 9.26576 16.2681 7.61953 15.2527 6.60417ZM12.4243 7.4527C12.971 6.90596 13.8575 6.90596 14.4042 7.4527C14.9509 7.99943 14.9509 8.88586 14.4042 9.4326L12 11.8368L9.59586 9.4326C9.04913 8.88586 9.04913 7.99943 9.59586 7.4527C10.1426 6.90596 11.029 6.90596 11.5758 7.4527L12 7.87696L12.4243 7.4527ZM9.84503 14.4C9.51365 14.4 9.24503 14.6686 9.24503 15C9.24503 15.3314 9.51365 15.6 9.84503 15.6H14.1527C14.4841 15.6 14.7527 15.3314 14.7527 15C14.7527 14.6686 14.4841 14.4 14.1527 14.4H9.84503ZM10.1143 16.9C10.1143 16.5686 10.3829 16.3 10.7143 16.3H13.2835C13.6149 16.3 13.8835 16.5686 13.8835 16.9C13.8835 17.2314 13.6149 17.5 13.2835 17.5H10.7143C10.3829 17.5 10.1143 17.2314 10.1143 16.9Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path d="M18.661 6.695a.6.6 0 1 0-1.149-.346l-.23.766a.6.6 0 1 0 1.149.346l.23-.766zm-8.166 8.395a.6.6 0 0 1-.338.778l-.744.293a.6.6 0 1 1-.44-1.116l.744-.293a.6.6 0 0 1 .778.338zm-.103 2.975a.6.6 0 1 0 .935.752l.919-1.143a.6.6 0 1 0-.935-.752l-.919 1.143zm3.416 1.606a.6.6 0 1 0 1.18.216l.144-.787a.6.6 0 1 0-1.181-.216l-.144.787zm8.135-12.148a.6.6 0 0 1 .054.847l-.969 1.101a.6.6 0 0 1-.901-.793l.969-1.101a.6.6 0 0 1 .847-.054zm.204 3.04a.6.6 0 1 0 .372 1.141l.761-.248a.6.6 0 1 0-.372-1.141l-.761.248z" />{' '}
          <path
            d="M13.922 15.149a3.67 3.67 0 0 1-3.103-3.977 3.67 3.67 0 0 1 7.259-.3 3.67 3.67 0 0 1 3.103 3.977 3.67 3.67 0 0 1-7.259.3zm-.013-1.223a3.67 3.67 0 0 1 2.945-3.033 2.47 2.47 0 0 0-4.841.394c-.12 1.246.709 2.364 1.896 2.639zm1.237 1.202a2.47 2.47 0 0 0 4.841-.394c.12-1.246-.709-2.364-1.896-2.639a3.67 3.67 0 0 1-2.945 3.033zm1.716-3.005a2.47 2.47 0 0 0-1.724 1.776 2.47 2.47 0 0 0 1.724-1.776zm9.072-8.455V16.52l2.428 1.867a.6.6 0 0 1 .234.472c.003.024.005.049.005.075v9.067c0 .7-.567 1.267-1.267 1.267H4.667c-.7 0-1.267-.567-1.267-1.267v-9.067a.6.6 0 0 1 .005-.074.6.6 0 0 1 .234-.472l2.428-1.867V3.668c0-.515.418-.933.933-.933h18c.515 0 .933.418.933.933zM10.436 23.793L7.267 21V3.934h17.467v17.064l-3.684 3.249-4.394-2.652c-.403-.243-.907-.243-1.309 0l-4.395 2.652-.516-.455zm-.561 1.105l-.232-.205L4.6 20.249v7.752c0 .025.014.047.035.058l5.239-3.162zm-2.931 3.17h18.114l-9.022-5.445c-.021-.013-.048-.013-.069 0l-9.022 5.445zm20.422-.008c.021-.011.035-.033.035-.058v-7.755l-5.274 4.651 5.239 3.162zm-1.432-8.12v-1.906l1.154.888-1.154 1.018zm-19.867.002l-1.156-1.018 1.156-.889v1.908z"
            fillRule="evenodd"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path d="M27.544 9.62c.099-.331-.088-.679-.419-.779s-.679.088-.778.418l-.504 1.676c-.099.331.088.679.418.779s.679-.088.779-.418l.504-1.676zM15.641 22.378c.127.321-.031.684-.352.811l-1.628.642c-.321.127-.684-.031-.811-.352s.031-.684.352-.811l1.628-.642c.321-.127.684.031.811.352zm.203 5.277c-.216.269-.173.662.096.879s.662.173.879-.096l1.723-2.144c.216-.269.173-.662-.096-.879s-.662-.173-.879.096l-1.723 2.144zm5.36 2.219c-.062.34.163.665.503.727s.665-.163.727-.503l.314-1.721c.062-.34-.163-.665-.503-.727s-.665.163-.727.503l-.314 1.721zm11.503-18.773c.259.228.284.623.056.882l-1.818 2.064c-.228.259-.623.284-.882.056s-.284-.623-.056-.882l1.818-2.064c.228-.259.623-.284.882-.056zm.423 5.351c-.328.107-.507.46-.4.788s.46.507.788.4l1.664-.543c.328-.107.507-.46.4-.788s-.46-.507-.788-.4l-1.663.543z" />{' '}
          <path
            d="M20.883 22.722a5.5 5.5 0 0 1 1.348-10.913c2.509.242 4.461 2.133 4.886 4.497a5.5 5.5 0 0 1-1.348 10.913c-2.509-.242-4.461-2.133-4.886-4.497zm-.078-1.284l.017-.221c.259-2.687 2.41-4.735 5.008-4.954a4.25 4.25 0 1 0-5.025 5.175zm1.364 1.327a4.25 4.25 0 1 0 5.025-5.175l-.017.221c-.259 2.687-2.41 4.736-5.008 4.954zm3.778-5.256a4.25 4.25 0 0 0-3.881 3.829l-.014.183a4.25 4.25 0 0 0 3.881-3.829l.014-.183zM38.625 5.5v19.415l3.749 2.883c.163.126.248.316.244.508a.63.63 0 0 1 .007.094V42c0 .898-.727 1.625-1.625 1.625H7c-.897 0-1.625-.727-1.625-1.625V28.399a.63.63 0 0 1 .007-.093.62.62 0 0 1 .244-.508l3.749-2.883V5.5c0-.621.504-1.125 1.125-1.125h27c.621 0 1.125.504 1.125 1.125zM15.471 35.894l-4.846-4.272V5.625h26.75V31.62l-5.771 5.089-6.764-4.083c-.516-.312-1.163-.312-1.679 0l-6.764 4.083-.925-.816zm-.197 1.493l-.63-.555-8.019-7.068V42c0 .207.168.375.375.375l8.274-4.989zm-5.847 4.989h29.146l-14.379-8.678c-.119-.072-.268-.072-.388 0L9.427 42.375zm31.573 0c.207 0 .375-.168.375-.375V29.759l-8.649 7.628L41 42.375zm.064-14.008l-2.439-1.875v4.026l2.439-2.151zM9.375 30.52l-2.442-2.151 2.442-1.878v4.029z"
            fillRule="evenodd"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
