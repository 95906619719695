import React from 'react';
import { MobileGuestMenu } from '../MobileGuestMenu';

export const MobileMainMenu: React.FC = () => {
  return (
    <>
      <MobileGuestMenu />
    </>
  );
};
