import React, { useMemo, useState } from 'react';
import { useFilestack } from '@shared/utils/filestack';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { OptionType } from '@withjoy/joykit';
import { useEmailEditorContext } from '../../../../EmailEditor.context';
import { PhotoSelectorProps } from './PhotoSelector';

export interface PhotoSelectorOptionType extends Pick<OptionType, 'label' | 'value' | 'key'> {
  alt: Maybe<string>;
  id?: string;
  width?: number;
  height?: number;
}

export const NO_PHOTO_OPTION_KEY = 'noPhoto';
export const CUSTOM_OPTION_KEY = 'custom';
export const UPLOAD_CUSTOM_KEY = 'upload';

export const filterTidbitsPage = (pages: ReadonlyArray<EventPageFragment> | undefined) => pages?.filter(page => page?.type !== EventPageType.tidbits);

type UsePhotoSelectorControllerArgs = Readonly<PhotoSelectorProps>;

export const loadImageDimensions = (imageUrl: string): Promise<{ width: number; height: number }> =>
  new Promise(resolve => {
    const image = new Image();

    image.onload = () => {
      const height = image.height;
      const width = image.width;
      resolve({ width, height });
    };

    image.src = imageUrl;
  });

export const usePhotoSelectorController = ({
  pages,
  handlePhotoSelect,
  allowCustomUpload = false,
  allowNoPhoto = true,
  containerId = '',
  photo
}: UsePhotoSelectorControllerArgs) => {
  const emailEditorContext = useEmailEditorContext();

  const [uploadedPhoto, setUploadedPhoto] = useState<{
    id?: string;
    url?: string;
    assetId?: string;
    width?: number;
    height?: number;
  }>(() => {
    const isPhotoCustom = photo && pages?.every(page => page.photo?.url !== photo.url);
    return isPhotoCustom ? photo : {};
  });

  const photoOptions: PhotoSelectorOptionType[] = useMemo(() => {
    const options = (pages || [])
      .filter(page => page?.photo?.url)
      .map(page => ({
        alt: page.photo!.altTag,
        label: page.pageTitle,
        value: page.photo!.url,
        key: page.pageSlug,
        id: page.photo?.id,
        width: page.photo?.width,
        height: page.photo?.height
      }));

    if (!allowCustomUpload && allowNoPhoto) {
      options.push({
        label: 'No Photo',
        alt: '',
        value: '',
        key: NO_PHOTO_OPTION_KEY,
        id: '',
        width: undefined,
        height: undefined
      });
    }
    if (allowCustomUpload && uploadedPhoto?.url) {
      options.push({
        label: 'Custom Photo',
        alt: 'custom',
        value: uploadedPhoto?.url,
        key: CUSTOM_OPTION_KEY,
        id: '',
        width: undefined,
        height: undefined
      });
    }

    return options;
  }, [pages, allowCustomUpload, allowNoPhoto, uploadedPhoto]);

  const [selectedPhotoOptionIndex, selectedPhotoOption] = useMemo(() => {
    if (photo?.url) {
      const indexFound = photoOptions.findIndex(option => option.value === photo?.url);
      const index = indexFound === -1 ? 0 : indexFound;
      return [index, photoOptions[index]];
    }

    const fallbackIndex = allowCustomUpload ? 0 : photoOptions.length - 1;

    return [fallbackIndex, photoOptions[fallbackIndex]];
  }, [photoOptions, photo, allowCustomUpload]);

  const { open, getPhotoPreviewUrl } = useFilestack({
    containerId,
    accept: ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff'],
    onUploadDone: async ({ filesUploaded }) => {
      const photo = filesUploaded?.[0];

      if (photo) {
        const previewUrl = await getPhotoPreviewUrl({ url: photo.url });
        const { width, height } = await loadImageDimensions(previewUrl);

        setUploadedPhoto({
          url: previewUrl,
          assetId: photo.filename,
          width,
          height
        });
        handlePhotoSelect({
          previewUrl,
          assetId: photo.filename,
          id: '',
          width,
          height
        });
      }
    },
    maxFiles: 1,
    onOpen: () => emailEditorContext?.setAllowSidePannedOutsideClickHandle(false),
    onClose: () => emailEditorContext?.setAllowSidePannedOutsideClickHandle(true)
  });

  const handleOnPhotoOptionChange = (photoOption: PhotoSelectorOptionType) => {
    if (photoOption.key === CUSTOM_OPTION_KEY) {
      if (uploadedPhoto?.url) {
        handlePhotoSelect({
          assetId: uploadedPhoto.assetId,
          previewUrl: uploadedPhoto.url as string,
          id: uploadedPhoto.id || '',
          width: uploadedPhoto.width,
          height: uploadedPhoto.height
        });
        return;
      }

      open();
      return;
    }

    if (photoOption.key === UPLOAD_CUSTOM_KEY) {
      open();
      return;
    }

    handlePhotoSelect({
      id: photoOption.id || '',
      assetId: '',
      previewUrl: photoOption.value,
      width: photoOption.width,
      height: photoOption.height
    });
  };

  const handleUpdatePhotoClick = (e?: React.SyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();

    open();
  };

  return {
    photoOptions,
    selectedPhotoOption,
    selectedPhotoOptionIndex,
    handleOnPhotoOptionChange,
    handleUpdatePhotoClick
  };
};
