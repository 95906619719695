import { AiModelVersion, useChatGPTTextCompletionQuery } from '@graphql/generated';
import { usePostOfficeTelemetry } from '@apps/postOffice/PostOffice.telemetry';

interface ThankYouNoteParams {
  tone?: string;
  context?: string;
  user: string;
  name?: string;
  items?: string;
  shortenProductNames?: boolean;
  skip?: boolean;
  hasSecondName?: boolean;
  onCompleted: (note: string) => void;
}

const useGenerateThankYouNote = ({
  tone = 'friendly',
  context = '',
  name = 'my friend',
  user,
  items = 'gift',
  shortenProductNames = false,
  skip,
  hasSecondName = false,
  onCompleted
}: ThankYouNoteParams) => {
  const { sendThankYouPrompt } = usePostOfficeTelemetry();
  const shortenPart = 'Shorten product names.';
  const prompt = `Simple first-person ${hasSecondName ? 'plural' : 'singular'} ${tone} ${context} thank you note to ${name} from ${user} for the ${items}. ${
    shortenProductNames ? shortenPart : ''
  }`;

  const { loading: isGeneratedMessageLoading } = useChatGPTTextCompletionQuery({
    variables: {
      prompt,
      modelVersion: AiModelVersion.gpt35turboInstruct
    },
    skip,
    batchMode: 'off',
    onCompleted(data) {
      onCompleted(data.textCompletion?.substring(2));
      sendThankYouPrompt(prompt);
    },
    onError() {
      sendThankYouPrompt(prompt);
    }
  });

  return { isGeneratedMessageLoading };
};

export default useGenerateThankYouNote;
