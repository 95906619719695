import React, { useLayoutEffect, useRef } from 'react';
import { TextLayerData } from '../../components/Layer.types';
import { textLayerDataToCSS } from '../../components/TextLayer';

interface TextMeasurerProps {
  layer: TextLayerData;
  onHeightChange?: (height: number) => void;
}

export const TextMeasurer = (props: TextMeasurerProps) => {
  const { layer, onHeightChange } = props;

  const boxRef = useRef<HTMLDivElement>(null);
  const onHeightChangeRef = useRef<TextMeasurerProps['onHeightChange']>();
  onHeightChangeRef.current = onHeightChange;
  useLayoutEffect(() => {
    const element = boxRef.current;
    if (!element) return;
    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      onHeightChangeRef.current?.(element.clientHeight);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div
      ref={boxRef}
      style={{
        ...textLayerDataToCSS(layer.textData),
        position: 'absolute',
        width: layer.layout.width,
        pointerEvents: 'none',
        userSelect: 'none', // Otherwise resize will cause blue range highlights.
        visibility: 'hidden'
      }}
    >
      {`${layer.textData.content}\n`}
    </div>
  );
};
