import React, { useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { ECardDraft, SaveTheDateEmailInputFields } from '../../SaveTheDate.types';
import { SaveTheDateAction, useSaveTheDateReducer } from '../SaveTheDate.reducer';
import { SaveTheDateStateContext, SaveTheDateDispatchContext } from '.';
import { ApolloError } from '@apollo/client';
import { useCreateDispatchHandlers } from './SaveTheDate.dispatch';
import * as ACTIONS from '../SaveTheDate.actions';
import { useSaveTheDateGetEcardDataQuery, SaveTheDateGetEcardDataQuery } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE, COUPLE_NAME_FORMATS, DISPLAY_DATE_FORMATS, LOCATION_FORMATS } from '@apps/saveTheDate/constants';
import { useEcardTranslations, DefaultTranslations } from '@apps/ecard/Ecard.i18n';
import { getReferrer } from '@apps/postOffice/PostOffice';
import { useQueryParamHelper } from '@shared/core/queryString';

export type ProcessingState = boolean | { error: ApolloError };

export type SaveTheDateState = Readonly<{
  eventId: string;
  hasInitializedOnce: boolean;
  currentUserName: string;
  isNavBarOpen: boolean;
  isAdjustingPosition: boolean;
  isEditPanelOpen: boolean;
  eCardDraft: ECardDraft;
  emailDraft: SaveTheDateEmailInputFields;
  eCardDataQuery: SaveTheDateGetEcardDataQuery | undefined;
  eventHandle: string;
  eventDisplayName: string;
  testEmail: string;
  emailIsVerified: boolean;
  isQueryLoading: boolean;
  adminReferrer: string;
}>;

interface GetInitialStateArgs {
  eventId: string;
  eventHandle: string;
  testEmail: string;
  emailIsVerified: boolean;
  eventDisplayName: string;
  translations: DefaultTranslations;
  adminReferrer: string;
}

const getInitialState: ({ eventHandle, testEmail, emailIsVerified, eventDisplayName, translations, eventId, adminReferrer }: GetInitialStateArgs) => SaveTheDateState = args => ({
  eventId: args.eventId,
  hasInitializedOnce: false,
  currentUserName: '',
  isNavBarOpen: true,
  isAdjustingPosition: false,
  isEditPanelOpen: false,
  eCardDraft: {
    id: '',
    title: args.translations.ecardContent.title,
    subTitle: args.translations.ecardContent.subtitle,
    message: args.translations.ecardContent.message,
    linkOptionPrimary: 'calendar',
    linkOptionSecondary: 'website',
    locationString: '',
    displayDateFormat: DISPLAY_DATE_FORMATS[1],
    displayDateString: '',
    displayEventLink: true,
    recipientIds: [],
    type: SAVE_THE_DATE_PHOTO_ECARD_TYPE,
    photoEcardTheme: 'goldBorderCursive',
    coupleNameFormat: COUPLE_NAME_FORMATS[0],
    locationStringFormat: LOCATION_FORMATS[1]
  },
  emailDraft: {
    id: '',
    subheadline: args.translations.emailContent.subheadline,
    salutation: args.translations.emailContent.salutation,
    message: args.translations.emailContent.message,
    imageUrl: undefined,
    buttonText: args.translations.emailContent.buttonText,
    subject: args.translations.emailContent.subject,
    headline: args.translations.emailContent.headline,
    replyToEmail: args.testEmail,
    includeGuestNames: false,
    includeEventDate: false,
    includeAddToCalendarLink: false
  },
  eCardDataQuery: undefined,
  eventHandle: args.eventHandle,
  eventDisplayName: args.eventDisplayName,
  testEmail: args.testEmail,
  emailIsVerified: args.emailIsVerified,
  isQueryLoading: true,
  adminReferrer: args.adminReferrer
});

const SaveTheDateDispatchContextProvider: React.FC<{
  eventId: string;
  dispatch: React.Dispatch<SaveTheDateAction>;
  ownerFirstName: string;
  fianceeFirstName?: string;
  eventHandle: string;
  eventPassword: string;
  location: string;
  date: string;
}> = ({ eventId, dispatch, ownerFirstName, fianceeFirstName, eventHandle, children, eventPassword, location, date }) => {
  const dispatchProviderValue = useCreateDispatchHandlers(eventId, dispatch, ownerFirstName, fianceeFirstName, eventHandle, eventPassword, location, date);

  return <SaveTheDateDispatchContext.Provider value={dispatchProviderValue}>{children}</SaveTheDateDispatchContext.Provider>;
};

interface SaveTheDateProviderProps
  extends Readonly<{
    eventId: string;
    eventHandle: string;
    eventDisplayName: string;
    children: React.ReactNode;
  }> {}

export const SaveTheDateProvider: React.FC<SaveTheDateProviderProps> = ({ eventId, eventHandle, eventDisplayName, children }) => {
  const {
    currentUser: { profile: userProfile }
  } = useAuth();
  const { data, loading: isQueryLoading } = useSaveTheDateGetEcardDataQuery({
    batchMode: 'off',
    fetchPolicy: 'no-cache',
    variables: { eventId, ecardType: SAVE_THE_DATE_PHOTO_ECARD_TYPE }
  });
  const { getEcardDefaultTranslations } = useEcardTranslations({ ecardDraftType: SAVE_THE_DATE_PHOTO_ECARD_TYPE });
  const translations = getEcardDefaultTranslations(eventDisplayName);
  const { getValueString } = useQueryParamHelper();
  const referrer = getValueString('referrer');
  let adminReferrer = getReferrer();
  if (referrer) {
    adminReferrer = referrer;
  }
  const [state, dispatch] = useImmerReducer<SaveTheDateState, SaveTheDateAction>(
    useSaveTheDateReducer,
    getInitialState({
      eventId,
      eventHandle,
      testEmail: userProfile?.email || '',
      emailIsVerified: !!userProfile?.emailIsVerified,
      eventDisplayName,
      translations,
      adminReferrer
    })
  );
  const { hasInitializedOnce, testEmail, emailDraft, eCardDraft } = state;

  useEffect(() => {
    if (data && !hasInitializedOnce) {
      dispatch({
        type: ACTIONS.INITIALIZE_SAVE_THE_DATE_STATE,
        payload: { queryResult: data, isQueryLoading }
      });
      if (data?.getMostRecentEcardByEventId) {
        const draft = data.getMostRecentEcardByEventId;

        dispatch({
          type: ACTIONS.SAVE_ECARD_DRAFT,
          payload: {
            eCardDraft: {
              ...eCardDraft,
              ...draft
            }
          }
        });
      }

      if (data?.getEcardEmailDraftsByEventId?.length) {
        const draft = data.getEcardEmailDraftsByEventId[0];
        dispatch({
          type: ACTIONS.SAVE_EMAIL_DRAFT,
          payload: {
            emailDraft: {
              subject: draft.subject,
              replyToEmail: draft.replyToEmail || testEmail,
              headline: draft.title,
              salutation: draft.salutation,
              includeGuestNames: draft.includeGuestNames,
              message: draft.message,
              imageUrl: draft.imageUrl,
              subheadline: draft.subTitle,
              includeEventDate: draft.includeDate,
              includeAddToCalendarLink: draft.includeAddToCalendar,
              buttonText: draft.buttonText
            }
          }
        });
      } else {
        // if there's no saved email draft, we won't have image url
        dispatch({
          type: ACTIONS.SAVE_EMAIL_DRAFT,
          payload: {
            emailDraft: {
              ...emailDraft,
              imageUrl: data?.eventById?.photo?.url
            }
          }
        });
      }
    }
  }, [data, dispatch, hasInitializedOnce, testEmail, emailDraft, eCardDraft, isQueryLoading]);

  return (
    <SaveTheDateStateContext.Provider value={state}>
      <SaveTheDateDispatchContextProvider
        eventId={eventId}
        dispatch={dispatch}
        ownerFirstName={data?.eventById?.info?.ownerFirstName || ''}
        fianceeFirstName={data?.eventById?.info?.fianceeFirstName || ''}
        eventHandle={eventHandle}
        eventPassword={data?.eventById?.info?.eventPassword || ''}
        location={data?.eventById?.info?.location || ''}
        date={data?.eventById?.info?.date || ''}
      >
        {children}
      </SaveTheDateDispatchContextProvider>
    </SaveTheDateStateContext.Provider>
  );
};
