import React, { useMemo } from 'react';
import { Flex, FlexProps } from '@withjoy/joykit';
import { addDays, format } from 'date-fns';
import { ShippingTruckSvg } from '@apps/registry/admin/assets';

const JOY_HOLD_DAYS = 1;
const VENDOR_PROCESSING_DAYS = 3;
const FASTEST_DELIVERY_DAYS = 2; // UPS 2nd Day air

export const BestDeliveryDayEstimation: React.FC<FlexProps> = props => {
  const formattedDate = useMemo(() => {
    const date = addDays(new Date(), JOY_HOLD_DAYS + VENDOR_PROCESSING_DAYS + FASTEST_DELIVERY_DAYS);
    const formattedDate = format(date, 'LLLL do');
    return formattedDate;
  }, []);

  return (
    <Flex justifyContent="center" gap={3} alignItems="center" {...props}>
      <ShippingTruckSvg />
      Get it as early as {formattedDate}
    </Flex>
  );
};
