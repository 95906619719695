import React, { useEffect } from 'react';
import { BookingDialog, useTravelBookingDialogState, DashboardTelemetryProvider, enrichDashboardTelemetryExtraInfo } from '@apps/admin/routes/Dashboard';

interface TravelBookingDialogCrossSellProps {
  eventId: string;
  eventHandle: string;
}

const Root = ({ eventHandle }: TravelBookingDialogCrossSellProps) => {
  const {
    hotelBlockEnabledValue,
    isBookingDialogOpen,
    isLoadingBookingDataOrWaitingAssignment,
    onBookingDialogClose,
    openTravelBookingDialog,
    shouldPresentTravelBookingDialog
  } = useTravelBookingDialogState(eventHandle);

  useEffect(() => {
    if (isLoadingBookingDataOrWaitingAssignment) {
      // handles both loading and unassigned states
      return;
    }

    if (hotelBlockEnabledValue === 'on' && shouldPresentTravelBookingDialog) {
      enrichDashboardTelemetryExtraInfo({ categoryOverride: 'admin.messaging.card' });
      openTravelBookingDialog();
    }
  }, [openTravelBookingDialog, hotelBlockEnabledValue, shouldPresentTravelBookingDialog, isLoadingBookingDataOrWaitingAssignment]);

  return <BookingDialog isOpen={isBookingDialogOpen} onClose={onBookingDialogClose} sourceIsBanner={false} openIntroStepLinkInNewTab onIntroStepLinkClick={onBookingDialogClose} />;
};

/**
 * TODO: Revisit this component when implementing public print shop
 */
export const TravelBookingDialogCrossSell = (props: TravelBookingDialogCrossSellProps) => {
  return (
    <DashboardTelemetryProvider context={{ eventId: props.eventId }}>
      <Root {...props} />
    </DashboardTelemetryProvider>
  );
};
