import { useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from '@shared/core';
import type { EmailInputFields, EmailType } from '@shared/components/EmailsAndEcards/Emails.types';
import { getFormikValidationSchema } from '@shared/components/EmailsAndEcards/Emails.utils';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { EcardType } from '@graphql/generated';
interface UseEmailEditorControllerArgs
  extends Readonly<{
    emailInput: EmailInputFields;
    emailType: EmailType;
    updateEmailInput?: (emailInput: EmailInputFields) => void;
    isMessageLoading?: boolean;
    editEmailContentTrack?: (ecardType: EcardType) => void;
    ecardDraftType?: EcardType;
  }> {}

export const useEmailEditorController = (args: UseEmailEditorControllerArgs) => {
  const { emailInput, emailType, updateEmailInput, isMessageLoading, editEmailContentTrack, ecardDraftType } = args;
  const { editEmailTrack, ...actions } = useEcardsTelemetry();
  const { setParentViewState } = useBottomSheetState();

  const { t2 } = useTranslation('emailsAndEcards');
  const tEmailEditor = t2('emailEditor');

  const formikValidationSchema = useMemo(() => getFormikValidationSchema(emailType, tEmailEditor), [emailType, tEmailEditor]);

  const emailFormik = useFormik<EmailInputFields>({
    initialValues: emailInput,
    validationSchema: formikValidationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (!!updateEmailInput) {
        updateEmailInput(values);
      }
    }
  });

  const [isEmailEditPanelShown, setIsEmailEditPanelShown] = useState<boolean>(false);
  const [isUnsavedChnangesDialogOpen, setIsUnsavedChnangesDialogOpen] = useState<boolean>(false);

  const disableEditEmail = emailType === 'thankyou' && isMessageLoading;

  const handleToggleEmailEditPanel = () => {
    if (!disableEditEmail) {
      actions.launchEmailEditor();

      if (!isEmailEditPanelShown && ecardDraftType) {
        editEmailContentTrack?.(ecardDraftType);
      }

      setIsEmailEditPanelShown(!isEmailEditPanelShown);
    }
  };

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, openEmailEditor: handleToggleEmailEditPanel, disableEditEmail }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableEditEmail]);

  const onCancel = () => {
    actions.editEmailCancelClick();
    emailFormik.resetForm();
    setIsEmailEditPanelShown(false);
  };

  const handleCancel = () => {
    if (emailFormik.dirty) {
      setIsUnsavedChnangesDialogOpen(true);
    } else {
      onCancel();
    }
  };

  const handleUnsavedChnangesDialogClose = () => {
    setIsUnsavedChnangesDialogOpen(false);
  };

  const handleUnsavedChnangesDialogConfirm = () => {
    handleUnsavedChnangesDialogClose();
    onCancel();
  };

  useEffect(() => {
    editEmailTrack.enter(ecardDraftType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleToggleEmailEditPanel,
    handleCancel,
    isEmailEditPanelShown,
    emailFormik,
    isUnsavedChnangesDialogOpen,
    handleUnsavedChnangesDialogClose,
    handleUnsavedChnangesDialogConfirm
  };
};
