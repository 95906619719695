import { styled } from '@withjoy/joykit';
import { Flex } from '@withjoy/joykit';

type Side = 'left' | 'right';

export const NAV_HEIGHT = 96;

export const StyledButton = styled.button<{ $side: Side }>`
  position: absolute;
  ${({ $side }) => $side}: 0;

  &:disabled {
    pointer-events: none;
    opacity: 50%;
  }
`;

export const Dot = styled.div<{ $isActive: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${({ $isActive }) => ($isActive ? '#333333' : '#D9D9D9')};
  margin: 0 8px;
  border-radius: 50%;
`;

export const DotsContainer = styled(Flex)`
  position: absolute;
`;
