import { CheckboxV2, Flex, TextV2 } from '@withjoy/joykit';
import React, { useContext, useState } from 'react';
import { PreviewContext, PreviewContextPayload, supportedModels } from '../ImagineYourCardRoot';
import { DefaultImageStyles } from '../../styles';
import { useFeatureValue } from '@shared/core/featureFlags';

type StylesList = Array<string>;
type StylesPayload = {
  styles: StylesList;
};

const isValidPayload = (payload: unknown): payload is StylesPayload => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  if (!Object.hasOwn(payload, 'styles')) {
    return false;
  }

  if (!isValidStylesList((payload as StylesPayload).styles)) {
    return false;
  }
  return true;
};

const isValidStylesList = (list: unknown): list is StylesList => {
  if (!Array.isArray(list)) {
    return false;
  }
  if (list.length == 0) {
    return false;
  }
  return true;
};

export const ControlCenter = () => {
  const { mOverride, setMOverride, oomphOverride, setOomphOverride, styleOverride, setStyleOverride, themes, cardType, themeOverride, setThemeOverride } = useContext(
    PreviewContext
  ) as PreviewContextPayload;

  const [hidden, setHidden] = useState(false);
  const { value, payload } = useFeatureValue('printImagineStyles');

  let stylesList = DefaultImageStyles.map(style => style.label);
  if (value === 'on' && isValidPayload(payload)) {
    stylesList = payload.styles;
  }

  const models = Object.keys(supportedModels).filter(key => isNaN(Number(key)));
  const filteredThemes = themes.filter(theme => {
    return theme.category === cardType;
  });

  if (hidden) {
    return null;
  }

  return (
    <Flex flexDirection="column" padding="12px 64px 12px 64px" gap="0.5rem">
      <Flex gap="0.5rem">
        <TextV2 typographyVariant="hed6">Admin Controls</TextV2>
        <TextV2
          typographyVariant="body2"
          lineHeight={2}
          onClick={() => {
            setHidden(true);
          }}
        >
          (Hide)
        </TextV2>
      </Flex>
      <Flex gap="1.5rem">
        <Flex gap="0.5rem">
          <TextV2>Model: </TextV2>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            value={mOverride}
            onChange={e => {
              setMOverride((e.target.value as unknown) as supportedModels);
            }}
          >
            <option key={'none'} value={'none'}>
              Server default
            </option>
            {models.map((model, index) => {
              return (
                <option key={model} value={model}>
                  {model}
                </option>
              );
            })}
          </select>
        </Flex>
        <Flex gap="0.5rem">
          <TextV2>Text prompt strength: </TextV2>
          <input
            placeholder={oomphOverride === 'none' ? 'Server default' : oomphOverride.toString()}
            value={oomphOverride === 'none' ? 'Server default' : oomphOverride.toString()}
            onChange={e => {
              setOomphOverride((e.target.value as unknown) as number);
            }}
          ></input>
        </Flex>
        <Flex gap="0.5rem">
          <CheckboxV2
            onChange={e => {
              if (e.target.checked) {
                setStyleOverride(stylesList[0]);
              } else {
                setStyleOverride(null);
              }
            }}
          >
            Force one style?
          </CheckboxV2>
          {styleOverride !== null && (
            // eslint-disable-next-line jsx-a11y/no-onchange
            <select
              onChange={e => {
                setStyleOverride(e.target.value);
              }}
            >
              {stylesList.map((style, index) => {
                return (
                  <option key={index} value={style}>
                    {style}
                  </option>
                );
              })}
            </select>
          )}
        </Flex>
        <Flex gap="0.5rem">
          <CheckboxV2
            onChange={e => {
              if (e.target.checked) {
                setThemeOverride(filteredThemes[0].themeID);
              } else {
                setThemeOverride(null);
              }
            }}
          >
            Force one theme?
          </CheckboxV2>
          {themeOverride !== null && (
            // eslint-disable-next-line jsx-a11y/no-onchange
            <select
              onChange={e => {
                setThemeOverride(e.target.value);
              }}
            >
              {filteredThemes.map((theme, index) => {
                return (
                  <option key={index} value={theme.themeID}>
                    {theme.themeID}
                  </option>
                );
              })}
            </select>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
