import { useCallback } from 'react';
import { CARDS_BASE_PATH } from '@apps/card/Card.routes';
import { useFeatureFlagsContext } from '@shared/core/featureFlags';
import { useRouteMatch } from '@react-router';
import { withWindow } from '@shared/utils/withWindow';

const AI_ROUTE_PATH = `${CARDS_BASE_PATH}/imagine`;

export const AUTH_REDIRECT_PARAM = 'isComingFromAuth';

const createURL = (win: Window, state: string | null, redirectPath: string) => {
  const url = new URL(win.location.href);
  url.pathname = redirectPath;
  const searchParams = url.searchParams;
  if (state) {
    searchParams.set('state', state);
  }
  searchParams.set(AUTH_REDIRECT_PARAM, 'true');
  return url.href;
};

const useAuthRedirectBasePath = () => {
  const isAiImagineRoute = !!useRouteMatch(AI_ROUTE_PATH);
  const redirectPath = isAiImagineRoute ? AI_ROUTE_PATH : CARDS_BASE_PATH;
  return redirectPath;
};

export const useAuthRedirectURLs = () => {
  const redirectPath = useAuthRedirectBasePath();
  const { formatToUrl } = useFeatureFlagsContext();

  return useCallback(
    (successState: string, failState: string) => {
      return {
        success: formatToUrl(createURL(window, successState, redirectPath)),
        failure: formatToUrl(createURL(window, failState, redirectPath))
      };
    },
    [formatToUrl, redirectPath]
  );
};

export const useAuthLogoutRedirectURL = () => {
  const redirectPath = useAuthRedirectBasePath();
  const { formatToUrl } = useFeatureFlagsContext();
  return withWindow(global => {
    return formatToUrl(createURL(global, null, redirectPath));
  }, undefined);
};
