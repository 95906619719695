import { PostOfficeDraftFragment, PostOfficeMessageType } from '@graphql/generated';
import { postOfficeDefaults } from './PostOfficeDefaults';
import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOfPostOfficeMessageType = (messageType: any) => Object.values(PostOfficeMessageType).includes(messageType);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPostOfficeDraftType = (draftType: any) =>
  ((isOfPostOfficeMessageType(draftType) as unknown) as PostOfficeMessageType) ? (draftType as PostOfficeMessageType) : PostOfficeMessageType.generic;

export const emailStateDefault: EmailInputFields = {
  eventId: '',
  draftId: '',
  subject: '',
  replyToEmail: '',
  headline: '',
  salutation: '',
  includeGuestNames: false,
  message: '',
  includeAppInstructions: true,
  includeWebsiteDetails: true,
  includeEventPassword: false,
  testName: '',
  testEmail: '',
  imageUrl: ''
};

export const getPostOfficeEmailInput = (
  eventId: string,
  draftType: PostOfficeMessageType,
  replyToEmail: string,
  showPasswordToggle: boolean,
  eventDisplayName: string,
  eventPhotoUrl?: string,
  draftData?: PostOfficeDraftFragment
) => {
  const defaultValues = postOfficeDefaults[draftType];
  const salutation = defaultValues?.salutation;

  let initialValues = {
    draftId: '',
    subject: defaultValues?.subject || '',
    replyToEmail: replyToEmail,
    headline: eventDisplayName,
    salutation,
    includeGuestNames: true,
    message: defaultValues?.message,
    includeAppInstructions: !!defaultValues?.includeAppInstructions,
    includeWebsiteDetails: !!defaultValues?.includeWebsiteDetails,
    includeEventPassword: !!(showPasswordToggle && defaultValues?.includeAppInstructions),
    imageUrl: eventPhotoUrl,
    assetId: undefined as Maybe<string>,
    containerId: undefined as Maybe<string>
  };
  if (!!draftData) {
    initialValues = {
      ...initialValues,
      draftId: 'id' in draftData ? draftData.id : '',
      subject: 'subject' in draftData ? draftData.subject : '',
      replyToEmail: 'replyToEmail' in draftData ? draftData.replyToEmail : '',
      headline: eventDisplayName,
      message: 'message' in draftData ? draftData.message : '',
      includeAppInstructions: 'includeAppInstructions' in draftData ? draftData.includeAppInstructions : !!defaultValues?.includeAppInstructions,
      includeWebsiteDetails: 'includeWebsiteDetails' in draftData ? draftData.includeWebsiteDetails : !!defaultValues?.includeWebsiteDetails,
      includeEventPassword:
        ('includeAppInstructions' in draftData && showPasswordToggle && draftData.includeAppInstructions) ||
        ('includeEventPassword' in draftData && showPasswordToggle && draftData.includeEventPassword),
      salutation: ('salutation' in draftData ? draftData.salutation : null) ?? salutation,
      includeGuestNames: 'includeGuestNames' in draftData ? draftData.includeGuestNames : initialValues.includeGuestNames,
      imageUrl: 'imageUrl' in draftData ? draftData?.imageUrl || '' : '',
      assetId: 'assetId' in draftData ? draftData.assetId : undefined,
      containerId: 'containerId' in draftData ? draftData.containerId : undefined
    };
  }

  return {
    eventId: eventId,
    ...initialValues
  };
};

export const getPostOfficeEmailPayload = (emailInputState: EmailInputFields, footerText?: string, testEmail?: string, testName?: string) => {
  return {
    ...emailInputState,
    includeAppInstructions: !!emailInputState.includeAppInstructions,
    includeWebsiteDetails: !!emailInputState.includeWebsiteDetails,
    includeEventPassword: !!emailInputState.includeEventPassword,
    message: emailInputState.message || '',
    testEmail,
    testName,
    footerText
  };
};

export type PostOfficeEmailPayload = ReturnType<typeof getPostOfficeEmailPayload>;
