import React from 'react';

type Props = {
  action: 'lead' | 'signup';
};

export const PixelImage: React.FC<Props> = props => {
  return <img src={getImageSourceFromProps(props)} alt={`conversion pixel - ${props.action}`} width="1" height="1" />;
};

export const createAndAttachPixelImage = (props: Props) => {
  const img = document.createElement('img');

  img.setAttribute('src', getImageSourceFromProps(props));
  img.setAttribute('width', '1');
  img.setAttribute('height', '1');
  document.body.appendChild(img);
};

const getImageSourceFromProps = ({ action }: Props, prefix = 'https://') => `${prefix}arttrk.com/pixel/?ad_log=referer&action=${action}&pixid=aad0d74e-06ee-4d0e-8000-408c8e31396a`;
