import { ElectronicCardFragment, ElectronicCardCardLinkOptionFragment, EcardType, GetEcardGuestExtraDataQuery } from '@graphql/generated';
import { getAdditionalLink, getEventWebsiteUrl } from '@apps/ecard/Ecard.utils';
import { EcardEditorThemeType } from '@apps/ecard/Ecard.types';

export const getFormattedEcardData = (
  data: ElectronicCardFragment,
  linkOptions: ElectronicCardCardLinkOptionFragment[],
  extraData: GetEcardGuestExtraDataQuery,
  domain?: string,
  website?: string
) => {
  return {
    id: data.id,
    displayEventLink: !!data.displayEventLink,
    title: data.title,
    subtitle: data.subTitle || '',
    message: data.message || '',
    additionalMessage: data.additionalMessage || '',
    location: data.locationString || '',
    eventId: data.eventId,
    joyTheme: data.design?.theme ?? undefined,
    dateFormat: data.displayDateFormat ?? '',
    date: data.type === EcardType.custom ? data.displayDateString : extraData.eventById?.info.finalizedDate?.timestamp ?? '',
    additionalLink1: data.linkOptionPrimary ? getAdditionalLink(data.linkOptionPrimary, linkOptions) : undefined,
    additionalLink2: data.linkOptionSecondary ? getAdditionalLink(data.linkOptionSecondary, linkOptions) : undefined,
    ecardDraftType: data.type ? EcardType[data.type as keyof typeof EcardType] : EcardType['savethedate'],
    selectedThemeType: EcardEditorThemeType.JOY,
    useMyColor: !!data.design?.shouldUseEventDesign,
    yourUrl: getEventWebsiteUrl(website, domain),
    guidId: data.guidId ? data.guidId : undefined
  };
};
