import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './Simplicity.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('simplicity')?.assetUrl || '';

export const Simplicity: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={61.5} left={89.5} width={321} height={421} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex width="100%" height="198px" padding="20px 30px" flexDirection="column" alignItems="center" justifyContent="flex-start">
          <TextV2
            color="#333"
            textAlign="center"
            fontSize={isFullNamesFormat ? 16 : 24}
            fontFamily="Playfair Display"
            fontWeight={600}
            lineHeight={isFullNamesFormat ? '21px' : '32px'}
            letterSpacing="0.1em"
            textTransform="uppercase"
            marginBottom={isFullNamesFormat ? '20px' : '26px'}
          >
            {formattedOwnerName}
            {formattedFianceeName ? ' & ' : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="center">
              {formattedDate ? (
                <TextV2 color="#333" textAlign="center" fontSize={13} fontFamily="Lato" fontWeight={400} lineHeight="16px" letterSpacing="0.1em">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="#333" textAlign="center" fontSize={13} fontFamily="Lato" fontWeight={400} lineHeight="16px" letterSpacing="0.1em">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="#333" textAlign="center" fontSize={13} fontFamily="Lato" fontWeight={400} lineHeight="16px" letterSpacing="0.1em">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
