import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './ArtDecoNavy.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('artDecoNavy')?.assetUrl || '';

export const ArtDecoNavy: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={0} left={0} width={500} height={321} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex width="100%" height="350px" padding="30px 30px" flexDirection="column" alignItems="center" justifyContent="flex-start">
          <TextV2
            color="white"
            textAlign="center"
            fontSize={isFullNamesFormat ? 16 : 30}
            fontFamily="Poiret One"
            fontWeight={400}
            lineHeight={isFullNamesFormat ? '39px' : '50px'}
            letterSpacing="0.4em"
            textTransform="uppercase"
            marginBottom={isFullNamesFormat ? '50px' : '35px'}
          >
            {formattedOwnerName}
            {formattedFianceeName ? (
              <>
                <br />
                {` & `}
                <br />
              </>
            ) : (
              ''
            )}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="center">
              {formattedDate ? (
                <TextV2 color="white" textAlign="center" fontSize={13} fontFamily="Poiret One" fontWeight={400} lineHeight="15px" letterSpacing="0.2em">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="white" textAlign="center" fontSize={13} fontFamily="Poiret One" fontWeight={400} lineHeight="15px" letterSpacing="0.2em">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="white" textAlign="center" fontSize={13} fontFamily="Poiret One" fontWeight={400} lineHeight="15px" letterSpacing="0.2em" marginTop="20px !important">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
