import { animationTransition } from '@shared/utils/animationTransition';
import { InterFonts } from '@shared/utils/fonts/interFonts';
import { Flex, styled, TextV2 } from '@withjoy/joykit';

export const ToggleWrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 4px;

  .switch {
    min-width: 56px;
    height: 32px;
    background-color: #ebebeb;
    border-radius: 16px;
    transition: ${animationTransition('background-color')};
    display: flex;
    align-items: center;

    span {
      height: 24px;
      width: 24px;
      border-radius: 12px;
      box-shadow: 0px 1px 5px -1px rgba(44, 41, 37, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.3);
      background: white;
      transform: translateX(4px);
      transition: ${animationTransition('transform')};
    }

    &.switch-active {
      background-color: #502080;

      span {
        transform: translateX(28px);
      }
    }
  }
`;

export const ToggleTitle = styled(TextV2)`
  ${InterFonts.bodyTitle}
`;

export const ToggleDesc = styled(TextV2)`
  ${InterFonts.caption}
  color: ${props => props.theme.colors.mono12};
`;

export const YourURLBox = styled(Flex)`
  ${InterFonts.body}
  width: 100%;
  height: 48px;
  padding: 0 16px;
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.colors.mono2};
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 4px;
`;

export const SelectorHiddenBox = styled(Flex)`
  width: 100%;
  height: 56px;
  padding: 0 18px;
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.colors.mono2};
  border: 1px solid ${({ theme }) => theme.colors.mono5};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.typography.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.variants.body2.fontWeight};
  line-height: ${({ theme }) => theme.typography.variants.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.variants.body2.letterSpacing};
`;

export const YourURLText = styled(TextV2)`
  color: ${props => props.theme.colors.mono8};
`;

export const InputHelperButton = styled.button`
  margin-top: 8px;
  font-family: ${props => props.theme.typography.variants.label2.fontFamily};
  font-size: ${props => props.theme.typography.variants.label2.fontSize};
  font-weight: ${props => props.theme.typography.variants.label2.fontWeight};
  color: ${props => props.theme.colors.linkText};
  transition: ${animationTransition('color')};

  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }

  &:active {
    color: ${props => props.theme.colors.linkActive};
  }
`;
