import { useLocation } from '@react-router';
import { History, Location } from 'history';
import { withWindow } from '@shared/utils/withWindow';
import { config } from '@static/js/joy';

type LocationArg = Location<History.PoorMansUnknown>;

export const useAccountRouteHelper = () => {
  const location = useLocation();
  // generate the sign-in/up link that will take you back to the page you're on
  const signInAndReturnToCurrentPath = () => {
    withWindow(global => {
      const currentPath = `${location.pathname}${location.search ? `?${location.search}` : ''}${location.hash ? `#${location.hash}` : ''}`;
      global.location.href = new URL(`/login?prev=${currentPath}`, config.clientUri).href;
    });
  };
  const signUpAndReturnToCurrentPath = () => {
    withWindow(global => {
      const currentPath = `${location.pathname}${location.search ? `?${location.search}` : ''}${location.hash ? `#${location.hash}` : ''}`;
      global.location.href = new URL(`/signup?prev=${currentPath}`, config.clientUri).href;
    });
  };
  const signUpAndGoToPath = (intendedLocation: LocationArg) => {
    withWindow(global => {
      global.location.href = new URL(
        `/signup?prev=${intendedLocation.pathname}${intendedLocation.search ? `?${intendedLocation.search}` : ''}${intendedLocation.hash ? `#${intendedLocation.hash}` : ''}`,
        config.clientUri
      ).href;
    });
  };
  const signInAndGoToPath = (intendedLocation: LocationArg) => {
    withWindow(global => {
      global.location.href = new URL(
        `/login?prev=${intendedLocation.pathname}${intendedLocation.search ? `?${intendedLocation.search}` : ''}${intendedLocation.hash ? `#${intendedLocation.hash}` : ''}`,
        config.clientUri
      ).href;
    });
  };
  return {
    signInAndReturnToCurrentPath,
    signUpAndReturnToCurrentPath,
    signInAndGoToPath,
    signUpAndGoToPath
  };
};
