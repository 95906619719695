import { styled } from '@withjoy/joykit';

export const PromptSuggestionsContainer = styled.div`
  display: flex;

  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
  font-weight: normal !important;
  /* max-width: 80%; */

  button {
    background-color: none;
    border: none;
  }
`;
