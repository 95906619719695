import { useMemo } from 'react';
import qs from 'query-string';
import { useTranslation } from '@shared/core/i18n';
import { withWindow } from '@shared/utils/withWindow';
import type { MarcomTranslations } from '@locales/generated';
import { useFeatureFlagsContext } from '@shared/core/featureFlags';
import { useMarcomAppTelemetry } from '@shared/components/Marcom/MarcomApp.telemetry';

type TopLevelLink = keyof Omit<MarcomTranslations['topLevelLinks'], 'joyWedding' | 'joyBaby' | 'giftBasketWidget'>;

type LinkConfig = DeepReadonly<
  Record<
    TopLevelLink,
    {
      id: string;
      label: (input: MarcomTranslations<string>['topLevelLinks']) => string;
      href: string;
      target?: string;
    }
  >
>;

export const useTopLevelLinks = () => {
  const { t2 } = useTranslation('marcom');
  const topLevelLinks = t2('topLevelLinks');
  const { wwwNavigate } = useMarcomAppTelemetry();
  const { formatToUrl } = useFeatureFlagsContext();

  return useMemo(() => {
    const preparedLinks = {} as Record<TopLevelLink, { id: string; label: string; href: string; onClick: React.MouseEventHandler<'a' | HTMLElement>; target?: string }>;

    const linkConfigs: LinkConfig = {
      findACouple: {
        id: 'menu4features',
        label: input => input.findACouple,
        href: '/find',
        target: '_blank'
      },
      login: {
        id: 'menu2login',
        label: input => input.login,
        href: `/login?${qs.stringify({ scenario: 'card-shop', createmethod: 'login' })}`
      },
      getStarted: {
        id: 'menu2create',
        label: input => input.getStarted,
        href: `/createwedding?${qs.stringify({ scenario: 'card-shop', createmethod: 'create account' })}`
      },
      shipping: {
        id: 'menu4shipping',
        label: input => input.shipping,
        href: '/shipping-policy',
        target: '_blank'
      },
      returns: {
        id: 'menu4returns',
        label: input => input.returns,
        href: '/return-policy',
        target: '_blank'
      },
      newsroom: {
        id: 'menu4newsroom',
        label: input => input.newsroom,
        href: '/newsroom',
        target: '_blank'
      },
      careers: {
        id: 'menu4careers',
        label: input => input.careers,
        href: '/careers',
        target: '_blank'
      },
      aboutJoy: {
        id: 'menu4aboutJoy',
        label: input => input.aboutJoy,
        href: '/our-story',
        target: '_blank'
      },
      contactUs: {
        id: 'menu4contactUs',
        label: input => input.contactUs,
        href: 'mailto:hello@withjoy.com',
        target: '_blank'
      },
      terms: {
        id: 'menu4terms',
        label: input => input.terms,
        href: 'https://withjoy.com/eula',
        target: '_blank'
      },
      privacy: {
        id: 'menu4privacy',
        label: input => input.privacy,
        href: 'https://privacy.withjoy.com',
        target: '_blank'
      },
      californiaPrivacyNotice: {
        id: 'menu4californiaPrivacyNotice',
        label: input => input.californiaPrivacyNotice,
        href: 'https://privacy.withjoy.com/privacy-policy#california-privacy-notice',
        target: '_blank'
      },
      doNotShareInformation: {
        id: 'menu4doNotShareInformation',
        label: input => input.doNotShareInformation,
        href: 'https://privacy.withjoy.com/opt-out',
        target: '_blank'
      }
    };

    Object.entries(linkConfigs).forEach(([key, { id, label, href, target }]) => {
      const nodeText = label(topLevelLinks);

      const formattedHref = formatToUrl(href);

      preparedLinks[key as TopLevelLink] = {
        id: id,
        label: nodeText,
        href: formattedHref,
        target,
        onClick: () => {
          wwwNavigate({ elementId: id, to: formattedHref, nodeText });
          if (href) {
            withWindow(global => global.open(formattedHref, target ?? '_self'));
          }
        }
      };
    });

    return preparedLinks;
  }, [topLevelLinks, wwwNavigate, formatToUrl]);
};
