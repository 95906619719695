import React, { useState } from 'react';
import { PreviewContainer } from './PreviewSide.styles';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { BOX_SHADOW_16 } from '@apps/card/boxShadow';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { CardDieCut } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import {
  CustomizePaperTemplate,
  CustomizeDigitalTemplate,
  CustomizeTemplateData,
  CustomizeConciergeTemplate
} from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { FitScaler } from '@apps/card/routes/CardCustomizer/components/FitScaler';
import { JoyLogo } from '@assets/index';

const TemplateWithLayersPreview = ({ template, setRescalerReady }: { template: CustomizePaperTemplate['template']; setRescalerReady: (next: boolean) => void }) => {
  const mobile = useIsMobileOrTablet();
  setRescalerReady(true);

  // Calculate aspect ratio for box.
  // Ensures that box fits this card well so text aligns properly.
  const page = template.themeJSON.card.front;
  const pageWidth = page.dimensions?.width ?? 5;
  const pageHeight = page.dimensions?.height ?? 7;
  const pageAspectRatio = `${pageWidth} / ${pageHeight}`;

  return (
    <Box aspectRatio={pageAspectRatio} width={mobile ? '40%' : '60%'} maxWidth="60%" maxHeight="80%">
      <FitScaler>
        <Box boxShadow={BOX_SHADOW_16}>
          <Page width={5} height={7} page={page} shape={CardDieCut.rectangle} />
        </Box>
      </FitScaler>
    </Box>
  );
};

const ImagePreview = ({ data }: { data: CustomizeDigitalTemplate | CustomizeConciergeTemplate }) => {
  const { format, template } = data;
  return (
    <Box aspectRatio={'5 / 7'} width={['35vw', null, null, 460]} boxShadow={BOX_SHADOW_16}>
      {format === 'concierge' ? (
        <Box
          backgroundImage={`url('${template.thumbnailUrl}')`}
          aspectRatio={'5 / 7'}
          paddingTop="56.25%"
          backgroundColor="mono1"
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        />
      ) : (
        <Box as="img" width="100%" src={template.thumbnailUrl} aspectRatio={'5 / 7'} />
      )}
    </Box>
  );
};

export const PreviewSide = ({ page }: { page: CustomizeTemplateData }) => {
  const [rescalerReady, setRescalerReady] = useState(page.format !== 'paper');
  const mobile = useIsMobileOrTablet();

  const { template, format } = page;

  return (
    <PreviewContainer
      className={rescalerReady ? 'ready' : ''}
      mobile={mobile}
      paddingY={{ _: 6, sm2: 10 }}
      paddingLeft={{ _: 7, sm2: 6 }}
      paddingRight={{ _: 54, sm2: 6 }}
      gap={{ _: 5, sm2: 8 }}
    >
      {page &&
        (format === 'paper' || format === 'premiumDigital' ? <TemplateWithLayersPreview setRescalerReady={setRescalerReady} template={template} /> : <ImagePreview data={page} />)}
      <Flex flexDirection="column" gap="16px" alignItems="center" justifyContent="center">
        {mobile && (
          <Box height={{ _: 6, sm2: 9 }}>
            <JoyLogo height="100%" />
          </Box>
        )}
        <TextV2
          typographyVariant="Hed40"
          fontSize={['20px', null, null, '40px']}
          style={{ width: '100%', maxWidth: mobile ? '260px' : '550px', padding: mobile ? '0px' : '0px 0px 100px 0px 100px' }}
          textAlign="center"
        >
          {mobile ? 'Sign up for free to finish fully customizing your perfect card.' : 'Join over one million happy couples planning smarter'}
        </TextV2>
      </Flex>
    </PreviewContainer>
  );
};
