import React from 'react';
import { SelectV1, Spinner } from '@withjoy/joykit';
import { LocationInputProps, useLocationInput } from '@shared/components/LocationInput/LocationInput.controller';
import { useFormControl } from '@withjoy/joykit/components/FormControl/FormControl.provider';
import { PoweredByGoogle } from './PoweredByGoogle';
import { useTranslation } from '@shared/core';

export const LocationInput = <T extends {}>(props: LocationInputProps<T>) => {
  const { t } = useTranslation('joykit');
  const { noOptionsMessage = t('locationInput', 'noOptionsMessage')() } = props;
  const { options, inputValue, handleInputValue, handleChange, iconRight, iconLeft, onLocationOpen, placeholder } = useLocationInput(props);
  const formControlProps = useFormControl({});

  return (
    <SelectV1
      noBorder={props.noBorder}
      placeholder={placeholder ? placeholder : 'San Francisco, CA'}
      options={options}
      inputValue={inputValue}
      noOptionsMessage={noOptionsMessage}
      searchable
      isInvalid={formControlProps.isInvalid}
      onChange={handleChange}
      onMenuOpen={onLocationOpen}
      optionFilter={() => true}
      iconRight={iconRight}
      iconLeft={props.loading ? <Spinner /> : iconLeft}
      onInputChange={handleInputValue}
      loadingMessage={() => <Spinner />}
      autoComplete="off"
      afterMenuContent={<PoweredByGoogle />}
      ref={props.refInput}
    />
  );
};
