/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Email',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.46675 4.03573V12.0401L5.65732 7.87389L1.46675 4.03573ZM6.24791 8.41482L2.10536 12.5333H14.1269L9.85147 8.41032L9.00043 9.18624C8.45317 9.6852 7.63382 9.68418 7.08771 9.18399L6.24791 8.41482ZM10.4439 7.87021L14.5334 11.8139V4.14161L10.4439 7.87021ZM14.0863 3.46663L8.46144 8.59506C8.22014 8.81507 7.86895 8.81469 7.62804 8.59404L2.02985 3.46663H14.0863ZM14.805 2.81144C14.6519 2.71929 14.4747 2.66663 14.2858 2.66663H1.71437C1.13578 2.66663 0.666748 3.16066 0.666748 3.77007V12.2298C0.666748 12.4079 0.706779 12.5761 0.777877 12.725C0.826986 12.8278 0.890916 12.9215 0.966715 13.0028C0.982615 13.0199 0.999037 13.0364 1.01595 13.0523C1.11647 13.1471 1.23446 13.2217 1.3642 13.2701C1.47369 13.311 1.59156 13.3333 1.71437 13.3333H14.2858C14.475 13.3333 14.6526 13.2804 14.8058 13.188C14.8484 13.1623 14.8891 13.1335 14.9277 13.102C14.9929 13.0486 15.052 12.9874 15.1036 12.9195C15.1873 12.8096 15.2514 12.6824 15.2904 12.5439C15.3184 12.4444 15.3334 12.3389 15.3334 12.2298V3.77007C15.3334 3.65964 15.318 3.553 15.2894 3.4524C15.2503 3.31528 15.1866 3.18938 15.1036 3.08042C15.0643 3.02875 15.0207 2.98089 14.9733 2.93745C14.9214 2.88989 14.8651 2.84762 14.805 2.81144Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.2 6.05365V18.0602L8.48585 11.8109L2.2 6.05365ZM9.37174 12.6223L3.15791 18.8H21.1902L14.7771 12.6155L13.5005 13.7794C12.6796 14.5279 11.4506 14.5263 10.6314 13.776L9.37174 12.6223ZM15.6657 11.8054L21.8 17.7209V6.21248L15.6657 11.8054ZM21.1294 5.2L12.692 12.8927C12.3301 13.2227 11.8033 13.2221 11.4419 12.8911L3.04465 5.2H21.1294ZM22.2073 4.21722C21.9778 4.07899 21.7119 4 21.4286 4H2.57143C1.70355 4 1 4.74105 1 5.65517V18.3448C1 18.6119 1.06005 18.8642 1.16669 19.0875C1.24036 19.2418 1.33625 19.3823 1.44995 19.5043C1.4738 19.5298 1.49843 19.5546 1.52381 19.5785C1.67458 19.7207 1.85156 19.8326 2.04617 19.9053C2.21042 19.9666 2.38722 20 2.57143 20H21.4286C21.7124 20 21.9787 19.9207 22.2086 19.782C22.2725 19.7435 22.3335 19.7004 22.3914 19.6531C22.4892 19.573 22.5779 19.4811 22.6553 19.3793C22.7808 19.2144 22.877 19.0237 22.9354 18.8159C22.9775 18.6666 23 18.5085 23 18.3448V5.65517C23 5.48953 22.9769 5.32956 22.9339 5.17866C22.8753 4.97298 22.7797 4.78414 22.6553 4.62069C22.5964 4.54319 22.5309 4.4714 22.4598 4.40623C22.382 4.33489 22.2975 4.27149 22.2073 4.21722Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.1785 24.9019V7.09809C30.1785 6.87985 30.1229 6.67461 30.025 6.49578L20.2844 15.4497L30.1264 25.2611C30.1603 25.1473 30.1785 25.0268 30.1785 24.9019ZM29.5732 25.9767C29.3833 26.0919 29.1605 26.1583 28.9222 26.1583H3.07789C2.84771 26.1583 2.63199 26.0964 2.44647 25.9883L12.3684 16.057L14.3195 17.8358C15.2836 18.7149 16.7227 18.7131 17.6848 17.8319L19.6226 16.0571L29.5732 25.9767ZM1.88093 25.2847L11.7066 15.4497L1.98546 6.47722C1.88115 6.66035 1.82157 6.87226 1.82157 7.09809V24.9019C1.82157 25.0354 1.84238 25.164 1.88093 25.2847ZM2.68718 5.90371L12.6563 15.105L12.6583 15.1069L14.9241 17.1727C15.545 17.7388 16.4589 17.7378 17.0787 17.1701L19.332 15.1063L29.335 5.91116C29.2057 5.8662 29.0668 5.84177 28.9222 5.84177H3.07789C2.94148 5.84177 2.81015 5.86351 2.68718 5.90371ZM0.924194 24.9019C0.924194 26.0914 1.88844 27.0556 3.07789 27.0556H28.9222C30.1116 27.0556 31.0759 26.0914 31.0759 24.9019V7.09809C31.0759 5.90864 30.1116 4.9444 28.9222 4.9444H3.07789C1.88844 4.9444 0.924194 5.90864 0.924194 7.09809V24.9019Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
