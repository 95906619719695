import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TextV2 } from '@withjoy/joykit';
import { Copy, Link as LinkIcon } from '@withjoy/joykit/icons';
import { useShareDialogController } from '@apps/admin/routes/DashboardPreview/components/ShareDialog.controller';
import { DashboardDialog } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/DashboardDialog/DashboardDialog';
import { CopyButton, EditLink, Footer, OpenButton, Row, ShareOption, Title, ValueText } from './ShareDialog.styles';
import { ShareDialogProps } from './ShareDialog.types';

export const ShareDialog: React.FC<ShareDialogProps> = props => {
  const { isOpen, isGuest } = props;
  const { shareOptions, guestShareOptions, guestDialogTitle, dialogTitle, editLinkText, copy, buttonName, onOpenNewTab, onCloseDialogHandle } = useShareDialogController(props);

  const options = isGuest ? guestShareOptions : shareOptions;

  return (
    <DashboardDialog title={isGuest ? guestDialogTitle : dialogTitle} isOpen={isOpen} onClose={onCloseDialogHandle}>
      <>
        {options.map(({ title, onCopy, text, editLink, onEditLinkClick }, index) => (
          <ShareOption key={title}>
            <Row>
              <Title>{title}</Title>
              <EditLink title={editLinkText} onClick={onEditLinkClick} href={editLink}>
                {editLinkText}
              </EditLink>
            </Row>
            <Row>
              <ValueText lines={3}>{text}</ValueText>
              <CopyToClipboard text={text}>
                <CopyButton title={copy} onClick={onCopy}>
                  <Copy />
                </CopyButton>
              </CopyToClipboard>
            </Row>
          </ShareOption>
        ))}
        <Footer>
          <OpenButton startIcon={<LinkIcon />} variant="outline" onClick={onOpenNewTab}>
            <TextV2 typographyVariant="button2">{buttonName}</TextV2>
          </OpenButton>
        </Footer>
      </>
    </DashboardDialog>
  );
};
