import { styled, TextV2 } from '@withjoy/joykit';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';

export const StyledImageBackground = styled.div<{ assetImage: string; isAdjustingPosition: boolean }>`
  width: 100%;
  height: 100%;
  background-image: ${props => backgroundImageForUrl(props.assetImage)};
  background-blend-mode: hue;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  pointer-events: ${({ isAdjustingPosition }) => (isAdjustingPosition ? 'none' : 'initial')};
`;

export const StyledNameText = styled(TextV2)`
  font-size: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: 500;
`;

export const StyledDetailsText = styled(TextV2)`
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.2em;
  font-weight: 500;
  font-family: inherit;
`;
