import { useCallback, useState } from 'react';
import { OnIntersectFn, useOnIntersectingChange } from '@shared/utils/hooks/useOnIntersectingChange';

export const useStickyCustomizeButton = (intersectionRef: React.RefObject<HTMLButtonElement>, enabled: boolean) => {
  const [isStaticCtaAboveViewport, setIsStaticCtaAboveViewport] = useState(false);

  const onIntersectingTriggerPointEl = useCallback<OnIntersectFn>((isIntersecting, entry) => {
    // on mobile, only consider intersection when the ref is higher up in the dom
    setIsStaticCtaAboveViewport(!isIntersecting && entry.boundingClientRect.top < 0);
  }, []);

  useOnIntersectingChange(intersectionRef, onIntersectingTriggerPointEl, {
    threshold: 1,
    root: null
    // rootMargin: '140px 0px 0px 0px'
  });

  return enabled && isStaticCtaAboveViewport;
};
