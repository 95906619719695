import { SpacingStack, StyleSystemProps, styled } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';
import { StyledInputContainer } from '@withjoy/joykit/components/InputV1/StyledInputContainer';

interface formikValidationProps {
  isInvalid?: boolean;
  error?: string;
}
// overrides to style SelectV1 InputV1s to look like InputV2
export const inputV1ToV2Styles = ({ isInvalid, error }: formikValidationProps): CSSObject => {
  return {
    ['& > div']: {
      width: '100% !important'
    },
    ['> div > div > div > div > div:nth-child(2)']: {
      left: '18px'
    },
    [`${StyledInputContainer}`]: {
      border: isInvalid || error ? '2px solid #c50020' : '2px solid transparent',
      borderRadius: '6px',
      _after: {
        border: '0px solid transparent',
        borderRadius: '6px',
        boxShadow: isInvalid || error ? '0px 0 0px 1px #c50020' : '0px 0 0px 1px #5f269b',
        content: '" "',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0
      },
      ['input']: {
        outline: 0,
        _focus: { outline: 0 },
        fontSize: '1rem !important'
      }
    }
  };
};

export const FormWrapperStack = styled(SpacingStack)`
  max-width: 360px;
`;

const signOutLinkStyles: StyleSystemProps = {
  display: ['block', 'inline-block']
};

export const styles = {
  signOutLinkStyles
};
