import React from 'react';
import { DialogV2, ButtonV2, TextV2, CancelButton } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

export interface Props
  extends Readonly<{
    dialogId: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    warningMessageContent?: string;
  }> {}

export const UnsavedChangesDialog: React.FC<Props> = props => {
  const { dialogId, isOpen, onClose, onConfirm, warningMessageContent = 'digital message' } = props;
  const { t } = useTranslation('emailsAndEcards');
  const tUnsavedChangesDialog = t('unsavedChangesDialog');
  return (
    <DialogV2 id={dialogId} isOpen={isOpen} onClose={onClose} size="sm">
      <DialogV2.Header justifyContent="space-between" alignItems="center" paddingTop="24px">
        <TextV2 typographyVariant="hed5">{tUnsavedChangesDialog.unsavedChangesWarningHed()}</TextV2>
        <DialogV2.CloseButton zIndex="modal" color="mono14" />
      </DialogV2.Header>
      <DialogV2.Body>
        <TextV2 typographyVariant="body1">{tUnsavedChangesDialog.unsavedChangesWarningDek({ warningMessageContent })}</TextV2>
      </DialogV2.Body>
      <DialogV2.Footer flexDirection="column" alignItems="center" justifyContent="center" paddingX="32px" borderTop="1px solid #EBEBEB">
        <ButtonV2 intent="destructive" onClick={onConfirm} width="100%">
          {tUnsavedChangesDialog.discardButtonText()}
        </ButtonV2>
        <CancelButton onClick={onClose} width="100%">
          {tUnsavedChangesDialog.cancelButtonText()}
        </CancelButton>
      </DialogV2.Footer>
    </DialogV2>
  );
};
