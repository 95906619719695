import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useTranslation } from '@shared/core';

export const useOptionalTestEmailViewController = () => {
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tOptionalTestEmail = tBottomSheet.optionalTestEmail;
  const { parentViewState } = useBottomSheetState();
  const handleButtonClick = parentViewState?.handleSendTestEmailDialogOpen as () => void;

  return { tOptionalTestEmail, handleButtonClick };
};
