import { JoyLogo } from '@assets/index';
import { Flex, TextV2 } from '@withjoy/joykit';
import React, { FormEvent, useRef, useState } from 'react';
import { HintText } from '../HintText/HintText';
import { ClearButton } from '../PromptBarButton/ClearButton';
import PromptBarButton from '../PromptBarButton/PromptBarButton';
import PromptSuggestions from '../PromptSuggestions/PromptSuggestions';
import {
  PromptForm,
  StickyLogoContainer,
  MenuBar,
  Blinder,
  PromptBarOutline,
  SpecialInput,
  PromptBarIsland,
  IslandContent,
  InputBar
} from '../ImagineYourCardRoot/ImagineYourCardRoot.styles';
import { useDetectPosition } from '@apps/imagineYourCard/hooks/useDetectPosition';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { responsiveSettings } from '../ImagineYourCardRoot/cardConstants';
import { useTranslation } from '@shared/core';

type PromptBarProps = {
  enabled?: boolean;
  prompt: string;
  inputFieldProps: {
    value: string;
    onChange: (newVal: string) => void;
  };

  demoPrompts: string[];
  isDemoRunning: boolean;
  handleStopDemo: () => void;
  handleHintCompletion: (hint: string) => void;

  trendingPrompts: string[];

  onSubmit: (prompt: string) => void;
  onFocus: () => void;
  onSetPrompt: (prompt: string) => void;
};

export const PromptBar = (props: PromptBarProps) => {
  const { isDemoRunning, handleStopDemo, onSubmit, demoPrompts, trendingPrompts, handleHintCompletion, onFocus, onSetPrompt, enabled, prompt, inputFieldProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [showIsland, setShowIsland] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [atPosition] = useDetectPosition(formRef, 20);
  const cols = useResponsive(responsiveSettings, 1)[0];
  const mobile = cols <= 2;
  const promptValue = useRef('');

  const { t } = useTranslation('stationery');
  const { placeholder, placeholderMobile, submit, subheadingPopular } = t('imagineCardAiApp', 'promptbar');

  const handleFocus = () => {
    onFocus();
    handleStopDemo();
    setShowIsland(true);
  };

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setShowIsland(false);
    onSubmit(promptValue.current);
  };

  return (
    <PromptForm onSubmit={handleSubmit} mobile={mobile} ref={formRef} islandOpened={showIsland} isSticky={atPosition}>
      {!mobile && (
        <StickyLogoContainer show={atPosition}>
          <JoyLogo color="#330066"></JoyLogo>
        </StickyLogoContainer>
      )}
      <Flex flexDirection="row" justifyContent="center" alignItems="center" padding="0px 25px 0px 25px">
        <MenuBar mobile={mobile} showJoyLogo={atPosition && !mobile}>
          {(showIsland || isDemoRunning) && (
            <Blinder
              active={showIsland || isDemoRunning}
              onClick={event => {
                setShowIsland(false);
                handleStopDemo();
              }}
            />
          )}
          <PromptBarOutline>
            <InputBar mobile={mobile}>
              {isDemoRunning && demoPrompts && (
                <HintText
                  demoPrompts={demoPrompts}
                  onClick={() => {
                    handleFocus();
                    inputRef.current?.focus();
                  }}
                  onComplete={handleHintCompletion}
                  onDemoEnd={handleStopDemo}
                />
              )}
              <SpecialInput
                ref={inputRef}
                placeholder={!isDemoRunning ? (mobile ? placeholderMobile() : placeholder()) : ''}
                mobile={mobile}
                style={{ opacity: isDemoRunning ? 0 : 1 }}
                onFocus={handleFocus}
                autoComplete="off"
                value={inputFieldProps.value}
                onChange={event => {
                  if (enabled) {
                    promptValue.current = event.target.value;
                    inputFieldProps.onChange(event.target.value);
                  }
                }}
                disabled={!enabled}
              ></SpecialInput>
              {showIsland && prompt.length > 0 && (
                <ClearButton
                  onClick={() => {
                    onSetPrompt('');
                    promptValue.current = '';
                  }}
                />
              )}
              <PromptBarButton text={submit()} enabled={enabled} loading={!enabled} />
            </InputBar>
          </PromptBarOutline>
          {showIsland && (
            <PromptBarIsland mobile={mobile} isSticky={atPosition && !mobile}>
              <IslandContent>
                <TextV2 typographyVariant="hed2" fontSize="13">
                  {subheadingPopular()}
                </TextV2>
                <PromptSuggestions
                  prompts={trendingPrompts}
                  onClick={value => {
                    promptValue.current = value;
                    inputFieldProps.onChange(value);
                    onSubmit(value);
                    setShowIsland(false);
                  }}
                />
              </IslandContent>
            </PromptBarIsland>
          )}
        </MenuBar>
      </Flex>
    </PromptForm>
  );
};
