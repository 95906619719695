import React from 'react';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { Box, BoxProps, IconV1 } from '@withjoy/joykit';
import { CarouselSkeleton } from '../../Carousel';
import { CarouselProvider } from '../../Carousel.controller';
import { CarouselProps } from '../../Carousel.types';
import { CarouselContainer, ItemsContainer, StyledArrowButton, styles } from './CarouselItemsDesktop.styles';
import { useCarouselDesktop } from './CarouselItemsDesktop.controller';

const CarouselItemsDesktop = ({
  children,
  totalNumberOfItems,
  arrowMarginX = 10,
  arrowOffsetY = 0,
  itemSpacing = 24,
  arrowVisibility = 'always',
  paginationBehavior = 'set',
  enableDotNav,
  enablePeek,
  columns = [1, 2, 3, 4, 5],
  showSkeleton = false,
  placeholder,
  onArrowClick = () => {}
}: CarouselProps) => {
  const isIndividualPagination: boolean = paginationBehavior === 'individual';

  const { containerRef, itemRef, handleArrowClick, totalNumberDots, activeDot, showLeftArrow, showRightArrow, elementsPerPage, handleDotClick, peekSize } = useCarouselDesktop({
    enablePeek,
    totalNumberOfItems,
    itemSpacing,
    isIndividualPagination,
    columns,
    onArrowClick
  });
  const hideArrows: boolean = arrowVisibility === 'hover';

  return (
    <CarouselProvider value={{ itemRef, elementsPerPage, itemSpacing, enablePeekSpacing: true, showSkeleton }}>
      <SkeletonGroup
        isReady={!showSkeleton}
        width="100%"
        placeholder={
          <CarouselContainer __css={styles.carouselContainer} height={'fit-content'}>
            <ItemsContainer __css={styles.itemsList(peekSize)}>
              <CarouselSkeleton placeholder={placeholder} />
            </ItemsContainer>
          </CarouselContainer>
        }
      >
        <CarouselContainer __css={styles.carouselContainer} height={'fit-content'}>
          {showLeftArrow && (
            <PrimaryControlArrow $dir="left" $hideArrows={hideArrows} style={{ left: arrowMarginX }} marginTop={arrowOffsetY} onClick={() => handleArrowClick('left')}>
              <IconV1 source="chevronLeft" iconSize={24} />
            </PrimaryControlArrow>
          )}
          <ItemsContainer __css={styles.itemsList(peekSize)} ref={containerRef}>
            {children}
          </ItemsContainer>
          {showRightArrow && (
            <PrimaryControlArrow $dir="right" $hideArrows={hideArrows} style={{ right: arrowMarginX }} marginTop={arrowOffsetY} onClick={() => handleArrowClick('right')}>
              <IconV1 source="chevronRight" iconSize={24} />
            </PrimaryControlArrow>
          )}
        </CarouselContainer>
        {enableDotNav && (
          <Box __css={styles.dotsContainer}>
            {Array.from({ length: totalNumberDots }).map((_, index) => (
              <DotButton key={index} isActive={index === activeDot} onClick={() => handleDotClick(index)} />
            ))}
          </Box>
        )}
      </SkeletonGroup>
    </CarouselProvider>
  );
};

const DotButton = ({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => {
  return <Box as="button" __css={styles.dotButton(isActive)} onClick={onClick} />;
};

const PrimaryControlArrow: React.FC<BoxProps & { $dir: 'left' | 'right'; $hideArrows: boolean }> = props => {
  return <StyledArrowButton as="button" __css={styles.arrowButton} {...props} />;
};

export { CarouselItemsDesktop };
