import React from 'react';
import { useTranslation } from '@shared/core';
import { ForgotPasswordWrapperStack } from '@apps/createWedding/routes/ForgotPassword/ForgotPassword.styles';
import { useForgotPasswordController } from '@apps/createWedding/routes/ForgotPassword/ForgotPassword.controller';
import { ButtonV2, InputV2, LinkV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { Email } from '@withjoy/joykit/icons';
import { wrapWithForgotPasswordHealthProvider } from './ForgotPassword.health';

export const ForgotPassword = wrapWithForgotPasswordHealthProvider(() => {
  const { t2 } = useTranslation('createWedding');
  const { formik, goToCreateAccount, goToSignIn, submissionError, focusHandlers } = useForgotPasswordController();

  const forgotPasswordTranslations = t2('forgotPassword');

  return (
    <ForgotPasswordWrapperStack spacing={8}>
      <SpacingStack spacing={1}>
        <TextV2 typographyVariant="hed6" textAlign="center">
          {forgotPasswordTranslations.heroText}
        </TextV2>
        <TextV2 typographyVariant="body1" color="mono12" textAlign="center">
          {forgotPasswordTranslations.helpText}
        </TextV2>
      </SpacingStack>
      <SpacingStack spacing={6}>
        <form onSubmit={formik.handleSubmit}>
          <SpacingStack spacing={8}>
            <InputV2
              onFocus={focusHandlers.email}
              startElement={<Email color="mono10" fill="#767676" size={24} />}
              placeholder={forgotPasswordTranslations.email}
              {...formik.getFieldProps('email')}
            />
            <ButtonV2 type="submit" intent="primary">
              {forgotPasswordTranslations.resetPassword}
            </ButtonV2>
            {submissionError && (
              <TextV2 textAlign="center" typographyVariant="body1" color="negative6">
                {submissionError}
              </TextV2>
            )}
            <SpacingStack spacing={6}>
              <TextV2 typographyVariant="body1" color="mono12" textAlign="center">
                {forgotPasswordTranslations.accountExists}{' '}
                <LinkV2 onClick={goToSignIn} textDecoration="none">
                  <TextV2 as="span" typographyVariant="button1">
                    {forgotPasswordTranslations.login}
                  </TextV2>
                </LinkV2>
              </TextV2>
              <TextV2 typographyVariant="body1" color="mono12" textAlign="center">
                {forgotPasswordTranslations.createAccountCta}{' '}
                <LinkV2 onClick={goToCreateAccount} textDecoration="none">
                  <TextV2 as="span" typographyVariant="button1">
                    {forgotPasswordTranslations.createAccount}
                  </TextV2>
                </LinkV2>
              </TextV2>
            </SpacingStack>
          </SpacingStack>
        </form>
      </SpacingStack>
    </ForgotPasswordWrapperStack>
  );
});
