import { useMinorUnitsToPriceString } from '@apps/card/hooks/useMinorUnitsToPriceString';
import { Flex, TextV2 } from '@withjoy/joykit';
import React, { useRef } from 'react';
import { VariablePriceDiscount } from '../useCardDiscountContext';
import { CardTileDiscountedPriceLabel } from './CardTileDiscountedPriceLabel';
import { useCardCategoryContext } from '../../CardCategoryProvider';

type CardTilePriceLabelProps = {
  paperPriceInMinorUnits: number;
  premiumDigitalPriceInMinorUnits: number;
  paperPromo: ReturnType<VariablePriceDiscount['getPrice']>;
  premiumDigitalPromo: ReturnType<VariablePriceDiscount['getPrice']>;
};

export const CardTilePriceLabel = (props: CardTilePriceLabelProps) => {
  const { paperPriceInMinorUnits, premiumDigitalPriceInMinorUnits, paperPromo, premiumDigitalPromo } = props;
  const minorUnitsToString = useMinorUnitsToPriceString();
  const { currentCategory } = useCardCategoryContext();
  const digitalPriceRef = useRef<HTMLDivElement | null>(null);
  const printPriceRef = useRef<HTMLDivElement | null>(null);

  const paperPriceString = minorUnitsToString(paperPriceInMinorUnits, { canShowFreeLabel: false });
  const premiumDigitalPriceString = minorUnitsToString(premiumDigitalPriceInMinorUnits, { canShowFreeLabel: false });

  return (
    <Flex flexDirection="column" gap="4px" justifyContent="center">
      <div ref={printPriceRef}>
        {paperPromo.isEnabled ? (
          <CardTileDiscountedPriceLabel
            formatString="Paper"
            alignment="center"
            showCode={false}
            discountedPrice={paperPromo.discountedPrice}
            preDiscountPrice={paperPriceString}
            sale={paperPromo.currentSale!}
            category={currentCategory}
          />
        ) : (
          <TextV2 typographyVariant="label2" margin="auto 0 auto 0">{`Paper ${paperPriceString}`}</TextV2>
        )}
      </div>
      <div ref={digitalPriceRef}>
        {premiumDigitalPromo.isEnabled ? (
          <CardTileDiscountedPriceLabel
            formatString="Digital"
            alignment="center"
            showCode={false}
            discountedPrice={premiumDigitalPromo.discountedPrice}
            preDiscountPrice={premiumDigitalPriceString}
            sale={premiumDigitalPromo.currentSale!}
            category={currentCategory}
          />
        ) : (
          <TextV2 typographyVariant="label2" margin="auto 0 auto 0">{`Digital ${premiumDigitalPriceString}`}</TextV2>
        )}
      </div>
    </Flex>
  );
};
