import { useFormik } from 'formik';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import { useAuth } from '@shared/components/AuthProvider';
import * as Yup from 'yup';
import { useLocation, useTranslation } from '@shared/core';
import { useState } from 'react';
import { isAuthProviderError } from '@apps/createWedding/utils';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { useViewContext } from '@apps/createWedding/components/View/ViewProvider';

type SignInValues = {
  email: string;
  password: string;
};

export const useSignInController = () => {
  const auth = useAuth();
  const { t2 } = useTranslation('createWedding');
  const [submissionError, setSubmissionError] = useState<string>('');
  const errorTranslations = t2('signIn', 'errors');
  const telemetry = useCreateWeddingTelemetry();
  const query = useLocation().search;
  const formik = useFormik<SignInValues>({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(errorTranslations.emailValid).required(errorTranslations.emailRequired),
      password: Yup.string().required(errorTranslations.passwordRequired)
    }).required(),
    onSubmit: async ({ email, password }) => {
      try {
        await auth.loginManager.loginWithUsernameAndPassword(email, password, `/${CREATE_WEDDING_APP_NAME}${query ? `?${query}` : ''}`);
      } catch (err) {
        if (isAuthProviderError(err)) {
          setSubmissionError(err.description);
        }
      }
    }
  });
  const { changeView } = useViewContext();

  const getFieldErrorProps = useFieldErrors(formik);

  const focusHandlers = {
    email: () => telemetry.emailClicked('createaccount'),
    password: () => telemetry.passwordClicked('createaccount')
  };

  return {
    getFieldErrorProps,
    formik,
    goToForgotPassword: () => changeView('forgotPassword'),
    goToCreateAccount: () => changeView('createAccount'),
    submissionError,
    focusHandlers
  };
};
