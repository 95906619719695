import { animationTransition } from '@shared/utils/animationTransition';
import { styled } from '@withjoy/joykit';

export const MAX_HEIGHT = 320;
export const MIN_HEIGHT = 74;
const BACKGROUND_COLOR = '#ffffff';

const getHeight = (scrollPosition: number, maxHeight: number, minHeight: number, forceClosed: boolean): number => {
  if (!!scrollPosition || forceClosed) {
    return minHeight;
  }

  return maxHeight;
};

type StyledDialogProps = { $scrollPosition: number; $maxHeight?: number; $minHeight?: number; $backgroundColor?: string; $forceClosed?: boolean };

export const StyledDialog = styled.div.attrs<StyledDialogProps>(
  ({ $scrollPosition, $maxHeight = MAX_HEIGHT, $minHeight = MIN_HEIGHT, $backgroundColor = BACKGROUND_COLOR, $forceClosed = false }) => ({
    style: {
      height: `${getHeight($scrollPosition, $maxHeight, $minHeight, $forceClosed)}px`,
      maxHeight: `${$maxHeight}px`,
      minHeight: `${$minHeight}px`,
      backgroundColor: $backgroundColor
    }
  })
)<StyledDialogProps>`
  transition: ${animationTransition('height')};
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px -16px 60px -12px rgba(44, 41, 37, 0.25), 0px -8px 36px -18px rgba(0, 0, 0, 0.3);
  z-index: 10;
`;
