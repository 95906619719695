/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Thank You',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.1464 5.7102C14.2897 4.85345 12.9563 4.76387 11.9998 5.44148C11.0434 4.76387 9.70999 4.85345 8.85323 5.7102C8.00515 6.55828 7.9088 7.87344 8.56418 8.82765L8.53501 8.85683L11.9998 12.3217L15.4647 8.85683L15.4355 8.82765C16.0909 7.87344 15.9945 6.55829 15.1464 5.7102ZM11.9998 11.0489L9.48962 8.53862C8.88432 7.9333 8.88432 6.95191 9.48963 6.3466C10.0949 5.74129 11.0763 5.74128 11.6816 6.34657L11.9998 6.66475L12.318 6.34657C12.9233 5.74128 13.9047 5.74129 14.51 6.3466C15.1153 6.95191 15.1153 7.9333 14.51 8.53862L11.9998 11.0489Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5498 9.02501V3.49999C4.5498 2.69917 5.19899 2.04999 5.9998 2.04999H17.9998C18.8006 2.04999 19.4498 2.69918 19.4498 3.49999V9.02496L21.2698 10.39C21.4012 10.4885 21.4634 10.6451 21.4474 10.7974C21.449 10.8158 21.4498 10.8345 21.4498 10.8536V20.5C21.4498 21.3008 20.8006 21.95 19.9998 21.95L3.99983 21.95C3.19902 21.95 2.54983 21.3008 2.54983 20.5L2.54983 10.8536C2.54983 10.8345 2.55066 10.8158 2.55227 10.7974C2.53628 10.6451 2.59844 10.4885 2.72983 10.39L4.5498 9.02501ZM5.4498 3.49999C5.4498 3.19623 5.69604 2.94999 5.9998 2.94999H17.9998C18.3036 2.94999 18.5498 3.19623 18.5498 3.49999V11.8027L13.9263 15.0036L12.9215 14.1761C12.3861 13.7352 11.6134 13.7352 11.078 14.1762L10.0733 15.0035L5.4498 11.8027V9.25682C5.44988 9.25203 5.44988 9.24724 5.4498 9.24245V3.49999ZM20.5498 20.4582L14.6484 15.5983L20.5498 11.5127V20.4582ZM12.3494 14.8709L19.8526 21.05H4.14695L11.6501 14.8709C11.8532 14.7036 12.1463 14.7036 12.3494 14.8709ZM9.35115 15.5982L3.44983 20.4582L3.44983 11.5127L9.35115 15.5982ZM4.5498 10.15L3.83595 10.6854L4.5498 11.1796V10.15ZM20.1637 10.6854L19.4498 10.15V11.1796L20.1637 10.6854Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8578 11.9223L16 16L20.1421 11.9223C21.2859 10.7963 21.2859 8.9706 20.1421 7.84456C18.9983 6.71853 17.1438 6.71853 16 7.84456C14.8562 6.71853 13.0017 6.71853 11.8578 7.84456C10.714 8.9706 10.714 10.7963 11.8578 11.9223ZM15.4387 8.41466L16 8.96717L16.5612 8.41466C17.3937 7.59516 18.7484 7.59516 19.5809 8.41466C20.4063 9.22729 20.4063 10.5396 19.5809 11.3522L16 14.8774L12.4191 11.3522C11.5936 10.5396 11.5936 9.22729 12.4191 8.41466C13.2515 7.59516 14.6063 7.59516 15.4387 8.41466Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.3261 12.1567V3.66404C6.3261 3.30505 6.61711 3.01404 6.9761 3.01404H25.0239C25.3829 3.01404 25.6739 3.30505 25.6739 3.66404V12.1567L28.1636 14.024C28.3168 14.1389 28.3235 14.3077 28.3235 14.4812V28.086C28.3235 28.5831 27.9206 28.986 27.4235 28.986L4.57642 28.986C4.07936 28.986 3.67642 28.5831 3.67642 28.086L3.67642 14.4812C3.67642 14.3077 3.68319 14.1389 3.83641 14.024L6.3261 12.1567ZM4.47642 14.9771L4.47642 27.6783L12.8257 20.7603L4.47642 14.9771ZM26.8824 28.186L5.11758 28.186L15.9361 19.222C15.9731 19.1914 16.0267 19.1914 16.0637 19.222L26.8824 28.186ZM27.5235 14.9771V27.6783L19.1742 20.7603L27.5235 14.9771ZM24.8739 15.8414L18.5344 20.2303L16.5741 18.606C16.241 18.33 15.7588 18.33 15.4257 18.606L13.4655 20.2302L7.1261 15.8414V3.81404H24.8739V15.8414ZM4.82299 14.284L6.3261 13.1567V15.2876L4.96652 14.3463C4.92182 14.3154 4.87303 14.2948 4.82299 14.284ZM27.177 14.284L25.6739 13.1567V15.2876L27.0334 14.3463C27.0781 14.3154 27.1269 14.2948 27.177 14.284Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
