import { useAccountRouteHelper } from '@apps/account/useAccountHelper';
import { useAuth } from '@shared/components/AuthProvider';
import { withWindow } from '@shared/utils/withWindow';
import React, { useEffect } from 'react';
import { JoyLogoLoader } from '../JoyLogoLoader';

export const MustBeSignedIn: React.FC<{}> = ({ children }) => {
  const { currentUser, isLoggedIn, hasInitializedOnce } = useAuth();
  const accountHelper = useAccountRouteHelper();

  useEffect(() => {
    if (!hasInitializedOnce || currentUser.profile) {
      return;
    }
    withWindow(global => {
      global.location.href = '/login';
    });
  }, [accountHelper, currentUser, hasInitializedOnce]);

  if (!hasInitializedOnce) {
    return <JoyLogoLoader loaderKey="protected-account-route" />;
  }

  if (currentUser && isLoggedIn) {
    return <>{children}</>;
  }
  return null;
};
