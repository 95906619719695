import React from 'react';
import { Box, BoxProps } from '@withjoy/joykit';
import { Matrix, Rect, XY, startDrag } from './startDrag';
import { useSurface } from './useSurface';
import { LayerData } from '../../components/Layer.types';
import { TextMeasurer } from './TextMeasurer';
import { QRCodeMeasurer } from './QRCodeMeasurer';
import { PPI } from './config';
import { PageDimensions } from '../../CardCustomizer.types';

const RADIUS = 6;
const DIAMETER = RADIUS * 2;
const PAGE_W = 5;
const PAGE_H = 7;

// 1/8" where it's not safe to place text. This is in addition to the 1/8" bleed. Source: Taylor.
export const SAFE_MARGIN = 9;

type HandleProps = Pick<BoxProps, 'cursor' | 'onPointerDown' | 'gridArea'>;

const Handle = (props: HandleProps) => {
  return (
    <Box
      backgroundColor="white"
      border="2px solid hsl(100, 30%, 30%, 1)"
      borderRadius={RADIUS}
      boxShadow="0px 1px 8px 0px rgba(0, 0, 0, 0.25)"
      pointerEvents="all"
      touchAction="none"
      {...props}
    ></Box>
  );
};

const Bar = (props: HandleProps) => {
  return <Box pointerEvents="all" touchAction="none" {...props} />;
};

interface AdornmentsProps {
  card: {
    dimensions?: PageDimensions;
    layers: readonly LayerData[];
  };
  update: (scopedUpater: (scopedDraft: { layers: readonly LayerData[] }) => void) => void;
}
export function Adornments({ card, update }: AdornmentsProps) {
  const [{ scale, activeLayerIndex, editingLayerIndex }, updateSurface] = useSurface();

  if (activeLayerIndex === undefined || !card.layers[activeLayerIndex]) return null;
  const layer = card.layers[activeLayerIndex];

  const { layout } = layer;

  const MIN_SIZE = layer.type === 'qrcode' ? 72 : 16;

  const onDrag = (delta: XY, transforms: Matrix) => {
    if (editingLayerIndex !== undefined) {
      updateSurface(draft => {
        draft.editingLayerIndex = undefined;
      });
    }
    const baseline = layout;
    const [transformX, transformY, transformWidth, transformHeight] = transforms;
    update(draft => {
      const uncontrained = {
        x: baseline.x + Math.min(baseline.width - MIN_SIZE, (transformX * delta.x) / scale),
        y: baseline.y + Math.min(baseline.height - MIN_SIZE, (transformY * delta.y) / scale),
        width: Math.max(MIN_SIZE, baseline.width + (transformWidth * delta.x) / scale),
        height: Math.max(MIN_SIZE, baseline.height + (transformHeight * delta.y) / scale)
      };
      const { x, y, width } = quadToRect(constrainQuadDimensions(rectToQuad(uncontrained), card.dimensions));
      draft.layers[activeLayerIndex].layout.x = x;
      draft.layers[activeLayerIndex].layout.y = y;
      draft.layers[activeLayerIndex].layout.width = width;
    });
  };

  // Stepped to avoid odd-looking 1 x 1.1 ratio handle.
  const handleHeight = layout.height >= DIAMETER * 4 ? DIAMETER * 2 : DIAMETER;

  const isLayerEditing = editingLayerIndex === activeLayerIndex;
  const isLayerResizable = layer.type !== 'image';

  return (
    <Box
      position="absolute"
      pointerEvents="none"
      cursor="move"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 100%, 50%, 0.1)"
      border="1px solid hsl(100, 50%, 20%, 0.4)"
      style={{
        left: scale * layout.x,
        top: scale * layout.y,
        width: scale * layout.width,
        height: scale * layout.height
      }}
    >
      {layer.type === 'text' && (
        <TextMeasurer
          layer={layer}
          onHeightChange={height => {
            update(page => {
              page.layers[activeLayerIndex].layout.height = height;
            });
          }}
        />
      )}
      {layer.type === 'qrcode' && (
        <QRCodeMeasurer
          layer={layer}
          onHeightChange={height => {
            update(page => {
              page.layers[activeLayerIndex].layout.height = height;
            });
          }}
        />
      )}
      <Box
        position="absolute"
        inset={`${-RADIUS}px`}
        gridTemplateAreas='"nw n ne" "w . e" "sw s se"'
        display="grid"
        gridTemplateColumns={`${RADIUS * 2}px 1fr ${RADIUS * 2}px`}
        gridTemplateRows={`1fr ${handleHeight}px 1fr`}
      >
        {!isLayerEditing && isLayerResizable && (
          <>
            <Bar gridArea="1 / 1/ -1" cursor="ew-resize" onPointerDown={startDrag(onDrag, [1, 0, -1, 0])} />
            <Bar gridArea="1 / 3/ -1" cursor="ew-resize" onPointerDown={startDrag(onDrag, [0, 0, 1, 0])} />
            <Handle gridArea="w" cursor="ew-resize" onPointerDown={startDrag(onDrag, [1, 0, -1, 0])} />
            <Handle gridArea="e" cursor="ew-resize" onPointerDown={startDrag(onDrag, [0, 0, 1, 0])} />
          </>
        )}
      </Box>
    </Box>
  );
}

// Helpers

type Quad = Readonly<[number, number, number, number]>;

function rectToQuad(rect: Rect): Quad {
  return [rect.x, rect.x + rect.width, rect.y, rect.y + rect.height];
}

function constrainQuadDimensions(quad: Quad, dimensions?: PageDimensions): Quad {
  const [x1, x2, y1, y2] = quad;
  const pageWith = dimensions?.width ?? PAGE_W;
  const pageHeight = dimensions?.height ?? PAGE_H;
  return [Math.max(SAFE_MARGIN, x1), Math.min(pageWith * PPI - SAFE_MARGIN, x2), Math.max(SAFE_MARGIN, y1), Math.min(pageHeight * PPI - SAFE_MARGIN, y2)] as const;
}

function quadToRect(quad: Quad): Rect {
  const [x1, x2, y1, y2] = quad;
  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1
  };
}
