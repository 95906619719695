import { Box } from '@withjoy/joykit';
import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { MEDIA_SERVICE } from '../steps/CardDesign/config';
import { ScaleRef } from '../steps/CardDesign/useSurface';
import { absolutePhotoUrl, layerLayoutDataToCSS, previewUrls, SCALE_MAX, Size } from './ImageLayer';
import { ImageLayerData } from './Layer.types';
import { PageEditor } from './Page';
import { clamp } from '@apps/card/Card.utils';

function getScalePanToFit(frame: Size, photo: Size) {
  const photoRatio = photo.width / photo.height;
  const frameRatio = frame.width / frame.height;
  const isPhotoTallerThanFrame = photoRatio < frameRatio;

  const scale = isPhotoTallerThanFrame ? frame.width / photo.width : frame.height / photo.height;

  return isPhotoTallerThanFrame
    ? {
        scale,
        x: 0,
        y: (frame.height - photo.height * scale) / 2
      }
    : {
        scale,
        x: (frame.width - photo.width * scale) / 2,
        y: 0
      };
}

export const CroppedImageLayer = ({ layer, photoSize, loader, loading }: { layer: ImageLayerData; photoSize: Size; loader?: ReactElement; loading?: boolean }) => {
  const url = previewUrls.get(layer.imageData.src) ?? absolutePhotoUrl(layer.imageData.src);
  const crop = layer.imageData.crop ?? getScalePanToFit(layer.layout, photoSize);

  // CSS backgroundSize:<percent> is a percent of the container. But we need a percent of the image.
  // Thus we use <img> in a <Box>
  return (
    <>
      {loader ? loader : null}
      <Box
        style={{
          ...layerLayoutDataToCSS(layer.layout),
          overflow: 'hidden', // Safari not responding to 'clip'
          // Opacity to fade in and out of Loader
          transition: '1s linear all',
          opacity: loading === true ? '0' : '1'
        }}
      >
        <img
          alt="the couple"
          src={url.startsWith(MEDIA_SERVICE) ? `${url}?rendition=medium` : url}
          width={photoSize.width}
          height={photoSize.height}
          style={{
            position: 'absolute',
            left: crop.x,
            top: crop.y,
            transformOrigin: 'top left',
            transform: `scale(${crop.scale})`
          }}
        />
      </Box>
    </>
  );
};

type Crop = Required<ImageLayerData['imageData']>['crop'];

function scaleViewportOriginCenter(previous: Crop, viewportSize: Size, scale: number) {
  const scaleChange = scale / previous.scale;
  const halfViewportX = viewportSize.width / 2;
  const halfViewportY = viewportSize.height / 2;
  return {
    x: -((-previous.x + halfViewportX) * scaleChange) + halfViewportX,
    y: -((-previous.y + halfViewportY) * scaleChange) + halfViewportY
  };
}

export const EditableCroppedImageLayer = (props: { layerIndex: number; layer: ImageLayerData; editor: PageEditor; photoSize: Size }) => {
  const { layer, editor, layerIndex, photoSize } = props;
  const { width, height } = layer.layout;

  const transformRef = useRef<ReactZoomPanPinchRef>(null);

  const scaleRef = useMemo<ScaleRef>(
    () => ({
      // Min scale ensures the photo covers the layer. Resolution is checked/guaranteed at upload time.
      min: Math.max(width / photoSize.width, height / photoSize.height),

      setScale: scale => {
        if (!transformRef.current) return;
        const state = transformRef.current.state;
        const { x, y } = scaleViewportOriginCenter({ scale: state.scale, x: state.positionX, y: state.positionY }, { width, height }, scale);
        transformRef.current.setTransform(clamp(width - photoSize.width * scale, 0, x), clamp(height - photoSize.height * scale, 0, y), scale);
      },

      frame: { width, height }
    }),
    [width, height, photoSize.height, photoSize.width]
  );

  useEffect(() => {
    editor.updateSurface(surfaceDraft => {
      surfaceDraft.scaleRef = scaleRef;
    });
    return () => {
      editor.updateSurface(surfaceDraft => {
        surfaceDraft.scaleRef = undefined;
      });
    };
    // Reason for eslint-disable: `editor` is not guarantted to be stable, but isn't supposed to change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scaleRef]);

  // When the URL changes `crop` will be undefined. However there is still a moment where `getScalePanToFit`
  // is using the stale `photoSize`. Setting TransformWrapper.key=~photoSize to account for this.
  const crop = layer.imageData.crop ?? getScalePanToFit(layer.layout, photoSize);
  const url = previewUrls.get(layer.imageData.src) ?? absolutePhotoUrl(layer.imageData.src);

  // If there is no scale/panX/panY, we generate a default value.
  // However, this is not written to imageData until an edit (eg drag move) is made.
  // Ideally simply selecting the image layer shouldn't result in the draft being edited.
  return (
    <Box
      style={{
        ...layerLayoutDataToCSS(layer.layout),
        // No overflow hidden here, as TransformComponent already does it.
        cursor: 'move'
      }}
    >
      <TransformWrapper
        key={`${photoSize.width}×${photoSize.height}`} // Reset internal state per unique dimensions
        ref={transformRef}
        disablePadding
        initialScale={crop.scale}
        initialPositionX={crop.x}
        initialPositionY={crop.y}
        minScale={scaleRef.min}
        maxScale={SCALE_MAX}
        onTransformed={(_ref, state) => {
          editor?.updateLayer(layerIndex, draft => {
            if (draft.type !== 'image') return;
            draft.imageData.crop = {
              scale: state.scale,
              x: state.positionX,
              y: state.positionY
            };
          });
        }}
      >
        <TransformComponent wrapperStyle={{ width: layer.layout.width, height: layer.layout.height }}>
          <img alt="the couple" src={url.startsWith(MEDIA_SERVICE) ? `${url}?rendition=medium` : url} width={photoSize.width} height={photoSize.height} />
        </TransformComponent>
      </TransformWrapper>
    </Box>
  );
};
