import { useBottomSheetState } from '@shared/components/BottomSheet';
import { HandleSendEmailArgs } from '@shared/components/EmailsAndEcards/components/Confirm/Confirm.controller';
import { PeopleContext } from '@shared/context/people';
import { ButtonV2, Flex, IconV2, TextV2, TooltipV2 } from '@withjoy/joykit';
import React, { useContext, useState } from 'react';
import { darkButtonOverride } from '../Shared.styles';
import { disabledButtonOverride } from './SendAndTrackView.styles';
import { SendAndTrackEmail } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';

const SendAndTrackView: React.FC = () => {
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tSendAndTrack = tBottomSheet.sendAndTrack;
  const { selectedPeople } = useContext(PeopleContext);
  const { setParentViewState, parentViewState } = useBottomSheetState();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const handleSendEmail = parentViewState?.handleSendEmail as (args: HandleSendEmailArgs) => void | undefined;
  const { transform } = useBottomSheetDescriptionOffset();

  const handleButtonClick = () => {
    setIsSendingEmail(true);
    handleSendEmail?.({
      onCompleted: () => {
        setIsSendingEmail(false);
        setParentViewState(prev => ({ ...prev, isConfirmSheetOpen: true }));
      },
      onError: () => setIsSendingEmail(false)
    });
  };

  const sendToText = selectedPeople.length === 1 ? tSendAndTrack.sendToSingular() : tSendAndTrack.sendTo({ count: selectedPeople.length });

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center">
        <IconV2 size="md" marginRight="12px">
          <SendAndTrackEmail />
        </IconV2>{' '}
        {tSendAndTrack.title()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" flex="1" style={{ transform }}>
        {!!parentViewState?.email ? (parentViewState.email as string) : tSendAndTrack.description()}
      </TextV2>
      {selectedPeople.length || !!parentViewState?.email ? (
        <ButtonV2 overrides={darkButtonOverride} onClick={handleButtonClick} loading={isSendingEmail}>
          {parentViewState?.recipientName ? tSendAndTrack.sendToName({ name: parentViewState.recipientName }) : sendToText}
        </ButtonV2>
      ) : (
        <TooltipV2 content="Select recipients to send your message" placement="top">
          <ButtonV2 overrides={disabledButtonOverride}>{tSendAndTrack.sendToDisabled()}</ButtonV2>
        </TooltipV2>
      )}
    </Flex>
  );
};

export default SendAndTrackView;
