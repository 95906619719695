import React, { forwardRef } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import { TextAreaProps } from '.';
import { animationTransition } from '../../utils';
import { styled, css, ThemeStyledProps } from '@shared/joykit/packages/core/common/styledComponents';

const TextAreaComponent = forwardRef<HTMLElement, TextAreaProps & Pick<TextareaAutosizeProps, 'onHeightChange'>>(({ minimal, resize, error, ...props }, ref) => {
  return <TextareaAutosize ref={ref} {...(props as any)} />;
});

const baseStyles = css`
  border-radius: 5px;
  max-width: 100%;
  outline: none;
  width: 100%;
  transition: ${animationTransition('border-color')};
  ${props => props.theme.typography.variants?.body1};
  font-family: ${props => props.theme.typography.primaryFontFamily};

  :disabled {
    background-color: ${props => props.theme.colors.buttonDisabledFill};
    color: ${props => props.theme.colors.buttonDisabledText};
  }
`;

const plainStyles = (props: ThemeStyledProps<TextAreaProps>) => css`
  ${() => {
    return props.minimal
      ? {
          padding: 0,
          border: 0
        }
      : css`
          padding: ${props.theme.space[5]};
          border: 1px solid ${props.error ? props.theme.colors.buttonDestructiveFill : props.theme.colors.mono4};

          :hover:enabled {
            border-color: ${props.error ? props.theme.colors.buttonDestructiveHover : props.theme.colors.buttonPrimaryHover};
          }
        `;
  }}
`;

const resizeStyles = (props: TextAreaProps) => css`
  resize: ${() => {
    if (typeof props.resize === 'boolean') {
      return props.resize ? 'auto' : 'none';
    }
    return props.resize;
  }};
`;

export const StyledTextArea = styled(TextAreaComponent)`
  ${baseStyles}
  ${plainStyles};
  ${resizeStyles}
`;

export const TextAreaContainer = styled.div<Pick<TextAreaProps, 'disabled' | 'error' | 'minimal'> & { resizeEnabled: boolean; focused: boolean }>`
  position: relative;
  ${props => {
    if (!props.disabled && !props.minimal && !props.resizeEnabled) {
      return css`
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          opacity: 0;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          border: 3px solid ${props.error ? props.theme.colors.buttonDestructiveActive : props.theme.colors.buttonPrimaryActive || 'transparent'};
          border-radius: 5px;
          pointer-events: none;
          transition: ${animationTransition('border-color', 'opacity')};
          opacity: ${props.focused ? 1 : 0};
        }
      `;
    }
    return null;
  }}
`;
