import { AutoPopulateAccommodationPlacesPayload, useAutoPopulateAccommodationPlacesMutation } from '@graphql/generated';
import { addAction } from '@shared/utils/logger';

interface AutoPopulateAccommodationPlacesArgs {
  onCompleted?: () => void;
  onError?: () => void;
}

export const useAutoPopulateAccommodationPlaces = ({ onCompleted, onError }: AutoPopulateAccommodationPlacesArgs) => {
  const [autoPopulateAccommodationPlaces, { loading }] = useAutoPopulateAccommodationPlacesMutation({ onCompleted, onError });

  const callAutoPopulateAccommodationPlaces = (payload: AutoPopulateAccommodationPlacesPayload) => {
    return new Promise(resolve => {
      autoPopulateAccommodationPlaces({ variables: { payload } })
        .then(response => {
          if (response.data) {
            addAction('autoPopulateAccommodationPlaces', {
              message: 'success',
              context: 'AutoPopulate',
              eventId: payload.eventId,
              latitude: payload.location?.latitude,
              longitude: payload.location?.longitude,
              placesCount: response.data?.autoPopulateAccommodationPlaces?.length
            });
            resolve(true);
          }
          if (response.errors) {
            const message = response.errors[0].message;
            addAction('autoPopulateAccommodationPlacesError', {
              message,
              context: 'AutoPopulate',
              eventId: payload.eventId,
              latitude: payload.location?.latitude,
              longitude: payload.location?.longitude
            });
            resolve(true);
          }
        })
        .catch(error => {
          const message = error instanceof Error ? error.message : '';
          addAction('autoPopulateAccommodationPlacesError', {
            message,
            context: 'AutoPopulate',
            eventId: payload.eventId,
            latitude: payload.location?.latitude,
            longitude: payload.location?.longitude
          });
          resolve(true);
        });
    });
  };

  return { callAutoPopulateAccommodationPlaces, loading };
};
