import React from 'react';
import { Flex } from '@withjoy/joykit';
import { NoSsr } from '@shared/components/NoSsr';
import { StyledHamburger, styles } from './MobileView.styles';
import { MobileViewProvider } from './MobileView.provider';
import { MobileMenuDrawer } from './components/MobileMenuDrawer';
import type { MarcomAppNavBarMobileProps } from '../../AppNavBar.types';
import { pxToRem } from '@withjoy/joykit/theme';

export const MobileView: React.FC<MarcomAppNavBarMobileProps> = props => {
  const { setMobileNavIsOpen } = props;
  return (
    <MobileViewProvider {...props}>
      <Flex alignItems="center" height={pxToRem(41)}>
        <StyledHamburger onClick={() => setMobileNavIsOpen(true)} __css={styles.hamburger} />
      </Flex>
      <NoSsr>
        <MobileMenuDrawer />
      </NoSsr>
    </MobileViewProvider>
  );
};

MobileView.displayName = 'MarcomAppNavBarMobile';
