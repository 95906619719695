import { createTelemetry, createTelemetryObject } from '@shared/core';

const category = 'newmarketing.shop';

const telemObject = createTelemetryObject({
  actions: {
    wwwNavigate: ({ elementId, nodeText, to }: { elementId: string; nodeText: string; to: string }) => ({
      category,
      action: 'WWWNavigate',
      label: elementId,
      extraInfo: {
        elementId,
        text: nodeText,
        target: to
      }
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(telemObject);

export { TelemetryProvider as MarcomAppTelemetryProvider, useTelemetry as useMarcomAppTelemetry };
