import React from 'react';
import { Choice, ChoiceProps } from '../Choice';
import { StyledInput, Indicator } from './';
import { generateComponentDisplayName } from '../../utils';
import { CHOICE_INDICATOR } from '../../common';

export type Intent = 'primary' | 'neutral';
export interface ToggleProps extends ChoiceProps {
  children?: React.ReactNode;
  intent?: Intent;
}

const Toggle: React.FC<ToggleProps> = React.memo(props => {
  const indicatorMarkup = <Indicator className={CHOICE_INDICATOR} />;
  return (
    <Choice {...props} indicator={indicatorMarkup}>
      <StyledInput {...props} />
    </Choice>
  );
});

Toggle.displayName = generateComponentDisplayName('Toggle');
Toggle.defaultProps = {
  alignIndicator: 'right'
};

export default Toggle;
