import { useState, useEffect } from 'react';

export const useShouldShowOnScrollYPosition = (threshold: number) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const handler = () => {
      const currentScroll = window.scrollY;
      setShouldShow(currentScroll >= (threshold || 100));
    };

    handler();

    document.addEventListener('scroll', handler);

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, [threshold]);

  return shouldShow;
};
