import React, { Fragment } from 'react';
import { ButtonV2, TextV2, CancelButton } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { Dialog, Transition } from '@headlessui/react';
import { DialogSection, StyledDialog } from './UnsavedChangesDialog.styles';

export interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    warningMessageContent?: string;
  }> {}

export const UnsavedChangesDialog: React.FC<Props> = props => {
  const { isOpen, onClose, onConfirm, warningMessageContent = 'digital message' } = props;
  const { t } = useTranslation('emailsAndEcards');
  const tUnsavedChangesDialog = t('unsavedChangesDialog');
  return (
    <Transition appear show={isOpen} as={Fragment}>
      {/* @ts-ignore */}
      <StyledDialog onClose={onClose}>
        <Transition.Child as={Fragment} enter="transition" enterFrom="opacity-0" enterTo="opacity-100" leave="transition" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="overlay" />
        </Transition.Child>
        <Transition.Child as={Fragment} enter="transition" enterFrom="opacity-0" enterTo="opacity-100" leave="transition" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Dialog.Panel className="panel">
            <DialogSection>
              <TextV2 typographyVariant="hed1">{tUnsavedChangesDialog.unsavedChangesWarningHed()}</TextV2>
            </DialogSection>
            <DialogSection>
              <TextV2 typographyVariant="body2">{tUnsavedChangesDialog.unsavedChangesWarningDek({ warningMessageContent })}</TextV2>
            </DialogSection>
            <DialogSection flex>
              <CancelButton marginRight={3} onClick={onClose}>
                {tUnsavedChangesDialog.cancelButtonText()}
              </CancelButton>
              <ButtonV2 intent="destructive" onClick={onConfirm}>
                {tUnsavedChangesDialog.discardButtonText()}
              </ButtonV2>
            </DialogSection>
          </Dialog.Panel>
        </Transition.Child>
      </StyledDialog>
    </Transition>
  );
};
