import React, { useState } from 'react';
import { getCount, getImagesFromStorage, reserveImageIndex } from '../ImageStorageManager';
import { previewReducerAction } from './usePreviewReducer';
import AICardPreviewContainer from '../../AICardPreview/AICardPreview';
import { genQuantity } from '../ImagineYourCardRoot';
import AICardDummyContainer from '../../AICardPreview/AICardDummy';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { seed, theme } from './useImagineOptions';
import AICardReimaginedContainer from '../../AICardPreview/AICardReimagined';

type usePreviewsProps = {
  previewDispatcher: React.Dispatch<previewReducerAction>;
  themes: theme[];
  cardType: StationeryTemplateCategoryEnum;
  seeds: seed[];
  styles: string[];
  styleOverride?: string | null;
  themeOverride?: string | null;
};

export const usePreviews = (props: usePreviewsProps) => {
  const { previewDispatcher, themes, cardType, seeds, styles, styleOverride, themeOverride } = props;
  const [loadingCount, setLoadingCount] = useState(0);
  const count = getCount();

  const addImage = (prompt: string) => {
    if (loadingCount != 0) {
      return;
    }

    for (let i = 0; i < genQuantity; i++) {
      const themeIndex = i + count;

      const themesSubset = themes.filter(theme => {
        return theme.category === cardType;
      });

      const themesLength = themesSubset.length;
      let theme = themesSubset[themeIndex % themesLength];

      let m: string | undefined;
      let oomph: number | undefined;

      let resource: string | undefined = undefined;
      const index = reserveImageIndex();

      const stylesLength = styles.length;
      const seed = (seeds ?? []).find(seed => {
        return seed.prompt === prompt;
      });

      if (seed) {
        const themeSeed = seed.themes.find(t => {
          return t.themeID === theme.themeID && t.category === theme.category;
        });

        if (themeSeed) {
          theme = themeSeed;
          resource = themeSeed.resourceID;
          oomph = themeSeed.oomph ? Number(themeSeed.oomph) : undefined;
          m = themeSeed.m;
        }
      }

      if (themeOverride) {
        const newTheme = themesSubset.find(t => t.themeID === themeOverride);
        if (newTheme) {
          theme = newTheme;
        }
      }

      let style = styles[themeIndex % stylesLength];
      if (styleOverride) {
        style = styleOverride;
      }

      let newPreview = (
        <AICardPreviewContainer
          key={index}
          promptPayload={{ prompt, style: style }}
          themeID={theme.themeID}
          category={theme.category}
          index={index}
          setLoadingCount={setLoadingCount}
        ></AICardPreviewContainer>
      );

      if (resource) {
        newPreview = (
          <AICardReimaginedContainer
            key={index}
            promptPayload={{ prompt, style: style }}
            themeID={theme.themeID}
            category={theme.category}
            index={index}
            setLoadingCount={setLoadingCount}
            resourceID={resource}
            m={m}
            oomph={oomph}
          ></AICardReimaginedContainer>
        );
      }

      previewDispatcher({
        type: 'addPreview',
        newPreview
      });
    }
  };

  const addLocalStorage = () => {
    const lsContents = getImagesFromStorage();

    if (!lsContents) {
      return;
    }

    for (let i = lsContents.length - 1; i >= 0; i--) {
      const lsItem = lsContents[i];

      previewDispatcher({
        type: 'addDummy',
        newPreview: (
          <AICardDummyContainer
            resourceID={lsItem.resourceID}
            index={i}
            prompt={lsItem.promptPayload!}
            themeID={lsItem.themeID}
            category={lsItem.category}
            url={lsItem.url}
          ></AICardDummyContainer>
        )
      });
    }
  };

  return { addImage, addLocalStorage, loadingCount, setLoadingCount };
};
