import { useCallback, useMemo } from 'react';
import { monorepoRoutePaths } from '@apps/admin/route.paths';
import { ShareDialogProps } from '@apps/admin/routes/DashboardPreview/components/ShareDialog';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useDashboardPreviewTelemetry } from '@apps/admin/routes/DashboardPreview/DashboardPreview.telemetry';
import { useToast } from '@withjoy/joykit';
import { withWindow } from '@shared/utils/withWindow';

export const useShareDialogController = (props: ShareDialogProps) => {
  const { guestSiteLink, eventPassword, eventHandle, onClose } = props;
  const { t, t2 } = useTranslation('shareDialog');
  const { dialogTitle, guestDialogTitle, editLinkText, copy, buttonName, eventPasswordText, joyUrlText } = t2('text');
  const { toast } = useToast();
  const routeHelpers = useRouterHelper();
  const telemetry = useDashboardPreviewTelemetry();

  const onCloseDialogHandle = () => {
    telemetry.shareDialogClose();
    onClose();
  };

  const publishToast = useEventCallback(copyText => {
    return toast(t('text', 'toastText')({ copyText }));
  });

  const settingsGeneralUrl = useMemo(() => withWindow(global => `${global.location.origin}/${eventHandle}/edit/settings/general`, ''), [eventHandle]);
  const settingsSecurityUrl = useMemo(() => withWindow(global => `${global.location.origin}/${eventHandle}/edit/settings/security`, ''), [eventHandle]);

  const resolveEditLink = useCallback(
    (path: string, isGeneralLink: boolean) => {
      return eventHandle ? (isGeneralLink ? settingsGeneralUrl : settingsSecurityUrl) : path;
    },
    [eventHandle, settingsGeneralUrl, settingsSecurityUrl]
  );

  const shareOptions = [
    {
      title: joyUrlText,
      onCopy: () => {
        publishToast(joyUrlText);
        telemetry.shareDialogCopy(joyUrlText);
      },
      text: guestSiteLink,
      editLink: resolveEditLink(routeHelpers.buildPath(monorepoRoutePaths.settingsGeneral), true),
      onEditLinkClick: () => telemetry.shareDialogEdit(joyUrlText)
    },
    {
      title: eventPasswordText,
      onCopy: () => {
        publishToast(eventPasswordText);
        telemetry.shareDialogCopy(eventPasswordText);
      },
      text: eventPassword,
      editLink: resolveEditLink(routeHelpers.buildPath(monorepoRoutePaths.settingsSecurity), false),
      onEditLinkClick: () => telemetry.shareDialogEdit(eventPasswordText)
    }
  ];

  const guestShareOptions = [
    {
      title: joyUrlText,
      onCopy: () => {
        publishToast(joyUrlText);
        telemetry.shareDialogCopy(joyUrlText);
      },
      text: guestSiteLink,
      editLink: routeHelpers.buildPath(monorepoRoutePaths.settingsGeneral),
      onEditLinkClick: () => telemetry.shareDialogEdit(joyUrlText)
    }
  ];

  const onOpenNewTab = () => {
    telemetry.guestSiteViewOpen();
    window.open(guestSiteLink, '_blank');
  };

  return {
    shareOptions,
    guestShareOptions,
    dialogTitle,
    guestDialogTitle,
    copy,
    editLinkText,
    eventPasswordText,
    buttonName,
    onOpenNewTab,
    onCloseDialogHandle
  };
};
