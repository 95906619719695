import React from 'react';
import { useTranslation } from '@shared/core';
import { useSignInController } from '@apps/createWedding/routes/SignIn/SignIn.controller';
import { SignInWrapperStack, CreateAccountLink } from '@apps/createWedding/routes/SignIn/SignIn.styles';
import { ButtonV2, FormControl, InputV2, LinkV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { Email, Lock } from '@withjoy/joykit/icons';
import { wrapWithSignInHealthProvider } from './SigIn.health';

export const SignIn = wrapWithSignInHealthProvider(() => {
  const { t2 } = useTranslation('createWedding');
  const { getFieldErrorProps, formik, goToForgotPassword, goToCreateAccount, submissionError, focusHandlers } = useSignInController();

  const signInTranslations = t2('signIn');

  return (
    <SignInWrapperStack spacing={8}>
      <SpacingStack alignItems="center" spacing={1}>
        <TextV2 textAlign="center" typographyVariant="hed6">
          {signInTranslations.heroText}
        </TextV2>
        <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
          {signInTranslations.helpText}{' '}
          <CreateAccountLink onClick={goToCreateAccount} textDecoration="none">
            <TextV2 as="span" typographyVariant="body3" color="linkText">
              {signInTranslations.createAccount}
            </TextV2>
          </CreateAccountLink>
        </TextV2>
      </SpacingStack>
      <SpacingStack spacing={6}>
        <form onSubmit={formik.handleSubmit}>
          <SpacingStack spacing={8}>
            <SpacingStack spacing={6}>
              <FormControl {...getFieldErrorProps('email')}>
                <InputV2
                  startElement={<Email color="mono10" fill="#767676" size={24} />}
                  placeholder={signInTranslations.email}
                  onFocus={focusHandlers.email}
                  {...formik.getFieldProps('email')}
                />
              </FormControl>
              <FormControl {...getFieldErrorProps('password')}>
                <InputV2
                  startElement={<Lock color="mono10" fill="#767676" size={24} />}
                  type="password"
                  placeholder={signInTranslations.password}
                  onFocus={focusHandlers.password}
                  {...formik.getFieldProps('password')}
                />
              </FormControl>
              {submissionError && (
                <TextV2 textAlign="center" typographyVariant="body1" color="negative6">
                  {submissionError}
                </TextV2>
              )}
            </SpacingStack>
            <ButtonV2 disabled={formik.isSubmitting} intent="primary" type="submit">
              {signInTranslations.login}
            </ButtonV2>
          </SpacingStack>
        </form>
        <LinkV2 textAlign="center" onClick={goToForgotPassword} textDecoration="none">
          <TextV2 typographyVariant="button2" color="linkText">
            {signInTranslations.forgotCredentials}
          </TextV2>
        </LinkV2>
      </SpacingStack>
    </SignInWrapperStack>
  );
});
