import { ButtonV2, CloseButtonProps, css, defaultTheme, DrawerV2Props, Flex, styled, TextV2 } from '@withjoy/joykit';
import { baseState } from '@apps/admin/routes/Dashboard/common/commonDasboard.styles';

export const DialogHeader = styled(Flex)<{ customHeader?: boolean }>`
  align-items: center;
  justify-content: space-between;
  padding: ${({ customHeader }) => (customHeader ? '1rem 2rem 0rem' : '1rem 2rem')};
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    padding: ${({ customHeader }) => (customHeader ? '1.5rem 2rem 0' : '1.5rem 2rem')};
  }
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`;

export const DialogTitle = styled(TextV2)`
  ${({ theme }) => theme.typography.variants.hed3};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.hed4};
  }
`;

export const DialogBody = styled.div`
  padding: 2rem;
`;

export const DialogFooter = styled(Flex)`
  border-top: 0.0625rem solid ${({ theme }) => theme.colors.mono3};
  justify-content: space-between;
  padding: 1.5rem 2rem;
`;

export const baseDialogButton = css`
  ${({ theme }) => theme.typography.variants.button2}
  align-items: center;
  border-radius: 0.25rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  width: 7.75rem;
`;

export const DialogBaseButton = styled.button`
  ${baseDialogButton};
`;

export const DialogPrimaryButton = styled(ButtonV2)`
  ${baseDialogButton};
  background: ${({ theme }) => theme.colors.primary8};
  color: ${({ theme }) => theme.colors.white};
  outline-color: transparent;
  padding: 0 1.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary7};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.primary8};
    outline-color: ${({ theme }) => theme.colors.primary7};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary9};
  }
`;

export const DialogSecondaryButton = styled(DialogBaseButton)`
  ${baseState};
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    color: ${({ theme }) => theme.colors.mono12};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.colors.black};
    outline: 0.125rem solid ${({ theme }) => theme.colors.primary7};
  }

  &:active {
    color: ${({ theme }) => theme.colors.mono13};
  }
`;

export const closeButtonOverrides: NonNullable<CloseButtonProps['overrides']> = {
  Root: {
    props: {
      borderRadius: '50%',
      border: `0.0625rem solid ${defaultTheme.colors.mono5}`,
      height: '2.5rem',
      minWidth: '2.5rem',
      outline: `0.125rem solid ${defaultTheme.colors.transparent}`,
      padding: 0,
      transition: 'background-color 0.2s, outline 0.2s, color 0.2s, opacity 0.2s',
      width: '2.5rem',
      _focusVisible: {
        background: defaultTheme.colors.white,
        outline: `0.125rem solid ${defaultTheme.colors.primary8}`
      },
      _hover: {
        background: defaultTheme.colors.mono3
      },
      _active: {
        background: defaultTheme.colors.mono5
      }
    }
  },
  Icon: {
    props: {
      color: defaultTheme.colors.black
    }
  }
};

export const drawerOverrides: NonNullable<DrawerV2Props['overrides']> = {
  Root: {
    props: {
      background: 'rgb(0 0 0 / 0.3)'
    }
  },
  Content: {
    props: {
      height: 'unset',
      borderRadius: '0.5rem 0.5rem 0 0',
      width: '100vw',
      padding: '0',
      maxHeight: '100%',
      overflow: 'auto'
    }
  }
};
