import { useAuth } from '@shared/components/AuthProvider';
import { EventUserStatus } from '@graphql/generated';
import { getLocalStorage } from '@shared/core';
import { PENDING_CREATION_KEY } from '@apps/createWedding/routes/CreateWeddingForm/CreateWeddingForm.controller';
import { useMemo } from 'react';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';

export const useWeddingAlreadyExistsController = () => {
  const authProvider = useAuth();
  const ownedEvent = authProvider.currentUser.eventMemberships.find(event => event.status === EventUserStatus.owner);
  const localStorage = getLocalStorage();

  if (localStorage.getItem(PENDING_CREATION_KEY)) {
    localStorage.setItem(PENDING_CREATION_KEY, undefined);
  }

  // The data about website we can safely assume it exists
  // and calculate it only on component mount.
  // It's correlated to deeper issue with signing out
  // which firstly removes the user from auth provider and then reloads the page.
  const website = useMemo(() => ownedEvent?.event?.website, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { website, signOut: () => authProvider.provideLogout({}, `${window.location.origin}/${CREATE_WEDDING_APP_NAME}`) };
};
