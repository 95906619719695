import { createImmerContext } from './createImmerContext';

export type ScaleRef = {
  min: number;
  setScale: (scale: number) => void;
  frame: { width: number; height: number };
};

export interface SurfaceState {
  scale: number;
  hoverLayerIndex: number | undefined;
  activeLayerIndex: number | undefined;
  editingLayerIndex: number | undefined;
  scaleRef: ScaleRef | undefined;
}

const [SurfaceProvider, useSurface] = createImmerContext<SurfaceState>(() => ({
  scale: 1,
  hoverLayerIndex: undefined,
  activeLayerIndex: undefined,
  editingLayerIndex: undefined,
  scaleRef: undefined
}));

export { SurfaceProvider, useSurface };
