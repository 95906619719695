import { useState, useEffect } from 'react';
import { EcardLandingRightPanelTabItem } from '@apps/ecard/Ecard.types';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { EcardType } from '@graphql/generated';

interface UseEcardLandingControllerArgs
  extends Readonly<{
    ecardDraftType: EcardType;
  }> {}

export const useEcardLandingController = (args: UseEcardLandingControllerArgs) => {
  const { ecardDraftType } = args;
  const [selectedTab, setSelectedTab] = useState<EcardLandingRightPanelTabItem>(EcardLandingRightPanelTabItem.ECARD);
  const { eCardLandingPageTrack } = useEcardsTelemetry();

  useEffect(() => {
    eCardLandingPageTrack.enter(ecardDraftType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedTab,
    setSelectedTab
  };
};
