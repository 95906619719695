import React from 'react';

import { styled } from '../../../../common';
import { animationTransition } from '../../../../utils';

const StyledSvg = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* path {
    transition: ${animationTransition('fill')};
  } */
`;
/* tslint:disable:max-line-length */

export const CheckSVG: React.FC<{ fill?: string }> = ({ fill = 'currentColor' }) => {
  return (
    <StyledSvg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7075 1.55734L4.85045 7.78111C4.80672 7.81854 4.76921 7.84584 4.73027 7.8711C4.67196 7.90651 4.63178 7.92587 4.59 7.94246C4.52831 7.96552 4.48458 7.97769 4.44067 7.98562C4.37969 7.99576 4.33311 8 4.28653 8C4.25737 8 4.22822 7.99871 4.19977 7.99502C4.15675 7.98857 4.13524 7.98562 4.11444 7.98137C4.06786 7.96902 4.04901 7.96478 4.03035 7.95832C3.98234 7.94099 3.96581 7.93454 3.9491 7.92734C3.9379 7.92236 3.92759 7.9172 3.91639 7.91148C3.9011 7.90355 3.88581 7.89563 3.87123 7.8877C3.8595 7.88124 3.8483 7.87405 3.83728 7.86686C3.82341 7.85819 3.81025 7.84879 3.79692 7.83938C3.78519 7.83071 3.77399 7.82223 3.76225 7.81356C3.75052 7.80342 3.7379 7.79328 3.72687 7.78332C3.71567 7.77318 3.70465 7.76304 3.69345 7.75234C3.68865 7.74791 0.251072 4.18442 0.251072 4.18442C-0.0836906 3.83718 -0.0836906 3.27455 0.251072 2.92749C0.585834 2.58025 1.12824 2.58025 1.46283 2.92749L4.32475 5.89591L10.5784 0.220384C10.9347 -0.103805 11.4756 -0.0656324 11.7882 0.303921C12.0993 0.672737 12.0637 1.23389 11.7075 1.55734Z"
        fill={fill}
      />
    </StyledSvg>
  );
};
