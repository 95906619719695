import React from 'react';
import { examplePromptData } from '../Components/HintText/FirstTimeExperienceConfig';
import AICardDummyContainer from '../Components/AICardPreview/AICardDummy';
import { addImageToStorage } from '../Components/ImagineYourCardRoot/ImageStorageManager';
import { previewReducerAction } from '../Components/ImagineYourCardRoot/hooks/usePreviewReducer';

type addStarterCardsProps = {
  previewDispatcher: React.Dispatch<previewReducerAction>;
};

export const useAddStarterCards = (props: addStarterCardsProps) => {
  const { previewDispatcher } = props;

  const prepLocalStorage = () => {
    const prompts = examplePromptData;
    prompts.forEach((prompt, index) => {
      for (let i = prompt.images.length - 1; i >= 0; i--) {
        const image = prompt.images[i];
        addImageToStorage({
          url: image.src,
          themeID: image.theme,
          category: image.category,
          promptPayload: {
            prompt: prompt.prompt,
            style: image.style
          },
          resourceID: image.resourceID,
          demo: true
        });
      }
      // examplePromptData.splice(index, 1);
    });
  };

  const addCards = (reverseOrder?: boolean) => {
    const prompts = examplePromptData;
    prompts.forEach((prompt, index) => {
      for (let i = prompt.images.length - 1; i >= 0; i--) {
        const image = prompt.images[i];
        previewDispatcher({
          type: 'addPreview',
          newPreview: (
            <AICardDummyContainer
              themeID={image.theme}
              category={image.category}
              url={image.src}
              prompt={{
                prompt: prompt.prompt,
                style: image.style
              }}
              index={prompt.images.length - 1 - i}
              resourceID={image.resourceID}
            />
          )
        });
      }
      // examplePromptData.splice(index, 1);
    });
  };

  return { addCards, prepLocalStorage };
};
