import React from 'react';
import { CardType, CardTypeRow } from './CardTypeSelector.styles';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

export type Category = {
  name: string;
  category: StationeryTemplateCategoryEnum;
};

const defaultCategoryList = [
  {
    name: 'Save The Dates',
    category: StationeryTemplateCategoryEnum.saveTheDate
  },
  {
    name: 'Invitations',
    category: StationeryTemplateCategoryEnum.invitation
  }
];

type CardTypeSelectorProps = {
  cardType: StationeryTemplateCategoryEnum;
  onSelectType: (type: StationeryTemplateCategoryEnum) => void;
  categoryList?: Category[];
};

export const CardTypeSelector = ({ cardType, onSelectType, categoryList }: CardTypeSelectorProps) => {
  return (
    <CardTypeRow>
      {(categoryList ?? defaultCategoryList).map(({ name, category }, index) => {
        return (
          <CardType
            key={index}
            typographyVariant="body1"
            style={{ borderBottom: category === cardType ? '2px solid black' : '' }}
            onClick={() => {
              onSelectType(category);
            }}
          >
            {name}
          </CardType>
        );
      })}
    </CardTypeRow>
  );
};
