import React from 'react';
import { styled, ThemeStyledProps, css } from '@shared/joykit/packages/core/common/styledComponents';
import { StackProps } from '.';
import { getStyledBaseComponentProps, getStyledMargin, getStyledFlexDirection } from '../../common/styledSystem/styledFunctions';
import { Box } from '../Box';

export const getStyledStackVertical = (props: ThemeStyledProps<StackProps>) => {
  return getStyledFlexDirection({
    theme: props.theme,
    flexDirection: [props.vertical && props.stackVerticalOnMobile ? 'column' : undefined, null, props.vertical ? 'column' : 'row']
  });
};

const responsiveSpaceStyle = (props: ThemeStyledProps<StackProps>) => {
  let spacing = props.theme.space[props.spacing!];
  spacing = spacing || (typeof props.spacing === 'number' ? `${props.spacing}px` : spacing);
  const isVertical = props.vertical;
  const verticalSpacing = `${spacing} 0 0 0`;
  const horizontalSpacing = `0 0 0 ${spacing}`;
  return getStyledMargin({
    theme: props.theme,
    margin: [props.stackVerticalOnMobile ? verticalSpacing : horizontalSpacing, null, isVertical ? verticalSpacing : horizontalSpacing]
  });
};

const StackComponent: React.FC<StackProps & { fowardedRef?: React.Ref<HTMLDivElement> }> = props => {
  const { vertical, spacing, margin, stackVerticalOnMobile, fowardedRef, ...rest } = props;
  return (
    <Box className={props.className} ref={props.fowardedRef} {...rest}>
      {props.children}
    </Box>
  );
};

const verticalStyle = (props: ThemeStyledProps<StackProps>) => {
  return css`
    ${getStyledStackVertical(props)};
    display: flex;
    /* justify-content: flex-start;
    align-items: flex-start; */
    max-width: 100%;
    > *:not(:first-child) {
      ${responsiveSpaceStyle(props)}
    }
  `;
};

export const StyledStack = styled(StackComponent)`
  ${getStyledBaseComponentProps}
  ${verticalStyle}
`;
