import { LinkV2, styled } from '@withjoy/joykit';

export const StyledForm = styled.form`
  display: flex;
  justify-content: center;
`;

export const InheritSizeLinkTerms = styled(LinkV2)`
  font-size: inherit;
  text-decoration-color: ${({ theme }) => theme.colors.mono5};
  color: ${({ theme }) => theme.colors.mono10};
  :hover {
    text-decoration-color: ${({ theme }) => theme.colors.mono8};
  }
  :visited,
  :active {
    text-decoration-color: ${({ theme }) => theme.colors.mono14};
  }
`;
