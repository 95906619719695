import React, { createContext, useContext, useState } from 'react';

interface EmailEditorContext {
  allowSidePannedOutsideClickHandle: boolean;
  setAllowSidePannedOutsideClickHandle: (value: boolean) => void;
}

const EmailEditorContext = createContext<EmailEditorContext>({
  allowSidePannedOutsideClickHandle: true,
  setAllowSidePannedOutsideClickHandle: () => undefined
});

export const EmailEditorContextProvider: React.FC = ({ children }) => {
  const [allowSidePannedOutsideClickHandle, setAllowSidePannedOutsideClickHandle] = useState(true);

  return <EmailEditorContext.Provider value={{ allowSidePannedOutsideClickHandle, setAllowSidePannedOutsideClickHandle }}>{children}</EmailEditorContext.Provider>;
};

export const useEmailEditorContext = () => useContext(EmailEditorContext);
