import React from 'react';
import { Box, ButtonV2, DialogV2, DrawerV2, Flex, TextV2 } from '@withjoy/joykit';
import { templateToTitle } from '@apps/card/Card.utils';
import { useTranslation } from '@shared/core';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { MinimalStationeryTemplate } from './types';
import { AssetPreview } from '../GalleryTile';

interface ExistingDraftsWarningDialogProps {
  isOpen: boolean;

  template: MinimalStationeryTemplate;
  variant: 'single' | 'multiple';

  handleOnClose: () => void;
  handleOnViewOrEditExistingDraftClick: () => void;
  handleOnCreateNewDraftClick: () => void;
}

const BodyContent = (props: ExistingDraftsWarningDialogProps) => {
  const { template, variant, handleOnCreateNewDraftClick, handleOnViewOrEditExistingDraftClick } = props;

  const { thumbnailUrl } = template;

  const { t } = useTranslation('stationery');

  const translations = t('dashboard', 'existingDraftsWarningDialog');

  const variantTranslation = translations.variants[variant];

  return (
    <Flex flexDirection={{ _: 'column', md: 'row' }}>
      <Box
        display={{ _: 'flex', md: 'block' }}
        justifyContent="center"
        width={{ md: '312px' }}
        backgroundColor="mono1"
        paddingX={6}
        borderTopLeftRadius={12}
        borderBottomLeftRadius={12}
        borderTopRightRadius={0}
        paddingY={{ _: 8, md: 80 }}
        flexShrink={0}
        height={{ _: '332px', md: 'unset' }}
      >
        <AssetPreview assetUrl={thumbnailUrl ?? ''} />
      </Box>
      <Flex flexDirection="column" padding={{ _: 7, md: 'unset' }} paddingX={{ md: 8 }} paddingY={{ md: 9 }} alignItems="flex-start" justifyContent="center">
        <TextV2 typographyVariant="hed6" fontWeight="semibold">
          {templateToTitle(template)}
        </TextV2>

        <TextV2 marginTop={5} typographyVariant="body2">
          {variantTranslation.message()}
        </TextV2>
        <ButtonV2 onClick={handleOnViewOrEditExistingDraftClick} marginTop={7} intent="neutral" fullWidth={true} shape="rounded">
          {variantTranslation.ctaText()}
        </ButtonV2>
        <ButtonV2 onClick={handleOnCreateNewDraftClick} marginTop={5} intent="neutral" variant="outline" shape="rounded" fullWidth={true}>
          {translations.startNewDraftCtaText()}
        </ButtonV2>
      </Flex>
    </Flex>
  );
};

const DesktopView = (props: ExistingDraftsWarningDialogProps) => {
  const { isOpen, handleOnClose } = props;

  return (
    <DialogV2
      size={'2xl'}
      disableAutoFocus
      isOpen={isOpen}
      onClose={handleOnClose}
      overrides={{
        Body: {
          props: {
            paddingX: 0,
            paddingY: 0
          }
        },
        CloseButton: {
          props: {
            color: 'black',
            top: 4,
            right: 4,
            padding: 0,
            borderRadius: '100%'
          }
        }
      }}
    >
      <DialogV2.CloseButton />
      <DialogV2.Body>
        <BodyContent {...props} />
      </DialogV2.Body>
    </DialogV2>
  );
};

const MobileView = (props: ExistingDraftsWarningDialogProps) => {
  const { isOpen, handleOnClose } = props;

  return (
    <DrawerV2
      anchor="bottom"
      size={640}
      disableAutoFocus
      isOpen={isOpen}
      onClose={handleOnClose}
      overrides={{
        Content: {
          props: {
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3
          }
        },
        Body: {
          props: {
            paddingX: 0,
            paddingY: 0
          }
        },
        CloseButton: {
          props: {
            color: 'black',
            top: 4,
            right: 4,
            backgroundColor: 'white',
            padding: 0,
            borderRadius: '100%'
          }
        }
      }}
    >
      <DrawerV2.CloseButton />
      <DrawerV2.Body>
        <BodyContent {...props} />
      </DrawerV2.Body>
    </DrawerV2>
  );
};

export const ExistingDraftsWarningDialog = (props: ExistingDraftsWarningDialogProps) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  return isMobileOrTablet ? <MobileView {...props} /> : <DesktopView {...props} />;
};
