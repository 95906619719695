import { useEffect } from 'react';
import useInView from './useInView';

// Returns a ref to be attatched to a video container.
// Accepts play and pause callbacks, which are triggered as
// the container enters and exits the screen.
export const usePauseOffScreen = (playVideo: () => void, pauseVideo: () => void, disable?: boolean) => {
  const [ref, isInView] = useInView();

  useEffect(() => {
    if (!disable) {
      if (isInView) {
        playVideo();
      } else {
        pauseVideo();
      }
    }
  }, [disable, isInView, pauseVideo, playVideo]);

  return [ref];
};
