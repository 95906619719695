import React, { useMemo } from 'react';
import { PhotoECardContainer, PhotoECardWrapper, PhotoECardScalableContainer } from './PhotoECardPreviewWrapper.styles';
import { Box, Flex, TextV2, TooltipV2, LinkV2 } from '@withjoy/joykit';
import { Info } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import { useEcardScaling } from '@shared/components/EmailsAndEcards/hooks/useEcardScaling';
import { PHOTO_ECARD_DIMENSIONS } from '@apps/saveTheDate/constants';

interface PhotoECardPreviewWrapperProps
  extends Readonly<{
    isECardEditPanelShown: boolean;
    isGuestView: boolean;
    guestNames?: string;
    onLogoutEventHandle?: () => void;
    isAdjustingPosition?: boolean;
    showNotYou: boolean;
  }> {}

export const PhotoECardPreviewWrapper: React.FC<PhotoECardPreviewWrapperProps> = ({
  isAdjustingPosition,
  isECardEditPanelShown,
  isGuestView,
  guestNames,
  onLogoutEventHandle,
  children,
  showNotYou
}) => {
  const namesListStr = !!guestNames ? guestNames : '[First Name(s)]';
  const { t, t2 } = useTranslation('emailsAndEcards');
  const tEcardGuestToolTip = t('ecardGuest', 'toolTip');
  const tGenericEcard = t2('genericEcard');
  const tAdjustment = t2('ecardEditorPhotoSaveTheDate', 'ecardPhotoAdjustmentEditor');

  const { ecardScalingState, nodeRef } = useEcardScaling({
    containerBaseWidth: PHOTO_ECARD_DIMENSIONS.width + 24
  });

  const { scale, height } = ecardScalingState;

  const adjustmentTitle = useMemo(
    () => (
      <Flex alignItems="center" marginTop="32px">
        <TextV2 typographyVariant="hed3">{tAdjustment.title}</TextV2>
      </Flex>
    ),
    [tAdjustment]
  );

  const guestViewTitle = useMemo(
    () => (
      <Flex marginTop="48px" alignItems="center" justifyContent={showNotYou ? 'space-between' : 'center'} maxWidth="500px" width="100%">
        {guestNames && (
          <>
            <TextV2 display="flex" typographyVariant="body1">
              <Box as="span" color="mono14">
                {tGenericEcard.toPerson} {namesListStr}
              </Box>
            </TextV2>
            {showNotYou && (
              <Box marginLeft="32px">
                <LinkV2 typographyVariant="body1" color="mono12" onClick={onLogoutEventHandle} whiteSpace="nowrap">
                  {tGenericEcard.notYou}
                </LinkV2>
              </Box>
            )}
          </>
        )}
      </Flex>
    ),
    [namesListStr, onLogoutEventHandle, tGenericEcard.notYou, tGenericEcard.toPerson, showNotYou, guestNames]
  );

  const defaultTitle = useMemo(
    () => (
      <Flex alignItems="center" justifyContent="center" marginTop="40px">
        <TextV2 display="flex" typographyVariant="body1">
          <Box as="span" color="mono10" marginRight="4px">
            {tGenericEcard.toPerson}
          </Box>
          <Box as="span" color="mono12" marginRight="5px">
            {tGenericEcard.toPersonNames}
          </Box>
        </TextV2>
        <TooltipV2
          content={
            <Box width={'256px'} padding={3}>
              <TextV2 typographyVariant="label2">
                <b>{tEcardGuestToolTip.firstPart()}</b> <br /> {tEcardGuestToolTip.secondPart()}
              </TextV2>
            </Box>
          }
        >
          <Info size="sm" />
        </TooltipV2>
      </Flex>
    ),
    [tEcardGuestToolTip, tGenericEcard.toPerson, tGenericEcard.toPersonNames]
  );

  const title = useMemo(() => {
    if (isAdjustingPosition) {
      return adjustmentTitle;
    }

    return isGuestView ? guestViewTitle : defaultTitle;
  }, [adjustmentTitle, defaultTitle, guestViewTitle, isAdjustingPosition, isGuestView]);

  return (
    <PhotoECardWrapper $isECardEditPanelShown={isECardEditPanelShown}>
      {title}
      <PhotoECardContainer marginTop={isGuestView ? '20px' : '32px'} style={{ height: height ?? '100%' }} ref={nodeRef}>
        <PhotoECardScalableContainer className="menu" style={{ transform: `scale(${scale})` }}>
          {children}
        </PhotoECardScalableContainer>
      </PhotoECardContainer>
    </PhotoECardWrapper>
  );
};
