import React from 'react';
import { DialogV2 } from '@withjoy/joykit';
import { Box, ButtonV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    email: string;
  }> {}

export const SentResetPassword: React.FC<Props> = props => {
  const { isOpen, onClose, email } = props;
  const { t } = useTranslation('account');
  return (
    <DialogV2 id="SentResetPassword" onClose={onClose} isOpen={isOpen}>
      <DialogV2.Header>
        <TextV2 typographyVariant="hed1">{t('sentResetPassword', 'title')()}</TextV2>
      </DialogV2.Header>
      <DialogV2.Body>
        <Box display="flex" flexDirection="column" paddingY={4}>
          <TextV2 typographyVariant="body1">{t('sentResetPassword', 'sentEmail')({ email: email })}</TextV2>
          <TextV2 typographyVariant="body1">&nbsp;</TextV2>
          <TextV2 typographyVariant="body1">{t('sentResetPassword', 'ignoreClause')()}</TextV2>
        </Box>
      </DialogV2.Body>
      <DialogV2.Footer>
        <ButtonV2 intent="primary" onClick={onClose}>
          {t('sentResetPassword', 'confirmButton')()}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};
