import { ExitCardPdpModalArgs, useCardTelemetry } from '@apps/card/Card.telemetry';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

/**
 * Handler intended to be used when the user exits the modal via close button or the breadcrumbs.
 * It'll fire the necessary telemetry + close the dialog
 */
export const useExitWithoutCreatingDraftHandler = (source: ExitCardPdpModalArgs['name']) => {
  const { exitCardPdpModal } = useCardTelemetry();

  return useEventCallback(() => {
    exitCardPdpModal({ name: source });
  });
};
