import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { StyledNavRoot, StyledNavWrapper, StyledNavList, StyledNavLink, Indicator, NAV_HEIGHT } from './NavBarBottom.styles';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';
import { useSpring } from 'react-spring';

type RoutesWithLabels = {
  path: string;
  label: string;
  value: string;
}[];

interface NavBarBottomProps
  extends Readonly<{
    routes: RoutesWithLabels;
    isOpen: boolean;
  }> {}

export const NavBarBottom: React.FC<NavBarBottomProps> = React.memo(({ routes, isOpen }) => {
  const isMounted = useIsMounted();
  const tabsWrapper = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLSpanElement>(null);

  const [{ bottom }, setBottom] = useSpring(() => ({ bottom: NAV_HEIGHT }));
  const [{ left, width }, setLeft] = useSpring(() => ({ width: 0, left: 0 }));
  useLayoutEffect(() => {
    if (isMounted) {
      if (indicatorRef.current) {
        if (tabsWrapper.current) {
          const children = Array.from(tabsWrapper.current.children[0].children);
          const activeTab = (children as HTMLElement[]).find(child => child.classList.contains('active'));
          if (activeTab) {
            const { width } = activeTab.getBoundingClientRect();
            setLeft({ left: activeTab.offsetLeft, width });
          }
        }
      }
    }
  });

  useEffect(() => {
    setBottom({ bottom: isOpen ? 0 : NAV_HEIGHT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <StyledNavRoot style={{ transform: bottom.interpolate(x => `translate3d(0, ${x}px, 0)`), willChange: 'transform' }}>
      <StyledNavWrapper ref={tabsWrapper}>
        <StyledNavList>
          {routes.map(route => {
            return (
              <StyledNavLink key={route.path} to={route.path} data-testid={`nav-${route.value}`}>
                {route.label}
              </StyledNavLink>
            );
          })}
        </StyledNavList>
        <Indicator ref={indicatorRef} style={{ transform: left.interpolate(value => `translate3d(${value}px, -50%, 0px)`), willChange: 'transform', width }} />
      </StyledNavWrapper>
    </StyledNavRoot>
  );
});
