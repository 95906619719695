import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './BrushedEdge.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('brushedEdge')?.assetUrl || '';

export const BrushedEdge: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={0} left={0} width={500} height={451} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex width="100%" height="275px" padding="36px 30px" flexDirection="column" alignItems="center" justifyContent="center">
          <TextV2
            color="#333"
            textAlign="center"
            fontSize={isFullNamesFormat ? 16 : 24}
            fontFamily="Spectral"
            fontWeight={600}
            lineHeight={isFullNamesFormat ? '24px' : '37px'}
            letterSpacing="0.4em"
            textTransform="uppercase"
            marginBottom="30px"
          >
            {formattedOwnerName}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName ? ' + ' : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="center">
              {formattedDate ? (
                <TextV2 color="#333" textAlign="center" fontSize={14} fontFamily="Spectral" fontWeight={500} lineHeight="21px" letterSpacing="0.1em">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="#333" textAlign="center" fontSize={14} fontFamily="Spectral" fontWeight={500} lineHeight="21px" letterSpacing="0.1em">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2
                  color="#333"
                  textAlign="center"
                  fontSize={14}
                  fontFamily="Spectral"
                  fontWeight={500}
                  fontStyle="italic"
                  lineHeight="21px"
                  letterSpacing="0.1em"
                  marginTop="20px !important"
                >
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
