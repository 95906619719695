import { styled, Box } from '@withjoy/joykit';
import { PHOTO_ECARD_DIMENSIONS, PHOTO_ECARD_ASPECT_RATIO } from '@apps/saveTheDate/constants';
import { animationTransition } from '@shared/utils/animationTransition';

export const FrontCard = styled(Box)<{ $isAdjusting: boolean; $isPanning: boolean; $roundTopCorners: boolean }>`
  width: ${PHOTO_ECARD_DIMENSIONS.width}px;
  aspect-ratio: ${PHOTO_ECARD_ASPECT_RATIO};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    z-index: 3;
    transform: translateX(-6px) translateY(-6px);
    border: 2px solid transparent;
    border-top-left-radius: ${({ $roundTopCorners }) => ($roundTopCorners ? '260px' : '8px')};
    border-top-right-radius: ${({ $roundTopCorners }) => ($roundTopCorners ? '260px' : '8px')};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: ${animationTransition('border-color')};
    pointer-events: none;
  }

  &:hover {
    &::after {
      border-color: ${({ theme, $isAdjusting }) => ($isAdjusting ? theme.colors.purple6 : 'transparent')};
    }
  }

  ${({ $isPanning, theme }) =>
    $isPanning
      ? `&::after {
    border-color: ${theme.colors.purple8} !important;
  }`
      : ''}
`;

export const TemplateContainer = styled(Box)`
  width: ${PHOTO_ECARD_DIMENSIONS.width}px;
  height: ${PHOTO_ECARD_DIMENSIONS.height}px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: 0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07);
`;
