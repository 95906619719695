import React from 'react';
import { config } from '@static/js/env.config';
import { TextV1 } from '@withjoy/joykit';
import { UserMenu, Option } from '@shared/components/UserMenu';
import { BackButtonContainer, StyledBackChevron, StyledJoyLogoIcon } from './StyledTopBar.styles';
import { useAuth } from '@shared/components/AuthProvider';
import { accountMeRoutePaths } from '@apps/accountMe/AccountMe.route.paths';
import { useRouterHelper } from '@shared/core';
import { ReactComponent as GuestListIcon } from '@assets/icons/guestlist.svg';
import { Profile, LogOut } from '@withjoy/joykit/icons';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';

export const BackButton: React.FC<{ onClick: () => void }> = props => {
  const { children, onClick } = props;
  return (
    <BackButtonContainer onClick={onClick}>
      <StyledBackChevron />
      <TextV1 variant="hed6">{children}</TextV1>
    </BackButtonContainer>
  );
};

export const StyledJoyLogo: React.FC<{ path?: string }> = props => {
  const { path } = props;
  const homePageURL = new URL(path ? path : '/', config.clientUri).toString();
  if (path) {
    return (
      <a href={homePageURL}>
        <StyledJoyLogoIcon />
      </a>
    );
  }
  return <StyledJoyLogoIcon />;
};

export const StyledUserMenu: React.FC<{ path?: string; disableManageEventsRedirect?: boolean; disableAccountInfoRedirect?: boolean }> = props => {
  const {
    currentUser: { profile },
    provideLogout
  } = useAuth();

  const { trackMePageClick } = useAccountMeTelemetry();

  const routerHelper = useRouterHelper();
  const title = profile?.email?.charAt(0);
  const routes = [
    !props.disableManageEventsRedirect && {
      title: 'Manage Events',
      icon: <GuestListIcon />,
      onClick: () => {
        trackMePageClick('Manage', 'AvatarMenu');
        routerHelper.goToAccountMeRoute(accountMeRoutePaths.events.goToPath());
      }
    },
    !props.disableAccountInfoRedirect && {
      title: 'Account Info',
      icon: <Profile />,
      onClick: () => {
        trackMePageClick('Account', 'AvatarMenu');
        routerHelper.goToAccountMeRoute(accountMeRoutePaths.personalInfo.goToPath());
      }
    },
    // {
    //   title: 'Find Event',
    //   onClick: () => {
    //     actions.trackUserMenuItemClicked('Find Event');
    //     globalWindow.open?.(globalWindow.location?.origin + '/find');
    //   }
    // },
    {
      title: 'Sign Out',
      icon: <LogOut />,
      onClick: () => {
        trackMePageClick('SignOut', 'AvatarMenu');
        provideLogout({});
      }
    }
  ].filter(opt => !!opt) as Option[];

  return <UserMenu title={title} routes={routes} />;
};
