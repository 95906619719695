import { TextV2 } from '@withjoy/joykit';
import React from 'react';
import { PromptSuggestionsContainer } from './PromptSuggestions.styles';

type PromptSuggestionsProps = {
  prompts: Array<string>;
  onClick: (value: string) => void;
};

type PromptContainerProps = {
  prompt: string;
  onClick: (value: string) => void;
};

const PromptContainer = ({ prompt, onClick }: PromptContainerProps) => {
  return (
    <TextV2 fontSize="15px" padding="6px 8px 6px 8px" backgroundColor="white" borderRadius="4px" style={{ fontWeight: 400 }}>
      <button
        onClick={() => {
          onClick(prompt);
        }}
        type="submit"
        formAction="submit"
      >
        {prompt}
      </button>
    </TextV2>
  );
};

const PromptSuggestions = ({ prompts, onClick }: PromptSuggestionsProps) => {
  return (
    <PromptSuggestionsContainer>
      {prompts.map((prompt, index) => {
        return <PromptContainer key={index} prompt={prompt} onClick={onClick} />;
      })}
    </PromptSuggestionsContainer>
  );
};

export default PromptSuggestions;
