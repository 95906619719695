import { Box } from '@withjoy/joykit';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import React from 'react';
import { FooterLink } from './GuestFooter.styles';
import { useTranslation } from '@shared/core';

interface Props
  extends Readonly<{
    footerText?: string;
  }> {}

export const GuestFooter: React.FC<Props> = props => {
  const { footerText } = props;
  /**
   * NOTE: This component looks funky, but it is coded in a way to closely resemble the way ReactMjml's layout components operate
   * In this way it should be easier to get to visual parity between the preview and the actual email
   */

  const { t } = useTranslation('emailsAndEcards');
  const tECardFooter = t('ecardFooter');
  return (
    <Box backgroundColor="#2C2925" fontFamily={EmailFontFamilies.ibmPlexSans} borderRadius={['0px', '0px', '0px 0px 16px 16px']}>
      <Box padding="64px 56px 32px">
        <img alt="joy-logo" width="72px" src="https://withjoy.com/assets/public/marcom-prod/emails/logos/joy-logo-white.png" />
      </Box>
      <Box color="white" padding="0px 56px 40px" fontSize="32px" lineHeight="42px" letterSpacing="-1%">
        <Box maxWidth="242px">{tECardFooter.label()}</Box>
      </Box>
      {!!footerText ? (
        <Box padding="0px 56px 16px" fontSize="12px" lineHeight="20px">
          <Box color={'brandWarmGray2'}>
            {footerText}
            <FooterLink>{tECardFooter.learnMore()}</FooterLink>
          </Box>
        </Box>
      ) : null}
      <Box padding="0px 56px 64px" color={'brandWarmGray2'} fontSize="10px" lineHeight={'17px'}>
        <FooterLink style={{ marginRight: '16px' }}>{tECardFooter.privacyPolicy()}</FooterLink>
        <FooterLink>{tECardFooter.termsAndConditions()}</FooterLink>
      </Box>
    </Box>
  );
};
