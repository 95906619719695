import { ecardDraftTypeType, EcardDefaultsTranslations, EcardEditorThemeType, EcardState, EcardTypeDisplay, EcardEventDesign } from './Ecard.types';
import { ecardDefaults } from '@apps/ecard/Ecard.defaults';
import {
  ElectronicCardCardLinkOptionFragment,
  ElectronicCardFragment,
  PersonFragment,
  UserFragment,
  EcardEmailDraftFragment,
  ThemeFragment,
  EcardDesignInput,
  EventDesignFragment,
  EcardType,
  EventPageFragment
} from '@graphql/generated';
import { ecardRoutePaths } from './Ecard.route.paths';
import { useRouterHelper } from '@shared/core';
import { ECARD_DATE_FORMATS } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import { format } from 'date-fns';
import { config } from '@static/js/env.config';
import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import { parseDesignPreferences, colorPointerUtils } from '@shared/utils/websiteDesign';
import { getPeopleIds } from '@shared/components/EmailsAndEcards/Emails.utils';
import { isValidUrl } from '@shared/utils/urlHelpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEcardDraftType = (x: any): x is EcardType => ecardDraftTypeType.includes(x);

export const getEcardTypeDisplayName = (ecardDraftType: EcardType) => EcardTypeDisplay[ecardDraftType];

export const ecardStateDefault: EcardState = {
  emailInput: {
    eventId: '',
    subject: '',
    replyToEmail: '',
    imageUrl: '',
    headline: '',
    subheadline: '',
    salutation: '',
    includeGuestNames: false,
    message: '',
    testName: '',
    testEmail: '',
    includeEventDate: false,
    includeAddToCalendarLink: false,
    buttonText: ''
  },
  ecardInput: {
    id: '',
    eventId: '',
    customTheme: undefined,
    joyTheme: undefined,
    selectedThemeType: EcardEditorThemeType['JOY'],
    title: '',
    subtitle: '',
    dateFormat: ECARD_DATE_FORMATS[0],
    location: '',
    message: '',
    date: '',
    displayEventLink: false,
    additionalLink1: undefined,
    additionalLink2: undefined,
    additionalMessage: '',
    yourUrl: '',
    ecardDraftType: EcardType['savethedate'],
    useMyColor: false,
    guidId: ''
  },
  adminReferrer: ''
};

export const getInitialEcardState = ({
  eventId,
  userProfile,
  eventPhotoUrl,
  tEcardDefaults,
  ecardDraftType,
  eventHandle,
  eventDate,
  linkOptions,
  eventDesign,
  location,
  adminReferrer
}: {
  eventId: string;
  userProfile: UserFragment | null;
  eventPhotoUrl: Maybe<string>;
  tEcardDefaults: EcardDefaultsTranslations;
  ecardDraftType: EcardType;
  eventHandle: string;
  eventDate: Maybe<string>;
  linkOptions: ElectronicCardCardLinkOptionFragment[];
  eventDesign: Maybe<EcardEventDesign>;
  location: Maybe<string>;
  adminReferrer: string;
}) => {
  let shouldUseEventDesign = false;
  if (eventDesign) {
    const { alohaColorPreference } = parseDesignPreferences(eventDesign.designPreferences);
    shouldUseEventDesign = colorPointerUtils.isEventPointer(alohaColorPreference?.colorPointerPosition ?? undefined);
  }
  return {
    emailInput: {
      ...tEcardDefaults['emailContent'],
      eventId: eventId,
      replyToEmail: userProfile && userProfile.email ? userProfile.email : '',
      imageUrl: eventPhotoUrl ?? undefined,
      includeGuestNames: true,
      testName: 'First Name',
      testEmail: userProfile && userProfile.email ? userProfile.email : '',
      includeEventDate: !!eventDate,
      includeAddToCalendarLink: !isCustomECardType(ecardDraftType) && isValidDate(eventDate)
    },
    ecardInput: {
      ...tEcardDefaults['ecardContent'],
      eventId: eventId,
      selectedThemeType: EcardEditorThemeType.JOY,
      joyTheme: eventDesign?.theme as ThemeFragment,
      dateFormat: ECARD_DATE_FORMATS[0],
      location: location || '',
      date: (isCustomECardType(ecardDraftType) ? getFormattedDate(eventDate, ECARD_DATE_FORMATS[0]) : eventDate) || '',
      displayEventLink: true,
      additionalLink1: getAdditionalLink(ecardDefaults[ecardDraftType].additionalLink1, linkOptions),
      additionalLink2: getAdditionalLink(ecardDefaults[ecardDraftType].additionalLink2, linkOptions),
      yourUrl: eventHandle,
      ecardDraftType,
      useMyColor: shouldUseEventDesign
    },
    adminReferrer
  };
};

export const getEcardInputState = (
  state: EcardState['ecardInput'],
  data: ElectronicCardFragment,
  linkOptions: ElectronicCardCardLinkOptionFragment[],
  location: Maybe<string>,
  eventDate: Maybe<string>
) => {
  return {
    ...state,
    id: data.id,
    displayEventLink: !!data.displayEventLink,
    title: data.title,
    subtitle: data.subTitle || '',
    message: data.message || '',
    additionalMessage: data.additionalMessage || '',
    location: data.type && isCustomECardType(data.type as EcardType) ? data.locationString || '' : location || '',
    date: data.type && isCustomECardType(data.type as EcardType) ? data.displayDateString || '' : eventDate || '',
    joyTheme: data.design?.theme || undefined,
    useMyColor: !!data.design?.shouldUseEventDesign,
    dateFormat: data.displayDateFormat || '',
    additionalLink1: getAdditionalLink(data.linkOptionPrimary, linkOptions),
    additionalLink2: getAdditionalLink(data.linkOptionSecondary, linkOptions)
  };
};

export const getAdditionalLink = (linkOption: Maybe<string>, linkOptions: ElectronicCardCardLinkOptionFragment[]) => {
  if (!linkOption || linkOptions.length < 1) {
    return undefined;
  } else {
    const selected = linkOptions.find(option => option.name === linkOption);
    if (selected) {
      return {
        label: selected.label,
        value: selected.name
      };
    } else return undefined;
  }
};

export const getEcardPayload = (ecardInput: EcardState['ecardInput']) => {
  return {
    title: ecardInput.title,
    subTitle: ecardInput.subtitle,
    message: ecardInput.message,
    additionalMessage: ecardInput.additionalMessage,
    linkOptionPrimary: ecardInput.additionalLink1?.value,
    linkOptionSecondary: ecardInput.additionalLink2?.value,
    locationString: ecardInput.location,
    displayDateFormat: ecardInput.dateFormat,
    displayDateString: ecardInput.date,
    displayEventLink: ecardInput.displayEventLink,
    eventId: ecardInput.eventId,
    recipientIds: [],
    type: ecardInput.ecardDraftType
  };
};

export const getEcardDesignPayload = (eventDesign: EventDesignFragment, ecardId: string, ecardInput: EcardState['ecardInput']): EcardDesignInput => {
  return {
    ecardDesignId: eventDesign?.id,
    ecardId,
    themeId: ecardInput.joyTheme?.id as string,
    shouldUseEventDesign: ecardInput.useMyColor
  };
};

export const useGetEcardDraftType = (draftType: string) => {
  const routerHelper = useRouterHelper();
  if (isEcardDraftType(draftType)) {
    return EcardType[draftType];
  } else {
    // if no match for drafttype go to 'savethedate'
    routerHelper.goToAdminRoute(`ecard/${ecardRoutePaths.design.goToPath('savethedate')}`);
    return EcardType['savethedate'];
  }
};

const hasDST = (date: Date) => {
  // for an area where DST is observed, the offset the getTimezoneOffset method returns for January will be different from the one returned for July
  // the getTimezoneOffset method returns a greater value during Standard Time than during Daylight Saving Time
  // if the two values are not equal, then Daylight Saving time is observed for the passed-in date
  const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

  return Math.max(january, july) !== date.getTimezoneOffset();
};

const getCorrectTimezoneOffset = (date: Date, wrongTimezoneOffset: number) => {
  const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

  return january === wrongTimezoneOffset ? july : january;
};

export const getFormattedDate = (date: Maybe<string>, dateFormat: string) => {
  if (!!date) {
    if (isValidDate(date)) {
      // create a substring to get only the date and discard the time
      const dateOnly = new Date(date.substring(0, 10));
      const hasDSTdateOnly = hasDST(dateOnly);
      // convert the time to midnight
      const dateOnlyTimezoneOffset = dateOnly.getTimezoneOffset();
      const dateOnlyAtMidnight = new Date(dateOnly.valueOf() + dateOnlyTimezoneOffset * 60 * 1000);
      const hasDSTdateOnlyAtMidnight = hasDST(dateOnlyAtMidnight);

      if (hasDSTdateOnly === hasDSTdateOnlyAtMidnight) {
        return format(dateOnlyAtMidnight, dateFormat);
      } else {
        // if one date has DST and the other has not we need to get the correct Timezone Offset to calculate the date at midnight
        // otherwise we would be using the offset of one timezone in another timezone
        const correctTimezoneOffset = getCorrectTimezoneOffset(dateOnlyAtMidnight, dateOnlyTimezoneOffset);
        const correctDateOnlyAtMidnight = new Date(dateOnly.valueOf() + correctTimezoneOffset * 60 * 1000);
        return format(correctDateOnlyAtMidnight, dateFormat);
      }
    } else {
      return date;
    }
  }
  return '';
};

export const isValidDate = (date: Maybe<string>) => (!!date ? !isNaN(new Date(date).getDate()) : false);

export const getEmailInputState = (
  state: EcardState['emailInput'],
  data: EcardEmailDraftFragment,
  date?: string,
  pages?: ReadonlyArray<EventPageFragment>,
  eventPhotoUrl?: string
) => {
  return {
    eventId: state.eventId,
    replyToEmail: data.replyToEmail || '',
    salutation: data.salutation ?? '',
    includeGuestNames: data.includeGuestNames,
    includeEventDate: !!date ? data.includeDate : false,
    includeAddToCalendarLink: !isCustomECardType(data.ecardType) && isValidDate(date) ? data.includeAddToCalendar : false,
    message: data.message,
    subject: data.subject,
    buttonText: data.buttonText,
    headline: data.title,
    subheadline: data.subTitle,
    imageUrl: data.imageUrl ? getPhotoUrl(data.imageUrl, pages, eventPhotoUrl) : ''
  };
};

export const getEcardEmailPayload = (
  emailInput: EcardState['emailInput'],
  ecardId: string,
  ecardDraftType: EcardType,
  date?: string,
  testName?: string,
  testEmail?: string,
  selectedPeople?: ReadonlyArray<Partial<PersonFragment>>,
  footerText?: string,
  location?: string
) => {
  return {
    ecardId: ecardId as string,
    testName,
    testEmail,
    replyToEmail: emailInput.replyToEmail || '',
    salutation: emailInput.salutation || '',
    recipientIds: !!testEmail || !selectedPeople ? [] : getPeopleIds(selectedPeople),
    includeGuestNames: emailInput.includeGuestNames,
    includeDate: !!date ? !!emailInput.includeEventDate : false,
    includeAddToCalendar: isCustomECardType(ecardDraftType) ? false : isValidDate(date) ? !!emailInput.includeAddToCalendarLink : false,
    message: emailInput.message || '',
    subject: emailInput.subject,
    title: emailInput.headline || '',
    subTitle: emailInput.subheadline,
    buttonText: emailInput.buttonText,
    imageUrl: emailInput.imageUrl,
    footerText,
    date,
    location
  };
};

// check if user has custom domain set, if so return it as their event url, if not use website (url handle)
export const getEventWebsiteUrl = (website?: string, customDomain?: string): string => {
  let newCustomDomain = customDomain;
  if (newCustomDomain && !isValidUrl(newCustomDomain)) {
    newCustomDomain = `http://${customDomain}`;
    if (!isValidUrl(newCustomDomain)) {
      newCustomDomain = undefined;
    }
  }
  return newCustomDomain ?? (website && `${config.clientUri}/${website}`) ?? '';
};

export const isCustomECardType = (ecardType: EcardType) => {
  return ecardType === EcardType['custom'];
};

export const getFilteredLinkOptions = (linkOptions: ElectronicCardCardLinkOptionFragment[], ecardType: EcardType) => {
  // Removing mailingAddress option from the additional links dropdown until the address collection work is done
  // and not showing "add to calendar" option for custom eCards
  return linkOptions.filter((option: ElectronicCardCardLinkOptionFragment) => {
    if (isCustomECardType(ecardType)) {
      return option.name !== 'mailingAddress' && option.name !== 'calendar';
    } else {
      return option.name !== 'mailingAddress';
    }
  });
};

export const getPhotoUrl = (imageUrl: string, pages?: ReadonlyArray<EventPageFragment>, eventPhotoUrl?: string) => {
  const fallbackUrl: string = eventPhotoUrl || '';
  if (!pages?.length) {
    return fallbackUrl;
  }
  return (
    pages
      .map(page => page?.photo?.url)
      .filter(notNullOrUndefined)
      .find(pagePhotoUrl => pagePhotoUrl === imageUrl) || fallbackUrl
  );
};
