/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Custom Page',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M20.4559 21.2785C20.1767 21.9996 19.3658 22.3577 18.6448 22.0785L13.3646 20.0337C12.6435 19.7544 12.2854 18.9436 12.5646 18.2226L14.6094 12.9423C14.8887 12.2213 15.6995 11.8631 16.4205 12.1423L21.7008 14.1872C22.4218 14.4664 22.78 15.2772 22.5007 15.9983L20.4559 21.2785ZM1.42751 13.2744C1.23669 12.2097 2.27385 11.34 3.28897 11.7135L9.84163 14.1244C10.8457 14.4939 11.0811 15.8074 10.2679 16.5025L4.9604 21.0391C4.13818 21.7419 2.86353 21.2866 2.67271 20.2219L1.42751 13.2744ZM14.3828 3.12621C16.116 4.85941 16.116 7.66947 14.3828 9.40266C12.6496 11.1359 9.83958 11.1359 8.10638 9.40266C6.37319 7.66947 6.37319 4.85941 8.10638 3.12621C9.83958 1.39302 12.6496 1.39302 14.3828 3.12621Z"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
