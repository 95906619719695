/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Option Select Down',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M12 16L6 9.89091L6.78505 9L12 14.3455L17.215 9L18 9.89091L12 16Z" fill="currentColor" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
