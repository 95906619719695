import React, { useContext, useMemo } from 'react';
import { Box, TextV2 } from '@withjoy/joykit';
import { StyledBox } from './RecipientsBlock.styles';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { PeopleContext } from '@shared/context/people';
import { useSelectGuestsController } from '@apps/playground/components/SelectGuests/SelectGuests.controller';
import { useScrollPosition } from '@shared/utils/hooks/useScrollPosition';
import { ReactComponent as AddGuestsSvg } from '@assets/icons/add-guests.svg';

interface RecipientsBlockProps {
  activeStepIndex?: number;
}

const RecipientsBlock: React.FC<RecipientsBlockProps> = ({ activeStepIndex = 1 }) => {
  const { step, parentViewState } = useBottomSheetState();
  const { selectedPeople, setSelectedPeople } = useContext(PeopleContext);
  const scrollPosition = useScrollPosition();

  const isHighlighted = ['send-by-email', 'recipients'].includes(step);
  const relation = Math.min(1, scrollPosition / 50);
  const opacity = 1 - relation;

  const { startSelection } = useSelectGuestsController({
    eventHandle: (parentViewState?.eventHandle as string) || '',
    title: (
      <TextV2 typographyVariant="hed4" color="mono14" display="flex" alignItems="center" fontSize="24px" lineHeight="1">
        <AddGuestsSvg style={{ height: 28, width: 28, marginRight: 12 }} /> Select Guests
      </TextV2>
    ),
    onSelectedPeople: setSelectedPeople,
    initialSelectedPeople: selectedPeople
  });

  const text = useMemo(() => {
    if (!selectedPeople.length) {
      return 'No recipients selected';
    }

    if (selectedPeople.length === 1) {
      return selectedPeople[0].full_name;
    }

    return `${selectedPeople[0].full_name} and ${selectedPeople.length - 1} other guests`;
  }, [selectedPeople]);

  return (
    <StyledBox display={['flex', 'flex', 'none']} paddingX="16px" paddingY="8px" marginBottom="8px" $highlighted={isHighlighted} $shadowOpacity={opacity * 0.3}>
      <Box color={'mono10'} marginRight={[4, 4, 5]}>{`To: `}</Box>
      <Box color={isHighlighted ? 'linkText' : 'mono13'} onClick={isHighlighted ? startSelection : undefined}>
        {text}
      </Box>
    </StyledBox>
  );
};

export default RecipientsBlock;
