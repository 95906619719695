import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { CAROUSEL_BREAKPOINTS } from '@apps/registry/common/components/Catalog/Catalog.constants';

type UseCarouselDesktopProps = {
  totalNumberOfItems: number;
  itemSpacing: number;
  enablePeek: Maybe<boolean>;
  isIndividualPagination: boolean;
  columns?: [xs: number, sm: number, md: number, lg: number, xl: number];
  onArrowClick?: (direction: 'left' | 'right') => void;
};

export const useCarouselDesktop = ({ enablePeek, totalNumberOfItems, itemSpacing, isIndividualPagination, columns, onArrowClick }: UseCarouselDesktopProps) => {
  const [elementsPerPage] = useResponsive(
    {
      values: {
        [CAROUSEL_BREAKPOINTS[0]]: columns?.[0] ?? 1,
        [CAROUSEL_BREAKPOINTS[1]]: columns?.[1] ?? 2,
        [CAROUSEL_BREAKPOINTS[2]]: columns?.[2] ?? 3,
        [CAROUSEL_BREAKPOINTS[3]]: columns?.[3] ?? 4,
        [CAROUSEL_BREAKPOINTS[4]]: columns?.[4] ?? 5
      }
    },
    // we show 4 items for 13' and 15' laptops
    4
  );

  const [paddingX] = useResponsive(
    {
      values: {
        mobile: 24,
        sm2: 24,
        md3: 40,
        lg1: 64,
        xxl: 88
      }
    },
    88
  );

  // Settings
  const [carouselSettings, setCarouselSettings] = useState<{ currentPosIndex: number; activeDot: number; showLeftArrow: boolean; showRightArrow: boolean }>({
    currentPosIndex: 0,
    activeDot: 0,
    showLeftArrow: false,
    showRightArrow: false
  });

  // DOM Refs
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const lastPageOffset = useRef<number>(0);

  const totalPageCount = useMemo(() => {
    // `currentPosIndex` is zero-based
    return Math.max(Math.ceil(totalNumberOfItems / elementsPerPage) - 1, 0);
  }, [elementsPerPage, totalNumberOfItems]);

  const totalNumberDots = useMemo(() => {
    return Math.ceil(totalNumberOfItems / elementsPerPage);
  }, [elementsPerPage, totalNumberOfItems]);

  const peekSize = useMemo(() => {
    return enablePeek ? paddingX : 0;
  }, [enablePeek, paddingX]);

  /**
   * Opt to directly mutate transform property instead of passing as a prop to
   * styled-component -- this would prevent over-generation of classes
   */
  const moveContainerByPxAmount = useEventCallback((xPos: number) => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    container.style.transform = `translate3d(${xPos}px, 0, 0)`;
  });

  const getPosByPage = useCallback(
    (page: number, pageSize: number) => {
      if (page === 0) {
        // Adjust the page alignment to the first element until last page is reached
        lastPageOffset.current = 0;
        return 0;
      }

      const lastPage = Math.floor(totalNumberOfItems / elementsPerPage);
      const offsetItems = totalNumberOfItems % elementsPerPage;
      // If it's the last page calculate lastPageOffset based on items width
      if (page === lastPage && offsetItems > 0 && itemRef.current) {
        const itemWidth = itemRef.current.clientWidth;
        const offsetItemsWidth = offsetItems * itemWidth + (offsetItems - 1) * itemSpacing;
        // Adjust the page alignment to the last element until first page is reached again
        lastPageOffset.current = pageSize - offsetItemsWidth;
      }

      const fullPageOffset = page * (pageSize + itemSpacing);
      return -(fullPageOffset - lastPageOffset.current);
    },
    [elementsPerPage, totalNumberOfItems, itemSpacing, lastPageOffset]
  );

  const getPosByItem = useCallback(
    (item: number) => {
      const itemWidth = itemRef.current?.clientWidth ?? 0;
      return -(item * (itemWidth + itemSpacing));
    },
    [itemSpacing]
  );

  const repositionContainer = useCallback(
    (targetIndex?: number, isDotNav: boolean = false) => {
      if (!containerRef.current) return;

      if (!isIndividualPagination || isDotNav) {
        const fullContainerWidth = containerRef.current.clientWidth;
        // Account for container padding
        const containerContentWidth = fullContainerWidth - peekSize * 2;

        // If `targetIndex` is more than the limit, use the max page num
        const actualPage = Math.min(targetIndex ?? carouselSettings.currentPosIndex, totalPageCount);
        const nextPos = getPosByPage(actualPage, containerContentWidth);

        moveContainerByPxAmount(nextPos);
        setCarouselSettings(prev => {
          return {
            ...prev,
            activeDot: actualPage,
            currentPosIndex: actualPage,
            showLeftArrow: actualPage > 0,
            showRightArrow: actualPage < totalPageCount
          };
        });
      } else {
        const actualPage = targetIndex ? (targetIndex < totalNumberOfItems - elementsPerPage ? Math.floor(targetIndex / elementsPerPage) : totalNumberDots - 1) : 0;
        const nextPosIndex = targetIndex ?? carouselSettings.currentPosIndex;
        const nextPos = getPosByItem(nextPosIndex);

        moveContainerByPxAmount(nextPos);
        setCarouselSettings(prev => {
          return {
            ...prev,
            activeDot: actualPage,
            currentPosIndex: nextPosIndex,
            showLeftArrow: nextPosIndex > 0,
            showRightArrow: nextPosIndex < totalNumberOfItems - elementsPerPage
          };
        });
      }
    },
    [
      isIndividualPagination,
      peekSize,
      carouselSettings.currentPosIndex,
      totalPageCount,
      getPosByPage,
      moveContainerByPxAmount,
      totalNumberOfItems,
      elementsPerPage,
      totalNumberDots,
      getPosByItem
    ]
  );

  useEffect(() => {
    // Adjust page size when page is loaded for the first time
    repositionContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      repositionContainer();
    };
    // Adjust page size when window is resized
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [repositionContainer]);

  const handleArrowClick = useCallback(
    (dir: 'left' | 'right') => {
      const { currentPosIndex } = carouselSettings;
      const lastPage = (totalNumberOfItems - elementsPerPage) / elementsPerPage;
      if (
        // leading edge
        (dir === 'left' && currentPosIndex === 0) ||
        // trailing edge
        (dir === 'right' && currentPosIndex === lastPage)
      ) {
        return;
      }

      onArrowClick?.(dir);

      const pageDelta = dir === 'left' ? -1 : 1;
      const nextPage = currentPosIndex + pageDelta;

      repositionContainer(nextPage);
    },
    [carouselSettings, elementsPerPage, onArrowClick, repositionContainer, totalNumberOfItems]
  );

  const handleDotClick = useCallback(
    (index: number) => {
      repositionContainer(index, true);
    },
    [repositionContainer]
  );

  return {
    handleArrowClick,
    handleDotClick,
    totalNumberDots,
    containerRef,
    itemRef,
    elementsPerPage,
    peekSize,
    activeDot: carouselSettings.activeDot,
    showLeftArrow: carouselSettings.showLeftArrow,
    showRightArrow: carouselSettings.showRightArrow
  };
};
