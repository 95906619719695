import React, { useCallback } from 'react';
import { Box, Flex, TextV2, TooltipV2 } from '@withjoy/joykit';
import { Info } from '@withjoy/joykit/icons';
import { EcardPreviewWrapper, EcardPreviewContainer, StyledLink, styles } from './EcardWrapper.styles';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import { useTranslation } from '@shared/core';
import { useEcardScaling } from '@shared/components/EmailsAndEcards/hooks/useEcardScaling';
import { ECARD_DEFAULT_WIDTH } from '@apps/ecard/Ecard.constants';

interface Props
  extends Readonly<{
    names?: Maybe<string>;
    isEcardEditorShown: boolean;
    showRecipient?: boolean;
    isGuestView?: boolean;
    onLogoutEventHandle?: () => void;
  }> {}

export const EcardWrapper: React.FC<Props> = props => {
  const { names, isEcardEditorShown, showRecipient = true, isGuestView, onLogoutEventHandle } = props;
  const namesListStr = !!names ? names : '[First Name(s)]';
  const nameListStrSplittedByComma = namesListStr.split(',');
  const nameListStrSplittedByAmpersand = namesListStr.split(' & ');
  const isACouple = nameListStrSplittedByComma.length > 1;
  const isAFamily = nameListStrSplittedByComma.length === 1 && nameListStrSplittedByAmpersand.length > 1;
  const { t, t2 } = useTranslation('emailsAndEcards');
  const tEcardGuestToolTip = t('ecardGuest', 'toolTip');
  const tGenericEcard = t2('genericEcard');

  const { ecardScalingState, nodeRef } = useEcardScaling({
    containerBaseWidth: ECARD_DEFAULT_WIDTH + 24
  });

  const { scale, height } = ecardScalingState;

  const onLogoutUser = useCallback(() => {
    if (onLogoutEventHandle) {
      onLogoutEventHandle();
    }
  }, [onLogoutEventHandle]);

  return (
    <EcardPreviewWrapper __css={styles.getPreviewWrapperStyles(isEcardEditorShown, !showRecipient)} style={{ height: height ?? '100%' }} ref={nodeRef}>
      {showRecipient && (
        <Flex flexDirection="column" alignItems="center" marginTop={isGuestView ? [6, 9] : ['100px', '100px', '70px']}>
          <Flex alignItems="center">
            {(names || !isGuestView) && (
              <TextV2 display="flex" typographyVariant="body1" fontFamily={EmailFontFamilies.ibmPlexSans}>
                <Box as="span" color={'mono10'} marginRight={2}>
                  {tGenericEcard.toPerson}
                </Box>
                <Box as="span" color={'mono13'} marginRight={1}>
                  {namesListStr}
                </Box>
              </TextV2>
            )}
            {!isGuestView && (
              <TooltipV2
                content={
                  <Box width={'256px'} padding={3}>
                    <TextV2 typographyVariant="label2">
                      <b>{tEcardGuestToolTip.firstPart()}</b> <br /> {tEcardGuestToolTip.secondPart()}
                    </TextV2>
                  </Box>
                }
              >
                <Info size="sm" />
              </TooltipV2>
            )}
          </Flex>
          {isGuestView && names && (
            <StyledLink typographyVariant="body1" fontFamily={EmailFontFamilies.ibmPlexSans} __css={styles.styledLink} color={'mono10'} onClick={onLogoutUser}>
              {`${tGenericEcard.notPerson} ${isACouple ? nameListStrSplittedByComma[0] : isAFamily ? nameListStrSplittedByAmpersand[0] : namesListStr}?`}
            </StyledLink>
          )}
        </Flex>
      )}
      <EcardPreviewContainer __css={styles.previewContainer} style={{ transform: `scale(${scale})` }}>
        {props.children}
      </EcardPreviewContainer>
    </EcardPreviewWrapper>
  );
};
