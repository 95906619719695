import { useBottomSheetState } from '@shared/components/BottomSheet';

const useShareWithLinkController = () => {
  const { parentViewState } = useBottomSheetState();

  const handleCopyShareableLink = async () => {
    if (parentViewState && parentViewState.handleCopyShareableLink) {
      const copyLink = parentViewState.handleCopyShareableLink as () => Promise<void>;
      await copyLink();
    }
  };

  return {
    handleCopyShareableLink,
    isCreatingShareableLink: !!parentViewState?.isCreatingShareableLink
  };
};

export default useShareWithLinkController;
