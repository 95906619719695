import { styled, ButtonV2, css } from '@withjoy/joykit';
import { NavLink } from '@shared/core';

export const NAV_HEIGHT = 96;

export const StyledNavWrapper = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${NAV_HEIGHT}px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 16px 32px rgb(0 0 0 / 24%);
`;

export const StyledNavList = styled.ul`
  display: flex;
  & > li:not(:first-child) {
    margin-left: 12px;
    @media screen and (min-width: 769px) {
      margin-left: 8px;
    }
  }
`;

export const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  position: relative;
  font-weight: 600;

  &.active {
    color: ${props => `${props.theme.colors.linkText}`};
    &::after {
      display: block;
      content: '';
      border-bottom: ${props => `4px solid ${props.theme.colors.linkText}`};
      width: calc(100% - 32px);
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 75%;
    `}
`;

export const StyledNextButton = styled(ButtonV2)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
`;
