import { StyledItemWrapper, StyledListHeader } from '../SelectList/SelectList.styles';
import { LinkV1, Box, styled } from '@withjoy/joykit';
import { DialogV1 } from '@withjoy/joykit';
import { css } from '@withjoy/joykit';
import { mediaScreenMobile, mediaScreenNotMobile } from '../../utils/media/mediaQueriesUtils';

// these pixel values just manually calculated, to set sticky position top values
const DIALOG_HEADER_HEIGHT_PX = 64;
const SEARCH_BAR_HEIGHT_PX = 88;
const TABS_BAR_HEIGHT_PX = 37;
const SPACER_HEIGHT_PX = 24;
const SEARCH_BAR_TOP_PX = DIALOG_HEADER_HEIGHT_PX;
const TABS_BAR_TOP_PX = SEARCH_BAR_TOP_PX + SEARCH_BAR_HEIGHT_PX;
const SPACER_TOP_PX = TABS_BAR_TOP_PX + TABS_BAR_HEIGHT_PX;
const LIST_HEADER_TOP_PX = SPACER_TOP_PX + SPACER_HEIGHT_PX;

export const StyledLink = styled(LinkV1)`
  color: ${props => props.theme.colors.linkText};
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyListContentWrapper = styled.div``;

export const AnchorLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: ${TABS_BAR_TOP_PX}px;
  padding: 0 2rem 1rem 1.5rem;
  border-bottom: 1px solid #ebebeb;
  background: white;
  z-index: 2;
  a {
    font-size: 13px;
    line-height: 20px;
    margin-right: 2rem;
    text-decoration: none;

    span {
      display: none;
    }
  }
`;

export const ListsTopSpacerMobile = styled.div`
  height: ${SPACER_HEIGHT_PX}px;
  position: sticky;
  top: ${SPACER_TOP_PX}px;
  z-index: 2;
  background: white;
`;

export const StyledSearchWrapper = styled.div`
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  position: sticky;
  top: ${SEARCH_BAR_TOP_PX}px;
  background: white;
  z-index: 2;
  input {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledDialogBody = styled(Box)`
  min-height: calc(100% - 64px - 96px);

  ${mediaScreenMobile`${css`
    flex: 1;
  `}`}
`;

export const StyledDialogFooter = styled.div`
  position: sticky;
  bottom: 0;
  height: 96px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  z-index: 2;
  border-top: 1px solid #ebebeb;
  .cancel-button {
    margin-right: 1rem;
    display: none;
  }
  .save-button {
    flex: 1;
  }
`;

export const StyledDialog = styled(DialogV1)`
  .joykit-dialog-header {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    z-index: 2000;
    background-color: white;
    border-bottom: 1px solid #ebebeb;
  }
  ${mediaScreenMobile`${css`
    border-radius: 12px 12px 0 0;

    [role="dialog"] {
      display: flex;
      flex-direction: column;
    }

    .joykit-dialog-header {
      height: 79px;
      border: none;
      background-color: white !important;
      display: flex;
      align-items: center;
    }

    ${StyledSearchWrapper} {
      padding-top: 0;
      top: 79px;
      input { min-height: 40px; border-color: #D6D6D6; border-radius: 4px; }
      div > span { min-height: 43px; }
      padding-bottom: 24px;
    }
    ${AnchorLinksWrapper} {
      top: 143px;
      padding-bottom: 16px;
      border-bottom: 1px solid #EBEBEB;

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;

        span {
          display: inline;
        }
      }
    }
    ${ListsTopSpacerMobile} {
      top: 181px;
    }
    ${StyledListHeader} {
      top: 205px !important;
    }
    ${StyledDialogFooter} {
      display: flex;
      flex-direction: column-reverse;
      padding: 24px 32px;
      height: 160px;
      box-shadow: 0px 4px 12px -2px rgba(44, 41, 37, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3);

      .cancel-button {
        display: block !important;
        margin: 16px 0 0;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.mono14};

        &:hover {
          color: ${({ theme }) => theme.colors.mono12};
        }

        &:active {
          color: ${({ theme }) => theme.colors.mono13};
        }
      }

      .save-button, .cancel-button {
        width: 100%;
      }

      .save-button {
        color: white;
        background-color: ${({ theme }) => theme.colors.mono14};
        font-size: 16px;
        font-weight: 600;

        &:hover {
          background-color: ${({ theme }) => theme.colors.mono12};
        }

        &:active {
          background-color: ${({ theme }) => theme.colors.mono13};
        }
      }
  `}`}
  }
  ${mediaScreenNotMobile`${css`
    ${StyledListHeader} {
      padding-right: 1rem;
    }
    ${StyledDialogFooter} {
      position: static;
      padding: 0 2rem;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .save-button {
        flex: none;
      }
      .cancel-button {
        display: block;
      }
    }
    ${StyledDialogBody} {
      min-height: auto;
    }
  `}`}
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  .select-labels-list {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    // quickfix for click not working on part of labels list in mobile mode due to margin-top/padding-top trick
    z-index: 1;
    position: relative;
  }
  .select-people-list {
    width: 100%;
    height: auto;
  }
  .select-people-list,
  .select-labels-list {
    // trick to make possible to scroll to anchor element with sticky header on top on mobile
    margin-top: -${LIST_HEADER_TOP_PX}px;
    padding-top: ${LIST_HEADER_TOP_PX}px;
    ${StyledItemWrapper} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    ${StyledListHeader} {
      padding-left: 0.5rem;
      padding-right: 1.5rem;
    }
  }
  .selected-people-list {
    display: none;
    width: 200px;
    height: 439px;
    overflow: scroll;
    background: #fbfbfb;
    border-left: 1px solid #f2f2f2;
    flex-shrink: 0;
    ${StyledListHeader} {
      padding-top: 1rem;
      background: #fbfbfb;
    }
  }
  ${EmptyListContentWrapper} {
    &,
    & * {
      font-size: 13px;
      line-height: 20px;
      text-decoration: none;
    }
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 0.875rem;
  }
  ${mediaScreenNotMobile`${css`
    ${LeftBox} {
      width: auto;
      min-width: 0;
      padding-left: 0.5rem;
    }
    .select-labels-list {
      width: 300px;
      height: 351px;
      overflow: scroll;
      margin-bottom: 0;
      padding-top: 0;
    }
    .select-people-list {
      width: 300px;
      height: 351px;
      overflow: scroll;
      border-left: 1px solid #ebebeb;
    }
    .selected-people-list {
      display: block;
    }
    .select-people-list,
    .select-labels-list {
      margin-top: 0;
      padding-top: 0;
    }
    ${StyledSearchWrapper} {
      border-bottom: none;
      padding-right: 2rem;
    }
    ${ListsTopSpacerMobile} {
      display: none;
      border-bottom: none;
    }
    ${AnchorLinksWrapper} {
      display: none;
      border-bottom: none;
    }
    ${SelectListsWrapper} {
      flex-direction: row;
    }
  `}`}
  ${mediaScreenMobile`${css`
    ${StyledListHeader} {
      top: ${LIST_HEADER_TOP_PX}px;
    }
  `}`}
`;
