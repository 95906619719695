/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Photo',
  definitions: {
    '24': {
      viewBox: '0 0 24 18',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.70156 0.0627441C1.09405 0.0627441 0.601562 0.555232 0.601562 1.16274V16.8373C0.601562 17.4448 1.09405 17.9373 1.70156 17.9373H21.2995C21.907 17.9373 22.3995 17.4448 22.3995 16.8373V1.16274C22.3995 0.555231 21.907 0.0627441 21.2995 0.0627441H1.70156ZM1.80156 16.7373V9.18553L5.52706 5.46003L16.8043 16.7373H1.80156ZM4.74925 4.54079L1.80156 7.48848V1.26274H21.1995V11.0981L19.056 8.95461C18.6264 8.52504 17.9299 8.52504 17.5003 8.95461L14.1095 12.3454L6.30488 4.54079C5.8753 4.11122 5.17882 4.11122 4.74925 4.54079ZM14.958 13.194L18.4771 16.713L18.4528 16.7373H21.1995V12.7952L18.2782 9.87385L14.958 13.194ZM14.0622 4.87658C14.0622 4.39538 14.4523 4.0053 14.9335 4.0053C15.4147 4.0053 15.8048 4.39538 15.8048 4.87658C15.8048 5.35777 15.4147 5.74786 14.9335 5.74786C14.4523 5.74786 14.0622 5.35777 14.0622 4.87658ZM14.9335 2.8053C13.7896 2.8053 12.8622 3.73264 12.8622 4.87658C12.8622 6.02051 13.7896 6.94786 14.9335 6.94786C16.0775 6.94786 17.0048 6.02051 17.0048 4.87658C17.0048 3.73264 16.0775 2.8053 14.9335 2.8053Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
