import React from 'react';
import { useAIRouterContext } from '@apps/imagineYourCard/router';
import { Login } from './Login';
import { CreateEventStateProvider } from '@shared/createEvent/CreateEventState';
import { DialogV2 } from '@withjoy/joykit';

export const FullScreenLogin = () => {
  const { isLoginModalOpen, onCloseLoginModal } = useAIRouterContext();
  return (
    <DialogV2
      overrides={{
        Body: {
          props: {
            paddingX: { _: 0, xs: 0 },
            paddingY: { _: 0, xs: 0 }
          }
        }
      }}
      isOpen={isLoginModalOpen}
      onClose={onCloseLoginModal}
      size="full"
    >
      <CreateEventStateProvider>
        <DialogV2.CloseButton zIndex="modal" />
        <DialogV2.Body>
          <Login />
        </DialogV2.Body>
      </CreateEventStateProvider>
    </DialogV2>
  );
};
