import React from 'react';
import { Flex, Box } from '@withjoy/joykit';
import { useStepsController } from './Steps.controller';
import { ReactComponent as LeftArrowSvg } from '@assets/icons/icon-left-arrow.svg';
import { ReactComponent as RightArrowSvg } from '@assets/icons/icon-right-arrow.svg';
import { StyledButton, Dot, DotsContainer, NAV_HEIGHT } from './Steps.styles';

export const Steps: React.FC<{ disableNavigation?: boolean }> = ({ disableNavigation = false }) => {
  const {
    currentIndex,
    views,
    maxHeight,
    showNavComponent,
    viewToShow,
    showLeftArrow,
    showRightArrow,
    handleLeftArrow,
    handleRightArrow,
    disableNextButton
  } = useStepsController();

  return (
    <>
      <Box height={maxHeight - NAV_HEIGHT}>
        <>{viewToShow?.children}</>
      </Box>
      {showNavComponent && (
        <Flex alignItems="center" justifyContent="center" margin={6} height="48px" position="relative">
          {showLeftArrow && (
            <StyledButton $side={'left'} onClick={handleLeftArrow} disabled={disableNavigation}>
              <LeftArrowSvg />
            </StyledButton>
          )}
          <DotsContainer alignItems="center" justifyContent="center">
            {views.map((_, index) => (
              <Dot key={index} $isActive={index === currentIndex} />
            ))}
          </DotsContainer>
          {showRightArrow && (
            <StyledButton $side={'right'} onClick={handleRightArrow} disabled={disableNavigation || disableNextButton}>
              <RightArrowSvg />
            </StyledButton>
          )}
        </Flex>
      )}
    </>
  );
};
