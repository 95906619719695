import { Box } from '../Box';
import { styled, ThemeStyledProps, css } from '@shared/joykit/packages/core/common/styledComponents';
import { InputLabelProps } from '.';
import { animationTransition, generateComponentDisplayName } from '../../utils';
import { getStyledLayoutExternalSpacing } from '../../common/styledSystem/styledFunctions';

const colorStyle = (props: ThemeStyledProps<InputLabelProps>) => {
  let color;
  if (props.disabled) {
    color = props.theme.colors.typeDisabledDark;
  } else if (props.error) {
    color = props.theme.colors.negative6;
  } else {
    color = props.theme.colors.typePrimaryDark;
  }
  return css`
    color: ${color};
  `;
};

export const StyledLabel = styled(Box).attrs(() => ({
  typeVariant: 'label3',
  as: 'label'
}))`
  transition: ${animationTransition('color')};
  ${colorStyle};
  ${getStyledLayoutExternalSpacing};
  display: ${props => (props.hidden ? 'none' : undefined)};
`;

StyledLabel.displayName = generateComponentDisplayName('InputLabel__Root');
