import React, { Children, PropsWithChildren, ReactElement, useRef, useState } from 'react';
import { ButtonWrapperStyle } from './PageWrapper.styles';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';

const REQUIRED_SPACE = '32px';
const GAP = 10;
const HEIGHT = 48;

const MOBILE_OFFSET = '12px';

export const ButtonWrapper = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [hover, setHover] = useState(false);
  const mobileOrTablet = useIsMobileOrTablet();
  const wrapper = useRef<HTMLDivElement | null>(null);

  const baseProps = {
    className: hover ? 'hovered' : '',
    shape: 'rounded',
    intent: 'neutral',
    variant: 'solid'
  };

  let height = 0;

  return (
    <ButtonWrapperStyle
      ref={wrapper}
      onMouseEnter={() => {
        setHover(true);
      }}
      onTouchStart={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {Children.map(children, (c, index) => {
        const child = c as ReactElement;
        const wrapperWidth = wrapper.current?.clientWidth;
        const bottom = `calc(${REQUIRED_SPACE} + ${wrapperWidth ? (wrapperWidth - 32) * 0.08 : 0}px + ${height}px${mobileOrTablet ? ' - ' + MOBILE_OFFSET : ''})`;

        if (child.type != 'div') {
          height += HEIGHT + GAP;
        }

        return <child.type bottom={bottom} {...baseProps} {...child.props} />;
      })}
    </ButtonWrapperStyle>
  );
};
