import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { PeopleContext } from '@shared/context/people';
import { ButtonV2, DrawerV2, TextV2 } from '@withjoy/joykit';
import { ReactComponent as EmailSentSvg } from '@assets/icons/sent-plane.svg';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { PersonFragment } from '@graphql/generated';

interface ConfirmSheetProps {}

const ConfirmSheetThankYou: React.FC<ConfirmSheetProps> = () => {
  const isMobile = useIsMobileScreen();
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tConfirm = tBottomSheet.confirm;
  const { parentViewState, setParentViewState } = useBottomSheetState();
  const { selectedPeople: selectedPeopleContext } = useContext(PeopleContext);
  const [selectedPeople, setSelectedPeople] = useState<
    Partial<
      PersonFragment & {
        full_name: string;
      }
    >[]
  >([]);

  useEffect(() => {
    if (selectedPeopleContext.length) {
      setSelectedPeople([...selectedPeopleContext]);
    }
  }, [selectedPeopleContext]);

  const handleBack = () => {
    withWindow(() => {
      const defaultUrl = `${window.location.origin}/${parentViewState?.eventHandle}/edit/email/create?draftType=thankyou`;
      window.location.href = (parentViewState?.redirect as string) || defaultUrl;
    });
  };

  const dekText = useMemo(() => {
    if (parentViewState?.recipientName) {
      return tConfirm.descriptionThankYouName({ name: parentViewState.recipientName });
    }

    if (selectedPeople.length === 1) {
      return tConfirm.descriptionThankYouName({ name: selectedPeople[0].full_name });
    }

    return tConfirm.descriptionThankYouCount({ count: selectedPeople.length });
  }, [parentViewState?.recipientName, selectedPeople, tConfirm]);

  if (!isMobile) return null;

  return (
    <DrawerV2
      overrides={{ Content: { props: { borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: 'var(--full-screen-height)' } } }}
      anchor="bottom"
      isOpen={!!parentViewState?.isConfirmSheetOpen}
      onClose={() => setParentViewState(prev => ({ ...prev, isConfirmSheetOpen: false }))}
      useBackdrop={false}
    >
      <DrawerV2.CloseButton top="24px" right="24px" padding={0} color="mono14" />
      <DrawerV2.Body display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingX={6}>
        <EmailSentSvg />
        <TextV2 marginBottom="16px" textAlign="center" color="mono14" typographyVariant="hed6">
          {tConfirm.titleThankYou()}
        </TextV2>
        <TextV2 marginBottom="40px" fontWeight={500} textAlign="center" fontSize="15px" lineHeight="22px" color="mono12">
          {dekText}
        </TextV2>
        <ButtonV2 width="100%" onClick={handleBack}>
          {tConfirm.buttonThankYou()}
        </ButtonV2>
      </DrawerV2.Body>
    </DrawerV2>
  );
};

export default ConfirmSheetThankYou;
