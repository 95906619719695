import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './GoldWreath.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('goldWreath')?.assetUrl || '';

export const GoldWreath: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={48} left={65} height={370} width={370} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex height="196px" width="100%" padding="30px" flexDirection="column" alignItems="center" justifyContent="center">
          <TextV2 color="#785F51" textAlign="center" fontSize={16} fontFamily="Quicksand" fontWeight={600} lineHeight="27px" letterSpacing="0.1em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName ? ' & ' : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="left">
              {formattedDate ? (
                <TextV2 color="#785F51" textAlign="center" fontSize={13} fontFamily="Quicksand" lineHeight="16px" letterSpacing="0.1em" textTransform="uppercase">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="#785F51" textAlign="center" fontSize={13} fontFamily="Quicksand" lineHeight="16px" letterSpacing="0.1em" textTransform="uppercase">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="#785F51" textAlign="center" fontSize={13} fontFamily="Quicksand" lineHeight="16px" letterSpacing="0.1em" textTransform="uppercase">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
