import { useMemo, useRef, useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { animationTransition } from '@shared/utils/animationTransition';
import { mergeRefs } from '@shared/utils/hooks/setRef';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useDefaultMediaMatchContext } from '@shared/utils/media/DefaultMediaMatchProvider';
import { CAROUSEL_BREAKPOINTS } from '@apps/registry/common/components/Catalog/Catalog.constants';

export const SWIPE_TRANSITION_DURATION: number = 500;
export const SWIPE_RELEASE_DISTANCE = 100;
export const DEFAULT_PEEK_SIZE = 24;

export type UseCarouselInlineProps = {
  totalNumberOfItems: number;
  itemSpacing: number;
  enablePeek: Maybe<boolean>;
  columns?: [xs: number, sm: number, md: number, lg: number, xl: number];
  peekExtraSize: number;
};

export const useCarouselInline = ({ enablePeek, totalNumberOfItems, itemSpacing, columns, peekExtraSize }: UseCarouselInlineProps) => {
  const context = useDefaultMediaMatchContext();
  const defaultScreen = context?.defaultScreen;

  const [elementsPerPage] = useResponsive(
    {
      values: {
        [CAROUSEL_BREAKPOINTS[0]]: columns?.[0] ?? 1,
        [CAROUSEL_BREAKPOINTS[1]]: columns?.[1] ?? 2
      }
    },
    defaultScreen === 'mobile' ? 1 : 2
  );

  const [isResetting, setIsResetting] = useState(false);
  const [stopScroll, setStopScroll] = useState(false);
  const [stopSwipe, setStopSwipe] = useState(false);
  const dragContainerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const trackerRefs = useRef<{ resetTimeoutId: number | undefined; isResetting: boolean }>({ resetTimeoutId: undefined, isResetting: false });
  const isSwiped = useRef(false);
  const shouldSwipe = totalNumberOfItems > 1;
  const lastLeftPosition = useRef(0);

  const peekSize = useMemo(() => {
    return enablePeek ? DEFAULT_PEEK_SIZE : 0;
  }, [enablePeek]);

  const peekSizeRight = useMemo(() => {
    return enablePeek ? DEFAULT_PEEK_SIZE + peekExtraSize : 0;
  }, [enablePeek, peekExtraSize]);

  const handleSwipe = useEventCallback((swipeData: SwipeEventData, carouselWidth: number) => {
    const transform = swipeData.deltaX + SWIPE_RELEASE_DISTANCE * swipeData.velocity * (swipeData.dir === 'Left' ? -1 : 1);
    const left = lastLeftPosition.current + transform;
    const carouselWidthNeg = carouselWidth * -1;

    if (left > 0) return [transform - left, 0];
    if (left < carouselWidthNeg) return [transform - (left - carouselWidthNeg), carouselWidthNeg];
    return [transform, left];
  });

  const handlers = useSwipeable({
    onSwipeStart: e => {
      e.event.stopPropagation();

      if (e.dir === 'Left' || e.dir === 'Right') {
        setStopScroll(true);
      } else {
        setStopSwipe(true);
      }
    },
    onSwiping: data => {
      if (stopSwipe) return;
      isSwiped.current = true;
      const dragContainer = dragContainerRef.current;
      if (dragContainer && !isResetting) {
        dragContainer.style.willChange = 'transform';
        dragContainer.style.transform = `translateX(${data.deltaX}px)`;
        dragContainer.style.transition = `none`;
      }
    },
    onSwiped: data => {
      if (stopSwipe) {
        setStopSwipe(false);
        return;
      }

      setStopScroll(false);
      const dragContainer = dragContainerRef.current;
      if (dragContainer && !isResetting) {
        setIsResetting(true);

        const elementWidth = itemRef.current?.clientWidth ? itemRef.current?.clientWidth + itemSpacing : 0;
        const carouselWidth = elementWidth * (totalNumberOfItems - elementsPerPage);
        const carouselWidthPlusPeek = peekSize !== 0 ? carouselWidth + peekSize - peekExtraSize - itemSpacing : carouselWidth;
        const [transform, left] = handleSwipe(data, carouselWidthPlusPeek);

        // Snap to the nearest carousel product
        dragContainer.style.transition = animationTransition('transform');
        dragContainer.style.willChange = 'transform';
        dragContainer.style.transform = `translateX(${transform}px)`;

        // Delay resetting transform + left properties until after the current transform animation
        trackerRefs.current.resetTimeoutId = window.setTimeout(() => {
          dragContainer.style.transition = 'none';
          dragContainer.style.willChange = 'auto';
          dragContainer.style.transform = `translateX(0px)`;
          dragContainer.style.left = `${left}px`;
          setIsResetting(false);
          lastLeftPosition.current = left;
          // Setting timeout to same duration as the transition
        }, SWIPE_TRANSITION_DURATION);
      }
    },
    trackMouse: shouldSwipe,
    trackTouch: shouldSwipe
  });

  return {
    wrapperRef: mergeRefs(handlers.ref, dragContainerRef),
    elementsPerPage,
    itemRef,
    handlers,
    stopScroll,
    peekSize,
    peekSizeRight
  } as const;
};
