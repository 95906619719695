import { useState, useRef, useCallback, useEffect } from 'react';
import { EcardEditorThemeType, EcardEditorType } from '@apps/ecard/Ecard.types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ThemeFragment, CharmThemeFragment, EcardType } from '@graphql/generated';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useUpdateEcardDesignFromQueryParam } from './hooks/useUpdateEcardDesignFromQueryParam';

export interface EcardDesignInputFields
  extends Readonly<{
    id?: string;
    eventId: string;
    customTheme?: ThemeFields;
    joyTheme?: CharmThemeFragment;
    selectedThemeType: EcardEditorThemeType;
    title: string;
    subtitle: string;
    dateFormat: string;
    location: string;
    message: string;
    date: string;
    displayEventLink: boolean;
    additionalLink1?: SelectOptionFields;
    additionalLink2?: SelectOptionFields;
    additionalMessage: string;
    yourUrl: string;
    ecardDraftType: EcardType;
    useMyColor: boolean;
    guidId?: string;
  }> {}

export interface SelectOptionFields
  extends Readonly<{
    label: string;
    value: string;
  }> {}

export interface ThemeFields
  extends Readonly<{
    imageUrl: string;
    title: string;
  }> {}

interface UseDesignControllerArgs
  extends Readonly<{
    ecardInput: EcardDesignInputFields;
    updateEcardInput?: (ecardInput: EcardDesignInputFields) => void;
    editCardDesignTrack?: (ecardType: EcardType) => void;
    editCardContentTrack?: (ecardType: EcardType) => void;
  }> {}

export const useDesignController = (args: UseDesignControllerArgs) => {
  const { ecardInput, updateEcardInput, editCardDesignTrack, editCardContentTrack } = args;
  const [isEditShown, setIsEditShown] = useState<boolean>(false);
  const [selectedEditorType, setSelectedEditorType] = useState<EcardEditorType>(EcardEditorType.DESIGN);
  const [isUnsavedChnangesDialogOpen, setIsUnsavedChnangesDialogOpen] = useState<boolean>(false);
  const telemetry = useEcardsTelemetry();
  const { setParentViewState } = useBottomSheetState();

  useEffect(() => {
    if (isEditShown) {
      if (selectedEditorType === EcardEditorType.DESIGN) {
        editCardDesignTrack?.(ecardInput.ecardDraftType);
      }
      if (selectedEditorType === EcardEditorType.CONTENT) {
        editCardContentTrack?.(ecardInput.ecardDraftType);
      }
    }
  }, [isEditShown, selectedEditorType, editCardDesignTrack, editCardContentTrack, ecardInput.ecardDraftType]);

  const handleToggleECardEditPanel = () => {
    setIsEditShown(!isEditShown);
  };

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, openECardEditor: handleToggleECardEditPanel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ecardFormik = useFormik<EcardDesignInputFields>({
    initialValues: ecardInput,
    validationSchema: Yup.object<EcardDesignInputFields>({
      id: Yup.string(),
      eventId: Yup.string(),
      selectedThemeType: Yup.mixed().oneOf(Object.values(EcardEditorThemeType)),
      customTheme: Yup.object<ThemeFields>(),
      joyTheme: Yup.object<ThemeFragment>(),
      title: Yup.string(),
      subtitle: Yup.string(),
      message: Yup.string(),
      date: Yup.string(),
      dateFormat: Yup.string(),
      location: Yup.string(),
      displayEventLink: Yup.boolean(),
      additionalLink1: Yup.object<SelectOptionFields>(),
      additionalLink2: Yup.object<SelectOptionFields>(),
      additionalMessage: Yup.string(),
      yourUrl: Yup.string(),
      ecardDraftType: Yup.mixed<EcardType>(),
      useMyColor: Yup.boolean(),
      guidId: Yup.string()
    }),
    enableReinitialize: true,
    onSubmit: values => {
      if (!!updateEcardInput) {
        updateEcardInput(values);
      }
    }
  });

  useUpdateEcardDesignFromQueryParam({
    ecardFormik
  });

  const handleToggleEdit = (type?: EcardEditorType) => {
    if (type && !isEditShown) {
      setSelectedEditorType(type);
      telemetry.launchEcardEditor(ecardFormik.values.ecardDraftType as EcardType, type);
    }
    setIsEditShown(!isEditShown);
  };

  const onCancel = () => {
    ecardFormik.resetForm();
    setIsEditShown(false);
    telemetry.editECardCancelClick(ecardFormik.values.ecardDraftType as EcardType, selectedEditorType);
  };

  const handleCancel = () => {
    if (ecardFormik.dirty) {
      setIsUnsavedChnangesDialogOpen(true);
    } else {
      onCancel();
    }
  };

  const handleUnsavedChnangesDialogClose = () => {
    setIsUnsavedChnangesDialogOpen(false);
  };

  const handleUnsavedChnangesDialogConfirm = () => {
    handleUnsavedChnangesDialogClose();
    onCancel();
  };

  const handleSetSelectedEditorType = (type: EcardEditorType) => {
    setSelectedEditorType(type);
    handleScrollToFront();
    telemetry.eCardEditorTabClick(ecardFormik.values.ecardDraftType as EcardType, type);
  };

  const previewFrontRef = useRef<HTMLDivElement>(null);
  const previewBackRef = useRef<HTMLDivElement>(null);

  const handleScrollToFront = useCallback(() => {
    if (previewFrontRef && previewFrontRef.current) {
      previewFrontRef.current.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [previewFrontRef]);

  const handleScrollToBack = useCallback(() => {
    if (previewBackRef && previewBackRef.current) {
      previewBackRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [previewBackRef]);

  return {
    ecardFormik,
    handleToggleEdit,
    handleCancel,
    isEditShown,
    selectedEditorType,
    handleSetSelectedEditorType,
    isUnsavedChnangesDialogOpen,
    handleUnsavedChnangesDialogClose,
    handleUnsavedChnangesDialogConfirm,
    previewFrontRef,
    previewBackRef,
    handleScrollToFront,
    handleScrollToBack
  };
};
