import { styled } from '@withjoy/joykit';
import { Colors } from '@withjoy/joykit/theme';
import { random } from 'lodash-es';

const SPACING = 2560;
const TIME = ['5s', '10s', '20s', '30s'];

const particles = (max: number, color: string) => {
  const base = `0px 0px ${color}`;
  const particlesValues = new Array(max).fill(null).map(() => `${base}, ${random(0, SPACING)}px ${random(0, SPACING)}px ${color}`);

  return `box-shadow: ${particlesValues.join()}`;
};

export const Container = styled.div<{ color: string | keyof Colors }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 305px;

  .ml-animation-wrapper {
    position: absolute;
    height: 305px;
    width: 100%;
    overflow: hidden;
  }

  .ml-particle,
  .ml-particle:after {
    background: transparent;
  }

  .ml-particle:after {
    position: absolute;
    content: '';
    top: ${SPACING}px;
  }

  .ml-particle-1 {
    animation: ml-animParticle ${TIME[0]} linear infinite;
    ${({ color, theme }) => particles(600, theme.colors[color as keyof Colors] ?? color)};
    height: 1px;
    width: 1px;
    border-radius: 0.5px;
  }

  .ml-particle-1:after {
    @include particles(600);
    height: 1px;
    width: 1px;
    border-radius: 0.5px;
  }

  .ml-particle-2 {
    animation: ml-animParticle ${TIME[1]} linear infinite;
    ${({ color, theme }) => particles(200, theme.colors[color as keyof Colors] ?? color)};
    height: 2px;
    width: 2px;
    border-radius: 1px;
  }

  .ml-particle-2:after {
    ${({ color, theme }) => particles(200, theme.colors[color as keyof Colors] ?? color)};
    height: 2px;
    width: 2px;
    border-radius: 1px;
  }

  .ml-particle-3 {
    animation: ml-animParticle ${TIME[2]} linear infinite;
    ${({ color, theme }) => particles(100, theme.colors[color as keyof Colors] ?? color)};
    height: 3px;
    width: 3px;
    border-radius: 1.5px;
  }

  .ml-particle-3:after {
    ${({ color, theme }) => particles(100, theme.colors[color as keyof Colors] ?? color)};
    height: 3px;
    width: 3px;
    border-radius: 1.5px;
  }

  .ml-particle-4 {
    animation: ml-animParticle ${TIME[3]} linear infinite;
    ${({ color, theme }) => particles(400, theme.colors[color as keyof Colors] ?? color)};
    height: 1px;
    width: 1px;
    border-radius: 0.5px;
  }

  .ml-particle-4:after {
    ${({ color, theme }) => particles(400, theme.colors[color as keyof Colors] ?? color)};
    height: 1px;
    width: 1px;
    border-radius: 0.5px;
  }

  @keyframes ml-animParticle {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-${SPACING}px);
    }
  }

  @keyframes ml-fadeBar {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }

  .ml-spinner {
    position: absolute;
    width: 54px;
    height: 54px;
    display: inline-block;

    div {
      width: 6%;
      height: 16%;
      background: ${({ color, theme }) => theme.colors[color as keyof Colors] ?? color};
      position: absolute;
      left: 49%;
      top: 43%;
      opacity: 0;
      -webkit-border-radius: 50px;
      -webkit-animation: ml-fadeBar 1s linear infinite;
    }

    .ml-bar1 {
      -webkit-transform: rotate(0deg) translate(0, -130%);
      -webkit-animation-delay: 0s;
    }

    .ml-bar2 {
      -webkit-transform: rotate(30deg) translate(0, -130%);
      -webkit-animation-delay: -0.9167s;
    }

    .ml-bar3 {
      -webkit-transform: rotate(60deg) translate(0, -130%);
      -webkit-animation-delay: -0.833s;
    }
    .ml-bar4 {
      -webkit-transform: rotate(90deg) translate(0, -130%);
      -webkit-animation-delay: -0.7497s;
    }
    .ml-bar5 {
      -webkit-transform: rotate(120deg) translate(0, -130%);
      -webkit-animation-delay: -0.667s;
    }
    .ml-bar6 {
      -webkit-transform: rotate(150deg) translate(0, -130%);
      -webkit-animation-delay: -0.5837s;
    }
    .ml-bar7 {
      -webkit-transform: rotate(180deg) translate(0, -130%);
      -webkit-animation-delay: -0.5s;
    }
    .ml-bar8 {
      -webkit-transform: rotate(210deg) translate(0, -130%);
      -webkit-animation-delay: -0.4167s;
    }
    .ml-bar9 {
      -webkit-transform: rotate(240deg) translate(0, -130%);
      -webkit-animation-delay: -0.333s;
    }
    .ml-bar10 {
      -webkit-transform: rotate(270deg) translate(0, -130%);
      -webkit-animation-delay: -0.2497s;
    }
    .ml-bar11 {
      -webkit-transform: rotate(300deg) translate(0, -130%);
      -webkit-animation-delay: -0.167s;
    }
    .ml-bar12 {
      -webkit-transform: rotate(330deg) translate(0, -130%);
      -webkit-animation-delay: -0.0833s;
    }
  }
`;

export const ParticlesContainer = styled.div`
  height: 305px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    z-index: 3;
    pointer-events: none;
  }
`;
