/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Log Out',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M21.4247 12.4227C21.659 12.1884 21.659 11.8085 21.4247 11.5742L18.4247 8.5742C18.1903 8.33988 17.8104 8.33988 17.5761 8.5742C17.3418 8.80851 17.3418 9.18841 17.5761 9.42273L19.5519 11.3985L9.0004 11.3985C8.66903 11.3985 8.4004 11.6671 8.4004 11.9985C8.4004 12.3298 8.66903 12.5985 9.0004 12.5985L19.5519 12.5985L17.5761 14.5742C17.3418 14.8085 17.3418 15.1884 17.5761 15.4227C17.8104 15.657 18.1903 15.657 18.4247 15.4227L21.4247 12.4227Z"
            fill="currentColor"
          />{' '}
          <path
            d="M12.0004 5.09844C13.3259 5.09844 14.4004 6.17295 14.4004 7.49844V8.24844C14.4004 8.57981 14.669 8.84844 15.0004 8.84844C15.3318 8.84844 15.6004 8.57981 15.6004 8.24844V7.49844C15.6004 5.51021 13.9886 3.89844 12.0004 3.89844L6.00039 3.89844C4.01217 3.89844 2.40039 5.51021 2.40039 7.49844L2.40039 16.4984C2.40039 18.4867 4.01216 20.0984 6.00039 20.0984L12.0004 20.0984C13.9886 20.0984 15.6004 18.4867 15.6004 16.4984V15.7484C15.6004 15.4171 15.3318 15.1484 15.0004 15.1484C14.669 15.1484 14.4004 15.4171 14.4004 15.7484L14.4004 16.4984C14.4004 17.8239 13.3259 18.8984 12.0004 18.8984L6.00039 18.8984C4.67491 18.8984 3.60039 17.8239 3.60039 16.4984L3.60039 7.49844C3.60039 6.17295 4.67491 5.09844 6.00039 5.09844L12.0004 5.09844Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
