/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Video',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5 6.2h14a.8.8 0 0 1 .8.8v10a.8.8 0 0 1-.8.8H5a.8.8 0 0 1-.8-.8V7a.8.8 0 0 1 .8-.8zM3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7zm11.347 5L9.6 14.374V9.626L14.347 12zm-4.273-3.478a1.16 1.16 0 0 0-1.126.051c-.34.21-.55.583-.55.984v4.886a1.16 1.16 0 0 0 .549.984c.34.21.767.23 1.126.05l4.886-2.443a1.16 1.16 0 0 0 .001-2.07l-4.886-2.443z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M10 11.25h28A2.75 2.75 0 0 1 40.75 14v20A2.75 2.75 0 0 1 38 36.75H10A2.75 2.75 0 0 1 7.25 34V14A2.75 2.75 0 0 1 10 11.25zM6 14a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v20a4 4 0 0 1-4 4H10a4 4 0 0 1-4-4V14zm13.834 4.951a.44.44 0 0 1 .429-.019l9.343 4.672a.44.44 0 0 1 0 .789l-9.343 4.672a.44.44 0 0 1-.638-.394v-9.343a.44.44 0 0 1 .209-.375zm.988-1.137a1.69 1.69 0 0 0-2.447 1.512v9.344a1.69 1.69 0 0 0 2.447 1.512l9.344-4.672a1.69 1.69 0 0 0 .001-3.024l-9.344-4.672z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
