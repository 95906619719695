import * as React from 'react';
import { StyledItemInfo, AvatarWrapper, AvatarBorder, ItemText, ItemSubtext, StylingProps, TextContainer, AvatarName } from '../../components/PeopleListItem/PeopleListItem.styles';
// import { ReactComponent as DefaultAvatar } from '@assets/icons/default-avatar.svg';

interface Props
  extends Readonly<{
    avatarUrl?: string;
    labelText: string;
    sublabelText?: string;
    disabled?: boolean;
    inGroupPosition?: InGroupPosition;
    isSmall?: boolean;
    avatarName?: string;
  }> {}

export type InGroupPosition = 'first' | 'middle' | 'last' | '';

export const PeopleListItem: React.FC<Props> = React.memo(({ avatarUrl, labelText, sublabelText, disabled, isSmall, inGroupPosition, avatarName }) => {
  const isNoNamePlusOne = inGroupPosition !== 'first' && !avatarName;
  const initials = avatarName || (inGroupPosition !== 'first' ? '+1' : '?');
  const subProps: StylingProps = { isSmall, inGroupPosition, disabled, isNoNamePlusOne };
  return (
    <StyledItemInfo {...subProps}>
      <AvatarWrapper>
        {avatarUrl ? <img src={avatarUrl} alt={labelText + ' photo'} /> : <AvatarName>{initials}</AvatarName>}
        {inGroupPosition && <AvatarBorder />}
      </AvatarWrapper>
      <TextContainer>
        <ItemText>{labelText}</ItemText>
        {!isSmall && sublabelText && <ItemSubtext>{sublabelText}</ItemSubtext>}
      </TextContainer>
    </StyledItemInfo>
  );
});
