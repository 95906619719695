import React from 'react';
import { isValid, parse } from 'date-fns';
import { LocationInput } from '@shared/components/LocationInput';
import { ButtonV2, CheckboxV2, DatePickerInput, FormControl, InputV2, LinkV2, SpacingStack, TextV2, Flex, useDisclosure } from '@withjoy/joykit';
import WeddingHeroText from '@apps/createWedding/routes/CreateWeddingForm/components/WeddingHeroText';
import { FormWrapperStack, styles } from '@apps/createWedding/routes/CreateWeddingForm/CreateWeddingForm.styles';
import { useTranslation } from '@shared/core';
import { useCreateWeddingFormController, CreateWeddingFormProps } from '@apps/createWedding/routes/CreateWeddingForm/CreateWeddingForm.controller';
import { wrapWithCreateWeddingFormHealthProvider } from './CreateWeddingForm.Health';
import { inputV1ToV2Styles } from '@apps/createWedding/routes/CreateWeddingForm/CreateWeddingForm.styles';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

const InputLabel: React.FC = ({ children }) => <TextV2 typographyVariant="label3">{children}</TextV2>;

const dateFormat = 'MM/dd/yyyy';

export const CreateWeddingForm = wrapWithCreateWeddingFormHealthProvider<CreateWeddingFormProps>(props => {
  const { t } = useTranslation('createWedding');
  const { formik, user, getFieldErrorProps, signOut, blockWeddingDate, setBlockWeddingDate, telemetry } = useCreateWeddingFormController(props);

  const weddingFormTranslations = t('weddingForm');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const datapickerAutocloseEnabled = useFeatureValue('datepickerAutocloseExperiment').value === 'treatment';

  const changeAccount = weddingFormTranslations.changeAccount({
    email: user.email,
    SignOut: () => (
      <LinkV2 onClick={signOut} {...styles.signOutLinkStyles} typographyVariant="button1" textDecoration="none">
        {weddingFormTranslations.signOut()}
      </LinkV2>
    )
  });

  const handleDateInputChange = useEventCallback((dateString: string) => {
    const isSameLength = dateString.replace(/(\s)*/g, '').length === dateFormat.replace(/(\s)*/g, '').length;
    const inputDate = parse(dateString, dateFormat, new Date());
    if (!isSameLength || !isValid(inputDate)) {
      formik.setFieldValue('isDateValid', false);
    }
  });

  return (
    <FormWrapperStack spacing={8}>
      <WeddingHeroText ownerFirstName={formik.values.ownerFirstName} partnerFirstName={formik.values.partnerFirstName || ''} />
      <form onSubmit={formik.handleSubmit}>
        <SpacingStack spacing={5}>
          <FormControl
            onFocus={() => telemetry.inputHandler('ownerFirstName', 'onFocus')}
            {...getFieldErrorProps('ownerFirstName')}
            label={<InputLabel>{weddingFormTranslations.ownerFirstName()}</InputLabel>}
          >
            <InputV2 placeholder={weddingFormTranslations.ownerFirstNamePlaceholder()} {...formik.getFieldProps('ownerFirstName')} />
          </FormControl>
          <FormControl
            onFocus={() => telemetry.inputHandler('partnerFirstName', 'onFocus')}
            {...getFieldErrorProps('partnerFirstName')}
            label={<InputLabel>{weddingFormTranslations.partnerFirstName()}</InputLabel>}
          >
            <InputV2 placeholder={weddingFormTranslations.partnerFirstNamePlaceholder()} {...formik.getFieldProps('partnerFirstName')} />
          </FormControl>
          <SpacingStack spacing={5}>
            <FormControl
              onFocus={() => telemetry.inputHandler('eventDate', 'onFocus')}
              {...getFieldErrorProps('weddingDate')}
              label={<InputLabel>{weddingFormTranslations.weddingDate()}</InputLabel>}
            >
              <DatePickerInput
                isDisabled={blockWeddingDate}
                endElement={null}
                mask={null}
                minDate={new Date()}
                dateFormat="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                {...formik.getFieldProps('weddingDate')}
                onFocus={e => e.stopPropagation()}
                onOpen={onOpen}
                onClose={onClose}
                isOpen={isOpen}
                onChange={date => {
                  formik.setFieldValue('isDateValid', true);
                  formik.setFieldValue('weddingDate', date);
                  telemetry.inputHandler('eventDate', 'onChange');
                  if (date && datapickerAutocloseEnabled) {
                    setTimeout(() => onClose(), 50);
                  }
                }}
                onInputChange={handleDateInputChange}
              />
            </FormControl>
            <CheckboxV2
              checked={blockWeddingDate}
              onChange={e => {
                setBlockWeddingDate(e.target.checked);
                telemetry.inputHandler('eventDate', 'onChange');
              }}
              fontWeight={500}
            >
              {weddingFormTranslations.skipDateDefinition()}
            </CheckboxV2>
          </SpacingStack>
          <FormControl
            onFocus={() => telemetry.inputHandler('eventLocation', 'onFocus')}
            {...getFieldErrorProps('location')}
            label={<InputLabel>{weddingFormTranslations.location()}</InputLabel>}
          >
            <Flex __css={inputV1ToV2Styles({ ...getFieldErrorProps('location') })}>
              <LocationInput
                {...formik.getFieldProps('location')}
                noOptionsMessage={t('weddingForm', 'noOptions')()}
                onLocationChange={location => {
                  formik.setFieldValue('location', location);
                  telemetry.inputHandler('eventLocation', 'onChange');
                }}
              />
            </Flex>
          </FormControl>
        </SpacingStack>
      </form>
      <SpacingStack spacing={6}>
        <ButtonV2 loading={props.isCreateCtaLoading || formik.isSubmitting} type="submit" onClick={formik.submitForm} intent="primary">
          {weddingFormTranslations.createWeddingCta()}
        </ButtonV2>
        <TextV2 textAlign="center" typographyVariant="body1" color="mono12">
          {changeAccount}
        </TextV2>
      </SpacingStack>
    </FormWrapperStack>
  );
});
