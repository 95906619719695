import { useCallback, useState } from 'react';

/**
 * Hook to manage boolean (on - off) states.
 */
export const useBoolean = (initialValue: boolean | (() => boolean) = false) => {
  const [state, setState] = useState<boolean>(initialValue);

  const on = useCallback(() => {
    setState(true);
  }, []);
  const off = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState(prev => !prev);
  }, []);

  return [state, { on, off, toggle }] as const;
};
