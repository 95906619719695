import React, { Dispatch, SetStateAction } from 'react';
import { Redirect, Route, Switch } from '@react-router';
import { RoutePath, RoutesPaths, RoutesProvider, useRouterHelper } from '@shared/core';
import { MustBeSignedIn } from '@shared/components/MustBeSignedIn/MustBeSignedIn';
import { PostOffice } from './PostOffice';
import { usePostOfficeController } from './PostOffice.controller';
import { usePostOfficeRouterController } from './PostOfficeRouter.controller';
import { Confirm, EmailEditor } from '@shared/components/EmailsAndEcards';
import { PostOfficeMessageType } from '@graphql/generated';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import { JoyKitV2AdminTheme } from '@shared/utils/style/joykitV2AdminTheme';
import { TelemetryProvider } from './PostOffice.telemetry';
import { PeopleProvider } from '@shared/context/people';
import { BottomSheet, BottomSheetProvider } from '@shared/components/BottomSheet';
import ConfirmSheet from './components/ConfirmSheet';
import MobileSendTestEmailDialog from './components/MobileSendTestEmailDialog';
import ConfirmSheetThankYou from './components/ConfirmSheetThankYou';

/**
 * Example Route name types. Defines the routes that are available
 */
export type PostOfficeRouteNames = 'create' | 'confirm';

export type PostOfficeRoutePaths = RoutesPaths<PostOfficeRouteNames>;
/**
 * Example route paths. Has a path for the <Router /> and a function for history to push to.
 */
export const postOfficeRoutePaths: PostOfficeRoutePaths = {
  create: {
    path: `create`,
    goToPath: () => `create`
  },
  confirm: {
    path: `confirm`,
    goToPath: () => `confirm`
  }
};

const RoutesPostOffice: React.FC<{
  draftType: PostOfficeMessageType;
  eventDisplayName: string;
  eventHandle: string;
  eventId: string;
  setIsThankYouMessageLoading: Dispatch<SetStateAction<boolean>>;
}> = ({ draftType, eventDisplayName, eventHandle, eventId, setIsThankYouMessageLoading }) => {
  const routeHelpers = useRouterHelper();

  const {
    // postOfficeFormik: formik,
    handleSendEmail,
    testEmail,
    emailIsVerified,
    isPasswordToggleShown,
    footerText,
    eventPassword,
    loadingDraftData,
    emailInputState,
    updateEmailInput,
    pages,
    isMobile,
    hideGuestSelector,
    isMessageLoading,
    redirect,
    recipientName
  } = usePostOfficeController({ eventId, draftType, eventDisplayName, eventHandle, setIsThankYouMessageLoading });
  const editor = (
    <EmailEditor
      eventPassword={eventPassword}
      loadingDraftData={loadingDraftData}
      isPasswordToggleShown={isPasswordToggleShown}
      emailType={draftType === 'thankyou' ? 'thankyou' : 'generic'}
      emailInput={emailInputState}
      updateEmailInput={updateEmailInput}
      footerText={footerText}
      eventHandle={eventHandle}
      eventDisplayName={eventDisplayName}
      pages={pages}
      isMessageLoading={isMessageLoading}
      recipientName={recipientName}
    />
  );

  if (isMobile) {
    return editor;
  }

  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(postOfficeRoutePaths.create.path),
      component: () => editor
    },
    {
      path: routeHelpers.buildPath(postOfficeRoutePaths.confirm.path),
      component: () => (
        <Confirm
          hideGuestSelector={hideGuestSelector}
          eventHandle={eventHandle}
          handleSendEmail={handleSendEmail}
          testEmail={testEmail}
          draftType={draftType}
          emailIsVerified={emailIsVerified}
          redirect={redirect}
          successScreen={draftType === 'thankyou' ? 'thankyou' : 'generic'}
          recipientName={recipientName}
        />
      )
    }
  ];
  return (
    <Switch>
      {routes.map(route => (
        <Route key={route.path} path={route.path} render={route.component} />
      ))}
      <Redirect to={routes[0].path} />
    </Switch>
  );
};

export const RouterPostOffice: React.FC<{ eventId: string; eventHandle: string; eventDisplayName: string }> = ({ eventId, eventHandle, eventDisplayName }) => {
  const { draftType, fromDashboard, MIN_HEIGHT, MAX_HEIGHT, views, isThankYouMessageLoading, setIsThankYouMessageLoading } = usePostOfficeRouterController();

  return (
    <JoyKitThemeProvider theme={JoyKitV2AdminTheme}>
      <RoutesProvider appUrl={'email'} handle={eventHandle}>
        <MustBeSignedIn>
          <PeopleProvider>
            <TelemetryProvider context={{ eventId }}>
              <PostOffice eventHandle={eventHandle} draftType={draftType} fromDashboard={fromDashboard} isThankYouMessageLoading={isThankYouMessageLoading}>
                <BottomSheetProvider views={views} minHeight={MIN_HEIGHT} maxHeight={MAX_HEIGHT}>
                  <RoutesPostOffice
                    draftType={draftType}
                    eventDisplayName={eventDisplayName}
                    eventHandle={eventHandle}
                    eventId={eventId}
                    setIsThankYouMessageLoading={setIsThankYouMessageLoading}
                  />
                  <BottomSheet disableNavigation={draftType === 'thankyou' && isThankYouMessageLoading} />
                  <MobileSendTestEmailDialog />
                  {draftType === 'thankyou' ? <ConfirmSheetThankYou /> : <ConfirmSheet />}
                </BottomSheetProvider>
              </PostOffice>
            </TelemetryProvider>
          </PeopleProvider>
        </MustBeSignedIn>
      </RoutesProvider>
    </JoyKitThemeProvider>
  );
};
