import { animationTransition } from '@shared/utils/animationTransition';
import { InterFonts } from '@shared/utils/fonts/interFonts';
import { styled, TextV2 } from '@withjoy/joykit';

export const Container = styled.div`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    border: none;
    outline: none;
    cursor: initial;
  }

  .transition {
    transition: ${animationTransition('transform')};
  }

  .right {
    transform: translateX(376px);

    @media (max-width: 767px) {
      transform: translateX(100%);
    }
  }

  .left {
    transform: translateX(0);
  }
`;

export const StyledDialog = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  border-left: 1px solid #ebebeb;
  width: 528px;
  background-color: white;
  right: 0;
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Scrollable = styled.div`
  overflow-y: scroll;
  padding: 0 32px 168px;
  max-height: 100vh;
`;

export const StyledHeader = styled(TextV2)`
  ${InterFonts.hed6};
  padding: 40px 0 32px;
`;
