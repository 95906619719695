import React from 'react';
import { Box, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import CreateEventButton from '../CreateEventButton';

interface ZeroEventsProps {
  hideImage?: boolean;
}

export const ZeroEvents: React.FC<ZeroEventsProps> = ({ hideImage }) => {
  const { t } = useTranslation('account');

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems="center" marginTop={'80px'}>
      {!hideImage && (
        <Box marginBottom={6} maxWidth={350}>
          <img src="https://withjoy.com/assets/public/account-events-zero-state.png" alt="" width="100%" />
        </Box>
      )}
      <TextV2 as="h1" typographyVariant={'display1'} marginBottom={3}>
        {t('noEventsSection', 'heading')()}
      </TextV2>
      <TextV2 as="p" typographyVariant={'body2'} marginBottom={9} textAlign={'center'} maxWidth={'23.75rem'}>
        {t('noEventsSection', 'description')()}
      </TextV2>
      <CreateEventButton />
    </Box>
  );
};
