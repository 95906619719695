import { addAction } from '@shared/utils/logger';

export interface LogData {
  message: string;
  [key: string]: unknown;
}

export function logAcquisitionContextTrackingEvent(type: 'error' | 'success', data: LogData): void {
  addAction('AcquisitionContextTracking', { ...data, type });
}
