import { Flex, styled, LinkV2, ButtonV2, TextV2 } from '@withjoy/joykit';
import { buttonStates, ellipsis, linkStates, outlineButtonStates } from '@apps/admin/routes/Dashboard/common/commonDasboard.styles';

export const Row = styled(Flex)`
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Footer = styled(Flex)`
  justify-content: flex-start;
`;

export const ShareOption = styled.div`
  margin-bottom: 2rem;

  &:first-child {
    margin-bottom: 1.5rem;
  }
`;

export const OpenButton = styled(ButtonV2)`
  ${outlineButtonStates};
`;

export const ValueText = styled(TextV2)<{ lines: number }>`
  ${({ theme }) => theme.typography.variants.body1};
  ${props => ellipsis(props.lines)}
  word-break: break-all;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.body2};
  }
`;

export const EditLink = styled(LinkV2)`
  ${props => props.theme.typography.variants.body1};
  ${linkStates};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.label2};
  }
`;

export const Title = styled(TextV2)`
  ${props => props.theme.typography.variants.hed1};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.label3};
  }
`;

export const CopyButton = styled.button`
  ${buttonStates};
  border: 0.0625rem solid ${props => props.theme.colors.mono5};
  border-radius: 0.25rem;
  color: ${props => props.theme.colors.primary7};
  flex: 0 0 auto;
  height: 3rem;
  margin-left: 0.5rem;
  width: 3rem;
`;
