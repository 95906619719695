import * as React from 'react';
import { PersonViewItem } from '../../PeoplePicker';
import { SelectList } from '../../../SelectList/SelectList';
import { PeopleListItem } from '../../../PeopleListItem/PeopleListItem';
import { DragIconWrapper, DrawerHeader, HeaderTitle, SelectListWrapper, StyledDrawer } from './SelectedPeopleDrawer.styles';
import { ReactComponent as DragIcon } from '@assets/icons/drag.svg';

interface Props {
  selectedPeople: PersonViewItem[];
  isOpen: boolean;
  onClose: () => void;
}

export const SelectedPeopleDrawer: React.FC<Props> = ({ selectedPeople, isOpen, onClose }) => {
  selectedPeople = selectedPeople || [];
  return (
    <StyledDrawer isOpen={isOpen} position="bottom" onClose={onClose}>
      <DrawerHeader onClick={onClose}>
        <DragIconWrapper>
          <DragIcon />
        </DragIconWrapper>
        <HeaderTitle>{`${selectedPeople.length} Guests Selected`} </HeaderTitle>
      </DrawerHeader>
      <SelectListWrapper>
        <SelectList
          canSelect={false}
          headerLabel={''}
          items={selectedPeople}
          itemRenderer={item => <PeopleListItem isSmall avatarUrl={item.avatarUrl} labelText={item.labelText} inGroupPosition={item.inGroupPosition} />}
        />
      </SelectListWrapper>
    </StyledDrawer>
  );
};
