import { Box, ButtonV2, styled, StyleSystemProps } from '@withjoy/joykit';
import { ReactComponent as JoyLogo } from '@assets/joy-logo.svg';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';

export const BackCardContainer = styled(Box)``;
export const BackCardButton = styled(ButtonV2)``;
export const BackCardOverlay = styled(Box)``;
export const BackCardLink = styled(Box)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

const containerStyles: StyleSystemProps = {
  boxShadow: '0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04)',
  filter: 'drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.03))',
  position: 'relative',
  marginTop: [4, 4, 6],
  marginBottom: 24,
  paddingX: '48px',
  paddingY: '72px',
  backgroundColor: 'white',
  color: 'black',
  width: ['100%', '500px'],
  borderRadius: '4px'
};

export const BackCardMessageContainer = styled(Box)`
  a {
    color: inherit;
    &:hover {
      color: inherit;
      opacity: 0.8;
    }
    &:active {
      color: inherit;
      opacity: 1;
    }
  }
`;

const buttonStyles: ButtonOverrides = {
  Root: {
    props: {
      borderColor: 'black',
      color: 'black',
      flex: ['unset', 1],
      height: '48px',
      width: ['240px', 'auto'],
      _even: {
        marginLeft: [0, '8px'],
        marginTop: ['16px', 0]
      },
      _odd: {
        marginLeft: [0, '8px'],
        marginTop: ['16px', 0]
      },
      _first: {
        marginTop: 0,
        marginLeft: 0
      }
    }
  }
};

export const BackCardLogo = styled(JoyLogo)`
  height: 24px;
  width: 38px;
  margin-left: 8px;
`;

const footerWebsiteUrl: StyleSystemProps = {
  color: 'inherit',
  textDecoration: 'none',
  fontSize: '16px',
  lineHeight: '19px',
  fontFamily: 'Gotham SSm',
  fontWeight: 500,
  _hover: {
    opacity: 0.8,
    _active: {
      opacity: 1
    }
  },
  _active: {
    opacity: 1
  },
  _visited: {
    color: 'inherit'
  }
};

const messageContainer: StyleSystemProps = {
  textAlign: 'center'
};

export const GraphicAccentImg = styled.img<{ pixelDensity: number }>`
  transform: scale(${props => 1 / Math.max(props.pixelDensity, 1)});
`;

const linkStyles: StyleSystemProps = {
  color: 'mono8',
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  _hover: {
    color: 'black'
  }
};

export const styles = {
  footerWebsiteUrl,
  messageContainer,
  container: containerStyles,
  button: buttonStyles,
  link: linkStyles
};
