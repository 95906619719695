import React from 'react';
import { EmailInputFields, EmailEditorTranslations, EmailEditPanelOption } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormikProps } from 'formik';
import { Box, Flex, FormControl, InputV2, TextV2, CheckboxV2, Toggle } from '@withjoy/joykit';
import {
  StyledOptionsWrapper,
  StyledOptionContainer,
  StyledBodyTitle,
  StyledBody,
  StyledLabel,
  StyledTextArea,
  AddPhotosLink
} from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/EmailEditPanel.styles';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import { EventPageFragment } from '@graphql/generated';
import PhotoSelector from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector';
import { filterTidbitsPage } from '../PhotoSelector/PhotoSelector.controller';

export interface EcardEmailFormProps
  extends Readonly<{
    formik: FormikProps<EmailInputFields>;
    tEmailEditor: EmailEditorTranslations;
    ecardEmailOptions: Array<EmailEditPanelOption>;
    handleIncludeGuestNamesToggle: () => void;
    onGoToPhotosClick: () => void;
    handlePhotoSelect: (photoUrl: string) => void;
    pages?: ReadonlyArray<EventPageFragment>;
  }> {}

export const EcardEmailForm: React.FC<EcardEmailFormProps> = ({
  formik,
  tEmailEditor,
  ecardEmailOptions,
  handleIncludeGuestNamesToggle,
  onGoToPhotosClick,
  handlePhotoSelect,
  pages
}) => {
  return (
    <>
      <FormControl label={<StyledLabel>{tEmailEditor.emailSubjectLabel}</StyledLabel>} error={formik.errors.subject} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('subject')} placeholder={tEmailEditor.emailSubjectPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('subject').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.replyToLabel}</StyledLabel>} error={formik.errors.replyToEmail} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('replyToEmail')} placeholder={tEmailEditor.emailSubjectPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('replyToEmail').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.photoLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <PhotoSelector pages={filterTidbitsPage(pages)} handlePhotoSelect={({ previewUrl }) => handlePhotoSelect(previewUrl)} photo={{ url: formik.values.imageUrl || '' }} />
          <Box marginTop={3}>
            <AddPhotosLink onClick={onGoToPhotosClick}>{tEmailEditor.addPhotos}</AddPhotosLink>
          </Box>
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('imageUrl').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.headlineLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('headline')} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('headline').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.subheadlineLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('subheadline')} placeholder={tEmailEditor.subheadlinePlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('subheadline').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.salutationLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <Flex alignItems="center">
            <Box width="46%" marginRight={3}>
              <InputV2 {...formik.getFieldProps('salutation')} placeholder={tEmailEditor.salutationPlaceholder} />
            </Box>
            <CheckboxV2 id="includeGuestNames" isChecked={!!formik.getFieldMeta('includeGuestNames').value} onChange={handleIncludeGuestNamesToggle}>
              {tEmailEditor.includeGuestNamesLabel}
            </CheckboxV2>
          </Flex>
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('salutation').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.messageLabel}</StyledLabel>} error={formik.errors.message} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <StyledTextArea {...formik.getFieldProps('message')} placeholder={tEmailEditor.messagePlaceholder} resize={false} minRows={8} maxRows={8} tabIndex={0} minimal={true} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('message').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.buttonTextLabel}</StyledLabel>} error={formik.errors.buttonText} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('buttonText')} placeholder={tEmailEditor.buttonTextPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('buttonText').error}
          </TextV2>
        </>
      </FormControl>
      <StyledOptionsWrapper paddingX={4} paddingY={0}>
        {ecardEmailOptions.map(
          option =>
            !option.isHidden && (
              <StyledOptionContainer key={option.formikName} alignItems="center" justifyContent="space-between" paddingY={5}>
                <Flex flexDirection="column" alignItems="flex-start">
                  <StyledBodyTitle>{option.hed}</StyledBodyTitle>
                  <StyledBody>{option.dek}</StyledBody>
                </Flex>
                <Toggle hideLabel={true} label={option.name} checked={option.isOn} name={option.formikName} onChange={option.onCange} disabled={!!option.isDisabled} />
              </StyledOptionContainer>
            )
        )}
      </StyledOptionsWrapper>
    </>
  );
};
