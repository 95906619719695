import { ImageLayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';
import { StationeryTemplateWithThemeJson } from '@apps/card/routes/Dashboard/components/DesignsGallery/useDesignTemplates';
import { usePdpData } from '@apps/card/routes/Dashboard/components/ProductDetailsPage/usePdpData';
import { AIBackgroundPromptPayload, StationeryTemplateCategoryEnum } from '@graphql/generated';
import React, { useRef } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { desiredPadding, desktopCols, mobileCols } from '../ImagineYourCardRoot/cardConstants';
import { ImageHistoryElement, ImageHistoryVariation } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { absolutePhotoUrl } from '@apps/card/routes/CardCustomizer/components/ImageLayer';

type AICardDummyProps = {
  template: StationeryTemplateWithThemeJson;
  url: string;
  prompt: AIBackgroundPromptPayload;
  index: number;
  resourceID?: string;
};

type AICardPreviewContainerProps = {
  themeID: string;
  category: StationeryTemplateCategoryEnum;
  url: string;
  prompt: AIBackgroundPromptPayload;
  index: number;
  resourceID?: string;
};

const AICardDummyContainer = ({ themeID, category, url, prompt, index, resourceID }: AICardPreviewContainerProps) => {
  const { loading, template } = usePdpData(themeID, category);

  const mobile = useIsMobileOrTablet();
  const columns = mobile ? mobileCols : desktopCols;
  const estimatedHeight = (document.body.clientWidth / columns + desiredPadding * 2) * (7 / 5);

  if (!url.includes('http')) {
    return <div style={{ height: `${estimatedHeight}px` }}>&nbsp;</div>;
  }

  if (!loading && template) {
    return (
      <div>
        <AICardDummy resourceID={resourceID} index={index} template={template} url={url} prompt={prompt} />
      </div>
    );
  } else if (loading) {
    return <div></div>;
  } else {
    return (
      <div>
        <p>Invalid template provided.</p>
      </div>
    );
  }
};

const AICardDummy = ({ template, prompt, url, index, resourceID }: AICardDummyProps) => {
  const page = template.themeJSON.card.front;
  const resource = useRef(resourceID);

  if (!template.themeJSON.imageHistory) {
    template.themeJSON.imageHistory = new Array<ImageHistoryElement>();
  }

  const initialImage = (page.layers[0] as ImageLayerData).imageData.src;
  template.themeJSON.imageHistory.push({
    type: 'upload',
    url: absolutePhotoUrl(initialImage)
  });
  (page.layers[0] as ImageLayerData).imageData.src = url;
  template.themeJSON.imageHistory.push({
    type: 'ai_prompt',
    url,
    promptPayload: prompt
  } as ImageHistoryVariation);

  return <PageWrapper index={index} resourceID={resource} promptPayload={prompt} template={template} page={page} />;
};

export default AICardDummyContainer;
