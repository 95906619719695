import React from 'react';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { CarouselSkeleton } from '../../Carousel';
import { CarouselProvider } from '../../Carousel.controller';
import { CarouselProps } from '../../Carousel.types';
import { useCarouselInline } from './CarouselItemsInline.controller';
import { ItemsContainer, styles } from './CarouselItemsInline.styles';

const CarouselItemsInline = ({
  children,
  totalNumberOfItems,
  itemSpacing = 24,
  enablePeek,
  showSkeleton,
  placeholder,
  columns = [1, 2, 3, 4, 5],
  peekMobileExtraSize = 0
}: CarouselProps) => {
  const { wrapperRef, elementsPerPage, handlers, stopScroll, itemRef, peekSize, peekSizeRight } = useCarouselInline({
    enablePeek,
    totalNumberOfItems,
    itemSpacing,
    columns,
    peekExtraSize: peekMobileExtraSize
  });

  return (
    <CarouselProvider value={{ itemRef, elementsPerPage, itemSpacing, enablePeekSpacing: enablePeek ?? false, showSkeleton }}>
      <SkeletonGroup
        isReady={!showSkeleton}
        placeholder={
          <ItemsContainer __css={styles.itemsListInline(peekSize, peekSizeRight)} position={'relative'}>
            <CarouselSkeleton placeholder={placeholder} />
          </ItemsContainer>
        }
      >
        <ItemsContainer
          __css={styles.itemsListInline(peekSize, peekSizeRight)}
          {...handlers}
          position={'relative'}
          style={{
            touchAction: stopScroll ? 'none' : 'pan-y'
          }}
          role="group"
          ref={wrapperRef}
        >
          {children}
        </ItemsContainer>
      </SkeletonGroup>
    </CarouselProvider>
  );
};

export { CarouselItemsInline };
