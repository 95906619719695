import React from 'react';
import { BaseComponentProps } from '../../common/props';
import { LinkV1 } from '../LinkV1';
import { ButtonVariant } from '../../common/AbstractButton';
import { TextV1 } from '@withjoy/joykit';

export interface FormHelpTextProps extends BaseComponentProps {
  disabled?: boolean;
  message: React.ReactNode;
  component?: 'button' | 'a';
  variant?: ButtonVariant;
  onClick?: React.MouseEventHandler;
  href?: string;
}

export const FormHelpText: React.FC<FormHelpTextProps> = React.memo(props => {
  const { message, component, href } = props;

  let content;
  if (component === 'a' || component === 'button') {
    content = (
      <LinkV1 color={'currentColor'} {...props} to={href!}>
        {message}
      </LinkV1>
    );
  } else {
    content = message;
  }

  return (
    <TextV1 typeVariant="label2" color="mono70">
      {content}
    </TextV1>
  );
});
