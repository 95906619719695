import React from 'react';
import { useMobileView } from '../../useMobileView';
import { ButtonV2, SpacingStack } from '@withjoy/joykit';
import { MobileGuestSubMenu } from '../MobileGuestSubMenu';
import { Wrapper, ButtonContainer, styles } from './MobileGuestMenu.styles';

export const MobileGuestMenu: React.FC = () => {
  const { topLevelLinks, resourceRedirectLinks } = useMobileView();
  return (
    <Wrapper {...styles.wrapper}>
      <SpacingStack spacing={6} paddingX={6}>
        {resourceRedirectLinks.map(props => (
          <MobileGuestSubMenu key={props.type + props.heading} {...props} />
        ))}
      </SpacingStack>
      <ButtonContainer {...styles.suttonContainer}>
        <ButtonV2
          variant="ghost"
          intent="neutral"
          width="100%"
          as="a"
          href={topLevelLinks.findACouple.href}
          target={topLevelLinks.findACouple.target}
          onClick={topLevelLinks.findACouple.onClick}
        >
          {topLevelLinks.findACouple.label}
        </ButtonV2>
      </ButtonContainer>
    </Wrapper>
  );
};
