import { Box, keyframes, styled, StyleSystemProps } from '@withjoy/joykit';
import { ArrowUp } from '@withjoy/joykit/icons';
import { animationTransition, animationTransitionExt } from '@shared/utils/animationTransition';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

const bounceKeyframe = keyframes`
  0% {
    bottom: 50%;
  }

  50% {
    bottom: 60%;
  }

  100% {
    bottom: 50%
  }
`;

export const StyledRoot = styled(Box)`
  &.is-visible {
    > svg {
      animation: ${bounceKeyframe} 1s 500ms;
    }
  }
`;

export const StyledBackground = styled(Box)``;

export const StyledArrow = styled(ArrowUp)``;

const rootStyles: CSSObject = {
  position: 'fixed',
  bottom: 0,
  right: 0,
  size: 64,
  zIndex: 'sticky',
  userSelect: 'none',
  pointerEvents: 'none',
  ['&.is-visible']: {
    userSelect: 'all',
    pointerEvents: 'all',
    [' > span']: {
      cursor: 'pointer',
      transform: 'rotate(45deg)',
      transition: animationTransition('transform')
    },

    [' > svg']: {
      opacity: 1,
      transition: animationTransitionExt({ property: 'opacity', delay: '400ms' })
    }
  }
};

const backgroundStyles: StyleSystemProps = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  bottom: '-100%',
  right: '-100%',
  borderRadius: '100%',
  borderWidth: '64px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderLeftColor: 'black',
  transform: 'rotate(-45deg)',
  transition: animationTransitionExt({ property: 'transform', delay: '200ms' })
};

const arrowStyles: StyleSystemProps = {
  pointerEvents: 'none',
  opacity: 0,
  position: 'absolute',
  bottom: '50%',
  right: '50%',
  color: 'white',
  transform: 'translate(90%, 90%)',
  transition: animationTransitionExt({ property: 'opacity', duration: '300ms' })
};

export const styles = {
  root: rootStyles,
  background: backgroundStyles,
  arrow: arrowStyles
};
