import { Box, Flex, styled } from '@withjoy/joykit';
import { DrawerV1 } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';

const NAV_HEIGHT = 152;

export const EMAIL_EDITOR_DRAWER_WIDTH = 650;

export const StyledEcardEditorDrawer = styled(DrawerV1)`
  height: 100%;
  width: 650px;
  max-width: 100%;
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
  overflow: hidden;
  border-radius: 0;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ErrorFiller = styled(Box)<{ isRendered?: boolean }>`
  height: 20px;
  display: ${({ isRendered }) => (isRendered ? 'inline-block' : 'none')};
`;

export const FormWrapper = styled(Box)`
  height: calc(100% - ${NAV_HEIGHT}px);
  overflow-y: scroll;
`;

export const DrawerButtonsWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: ${NAV_HEIGHT}px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px -14px 30px -18px rgba(0, 0, 0, 0.08);
`;

export const EcardEditorDesc = styled.span`
  ${InterFonts.caption}
  color: ${props => props.theme.colors.mono12};
  margin-top: 4px;
  text-align: center;
`;

export const EcardEditorDescLink = styled.a`
  color: ${props => props.theme.colors.primary8};

  &:hover {
    color: ${props => props.theme.colors.primary6};
  }
`;

export const EcardEditorDescHighlight = styled.span`
  color: ${props => props.theme.colors.primary8};
`;
