import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetEventByNameForPeoplePickerQuery, PersonFragment } from '@graphql/generated';
import { LabelIdsToPeopleIdsMap, LabelViewItem, PersonViewItem } from '@shared/components/PeoplePicker/PeoplePicker';
import { PeoplePickerDialog } from '@shared/components/PeoplePicker/PeoplePicker.dialog';
import { useOverlayUnstable } from '@withjoy/joykit';
import { getInGroupPosition } from '@shared/utils/groupedListUtils';
import { isEqual } from 'lodash-es';

export type SelectedPerson = Readonly<Partial<PersonFragment & { full_name: string }>>;

interface ControllerProps
  extends Readonly<{
    title: React.ReactNode;
    eventHandle: string;
    onSelectedPeople?: (people: ReadonlyArray<SelectedPerson>) => void;
    initialSelectedPeople?: ReadonlyArray<SelectedPerson>;
  }> {}

export const useSelectGuestsController = ({ eventHandle, title, onSelectedPeople, initialSelectedPeople }: ControllerProps) => {
  const [selectedPeople, setSelectedPeople] = useState<ReadonlyArray<SelectedPerson>>([]);
  const [isPeoplePickerSaving, setIsPeoplePickerSaving] = useState(false);
  const [viewLabels, setViewLabels] = useState<ReadonlyArray<LabelViewItem>>([]);
  const [viewPeople, setViewPeople] = useState<ReadonlyArray<PersonViewItem>>([]);
  const [labelIdsToPeopleIdsMap, setLabelIdsToPeopleIdsMap] = useState<LabelIdsToPeopleIdsMap>({});
  const { data, error, loading } = useGetEventByNameForPeoplePickerQuery({
    batchMode: undefined,
    variables: { eventHandle }
  });
  const apiLabels = data?.eventByName?.labels || null;
  const apiHouseholds = useMemo(() => {
    return data?.eventByName?.guests.households || [];
  }, [data]);

  const isPersonSelected = useCallback(
    (person: PersonFragment): boolean => {
      return selectedPeople.some(selectedPerson => person.id === selectedPerson.id || person.personIdTag === selectedPerson.personIdTag);
    },
    [selectedPeople]
  );

  useEffect(() => {
    if (initialSelectedPeople) {
      setSelectedPeople(initialSelectedPeople);
    }
  }, [initialSelectedPeople]);

  useEffect(() => {
    const newLabelIdsToPeopleIdsMap: Record<string, Array<string>> = {};
    const newViewLabels = (apiLabels || [])
      .filter(label => label.people && label.people.length > 0)
      .map(
        (label): LabelViewItem => {
          newLabelIdsToPeopleIdsMap[label.id] = label.people.map(person => person.id);
          return { id: label.id, selected: false, labelText: `${label.display} (${label.people.length})` };
        }
      );
    const newViewPeople = apiHouseholds.reduce((acc, household) => {
      const householdPersons = household.members.map(
        (person, index): PersonViewItem => ({
          id: person.id,
          personIdTag: person.personIdTag || undefined,
          selected: isPersonSelected(person),
          labelText: [person.firstName, person.lastName].filter(name => !!name).join(' '),
          sublabelText: `${person.hasEmail ? '' : 'Missing email address'}`,
          disabled: !person.hasEmail,
          groupId: person.household!.id,
          avatarName: person.avatarName || '',
          inGroupPosition: getInGroupPosition(index, household.members.length),
          email: person.email || ''
        })
      );
      return [...acc, ...householdPersons];
    }, [] as PersonViewItem[]);

    if (!isEqual(newViewLabels, viewLabels)) {
      setViewLabels(newViewLabels);
    }
    if (!isEqual(newViewPeople, viewPeople)) {
      setViewPeople(newViewPeople);
    }
    if (!isEqual(newLabelIdsToPeopleIdsMap, labelIdsToPeopleIdsMap)) {
      setLabelIdsToPeopleIdsMap(newLabelIdsToPeopleIdsMap);
    }
  }, [apiLabels, apiHouseholds, isPersonSelected, setViewLabels, setViewPeople, viewLabels, viewPeople, labelIdsToPeopleIdsMap]);

  const submit = ({ mergedSelectionPeople }: { mergedSelectionPeople: ReadonlyArray<PersonViewItem> }) => {
    setIsPeoplePickerSaving(true);
    const newSelectedPeople = mergedSelectionPeople
      .filter(person => person.selected)
      .map(person => ({ id: person.id, personIdTag: person.personIdTag, full_name: person.labelText }));
    if (onSelectedPeople) {
      onSelectedPeople(newSelectedPeople);
    }
    hidePeoplePicker();
    // it is used only specifically for the invitations/save the dates case, as no labels selection should be saved - only people
    setSelectedPeople(newSelectedPeople);
  };

  const [showPeoplePicker, hidePeoplePicker] = useOverlayUnstable(
    {
      overlayKey: 'people_picker',
      render: overlayProps => <PeoplePickerDialog {...overlayProps} />,
      props: {
        title,
        eventHandle,
        isLoading: isPeoplePickerSaving,
        allPeople: viewPeople,
        allLabels: viewLabels,
        labelIdsToPeopleIdsMap,
        submit
      }
    },
    { dependencies: [viewPeople, viewLabels, isPeoplePickerSaving] }
  );

  const startSelection = useCallback(() => {
    setViewLabels([...viewLabels]);
    setViewPeople([...viewPeople]);
    setIsPeoplePickerSaving(false);
    showPeoplePicker();
  }, [setIsPeoplePickerSaving, showPeoplePicker, viewLabels, viewPeople]);

  return {
    isPeoplePickerSaving,
    loading,
    error,
    hidePeoplePicker,
    selectedPeople,
    startSelection,
    viewPeople
  };
};
