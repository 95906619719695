import { styled, TextV2 } from '@withjoy/joykit';
import { OptionSelectDown } from '@withjoy/joykit/icons';
import { Link } from '@react-router';
import { animationTransition } from '@shared/utils/animationTransition';
import { buttonStates, dropdownStates } from '@apps/admin/routes/Dashboard/common/commonDasboard.styles';
import { css, pxToRem } from '@withjoy/joykit/theme';

export const NavTitle = styled(TextV2)`
  align-items: center;
  display: flex;
  height: 1.125rem;
  margin-top: 0.3125rem;
  text-align: center;

  svg {
    flex: 0 0 auto;
    height: 1.125rem;
    width: 1.125rem;
  }
`;

export const ExpandArrow = styled(OptionSelectDown)<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg) translateZ(0)'};
  transition: transform 200ms ease-in-out;
`;

export const ItemList = styled.div<{ isOpen: boolean; position: { x: number; y: number } }>`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  box-shadow: 0 0.4375rem 1.6875rem rgba(44, 41, 37, 0.06), 0 0.25rem 1rem rgba(0, 0, 0, 0.07);
  cursor: default;
  display: none;
  left: ${props => `${props.position.x}px`};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  padding: 0.35rem;
  position: fixed;
  top: ${props => `${props.position.y}px`};
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0.8)')};
  transition: ${animationTransition('opacity', 'transform', 'box-shadow', 'height')};
  transition-duration: 0.3s;
  transform-origin: left top;
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  z-index: ${({ theme, isOpen }) => (isOpen ? theme.zIndices.dropdown : 'unset')};
  max-height: calc(100vh - 7.125rem);
  min-width: ${pxToRem(259.63)};
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    display: block;
  }
`;

export const ListButton = styled.a<{ isOverflowOpen: boolean }>`
  ${({ theme }) => theme.typography.variants.body2};
  ${dropdownStates};
  align-items: center;
  background: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  min-height: 3rem;
  opacity: ${props => (props.isOverflowOpen ? 1 : 0)};
  padding: 1rem 0.5rem;
  white-space: nowrap;
  width: 100%;

  svg:first-child {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.label2};
    padding: 0.75rem 0.5rem;
  }
`;

export const ListButtonLabel = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  white-space: nowrap;
`;

export const NavigationItemRoot = styled(Link)<{ isOpen: boolean }>`
  ${buttonStates};
  background: ${({ isOpen, theme }) => (isOpen ? theme.colors.mono2 : theme.colors.white)};
  border-radius: 0.25rem;
  cursor: pointer;
  height: 6.5rem;
  position: relative;
  user-select: none;
  width: 6.8125rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    height: 4.75rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    height: 5.5rem;
    width: 7.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm4}) {
    height: 4.75rem;
    width: 6.8125rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md3}) {
    height: 5.5rem;
    width: 7.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 7rem;
    width: 8.75rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 8.875rem;
    width: 11.25rem;
  }
`;

export const ItemContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const NavIcon = styled.div`
  position: relative;
  svg {
    -webkit-backface-visibility: initial;
    -webkit-transform-origin: 50% 50%;
    height: 3rem;
    width: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    svg {
      height: 3rem;
      width: 3rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm4}) {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    svg {
      height: 3rem;
      width: 3rem;
    }
  }
`;

const NewBadgeFormat = css`
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${props => props.theme.colors.buttonProductiveHover};
  background-color: ${props => props.theme.colors.buttonProductiveHover};
  border-radius: 12px;
  letter-spacing: -0.032px;
`;

export const NewBadge = styled(TextV2)`
  ${NewBadgeFormat}
  padding: 3px 8px;
  font-size: ${pxToRem(13)};
`;

export const NotificationPill = styled(NewBadge)`
  background-color: #f16e00;
  border-color: #f16e00;
  border-radius: 24px;
  height: ${pxToRem(18)};
`;

export const NewBadgeItem = styled(TextV2)`
  ${NewBadgeFormat}
  font-weight: 700;
  font-size: ${pxToRem(13)};
  position: absolute;
  border-radius: 12px;
  padding: 3px 8px;
  top: -15px;
  right: -30px;
`;
