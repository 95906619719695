import { useCallback, useRef } from 'react';
import type { UseCheckboxProps } from '.';

import { PropGetter } from '@withjoy/joykit/utils';
import { callAllHandlers } from '@shared/utils/functions';
import { useControllableState } from '@shared/utils/hooks/useControllableState';
import { dataAttr, stopEvent } from '@shared/utils/dom';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useBoolean } from '@shared/utils/hooks/useBoolean';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { mergeRefs } from '@shared/utils/hooks/setRef';

export const useCheckbox = (props: UseCheckboxProps) => {
  const {
    id,
    isDisabled,
    isIndeterminate,
    name,
    value,
    isChecked: isCheckedProp,
    isReadOnly,
    isRequired,
    defaultChecked,
    onBlur: onBlurProp,
    onChange: onChangeProp,
    onFocus: onFocusProp,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedBy,
    ...htmlProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [isCheckedState, setIsCheckedState, isControlled] = useControllableState({ value: isCheckedProp, defaultValue: defaultChecked });

  const [isFocused, setIsFocused] = useBoolean();

  const onBlur = useEventCallback(onBlurProp);
  const onChange = useEventCallback(onChangeProp);
  const onFocus = useEventCallback(onFocusProp);

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly || isDisabled) {
        e.preventDefault();
        return;
      }
      if (!isControlled) {
        setIsCheckedState(isIndeterminate ? true : e.target.checked);
      }

      onChange?.(e);
    },
    [isControlled, isDisabled, isIndeterminate, isReadOnly, onChange, setIsCheckedState]
  );

  useIsomorphicLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(isIndeterminate);
    }
  }, [isIndeterminate]);

  useIsomorphicLayoutEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.checked !== isCheckedState) {
        setIsCheckedState(inputRef.current.checked);
      }
    }
  }, [isCheckedState, setIsCheckedState]);

  const getRootProps: PropGetter = useCallback(
    (props = {}) => {
      return {
        ...props,
        ...htmlProps,
        'data-disabled': dataAttr(isDisabled),
        'data-checked': dataAttr(isCheckedState)
      };
    },
    [htmlProps, isCheckedState, isDisabled]
  );

  const getInputProps: PropGetter<HTMLInputElement> = useCallback(
    (props = {}, forwardedRef) => {
      return {
        ...props,
        ref: mergeRefs(inputRef, forwardedRef),
        type: 'checkbox',
        id,
        name,
        value: value || '',
        checked: isCheckedState || false,
        onChange: callAllHandlers(props.onChange, handleOnChange),
        onFocus: callAllHandlers(props.onFocus, onFocus, setIsFocused.on),
        onBlur: callAllHandlers(props.onBlur, onBlur, setIsFocused.off),
        readOnly: isReadOnly,
        required: isRequired,
        disabled: isDisabled,
        'aria-label': ariaLabel,
        'aria-labelledby': ariaLabelledBy,
        'aria-describedby': ariaDescribedBy
      };
    },
    [
      ariaLabel,
      ariaLabelledBy,
      ariaDescribedBy,
      handleOnChange,
      id,
      isCheckedState,
      isDisabled,
      isReadOnly,
      isRequired,
      name,
      onBlur,
      onFocus,
      setIsFocused.on,
      setIsFocused.off,
      value
    ]
  );

  const getControlProps: PropGetter = useCallback(
    (props = {}) => {
      const onPressDown = (event: React.MouseEvent) => {
        // On mousedown, the input blurs and returns focus to the `body`,
        // we need to prevent this. Native checkboxes keeps focus on `input`
        event.preventDefault();
      };

      return {
        ...props,
        'aria-hidden': true,
        'data-checked': dataAttr(isCheckedState),
        'data-disabled': dataAttr(isDisabled),
        'data-indeterminate': dataAttr(isIndeterminate),
        'data-focus': dataAttr(isFocused),
        onMouseDown: callAllHandlers(props.onMouseDown, onPressDown)
      };
    },
    [isCheckedState, isDisabled, isFocused, isIndeterminate]
  );

  const getLabelProps: PropGetter = useCallback(
    (props = {}) => {
      return {
        ...props,
        'data-disabled': dataAttr(isDisabled),

        /**
         * Prevent `onBlur` being fired when the checkbox label is touched
         */
        onMouseDown: callAllHandlers(props.onMouseDown, stopEvent),
        onTouchStart: callAllHandlers(props.onTouchStart, stopEvent)
      };
    },
    [isDisabled]
  );

  return {
    state: {
      isChecked: isCheckedState,
      isIndeterminate
    },
    getRootProps,
    getControlProps,
    getInputProps,
    getLabelProps
  };
};
