import globalWindow from '@shared/core/globals';
import { withWindow } from '@shared/utils/withWindow';
import { useCallback } from 'react';

const navigator = globalWindow.navigator;

export const useCopyToClipboard = (callback?: () => void) => {
  const isOS = () => {
    return navigator?.userAgent.match(/ipad|iphone/i);
  };
  const fallbackCopyTextToClipboard = useCallback((text: string) => {
    withWindow(window => {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      textArea.style.top = '100';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);

      if (isOS()) {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.focus();
        textArea.select();
      }

      try {
        document.execCommand('copy');
      } catch (e) {
        // todo error handle
      }

      document.body.removeChild(textArea);
    });
  }, []);

  const primary = useCallback(
    (str: string) => {
      navigator?.clipboard.writeText(str);
      callback && callback();
    },
    [callback]
  );

  const fallback = useCallback(
    (str: string) => {
      fallbackCopyTextToClipboard(str);
      callback && callback();
    },
    [callback, fallbackCopyTextToClipboard]
  );

  if (!navigator?.clipboard) {
    return fallback;
  }
  return primary;
};
