import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { ProductOffering } from '../ConciergeServiceSection/ConciergeServiceSection.types';

export const useCustomizePaperlustConcierge = () => {
  const { goToConcierge } = useCardsRouterContext();

  const handleCustomizePaperlustConciergeClick = useEventCallback((offering: ProductOffering) => {
    goToConcierge(offering.identifier);
  });

  return {
    handleCustomizePaperlustConciergeClick
  };
};
