import React from 'react';
import { Wrapper } from './AccountMe.styles';
import TopBarV2 from './components/TopBarV2';

interface Props extends Readonly<{}> {}

export const AccountMe: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <TopBarV2 />
      {children}
    </Wrapper>
  );
};
