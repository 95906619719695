import React from 'react';
import { useTranslation } from '@shared/core';
import { useCreateAccountController } from '@apps/createWedding/routes/CreateAccount/CreateAccount.controller';
import { CreateAccountWrapper } from '@apps/createWedding/routes/CreateAccount/CreateAccount.styles';
import { PrivacyPolicyLink } from '@apps/createWedding/routes/CreateAccount/components/PrivacyPolicyLink';
import { TermsOfServiceLink } from '@apps/createWedding/routes/CreateAccount/components/TermsOfServiceLink';
import { PrivacyNoticeLink } from '@apps/createWedding/routes/CreateAccount/components/PrivacyNoticeLink';
import { useScenario } from '@apps/createWedding/hooks';
import { ReactComponent as FacebookIcon } from '@assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg';
import { PixelImage } from '@apps/createWedding/components/PixelImage';
import { ButtonV2, FormControl, InputV2, LinkV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { Email, Lock } from '@withjoy/joykit/icons';
import { ShowDevAlert } from '@apps/createWedding/components/ShowDevAlert';
import { wrapWithCreateAccountHealthProvider } from './CreateAccount.health';
import { FindCouple } from './components/FindCouple';

export const CreateAccount = wrapWithCreateAccountHealthProvider(() => {
  const { t, t2 } = useTranslation('createWedding');
  const { formik, submissionError, goToSignIn, signInWithFacebook, signInWithGoogle, showSocialSignIn, focusHandlers, getFieldErrorProps } = useCreateAccountController();
  const scenario = useScenario();

  const translations = t('createAccount');
  const { helpText, heroText } = t2('createAccount', 'scenarios', scenario);
  const termsAndPrivacy = translations.termsAndPrivacy({ PrivacyPolicy: PrivacyPolicyLink, TermsOfService: TermsOfServiceLink, PrivacyNotice: PrivacyNoticeLink });

  return (
    <CreateAccountWrapper spacing={8}>
      <ShowDevAlert />
      <SpacingStack spacing={1}>
        <TextV2 textAlign="center" as="h1" typographyVariant="hed6">
          {heroText}
        </TextV2>
        <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
          {helpText}{' '}
          <LinkV2 onClick={goToSignIn} textDecoration="none">
            <TextV2 as="span" typographyVariant="body3" color="linkText">
              {translations.signIn()}
            </TextV2>
          </LinkV2>
        </TextV2>
      </SpacingStack>
      {showSocialSignIn && (
        <SpacingStack spacing={6}>
          <ButtonV2 onClick={signInWithFacebook} variant="outline" startIcon={<FacebookIcon />}>
            {translations.signInWithFacebook()}
          </ButtonV2>
          <ButtonV2 onClick={signInWithGoogle} variant="outline" startIcon={<GoogleIcon />}>
            {translations.signInWithGoogle()}
          </ButtonV2>
        </SpacingStack>
      )}
      <form onSubmit={formik.handleSubmit}>
        <SpacingStack spacing={8}>
          <SpacingStack spacing={6}>
            <FormControl {...getFieldErrorProps('email')} label={<TextV2 typographyVariant="label3">{translations.email()}</TextV2>}>
              <InputV2 startElement={<Email color="mono10" fill="#767676" size={24} />} onFocus={focusHandlers.email} {...formik.getFieldProps('email')} />
            </FormControl>
            <FormControl {...getFieldErrorProps('password')} label={<TextV2 typographyVariant="label3">{translations.password()}</TextV2>}>
              <InputV2 type="password" startElement={<Lock color="mono10" fill="#767676" size={24} />} onFocus={focusHandlers.password} {...formik.getFieldProps('password')} />
            </FormControl>
            {submissionError && (
              <TextV2 textAlign="center" typographyVariant="body1" color="negative6">
                {submissionError}
              </TextV2>
            )}
          </SpacingStack>
          <SpacingStack spacing={6}>
            <ButtonV2 type="submit" disabled={formik.isSubmitting} intent="primary">
              {translations.createAccountCta()}
            </ButtonV2>
            <SpacingStack>
              <TextV2 textAlign="center" typographyVariant="label1" color="gray8" style={{ lineHeight: '1.17rem' }}>
                {termsAndPrivacy}
              </TextV2>
            </SpacingStack>
            <FindCouple />
            <PixelImage action="signup" />
          </SpacingStack>
        </SpacingStack>
      </form>
    </CreateAccountWrapper>
  );
});
