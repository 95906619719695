import React from 'react';
import { Box, Flex } from '@withjoy/joykit';
import { NavLink, useTranslation } from '@shared/core';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { animationTransition } from '@shared/utils/animationTransition';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

const NavItem = ({ category }: { category: StationeryTemplateCategoryEnum }) => {
  const { getShopPath } = useCardsRouterContext();
  const { viewCategoryDesigns } = useCardTelemetry();
  const { t } = useTranslation('stationery');
  const titleTrans = t('dashboard', 'categoryNav', category);

  return (
    <Box
      position="relative"
      display="inline-flex"
      alignItems="center"
      as={NavLink}
      to={getShopPath('wedding', category)}
      onClick={() => {
        viewCategoryDesigns(category);
      }}
      cursor={'pointer'}
      paddingY={4}
      _after={{
        content: '" "',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '2px',
        backgroundColor: 'mono12',
        opacity: 0,
        transition: animationTransition('opacity')
      }}
      __css={{
        ['&.active']: {
          _after: {
            opacity: 1
          },
          cursor: 'default',
          pointerEvents: 'none'
        },
        ['&:not(.active)']: {
          _hover: {
            _after: {
              opacity: 0.2
            }
          }
        }
      }}
    >
      {titleTrans()}
    </Box>
  );
};

export const CategoryNav = () => {
  const { value: showHolidayCategory } = useFeatureValue('printHolidayCategoryEnabled');
  const isMobile = useIsMobileScreen();

  return (
    <Flex
      as="ul"
      justifyContent={isMobile ? 'flex-start' : 'center'}
      height={9}
      whiteSpace="nowrap"
      overflowX={isMobile ? 'scroll' : 'hidden'}
      paddingX={{ _: 6, md: 9 }}
      borderBottom={'1px solid'}
      borderBottomColor={'mono3'}
      columnGap={6}
    >
      <NavItem category={StationeryTemplateCategoryEnum.saveTheDate} />
      <NavItem category={StationeryTemplateCategoryEnum.invitation} />
      <NavItem category={StationeryTemplateCategoryEnum.thankYou} />
      {showHolidayCategory === 'on' && <NavItem category={StationeryTemplateCategoryEnum.holiday} />}
    </Flex>
  );
};
