import { TextArea, styled } from '@withjoy/joykit';

export const StyledTextArea = styled(TextArea)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.mono14};
  transition: border-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s,
    box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.25rem;
  font-size: 0.9375rem;
  height: 3rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  &:hover {
    box-shadow: 0px 0 0px 1px ${props => props.theme.colors.linkHover};
    border-color: transparent;
    transition: box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  }
  &:focus {
    box-shadow: 0px 0 0px 2px ${props => props.theme.colors.linkText};
    border-color: transparent;
    transition: box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  }
`;
