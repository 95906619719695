export const DefaultImageStyles = [
  {
    label: 'Watercolor'
  },
  {
    label: 'InkPen'
  },
  {
    label: 'Oil Painting'
  },
  {
    label: 'Pencil'
  },
  {
    label: 'Bohemian'
  },
  {
    label: 'Pastel'
  }
];
