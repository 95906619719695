import React from 'react';
import { outlinedButtonOverrides, filledButtonOverrides } from '@apps/saveTheDate/components/Shared.styles';
import { Flex, ButtonV2 } from '@withjoy/joykit';

interface AdjustmentButtonsProps {
  cancelAdjustment: () => void;
  applyAdjustment: () => void;
}

const AdjustmentButtons: React.FC<AdjustmentButtonsProps> = ({ cancelAdjustment, applyAdjustment }) => (
  <Flex marginTop={32} justifyContent="center">
    <ButtonV2 variant="outline" width={120} marginRight={16} onClick={cancelAdjustment} overrides={outlinedButtonOverrides}>
      Cancel
    </ButtonV2>
    <ButtonV2 width={120} onClick={applyAdjustment} overrides={filledButtonOverrides}>
      Apply
    </ButtonV2>
  </Flex>
);

export default AdjustmentButtons;
