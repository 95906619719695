import { styled } from '@withjoy/joykit';

export const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledItemWrapper = styled.label`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  padding-left: 1rem;
  &:hover {
    background-color: rgb(251, 251, 251);
  }
  .joykit-choice-indicator {
    margin-right: 1rem !important;
  }
`;

export const ItemInfoWrapper = styled.span`
  flex: 1;
  min-width: 0;
`;

export const StyledListHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding-bottom: 0.625rem;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  > .list-header-label {
    font-size: 13px;
    line-height: 20px;
    margin-left: 1rem;
  }
  > .list-header-link {
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
  }
`;
