import { withWindow } from '@shared/utils/withWindow';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Redirects to a new URL after a specified timeout.
 * Required when trying to fire a telemetry event before a redirect.
 */
export const useRedirectAfterTimeout = () => {
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      const timeoutId = timeoutRef.current;
      if (typeof timeoutId === 'number') {
        withWindow(global => {
          global.clearTimeout(timeoutId);
        });
      }
    };
  });
  return useCallback((redirectUrl: string, timeoutMs: number = 500) => {
    withWindow(global => {
      timeoutRef.current = global.setTimeout(() => {
        global.location.href = redirectUrl;
      }, timeoutMs);
    });
  }, []);
};
