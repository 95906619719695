import { SpacingStack, SpacingStackProps, styled } from '@withjoy/joykit';

export const Wrapper = styled(SpacingStack)``;
const wrapperStyles: SpacingStackProps = {
  spacing: 7,
  paddingTop: 5,
  paddingBottom: 6,
  flexGrow: 1,
  justifyContent: 'space-between',
  height: '86%',
  overflowY: 'scroll'
};

export const ButtonContainer = styled(SpacingStack)``;
const suttonContainerStyles: SpacingStackProps = {
  spacing: 5,
  paddingX: 6,
  justifyContent: 'flex-end'
};

export const styles = {
  wrapper: wrapperStyles,
  suttonContainer: suttonContainerStyles
};
