import { usePdpData } from '@apps/card/routes/Dashboard/components/ProductDetailsPage/usePdpData';
import { AIBackgroundPromptPayload, StationeryTemplateCategoryEnum } from '@graphql/generated';
import React, { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import { ImageLayerData, LayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';
import { StationeryTemplateWithThemeJson } from '@apps/card/routes/Dashboard/components/DesignsGallery/useDesignTemplates';
import { msChampagne } from '../HintText/FirstTimeExperienceConfig';

export const AICardDemoContainer = ({
  src,
  templateId,
  category,
  promptPayload,
  index
}: {
  src: string;
  templateId: string;
  category: StationeryTemplateCategoryEnum;
  promptPayload: AIBackgroundPromptPayload;
  index: number;
}) => {
  const { template, loading } = usePdpData(templateId, category);
  const page = template?.themeJSON.card.front;

  if (!loading) {
    if (page) {
      return <AICardDemo src={src} index={index} promptPayload={promptPayload} template={template!} page={page!} />;
    }
  }

  return <div></div>;
};

export const AICardDemo = ({
  template,
  page,
  promptPayload,
  index,
  src
}: {
  template: StationeryTemplateWithThemeJson;
  page: { fill: string; layers: LayerData[] };
  promptPayload: AIBackgroundPromptPayload;
  index: number;
  src: string;
}) => {
  const [showChampagne, setShowChampagne] = useState(true);
  const [timeoutInit, setTimeoutInit] = useState(false);

  useEffect(() => {
    if (!timeoutInit) {
      setTimeout(() => {
        setShowChampagne(false);
        (page.layers[0] as ImageLayerData).imageData.src = src;
      }, msChampagne);
      setTimeoutInit(true);
    }
  }, [page.layers, src, timeoutInit]);

  return <PageWrapper index={index} loading={showChampagne} promptPayload={promptPayload} template={template!} page={page!} />;
};
