import { styled } from '@withjoy/joykit';

export const FormSideContainer = styled.div<{ mobile?: boolean | undefined }>`
  width: ${props => (props.mobile ? '100%' : '40%' || '40%')};
  height: ${props => (props.mobile ? 'fit-content' : '100vh')};
  padding: 0px 40px 0px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  height: fit-content;
  width: 340px;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 50px;
  text-align: center;

  span {
    white-space: nowrap;
  }
`;

export const FormSection = styled.div<{ gap?: string | undefined; alignment?: string | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.alignment || 'center'};
  align-items: ${props => props.alignment || 'center'};
  text-align: ${props => props.alignment || 'center'};
  width: 100%;

  gap: ${props => props.gap || '0px'};
`;
