import { useTranslation } from '@shared/core';

export const useBookingDialogReminderOptions = () => {
  const { t2 } = useTranslation('bookingDialog');
  const { opt1day, opt1week, opt2weeks } = t2('options');
  return [
    {
      id: 'reminder-1',
      value: '1',
      label: opt1day
    },
    {
      id: 'reminder-2',
      value: '7',
      label: opt1week
    },
    {
      id: 'reminder-3',
      value: '14',
      label: opt2weeks
    }
  ];
};
