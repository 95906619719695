import { useMemo } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { isArray, isObject } from '@shared/utils/assertions';
import { PromotionConfig } from '../routes/CardCustomizer/usePromotionConfig';

function usePromoBannerConfig() {
  const { payload: promotionBannerPayload } = useFeatureValue('printHomePromotionBanner');
  const { payload: couponCodeLaunchPayload } = useFeatureValue('printCouponCodeLaunch');

  const bannerPromoConfig = useMemo(() => {
    if (isObject(promotionBannerPayload) && promotionBannerPayload.code && isArray(couponCodeLaunchPayload)) {
      // `printHomePromotionBanner` feature flag will tell us which promo to highlight
      const config = (couponCodeLaunchPayload as PromotionConfig[]).find(promo => promo.active && promo.code.toLowerCase() === promotionBannerPayload.code.toLowerCase());
      return config;
    }
    return undefined;
  }, [couponCodeLaunchPayload, promotionBannerPayload]);

  return {
    bannerPromoConfig
  };
}

export { usePromoBannerConfig };
