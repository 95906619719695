import { animationTransition } from '@shared/utils/animationTransition';
import { Box, styled } from '@withjoy/joykit';
import { SaveTheDateEditorType } from '@apps/saveTheDate/SaveTheDate.types';

const topValues = { email: '40vh', design: '30vh', content: 'calc(30vh + 80px);' };

export const StyledEditButton = styled(Box)<{ $isVisible?: boolean; $type: SaveTheDateEditorType }>`
  position: absolute;
  top: ${({ $type }) => topValues[$type]};
  left: 97%;
  cursor: pointer;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: ${animationTransition('visibility', 'opacity')};
`;
