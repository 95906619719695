import React from 'react';
import { Box } from '@withjoy/joykit';
import { StyledThemeWrapper, StyledThemeImage, StyledThemeTitleContainer, StyledThemeTitle } from './EcardThemeCard.styles';
import { addRendition } from '@shared/utils/photoRendition';

interface Theme
  extends Readonly<{
    id: string;
    name: string;
    thumbnailUrl: string;
  }> {}

interface EcardThemeCardProps
  extends Readonly<{
    selected: boolean;
    theme: Theme;
    handleThemeSelect: (themeSelected: string) => void;
  }> {}

export const EcardThemeCard: React.FC<EcardThemeCardProps> = ({ selected, theme: { id, name, thumbnailUrl }, handleThemeSelect }) => {
  return (
    <StyledThemeWrapper
      selected={selected}
      onClick={() => {
        handleThemeSelect(id);
      }}
    >
      <Box position="relative" flex={1}>
        <StyledThemeImage src={addRendition({ url: thumbnailUrl, renditionSize: 'small' })} />
      </Box>
      <StyledThemeTitleContainer>
        <StyledThemeTitle>{name}</StyledThemeTitle>
      </StyledThemeTitleContainer>
    </StyledThemeWrapper>
  );
};
