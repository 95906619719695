import React from 'react';
import { OptionType, SelectV1 } from '@withjoy/joykit';
import { useLinkSelectorController } from './LinkSelector.controller';
import { GeneralMessageLinkType } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormControl } from '@withjoy/joykit';
// import { Drawer } from '@joykit-v2/components/core/Drawer';
import { StyledLabel } from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/EmailEditPanel.styles';

interface LinkSelectorProps {
  handleLinkSelect: (link: GeneralMessageLinkType) => void;
  initialSelectedLink: GeneralMessageLinkType;
  linkLabel: string;
}

export const LinkSelector: React.FC<LinkSelectorProps> = props => {
  const { handleLinkSelect, initialSelectedLink, linkLabel } = props;

  const { linkOptions, selectedLinkOption, handleOnLinkOptionChange } = useLinkSelectorController({ handleLinkSelect, initialSelectedLink });

  const handleOnChange = (value: OptionType | null) => {
    if (value) {
      handleOnLinkOptionChange(value);
    }
  };
  return (
    <FormControl label={<StyledLabel>{linkLabel}</StyledLabel>}>
      <SelectV1 value={selectedLinkOption} searchable={false} onChange={handleOnChange} options={linkOptions} />
    </FormControl>
  );
};
