import { useState } from 'react';
import { FontDefinition } from '@shared/utils/fonts/fonts';
import { windowExists } from '@shared/utils/windowExists';
// working around joykit's createGlobalStyle
// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle, DefaultTheme, GlobalStyleComponent } from 'styled-components';

const generateFonts = (fontList: FontDefinition[]) => createGlobalStyle`
  ${fontList.map(
    font => `
    @font-face {
      font-family: ${font.fontFamily};
      font-display: fallback;
      src: url(${font.url}2) format("${font.format}2"), url(${font.url}) format("${font.format}");
      font-style: ${font.fontStyle};
      font-weight: ${font.fontWeight};
    }
  `
  )}
`;

const loadFont = async (font: { id: string; url: string }) => {
  return new Promise<void>((resolve, reject) => {
    if (windowExists()) {
      const document = window.document;
      if (document.getElementById(`email-font-${font.id}`)) {
        resolve();
      } else {
        const head = document.getElementsByTagName('head')[0];
        const fontScriptTag = document.createElement('script');
        fontScriptTag.id = `email-font-${font.id}`;
        head.appendChild(fontScriptTag);
        fontScriptTag.onload = () => {
          resolve();
        };
        fontScriptTag.src = font.url;
      }
    }
  });
};

const loadFonts = async (fontUrls: Array<{ id: string; url: string }>) => {
  const fontPromises = fontUrls.map(font => loadFont(font));
  return Promise.all(fontPromises);
};

const getFontListLoadedHook = (fontList: FontDefinition[]): (() => boolean) => {
  return () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fontLoaded, setFontLoaded] = useState(false);
    loadFonts(fontList).then(() => setFontLoaded(true));
    return fontLoaded;
  };
};

export const fontImportHook = (fontList: FontDefinition[]): [() => boolean, GlobalStyleComponent<{}, DefaultTheme>] => {
  const FontsGlobalStyle = generateFonts(fontList);
  return [getFontListLoadedHook(fontList), FontsGlobalStyle];
};
