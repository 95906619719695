import { useFormik } from 'formik';
import { CreateEventMutation, EventType, EventUserStatus, useTrackImpactRadiusMutation } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import * as Yup from 'yup';
import { getLocalStorage, useIdentify, useSegmentGroupCallForEvent, useTranslation } from '@shared/core';
import { useEffect, useMemo, useState } from 'react';
import { Location } from '@shared/components/LocationInput/LocationInput.controller';
import { addMonths } from 'date-fns';
import { useDesignParams } from '@apps/createWedding/hooks/useDesignParams';
import { getTelemetryDateLabel, omitEmpty } from '@apps/createWedding/utils';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { createAndAttachPixelImage } from '@apps/createWedding/components/PixelImage/PixelImage';
import { getTimezone } from '@apps/createWedding/utils/getTimezone';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { useViewContext } from '@apps/createWedding/components/View/ViewProvider';
import { useEventState } from '@apps/createWedding/EventState';
import { useFeatureValue } from '@shared/core/featureFlags';
import { readAcquisitionContexts, readAndResetAcquisitionContexts } from '@shared/createEvent/acquisitionContextTracking';
import { StoredAcquisitionContextDetails } from '@shared/createEvent/acquisitionContextTracking/acquisitionContextTracking.types';
import { addAction } from '@shared/utils/logger';

import { getEventTypeConfig } from '@config';
import { getCreateEventPayload } from '@shared/utils/impactRadius/utilsImpactRadius';
import { useAutoPopulateAccommodationPlaces } from '@apps/createWedding/hooks/useAutoPopulateAccommodationPlaces';

const CREATE_WEDDING_KEY = '@@createwedding';

export const PENDING_CREATION_KEY = '@@createwedding/pending-creation';

export type CreateWeddingFormFields = {
  ownerFirstName: string;
  partnerFirstName: string | null;
  weddingDate: Date | null;
  isDateValid: boolean;
  location: Location | null;
  font?: string;
  template?: string;
  scenario?: string;
  theme?: string;
};

export type OnEventCreateSuccessHandler = (data: NonNullable<CreateEventMutation['createEventV0301']>) => void;

export interface CreateWeddingFormProps {
  disableQuestionnaire?: boolean;
  skipWaitForDesignParamToSettle?: boolean;
  onAfterEventCreateInitiated?: () => void;
  onEventCreateSuccess?: OnEventCreateSuccessHandler;
  isCreateCtaLoading?: boolean;
  logoutRedirectUri?: string;
}

interface UseCreateWeddingFormControllerArgs extends CreateWeddingFormProps {}

export const useCreateWeddingFormController = (args: UseCreateWeddingFormControllerArgs) => {
  const { disableQuestionnaire, logoutRedirectUri, onAfterEventCreateInitiated, onEventCreateSuccess, skipWaitForDesignParamToSettle } = args;
  const authProvider = useAuth();
  const { identify } = useIdentify();
  const viewContext = useViewContext(false);
  const { createEvent, addEventCreatedListener } = useEventState();
  const localStorage = getLocalStorage();
  const { t } = useTranslation('createWedding');
  const { designParams, settled } = useDesignParams();
  const telemetry = useCreateWeddingTelemetry();
  const [blockWeddingDate, setBlockWeddingDate] = useState<boolean>(false);
  const [trackImpactRadius] = useTrackImpactRadiusMutation();
  const enableBlankDates = useFeatureValue('enableBlankDates');

  const accommodationsAutoPopulatePlacesExperimentEnabled = useFeatureValue('accommodationsAutoPopulatePlacesExperiment').value === 'treatment';
  const { callAutoPopulateAccommodationPlaces } = useAutoPopulateAccommodationPlaces({});
  const { executeSegmentGroupCallForEvent } = useSegmentGroupCallForEvent();

  const waitForDesignDeferred = useMemo(() => {
    let outsideResolve: (value?: unknown) => void = () => {};
    const promise = new Promise(resolve => {
      outsideResolve = resolve;
    });
    return { promise, resolve: outsideResolve };
  }, []);

  const shouldResolveDesignPromise = settled || skipWaitForDesignParamToSettle;

  useEffect(() => {
    if (shouldResolveDesignPromise) {
      waitForDesignDeferred.resolve();
    }
  }, [shouldResolveDesignPromise, waitForDesignDeferred]);

  const errorTranslations = t('weddingForm', 'errors');

  const initialValuesFromLocalStorage = useMemo(() => {
    const values = localStorage.getItem(CREATE_WEDDING_KEY);

    if (!values) {
      return;
    }

    const parsedVal = JSON.parse(values);

    return { ...parsedVal, ...(parsedVal.weddingDate ? { weddingDate: new Date(parsedVal.weddingDate) } : {}) };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTrackImpactRadius = async (event: CreateEventMutation) => {
    try {
      const eventTypeConfig = getEventTypeConfig(EventType.wedding);
      const payload = getCreateEventPayload(
        eventTypeConfig.impactRadiusCampaignId,
        eventTypeConfig.impactRadiusCreateEventActionTrackerId,
        event.createEventV0301?.id,
        authProvider.currentUser.profile?.activeAlias?.auth0Id
      );

      if (!payload) {
        return;
      }

      await trackImpactRadius({ variables: { payload } });
    } catch (e) {
      telemetry.trackError('CreateWeddingTrackImpactRadius', e);
    }
  };

  const handleCreateEvent = async (values: CreateWeddingFormFields) => {
    const { timezoneId, timezoneMethod } = getTimezone();
    const eventDate = blockWeddingDate ? null : values.weddingDate;
    const eventDateToIsoString = eventDate?.toISOString() || null;

    telemetry.dateSet(eventDate?.toISOString() ?? 'dateUndecided', getTelemetryDateLabel(eventDate));
    telemetry.timezoneRegistered({ timezoneId, timezoneMethod });
    telemetry.submitDetailsClicked(authProvider.currentUser?.profile?.email!);
    const acquisitionContext = readAcquisitionContexts();
    let forceHotelBlockEnabled = false;
    const checkAcquisitionContextData = (dataDetails: StoredAcquisitionContextDetails, key: string, value: string) => {
      for (const item of dataDetails.data) {
        if (item.key === key && item.value === value) {
          return true;
        }
      }
      return false;
    };

    acquisitionContext.forEach(item => {
      if (item?.relativeUrl?.includes('hotel-room-blocks') || checkAcquisitionContextData(item, 'utm_campaign', '[SAGE]-Wedding-Website-New-MarComm-Travel-Segments-Only')) {
        forceHotelBlockEnabled = true;
      }
    });

    addAction('checkForceHotelBlockEnabled', { acquisitionContext, forceHotelBlockEnabled, userId: authProvider.currentUser?.profile?.id });

    await waitForDesignDeferred.promise;

    createEvent({
      variables: {
        payload: omitEmpty({
          ownerFirstName: values.ownerFirstName,
          fianceeFirstName: values.partnerFirstName,
          ownerLastName: '',
          fianceeLastName: '',
          lat: values.location?.lat,
          lng: values.location?.lng,
          placeId: values.location?.placeId,
          placeName: values.location?.place,
          font: values.font,
          template: values.template,
          mainTzid: timezoneId,
          theme: values.theme,
          mainDate: enableBlankDates ? eventDateToIsoString : eventDateToIsoString || addMonths(new Date(), 9).toISOString(),
          acquisitionContext: {
            currentTimestamp: Date.now(),
            details: readAndResetAcquisitionContexts()
          }
        })
      }
    });
    localStorage.setItem(CREATE_WEDDING_KEY, undefined);
    createAndAttachPixelImage({ action: 'lead' });
    addEventCreatedListener(async event => {
      if (event.createEventV0301) {
        const { id: eventId, firebaseId, website, info } = event.createEventV0301!;

        executeSegmentGroupCallForEvent(firebaseId, {
          postgresEventId: eventId,
          eventType: info.eventType
        });
        const adminOrOwnerEventCount = authProvider.currentUser?.eventMemberships?.filter(x => x.status === EventUserStatus.admin || x.status === EventUserStatus.owner).length;
        telemetry.weddingCreated(website, firebaseId, authProvider.currentUser.profile?.email ?? '', adminOrOwnerEventCount);
        handleTrackImpactRadius(event);
        if (accommodationsAutoPopulatePlacesExperimentEnabled) {
          await callAutoPopulateAccommodationPlaces({ eventId });
        }

        identify({ forceHotelBlockEnabled });

        onEventCreateSuccess?.(event.createEventV0301);
      }
    });

    if (!disableQuestionnaire) {
      viewContext?.changeView('questionnaire');
    }

    onAfterEventCreateInitiated?.();
  };

  const formik = useFormik<CreateWeddingFormFields>({
    initialValues: {
      ownerFirstName: '',
      partnerFirstName: '',
      weddingDate: addMonths(new Date(), 9),
      location: null,
      ...initialValuesFromLocalStorage,
      ...designParams,
      isDateValid: true
    },
    validationSchema: Yup.object<CreateWeddingFormFields>({
      ownerFirstName: Yup.string().required(errorTranslations.firstNameRequired()),
      partnerFirstName: Yup.string().nullable(),
      weddingDate: Yup.date()
        .nullable()
        .test('valid date', errorTranslations.invalidDate(), function () {
          const isDateValid = this.parent.isDateValid;
          return blockWeddingDate || isDateValid;
        }),
      isDateValid: Yup.boolean(),
      location: Yup.object<Location>({ place: Yup.string().required(), lng: Yup.number(), lat: Yup.number(), placeId: Yup.string() })
        .required(errorTranslations.locationRequired())
        .nullable(true),
      font: Yup.string(),
      template: Yup.string(),
      scenario: Yup.string(),
      theme: Yup.string()
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleCreateEvent
  });

  const getFieldErrorProps = useFieldErrors<CreateWeddingFormFields>(formik);

  useEffect(() => {
    localStorage.setItem(CREATE_WEDDING_KEY, JSON.stringify(formik.values));
  }, [formik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const signOut = () => authProvider.provideLogout({}, logoutRedirectUri ?? `${window.location.origin}/${CREATE_WEDDING_APP_NAME}`);

  return { formik, user: authProvider.currentUser.profile!, getFieldErrorProps, signOut, blockWeddingDate, setBlockWeddingDate, telemetry };
};
