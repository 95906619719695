import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { EventRSVPAttendanceStatus, EventType, EventUserStatus, UserForMePageFragment } from '@graphql/generated';
import { useSegmentGroupCallForEvent, useTranslation } from '@shared/core';
import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import { withWindow } from '@shared/utils/withWindow';
import { useDisclosure } from '@withjoy/joykit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ForwardRefExoticComponent, MemoExoticComponent, RefAttributes } from 'react';
import { IconV2Props } from '@withjoy/joykit';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { ReactComponent as IconTrash } from '@assets/icons/icon-trash.svg';
import { Share } from '@withjoy/joykit/icons';
import { EventPageSlugs } from '@apps/admin/routes/Dashboard/common/enums/EventPageSlugs';
import { getProductVisibility } from '@apps/admin/routes/Dashboard/common/enums/ProductVisibilityEnum';
import { PageInfo } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/hooks/useProductRows';
import { OpenWindowIcon } from '@apps/accountMe/components/EventCardV2/EventCard.styles';
interface Args
  extends Readonly<{
    user?: UserForMePageFragment | null;
  }> {}

export type EventUserRole = { label: string; status: EventUserStatus };

export type EventCardModel = {
  id: string;
  firebaseId: string;
  postgresEventId: string;
  eventType: EventType;
  status: EventUserStatus;
  // The new cards doesn't accept multiple roles.
  roles?: string[];
  dateString?: string;
  datems?: number;
  name?: Nullable<string>;
  location?: Nullable<string>;
  imageUrl?: string;
  imageColor: string;
  eventLink: string;
  actions: { onClick: () => void; title: string; path?: string; icon?: MemoExoticComponent<ForwardRefExoticComponent<IconV2Props & RefAttributes<SVGSVGElement>>> }[];
  role?: EventUserRole;
  attending?: boolean;
};

const isOwner = (role: EventUserStatus) => EventUserStatus.owner === role;
const isAdmin = (role: EventUserStatus) => EventUserStatus.admin === role;

interface PartialItem {
  status: EventUserStatus;
  datems?: number;
}
function sortEventUsers<Item extends PartialItem>(eventUsers: Array<Item>, direction: 1 | -1) {
  return eventUsers.sort((userA, userB) => {
    if (userA.status === userB.status) {
      return direction * (userA.datems === userB.datems ? 0 : (userA.datems ?? Infinity) < (userB.datems ?? Infinity) ? -1 : 1);
    }
    if (userA.status === EventUserStatus['owner']) {
      return -1;
    }
    if (userB.status === EventUserStatus['owner']) {
      return 1;
    }
    if (userA.status === EventUserStatus['admin']) {
      return -1;
    }
    if (userB.status === EventUserStatus['admin']) {
      return 1;
    }
    return 1;
  });
}
export const cardLink = (roles: Maybe<string[]>, eventLink: string) => {
  const isOwnerOrAdmin = roles?.some(el => el === 'Owner' || el === 'Admin');
  return isOwnerOrAdmin ? `${eventLink}/edit` : eventLink;
};

export const useMeController = (args: Args) => {
  const { user } = args;

  const { events: eventsPage, ...actions } = useAccountMeTelemetry();
  const { executeSegmentGroupCallForEvent } = useSegmentGroupCallForEvent();
  const [eventIdToDeleteV2, setEventIdToDeleteV2] = useState<string>();
  const [eventIdToLeave, setEventIdToLeave] = useState<string | undefined>();
  const [eventNameToLeave, setEventNameToLeave] = useState<string | undefined>();
  const { isOpen: isShareDialogOpen, onClose: onShareDialogClose, onOpen: onShareDialogOpen } = useDisclosure();
  const { isOpen: isEventDeleteSuccessDialogOpen, onClose: onDeleteEventSuccessDialogClose, onOpen: onEventDeleteSuccessDialogOpen } = useDisclosure();
  const [{ guestSiteLink, eventPassword, isGuest, eventHandle }, setDialogData] = useState({ guestSiteLink: '', eventPassword: '', isGuest: false, eventHandle: '' });
  const eventUsers = user?.eventUsers.filter(user => !!user.event?.id);

  const { t } = useTranslation('account');

  const email = useMemo(() => user?.email || '', [user?.email]);

  const setTelemetryGroup = useCallback(
    (firebaseId: Maybe<string>, eventId: Maybe<string>, eventType: Maybe<EventType>) => {
      executeSegmentGroupCallForEvent(firebaseId, {
        postgresEventId: eventId,
        eventType: eventType
      });
    },
    [executeSegmentGroupCallForEvent]
  );

  const handleShareDialogOpen = useCallback(
    (eventHandle: string, eventPassword: string, isGuest: boolean): void => {
      const guestSiteLink = `${window.location.origin}/${eventHandle}`;
      setDialogData({ guestSiteLink, eventPassword, isGuest, eventHandle });
      onShareDialogOpen();
    },
    [onShareDialogOpen]
  );

  const isRsvpOptionPresentInGuestDropdown = (eventPages: PageInfo[], optOutOfGuestList: boolean | undefined | null): boolean => {
    const rsvpPage = eventPages.find(({ pageSlug }) => pageSlug === EventPageSlugs.Rsvp);

    const visibility = getProductVisibility(rsvpPage?.visibility);
    const isEnabled: boolean = !optOutOfGuestList;

    if (visibility === 'private' || (visibility === 'public' && isEnabled)) {
      return true;
    } else {
      return false;
    }
  };

  const dropDownActionsForCard = useCallback(
    (
      eventLink: string,
      ownsEvent: boolean,
      eventId: string,
      role: { label: string; status: EventUserStatus } | undefined,
      eventPassword: string,
      isRsvpOptionVisible: boolean,
      eventName: string,
      id: string,
      firebaseId: string,
      eventType: EventType
    ) =>
      [
        ownsEvent || role?.status === EventUserStatus.admin
          ? {
              onClick: () => {
                setTelemetryGroup(firebaseId, id, eventType);
                actions.trackEventCardMenuItemClickedV2('Edit');
                withWindow(window => {
                  const url = `${window.location.origin}/${eventLink}/edit`;
                  window.location.href = url;
                });
              },
              title: t('eventsPage', 'editEvent', 'buttonTitle')(),
              icon: EditIcon
            }
          : role?.status === EventUserStatus.guest && isRsvpOptionVisible // if it is a guest and rvsp is enabled or password protected
          ? {
              onClick: () => {
                setTelemetryGroup(firebaseId, id, eventType);
                actions.trackEventCardMenuItemClickedV2('RSVP');
                withWindow(window => {
                  const url = `${window.location.origin}/${eventLink}/rsvp`;
                  window.location.href = url;
                });
              },
              title: t('eventsPage', 'rsvpEvent', 'buttonTitle')(),
              icon: EditIcon
            }
          : null,
        {
          onClick: () => {
            setTelemetryGroup(firebaseId, id, eventType);
            actions.trackEventCardMenuItemClickedV2('Share');
            handleShareDialogOpen(eventLink, eventPassword, role?.status === EventUserStatus.guest);
          },
          title: t('eventsPage', 'shareEvent', 'buttonTitle')(),
          icon: Share
        },
        {
          onClick: () => {
            setTelemetryGroup(firebaseId, id, eventType);
            actions.trackEventCardMenuItemClickedV2('Preview');
            withWindow(window => {
              const url = `${window.location.origin}/${eventLink}`;
              window.open(url, '_blank');
            });
          },
          title: t('eventsPage', 'previewEvent', 'buttonTitle')(),
          icon: OpenWindowIcon
        },
        ownsEvent
          ? {
              onClick: () => {
                setTelemetryGroup(firebaseId, id, eventType);
                actions.trackEventCardMenuItemClickedV2('Delete');
                setEventIdToDeleteV2(eventId);
              },
              title: t('eventsPage', 'deleteEvent', 'buttonTitle')(),
              icon: IconTrash
            }
          : role?.status === EventUserStatus.guest // owners have delete option, guests have leave option, admin (collaborators) do not get any option
          ? {
              onClick: () => {
                setTelemetryGroup(firebaseId, id, eventType);
                setEventIdToLeave(eventId);
                setEventNameToLeave(eventName);
                actions.trackEventCardMenuItemClickedV2('Leave');
              },
              title: t('eventsPage', 'leaveEvent', 'buttonTitle')(),
              icon: IconTrash
            }
          : null
      ].filter(notNullOrUndefined),
    [actions, t, handleShareDialogOpen, setTelemetryGroup]
  );

  const handleCardClick = useCallback(() => {
    actions.trackEventCardClicked();
  }, [actions]);

  const getRoleLabel = (status: EventUserStatus) => {
    switch (status) {
      case EventUserStatus.owner:
        return {
          label: t('eventsPage', 'status', 'owner')(),
          status: EventUserStatus.owner
        };
      case EventUserStatus.admin:
        return {
          label: t('eventsPage', 'status', 'collaborator')(),
          status: EventUserStatus.admin
        };
      case EventUserStatus.guest:
        return {
          label: t('eventsPage', 'status', 'guest')(),
          status: EventUserStatus.guest
        };
    }
    return undefined;
  };

  const eventCards: EventCardModel[] =
    eventUsers
      ?.map(({ status, event }) =>
        event
          ? {
              id: event.info.id,
              postgresEventId: event.id,
              firebaseId: event.firebaseId,
              eventType: event.info.eventType,
              status: status,
              role: getRoleLabel(status) || undefined,
              roles: isOwner(status) ? [t('eventsPage', 'status', 'owner')()] : isAdmin(status) ? [t('eventsPage', 'status', 'admin')()] : undefined,
              name: event.info.eventDisplayName,
              dateString: event.info.finalizedDate?.dateString,
              datems: event.info.finalizedDate?.milliseconds,
              location: event.info.location,
              imageUrl: event.photo?.url,
              imageColor: event.style.primaryColorV0918.hex,
              eventLink: `/${event.website}` || '/',
              actions: dropDownActionsForCard(
                event.website || '',
                isOwner(status),
                event.id,
                getRoleLabel(status),
                event.info.eventPassword || '',
                isRsvpOptionPresentInGuestDropdown(event.pages, event.productVerticals?.optOutOfGuestList),
                event.info.eventDisplayName || '',
                event.id,
                event.firebaseId,
                event.info.eventType
              ),
              attending: event.activeSession?.eventCTA?.rsvpAttendanceStatus === EventRSVPAttendanceStatus.attending
            }
          : null
      )
      .filter(notNullOrUndefined) || [];

  const upcomingEventCards = sortEventUsers(
    eventCards.filter(card => (card.datems || Infinity) > Date.now()),
    1
  );
  const pastEventCards = sortEventUsers(
    eventCards.filter(card => (card.datems || Infinity) < Date.now()),
    -1
  );

  const handleNavButtonClick = useCallback(
    (itemCopy: string) => {
      actions.trackMePageButtonClick(itemCopy);
    },
    [actions]
  );

  const createEventUrl = useMemo(() => {
    return withWindow(() => {
      const url = new URL(window.location.origin);
      return url.href + 'createwedding';
    }, '');
  }, []);

  const findEventUrl = useMemo(() => {
    return withWindow(() => {
      const url = new URL(window.location.origin);
      return url.href + 'find';
    }, '');
  }, []);

  const handleCloseDeleteDialogV2 = useCallback(() => {
    setEventIdToDeleteV2(undefined);
  }, []);

  const handleCloseLeaveDialog = useCallback(() => {
    setEventIdToLeave(undefined);
  }, []);

  useEffect(() => {
    eventsPage.enter(user?.eventUsers.length);
    setTelemetryGroup(null, null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ownsAnEvent = useMemo(() => {
    return user?.eventUsers.find(eu => eu.status === EventUserStatus['owner']);
  }, [user?.eventUsers]);

  return {
    email,
    upcomingEventCards,
    pastEventCards,
    createEventUrl,
    findEventUrl,
    handleCardClick,
    handleNavButtonClick,
    handleMePageClick: actions.trackMePageClick,
    ownsAnEvent,
    eventIdToDeleteV2,
    eventIdToLeave,
    eventNameToLeave,
    handleCloseDeleteDialogV2,
    handleCloseLeaveDialog,
    onDeleteEventSuccessDialogClose,
    onEventDeleteSuccessDialogOpen,
    isEventDeleteSuccessDialogOpen,
    cardLink,
    isShareDialogOpen,
    onShareDialogOpen,
    onShareDialogClose,
    guestSiteLink,
    eventHandle,
    eventPassword,
    isGuest
  };
};
