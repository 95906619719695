import React, { useMemo } from 'react';
import { ButtonV2, Flex } from '@withjoy/joykit';
import { useConfirmController, HandleSendEmailArgs } from './Confirm.controller';
import { ConfirmContainer, ConfirmWrapper, StyledActionWrapper, StyledTitle, StyledBodyTitle, StyledBody, styles } from './Confirm.styles';
import ConfirmationScreen from './components/ConfirmationScreen';
import SendTestEmailDialog from './components/SendTestEmailDailog';
import { EcardType, PostOfficeMessageType } from '@graphql/generated';
import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import ConfirmationThankYou from './components/ConfirmationThankYou';
import { Copy } from '@withjoy/joykit/icons';
import ShareableDialog from './components/ShareableDialog';
import useHandleCopyShareableLink from './components/ShareableDialog/useHandleCopyShareableLink';

export type SuccessScreen = 'generic' | 'thankyou';

interface ConfirmProps
  extends Readonly<{
    eventHandle: string;
    handleSendEmail: (args: HandleSendEmailArgs) => void;
    emailIsVerified: boolean;
    draftType: EcardType | PostOfficeMessageType;
    testEmail?: string;
    hideGuestSelector?: boolean;
    redirect?: string;
    successScreen?: SuccessScreen;
    recipientName?: string;
    handleCopyShareableLink?: () => Promise<void>;
    isCreatingShareableLink?: boolean;
    aboutShareableLinksClick?: (label: 'Cancel' | 'I Understand') => void;
    confirmSelectGuestsTrack?: (ecardType: EcardType) => void;
    confirmTestEmailTrack?: (ecardType: EcardType) => void;
    confirmAndSendTrack?: (ecardType: EcardType) => void;
  }> {}

export const Confirm: React.FC<ConfirmProps> = ({
  eventHandle,
  handleSendEmail,
  emailIsVerified,
  draftType,
  testEmail,
  hideGuestSelector,
  redirect,
  successScreen = 'generic',
  recipientName,
  handleCopyShareableLink,
  isCreatingShareableLink,
  aboutShareableLinksClick,
  confirmSelectGuestsTrack,
  confirmTestEmailTrack,
  confirmAndSendTrack
}) => {
  const {
    confirmAndSendActions,
    tConfirmAndSend,
    isConfirmationScreenShown,
    selectedGuestsCount,
    handleSendTestEmail,
    handleSendTestEmailDialogClose,
    isSendTestEmailDialogOpen,
    selectedPeople,
    shareableLinksEnabled,
    shareableDialog
  } = useConfirmController({
    eventHandle,
    handleSendEmail,
    emailIsVerified,
    draftType,
    hideGuestSelector,
    handleCopyShareableLink,
    isCreatingShareableLink,
    confirmSelectGuestsTrack,
    confirmTestEmailTrack,
    confirmAndSendTrack
  });

  const handleCopyButtonClick = useHandleCopyShareableLink({ copy: handleCopyShareableLink, openDialog: shareableDialog.onOpen });

  const confirmationScreen = useMemo(() => {
    if (successScreen === 'thankyou') {
      return <ConfirmationThankYou redirect={redirect} eventHandle={eventHandle} selectedPeople={selectedPeople} recipientName={recipientName} />;
    }

    return <ConfirmationScreen draftType={draftType} redirect={redirect} eventHandle={eventHandle} selectedGuestsCount={selectedGuestsCount} />;
  }, [draftType, eventHandle, redirect, selectedGuestsCount, selectedPeople, successScreen, recipientName]);

  return (
    <ConfirmContainer alignItems="center" justifyContent="center" __css={styles.confirmContainer}>
      {isConfirmationScreenShown ? (
        confirmationScreen
      ) : (
        <ConfirmWrapper flexDirection="column" __css={styles.confirmWrapper}>
          {!shareableLinksEnabled && (
            <>
              <StyledTitle typographyVariant="hed5">{tConfirmAndSend.header()}</StyledTitle>
              <StyledBody marginBottom={4} typographyVariant="body1">
                {tConfirmAndSend.body()}
              </StyledBody>
            </>
          )}
          {shareableLinksEnabled && (
            <Flex flexDirection="column" alignItems="center">
              <img src="https://withjoy.com/assets/public/social_icons_big.png" alt="Social" style={{ height: '24px', width: '184px' }} />
              <StyledTitle marginTop={5} typographyVariant="hed5" textAlign="center">
                {tConfirmAndSend.shareTitle()}
              </StyledTitle>
              <StyledBody marginBottom={6} marginTop={3} typographyVariant="body1" textAlign="center">
                {tConfirmAndSend.shareBody()}
              </StyledBody>
              <ButtonV2 onClick={handleCopyButtonClick} loading={isCreatingShareableLink} marginBottom={8} marginX="auto" maxWidth="212px" width="100%" variant="outline">
                <Copy marginRight={3} />
                {tConfirmAndSend.shareAction()}
              </ButtonV2>
              <StyledTitle typographyVariant="hed5" textAlign="center">
                {tConfirmAndSend.emailHeader()}
              </StyledTitle>
              <StyledBody marginBottom={6} marginTop={3} marginX="auto" typographyVariant="body1" textAlign="center" maxWidth="490px">
                {tConfirmAndSend.emailBody()}
              </StyledBody>
              <ShareableDialog
                isOpen={shareableDialog.isOpen}
                onClose={shareableDialog.onClose}
                onCopy={handleCopyShareableLink}
                isCopying={isCreatingShareableLink}
                onCancelClick={() => aboutShareableLinksClick?.('Cancel')}
                onConfirmClick={() => aboutShareableLinksClick?.('I Understand')}
              />
            </Flex>
          )}
          {confirmAndSendActions.filter(notNullOrUndefined).map(action => (
            <StyledActionWrapper key={action.label} alignItems="center" justifyContent="space-between" __css={styles.actionWrapper}>
              <Flex>
                {action.icon}
                <Flex flexDirection="column" marginLeft={6}>
                  <StyledBodyTitle marginBottom={0} typographyVariant="hed2">
                    {action.hed}
                  </StyledBodyTitle>
                  <StyledBody typographyVariant="body1">{action.dek}</StyledBody>
                </Flex>
              </Flex>
              {action.button}
            </StyledActionWrapper>
          ))}
        </ConfirmWrapper>
      )}
      <SendTestEmailDialog
        dialogId="send-test-email-dialog-ecards"
        isOpen={isSendTestEmailDialogOpen}
        onClose={handleSendTestEmailDialogClose}
        handleSendTestEmail={handleSendTestEmail}
        testEmail={testEmail}
      />
    </ConfirmContainer>
  );
};
