import React from 'react';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMobileView } from '../../useMobileView';
import { Flex, ButtonV2, TextV2 } from '@withjoy/joykit';
import { useHeaderLinks } from '../../../../useHeaderLinks';
import { useMobileViewContext } from '../../MobileView.provider';
import { ChevronRightSquare, ChevronLeftSquare } from '@withjoy/joykit/icons';
import { Wrapper, styles } from './MobileSubMainMenu.styles';

export const MobileSubMainMenu: React.FC = () => {
  const { t2 } = useTranslation('marcom');
  const footerNav = t2('footerNav');

  const { resourceRedirectLinks } = useMobileView();
  const { selectedMenuType, onClickSubMenu, onClickBack } = useMobileViewContext();
  const { itemsToRender } = useHeaderLinks(selectedMenuType);
  const parentLabel = resourceRedirectLinks?.find(link => link.type === selectedMenuType)?.heading;

  return (
    <Wrapper __css={styles.wrapper}>
      <ButtonV2
        typographyVariant="FeatureHead17"
        padding={0}
        height={pxToRem(26)}
        intent="neutral"
        variant="ghost"
        color="mono12"
        startIcon={<ChevronLeftSquare size={16} marginTop={2} />}
        justifyContent="flex-start"
        onClick={() => onClickBack(true)}
      >
        {footerNav.back}
      </ButtonV2>
      <TextV2 typographyVariant="FeatureHead17" marginBottom={3} marginTop={6}>
        {parentLabel}
      </TextV2>
      <Flex flexDirection="column">
        {itemsToRender.map((item, idx) => (
          <ButtonV2
            key={item.label + idx}
            as="a"
            href={item.href}
            target={item.target}
            typographyVariant="HedMobile32"
            height={pxToRem(64)}
            padding={0}
            intent="neutral"
            variant="ghost"
            justifyContent="flex-start"
            endIcon={item.blogs?.length && item.blogs.length > 0 ? <ChevronRightSquare marginTop={2} /> : null}
            onClick={e => {
              if (item.blogs?.length && item.blogs.length > 0) {
                e.preventDefault();
                onClickSubMenu(idx);
              } else {
                item.onClick(e);
              }
            }}
          >
            {item.mobileLabel ? item.mobileLabel : item.label}
          </ButtonV2>
        ))}
      </Flex>
    </Wrapper>
  );
};
