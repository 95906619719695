import React from 'react';
import { styled, PositionWithoutDirection, isVerticalPosition, isHorizontalPosition } from '../../common';
import { DrawerDimension } from './Drawer.types';
import { getDraweDimensionValue, getInitialAxisPlacement, getBorderCorners } from './Drawer.utils';
import { animated } from 'react-spring';
import { Props } from '../../common/props';
import { CSSObject } from 'styled-components';
import { Box } from '../Box';

const ContainerRoot = React.forwardRef<
  HTMLDivElement,
  Props & {
    border: 'lg' | 'none';
    position: PositionWithoutDirection;
    width: DrawerDimension;
    height: DrawerDimension;
    children: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
>(({ className, border, children, height, width, position, ...restProps }, ref) => {
  return (
    <Box as={animated.div} className={className} {...restProps} ref={ref}>
      {children}
    </Box>
  );
});

const RADII = {
  lg: 3,
  none: 0
} as const;

export const Container = styled(ContainerRoot)<{
  position: PositionWithoutDirection;
  width: DrawerDimension;
  height: DrawerDimension;
}>`
  position: fixed;
  outline: none;
  ${props => getInitialAxisPlacement(props.position)}
  height: ${props => (isVerticalPosition(props.position) ? getDraweDimensionValue(props.height) : '100%')};
  width: ${props => (isHorizontalPosition(props.position) ? getDraweDimensionValue(props.width) : '100%')};
  z-index: 100;
  background-color: white;
  ${props => {
    if (props.border !== 'none') {
      const borderCorners = getBorderCorners(props.position);
      const borderRules: CSSObject = {};
      borderCorners.forEach(corner => {
        borderRules[`border-${corner}-radius`] = props.theme.radii[RADII[props.border]];
      });
      return borderRules;
    }
    return null;
  }}
`;
