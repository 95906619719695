import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';
interface ForgotPasswordFields
  extends Readonly<{
    email: string;
  }> {}

export function useForgotPasswordController() {
  const { t2 } = useTranslation('account');
  const tFormWarn = t2('formWarnings');
  const formik = useFormik<ForgotPasswordFields>({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object<ForgotPasswordFields>({
      email: Yup.string().email(tFormWarn.invalidEmail).required(tFormWarn.required)
    }),
    onSubmit: values => {
      //
    }
  });

  return {
    formik
  };
}
