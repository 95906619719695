import React from 'react';
import { EcardAdditionalLinkType, ECARD_ADDITIONAL_NAV_LINKS } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import { EcardDesignInputFields, SelectOptionFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { Flex, Box, TextV2, LinkV2, SpacingStack } from '@withjoy/joykit';
import { AddToCalendar as AddToCalendarIcon, Gift, Rsvp, Schedule, Plane, Website } from '@withjoy/joykit/icons';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import { EcardFooterButton, EcardFooterContainer, EcardFooterLink, EcardFooterLogo, EcardFooterMessageContainer, EcardFooterOverlay, styles } from './EcardFooter.styles';
import { PrivacyModeType } from '@graphql/generated';
import { AddToCalendar } from '@shared/components/AddToCalendar';
import { withWindow } from '@shared/utils/withWindow';
import { prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { Linkify } from '@shared/components/Linkify';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';

interface Props
  extends Readonly<{
    ecardData: EcardDesignInputFields;
    privacyMode?: PrivacyModeType;
    eventPassword?: Maybe<string>;
    eventHandle?: string;
    isEcardPreview?: boolean;
    isGuestView?: boolean;
  }> {}

export const EcardFooter = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { ecardData, privacyMode, eventPassword, eventHandle, isEcardPreview = false, isGuestView = false } = props;
  const { additionalMessage, additionalLink1, additionalLink2, displayEventLink, yourUrl, guidId, eventId } = ecardData;

  const showLink1 = additionalLink1 && additionalLink1.value && additionalLink1.value !== EcardAdditionalLinkType.NONE;
  const showLink2 = additionalLink2 && additionalLink2.value && additionalLink2.value !== EcardAdditionalLinkType.NONE;
  const showFooterLink1 = showLink1 && additionalLink1.value !== EcardAdditionalLinkType.ADD_TO_CALENDAR;
  const showFooterLink2 = showLink2 && additionalLink2.value !== EcardAdditionalLinkType.ADD_TO_CALENDAR;

  const getFooterIcon = (type: EcardAdditionalLinkType | string) => {
    switch (type) {
      case EcardAdditionalLinkType.ADD_TO_CALENDAR:
        return <AddToCalendarIcon size="md" />;
      case EcardAdditionalLinkType.RSVP:
        return <Rsvp size="md" />;
      case EcardAdditionalLinkType.BROWSE_REGISTRY:
        return <Gift size="md" />;
      case EcardAdditionalLinkType.VIEW_SCHEDULE:
        return <Schedule size="md" />;
      case EcardAdditionalLinkType.VIEW_TRAVEL_DETAILS:
        return <Plane size="md" />;
      case EcardAdditionalLinkType.VIEW_WEBSITE:
        return <Website size="md" />;
      default:
        return null;
    }
  };

  const { eCardLinkClick } = useEcardsTelemetry();

  const handleNavigateAdditionalPage = (type: EcardAdditionalLinkType | string, isGuestView: boolean) => {
    if (ECARD_ADDITIONAL_NAV_LINKS[type] && eventHandle) {
      isGuestView && eCardLinkClick(ECARD_ADDITIONAL_NAV_LINKS[type], eventId);
      withWindow(global => global.open(`${global.location.origin}/${eventHandle}/${ECARD_ADDITIONAL_NAV_LINKS[type]}${guidId ? `?guidId=${guidId}` : ''}`, '_blank'));
    }
  };

  const handleLinkClick = (url: string, type: string, tracking: boolean = false, isGuestView: boolean = false) => {
    tracking && isGuestView && eCardLinkClick(type, eventId);
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`;
    }
    withWindow(global => global.open(url, '_blank'));
  };

  const getLinkButton = (additionalLink: SelectOptionFields) => {
    return additionalLink.value == EcardAdditionalLinkType.ADD_TO_CALENDAR ? (
      <AddToCalendar
        start={ecardData.date}
        // make event a 24 hour period as current behavior
        // see https://github.com/joylifeinc/legacy-joy-monorepo/blob/57da0ad293570f1b6c5f2dc59875b0a1ef1585d9/modules/app-browser/widgets/ecard/ecard.js#L188
        end={new Date(new Date(ecardData.date).setDate(new Date(ecardData.date).getDate() + 1)).toDateString()}
        title={ecardData.title}
        location={ecardData.location ?? ''}
        description={ecardData.message ?? ''}
      >
        <EcardFooterButton
          overrides={styles.button}
          startIcon={getFooterIcon(additionalLink.value)}
          variant="outline"
          shape="square"
          onClick={() => isGuestView && eCardLinkClick('calendar', eventId)}
        >
          {additionalLink.label}
        </EcardFooterButton>
      </AddToCalendar>
    ) : (
      <EcardFooterButton
        overrides={styles.button}
        startIcon={getFooterIcon(additionalLink.value)}
        variant="outline"
        shape="square"
        onClick={() => handleNavigateAdditionalPage(additionalLink.value, isGuestView)}
      >
        {additionalLink.label}
      </EcardFooterButton>
    );
  };

  return (
    <>
      {(showLink1 || showLink2 || additionalMessage) && (
        <EcardFooterContainer __css={styles.container} ref={ref}>
          <Flex flexDirection={['column', 'row']} alignItems="center">
            {showLink1 && getLinkButton(additionalLink1)}
            {showLink2 && getLinkButton(additionalLink2)}
          </Flex>
          {additionalMessage && (
            <EcardFooterMessageContainer marginTop={showLink1 || showLink2 ? 9 : 0} {...styles.messageContainer}>
              <TextV2 as="div" typographyVariant="body4" whiteSpace="pre-wrap">
                <Linkify>{additionalMessage}</Linkify>
              </TextV2>
            </EcardFooterMessageContainer>
          )}
          {displayEventLink && (showFooterLink1 || showFooterLink2) && (
            <Flex marginTop={9} flexDirection="column" justifyContent="center" alignItems="center">
              <LinkV2
                typographyVariant="button3"
                onClick={() => handleLinkClick(`${yourUrl}${guidId ? `?guidId=${guidId}` : ''}`, 'wedding website', true, isGuestView)}
                {...styles.footerWebsiteUrl}
              >
                {prettyUrl(yourUrl)}
              </LinkV2>
              {privacyMode !== 'public' && (
                <Flex marginTop={1} alignItems="center" typographyVariant="body1">
                  <TextV2>Password: </TextV2>
                  <Box as="span" textTransform="uppercase" fontWeight={700} fontFamily={EmailFontFamilies.ibmPlexMono} marginLeft={1}>
                    {eventPassword}
                  </Box>
                </Flex>
              )}
            </Flex>
          )}
          {isEcardPreview && <EcardFooterOverlay __css={styles.overlay} />}
        </EcardFooterContainer>
      )}
      {!isEcardPreview && (
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center" marginTop={6}>
          <SpacingStack stack="horizontal">
            <EcardFooterLink
              as="a"
              $disabled={!isGuestView}
              role="button"
              aria-disabled={!isGuestView}
              onClick={() => handleLinkClick('https://help.withjoy.com/guest-help/?utm_source=e-card-invite&utm_medium=viral&utm_campaign=guest-help', 'guest-help')}
              __css={styles.link}
            >
              <TextV2 typographyVariant="label2">Guest Help</TextV2>
            </EcardFooterLink>
            <EcardFooterLink
              as="a"
              $disabled={!isGuestView}
              role="button"
              aria-disabled={!isGuestView}
              marginLeft={6}
              onClick={() => handleLinkClick('https://withjoy.com/?utm_source=viral&utm_medium=guestSite&utm_campaign=ecard', 'about')}
              __css={styles.link}
            >
              <TextV2 typographyVariant="label2">About</TextV2>
            </EcardFooterLink>
          </SpacingStack>
          <EcardFooterLink
            as="a"
            $disabled={!isGuestView}
            role="button"
            aria-disabled={!isGuestView}
            onClick={() => handleLinkClick('https://withjoy.com/?utm_source=viral&utm_medium=guestSite&utm_campaign=ecard', 'marcom')}
            __css={styles.link}
          >
            <TextV2 as="span" typographyVariant="label2">
              Made with
            </TextV2>
            <EcardFooterLogo />
          </EcardFooterLink>
        </Flex>
      )}
    </>
  );
});
