import { Box } from '@withjoy/joykit';
import React, { useLayoutEffect, useRef } from 'react';
import { textLayerDataToCSS } from '../../components/TextLayer';
import { QRCodeLayerData } from '../../components/Layer.types';

interface QRCodeMeasurerProps {
  layer: QRCodeLayerData;
  onHeightChange?: (height: number) => void;
}

export const QRCodeMeasurer = (props: QRCodeMeasurerProps) => {
  const { layer, onHeightChange } = props;

  const boxRef = useRef<HTMLDivElement>(null);
  const onHeightChangeRef = useRef<QRCodeMeasurerProps['onHeightChange']>();
  onHeightChangeRef.current = onHeightChange;
  useLayoutEffect(() => {
    const element = boxRef.current;
    if (!element) return;
    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      onHeightChangeRef.current?.(element.clientHeight);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, []);

  const { link, password } = layer.qrcodeData;

  return (
    <div
      ref={boxRef}
      style={{
        pointerEvents: 'none',
        userSelect: 'none', // Otherwise resize will cause blue range highlights.
        visibility: 'hidden',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'unset', // Will not affect measurement, so leaving unset.
        gap: '5px',

        ...textLayerDataToCSS(layer.qrcodeData),
        position: 'absolute',
        width: layer.layout.width
      }}
    >
      <Box style={{ width: 72, height: 72 }}></Box>
      <Box>
        {link}
        {password ? `\nPassword: ${password}` : ''}
      </Box>
    </div>
  );
};
