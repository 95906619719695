import React, { useEffect, useMemo, useRef } from 'react';
import { DraftFunction } from 'use-immer';
import { LayerData } from './Layer.types';
import { CurrentSelectionStrategy } from '../steps/CardDesign/SelectionStrategy';
import { useSurface } from '../steps/CardDesign/useSurface';
import { ScopedUpdateHandler } from '../useCardCustomization';
import { Page, PageEditor, PageProps } from './Page';
import { Box } from '@withjoy/joykit';
import { XY, startDrag } from '../steps/CardDesign/startDrag';
import { SAFE_MARGIN } from '../steps/CardDesign/Adornments';
import { useCardCustomizerTelemetry } from '../CardCustomizer.telemetry';
import { clamp } from '@apps/card/Card.utils';
import { PPI } from '../steps/CardDesign/config';

type UpdatePage = ScopedUpdateHandler<{
  fill: string;
  layers: LayerData[];
}>;

/**
 * Increments that dragging should snap to
 */
const DRAG_GRID: [number, number] = [2, 2];

function snapToGrid(grid: [number, number], pendingX: number, pendingY: number) {
  const x = Math.round(pendingX / grid[0]) * grid[0];
  const y = Math.round(pendingY / grid[1]) * grid[1];
  return [x, y];
}

export const EditablePage = (props: PageProps & { update: UpdatePage }) => {
  const { update, ...pageProps } = props;

  const { customizationButtonInteracted } = useCardCustomizerTelemetry();

  const [surface, updateSurface] = useSurface();

  // Every time the active layer changes, clear the editing layer.
  useEffect(() => {
    updateSurface(draft => {
      draft.editingLayerIndex = undefined;
    });
  }, [surface.activeLayerIndex, updateSurface]);

  // Storing surface in a ref so that it's not required as a dependency for the useMemo below.
  // `surface` changes but we need `editor` to be stable for downstream consumers.
  const surfaceRef = useRef(surface);
  surfaceRef.current = surface;

  const editor: PageEditor = useMemo(
    () => ({
      isActive: (layerIndex: number) => {
        return surfaceRef.current.activeLayerIndex === layerIndex;
      },
      isEditing: (layerIndex: number) => {
        return CurrentSelectionStrategy.isEditing(surfaceRef.current.editingLayerIndex, layerIndex);
      },
      updateLayer: (layerIndex: number, scopedUpdater: DraftFunction<LayerData>) => {
        update(draft => {
          const layer = draft.layers[layerIndex];
          if (!layer) return;
          scopedUpdater(layer);
        });
      },
      Selector: ({ children, layer, layerIndex }) => {
        const { cardLayerFocused } = useCardCustomizerTelemetry();

        const onDrag = (delta: XY, baseline: { x: number; y: number; width: number; height: number }) => {
          editor.updateLayer(layerIndex, draft => {
            const dimensions = pageProps.page.dimensions ?? pageProps;
            const [x, y] = snapToGrid(DRAG_GRID, baseline.x + delta.x / surfaceRef.current.scale, baseline.y + delta.y / surfaceRef.current.scale);
            draft.layout.x = clamp(SAFE_MARGIN, dimensions.width * PPI - SAFE_MARGIN - baseline.width, x);
            draft.layout.y = clamp(SAFE_MARGIN, dimensions.height * PPI - SAFE_MARGIN - baseline.height, y);
          });
        };

        return (
          <Box
            display="contents"
            pointerEvents={layer.editable ? 'all' : 'none'}
            onPointerOver={e => {
              if (e.button >= 0) return;
              updateSurface(draftSurface => {
                draftSurface.hoverLayerIndex = layerIndex;
              });
            }}
            onPointerOut={e => {
              if (e.button >= 0) return;
              updateSurface(draftSurface => {
                draftSurface.hoverLayerIndex = undefined;
              });
            }}
            onPointerDown={e => {
              e.stopPropagation(); // Prevent deselection

              // Prevent browser native drag/drop...
              // Except when text layer is in edit mode (to let the caret placement through).
              // Except when image layer is in active mode (to allow panning).
              const isTextLayerEditing = layer.type == 'text' && surfaceRef.current.editingLayerIndex === layerIndex;
              const isImageLayerActive = layer.type === 'image' && surfaceRef.current.activeLayerIndex === layerIndex;
              if (!(isTextLayerEditing || isImageLayerActive)) {
                e.preventDefault();
              }

              if (surfaceRef.current.activeLayerIndex !== layerIndex) {
                updateSurface(draftSurface => {
                  draftSurface.activeLayerIndex = layerIndex;
                });
                cardLayerFocused(layer.type);
                return;
              }

              // Disallow move/drag while editing text
              if (isTextLayerEditing) return;

              // Disallow move/drag for images.
              if (layer.type === 'image') return;

              startDrag(onDrag, JSON.parse(JSON.stringify(layer.layout)), didDrag => {
                if (didDrag) return;
                if (layer.type !== 'text') return;
                updateSurface(draftSurface => {
                  // If this layer received the onPointerDown, then activeLayerIndex === layerIndex at this point.
                  draftSurface.editingLayerIndex = surfaceRef.current.activeLayerIndex;
                  customizationButtonInteracted({
                    property: 'textLayer_editText'
                  });
                });
              })(e);
            }}
          >
            {children}
          </Box>
        );
      },
      updateSurface
    }),
    // Not including `update, surface, updateSurface` as it is unstable and invalidates this memo on every hover.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <Page {...pageProps} editor={editor} />;
};
