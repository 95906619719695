import React, { useCallback } from 'react';
import { ButtonV1, useOverlayContext } from '@withjoy/joykit';
import { DialogV1, OverlayComponentType } from '@withjoy/joykit';
import { LabelIdsToPeopleIdsMap, LabelViewItem, PeoplePicker, PersonViewItem } from './PeoplePicker';
import { StyledDialog, StyledDialogBody, StyledDialogFooter, StyledLink } from './PeoplePicker.styles';
import { usePeoplePickerController } from './PeoplePicker.controller';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useSelectGuestsTelemetry, TelemetryProvider } from '@shared/components/PeoplePicker/PeoplePicker.telemetry';
import { RemoveScroll } from 'react-remove-scroll';

interface Props
  extends Readonly<{
    title: React.ReactNode;
    eventHandle: string;
    allLabels: ReadonlyArray<LabelViewItem>;
    allPeople: ReadonlyArray<PersonViewItem>;
    labelIdsToPeopleIdsMap: LabelIdsToPeopleIdsMap;
    submit: (params: { people: ReadonlyArray<PersonViewItem>; labels: ReadonlyArray<LabelViewItem>; mergedSelectionPeople: ReadonlyArray<PersonViewItem> }) => void;
    isLoading: boolean;
  }> {}

export const PeoplePickerDialog: React.FC<OverlayComponentType<Props>> = ({
  overlayKey,
  hide,
  submit,
  title,
  eventHandle,
  allLabels,
  allPeople,
  labelIdsToPeopleIdsMap,
  isLoading
}) => {
  const {
    viewPeople,
    viewLabels,
    changeLabelSelection,
    changePersonSelection,
    selectAllPeople,
    isSelectedPeopleShown,
    toggleSelectedPeopleShown,
    getOutputData,
    selectedPeopleCount
  } = usePeoplePickerController(allLabels, allPeople, labelIdsToPeopleIdsMap);
  const [isMobile] = useResponsive<boolean>({ values: { mobile: true, tablet: false } });
  const closeDialog = useCallback(() => hide(overlayKey), [hide, overlayKey]);
  const telemetry = useSelectGuestsTelemetry();
  const guestsListUrl = `/${eventHandle}/edit/guests`;
  const overlayContext = useOverlayContext();
  const disableOutsideScroll = isMobile && !!overlayContext.isOverlayOpen(overlayKey)?.isOpen;

  return (
    <TelemetryProvider>
      <RemoveScroll enabled={disableOutsideScroll}>
        <StyledDialog
          overlayKey={overlayKey}
          canCloseOnOutsideClick={false}
          useBackdrop={false}
          {...(isMobile
            ? {
                animationFrom: { opacity: 0, scale: 1 },
                animationTo: { top: 0, opacity: 1, scale: 1 },
                animationLeave: { opacity: 0, scale: 1 }
              }
            : {})}
        >
          <DialogV1.Header title={title} isCloseButtonShown={isMobile} onClose={closeDialog} />
          <StyledDialogBody>
            <PeoplePicker
              eventHandle={eventHandle}
              isDisabled={isLoading}
              labels={viewLabels}
              people={viewPeople}
              onLabelSelectionChange={changeLabelSelection}
              onPersonSelectionChange={changePersonSelection}
              onSelectAllPeopleClick={selectAllPeople}
              isSelectedPeopleDrawerOpen={isSelectedPeopleShown}
              onSelectedPeopleDrawerClose={toggleSelectedPeopleShown}
              onClose={closeDialog}
              selectedPeopleCount={selectedPeopleCount}
            />
          </StyledDialogBody>
          <StyledDialogFooter>
            {!isMobile && (
              <StyledLink
                marginRight={'auto'}
                underline="none"
                onClick={() => {
                  telemetry.goToGuestList();
                }}
                href={guestsListUrl}
              >
                Edit Guest List
              </StyledLink>
            )}
            <ButtonV1 disabled={isLoading} className={'cancel-button'} intent={'secondary'} onClick={closeDialog}>
              Cancel
            </ButtonV1>
            <ButtonV1
              className={'save-button'}
              loading={isLoading}
              onClick={() => {
                telemetry.done();
                submit(getOutputData());
              }}
            >
              Done
            </ButtonV1>
          </StyledDialogFooter>
        </StyledDialog>
      </RemoveScroll>
    </TelemetryProvider>
  );
};
