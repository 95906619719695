import { ProviderSettings } from '../routes/SignIn/SignInPage.controller';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from '@react-router';
import { withWindow } from '@shared/utils/withWindow';

export const useShowThirdPartyProviders = (providers: ProviderSettings) => {
  return useMemo(() => {
    const lenProviders = Object.keys(providers).length;
    return (providers?.emailpassword && lenProviders > 1) || lenProviders > 0;
  }, [providers]);
};

export const updateSearchParam = (search: string, param: string, value: string): string => {
  // eslint-disable-next-line compat/compat
  const params = new URLSearchParams(location.search);
  params.set(param, value);
  return params.toString();
};

export const useCurrentUrl = () => {
  const location = useLocation();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    withWindow(window => setUrl(window.location.href));
  }, [location]);

  return {
    url
  };
};
