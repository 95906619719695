import { Dispatch, createContext, useContext, useCallback, useEffect } from 'react';
import { SaveTheDateAction } from '../SaveTheDate.reducer';
import {
  useSaveTheDateSaveEcardMutation,
  useSaveTheDateSaveEcardEmailMutation,
  useSaveTheDateSendEcardGuestEmailMutation,
  useSaveTheDateSaveEcardPhotoMutation,
  EcardType
} from '@graphql/generated';
import { ECardInputNoEventId, HandleSendEmailArgs, SaveTheDateHandlers } from '../../SaveTheDate.types';
import { getEmailDraftValues, getEmailPayload } from '@apps/saveTheDate/routes/Email/Email.utils';
import * as ACTIONS from '../SaveTheDate.actions';

import type { BaseMutationOptions } from '@apollo/client';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { useSaveTheDateState } from './SaveTheDate.state';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE, COUPLE_NAME_FORMATS, DISPLAY_DATE_FORMATS, LOCATION_FORMATS } from '@apps/saveTheDate/constants';
import { omit } from 'lodash-es';
import { getFooterTextWithCouplesNames } from '@shared/components/EmailsAndEcards/Emails.utils';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useCopyToClipboard } from '@shared/utils/hooks/useCopyToClipboard';
import { useToast } from '@withjoy/joykit';
import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core';

type ResolveHandlers<T> = Required<Pick<BaseMutationOptions<T>, 'onError' | 'onCompleted'>>;
type CreateResolveHandlersOptions<T> = {
  telemetry?: {};
  skipTrackingCount?: boolean;
  onCompleted?: BaseMutationOptions<T>['onCompleted'];
  onError?: BaseMutationOptions<T>['onError'];
};

const networkErrorRegex = RegExp(/(network error|failed to fetch|network|fetch)/gi);

interface UseMutationsArgs {
  eventHandle: string;
  eventId: string;
  ownerFirstName: string;
  fianceeFirstName?: string;
  eventPassword: string;
  date: string;
  location: string;
  dispatch: Dispatch<SaveTheDateAction>;
}

export interface EcardLookupPayload {
  ecardId: string;
  personId: string;
  isAnonymous?: boolean;
  eventPassword?: string | null;
  ecardType: EcardType;
  location: string;
  date: string;
}

function useMutations({ eventId, dispatch, ownerFirstName, fianceeFirstName, eventHandle, eventPassword, date, location }: UseMutationsArgs) {
  const state = useSaveTheDateState();
  const telemetry = useSaveTheDateTelemetry();
  const { setParentViewState } = useBottomSheetState();

  function createResolveHandlers<T extends object>(action: string, options: CreateResolveHandlersOptions<T> = { skipTrackingCount: false }): ResolveHandlers<T> {
    return {
      onError: error => {
        if (options.onError) {
          options.onError(error);
        } else {
          const extra: Record<string, unknown> = {};
          if (networkErrorRegex.test(error.message)) {
            extra.navigatorOnline = navigator.onLine;
            extra.userAgent = navigator.userAgent;
          }

          telemetry.trackError(action, error, extra);
        }
      },
      onCompleted: data => {
        options.onCompleted?.(data);
      }
    };
  }

  const [saveEcardMutation] = useSaveTheDateSaveEcardMutation(createResolveHandlers('SaveEcard'));
  const [saveEcardPhotoMutation] = useSaveTheDateSaveEcardPhotoMutation(createResolveHandlers('SaveEcardPhoto'));
  const [saveEcardEmailMutation] = useSaveTheDateSaveEcardEmailMutation(createResolveHandlers('EcardEmail'));
  const [sendEcardGuestEmail] = useSaveTheDateSendEcardGuestEmailMutation(createResolveHandlers('SendEcardGuestEmail'));

  // photo.id + photo.url + photo.assetId -> image was not edited
  // photo.id + photo.url -> photo from pages selected -> add photoId
  // photo.assetId + photo.url -> custom photo uploaded, has to be attached to ecard
  const saveEcard = async (ecardInput: ECardInputNoEventId, photo?: { id: string; url: string; assetId: string; width: number; height: number }) => {
    try {
      const photoIdData = photo?.id ? { photoId: photo.id } : {};
      const { coupleNameFormat, locationStringFormat, displayDateFormat, ...restEcardInput } = ecardInput;
      const ecardPayload = {
        coupleNameFormat: coupleNameFormat || COUPLE_NAME_FORMATS[0],
        locationStringFormat: locationStringFormat || LOCATION_FORMATS[1],
        displayDateFormat: displayDateFormat || DISPLAY_DATE_FORMATS[1],
        ...restEcardInput,
        eventId,
        ...photoIdData
      };
      const eCardDraft = await saveEcardMutation({ variables: { payload: ecardPayload } });
      telemetry.eCardSave(ecardPayload, !!photo?.assetId);
      if (eCardDraft.errors) {
        throw new Error(eCardDraft.errors.join('\n'));
      }
      if (eCardDraft && eCardDraft?.data?.saveEcard?.id) {
        const newECardId = eCardDraft?.data?.saveEcard?.id;

        if (!photo?.id && photo?.assetId) {
          await saveEcardPhotoMutation({ variables: { payload: { ecardId: newECardId, assetId: photo.assetId, containerId: eventId } } });
        }

        dispatch({ type: ACTIONS.SAVE_ECARD_DRAFT, payload: { eCardDraft: { id: newECardId, ...ecardPayload, photo } } });
        return newECardId;
      } else {
        return undefined;
      }
    } catch (e) {
      throw e;
    }
  };

  const saveEmail: SaveTheDateHandlers['saveEmail'] = useCallback(
    emailInput => {
      saveEcardEmailMutation({ variables: { payload: { ...emailInput } } });
      telemetry.emailSave(emailInput);
      dispatch({ type: ACTIONS.SAVE_EMAIL_DRAFT, payload: { emailDraft: { ...getEmailDraftValues(emailInput) } } });
    },
    [dispatch, saveEcardEmailMutation, telemetry]
  );

  const sendEmail = useCallback(
    async (args: HandleSendEmailArgs) => {
      telemetry.sendECardClick({ eCardData: state.eCardDraft, emailData: state.emailDraft }, (args.selectedPeopleIds?.length || 0).toString(), state.adminReferrer);
      let ecardId: string | undefined = state.eCardDraft?.id;

      if (!ecardId) {
        // no ecardId - couple never edited their ecard and never saved it but they are sending ecard email - save ecard data
        try {
          const payload = { ...omit(state.eCardDraft, ['id']), title: state.eCardDraft?.title || '', eventId, type: SAVE_THE_DATE_PHOTO_ECARD_TYPE, recipientIds: [] };
          telemetry.eCardSave(payload, !!payload.photo?.assetId);
          const saveEcardResponse = await saveEcardMutation({
            variables: { payload }
          });
          ecardId = saveEcardResponse.data?.saveEcard?.id;

          if (ecardId) {
            dispatch({
              type: ACTIONS.SAVE_ECARD_DRAFT,
              payload: {
                eCardDraft: {
                  id: ecardId,
                  title: state.eCardDraft?.title || '',
                  subTitle: state.eCardDraft?.subTitle,
                  message: state.eCardDraft?.message,
                  linkOptionPrimary: state.eCardDraft?.linkOptionPrimary,
                  linkOptionSecondary: state.eCardDraft?.linkOptionSecondary,
                  locationString: state.eCardDraft?.locationString,
                  displayDateFormat: state.eCardDraft?.displayDateFormat,
                  displayDateString: state.eCardDraft?.displayDateString,
                  displayEventLink: state.eCardDraft?.displayEventLink,
                  photoEcardTheme: state.eCardDraft?.photoEcardTheme,
                  type: SAVE_THE_DATE_PHOTO_ECARD_TYPE
                }
              }
            });
          }
        } catch (error) {
          telemetry.trackError('SaveEcardMutation', error);
        }
      }
      const { selectedPeopleIds, testEmail, testName, onCompleted, onError } = args;

      if (state?.eCardDataQuery?.getEcardEmailDraftsByEventId?.length === 0) {
        try {
          saveEmail(getEmailPayload(state.emailDraft, ecardId as string, state?.eCardDataQuery?.eventById?.info.date));
        } catch (error) {
          telemetry.trackError('SaveEcardMutation', error);
        }
      }

      const emailDraft = state.emailDraft;

      const emailPayload = {
        ecardId: ecardId as string,
        testName,
        testEmail,
        recipientIds: !!testEmail || !selectedPeopleIds?.length ? [] : selectedPeopleIds,
        replyToEmail: emailDraft?.replyToEmail || '',
        includeGuestNames: !!emailDraft?.includeGuestNames,
        includeAddToCalendar: false,
        includeDate: false,
        salutation: emailDraft?.salutation || '',
        message: emailDraft?.message || '',
        subject: emailDraft?.subject || '',
        buttonText: emailDraft?.buttonText,
        imageUrl: emailDraft?.imageUrl,
        title: emailDraft?.headline || '',
        subTitle: emailDraft?.subheadline,
        footerText: getFooterTextWithCouplesNames(ownerFirstName, fianceeFirstName, emailDraft?.replyToEmail)
      };

      if (testName && testEmail) {
        telemetry.sendTestEmail(testName, testEmail);
      } else {
        telemetry.sendEmail(emailPayload);
      }

      await sendEcardGuestEmail({
        variables: {
          payload: emailPayload
        }
      })
        .then(() => {
          !!onCompleted && onCompleted();
        })
        .catch(error => {
          telemetry.trackError('SendEcardGuestEmailMutation', error);
          !!onError && onError();
        });
    },
    [
      dispatch,
      eventId,
      fianceeFirstName,
      ownerFirstName,
      saveEcardMutation,
      saveEmail,
      sendEcardGuestEmail,
      state.adminReferrer,
      state?.eCardDataQuery?.eventById?.info.date,
      state?.eCardDataQuery?.getEcardEmailDraftsByEventId?.length,
      state.eCardDraft,
      state.emailDraft,
      telemetry
    ]
  );

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, handleSendEmail: sendEmail, eventHandle }));
  }, [sendEmail, setParentViewState, eventHandle]);

  const copyToClipboard = useCopyToClipboard();
  const { toast } = useToast();
  const { t } = useTranslation('emailsAndEcards');

  const copyShareableLink = useCallback(async () => {
    try {
      const payload = { ...omit(state.eCardDraft, ['id']), title: state.eCardDraft?.title || '', eventId, type: SAVE_THE_DATE_PHOTO_ECARD_TYPE, recipientIds: [] };
      telemetry.eCardSave(payload, !!payload.photo?.assetId);
      const ecardId =
        state.eCardDraft?.id ||
        (
          await saveEcardMutation({
            variables: { payload }
          })
        ).data?.saveEcard?.id;
      if (ecardId) {
        const lookupPayload: EcardLookupPayload = {
          ecardId,
          personId: 'anonymous',
          isAnonymous: true,
          eventPassword,
          ecardType: EcardType.savethedatephoto,
          location: encodeURI(location),
          date
        };
        const lookupId = Buffer.from(JSON.stringify(lookupPayload)).toString('base64');
        withWindow(window => {
          const link = `${window.location.origin}/ecard?lookup=${lookupId}`;
          copyToClipboard(link);
          telemetry.copyShareableLinkClicked(link);
        });
        toast(t('ecardDefaults', 'copiedLinkTooltip')());
      }
    } catch (error) {
      telemetry.trackError('Shareable link - creation', error);
    }
  }, [copyToClipboard, date, eventId, eventPassword, location, saveEcardMutation, state.eCardDraft, t, telemetry, toast]);

  return {
    saveEcard,
    saveEmail,
    sendEmail,
    copyShareableLink
  };
}

export const useCreateDispatchHandlers = (
  eventId: string,
  dispatch: Dispatch<SaveTheDateAction>,
  ownerFirstName: string,
  fianceeFirstName: string = '',
  eventHandle: string,
  eventPassword: string,
  location: string,
  date: string
): SaveTheDateHandlers => {
  const { saveEcard, saveEmail, sendEmail, copyShareableLink } = useMutations({ eventId, dispatch, ownerFirstName, fianceeFirstName, eventHandle, eventPassword, location, date });

  const toggleIsNavBarOpen = useCallback(() => {
    dispatch({ type: ACTIONS.TOGGLE_IS_NAV_BAR_OPEN });
  }, [dispatch]);

  const toggleIsAdjustingPosition = useCallback(() => {
    dispatch({ type: ACTIONS.TOGGLE_IS_ADJUSTING_POSITION });
  }, [dispatch]);

  const toggleEditPanel = useCallback(() => {
    dispatch({ type: ACTIONS.TOGGLE_EDIT_PANEL });
  }, [dispatch]);

  return {
    toggleIsAdjustingPosition,
    toggleIsNavBarOpen,
    toggleEditPanel,
    sendEmail,
    saveEcard,
    saveEmail,
    copyShareableLink
  };
};

export const SaveTheDateDispatchContext = createContext<SaveTheDateHandlers | undefined>(undefined);

export const useSaveTheDateDispatch = () => {
  const context = useContext(SaveTheDateDispatchContext);
  if (context === undefined) {
    throw new Error('useSaveTheDateDispatchContext must be used within a SaveTheDateDispatchContext Provider');
  }
  return context;
};
