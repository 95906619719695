import { Box, styled } from '@withjoy/joykit';

export const StyledEditDesignButton = styled(Box)`
  position: absolute;
  top: 30vh;
  left: 97%;
  cursor: pointer;
`;

export const StyledEditContentButton = styled(Box)`
  position: absolute;
  top: calc(30vh + 80px);
  left: 97%;
  cursor: pointer;
`;
