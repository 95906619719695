import React from 'react';
import { RoutesProvider } from '@shared/core';
import { useEcardGuestController } from './EcardGuest.controller';
import EcardPreview from '@shared/components/EmailsAndEcards/components/Design/components/EcardPreview';
import { Box, NoSsr } from '@withjoy/joykit';
import { Helmet } from 'react-helmet-async';
import { TelemetryProvider } from './EcardGuest.telemetry';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import Error from '@shared/components/EmailsAndEcards/components/Error';

export const EcardGuest: React.FC = () => {
  const { formattedEcardData, ecardEventDesign, guestNames, website, privacyMode, eventPassword, loading, onLogoutEventHandle, title } = useEcardGuestController();
  return (
    <RoutesProvider isGuestRoute={true}>
      <Helmet title={title}>
        <script src="https://withjoy.com/assets/public/styles/applicator.js"></script>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&display=swap" rel="stylesheet" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="Joy" />
        <meta property="og:url" content="withjoy.com" />
        <meta property="og:image" content="https://withjoy.com/media/envelopes/For%20You.png" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="noindex"></meta>
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/inter-ui/Inter-UI-Regular.woff2" />
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/inter-ui/Inter-UI-SemiBold.woff2" />
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/akkurat/AkkuratLLWeb-Bold.woff2" />
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/AustinNewsHeadline/AustinNewsHeadline-Light-Web.woff2" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap" rel="stylesheet" />
        <link href="https://withjoy.com/assets/public/joykit.normalize.css" rel="stylesheet" />
        <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"></script>
      </Helmet>
      <NoSsr>
        {loading ? (
          <JoyLogoLoader loaderKey="guest-ecard" />
        ) : !!formattedEcardData && !!ecardEventDesign ? (
          <TelemetryProvider context={{ eventId: formattedEcardData.eventId }}>
            <Box paddingX={4} backgroundColor="mono1">
              <EcardPreview
                ecardData={formattedEcardData}
                eventDesign={ecardEventDesign}
                names={guestNames}
                eventHandle={website}
                privacyMode={privacyMode}
                eventPassword={eventPassword}
                isGuestView={true}
                onLogoutEventHandle={onLogoutEventHandle}
              />
            </Box>
          </TelemetryProvider>
        ) : (
          <Error />
        )}
      </NoSsr>
    </RoutesProvider>
  );
};
