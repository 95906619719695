export const LOCAL_STORAGE_BANNER_DISMISS = 'bannerDismissState';
export const LOCAL_STORAGE_HAS_PRESENTED_TRAVEL_DIALOG = 'travelDialogPresentedState';
export const LOCAL_STORAGE_SHOULD_TRACK_TRAVEL_EDIT = 'shouldTrackTravelEditState';
export const LOCAL_STORAGE_SHOULD_PRESENT_TRAVEL_RETARGET_DIALOG = 'shouldPresentTravelRetargetDialog';
export const LOCAL_STORAGE_HAS_PRESENTED_RETARGET_DIALOG = 'travelRetargetDialogPresentedState';
export const LOCAL_STORAGE_DIALOG_PRESENTED_TIMESTAMP = 'dialogPresentedTimestamp';

export const LOCAL_STORAGE_WON_BANNER_DISMISS = 'wonBannerDismissState';
export const LOCAL_STORAGE_HAS_PRESENTED_WON_BOOKING_BANNER = 'wonBookingBannerPresentedState';
export const LOCAL_STORAGE_HAS_PRESENTED_WON_BOOKING_DIALOG = 'wonBookingDialogPresentedState';

// Print Local Storage Keys
export const LOCAL_STORAGE_HAS_PRESENTED_PRINT_EARLY_ACCESS_DIALOG = 'printEarlyAccessDialogPresentedState';
export const LOCAL_STORAGE_PRINT_EARLY_ACCESS_DIALOG_TIMESTAMP = 'printEarlyAccessDialogPresentedTimestamp';
