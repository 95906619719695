import { useAuth } from '@shared/components/AuthProvider';
import { useFormik } from 'formik';
import { useState } from 'react';
import { isAuthProviderError } from '@apps/createWedding/utils';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { createAndAttachPixelImage } from '@apps/createWedding/components/PixelImage';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import * as Yup from 'yup';
import { useLocation, useTranslation } from '@shared/core';
import { useViewContext } from '@apps/createWedding/components/View/ViewProvider';

type CreateAccountFields = {
  email: string;
  password: string;
};

export const useCreateAccountController = () => {
  const authProvider = useAuth();
  const [submissionError, setSubmissionError] = useState<string>('');
  const { changeView } = useViewContext();
  const queryParams = useQueryParams();
  const telemetry = useCreateWeddingTelemetry();
  const { t2 } = useTranslation('createWedding');
  const query = useLocation().search;

  const formikErrors = t2('createAccount', 'errors');

  const formik = useFormik<CreateAccountFields>({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(formikErrors.emailValid).required(formikErrors.emailRequired),
      password: Yup.string().required(formikErrors.passwordRequired)
    }).required(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ email, password }) => {
      try {
        telemetry.createAccountClicked();
        await authProvider.loginManager.signupWithUsernameAndPassword(email, password, `/${CREATE_WEDDING_APP_NAME}${query ? `?${query}` : ''}`);
      } catch (err) {
        telemetry.trackError('CreateAccount', err);
        if (isAuthProviderError(err)) {
          setSubmissionError(err.description);
        }
      }
    }
  });

  const getFieldErrorProps = useFieldErrors<CreateAccountFields>(formik);

  const focusHandlers = {
    email: () => {
      createAndAttachPixelImage({ action: 'signup' });
      telemetry.inputHandler('createAccountEmail', 'onFocus');
    },
    password: () => telemetry.inputHandler('createAccountPassword', 'onFocus')
  };

  return {
    formik,
    submissionError,
    focusHandlers,
    getFieldErrorProps,
    goToSignIn: () => changeView('signIn'),
    signInWithGoogle: () => authProvider.loginManager.loginWithGoogle(`/${CREATE_WEDDING_APP_NAME}`),
    signInWithFacebook: () => authProvider.loginManager.loginWithFacebook(`/${CREATE_WEDDING_APP_NAME}`),
    showSocialSignIn: ['google', 'facebook'].includes(`${queryParams.allowSignup}`)
  };
};
