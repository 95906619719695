import React, { useMemo } from 'react';
import { outlinedButtonOverrides, StyledLabel } from '../Shared.styles';
import { ThemesGrid } from './EditCardDesignForm.styles';
import { Flex, FormControl, ButtonV2, TooltipV2 } from '@withjoy/joykit';
import { FormikProps } from 'formik';
import { SaveTheDateDesignInputFields } from '@apps/saveTheDate/SaveTheDate.types';
import PhotoSelector from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector';
import { ReactComponent as AdjustAlignmentIcon } from '../../assets/icons/adjust-alignment.svg';
import EcardThemeCard from './components/EcardThemeCard';
import { SAVE_THE_DATE_PHOTO_THEMES } from '../../constants/index';
import { useSaveTheDateDispatch, useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { useTranslation } from '@shared/core';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { getThemeInfo } from '../../SaveTheDate.utils';
import { ReactComponent as ResetAlignmentIcon } from '../../assets/icons/reset.svg';
import { filterTidbitsPage } from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector/PhotoSelector.controller';

interface EditCardDesignFormProps
  extends Readonly<{
    formik: FormikProps<SaveTheDateDesignInputFields>;
  }> {}

export const EditCardDesignForm: React.FC<EditCardDesignFormProps> = ({ formik }) => {
  const { eCardDataQuery, eventId } = useSaveTheDateState();
  const { toggleIsAdjustingPosition } = useSaveTheDateDispatch();
  const { t2 } = useTranslation('emailsAndEcards');
  const translations = t2('ecardEditorPhotoSaveTheDate', 'ecardDesignEditor');
  const eventById = eCardDataQuery?.eventById;
  const pages = eventById?.pages;
  const { selectThemeClicked } = useSaveTheDateTelemetry();

  const resetPhotoPosition = () => {
    formik.setFieldValue('photoX', null);
    formik.setFieldValue('photoY', null);
    formik.setFieldValue('photoScale', 1);
  };

  const handlePhotoSelect = (photoUrl: string, assetId?: string, photoId?: string, width?: number, height?: number) => {
    formik.setFieldValue('photoId', photoId);
    formik.setFieldValue('photoUrl', photoUrl);
    formik.setFieldValue('photoAssetId', assetId || '');
    resetPhotoPosition();
    formik.setFieldValue('photoWidth', width);
    formik.setFieldValue('photoHeight', height);
  };

  const handleThemeSelect = (themeSelected: string) => {
    const currThemeInfo = getThemeInfo(formik.values.themeSelected);
    const nextThemeInfo = getThemeInfo(themeSelected);

    selectThemeClicked(getThemeInfo(themeSelected)?.telemetryId || '');
    formik.setFieldValue('themeSelected', themeSelected);

    if (currThemeInfo?.containerSize.width !== nextThemeInfo?.containerSize.width || currThemeInfo?.containerSize.height !== nextThemeInfo?.containerSize.height) {
      resetPhotoPosition();
    }
  };

  const resetButtonVisible = useMemo(() => {
    const xDirty = formik.initialValues.photoX !== formik.values.photoX;
    const yDirty = formik.initialValues.photoY !== formik.values.photoY;
    const scaleDirty = formik.initialValues.photoScale !== formik.values.photoScale;

    return xDirty || yDirty || scaleDirty;
  }, [formik.initialValues.photoScale, formik.initialValues.photoX, formik.initialValues.photoY, formik.values.photoScale, formik.values.photoX, formik.values.photoY]);

  const resetAdjustment = () => {
    formik.setFieldValue('photoX', formik.initialValues.photoX);
    formik.setFieldValue('photoY', formik.initialValues.photoY);
    formik.setFieldValue('photoScale', formik.initialValues.photoScale);
  };

  return (
    <>
      <FormControl marginTop="32px" marginBottom="20px" label={<StyledLabel>{translations.photoSourceLabel}</StyledLabel>}>
        <PhotoSelector
          pages={filterTidbitsPage(pages)}
          containerId={eventId}
          handlePhotoSelect={photo => handlePhotoSelect(photo.previewUrl, photo.assetId, photo.id, photo.width, photo.height)}
          allowCustomUpload
          photo={{
            id: formik.values.photoId,
            url: formik.values.photoUrl,
            assetId: formik.values.photoAssetId,
            width: formik.values.photoWidth,
            height: formik.values.photoHeight
          }}
        />
      </FormControl>
      <Flex>
        {resetButtonVisible && (
          <TooltipV2 content={translations.resetPosition}>
            <ButtonV2 onClick={resetAdjustment} startIcon={<ResetAlignmentIcon />} width="48px" marginRight="8px" variant="outline" overrides={outlinedButtonOverrides} />
          </TooltipV2>
        )}
        <ButtonV2 flex="1" variant="outline" startIcon={<AdjustAlignmentIcon />} onClick={toggleIsAdjustingPosition} overrides={outlinedButtonOverrides}>
          {translations.adjustPhotoLabel}
        </ButtonV2>
      </Flex>
      <FormControl paddingTop="20px" label={<StyledLabel>{translations.templatesLabel}</StyledLabel>}>
        <Flex flexDirection="column" alignItems="center">
          <ThemesGrid>
            {SAVE_THE_DATE_PHOTO_THEMES.map((theme, index) => (
              <EcardThemeCard key={index} theme={theme} selected={theme.id === formik.values.themeSelected} handleThemeSelect={handleThemeSelect} />
            ))}
          </ThemesGrid>
        </Flex>
      </FormControl>
    </>
  );
};
