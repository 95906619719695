/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Diamond',
  definitions: {
    '16': {
      viewBox: '0 0 16 17',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.54145 2.65821C4.37436 2.65821 4.21633 2.73417 4.11196 2.86465L1.34536 6.32325C1.17031 6.54209 1.18779 6.85756 1.38595 7.05572L7.6111 13.2809C7.82588 13.4957 8.17413 13.4957 8.38891 13.2809L14.6141 7.05572C14.8122 6.85756 14.8297 6.5421 14.6547 6.32326L11.8881 2.86465C11.7838 2.73416 11.6257 2.6582 11.4586 2.6582C9.15292 2.6582 6.84723 2.65821 4.54145 2.65821ZM8.58911 3.75821L7.41096 3.75821L6.33887 6.1168H9.6612L8.58911 3.75821ZM10.8695 6.1168L9.79741 3.7582L11.1943 3.7582L13.0809 6.1168H10.8695ZM9.68703 7.2168H6.31303L8.00003 11.2234L9.68703 7.2168ZM9.41381 10.7003L10.8806 7.2168H12.8973L9.41381 10.7003ZM2.91914 6.1168H5.13056L6.20265 3.75821L4.80581 3.75821L2.91914 6.1168ZM6.5863 10.7004L5.1195 7.2168H3.10266L6.5863 10.7004Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.21744 4.70509C7.03516 4.70509 6.86277 4.78795 6.7489 4.93029L2.92321 9.71291C2.73224 9.95164 2.75131 10.2958 2.96749 10.512L11.5757 19.1202C11.81 19.3545 12.1899 19.3545 12.4243 19.1202L21.0325 10.512C21.2487 10.2958 21.2677 9.95165 21.0768 9.71291L17.2512 4.93029C17.1373 4.78794 16.9649 4.70508 16.7826 4.70508C13.5941 4.70508 10.4059 4.70508 7.21744 4.70509ZM12.918 5.90508L11.082 5.90508L9.45359 9.48768H14.5465L12.918 5.90508ZM14.5746 10.6877H9.42543L12 16.8023L14.5746 10.6877ZM13.489 16.3584L15.8767 10.6877H19.1597L13.489 16.3584ZM15.8646 9.48768L14.2362 5.90508L16.4942 5.90508L19.3599 9.48768H15.8646ZM4.64006 9.48768H8.13544L9.7639 5.90508L7.50584 5.90509L4.64006 9.48768ZM10.5111 16.3585L8.1234 10.6877H4.84026L10.5111 16.3585Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.635 5.08985C8.45272 5.08985 8.28032 5.17272 8.16646 5.31506L2.27502 12.6801C2.08405 12.9189 2.10312 13.263 2.31929 13.4792L15.5757 26.7356C15.81 26.9699 16.1899 26.9699 16.4242 26.7356L29.6806 13.4792C29.8968 13.263 29.9159 12.9189 29.7249 12.6801L23.8336 5.31506C23.7198 5.17271 23.5474 5.08984 23.3651 5.08984C18.455 5.08985 13.5451 5.08985 8.635 5.08985ZM17.6222 6.28985L14.3778 6.28985L11.5755 12.455H20.4246L17.6222 6.28985ZM21.7427 12.455L18.9404 6.28985L23.0767 6.28984L28.0081 12.455H21.7427ZM20.4527 13.655H11.5473L16 24.2302L20.4527 13.655ZM17.3526 24.1102L21.7548 13.655H27.8078L17.3526 24.1102ZM3.9918 12.455H10.2573L13.0597 6.28985L8.92339 6.28985L3.9918 12.455ZM14.6475 24.1104L10.2453 13.655H4.19215L14.6475 24.1104Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.3387 8.42014C35.2201 8.27187 35.0405 8.18555 34.8506 8.18555L13.1495 8.18556C12.9597 8.18556 12.7801 8.27188 12.6615 8.42016L3.98191 19.2707C3.78299 19.5194 3.80285 19.8779 4.02803 20.1031L23.558 39.6331C23.8021 39.8771 24.1978 39.8771 24.4419 39.6331L43.9719 20.1031C44.1971 19.8779 44.2169 19.5194 44.018 19.2707L35.3387 8.42014ZM13.4499 9.43556L20.0702 9.43556L15.7063 19.0362H5.77024L13.4499 9.43556ZM17.0794 19.0362L21.4433 9.43556L26.5569 9.43555L30.9208 19.0362H17.0794ZM30.9502 20.2862H17.05L24.0001 36.7927L30.9502 20.2862ZM25.2414 37.0658L32.3065 20.2862H42.021L25.2414 37.0658ZM32.2939 19.0362L27.93 9.43555L34.5502 9.43555L42.2297 19.0362H32.2939ZM5.97891 20.2862H15.6937L22.759 37.0663L5.97891 20.2862Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
