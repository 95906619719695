import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core';

interface UseConfirmationScreenControllerArgs
  extends Readonly<{
    eventHandle: string;
    selectedGuestsCount: number;
  }> {}

export const useConfirmationScreenController = (args: UseConfirmationScreenControllerArgs) => {
  const { eventHandle, selectedGuestsCount } = args;
  const openGuestList = () => {
    withWindow(() => {
      window.location.href = window.location.origin + `/${eventHandle}/edit/guests`;
    });
  };

  const openPaperCard = () => {
    withWindow(() => {
      window.location.href = window.location.origin + `/${eventHandle}/edit/cards`;
    });
  };

  const { t, t2 } = useTranslation('emailsAndEcards');
  const tConfirmationScreen = t2('confirmAndSend', 'confirmationScreen');
  const tConfirmationScreenDek = t('confirmAndSend', 'confirmationScreen', 'dek', 'dek');
  const dekText = tConfirmationScreenDek({ count: selectedGuestsCount });

  return {
    openGuestList,
    openPaperCard,
    tConfirmationScreen,
    dekText
  };
};
