import { animationTransition } from '@shared/utils/animationTransition';
import { Box, styled } from '@withjoy/joykit';

type StyledBoxProps = { $highlighted: boolean; $shadowOpacity: number };

export const StyledBox = styled(Box).attrs<StyledBoxProps>(({ $highlighted, $shadowOpacity }) => ({
  style: {
    boxShadow: `0 0 0 5000px ${$highlighted ? `rgba(0, 0, 0, ${$shadowOpacity})` : 'transparent'}`
  }
}))<StyledBoxProps>`
  position: relative;
  background-color: white;
  border-radius: 20px;
  width: fit-content;
  z-index: 1;
  transition: ${animationTransition('box-shadow')};
`;
