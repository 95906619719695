import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { ModalInner, ModalWrapper, ANIMATIONS } from './dialog.styles';
import { Overlay } from '../Overlay/Overlay';
import { generateComponentDisplayName, safeInvoke } from '../../utils';
import { OverlayContext } from '../Overlay';
import { DialogHeader } from './components/DialogHeader';
import { DialogBody } from './components/DialogBody';
import EventListener from 'react-event-listener';
import { Transition } from '../Overlay/Transition';
import { DialogV1Props } from './dialog.props';
import { pickBy } from 'lodash';

/**
 * -----------------------------------
 * DEPRECATED -- Use `<DialogV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { DialogV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/DialogV2/Dialog.stories.tsx`.
 */
const DialogV1: React.FC<DialogV1Props> & { Header: typeof DialogHeader; Body: typeof DialogBody } = props => {
  const overlayContext = useContext(OverlayContext);
  const { isOpen } = overlayContext.isOverlayOpen(props.overlayKey);

  const [isScrollable, setisScrollable] = useState(false);

  const dialogRef = useRef<HTMLDivElement | null>(null);

  const checkAndSetIfScrollable = useCallback(() => {
    if (!isOpen) {
      return;
    }

    if (dialogRef.current) {
      const { height } = dialogRef.current.getBoundingClientRect();
      const nextIsScrollable = height > window.innerHeight - 120;
      if (isScrollable !== nextIsScrollable) {
        setisScrollable(() => nextIsScrollable);
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        checkAndSetIfScrollable();
      });
    } else {
      setisScrollable(false);
    }
  }, [isOpen]);

  const { children, width, ...overlayProps } = props;

  const isScrollableBehaviorEnabled = isScrollable;

  const handleOnEntered = useCallback(() => {
    safeInvoke(overlayProps.onEntered);
  }, [overlayProps.onEntered]);

  return (
    <Overlay {...overlayProps} isScrollContainer={true} containerRef={props.overlayRef} isOpen={isOpen} containerElement={dialogRef.current}>
      <Transition
        key={props.animationFrom || props.animationTo || props.animationLeave ? JSON.stringify(pickBy(props, ['animationFrom', 'animationTo', 'animationLeave'])) : undefined}
        toggle={isOpen}
        from={props.animationFrom || ANIMATIONS.from}
        to={props.animationTo || ANIMATIONS.to}
        leave={props.animationLeave || ANIMATIONS.leave}
        onEntering={overlayProps.onEntering}
        onEntered={handleOnEntered}
        onExiting={overlayProps.onExiting}
        onExited={overlayProps.onExited}
      >
        {transitionProps => {
          return transitionProps ? (
            <ModalWrapper isOpen={isOpen} ref={dialogRef} isScrollable={isScrollableBehaviorEnabled}>
              <ModalInner
                aria-modal="true"
                role="dialog"
                width={width}
                style={{
                  opacity: transitionProps.opacity,
                  transform: transitionProps.scale?.interpolate(x => `scale(${x}) translateZ(0.01px)`),
                  top: transitionProps.top?.interpolate(top => `${top}px`)
                }}
              >
                <EventListener target="window" onResize={checkAndSetIfScrollable} />

                {children}
              </ModalInner>
            </ModalWrapper>
          ) : null;
        }}
      </Transition>
    </Overlay>
  );
};

DialogV1.Header = DialogHeader;
DialogV1.Body = DialogBody;

DialogV1.displayName = generateComponentDisplayName(DialogV1);

export { DialogV1 };
