import { Accordion, Box, ButtonV2, CheckboxV2, DrawerV2, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { FilterFacet, FilterFacetOption } from './Filter.types';
import { ChevronDown, ChevronUp } from '@withjoy/joykit/icons';
import { useDesignGalleryFiltersContext } from '../../DesignGalleryFiltersProvider';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { CHECKBOX_OVERRIDES } from './FiltersDrawer.styles';
import { useTranslation } from '@shared/core';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { getCheckboxInputProps } from './components/ListCheckboxRow';

interface FiltersDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  filterFacets: FilterFacet[];
  defaultIndex: number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ActiveFilterCountTriggerButton = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display="inline-flex" alignItems="center" justifyContent="center" borderRadius="full" backgroundColor="#000" color="white" size={'inherit'}>
      {children}
    </Box>
  );
};

const Trigger = ({ label, isExpanded, activeFilterCount, onClick }: { label: string; isExpanded: boolean; activeFilterCount: number; onClick: () => void }) => {
  const expandedButton = activeFilterCount > 0 ? <ActiveFilterCountTriggerButton>{activeFilterCount}</ActiveFilterCountTriggerButton> : <ChevronUp size={24} />;
  return (
    <Accordion.Button onClick={onClick} justifyContent="space-between" paddingLeft={6} paddingRight={6} height={56}>
      <TextV2>{label}</TextV2>
      <Box display="flex" size={30} justifyContent="center" alignItems="center">
        {isExpanded ? expandedButton : <ChevronDown size={24} />}
      </Box>
    </Accordion.Button>
  );
};

const ListCheckboxRow = ({ onChange, option }: { option: FilterFacetOption; onChange: React.ChangeEventHandler<HTMLInputElement> }) => {
  return (
    <Box as="li" minHeight={8} display="flex">
      <CheckboxV2
        overrides={{
          ...CHECKBOX_OVERRIDES,
          Input: {
            props: getCheckboxInputProps(option.compositeCode, 'mobileDrawer')
          }
        }}
        width="100%"
        isChecked={option.isActive}
        onChange={onChange}
      >
        {option.label} ({option.matchingItemCount})
      </CheckboxV2>
    </Box>
  );
};

const Root = (props: FiltersDrawerProps) => {
  const { defaultIndex, filterFacets, onChange } = props;

  const { t } = useTranslation('stationery');
  const { filterDrawerButtonClicked, filterDrawerAccordionTriggerClicked } = useCardTelemetry({ page: 'designGalleryFiltersDrawer' });
  const tFiltersDrawer = t('dashboard', 'designsGallery', 'filtersDrawer');
  const { clearAllFilters, closeFilterDrawer, getActiveFilterCountByGroupCode } = useDesignGalleryFiltersContext();

  const handleOnResetFiltersClick = useEventCallback(() => {
    filterDrawerButtonClicked({ source: 'resetFilters' });
    clearAllFilters();
  });

  const handleOnShowResultsClick = useEventCallback(() => {
    filterDrawerButtonClicked({ source: 'showResults' });
    closeFilterDrawer();
  });

  return (
    <>
      <DrawerV2.Header typographyVariant="hed5" fontWeight={600}>
        {tFiltersDrawer.header()}
        <DrawerV2.CloseButton
          onClick={() => {
            filterDrawerButtonClicked({ source: 'exitDialog' });
          }}
        />
      </DrawerV2.Header>

      <DrawerV2.Body>
        <Accordion defaultIndex={defaultIndex}>
          {filterFacets.map(facet => {
            return (
              <Accordion.Item key={`${facet.groupCode}`}>
                {({ isExpanded }) => {
                  return (
                    <>
                      <Trigger
                        onClick={() => {
                          filterDrawerAccordionTriggerClicked({ facet: facet.label, isExpanded });
                        }}
                        activeFilterCount={getActiveFilterCountByGroupCode(facet.groupCode)}
                        label={facet.label}
                        isExpanded={isExpanded}
                      />

                      <Accordion.Panel paddingX={0}>
                        <Box as="ul" display="flex" flexDirection="column" rowGap={'2px'}>
                          {facet.options.map(option => {
                            return <ListCheckboxRow key={option.compositeCode} option={option} onChange={onChange} />;
                          })}
                        </Box>
                      </Accordion.Panel>
                    </>
                  );
                }}
              </Accordion.Item>
            );
          })}
        </Accordion>
      </DrawerV2.Body>

      <DrawerV2.Footer borderTop="1px solid" borderTopColor="mono3" paddingY={6} paddingX={4} justifyContent="space-between">
        <ButtonV2 intent="neutral" variant="ghost" onClick={handleOnResetFiltersClick}>
          {tFiltersDrawer.resetCta()}
        </ButtonV2>
        <ButtonV2 intent="neutral" shape="rounded" onClick={handleOnShowResultsClick}>
          {tFiltersDrawer.showResultsCta()}
        </ButtonV2>
      </DrawerV2.Footer>
    </>
  );
};

export const FiltersDrawer = (props: FiltersDrawerProps) => {
  const { isOpen, onClose } = props;

  return (
    <DrawerV2
      isOpen={isOpen}
      onClose={onClose}
      stickyBehavior="footer"
      size="full"
      enableDividers
      overrides={{
        Header: {
          props: {
            borderBottom: '1px solid',
            borderBottomColor: 'mono3'
          }
        }
      }}
    >
      <Root {...props} />
    </DrawerV2>
  );
};
