import { useMemo, useState } from 'react';
import { CreateEventMutation, useSetAndPublishThemeLayoutForCreateSurveyMutation, useSaveEventProductVerticalsMutation } from '@graphql/generated';
import { SurveyEventDesign, SurveyItems, SurveyOptions } from '@apps/createWedding/routes/Survey/Survey.types';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { setInitialPagesVisibility } from '@apps/createWedding/routes/Survey/setInitialPagesVisibility';
import { addError } from '@shared/utils/logger';
import { useAllowedDesignParams } from '@apps/createWedding/components/AllowedDesignParamsProvider';
import { useAuth } from '@shared/components/AuthProvider';
import { ReactComponent as RegistryIcon } from '@assets/icons/survey-registry.svg';
import { ReactComponent as WebsiteIcon } from '@assets/icons/survey-website.svg';
import { ReactComponent as SaveTheDateIcon } from '@assets/icons/survey-save-the-date.svg';
import { ReactComponent as GuestListIcon } from '@assets/icons/survey-guestlist.svg';

export const useSurveyController = (userId: string) => {
  const { loginManager } = useAuth();
  const [selectedOptions, setSelectedOptions] = useState<Partial<Record<SurveyOptions['id'], boolean>>>({});
  const [saveEventProductVerticals] = useSaveEventProductVerticalsMutation();
  const { honorSurveyResults: honorSurveyResultsTelemetry } = useCreateWeddingTelemetry();
  const { themes } = useAllowedDesignParams();
  const [setAndPublishThemeAndLayout] = useSetAndPublishThemeLayoutForCreateSurveyMutation();

  const surveyOptions: SurveyOptions[] = useMemo(() => {
    return [
      {
        id: SurveyItems.WEB_SITE,
        labelKey: SurveyItems.WEB_SITE,
        SurveyOptionIcon: WebsiteIcon
      },
      {
        id: SurveyItems.REGISTRY,
        labelKey: SurveyItems.REGISTRY,
        SurveyOptionIcon: RegistryIcon
      },
      {
        id: SurveyItems.INVITATION_SAVE_THE_DATES,
        labelKey: 'invitations',
        SurveyOptionIcon: SaveTheDateIcon
      },
      {
        id: SurveyItems.GUEST_LIST_RSVP,
        labelKey: 'guestList',
        SurveyOptionIcon: GuestListIcon
      }
    ];
  }, []);

  const surveyOptionIds: string[] = useMemo(() => surveyOptions.map(({ id }) => id), [surveyOptions]);

  const setTheme = async (themeId: string, eventDesign: SurveyEventDesign, selection: string[]) => {
    try {
      await setAndPublishThemeAndLayout({
        variables: {
          themeAndLayoutPayload: {
            eventDesignId: eventDesign.id ?? '',
            layoutId: eventDesign.websiteLayout?.id ?? '',
            themeId,
            useSuggestedThemeColor: true
          },
          eventDesignId: eventDesign.id ?? ''
        }
      });
    } catch (error) {
      addError(error, {
        surveySelection: selection,
        themeId,
        eventDesign
      });
    }
  };

  const saveProductVerticals = async (eventId: string | undefined) => {
    if (eventId) {
      let payload = {
        optOutOfGuestList: !selectedOptions.guestList_rsvp,
        optOutOfInvites: !selectedOptions.invitation_save_the_dates,
        optOutOfRegistry: !selectedOptions.registry,
        optOutOfWebsite: !selectedOptions.website
      };

      if (!Object.keys(selectedOptions).length) {
        payload = {
          optOutOfGuestList: false,
          optOutOfInvites: false,
          optOutOfRegistry: false,
          optOutOfWebsite: false
        };
      }

      await saveEventProductVerticals({ variables: { eventId, payload } });
    }
  };

  const handleEventCreated = async (event: CreateEventMutation) => {
    const website = event.createEventV0301?.website;
    const promises: Promise<unknown>[] = [];

    if (website) {
      const selection = Object.keys(selectedOptions).filter(option => option in selectedOptions);
      const selectionToSend = selection.length ? selection : surveyOptionIds;

      honorSurveyResultsTelemetry(selectionToSend);

      if (!selectionToSend.includes(SurveyItems.WEB_SITE)) {
        promises.push(setInitialPagesVisibility(selectionToSend, website, loginManager.getToken()));

        const eventDesign = event.createEventV0301?.eventDesign;
        const themeId = themes.get('blank') ?? '';

        if (themeId && eventDesign) {
          promises.push(setTheme(themeId, eventDesign, selectionToSend));
        }
      }
      promises.push(saveProductVerticals(event.createEventV0301?.id));
    }

    await Promise.all(promises);
  };

  return { selectedOptions, setSelectedOptions, handleEventCreated, surveyOptions, surveyOptionIds };
};
