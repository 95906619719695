import React from 'react';
import { Close } from '@withjoy/joykit/icons';
import { MobileBlogMenu } from '../MobileBlogMenu';
import { MobileMainMenu } from '../MobileMainMenu';
import { Box, Flex, DrawerV2 } from '@withjoy/joykit';
import { MobileSubMainMenu } from '../MobileSubMainMenu';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { StyledIconBrandLogoNoColor, styles } from './MobileMenuDrawer.styles';
import { MobileMenuType, useMobileViewContext } from '../../MobileView.provider';

export const MobileMenuDrawer: React.FC = () => {
  const { isMobileNavOpen, selectedMenu, setMobileNavIsOpen, onClickBack } = useMobileViewContext();
  const handleOnClose = useEventCallback(() => {
    onClickBack(true);
    setMobileNavIsOpen(false);
  });
  return (
    <DrawerV2 anchor="left" isOpen={isMobileNavOpen} overrides={{ Content: { props: { width: '100%' } } }}>
      <Flex justifyContent="space-between" padding={6} paddingBottom={5}>
        <Box as="a" href={'/'}>
          <StyledIconBrandLogoNoColor style={{ ...styles.iconBrandLogoNoColor }} />
        </Box>
        <Flex as="button" alignItems="center" onClick={handleOnClose}>
          <Close title="Close" size={32} />
        </Flex>
      </Flex>
      {selectedMenu === MobileMenuType.mainMenu && <MobileMainMenu />}
      {selectedMenu === MobileMenuType.subMenu && <MobileSubMainMenu />}
      {selectedMenu === MobileMenuType.blogMenu && <MobileBlogMenu />}
    </DrawerV2>
  );
};
