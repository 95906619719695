import React from 'react';
import { Skeleton, SkeletonGroup } from '@shared/components/Skeleton';
import { EcardDesignEditorGrid } from '../../EcardDesignEditor.styles';
import { ECARD_THEME_THUMBNAIL_WIDTH, ECARD_THEME_THUMBNAIL_HEIGHT } from '../../../EcardThemeCard/EcardThemeCard.styles';

const count = Array.from(Array(9));

interface Props
  extends Readonly<{
    children: React.ReactNode;
    isReady: boolean;
  }> {}

export const EcardThemesSkeletonScreens: React.FC<Props> = ({ children, isReady }) => {
  return (
    <SkeletonGroup
      isReady={isReady}
      placeholder={
        <EcardDesignEditorGrid>
          {count.map((_, index) => {
            return <Skeleton width={ECARD_THEME_THUMBNAIL_WIDTH} height={ECARD_THEME_THUMBNAIL_HEIGHT} key={index} />;
          })}
        </EcardDesignEditorGrid>
      }
    >
      {children}
    </SkeletonGroup>
  );
};
