import React from 'react';
import { Route, RoutePath, RoutesPaths, RoutesProvider, Switch, useRouterHelper, Redirect } from '@shared/core';
import { Design } from '@apps/saveTheDate/routes/Design';
import { Email } from '@apps/saveTheDate/routes/Email';
import { Confirm } from '@apps/saveTheDate/routes/Confirm';
import { PeopleProvider } from '@shared/context/people';
import ConfirmSheet from '@apps/postOffice/components/ConfirmSheet';
import MobileSendTestEmailDialog from '@apps/postOffice/components/MobileSendTestEmailDialog';
import { BottomSheet } from '@shared/components/BottomSheet';
import { useSaveTheDateState } from './state/context';

type RoutesNames = 'design' | 'email' | 'confirm';

export const routePaths: RoutesPaths<RoutesNames> = {
  design: {
    path: 'design',
    goToPath: () => 'design'
  },
  email: {
    path: 'email',
    goToPath: () => 'email'
  },
  confirm: {
    path: 'confirm',
    goToPath: () => 'confirm'
  }
};

interface RoutesSaveTheDateProps
  extends Readonly<{
    handle: string;
  }> {}

const RoutesSaveTheDate: React.FC<RoutesSaveTheDateProps> = ({ handle }) => {
  const routeHelpers = useRouterHelper();

  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(routePaths.design.path),
      component: () => <Design />
    },
    {
      path: routeHelpers.buildPath(routePaths.email.path),
      component: () => <Email />
    },
    {
      path: routeHelpers.buildPath(routePaths.confirm.path),
      component: () => <Confirm />
    }
  ];

  return (
    <Switch>
      {routes.map(route => {
        return <Route exact key={route.path} path={route.path} render={route.component} />;
      })}
      <Redirect to={routeHelpers.buildPath(routePaths.design.path)} />
    </Switch>
  );
};

interface RouterSaveTheDateProps
  extends Readonly<{
    appUrl: string;
    handle: string;
    isInEditor: boolean;
  }> {}

export const RouterSaveTheDate: React.FC<RouterSaveTheDateProps> = ({ appUrl, handle, isInEditor }) => {
  const { isAdjustingPosition } = useSaveTheDateState();

  return (
    <RoutesProvider appUrl={appUrl} handle={handle}>
      <PeopleProvider>
        {isInEditor && <BottomSheet forceClosed={isAdjustingPosition} />}
        <MobileSendTestEmailDialog />
        <ConfirmSheet />
        <RoutesSaveTheDate handle={handle} />
      </PeopleProvider>
    </RoutesProvider>
  );
};
