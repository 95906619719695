import { InGroupPosition } from '@shared/components/PeopleListItem/PeopleListItem';
import { ListItemVo } from '@shared/components/SelectList/SelectList';

export const getInGroupPosition = (itemIndex: number, groupSize: number) => {
  let result: InGroupPosition = '';
  if (groupSize > 1) {
    if (itemIndex === 0) {
      result = 'first';
    } else if (itemIndex === groupSize - 1) {
      result = 'last';
    } else {
      result = 'middle';
    }
  }

  return result;
};

export type InGroupPositionItem<T> = T & { inGroupPosition: InGroupPosition };

export const convertGroupsToItems = <T extends ListItemVo>(groups: ReadonlyArray<ReadonlyArray<T>>): Array<InGroupPositionItem<T>> => {
  return groups.reduce<Array<InGroupPositionItem<T>>>((acc, group) => {
    let result: Array<InGroupPositionItem<T>>;
    if (group.length > 1) {
      result = group.map((item, index) => ({ ...item, inGroupPosition: getInGroupPosition(index, group.length) }));
    } else {
      result = group.map(item => ({ ...item, inGroupPosition: '' }));
    }
    acc.push(...result);
    return acc;
  }, []);
};
