import React, { useContext } from 'react';
// @ts-ignore
import useSelectRecipientsController from './SelectRecipients.controller.tsx';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';
import { PeopleContext } from '@shared/context/people';
import { Flex, TextV2, IconV2, ButtonV2 } from '@withjoy/joykit';
import { SelectRecipientsEmail } from '@withjoy/joykit/icons';
import { outlinedDarkButtonOverrides } from '../Shared.styles';
import { useTranslation } from '@shared/core';

const SelectRecipientsView: React.FC = () => {
  const { onSelectGuestsClick } = useSelectRecipientsController();
  const { transform } = useBottomSheetDescriptionOffset();
  const { selectedPeople } = useContext(PeopleContext);
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tSelectRecipients = tBottomSheet.selectRecipients;

  const guestsDescription = selectedPeople.length === 1 ? tSelectRecipients.guestsDescriptionSingular() : tSelectRecipients.guestsDescription({ count: selectedPeople.length });

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center" height="24px">
        <IconV2 size="md" marginRight="12px">
          <SelectRecipientsEmail />
        </IconV2>{' '}
        {tSelectRecipients.title()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" flex="1" style={{ transform }}>
        {!!selectedPeople.length ? guestsDescription : tSelectRecipients.noGuestsDescription()}
      </TextV2>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={onSelectGuestsClick}>
        {!!selectedPeople.length ? tSelectRecipients.editGuests() : tSelectRecipients.addGuests()}
      </ButtonV2>
    </Flex>
  );
};

export default SelectRecipientsView;
