import React from 'react';
import { ReactComponent as EditDesignSvg } from '@assets/icons/edit-design.svg';
import { ReactComponent as EditContentSvg } from '@assets/icons/edit-content.svg';
import PhotoECardPreviewWrapper from './components/PhotoECardPreviewWrapper';
import EditButton from '@apps/saveTheDate/components/EditButton';
import { SaveTheDateDesignInputFields, SaveTheDateExtraData, SaveTheDateEditorType } from '@apps/saveTheDate/SaveTheDate.types';
import { usePhotoECardPreviewController } from './PhotoECardPreview.controller';
import { FrontCard, TemplateContainer } from './PhotoECardPreview.styles';
import BackCard from './components/BackCard';
import AdjustmentButtons from './components/AdjustmentButtons';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

interface PhotoECardPreviewProps
  extends Readonly<{
    ecardData: SaveTheDateDesignInputFields;
    isECardEditPanelShown?: boolean;
    toggleEditECardPanel?: (openType: SaveTheDateEditorType) => void;
    previewFrontRef?: React.RefObject<HTMLDivElement>;
    previewBackRef?: React.RefObject<HTMLDivElement>;
    extraData: SaveTheDateExtraData;
    isGuestView?: boolean;
    guestNames?: string;
    onLogoutEventHandle?: () => void;
    isAdjustingPosition?: boolean;
    toggleAdjustment?: () => void;
    savePosition?: (x: number, y: number, scale: number) => void;
  }> {}

const DEFAULT_BORDER_RADIUS = '4px';

export const ScaleContext = React.createContext({ isVisible: true });

export const PhotoECardPreview: React.FC<PhotoECardPreviewProps> = ({
  ecardData,
  isECardEditPanelShown = false,
  toggleEditECardPanel,
  previewFrontRef,
  previewBackRef,
  extraData,
  isGuestView = false,
  guestNames = '',
  isAdjustingPosition = false,
  toggleAdjustment,
  onLogoutEventHandle,
  savePosition
}) => {
  const isMobile = useIsMobileScreen();
  const {
    roundTopCorners,
    template,
    applyAdjustment,
    cancelAdjustment,
    handleEcardMouseEnter,
    handleEcardMouseLeave,
    isScaleControlVisible,
    isPanningPhoto,
    showNotYou
  } = usePhotoECardPreviewController({
    ecardData,
    extraData,
    isAdjustingPosition,
    toggleAdjustment,
    savePosition
  });
  const borderTopRadius = roundTopCorners ? '250px' : DEFAULT_BORDER_RADIUS;

  if (!extraData.hasInitializedOnce) {
    return null;
  }

  return (
    <PhotoECardPreviewWrapper
      isAdjustingPosition={isAdjustingPosition}
      isECardEditPanelShown={isECardEditPanelShown}
      isGuestView={isGuestView}
      guestNames={guestNames}
      onLogoutEventHandle={onLogoutEventHandle}
      showNotYou={showNotYou}
    >
      <FrontCard ref={previewFrontRef} $isAdjusting={isAdjustingPosition} $isPanning={isPanningPhoto} $roundTopCorners={roundTopCorners}>
        <ScaleContext.Provider value={{ isVisible: !isPanningPhoto && isAdjustingPosition && isScaleControlVisible }}>
          <TemplateContainer
            borderBottomLeftRadius={DEFAULT_BORDER_RADIUS}
            borderBottomRightRadius={DEFAULT_BORDER_RADIUS}
            borderTopLeftRadius={borderTopRadius}
            borderTopRightRadius={borderTopRadius}
            onMouseEnter={handleEcardMouseEnter}
            onMouseLeave={handleEcardMouseLeave}
          >
            {template}
          </TemplateContainer>
        </ScaleContext.Provider>
      </FrontCard>
      {isAdjustingPosition ? (
        <AdjustmentButtons applyAdjustment={applyAdjustment} cancelAdjustment={cancelAdjustment} />
      ) : (
        <BackCard ref={previewBackRef} ecardData={ecardData} extraData={extraData} isGuestView={isGuestView} />
      )}
      {toggleEditECardPanel && !isMobile && (
        <EditButton
          isVisible={!isECardEditPanelShown && !isAdjustingPosition}
          onEditToggle={() => toggleEditECardPanel(SaveTheDateEditorType.DESIGN)}
          type={SaveTheDateEditorType.DESIGN}
        >
          <EditDesignSvg />
        </EditButton>
      )}
      {toggleEditECardPanel && !isMobile && (
        <EditButton
          isVisible={!isECardEditPanelShown && !isAdjustingPosition}
          onEditToggle={() => toggleEditECardPanel(SaveTheDateEditorType.CONTENT)}
          type={SaveTheDateEditorType.CONTENT}
        >
          <EditContentSvg />
        </EditButton>
      )}
    </PhotoECardPreviewWrapper>
  );
};
