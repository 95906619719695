import { getLocalStorage } from '@shared/core';

const KEY = 'shareable-dialog-viewed';

interface Args {
  copy?: () => Promise<void>;
  openDialog: () => void;
}

const useHandleCopyShareableLink = (args: Args) => {
  const { openDialog, copy } = args;
  const localStorage = getLocalStorage();

  return async () => {
    if (!localStorage.getItem(KEY)) {
      openDialog();
      localStorage.setItem(KEY, 'true');
    } else {
      await copy?.();
    }
  };
};

export default useHandleCopyShareableLink;
