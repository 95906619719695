export enum EventPageSlugs {
  Custom = 'custom',
  Guests = 'guests',
  Registry = 'registrylinks',
  Rsvp = 'rsvp',
  Schedule = 'schedule',
  Story = 'story',
  Travel = 'travel',
  Accommodations = 'accommodations',
  Faq = 'faq',
  Settings = 'settings',
  Video = 'video',
  WeddingParty = 'weddingparty',
  Welcome = 'welcome'
}
