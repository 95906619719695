import { usePriceSummary } from '@apps/card/components/PriceSummary/PriceSummary';
import { useCustomizationContext } from '@apps/card/routes/CardCustomizer';

export const useCustomizationPriceSummary = () => {
  const [{ customizationConfig, templateCategory }] = useCustomizationContext(draft => {
    return {
      customizationConfig: draft.config,
      templateCategory: draft.stationeryTemplateCategory
    };
  });
  const summary = usePriceSummary({ templateCategory, priceUpTo: 'cardFront', cardConfig: customizationConfig, promotion: null });

  return {
    customizationConfig,
    summary
  };
};
