import React from 'react';
import { SignIn } from '@apps/account/components/SignIn';
import { useSignInPageController } from './SignInPage.controller';
import { SignUp } from '@apps/account/components/SignUp';
import { ForgotPassword } from '@apps/account/components/ForgotPassword';
import { History } from 'history';
import { ButtonV2, Box } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { Redirect } from '@react-router';
import StyledTopBar from '@shared/components/StyledTopBar';
import { CenteredStack, PageTitle } from '@apps/account/components/CommonStyles';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

export interface AccountProps
  extends Readonly<{
    history: History<History.PoorMansUnknown>;
  }> {}

const SignInPage: React.FC<AccountProps> = () => {
  const { mode, redirectUri, isSignedIn, logoutClickHandler, userProfile, switchMode } = useSignInPageController();
  const name = (userProfile?.firstName && [userProfile.firstName, userProfile.lastName].filter(n => !!n).join(' ')) || userProfile?.email || userProfile?.id;
  const { t } = useTranslation('account');
  if (isSignedIn) {
    return (
      <Box rowGap={6} margin={10}>
        {userProfile?.avatar && <img height="80px" width="auto" src={userProfile.avatar.url} alt={name}></img>}
        <div>{t('signedinas')({ name })}</div>
        <ButtonV2 width={'100%'} onClick={logoutClickHandler}>
          {t('signout')()}
        </ButtonV2>
      </Box>
    );
  }
  switch (mode) {
    case 'signup':
      return (
        <>
          <CenteredStack spacing={5} margin={6}>
            <PageTitle>{t('signup').signUpForJoy()}</PageTitle>
          </CenteredStack>
          <SignUp redirectUri={redirectUri} signInInstead={() => switchMode('signin')} />
        </>
      );
    case 'forgotpassword':
      return <ForgotPassword />;
    case 'signin':
      return <SignIn redirectUri={redirectUri} signUpInstead={() => switchMode('signup')} />;
    case 'redirect':
      return <Redirect to="/account/events" />;
    default:
      return <JoyLogoLoader loaderKey="account-mode" />;
  }
};

export const AccountPage: React.FC<AccountProps> = props => {
  return (
    <div>
      <StyledTopBar.TopBar>
        <a href="https://withjoy.com">
          <StyledTopBar.StyledJoyLogo />
        </a>
      </StyledTopBar.TopBar>
      <SignInPage {...props} />
    </div>
  );
};
