import { useAuth } from '@shared/components/AuthProvider';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useAccountTelemetry } from '@apps/account/account.telemetry';
import { Provider } from '@apps/account/types';
import { useTranslation } from '@shared/core';

export function useSignUpController(redirectUri: string = '/') {
  const authProvider = useAuth();
  const telemetry = useAccountTelemetry();
  const { t2 } = useTranslation('account');
  const tFormWarnings = t2('formWarnings');
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string>('');

  interface SignUpFields {
    email: string;
    password: string;
  }

  const formik = useFormik<SignUpFields>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object<SignUpFields>({
      email: Yup.string().email(tFormWarnings.invalidEmail).required(tFormWarnings.required),
      password: Yup.string().required(tFormWarnings.required)
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        telemetry.emailPasswordSignUp(values.email, !!values.password);
        await authProvider.loginManager.signupWithUsernameAndPassword(values.email, values.password, redirectUri, '/');
      } catch (err) {
        console.error(err);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setSubmitError((err as any).description);
        setLoading(false);
      }
      setLoading(false);
    }
  });

  const thirdPartyClickHandler = useCallback(
    (type: Provider) => {
      switch (type) {
        case 'google':
          telemetry.thirdPartyButton(type);
          // sign up with google
          return;
        case 'facebook':
          telemetry.thirdPartyButton(type);
          // sign up with facebook
          return;
        default:
          telemetry.unHandledThirdPartyButton(type);
          return;
      }
    },
    [telemetry]
  );

  return {
    formik,
    thirdPartyClickHandler,
    loading,
    submitError
  };
}
