import { styled } from '@withjoy/joykit';
import { Dialog } from '@headlessui/react';
import { animationTransition } from '@shared/utils/animationTransition';

export const StyledDialog = styled(Dialog)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .transition {
    transition: ${animationTransition('opacity')};
  }

  .opacity-100 {
    opacity: 1;
  }

  .opacity-0 {
    opacity: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(33, 33, 33, 0.48);
  }

  .panel {
    border-radius: 12px;
    width: 305px;
    max-width: 80%;
    max-height: 80%;
    position: relative;
    overflow: auto;
    box-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 13px 19px 2px rgb(0 0 0 / 14%), 0 5px 24px 4px rgb(0 0 0 / 12%);
    background-color: white;
  }
`;

export const DialogSection = styled.div<{ flex?: boolean }>`
  padding: 24px 32px;
  ${({ flex }) => (flex ? 'display: flex;' : '')}

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
`;
