import React from 'react';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { WidthProps } from 'styled-system';
import { getStyledWidth, getStyledLeft, getStyledRight } from '../../../../common/styledSystem/styledFunctions';
import { NavScrollButtonProps } from '.';
import { Box } from '../../../Box';

const ScrollButton: React.FC<NavScrollButtonProps & { children: any; width: WidthProps['width']; offset: any }> = ({ direction, children, offset, width, ...props }) => {
  delete (props as any).right;
  delete (props as any).left;
  return (
    <Box as="button" {...props} type="button">
      {children}
    </Box>
  );
};

export const StyledNavScrollButton: any = styled(ScrollButton).attrs(props => ({
  [props.direction]: props.offset
}))`
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  position: absolute;
  height: 100%;
  padding: 0;
  background-color: white;
  outline: none;
  ${getStyledLeft}
  ${getStyledRight}
  ${getStyledWidth}
`;
