import React from 'react';
import { TitleBarWrapper, TitleText } from './SectionTitleBar.styles';

interface Props
  extends Readonly<{
    title: string;
  }> {}

export const SectionTitleBar: React.FC<Props> = props => {
  const { title, children } = props;
  return (
    <TitleBarWrapper>
      <TitleText>{title}</TitleText>
      {children}
    </TitleBarWrapper>
  );
};
