import React, { useState } from 'react';
import { useTranslation } from '@shared/core';
import { DiscountTag, EasyReturns, PriceMatchPromise, Shipping } from '@withjoy/joykit/icons';

export type ValueProposition = {
  title: string;
  description: string;
  ctaText: string;
  label: string;
  icon: React.ReactNode;
};

export const useValuePropositionAdvertisement = () => {
  const { t2 } = useTranslation('adminDashboardV2');
  const translations = t2('valuePropositions');
  const [value] = useState<ValueProposition>(() => {
    const valuePropositions: ValueProposition[] = [
      {
        title: translations.postWeddingDiscount.title,
        description: translations.postWeddingDiscount.description,
        ctaText: translations.general.cta,
        label: 'valueProp_PostDiscount',
        icon: <DiscountTag size={32} />
      },
      {
        title: translations.priceMatchPromise.title,
        description: translations.priceMatchPromise.description,
        ctaText: translations.general.cta,
        label: 'valueProp_PriceMatching',
        icon: <PriceMatchPromise size={32} />
      },
      {
        title: translations.freeShipping.title,
        description: translations.freeShipping.description,
        ctaText: translations.general.cta,
        label: 'valueProp_FreeShipping',
        icon: <Shipping size={32} />
      },
      {
        title: translations.easyReturns.title,
        description: translations.easyReturns.description,
        ctaText: translations.general.cta,
        label: 'valueProp_Returns',
        icon: <EasyReturns size={32} />
      }
    ];
    return valuePropositions[Math.floor(Math.random() * valuePropositions.length)];
  });

  return value;
};
