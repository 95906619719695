export const ecardDefaults = {
  savethedate: {
    additionalLink1: 'calendar',
    additionalLink2: ''
  },
  invitation: {
    additionalLink1: 'calendar',
    additionalLink2: ''
  },
  changethedate: {
    additionalLink1: 'website',
    additionalLink2: ''
  },
  custom: {
    additionalLink1: 'rsvp',
    additionalLink2: 'registry'
  },
  savethedatephoto: {
    additionalLink1: 'calendar',
    additionalLink2: ''
  }
};
