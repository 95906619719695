import { styled, Box, StyleSystemProps } from '@withjoy/joykit';
import { breakpoints } from '@shared/utils/style/breakpoints';

export const MeWrapper = styled(Box)`
  max-width: 1280px;
  padding-bottom: 120px;
  margin: auto;
  & > div {
    margin-bottom: 64px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export const MeWrapperStyles: StyleSystemProps = {
  paddingTop: 0,
  paddingBottom: 7,
  paddingX: {
    _zero: 6,
    sm2: 8,
    md3: '80px'
  }
};

export const EventCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 32px;
  row-gap: 32px;
  @media (min-width: ${breakpoints.sm2}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: ${breakpoints.md3}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const EventCardSectionStyles: StyleSystemProps = {
  marginBottom: 10
};
