/* ResponsiveMenu
    Description: 
        A component that wraps around custom component StyledMenuContent and JoyKit DrawerV2. 
        It will render a Drawer on mobile, and a dropdown menu on desktop/tablet.  

    Caveats: 
        Currently the dropdown menu is triggered by a root with is contained within ResponsiveMenu. Ideally this would be configurable as to what should trigger the dialog opening.
        For the sake of time this is currently hardcoded as the set of dots that appear on EventCards on /account/events. Additionally this is currently scoped to accountMe - 
        in the future this can be further abstracted to accept a ref to use as a trigger, similar to the joykit implementation for Popover component

    References:
        This component was inspired by the implementation of the NavigationItem on the admin dashbaord by @Fabian-with-joy 
*/

import React, { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { DrawerV2, DrawerV2Props, Divider, TextV2, CloseButton, Box } from '@withjoy/joykit';
import { useOuterClick } from '@shared/utils/hooks/useOuterClick';
import { ListButtonLabel, ItemList } from './ResponsiveMenu.styles';
import { IconV2Props } from '@withjoy/joykit';
import { useRecalculateMenuPosition } from '@shared/utils/hooks/useRecalculateMenuPosition';
import { styles, StyledMenuContent, StyledDotDiv, ListDiv, DialogBody } from './ResponsiveMenu.styles';
import { closeButtonOverrides, DialogHeader, drawerOverrides } from '@apps/admin/routes/Dashboard/common/commonDialog.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { ReactComponent as VerticalDots } from '@assets/icons/icon-vertical-dots.svg';
import { IconSize } from '@withjoy/joykit/components/IconV2/Icon.types';

interface ResponsiveMenuProps {
  actions: { onClick: () => void; title: string; path?: string; icon?: React.ComponentType<IconV2Props>; isDisabled?: boolean }[];
  title?: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  children?: React.ReactNode;
  drawerOverrides?: DrawerV2Props['overrides'];
  lastOptionNeutralStyle: boolean;
  /**
   * If usePortal is true, the menu will be rendered in a portal. This is useful when the menu is not positioned on top of the trigger.
   * 'portalContainer' can be passed when 'usePortal' is true to specify the container where the portal should be rendered. Default is 'document.body'.
   */
  usePortal?: boolean;
  /**
   * If portalContainer is provided, the portal will be rendered in the specified container. Default is 'document.body'.
   */
  portalContainer?: HTMLElement;
  horizontalIcon?: boolean;
  iconSize?: IconSize | number;
  isDisabled?: boolean;
}

interface HandleMenuItemClickProps {
  action: { onClick: () => void };
  e: React.MouseEvent;
}

export const ResponsiveMenu: React.FC<ResponsiveMenuProps> = ({
  actions,
  title,
  isOpen,
  onToggle,
  children,
  lastOptionNeutralStyle,
  usePortal = false,
  portalContainer,
  horizontalIcon = false,
  iconSize = 'sm',
  isDisabled = false
}) => {
  const navigationMenuRef = useRef<HTMLDivElement>(null);
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const menuRef = useOuterClick(onToggle, !isMobile && isOpen && !usePortal);
  const drawerRef = useOuterClick(onToggle, isMobile && isOpen);
  const portalRef = useOuterClick(onToggle, usePortal && !isMobile && isOpen);
  const customDefaultPosition = { x: -4.49, y: 1.2 };
  const { positionMenu, recalculateMenuPosition } = useRecalculateMenuPosition(navigationMenuRef, menuRef, customDefaultPosition);

  const handleRootClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle();
    recalculateMenuPosition();
  };

  const handleMenuItemClick = ({ e, action }: HandleMenuItemClickProps) => {
    if (!isDisabled) {
      action.onClick();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const content = actions?.map(action => {
    return (
      <div key={action.title} style={{ cursor: action.isDisabled || isDisabled ? 'not-allowed' : 'auto' }}>
        <ListDiv isOverflowOpen={isOpen} isDisabled={action.isDisabled || isDisabled} onClick={(e: React.MouseEvent) => handleMenuItemClick({ e, action })}>
          <ListButtonLabel>
            {action.icon && <action.icon size={iconSize} />}
            {action.title}
          </ListButtonLabel>
        </ListDiv>
      </div>
    );
  });

  const renderItemList = useCallback(() => {
    return (
      <ItemList
        isOpen={isOpen}
        ref={navigationMenuRef}
        position={positionMenu}
        isDisabled={isDisabled}
        isLastOptionDisabled={!!actions[actions.length - 1].isDisabled}
        lastOptionNeutralStyle={lastOptionNeutralStyle}
      >
        {title && (
          <TextV2 typographyVariant="label3" tagName="h6" padding={3} textAlign="left">
            {title}
          </TextV2>
        )}
        {content}
      </ItemList>
    );
  }, [actions, content, isDisabled, isOpen, lastOptionNeutralStyle, positionMenu, title]);

  return (
    <>
      <StyledDotDiv __css={styles.dotDiv} ref={menuRef} backgroundColor="" onClick={(e: React.MouseEvent) => handleRootClick(e)}>
        <VerticalDots css={{ ...(horizontalIcon && { rotate: '90deg' }) }} />
        <StyledMenuContent __css={styles.menuContent} onClick={recalculateMenuPosition}>
          {usePortal ? createPortal(<Box ref={portalRef}>{renderItemList()}</Box>, portalContainer || document.getElementsByTagName('body')[0]) : renderItemList()}
        </StyledMenuContent>
      </StyledDotDiv>

      {isMobile && (
        <DrawerV2 disableAutoFocus enableReturnFocusOnClose useBackdrop={false} overrides={drawerOverrides} isOpen={isOpen} anchor="bottom">
          <div ref={drawerRef}>
            {title && (
              <>
                <DialogHeader>
                  <TextV2 typographyVariant="hed3" tagName={'h6'} padding={0}>
                    {title}
                  </TextV2>
                  <CloseButton overrides={closeButtonOverrides} onClick={onToggle} />
                </DialogHeader>
                <Divider />
              </>
            )}
            <DialogBody isDisabled={isDisabled} isLastOptionDisabled={!!actions[actions.length - 1].isDisabled}>
              {children}
              {content}
            </DialogBody>
          </div>
        </DrawerV2>
      )}
    </>
  );
};
