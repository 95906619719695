import React, { useCallback, useRef, useState } from 'react';
import { useDisclosure } from '@withjoy/joykit';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFeatureValue } from '@shared/core/featureFlags';
import { getLocalStorage } from '@shared/core';
import { RestrictedInternationalShippingWarningDialog } from './RestrictedInternationalShippingWarningDialog';
import { StationeryTemplateIdentifiers } from './types';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

const KEY = 'printGalleryConfirmedUsShippingAddress';
const TIMESTAMP_KEY = 'printGalleryConfirmedUsShippingAddressAt';

const localStorage = getLocalStorage();

type RestrictedInternationalShippingWarningContext = {
  /**
   * If the user is restricted based on the printInternationalShipping FF, show a warning dialog.
   * Else, call the nextDialogCallback.
   */
  checkIfUserIsRestricted: (templateIdentifiers: StationeryTemplateIdentifiers, onShowWarningDialog: () => void, nextDialogCallback?: () => void) => void;
};

const [Provider, useRestrictedInternationalShippingWarningContext] = createContext<RestrictedInternationalShippingWarningContext>({
  name: 'RestrictedInternationalShippingWarning'
});

const RestrictedInternationalShippingWarningProvider: React.FC<{ eventId: string | undefined }> = ({ children, eventId }) => {
  // In public shop, user may not be logged in and so there's no event set.
  const localStorageKeyByEventId = eventId ? `${eventId}-${KEY}` : KEY;
  const printGalleryConfirmedUsShippingAddress = localStorage.getItem(localStorageKeyByEventId);
  // Using localstorage here to avoid to show the warning dialog more than once per event if the user decides to move forward
  const { value } = useFeatureValue('printInternationalShipping', { skip: printGalleryConfirmedUsShippingAddress === 'true' });
  const { restrictedInternationalShippingWarningDialogConfirmClicked, restrictedInternationalShippingWarningDialogDismiss } = useCardTelemetry();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isUserRestricted = value === 'restricted';

  // Store the nextDialogCallback in a ref so that it can be called when the user
  // confirms they want to move forward in the warning dialog
  const nextDialogCallbackRef = useRef<(() => void) | null | undefined>(null);

  const [currentTemplateIdentifiers, setCurrentTemplateIdentifiers] = useState<StationeryTemplateIdentifiers | null>(null);

  const checkIfUserIsRestricted = useCallback<RestrictedInternationalShippingWarningContext['checkIfUserIsRestricted']>(
    (templateIdentifiers, onShowWarningDialog, nextDialogCallback) => {
      // If the user is restricted, show the dialog
      if (isUserRestricted) {
        // Store the nextDialogCallback in a ref so that it can be called after the dialog is closed
        // and the user confirms they want to move forward
        nextDialogCallbackRef.current = nextDialogCallback;
        setCurrentTemplateIdentifiers(templateIdentifiers);
        onShowWarningDialog();
        onOpen();
        return;
      }

      // If the user is not restricted, move forward
      nextDialogCallback?.();
    },
    [isUserRestricted, onOpen]
  );

  const handleOnClick = useEventCallback(() => {
    // Saving the keys into the localstorage to avoid to show the warning dialog more than once per event
    // if the user decides to move forward
    localStorage.setItem(localStorageKeyByEventId, 'true');
    localStorage.setItem(TIMESTAMP_KEY, new Date().toISOString());
    onClose();
    currentTemplateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogConfirmClicked({ themeId: currentTemplateIdentifiers.themeId });
    // Call the nextDialogCallback that we collected when we determined that the user was restricted
    // and should see the dialog
    nextDialogCallbackRef.current?.();
  });

  const handleOnClose = () => {
    currentTemplateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogDismiss({ themeId: currentTemplateIdentifiers.themeId });
    onClose();
  };

  return (
    <Provider value={{ checkIfUserIsRestricted }}>
      {children}
      {/* Only show the dialog if the user is restricted */}
      {isUserRestricted && (
        <RestrictedInternationalShippingWarningDialog
          isOpen={isOpen}
          templateIdentifiers={currentTemplateIdentifiers}
          handleOnClose={handleOnClose}
          handleOnClick={handleOnClick}
        />
      )}
    </Provider>
  );
};

export { RestrictedInternationalShippingWarningProvider, useRestrictedInternationalShippingWarningContext };
