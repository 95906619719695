import { addRendition } from '@shared/utils/photoRendition';
import React, { useCallback } from 'react';
import { ReactZoomPanPinchRef, TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ScaleSlider from '../components/PhotoECardPreview/components/ScaleSlider';

interface UseAdjustableImageArgs {
  isAdjustingPosition?: boolean;
  imageUrl: string;
  adjustmentRef?: React.RefObject<ReactZoomPanPinchRef>;
  positionX?: number | null;
  positionY?: number | null;
  photoWidth?: number;
  photoHeight?: number;
  photoScale?: number;
  setIsPanningPhoto: (value: boolean) => void;
}

interface AdjustableImageProps {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
}

const useAdjustableImage = ({ imageUrl, adjustmentRef, positionX, positionY, photoScale = 1, photoHeight, photoWidth, setIsPanningPhoto }: UseAdjustableImageArgs) => {
  const changeScale = useCallback(
    (width: number, height: number) => (scale: number) => {
      if (adjustmentRef?.current) {
        const state = adjustmentRef.current.state;
        const prevScale = state.scale;
        const scaleDifference = scale - prevScale;

        const centerX = (width / 2 - state.positionX) / prevScale;
        const centerY = (height / 2 - state.positionY) / prevScale;

        const calculatedPositionX = state.positionX - centerX * scaleDifference;
        const calculatedPositionY = state.positionY - centerY * scaleDifference;

        const boundedX = -Math.max(0, Math.min(-calculatedPositionX, photoWidth! * scale - width));
        const boundedY = -Math.max(0, Math.min(-calculatedPositionY, photoHeight! * scale - height));

        adjustmentRef.current.setTransform(boundedX, boundedY, scale, 0);
      }
    },
    [adjustmentRef, photoHeight, photoWidth]
  );

  const AdjustableImage = useCallback(
    ({ width = 500, height = 700, top = 0, left = 0 }: AdjustableImageProps) => (
      <TransformWrapper
        ref={adjustmentRef}
        disablePadding
        initialPositionX={positionX as number}
        initialPositionY={positionY as number}
        initialScale={photoScale}
        minScale={1}
        maxScale={3}
        onPanningStart={() => setIsPanningPhoto(true)}
        onPanningStop={() => setIsPanningPhoto(false)}
      >
        {({ state }) => (
          <>
            <TransformComponent wrapperStyle={{ width, height, top, left, cursor: 'move', position: 'absolute' }}>
              <img src={addRendition({ url: imageUrl, renditionSize: 'medium' })} alt="couple" width={photoWidth} height={photoHeight} />
            </TransformComponent>
            <ScaleSlider value={state.scale} onChange={changeScale(width, height)} />
          </>
        )}
      </TransformWrapper>
    ),
    [adjustmentRef, positionX, positionY, photoScale, setIsPanningPhoto, imageUrl, photoWidth, photoHeight, changeScale]
  );

  return { AdjustableImage };
};

export default useAdjustableImage;
