import React from 'react';
import { Flex } from '@withjoy/joykit';
import { useHistory } from '@react-router';
import { useTranslation } from '@shared/core';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { EcardType } from '@graphql/generated';
import Type from './components/Type';
import { getReferrer } from '@apps/postOffice/PostOffice';
import { useFeatureValue } from '@shared/core/featureFlags';

interface TypeSelectorProps
  extends Readonly<{
    eventHandle: string;
  }> {}

const ASSETS_BASE_URL = 'https://d2uft7zh7kxc3y.cloudfront.net/save-the-dates';

export const TypeSelector: React.FC<TypeSelectorProps> = ({ eventHandle }) => {
  const history = useHistory();
  const { t } = useTranslation('emailsAndEcards');
  const tEcardLandingLeftPane = t('ecardLanding', 'ecardLandingLeftPane');
  const isNewPrintExperienceEnabled = useFeatureValue('enableNewPrintExperience').value === 'on';
  const telemetry = useEcardsTelemetry();

  const handleOnClickArt = () => {
    telemetry.designAndSendClick(EcardType.savethedate);
    history.push(`${EcardType.savethedate}/design?referrer=${getReferrer()}`);
  };

  const handleOnClickPhoto = () => {
    telemetry.designAndSendClick(EcardType.savethedatephoto);
    history.push(`/${eventHandle}/edit/${EcardType.savethedate}/design?referrer=${getReferrer()}`);
  };

  const handleOnClickPrint = () => {
    history.push(`/${eventHandle}/edit/cards?ctgy=saveTheDate`);
  };

  const ArtType = () => (
    <Type
      leftImageUrl={`${ASSETS_BASE_URL}/art-1.jpg`}
      centerImageUrl={`${ASSETS_BASE_URL}/art-2.jpg`}
      rightImageUrl={`${ASSETS_BASE_URL}/art-3.jpg`}
      title={tEcardLandingLeftPane.landingArtTitle()}
      subtitle={tEcardLandingLeftPane.landingArtSubTitle()}
      buttonTitle={tEcardLandingLeftPane.landingArtButtonTitle()}
      buttonCallback={handleOnClickArt}
    />
  );

  const PhotoType = () => (
    <Type
      leftImageUrl={`${ASSETS_BASE_URL}/photo-1.jpg`}
      centerImageUrl={`${ASSETS_BASE_URL}/photo-2.jpg`}
      rightImageUrl={`${ASSETS_BASE_URL}/photo-3.jpg`}
      title={tEcardLandingLeftPane.landingPhotoTitle()}
      subtitle={tEcardLandingLeftPane.landingPhotoSubTitle()}
      buttonTitle={tEcardLandingLeftPane.landingPhotoButtonTitle()}
      buttonCallback={handleOnClickPhoto}
    />
  );

  const PaperType = () => (
    <Type
      imageUrl="https://withjoy.com/assets/public/paper-savethedate-charm.png"
      title={tEcardLandingLeftPane.landingPrintTitle()}
      subtitle={tEcardLandingLeftPane.landingPrintSubTitle()}
      buttonTitle={tEcardLandingLeftPane.landingPrintButtonTitle()}
      buttonCallback={handleOnClickPrint}
    />
  );

  return (
    <Flex flexDirection={['column', null, 'row']} paddingTop={[8, null, 0]} alignItems="center" flexWrap="wrap" justifyContent="center" width="fit-content" gap="4rem">
      <PhotoType />
      <ArtType />
      {isNewPrintExperienceEnabled && <PaperType />}
    </Flex>
  );
};
