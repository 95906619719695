import { DrawerV1Props, DrawerDimension } from './Drawer.types';
import { Position } from '../../common';

const isPullDirection = (position: DrawerV1Props['position']) => {
  return position === Position.TOP || position === Position.LEFT;
};

export const getBorderCorners = (position: DrawerV1Props['position']): ('top-left' | 'top-right' | 'bottom-left' | 'bottom-right')[] => {
  switch (position) {
    case Position.TOP:
      return ['bottom-left', 'bottom-right'];
    case Position.BOTTOM:
      return ['top-left', 'top-right'];
    case Position.LEFT:
      return ['top-right', 'bottom-right'];
    case Position.RIGHT:
      return ['top-left', 'bottom-left'];
    default:
      return [];
  }
};

export const getTransitionValues = (
  position: DrawerV1Props['position']
): {
  from: { translate: string };
  enter: { translate: string };
  leave: { translate: string };
} => {
  // const translateProperty = isVerticalPosition(position as Position) ? 'translateY' : 'translateX';
  const startAndEndValue = isPullDirection(position) ? '-100%' : '100%';
  // const startAndEndTransform = `${translateProperty}(${startAndEndValue})`;
  return {
    from: { translate: startAndEndValue },
    enter: { translate: '0%' },
    leave: { translate: startAndEndValue }
  };
};

export const getDraweDimensionValue = (dimension: DrawerDimension) => {
  return typeof dimension === 'string' ? dimension : `${dimension}px`;
};

export const getInitialAxisPlacement = (position: DrawerV1Props['position']) => {
  switch (position) {
    case 'top': {
      return {
        top: 0,
        left: 0,
        right: 0
      };
    }

    case 'bottom': {
      return {
        left: 0,
        bottom: 0,
        right: 0
      };
    }
    case 'left': {
      return {
        top: 0,
        left: 0,
        bottom: 0
      };
    }
    case 'right':
    default: {
      return {
        top: 0,
        right: 0,
        bottom: 0
      };
    }
  }
};
