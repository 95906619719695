import { UpdatedData } from '@apps/registry/admin/routes/YourRegistry/components/PageSettingsDialog/PageSettingsDialog.provider';
import { createTelemetry, createTelemetryObject } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { FeaturedProduct } from './components/DashboardContentV2/MultiProductPromo/types';
import { ReEngagementPopupItemTypes } from './components/DashboardContentV2/ReEngagementPopup/constants/ReEngagementPopup.const';

const category = 'AdminDashboard';
const pagePrefix = 'admin';

export type PagesForTelemetry =
  | 'dashboard'
  | 'productSelector'
  | 'visibilityDialog'
  | 'travelDialog'
  | 'wonBookingDialog'
  | 'printDialog'
  | 'multiProductPromoDialog'
  | 'reEngagementPopup';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  dashboard: ({ dashboardVersion }: { dashboardVersion: string }) => ({
    category,
    pagePrefix,
    page: 'Dashboard',
    action: 'AdminVisited',
    extraPageInfo: {
      dashboardVersion
    }
  }),
  productSelector: () => ({
    category,
    pagePrefix,
    page: 'EventProductSettingsDialog',
    action: 'ViewProductSelector'
  }),
  visibilityDialog: (pageName: string) => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: pageName,
    action: 'VisibilityDialog'
  }),
  travelDialog: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'hotelawarenessdialog',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.hotelawarenessdialog'
    }
  }),
  wonBookingDialog: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'wonhotelbookingdialog',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.hotelbookingwondialog'
    }
  }),
  printDialog: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'printawarenessdialog',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.printawarenessdialog'
    }
  }),
  multiProductPromoDialog: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'multiProductPromoDialog',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.multiProductPromoDialog'
    }
  }),
  reEngagementPopup: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'engagement_popup',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.engagement_popup'
    }
  })
};

export const dashboardTelemetry = createTelemetryObject({
  actions: {
    openPage: ({ label, section, categoryArg, pageSlug }: { label: string; section: string; categoryArg?: string; pageSlug?: string }) => ({
      action: 'OpenPage',
      category: categoryArg ? categoryArg : category,
      actionType: 'click',
      label,
      extraInfo: {
        section,
        pageSlug
      }
    }),
    visibilityUpdated: ({ visibility, visibilityChanged, eventPasswordChanged, label }: UpdatedData & { label: string }) => ({
      action: 'Updated',
      page: 'visibilityDialog',
      category,
      label,
      extraInfo: {
        visibility,
        visibilityChanged,
        eventPasswordChanged
      }
    }),
    shareClicked: () => ({
      action: 'Share',
      category,
      actionType: 'click',
      extraInfo: {
        section: 'EventSettings'
      },
      label: 'event'
    }),
    productSelectionSave: (selectedOptions: string[], removedOptions: string[], addedOptions: string[]) => ({
      action: 'EventProductSettingsUpdated',
      category,
      extraInfo: {
        selectedOptions,
        removedOptions,
        addedOptions
      },
      label: 'saved'
    }),
    valuePropositionClicked: (label?: string) => ({
      category: 'AdminDashboard',
      action: 'OpenPage',
      section: 'Registry',
      actionType: 'click',
      label
    }),
    bookingBannerCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'Learn More',
      extraInfo: {
        name: 'adminRoomBlockCTA'
      }
    }),
    bookingBannerDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRoomBlockDismiss',
      extraInfo: {
        name: 'adminRoomBlockDismiss'
      }
    }),
    bookingDialogCTAClicked: (action: 'Remind' | 'Done' | '' = '') => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'RoomBlock',
      extraInfo: {
        name: `adminRoomBlockCTA${action}_popup`
      }
    }),
    bookingDialogDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRoomBlockDismiss',
      extraInfo: {
        name: 'adminRoomBlockDismiss_popup'
      }
    }),
    registryDialogCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'RegistryBlock',
      extraInfo: {
        name: `adminRegistryBlockCTA_popup`
      }
    }),
    registryDialogDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRegistryBlockDismiss',
      extraInfo: {
        name: 'adminRegistryBlockDismiss_popup'
      }
    }),
    registryDialogRemindLaterClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRegistryRemindLater',
      extraInfo: {
        name: 'adminRegistryRemindLater_popup'
      }
    }),
    /**
     * To Iterable
     */
    bookingDialogReminder: (days: number) => ({
      category: 'RoomBlock',
      action: 'RemindMeLater',
      label: 'BtnConfirm',
      extraInfo: {
        method: 'email',
        reminderDaysDelay: days,
        campaignName: 'RoomBlock'
      }
    }),
    retargetDialogCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'Learn More',
      extraInfo: {
        name: 'adminRoomBlockCTA_retargetpopup'
      }
    }),
    retargetDialogDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRoomBlockDismiss',
      extraInfo: {
        name: 'adminRoomBlockDismiss_retargetpopup'
      }
    }),
    printEarlyAccessDialogCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'Explore Designs',
      extraInfo: {
        name: 'printEarlyAccessDialog_exploredesigns'
      }
    }),
    printEarlyAccessDialogDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRoomBlockDismiss',
      extraInfo: {
        name: 'printEarlyAccessDialog_dismissdialog'
      }
    }),
    // Won Booking
    wonBookingDialogDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminWonRoomBlockDismiss',
      extraInfo: {
        name: 'adminWonRoomBlockDismiss_popup'
      }
    }),
    wonBookingDialogCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'viewRoomBlocks',
      extraInfo: {
        name: `adminWonRoomBlockCTA_popup`
      }
    }),
    wonBookingBannerCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'viewRoomBlocks',
      extraInfo: {
        name: 'adminWonRoomBlockCTA_banner'
      }
    }),
    wonBookingBannerDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminWonRoomBlockDismiss',
      extraInfo: {
        name: 'adminWonRoomBlockDismiss_banner'
      }
    }),
    addShippingAddressButtonBannerClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'addShippingAddressButtonBanner'
    }),

    // Product Promo Dialog + Banner

    multiProductPromoDialogFeatureQuickViewClicked: (currentFeaturePromo: FeaturedProduct, nextFeaturePromo: FeaturedProduct) => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'multiProductPromo',
      extraInfo: {
        name: 'dialog_featureQuickViewClicked',
        currentFeaturePromo,
        nextFeaturePromo
      }
    }),
    multiProductPromoDialogNextCtaClicked: (currentFeaturePromo: FeaturedProduct) => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'multiProductPromo',
      extraInfo: {
        name: 'dialog_nextCtaClicked',
        currentFeaturePromo
      }
    }),
    multiProductPromoBannerOpenDrawer: (featureName: FeaturedProduct) => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'multiProductPromo',
      extraInfo: {
        name: 'banner_openDrawer',
        featureName
      }
    }),
    multiProductPromoBannerCloseDrawer: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'multiProductPromo',
      extraInfo: {
        name: 'banner_closeDrawer'
      }
    }),
    multiProductPromoDismissClicked: (source: 'dialog' | 'banner', currentFeaturePromo: FeaturedProduct | null) => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'multiProductPromo',
      extraInfo: {
        name: `${source}_dismiss`,
        currentFeaturePromo
      }
    }),
    multiProductPromoRedirectClicked: (source: 'dialog' | 'banner', featureName: FeaturedProduct) => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'multiProductPromo',
      extraInfo: {
        name: `${source}_featureCtaClicked`,
        featureName
      }
    }),

    // Re-engagement Popup
    reEngagementPopupCTAClicked: (popupType: ReEngagementPopupItemTypes, action: 'clicked' | 'dismissed') => ({
      category: 'hotelBlockConfirmationAbandonment',
      action: 'engagementPopupInteracted',
      label: 'engagement_popup',
      extraInfo: {
        name: action,
        popupType: popupType
      }
    }),
    printReEngagementPopupCTAClicked: (name: 'viewDrafts' | 'editDraft', action: 'clicked' | 'dismissed') => ({
      category: 'AdminDashboard',
      action: 'engagementPopupInteracted',
      label: 'engagement_popup',
      extraInfo: {
        name: `${name}_${action}`,
        popupType: 'print'
      }
    }),
    addNewPageOpen: () => ({
      action: 'WebsiteAdminButtonInteracted',
      category: 'website',
      pagePrefix: 'admin',
      label: 'Open Add New Page Dialog',
      extraInfo: {
        name: 'openAddNewPageDialog'
      }
    })
  },
  pages
});

const {
  TelemetryProvider: DashboardTelemetryProvider,
  useTelemetry: useDashboardTelemetry,
  enrichTelemetryExtraInfo: enrichDashboardTelemetryExtraInfo
} = createTelemetry(dashboardTelemetry, { eventId: '' });

type DashboardTelemetry = ReturnType<typeof useDashboardTelemetry>;

export { DashboardTelemetryProvider, useDashboardTelemetry, DashboardTelemetry, enrichDashboardTelemetryExtraInfo };
