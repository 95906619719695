/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chevron Right',
  definitions: {
    '16': {
      viewBox: '0 0 17 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5127 7.57196C12.7522 7.80711 12.7522 8.19293 12.5127 8.42809L7.23187 13.6147C7.01075 13.8318 6.65942 13.8413 6.42688 13.6364C6.16877 13.409 6.15865 13.0101 6.40489 12.7698L11.294 8.00002L6.40489 3.23023C6.15865 2.99 6.16877 2.59106 6.42688 2.36362C6.65942 2.15871 7.01075 2.16821 7.23187 2.38539L12.5127 7.57196Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
