import { useTranslation } from '@shared/core';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { useToast } from '@withjoy/joykit';

interface Args
  extends Readonly<{
    eventId?: string;
    onClose: () => void;
  }> {}

export const useDeleteEventSuccessController = (args: Args) => {
  const { onClose } = args;
  const { t2 } = useTranslation('account');
  const tDeleteEvent = t2('deleteEvent');
  const tSuccessToast = tDeleteEvent.successToast;
  const { trackDeleteEventFeedbackClick } = useAccountMeTelemetry();

  const { toast } = useToast();

  const handleDismissDeleteEventSuccessDialog = () => {
    onClose();
  };

  const handleDeleteEventSuccessConfirmed = (eventId?: string, feedback?: string) => {
    if (feedback) {
      trackDeleteEventFeedbackClick(feedback, eventId);
    }

    onClose();
    toast(tSuccessToast);
  };

  return {
    handleDismissDeleteEventSuccessDialog,
    handleDeleteEventSuccessConfirmed
  };
};
