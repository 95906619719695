import { useVerifyAccountEmailMutation } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import { useOnParam } from './useOnParam';
import safeUrlToRedirect from '@shared/utils/safeUrlToRedirect';
import { useReturnUrlParam, RETURN_URL_PARAM_KEY } from './useReturnUrlParam';
import { useEffect, useState } from 'react';
import { config } from '@static/js/env.config';
import { useAccountTelemetry } from '@apps/account/account.telemetry';

interface Args extends Readonly<{}> {}

const DEFAULT_RESULT_URL = config.clientUri + '/account/personal-info?dialog=verifyEmailSuccess';

export const useVerifyEmailController = (args: Args) => {
  const {} = args;
  const returnUrl = useReturnUrlParam();
  const { verifyEmail, trackResendVerifyEmail } = useAccountTelemetry();
  const safeUrl = returnUrl && safeUrlToRedirect(returnUrl) ? returnUrl : DEFAULT_RESULT_URL;
  const [resent, setResent] = useState<boolean>(false);
  const [sendVerifyEmail, { called: sent, loading: sending, error }] = useVerifyAccountEmailMutation({ variables: { payload: { returnUrl: safeUrl } } });
  const {
    currentUser: { profile }
  } = useAuth();
  const email = profile?.email;

  const handleResendVerificationEmail = () => {
    trackResendVerifyEmail();
    sendVerifyEmail();
    setResent(true);
  };

  useOnParam(
    () => {
      sendVerifyEmail();
    },
    {
      paramKey: RETURN_URL_PARAM_KEY,
      newValue: null,
      checkValue: val => true
    }
  );

  useEffect(() => {
    verifyEmail.enter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sending,
    sent,
    error,
    resent,
    handleResendVerificationEmail,
    email
  };
};
