import React, { useMemo } from 'react';
import { ButtonV2 } from '@withjoy/joykit';
import StyledTopBar from '@shared/components/StyledTopBar';
import { ReactComponent as JoyLogo } from '@assets/joy-logo.svg';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { Search } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { Spacer, TopBarWrapper } from './TopBar.styles';
import { useMeEventUsersEventInfoforMePage } from '@apps/accountMe/hooks';

const TopBar = () => {
  const isMobile = useIsMobileScreen();
  const { t2 } = useTranslation('account');
  const { findEventButton } = t2('eventsPage');
  const { data, loading } = useMeEventUsersEventInfoforMePage();
  const { trackMePageClick } = useAccountMeTelemetry();

  const upcomingEvents = useMemo(() => {
    return data?.me?.eventUsers.filter(eventUser => (eventUser.event?.info.finalizedDate?.milliseconds || Infinity) > Date.now());
  }, [data?.me?.eventUsers]);

  const joyLogoProps = {
    height: isMobile ? 28 : 40,
    width: isMobile ? 45 : 64
  };

  const findEventUrl = useMemo(() => {
    return withWindow(() => {
      const url = new URL(window.location.origin);
      return url.href + 'find';
    }, '');
  }, []);

  const showFindEventButton = !loading && (isMobile || upcomingEvents?.length === 0);

  return (
    <TopBarWrapper display="flex" rowGap="space-between" alignItems="center" position="sticky" top={0} backgroundColor={'white'}>
      <a href="/account/events" onClick={() => trackMePageClick('Home', 'TopBar')}>
        <JoyLogo {...joyLogoProps} />
      </a>
      <Spacer />
      {showFindEventButton && (
        <ButtonV2
          as="a"
          variant="outline"
          shape="rounded"
          intent="neutral"
          href={findEventUrl}
          target="_blank"
          maxHeight="2.5rem"
          minWidth="2.5rem"
          style={{ gap: '0.5rem' }}
          padding={['0 0', '0 1rem', '0 1rem']}
          onClick={() => trackMePageClick('FindAnEvent', 'TopBar')}
        >
          <Search />
          {!isMobile && findEventButton.value}
        </ButtonV2>
      )}
      <StyledTopBar.StyledUserMenu />
    </TopBarWrapper>
  );
};

export default TopBar;
