import { StationeryDraftFormat, useCreateStationeryDraftMutation } from '@graphql/generated';
import { useCardsRouterContext } from '@apps/card/Card.routes';

import { useExistingDraftsWarningContext } from '../ExistingDraftsWarningProvider';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { cloneDeep } from 'lodash-es';
import { ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';

import { Format, MinimalRequiredTemplateDataForDraft } from '../DesignsGallery/DesignsGallery.types';
import { useRestrictedInternationalShippingWarningContext } from '../RestrictedInternationalShippingWarningProvider';

type UseCustomizePremiumDigitalCardArgs = {
  eventId?: string;
  supportedFormats: Format;
};

export const useCustomizePremiumDigitalCard = (args: UseCustomizePremiumDigitalCardArgs) => {
  const { eventId, supportedFormats } = args;
  const { existingDraftsWarningDialog, cardDraftCreated, pdpCustomizeNowClicked, restrictedInternationalShippingWarningDialog } = useCardTelemetry({});

  const { goToDigitalCustomizeDraft } = useCardsRouterContext();
  const { checkForExistingDraftOrCreateNew } = useExistingDraftsWarningContext();
  const { checkIfUserIsRestricted } = useRestrictedInternationalShippingWarningContext();

  const [createStationeryDraft, { loading }] = useCreateStationeryDraftMutation({
    refetchQueries: () => ['GetDigitalAndPaperDraftsByEventId', 'GetExistingEventStationeryDraftsWithTemplate']
  });

  const handleOnCustomizePremiumDigitalCardClick = useEventCallback((template: MinimalRequiredTemplateDataForDraft, subtotalInMinorUnits?: number) => {
    checkIfUserIsRestricted(
      { themeId: template.themeId, category: template.category },
      () => {
        // This callback is called when the RestrictedInternationalShipping warning dialog is about to be shown.
        restrictedInternationalShippingWarningDialog.enter();
      },
      () => {
        checkForExistingDraftOrCreateNew(
          template.id,
          async existingDraftsCount => {
            try {
              pdpCustomizeNowClicked({ themeId: template.themeId, supportedFormats, format: 'premiumDigital' });
              const cardJSON = cloneDeep(template.themeJSON) as ThemeJson;

              const response = await createStationeryDraft({
                variables: {
                  payload: {
                    eventId,
                    cardJSON,
                    stationeryTemplateId: template.id,
                    format: StationeryDraftFormat.digital
                  }
                }
              });
              const maybeDraftId = response.data?.createStationeryDraft.id;

              if (maybeDraftId) {
                cardDraftCreated({
                  draftId: maybeDraftId,
                  themeId: template.themeId,
                  stationeryTemplateCategory: template.category,
                  isFavorite: !!template.isFavorite,
                  page: 'pdpModal',
                  existingDraftsCount,
                  subtotalInMinorUnits,
                  isDigital: true,
                  isPremiumDesign: true,
                  format: 'premiumDigital'
                });
                goToDigitalCustomizeDraft(maybeDraftId);
              }
            } catch (error) {
            } finally {
            }
          },
          () => {
            // This callback is called when the warning dialog is about to be shown.
            existingDraftsWarningDialog.enter();
          },
          true
        );
      }
    );
  });

  return {
    handleOnCustomizePremiumDigitalCardClick,
    loading
  };
};
