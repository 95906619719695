import { useMemo } from 'react';
import { useTranslation } from '@shared/core/i18n';
import { withWindow } from '@shared/utils/withWindow';
import type { HeaderLinksType, RenderLinkProps } from './AppNavBar.types';
import { useMarcomAppTelemetry } from '@shared/components/Marcom/MarcomApp.telemetry';
import { HEADER_LINKS } from '@shared/components/Marcom/AppNavBar/useHeaderLinks';

export const useHeaderLinks = (type?: HeaderLinksType) => {
  const { t2 } = useTranslation('marcom');
  const { wwwNavigate } = useMarcomAppTelemetry();

  const getLinkProps = useMemo(
    () => (id: string, href: string, nodeText: string, target?: string, mobileNodeText?: string): RenderLinkProps => {
      return {
        id,
        href,
        target,
        onClick: () => {
          wwwNavigate({ elementId: id, to: href, nodeText });
        },
        onKeyDown: (e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            wwwNavigate({ elementId: id, to: href, nodeText });
            if (href) {
              withWindow(global => {
                global.document.location.href = href;
              });
            }
          }
        },
        label: nodeText,
        mobileLabel: mobileNodeText
      };
    },
    [wwwNavigate]
  );

  const headerLinksData = HEADER_LINKS.wedding;

  const itemsToRender = useMemo(() => {
    const planInvite = t2('planInvite');
    const registry = t2('registry');
    const expertAdvice = t2('expertAdvice');

    const preparedLinks: Array<RenderLinkProps> = [];

    const linksToRender = type ? headerLinksData[type] : [];

    linksToRender.forEach(({ id, label, mobileLabel, href, target, blogs }) => {
      const preparedBlogLinks: Array<Omit<RenderLinkProps, 'blogs'>> = [];
      const nodeText = label({ planInvite, registry, expertAdvice });
      const mobileNodeText = mobileLabel?.({ planInvite, registry, expertAdvice });
      blogs?.forEach(({ id: blogId, label: blogLabel, href: blogHref, target: blogTarget }) => {
        const nodeTextBlog = blogLabel({ planInvite, registry, expertAdvice });
        preparedBlogLinks.push(getLinkProps(blogId, blogHref, nodeTextBlog, blogTarget));
      });
      preparedLinks.push({ ...getLinkProps(id, href, nodeText, target, mobileNodeText), blogs: preparedBlogLinks });
    });

    return preparedLinks;
  }, [type, t2, getLinkProps, headerLinksData]);

  const allItemsToRender = useMemo(() => {
    const planInvite = t2('planInvite');
    const registry = t2('registry');
    const expertAdvice = t2('expertAdvice');

    const preparedLinks: Array<RenderLinkProps> = [];

    const headerLinks: HeaderLinksType[] = ['planInvite', 'registry', 'expertAdvice'];
    headerLinks.forEach((type: HeaderLinksType) => {
      headerLinksData[type].forEach(({ id, label, mobileLabel, href, target, blogs }) => {
        const preparedBlogLinks: Array<Omit<RenderLinkProps, 'blogs'>> = [];
        const nodeText = label({ planInvite, registry, expertAdvice });
        const mobileNodeText = mobileLabel?.({ planInvite, registry, expertAdvice });
        blogs?.forEach(({ id: blogId, label: blogLabel, href: blogHref, target: blogTarget }) => {
          const nodeTextBlog = blogLabel({ planInvite, registry, expertAdvice });
          preparedBlogLinks.push(getLinkProps(blogId, blogHref, nodeTextBlog, blogTarget));
        });
        preparedLinks.push({ ...getLinkProps(id, href, nodeText, target, mobileNodeText), blogs: preparedBlogLinks });
      });
    });

    return preparedLinks;
  }, [t2, getLinkProps, headerLinksData]);

  return {
    HEADER_LINKS,
    headerLinks: headerLinksData,
    itemsToRender,
    allItemsToRender
  } as const;
};
