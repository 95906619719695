import type { CheckboxV2Props } from '.';
import { animationTransition } from '@shared/utils/animationTransition';
import { Box } from '../Box';
import { styled } from '@withjoy/joykit/theme';

import { anatomy } from '@shared/joykit/packages/core/common/utils/anatomy';
import { MultiPartStyleConfig, PartsStyleObject } from '@shared/joykit/packages/core/common/utils/styleConfig';

export const StyledRoot = styled(Box)``;

export const StyledControl = styled(Box)``;

export const StyledInput = styled(Box)``;

export const StyledLabel = styled(Box)``;

const checkboxAnatomy = anatomy('Checkbox').parts('root', 'control', 'label');

export const visuallyHiddenStyle: React.CSSProperties = {
  border: '0px',
  clip: 'rect(0px, 0px, 0px, 0px)',
  height: '1px',
  width: '1px',
  margin: '-1px',
  padding: '0px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'absolute'
};

const checkboxBaseStyles: PartsStyleObject<typeof checkboxAnatomy> = {
  root: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    position: 'relative',
    _disabled: {
      cursor: 'not-allowed'
    }
  },
  control: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'top',
    userSelect: 'none',
    flexShrink: 0,
    borderRadius: 1,
    size: '1.25rem',
    border: '1px solid',
    borderColor: 'inherit',
    transition: animationTransition('border-color', 'background-color', 'color', 'box-shadow'),
    _checked: {
      backgroundColor: 'primary1',
      borderColor: 'primary7',
      color: 'primary7'
    },
    _indeterminate: {
      backgroundColor: 'primary1',
      borderColor: 'primary7',
      color: 'primary7'
    },
    _disabled: {
      backgroundColor: 'mono2',
      borderColor: 'mono3',
      color: 'mono3'
    },
    _focus: {
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)'
    }
  },
  label: {
    transition: animationTransition('opacity'),
    userSelect: 'none',
    _disabled: { opacity: 0.4 }
  }
};

export const checkboxStyles: MultiPartStyleConfig<CheckboxV2Props, typeof checkboxAnatomy> = {
  parts: checkboxAnatomy.keys,
  baseStyle: checkboxBaseStyles
};
