import { styled, StyleSystemProps, Flex } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledList = styled(Flex)``;
const listStyles: StyleSystemProps = {
  justifyContent: 'center',
  alignItems: 'self-start',
  flexDirection: 'column',
  rowGap: 6
};

export const StyledBlogList = styled(Flex)``;
const blogListStyles: StyleSystemProps = {
  minWidth: pxToRem(240),
  justifyContent: 'center',
  alignItems: 'self-start',
  flexDirection: 'column',
  rowGap: 3
};

export const StyledListItem = styled(Flex)``;
const listItemStyles: StyleSystemProps = {};

export const styles = {
  list: listStyles,
  listItem: listItemStyles,
  blogList: blogListStyles
};
