import React, { useEffect } from 'react';
import { useEmailEditorController } from './EmailEditor.controller';
import EmailPreview from './components/EmailPreview';
import EmailEditPanel from './components/EmailEditPanel';
import type { EmailType, EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { EcardType, EventPageFragment } from '@graphql/generated';
import UnsavedChangesDialog from '@shared/components/EmailsAndEcards/components/Dialogs/UnsavedChangesDialog';
import { withWindow } from '@shared/utils/withWindow';
import { EmailEditorContextProvider } from './EmailEditor.context';

interface EmailEditorProps
  extends Readonly<{
    emailInput: EmailInputFields;
    emailType: EmailType;
    pages?: ReadonlyArray<EventPageFragment>;
    eventPassword?: Maybe<string>;
    isPasswordToggleShown?: boolean;
    loadingDraftData?: boolean;
    updateEmailInput?: (emailInput: EmailInputFields) => void;
    eventDate?: string;
    footerText?: string;
    eventHandle?: string;
    eventDisplayName?: string;
    ecardDraftType?: EcardType;
    isMessageLoading?: boolean;
    recipientName?: string;
    editEmailContentTrack?: (ecardType: EcardType) => void;
  }> {}

export const EmailEditor: React.FC<EmailEditorProps> = ({
  emailInput,
  emailType,
  pages,
  eventPassword,
  isPasswordToggleShown,
  loadingDraftData,
  updateEmailInput,
  eventDate,
  footerText,
  ecardDraftType,
  eventHandle,
  eventDisplayName,
  isMessageLoading,
  recipientName,
  editEmailContentTrack
}) => {
  const {
    handleToggleEmailEditPanel,
    handleCancel,
    isEmailEditPanelShown,
    emailFormik,
    isUnsavedChnangesDialogOpen,
    handleUnsavedChnangesDialogClose,
    handleUnsavedChnangesDialogConfirm
  } = useEmailEditorController({
    emailInput,
    emailType,
    updateEmailInput,
    isMessageLoading,
    editEmailContentTrack,
    ecardDraftType
  });

  useEffect(() => {
    withWindow(global => {
      global.scrollTo(0, 0);
    });
  }, []);

  return (
    <EmailEditorContextProvider>
      <div style={{ height: '100%' }}>
        <EmailPreview
          toggleEmailEditPanel={handleToggleEmailEditPanel}
          formik={emailFormik}
          eventPassword={eventPassword}
          loadingDraftData={loadingDraftData}
          isEmailEditPanelShown={isEmailEditPanelShown}
          emailType={emailType}
          eventDate={eventDate}
          footerText={footerText}
          isPasswordToggleShown={isPasswordToggleShown}
          isMessageLoading={isMessageLoading}
          recipientName={recipientName}
        />
        <EmailEditPanel
          toggleEmailEditPanel={handleToggleEmailEditPanel}
          handleCancelClick={handleCancel}
          isEmailEditPanelShown={isEmailEditPanelShown}
          formik={emailFormik}
          isPasswordToggleShown={isPasswordToggleShown}
          emailType={emailType}
          pages={pages}
          eventDate={eventDate}
          eventHandle={eventHandle}
          eventDisplayName={eventDisplayName}
          ecardDraftType={ecardDraftType}
        />
        <UnsavedChangesDialog
          isOpen={isUnsavedChnangesDialogOpen}
          onClose={handleUnsavedChnangesDialogClose}
          onConfirm={handleUnsavedChnangesDialogConfirm}
          dialogId="unsaved-changes-dialog-email-editor-ecards"
        />
      </div>
    </EmailEditorContextProvider>
  );
};
