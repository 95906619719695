import React from 'react';
import { Box } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { CarouselSlideBaseProps } from './Carousel';

type CarouselImageContainerProps = {} & CarouselSlideBaseProps;

export const CarouselImageContainer = (props: CarouselImageContainerProps) => {
  const { asset, onClick, currentPosition, index } = props;

  if (asset.type !== 'image') {
    return null;
  }

  return (
    <Box
      position="absolute"
      top="0px"
      left="0px"
      overflow="hidden"
      transition={animationTransition('transform')}
      zIndex={3}
      transform={`translateX(${100 * currentPosition}%)`}
      as="img"
      width="100%"
      src={asset.src}
      justifySelf="center"
      onClick={() => onClick?.(index)}
      cursor={onClick ? 'pointer' : undefined}
    />
  );
};
