import { DialogV2Props } from '@withjoy/joykit';

export const PRODUCT_PDP_ROOT_ID = 'product-pdp-root';
export enum CardFormat {
  digital = 'digital',
  paper = 'paper',
  premiumDigital = 'premiumDigital'
}

export const DIALOG_OVERRIDES: DialogV2Props['overrides'] = {
  Root: {
    props: {
      id: PRODUCT_PDP_ROOT_ID
    }
  },
  Body: {
    props: {
      paddingTop: [60, 60, 8],
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 60
    }
  },
  Header: {
    props: {
      borderBottom: '1px solid',
      borderBottomColor: 'mono3',
      marginBottom: { _: 5, sm2: 6 },
      paddingY: { _: 5, sm2: 5 },
      display: 'flex',
      justifyContent: 'center',
      height: { sm2: 72 },
      position: { _: 'relative', sm4: 'sticky' }
    }
  },
  CloseButton: {
    props: {
      top: { sm2: '50%', sm4: '50%' },
      transform: { sm2: 'translateY(-50%)' },
      display: { _: 'none', sm2: 'block' }
    }
  },
  Content: {
    props: {
      alignItems: 'center',
      height: '100%'
    }
  }
};
