import { LinkV2, SpacingStack, styled } from '@withjoy/joykit';

export const InheritSizeLink = styled(LinkV2)`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.gray8};
`;

export const CreateAccountWrapper = styled(SpacingStack)`
  max-width: 360px;
`;

export const FindCoupleLink = styled(LinkV2)`
  &:visited {
    color: #5f269b;
  }
  &:hover {
    color: #7139bf;
  }
  &:active {
    color: #502080;
  }
  text-decoration: none;
  padding-left: 8px;
`;
