import React from 'react';
import { Redirect, Route, Switch } from '@react-router';
import { RoutePath, RoutesProvider, useHistory, useRouterHelper } from '@shared/core';
import { MustBeSignedIn } from '@shared/components/MustBeSignedIn/MustBeSignedIn';
import { Ecard } from './Ecard';
import { useEcardController } from './Ecard.controller';
import { useGetEcardDraftType } from './Ecard.utils';
import { EcardRoutePaths } from './Ecard.types';
import NavBarBottom from '@shared/components/NavBarBottom';
import { Confirm, EmailEditor, Design, EcardLanding } from '@shared/components/EmailsAndEcards';
import { PeopleProvider } from '../../shared/context/people';
import { TelemetryProvider, useEcardsTelemetry } from './Ecard.telemetry';
import { EcardType } from '@graphql/generated';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { BottomSheet, BottomSheetProvider } from '@shared/components/BottomSheet';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import DesignEmailView from '@apps/postOffice/components/DesignEmailView';
import OptionalTestEmailView from '@apps/postOffice/components/OptionalTestEmailView';
import SelectRecipientsView from '@apps/postOffice/components/SelectRecipientsView';
import SendAndTrackView from '@apps/postOffice/components/SendAndTrackView';
import ConfirmSheet from '@apps/postOffice/components/ConfirmSheet';
import MobileSendTestEmailDialog from '@apps/postOffice/components/MobileSendTestEmailDialog';
import DesignECardView from '@apps/postOffice/components/DesignECardView';
import SelectDeliveryMethodView from '@apps/postOffice/components/SelectDeliveryMethodView';
import ShareWithLinkView from '@apps/postOffice/components/ShareWithLinkView';

export const FROM_DASHBOARD_TRUE = 'fromDashboard=true';

export const ecardRoutePaths: EcardRoutePaths = {
  design: {
    path: ':draftType/design',
    goToPath: (draftType: string) => `${draftType}/design`
  },
  email: {
    path: ':draftType/email',
    goToPath: (draftType: string) => `${draftType}/email`
  },
  confirm: {
    path: ':draftType/confirm',
    goToPath: (draftType: string) => `${draftType}/confirm`
  }
};

interface RoutesECardProps {
  eventDisplayName: string;
  ecardDraftType: EcardType;
  eventHandle: string;
  eventId: string;
}

const DesktopRoutesEcard: React.FC<RoutesECardProps> = ({ eventDisplayName, eventHandle, eventId, ecardDraftType }) => {
  const {
    handleSendEmail,
    loadingDraftData,
    eventPassword,
    isPasswordToggleShown,
    pages,
    ecardState,
    updateEmailInput,
    updateEcardInput,
    ecardNavBarTranslations,
    testEmail,
    eventDesign,
    privacyMode,
    eventDate,
    footerText,
    linkOptions,
    emailIsVerified,
    handleCopyShareableLink,
    isCreatingShareableLink,
    aboutShareableLinksClick
  } = useEcardController({
    eventDisplayName,
    eventId,
    ecardDraftType,
    eventHandle
  });
  const isMobile = useIsMobileScreen();
  const { getValueString } = useQueryParamHelper();
  const fromDashboard = getValueString('fromDashboard');
  const routeHelpers = useRouterHelper();
  const { editCardDesignTrack, editCardContentTrack, editEmailContentTrack, confirmSelectGuestsTrack, confirmTestEmailTrack, confirmAndSendTrack } = useEcardsTelemetry();

  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(ecardRoutePaths.design.path),
      component: () => (
        <Design
          ecardInput={ecardState.ecardInput}
          updateEcardInput={updateEcardInput}
          eventDesign={eventDesign}
          eventHandle={eventHandle}
          privacyMode={privacyMode}
          eventPassword={eventPassword}
          linkOptions={linkOptions}
          eventDisplayName={eventDisplayName}
          editCardDesignTrack={editCardDesignTrack.enter}
          editCardContentTrack={editCardContentTrack.enter}
        />
      )
    },
    {
      path: routeHelpers.buildPath(ecardRoutePaths.email.path),
      component: () => (
        <EmailEditor
          eventPassword={eventPassword}
          loadingDraftData={loadingDraftData}
          isPasswordToggleShown={isPasswordToggleShown}
          pages={pages}
          emailType="ecard"
          emailInput={ecardState.emailInput}
          updateEmailInput={updateEmailInput}
          eventDate={eventDate}
          footerText={footerText}
          eventHandle={eventHandle}
          ecardDraftType={ecardDraftType}
          editEmailContentTrack={editEmailContentTrack.enter}
        />
      )
    },
    {
      path: routeHelpers.buildPath(ecardRoutePaths.confirm.path),
      component: () => (
        <Confirm
          handleCopyShareableLink={handleCopyShareableLink}
          isCreatingShareableLink={isCreatingShareableLink}
          eventHandle={eventHandle}
          handleSendEmail={handleSendEmail}
          draftType={ecardDraftType}
          testEmail={testEmail}
          emailIsVerified={emailIsVerified}
          aboutShareableLinksClick={label => aboutShareableLinksClick(label, ecardDraftType)}
          confirmSelectGuestsTrack={confirmSelectGuestsTrack.enter}
          confirmTestEmailTrack={confirmTestEmailTrack.enter}
          confirmAndSendTrack={confirmAndSendTrack.enter}
        />
      )
    }
  ];

  const fromDashboardAppendix = fromDashboard === 'true' ? `?${FROM_DASHBOARD_TRUE}` : '';
  const routesWithLabels = [
    {
      path: routeHelpers.buildPath(ecardRoutePaths.design.goToPath(ecardDraftType) + fromDashboardAppendix),
      label: ecardNavBarTranslations.design,
      value: 'design'
    },
    {
      path: routeHelpers.buildPath(ecardRoutePaths.email.goToPath(ecardDraftType) + fromDashboardAppendix),
      label: ecardNavBarTranslations.email,
      value: 'email'
    },
    {
      path: routeHelpers.buildPath(ecardRoutePaths.confirm.goToPath(ecardDraftType) + fromDashboardAppendix),
      label: ecardNavBarTranslations.confirm,
      value: 'confirm'
    }
  ];

  return (
    <>
      <Switch>
        <Route
          exact
          path={routeHelpers.buildPath(':draftType')}
          render={() =>
            loadingDraftData ? (
              <JoyLogoLoader loaderKey="ecard-draft" />
            ) : eventDesign || ecardDraftType === EcardType.savethedate ? (
              <EcardLanding
                ecardDraftType={ecardDraftType}
                ecardInput={ecardState.ecardInput}
                eventDesign={eventDesign!}
                privacyMode={privacyMode}
                eventPassword={eventPassword}
                eventHandle={eventHandle}
              />
            ) : null
          }
        />
        <Route
          exact
          path={routeHelpers.buildPath(':draftType/:type')}
          render={() =>
            loadingDraftData ? (
              <JoyLogoLoader loaderKey="ecard-draft-type" />
            ) : (
              <Ecard ecardDraftType={ecardDraftType}>
                <Switch>
                  {routes.map(route => (
                    <Route key={route.path} path={route.path} render={route.component} />
                  ))}
                </Switch>
                {!isMobile && <NavBarBottom routes={routesWithLabels} isOpen={true} />}
              </Ecard>
            )
          }
        />
        <Redirect to={routes[0].path} />
      </Switch>
    </>
  );
};

export const RouterEcard: React.FC<{ eventId: string; eventHandle: string; eventDisplayName: string; draftType: string }> = ({
  eventHandle,
  eventDisplayName,
  eventId,
  draftType
}) => {
  const history = useHistory();
  const ecardDraftType = useGetEcardDraftType(draftType);
  const isInEditor = ['/design', '/email', '/confirm'].some(path => history.location.pathname.endsWith(path));

  const handleBottomSheetChange = (route: string) => {
    if (isInEditor && !history.location.pathname.endsWith(route)) {
      history.replace(route);
    }
  };

  return (
    <RoutesProvider appUrl={'ecard'} handle={eventHandle}>
      <MustBeSignedIn>
        <PeopleProvider>
          <TelemetryProvider context={{ eventId }}>
            <BottomSheetProvider
              views={[
                { id: 'card', children: <DesignECardView onVisible={() => handleBottomSheetChange('design')} /> },
                { id: 'email', children: <DesignEmailView onVisible={() => handleBottomSheetChange('email')} /> },
                { id: 'delivery-method', children: <SelectDeliveryMethodView />, disableNextButton: true },
                { id: 'share-with-link', children: <ShareWithLinkView />, backButtonDirection: 'delivery-method', disableNextButton: true },
                { id: 'send-by-email', children: <SelectRecipientsView />, backButtonDirection: 'delivery-method' },
                { id: 'test-email', children: <OptionalTestEmailView /> },
                { id: 'send', children: <SendAndTrackView /> }
              ]}
            >
              {isInEditor && <BottomSheet />}
              <MobileSendTestEmailDialog />
              <ConfirmSheet />
              <DesktopRoutesEcard eventDisplayName={eventDisplayName} eventHandle={eventHandle} eventId={eventId} ecardDraftType={ecardDraftType} />
            </BottomSheetProvider>
          </TelemetryProvider>
        </PeopleProvider>
      </MustBeSignedIn>
    </RoutesProvider>
  );
};
