/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Hamburger',
  definitions: {
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path d="M6 10a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm0 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z" fill="currentColor" />
          <path d="M6 22a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z" fill="currentColor" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
