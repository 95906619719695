import { Flex, TextV2, styled, StyleSystemProps } from '@withjoy/joykit';

export const ConfirmContainer = styled(Flex)``;
export const ConfirmWrapper = styled(Flex)``;
export const StyledActionWrapper = styled(Flex)``;
export const StyledTitle = styled(TextV2)``;
export const StyledBodyTitle = styled(TextV2)``;
export const StyledBody = styled(TextV2)``;

export const confirmContainerStyles: StyleSystemProps = {
  height: '100%',
  width: '100%'
};

export const confirmWrapperStyles: StyleSystemProps = {
  width: '100%',
  maxWidth: '864px',
  paddingTop: 7,
  paddingBottom: 9,
  paddingX: 9,
  backgroundColor: 'white',
  borderRadius: '8px'
};

export const actionWrapperStyles: StyleSystemProps = {
  borderTop: '1px solid #f2f2f2',
  paddingTop: 7,
  _notLast: {
    paddingBottom: 7
  }
};

export const titleStyles: StyleSystemProps = {
  // all variants here not working?
  typographyVariant: 'hed5'
};

export const hedStyles: StyleSystemProps = {
  typographyVariant: 'hed2'
};

export const dekStyles: StyleSystemProps = {
  typographyVariant: 'body1'
};

export const styles = {
  confirmContainer: confirmContainerStyles,
  confirmWrapper: confirmWrapperStyles,
  actionWrapper: actionWrapperStyles,
  title: titleStyles,
  hed: hedStyles,
  dek: dekStyles
};
