import React, { useEffect, useMemo, useRef, useState } from 'react';

import { StyledContainer, StyledPanel, StyledItem, StyledIcon, StyledButton, accordionStyles, useAccordionStyles, AccordionStylesProvider } from './Accordion.styles';
import { ReactComponent as IconDownChevron } from '@assets/icons/down-chevron.svg';
import { DescendantsProvider } from './useDescendants';
import { AccordionItemProvider, AccordionProvider, useAccordion, useAccordionItem, useAccordionItemContext } from './useAccordion';
import { useStyleConfig } from '@shared/joykit/packages/core/common/utils/styleConfig';
import { animated, useSpring } from 'react-spring';
import { AccordionProps, AccordionItemProps, AccordionButtonProps, AccordionPanelProps, AccordionIconProps } from '.';
import { runIfFn } from '@shared/utils/functions';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';
import { debounce } from 'lodash-es';

//---------------------------------------
// Accordion

export const Accordion: React.FC<AccordionProps> & {
  Item: typeof AccordionItem;
  Button: typeof AccordionButton;
  Panel: typeof AccordionPanel;
  Icon: typeof AccordionIcon;
} = (props: AccordionProps) => {
  const { descendants, htmlProps, ...context } = useAccordion(props);
  const styles = useStyleConfig(accordionStyles, props);
  const ctx = useMemo(() => context, [context]);

  return (
    <DescendantsProvider value={descendants}>
      <AccordionProvider value={ctx}>
        <AccordionStylesProvider value={styles}>
          <StyledContainer {...htmlProps} />
        </AccordionStylesProvider>
      </AccordionProvider>
    </DescendantsProvider>
  );
};

//---------------------------------------
// Accordion Item

export const AccordionItem: React.FC<AccordionItemProps> = props => {
  const { children } = props;
  const { htmlProps, ...context } = useAccordionItem(props);
  const styles = useAccordionStyles();
  const ctx = useMemo(() => context, [context]);

  return (
    <AccordionItemProvider value={ctx}>
      <StyledItem __css={styles.item} {...htmlProps}>
        {runIfFn(children, { isExpanded: ctx.isOpen })}
      </StyledItem>
    </AccordionItemProvider>
  );
};

//---------------------------------------
// Accordion Button

export const AccordionButton: React.FC<AccordionButtonProps> = props => {
  const { getButtonProps } = useAccordionItemContext();
  const buttonProps = getButtonProps(props);
  const styles = useAccordionStyles();

  return <StyledButton as="button" type="button" __css={styles.button} {...buttonProps} />;
};

//---------------------------------------
// Accordion Panel

export const AccordionPanel: React.FC<AccordionPanelProps> = props => {
  const [shouldSkipFirstAnimation, setShouldSkipFirstAnimation] = useState<boolean>(true);

  const { isOpen, getPanelProps } = useAccordionItemContext();
  const styles = useAccordionStyles();
  const nodeRef = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();

  const [style, animate] = useSpring<{ height: number; opacity: number }>(() => ({
    height: 0,
    opacity: 0,
    config: {
      friction: 20,
      clamp: true
    },
    immediate: !isMounted
  }));

  const triggerAnimation = useEventCallback(() => {
    animate({
      height: (isOpen ? nodeRef.current?.offsetHeight : 0) || 0,
      opacity: isOpen ? 1 : 0,
      immediate: shouldSkipFirstAnimation
    });
  });

  useEffect(() => {
    triggerAnimation();
  }, [triggerAnimation, isOpen]);

  useEffect(() => {
    const currentHeight = nodeRef.current?.offsetHeight;

    if (currentHeight !== undefined && props.onHeightChange) {
      props.onHeightChange(currentHeight);
    }
  }, [isOpen, props.children]);

  const triggerRefreshHeight = useEventCallback(() => {
    if (!isOpen) return;

    animate({
      height: nodeRef.current?.offsetHeight
    });
  });

  useEffect(() => {
    if (!props.shouldWatchForHeightChanges) return;

    const node = nodeRef.current;
    if (!node) return;

    const observer = new MutationObserver(() => triggerRefreshHeight());
    observer.observe(node, { childList: true, subtree: true });
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [props.shouldWatchForHeightChanges, triggerRefreshHeight]);

  useEffect(
    withWindow(
      global => {
        const resizeHandler = debounce(() => {
          triggerAnimation();
        }, 133);

        global.window.addEventListener('resize', resizeHandler);

        return () => {
          global.window.removeEventListener('resize', resizeHandler);
        };
      },
      () => {}
    ),
    [triggerAnimation]
  );

  useEffect(() => {
    if (isMounted) setShouldSkipFirstAnimation(false);
  }, [isMounted]);

  return (
    <animated.div
      style={{
        overflow: 'hidden',
        visibility: style.height?.interpolate(val => (typeof val === 'undefined' ? undefined : (val as number) <= 0 ? 'hidden' : undefined)),
        height: style.height?.interpolate(val => (typeof val === 'undefined' ? undefined : `${val}px`)),
        opacity: style.opacity,
        ...props.style
      }}
    >
      <StyledPanel __css={styles.panel} {...getPanelProps(props)} ref={nodeRef}>
        {runIfFn(props.children, { triggerAnimation })}
      </StyledPanel>
    </animated.div>
  );
};

//---------------------------------------
// Accordion Icon

export const AccordionIcon: React.FC<AccordionIconProps> = props => {
  const { isOpen } = useAccordionItemContext();
  const styles = useAccordionStyles();

  const iconStyles = {
    ...styles.icon,
    transform: `rotate(${isOpen ? -180 : 0}deg)`
  };

  if (props.icon) {
    return props.icon(isOpen);
  }

  return <StyledIcon as={IconDownChevron} __css={iconStyles} {...props} />;
};

Accordion.displayName = 'Accordion';
AccordionButton.displayName = 'AccordionButton';
AccordionItem.displayName = 'AccordionItem';
AccordionIcon.displayName = 'AccordionIcon';
Accordion.Button = AccordionButton;
Accordion.Item = AccordionItem;
Accordion.Panel = AccordionPanel;
Accordion.Icon = AccordionIcon;
