import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledBorderBackground, StyledTitleBackground, StyledDetailsText } from './GoldBorderCursive.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetBorderUrl = getThemeInfo('goldBorderCursive')?.assetUrl || '';
const assetTitleUrl = getThemeInfo('goldBorderCursive')?.assetTitleUrl || '';

export const GoldBorderCursive: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const namesDivider = isFullNamesFormat ? (
    <>
      <br />
      {'&'}
      <br />
    </>
  ) : (
    ' & '
  );

  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  const areDetailsShown = !!formattedDate || !!formattedLocation || !!inviteToFollow;
  return (
    <>
      <AdjustableImage />
      <StyledTitleBackground backgroundImage={addRendition({ url: assetTitleUrl, renditionSize: 'medium' })}>
        <Flex padding="108px 40px 40px" flexDirection="column" alignItems="center" fontFamily="Lora" color="white">
          <TextV2 color="inherit" textAlign="center" fontSize={14} fontFamily="inherit" fontWeight={600} lineHeight="22px" letterSpacing="0.25em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName ? namesDivider : null}
            {formattedFianceeName}
          </TextV2>
          {areDetailsShown && (
            <Flex flexDirection="column" marginTop={5}>
              {formattedDate ? <StyledDetailsText>{formattedDate}</StyledDetailsText> : null}
              {formattedLocation ? <StyledDetailsText>{formattedLocation}</StyledDetailsText> : null}
              {inviteToFollow ? <StyledDetailsText>{inviteToFollow}</StyledDetailsText> : null}
            </Flex>
          )}
        </Flex>
      </StyledTitleBackground>
      <StyledBorderBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetBorderUrl, renditionSize: 'medium' })} />
    </>
  );
};
