import React from 'react';
import { EcardEventDesign } from '@apps/ecard/Ecard.types';
import EcardLandingLeftPane from './components/EcardLandingLeftPane';
import EcardLandingRightPane from './components/EcardLandingRightPane';
import { Box, Flex } from '@withjoy/joykit';
import { useEcardLandingController } from './EcardLanding.controller';
import { EcardDesignInputFields } from '../Design/Design.controller';
import { PrivacyModeType, EcardType } from '@graphql/generated';
import EcardLandingHeader from './components/EcardLandingHeader';
import { HEADER_HEIGHT_DESKTOP, ECARD_LANDING_LEFT_PANE_WIDTH_DESKTOP, ECARD_LANDING_RIGHT_PANE_WIDTH_DESKTOP } from './EcardLanding.constants';
import SaveThedateLanding from './components/SaveThedateLanding';

interface EcardLandingProps
  extends Readonly<{
    ecardInput: EcardDesignInputFields;
    eventDesign: EcardEventDesign;
    ecardDraftType: EcardType;
    eventHandle: string;
    privacyMode?: PrivacyModeType;
    eventPassword?: Maybe<string>;
  }> {}

export const EcardLanding: React.FC<EcardLandingProps> = ({ ecardInput, eventDesign, ecardDraftType, privacyMode, eventPassword, eventHandle }) => {
  const { selectedTab, setSelectedTab } = useEcardLandingController({ ecardDraftType });
  const isSaveTheDate = ecardDraftType === EcardType.savethedate;

  return (
    <Box width="100%">
      <EcardLandingHeader ecardDraftType={ecardDraftType} isSaveTheDate={isSaveTheDate} />
      {isSaveTheDate ? (
        <SaveThedateLanding ecardInput={ecardInput} eventHandle={eventHandle} />
      ) : (
        <Flex width="100%" height={['100%', '100vh']} flexDirection={['column', 'row']}>
          <Flex width={['100%', ECARD_LANDING_LEFT_PANE_WIDTH_DESKTOP]} height="100%" flexDirection="column" paddingTop={['0px', `${HEADER_HEIGHT_DESKTOP}px`]} order={[2, 1]}>
            <EcardLandingLeftPane ecardDraftType={ecardDraftType} ecardInput={ecardInput} eventHandle={eventHandle} />
          </Flex>
          <Flex width={['100%', ECARD_LANDING_RIGHT_PANE_WIDTH_DESKTOP]} order={[1, 2]}>
            <EcardLandingRightPane
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              ecardInput={ecardInput}
              eventDesign={eventDesign}
              privacyMode={privacyMode}
              eventPassword={eventPassword}
              ecardDraftType={ecardDraftType}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
