import React from 'react';
import { StyledEditDesignButton, StyledEditContentButton } from './EcardPreview.styles';
import { ReactComponent as EditDesignSvg } from '@assets/icons/edit-design.svg';
import { ReactComponent as EditContentSvg } from '@assets/icons/edit-content.svg';
import EcardWrapper from './components/EcardWrapper';
import { fontImportHook } from '@shared/utils/fonts/useFonts';
import { emailFonts } from '@shared/utils/fonts/emailFonts';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import EcardFooter from '@shared/components/EmailsAndEcards/components/Design/components/EcardPreview/components/EcardFooter';
import { EcardEditorType, EcardEventDesign } from '@apps/ecard/Ecard.types';
import GenericEcard from './components/GenericEcard';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import { PrivacyModeType } from '@graphql/generated';
import { useEcardDesignTheming } from '@apps/ecard/theming/useEcardDesignTheming';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

const Fonts = [emailFonts.IBM_Plex_Sans, emailFonts.IBM_Plex_Mono, emailFonts.IBM_Plex_Mono_Bold, emailFonts.Playfair_Display];
const [, FontStyles] = fontImportHook(Fonts);

interface EcardPreviewProps
  extends Readonly<{
    toggleEdit?: (type?: EcardEditorType) => void;
    isEditShown?: boolean;
    ecardData: EcardDesignInputFields;
    eventDesign?: EcardEventDesign | null;
    names?: Maybe<string>;
    privacyMode?: PrivacyModeType;
    eventPassword?: Maybe<string>;
    eventHandle?: string;
    isEcardPreview?: boolean;
    isGuestView?: boolean;
    previewFrontRef?: React.RefObject<HTMLDivElement>;
    previewBackRef?: React.RefObject<HTMLDivElement>;
    onLogoutEventHandle?: () => void;
  }> {}

export const EcardPreview: React.FC<EcardPreviewProps> = ({
  toggleEdit,
  isEditShown = false,
  ecardData,
  eventDesign,
  names,
  privacyMode,
  eventPassword,
  eventHandle,
  isEcardPreview = false,
  isGuestView = false,
  previewFrontRef,
  previewBackRef,
  onLogoutEventHandle
}) => {
  const isMobile = useIsMobileScreen();
  const { theme } = useEcardDesignTheming(eventDesign, ecardData.useMyColor);
  const showEditButtons = toggleEdit && !isEditShown && !isEcardPreview && !isMobile;

  return (
    <EcardWrapper names={names} isEcardEditorShown={isEditShown} showRecipient={!isEcardPreview} isGuestView={isGuestView} onLogoutEventHandle={onLogoutEventHandle}>
      {!!FontStyles ? <FontStyles /> : null}
      <JoyKitThemeProvider theme={theme}>
        <GenericEcard ecardData={ecardData} eventDesign={eventDesign} isEcardPreview={isEcardPreview} ref={previewFrontRef} />
        <EcardFooter
          eventHandle={eventHandle}
          ecardData={ecardData}
          privacyMode={privacyMode}
          eventPassword={eventPassword}
          isEcardPreview={isEcardPreview}
          ref={previewBackRef}
          isGuestView={isGuestView}
        />
      </JoyKitThemeProvider>
      {showEditButtons ? (
        <StyledEditDesignButton onClick={() => toggleEdit(EcardEditorType.DESIGN)}>
          <EditDesignSvg />
        </StyledEditDesignButton>
      ) : null}
      {showEditButtons ? (
        <StyledEditContentButton onClick={() => toggleEdit(EcardEditorType.CONTENT)}>
          <EditContentSvg />
        </StyledEditContentButton>
      ) : null}
    </EcardWrapper>
  );
};
