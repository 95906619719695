import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { ApolloError } from '@apollo/client';
import { EcardLandingRightPanelTabItem, EcardEditorType } from '@apps/ecard/Ecard.types';
import { ElectronicCardInput, ElectronicCardEmailDraftInput, ElectronicCardEmailMessageInput, EcardType, PostOfficeMessageType } from '@graphql/generated';
import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';

type PagesForTelemetry =
  | 'designCardTrack'
  | 'editEmailTrack'
  | 'confirmAndSendTrack'
  | 'confirmSelectGuestsTrack'
  | 'confirmTestEmailTrack'
  | 'editEmailContentTrack'
  | 'eCardLandingPageTrack'
  | 'editCardDesignTrack'
  | 'editCardContentTrack';

const getCategory = (draftType: EcardType | PostOfficeMessageType) => {
  if (
    draftType === PostOfficeMessageType.generic ||
    draftType === PostOfficeMessageType.giftWrap ||
    draftType === PostOfficeMessageType.greetingCard ||
    draftType === PostOfficeMessageType.reminder ||
    draftType === PostOfficeMessageType.thankyou
  ) {
    return 'admin.postOffice';
  }

  return 'admin.messaging';
};
const emailCategory = `admin.eCardEmail`;

const pages: { [key in PagesForTelemetry]: PageFunctionType } = {
  eCardLandingPageTrack: (eCardType: EcardType) => ({
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard',
    action: 'ECardLandingPageViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  designCardTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard.design',
    action: 'DesignCardViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  editCardDesignTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard.design.edit',
    action: 'EditCardDesignViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  editCardContentTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: 'ecard.design.editcontent',
    action: 'EditCardContentViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  editEmailTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.${eCardType}.email`,
    action: 'EditEmailViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  editEmailContentTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.email.edit`,
    action: 'EditCardContentViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  confirmAndSendTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.confirm`,
    action: 'ConfirmAndSendViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  confirmSelectGuestsTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.confirm.selectguests`,
    action: 'ConfirmSelectGuestsViewed',
    extraPageInfo: {
      type: eCardType
    }
  }),
  confirmTestEmailTrack: (eCardType: EcardType) => ({
    type: 'enter',
    category: 'messaging',
    pagePrefix: 'admin',
    page: `ecard.confirm.test`,
    action: 'ConfirmTestEmailViewed',
    extraPageInfo: {
      type: eCardType
    }
  })
};

export const ecardsTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, eCardType: EcardType, extra = {}) => ({
      category: getCategory(eCardType),
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { type: eCardType, error, mutation, ...extra }
    }),
    viewMatchingPrintsFromVendor: (eCardType: EcardType, themeId: string) => ({
      action: 'ViewMatchingPrintsFromVendor',
      actionType: undefined,
      category: getCategory(eCardType),
      label: themeId,
      extraInfo: {
        type: eCardType
      }
    }),
    previewTabClick: (eCardType: EcardType, label: EcardLandingRightPanelTabItem) => ({
      action: 'EcardPreviewTab',
      actionType: 'click',
      category: getCategory(eCardType),
      label,
      extraInfo: {
        type: eCardType
      }
    }),
    explorePrintsClick: (eCardType: EcardType, themeId: string) => ({
      action: 'ExplorePrints',
      actionType: 'click',
      category: getCategory(eCardType),
      label: themeId,
      extraInfo: {
        type: eCardType
      }
    }),
    designAndSendClick: (eCardType: EcardType) => ({
      action: 'DesignAndSend',
      actionType: 'click',
      category: getCategory(eCardType),
      extraInfo: {
        type: eCardType
      }
    }),
    viewGuestListClick: (eCardType: EcardType) => ({
      action: 'ViewGuestList',
      actionType: 'click',
      category: getCategory(eCardType),
      extraInfo: {
        type: eCardType
      }
    }),
    explorePrintParnterClick: (eCardType: EcardType, partnerName: string) => ({
      action: 'ExplorePrintParnter',
      actionType: 'click',
      category: getCategory(eCardType),
      label: partnerName,
      extraInfo: {
        type: eCardType
      }
    }),
    editEcardClick: (eCardType: EcardType) => ({
      action: 'EditEcard',
      actionType: 'click',
      category: getCategory(eCardType),
      extraInfo: {
        type: eCardType
      }
    }),
    eCardAdminLinkClick: (eCardType: EcardType, linkLabel: 'date' | 'location' | 'url') => ({
      action: 'ECardAdminLink',
      actionType: 'click',
      category: getCategory(eCardType),
      label: linkLabel,
      extraInfo: {
        type: eCardType
      }
    }),
    launchEcardEditor: (eCardType: EcardType, ecardEditorType: EcardEditorType) => ({
      action: 'LaunchEcardEditor',
      actionType: 'click',
      category: getCategory(eCardType),
      label: ecardEditorType,
      extraInfo: {
        type: eCardType
      }
    }),
    eCardEditorTabClick: (eCardType: EcardType, ecardEditorType: EcardEditorType) => ({
      action: 'EcardEditorTab',
      actionType: 'click',
      category: getCategory(eCardType),
      label: ecardEditorType,
      extraInfo: {
        type: eCardType
      }
    }),
    editECardDoneClick: (eCardType: EcardType, ecardEditorType: EcardEditorType) => ({
      action: 'Done',
      actionType: 'click',
      category: getCategory(eCardType),
      label: `eCard editor - ${ecardEditorType}`,
      extraInfo: {
        type: eCardType
      }
    }),
    editECardCancelClick: (eCardType: EcardType, ecardEditorType: EcardEditorType) => ({
      action: 'Cancel',
      actionType: 'click',
      category: getCategory(eCardType),
      label: `eCard editor - ${ecardEditorType}`,
      extraInfo: {
        type: eCardType
      }
    }),
    eCardSave: (eCardType: EcardType, eCardData: ElectronicCardInput, ecardTheme: string) => ({
      action: 'ECardSave',
      actionType: undefined,
      category: getCategory(eCardType),
      extraInfo: {
        type: eCardType,
        eCardData: {
          ...eCardData,
          ecardTheme
        }
      }
    }),
    launchEmailEditor: () => ({
      action: 'LaunchEmailEditor',
      actionType: undefined,
      category: emailCategory
    }),
    editEmailDoneClick: () => ({
      action: 'Done',
      actionType: 'click',
      category: emailCategory,
      label: 'email editor'
    }),
    editEmailCancelClick: () => ({
      action: 'Cancel',
      actionType: 'click',
      category: emailCategory,
      label: 'email editor'
    }),
    emailSave: (emailData: ElectronicCardEmailDraftInput) => ({
      action: 'EmailSave',
      actionType: undefined,
      category: getCategory(emailData.ecardType),
      extraInfo: {
        type: emailData.ecardType,
        emailData
      }
    }),
    sendEmail: (eCardType: EcardType, emailData: ElectronicCardEmailMessageInput) => ({
      action: 'SendEmail',
      actionType: undefined,
      category: getCategory(eCardType),
      label: (emailData.recipientIds?.length || 0).toString(),
      extraInfo: {
        type: eCardType,
        emailData: {
          ...emailData,
          totalRecipients: emailData.recipientIds?.length || 0
        }
      }
    }),
    sendTestEmail: (eCardType: EcardType, name: string, email: string) => ({
      action: 'SendTest',
      actionType: 'click',
      category: getCategory(eCardType),
      extraInfo: {
        type: eCardType,
        name,
        email
      }
    }),
    sendECardClick: (eCardType: EcardType, payload: { eCardData: EcardDesignInputFields; emailData: EmailInputFields }, label: string, adminReferrer: string) => ({
      action: 'SendECard',
      actionType: 'click',
      category: getCategory(eCardType),
      label,
      extraInfo: {
        type: eCardType,
        ecardMessageFrontContent: payload.eCardData.message,
        ecardMessageBackContent: payload.eCardData.additionalMessage,
        theme: payload.eCardData.joyTheme?.themeId,
        customPhotoUpload: false,
        eCardData: payload.eCardData,
        adminReferrer
      }
    }),
    eCardLinkClick: (linkName: string, eventId: string) => ({
      action: 'eCardLink',
      actionType: 'click',
      category: 'wedding',
      label: linkName,
      extraInfo: {
        eventId
      }
    }),
    selectGuestsClicked: (draftType: EcardType | PostOfficeMessageType) => ({
      action: 'SelectGuests',
      actionType: 'click',
      category: getCategory(draftType),
      extraInfo: {
        type: draftType
      }
    }),
    copyShareableLinkClicked: (draftType: EcardType, link: string) => ({
      action: 'CopyShareableLink',
      actionType: 'click',
      category: getCategory(draftType),
      label: link,
      extraInfo: {
        type: draftType
      }
    }),
    aboutShareableLinksClick: (label: 'Cancel' | 'I Understand', draftType: EcardType) => ({
      action: 'AboutShareableCards',
      actionType: 'click',
      category: getCategory(draftType),
      label,
      extraInfo: {
        type: draftType
      }
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry } = createTelemetry(ecardsTelemetry, { eventId: '' });

const useEcardsTelemetry = useTelemetry;

export { TelemetryProvider, useEcardsTelemetry };
