import { pxToRem } from '@withjoy/joykit/theme';
import { styled, Box, StyleSystemProps } from '@withjoy/joykit';

export const StyledHamburger = styled(Box)``;
const hamburgerStyles: StyleSystemProps = {
  width: pxToRem(24),
  height: pxToRem(18),
  cursor: 'pointer',
  marginRight: 5,

  _after: {
    content: '" "',
    display: 'block',
    width: '100%',
    height: pxToRem(2),
    boxShadow: '#333 0px 6px 0px 0px, #333 0px 12px 0px 0px',
    backgroundColor: 'mono14',
    borderRadius: pxToRem(1)
  }
};

export const styles = {
  hamburger: hamburgerStyles
};
