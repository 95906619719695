import { Box, styled } from '@withjoy/joykit';

export const PreviewContainer = styled(Box)<{ mobile: boolean }>`
  width: ${props => (props.mobile ? '100%' : '60%')};
  height: ${props => (props.mobile ? 'fit-content' : '100vh')};

  display: flex;
  flex-direction: ${props => (props.mobile ? 'row' : 'column')};

  justify-content: center;
  align-items: center;

  background-color: #fff9f5;

  ${props =>
    props.className === 'ready'
      ? `
  * {
    opacity: 100%;
    transition: opacity 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  `
      : `
  * {
    opacity: 0%;
    transition: opacity 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  `}
`;
