import React from 'react';
import { Box, Divider, Flex, TextV2 } from '@withjoy/joykit';
import { Home } from '@withjoy/joykit/icons';
import { RoutesProvider, useRouterHelper, useTranslation } from '@shared/core';
import { BurgerMenu } from './components/BurgerMenu/BurgerMenu';
import { pxToRem } from '@withjoy/joykit/theme';
import { useEventInfo } from '@shared/utils/eventInfo';
import { MenuItem } from './components/BurgerMenu/MenuItem';
import { monorepoRoutePaths, routePaths } from '@apps/admin/route.paths';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useEventTypeConfig } from '@shared/utils/eventType';
import { GLOBAL_NAV_MENU, useUniversalBurgerMenuController } from './UniversalBurgerMenu.controller';
import { styles } from './UniversalBurgerMenu.styles';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { EventType } from '@graphql/generated';

type MenuFooterProps = {
  time?: string;
  photoUrl?: string;
  location: Maybe<string>;
  trackAndProvideLogout: () => void;
};

const MenuFootorMenuItem = (props: { title: string; onClick: () => void; path?: string; pathIsInJoyWeb?: boolean }) => {
  return <MenuItem typographyVariant="body1" color="mono12" path={props.path} fontWeight={400} text={props.title} pathIsInJoyWeb={props.pathIsInJoyWeb} onClick={props.onClick} />;
};

const MenuFooter = ({ time, location, photoUrl, trackAndProvideLogout }: MenuFooterProps) => {
  const { eventInfo } = useEventInfo();
  const t = useTranslation('sharedRegistry').t2('registryNavigationMenu');
  const { buildPath } = useRouterHelper();
  const telemetry = useDashboardTelemetry();
  return (
    <Flex flexDirection="column" width="100%" rowGap={1}>
      <Divider paddingY={5} />
      <Flex columnGap={6} alignItems="center" paddingY={6} paddingLeft={5}>
        <Box __css={styles.eventWidgetPhoto(photoUrl)} />
        <Flex flexDirection="column" rowGap={1}>
          <TextV2 typographyVariant="hed2">{eventInfo?.eventDisplayName}</TextV2>
          <TextV2 typographyVariant="label2">{time}</TextV2>
          <TextV2 typographyVariant="label2">{location}</TextV2>
        </Flex>
      </Flex>
      <Flex flexDirection="column" paddingLeft={5}>
        {eventInfo?.eventType !== EventType.babyRegistry && (
          <MenuFootorMenuItem
            title={t.eventSettings}
            path={buildPath(monorepoRoutePaths.settings)}
            pathIsInJoyWeb
            onClick={() => telemetry.openPage({ label: 'settings', section: 'Avatar', categoryArg: GLOBAL_NAV_MENU })}
          />
        )}
        <MenuFootorMenuItem
          title={t.accountInfo}
          path={`/${monorepoRoutePaths.accountPersonalInfo}`}
          pathIsInJoyWeb
          onClick={() => telemetry.openPage({ label: 'account', section: 'Avatar', categoryArg: GLOBAL_NAV_MENU })}
        />
        <MenuFootorMenuItem
          title={t.manageEvents}
          path={`/account/events`}
          pathIsInJoyWeb
          onClick={() => telemetry.openPage({ label: 'myEvents', section: 'Avatar', categoryArg: GLOBAL_NAV_MENU })}
        />
        <MenuFootorMenuItem title={t.help} path="https://help.withjoy.com" onClick={() => telemetry.openPage({ label: 'help', section: 'Avatar', categoryArg: GLOBAL_NAV_MENU })} />
        <MenuItem
          typographyVariant="body1"
          color="mono14"
          fontWeight={500}
          text={t.signOut}
          onClick={() => {
            telemetry.openPage({ label: 'accountSignOut', section: 'Avatar', categoryArg: GLOBAL_NAV_MENU });
            trackAndProvideLogout();
          }}
        />
      </Flex>
    </Flex>
  );
};

const MenuHeader = () => {
  const t = useTranslation('sharedRegistry').t2('registryNavigationMenu');
  const { config } = useEventTypeConfig();
  const { buildPath } = useRouterHelper();
  const telemetry = useDashboardTelemetry();

  return config?.eventDashboardEnabled ? (
    <Flex flexDirection="column" rowGap={pxToRem(24)}>
      <Box paddingLeft={pxToRem(12)} paddingTop={pxToRem(16)}>
        <MenuItem
          typographyVariant="hed3"
          fontWeight={600}
          text={t.eventDashboard}
          icon={Home}
          path={buildPath(routePaths.dashboard.path)}
          pathIsInJoyWeb
          onClick={() => telemetry.openPage({ label: 'eventDashboard', section: 'Event Dashboard', categoryArg: GLOBAL_NAV_MENU })}
        />
        <Divider marginTop={pxToRem(8)} marginBottom={pxToRem(8)} />
      </Box>
    </Flex>
  ) : (
    <></>
  );
};

const Root = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useIsMobileScreen();
  const { items, trackAndProvideLogout, eventDate, location, photoUrl } = useUniversalBurgerMenuController();

  return (
    <BurgerMenu
      items={items}
      enableMobileFirst={isMobile}
      DrawerHeaderContent={<MenuHeader />}
      DrawerFooterContent={<MenuFooter time={eventDate} location={location} photoUrl={photoUrl} trackAndProvideLogout={trackAndProvideLogout} />}
    >
      {children}
    </BurgerMenu>
  );
};

export const UniversalBurgerMenu: React.FC<{ eventHandle?: string }> = ({ children, eventHandle }) => {
  const eventInfo = useEventInfo();

  return (
    <RoutesProvider handle={eventHandle || eventInfo.eventHandle}>
      <Root>{children}</Root>
    </RoutesProvider>
  );
};
