/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Website',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.961 9.316a.6.6 0 0 1 .6-.6h3.892a.6.6 0 0 1 0 1.2h-3.892a.6.6 0 0 1-.6-.6zm1.967 1.679a.6.6 0 0 0 0 1.2h1.159a.6.6 0 0 0 0-1.2h-1.159zm-1.223 3.948a.6.6 0 0 1 .6-.6h2.405a.6.6 0 1 1 0 1.2h-2.405a.6.6 0 0 1-.6-.6z"
            fill="currentColor"
          ></path>{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 19a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16zM11 6.2H9.161l.704.515a.64.64 0 0 1 .156.857.56.56 0 0 1-.812.148l-1.091-.798a.64.64 0 0 1-.222-.722H4a.8.8 0 0 0-.759.545c.53-.064 1.081.118 1.484.544l1.658 1.755a1.95 1.95 0 0 1 0 2.653l-1.658 1.755c-.413.438-.984.617-1.526.538V17a.8.8 0 0 0 .8.8h.433a.63.63 0 0 1 .168-.372l.946-1.001a.56.56 0 0 1 .824 0c.228.241.228.631 0 .872l-.473.5H9.31c-.564-.577-.91-1.381-.91-2.255 0-1.566 1.109-2.909 2.6-3.117V6.2zm-7.8 6.546a.54.54 0 0 0 .653-.119l1.658-1.755a.75.75 0 0 0 0-1.005L3.853 8.114a.54.54 0 0 0-.653-.119v4.752zm7.8.904v3.791c-.784-.196-1.4-.947-1.4-1.895s.616-1.699 1.4-1.895zm1.2-7.45v11.6H20a.8.8 0 0 0 .8-.8V7a.8.8 0 0 0-.8-.8h-7.8z"
            fill="currentColor"
          ></path>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
