import { CardDieCut } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { LayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { StationeryTemplateWithThemeJson } from '@apps/card/routes/Dashboard/components/DesignsGallery/useDesignTemplates';
import { AIBackgroundPromptPayload } from '@graphql/generated';
import { Box, TextV2 } from '@withjoy/joykit';
import React, { MutableRefObject, useContext, useState } from 'react';
import { OpaqueHoverButton, PageSlideAnim, TransparentHoverButton } from './PageWrapper.styles';
import { reserveImageIndex } from '../ImagineYourCardRoot/ImageStorageManager';
import { ResponsivePageScaler } from './ResponsivePageScaler';
import { usePublicShopCustomizeNowClickHandler } from '@apps/card/PublicShop/routes/Gallery/usePublicShopCustomizeNowClickHandler';
import { windowExists } from '@shared/utils/windowExists';
import { useIsMobileOrTablet, useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { desktopCols, responsiveSettings } from '../ImagineYourCardRoot/cardConstants';
import AICardReimaginedContainer from '../AICardPreview/AICardReimagined';
import { cloneDeep } from 'lodash-es';
import { genQuantity, PreviewContext, PreviewContextPayload } from '../ImagineYourCardRoot/ImagineYourCardRoot';
import { ButtonWrapper } from './ButtonWrapper';
import AICardPreviewContainer from '../AICardPreview/AICardPreview';
import { useTranslation } from '@shared/core';
import { Champagne } from '@apps/imagineYourCard/Components/ImagineYourCardRoot/loader/ChampagneBubbleLoader';
import { useImagineTelemetry } from '../ImagineYourCardRoot/ImagineYourCard.telemetry';

type PageWrapperProps = {
  promptPayload: AIBackgroundPromptPayload;
  template: StationeryTemplateWithThemeJson;
  page: {
    fill: string;
    layers: Array<LayerData>;
  };
  hideButtons?: boolean;
  resourceID?: MutableRefObject<string | undefined>;
  loading?: boolean;
  index: number;
  hideShadow?: boolean;
  useSlideAnim?: boolean;
};

export enum moreLikeMeBehavior {
  REFINE,
  RESTYLE,
  LEGACY
}

const PageWrapper = ({ promptPayload, page, template, resourceID, hideButtons, hideShadow, loading, index, useSlideAnim }: PageWrapperProps) => {
  const [deleted] = useState(false);
  const { previewDispatcher, previewLength, setLoadingCount, cardType, setPromptField, handleCustomizeNowClickOverride, styles: allStyles } = useContext(
    PreviewContext
  ) as PreviewContextPayload;
  const { goToCustomizeDraftOrLogin } = usePublicShopCustomizeNowClickHandler();
  const mobile = useIsMobileScreen();
  const mobileOrTablet = useIsMobileOrTablet();
  const cols = useResponsive(responsiveSettings, 1)[0];

  const { t } = useTranslation('stationery');
  const t2 = t('imagineCardAiApp', 'cards');
  const { imagineImageReimagined, imagineImageCustomized } = useImagineTelemetry();

  const { mOverride, oomphOverride, styleOverride } = useContext(PreviewContext) as PreviewContextPayload;
  const handleCustomizeNowClick = () => {
    if (!handleCustomizeNowClickOverride) {
      goToCustomizeDraftOrLogin({ format: 'paper', template }, () => {});
    } else {
      handleCustomizeNowClickOverride(template);
    }
    imagineImageCustomized({
      category: template.category,
      themeID: template.themeId,
      prompt: promptPayload.prompt,
      style: promptPayload.style
    });
  };

  const reimagine = () => {
    for (let i = 0; i < genQuantity; i++) {
      const index = reserveImageIndex();

      previewDispatcher!({
        type: 'addPreview',
        newPreview: (
          <AICardPreviewContainer
            key={index}
            promptPayload={promptPayload}
            themeID={template.themeId}
            category={template.category}
            index={index}
            setLoadingCount={setLoadingCount}
          ></AICardPreviewContainer>
        )
      });
    }

    setPromptField(promptPayload.prompt);

    if (windowExists()) {
      window.scrollTo(0, 0);
    }
    return true;
  };

  const moreLikeMe = (behavior: moreLikeMeBehavior) => {
    let styles = [promptPayload.style];

    imagineImageReimagined({
      category: template.category,
      themeID: template.themeId,
      prompt: promptPayload.prompt,
      style: promptPayload.style,
      resourceID: resourceID?.current!,
      type: behavior
    });

    if (behavior == moreLikeMeBehavior.LEGACY) {
      return reimagine();
    }

    if (behavior == moreLikeMeBehavior.RESTYLE) {
      styles = cloneDeep(
        allStyles
          .map(value => ({ value: value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
      );
    }

    for (let i = 0; i < genQuantity; i++) {
      const lsIndex = reserveImageIndex();
      const payload = {
        ...promptPayload,
        style: styleOverride ?? styles[i % styles.length]
      };

      if (!resourceID?.current) {
        return false;
      }

      const pos = (previewLength - 1 - index) % desktopCols;
      previewDispatcher!({
        type: 'addPreview',
        newPreview: (
          <AICardReimaginedContainer
            targetRow={cols === desktopCols ? pos : undefined}
            myRow={cols === desktopCols ? genQuantity - 1 - i : undefined}
            key={lsIndex}
            promptPayload={payload}
            themeID={template.themeId}
            resourceID={resourceID.current}
            category={template.category}
            index={lsIndex}
            setLoadingCount={setLoadingCount}
            m={mOverride !== 'none' ? mOverride : undefined}
            oomph={oomphOverride !== 'none' ? Number(oomphOverride) : undefined}
          ></AICardReimaginedContainer>
        )
      });
    }

    setPromptField(promptPayload.prompt);

    if (windowExists()) {
      window.scrollTo(0, 0);
    }
    return true;
  };

  if (deleted) {
    return <div style={{ width: '0px', height: '0px' }}></div>;
  }

  const ChampagneLoader = loading ? <Champagne /> : undefined;
  return (
    <div style={{ display: template.category === cardType ? 'block' : 'none', paddingBottom: mobileOrTablet ? '16px' : '32px' }}>
      <Box backgroundColor="transparent" display="flex" alignItems="center" justifyContent="center" flexDirection="column" position="relative" touchAction="pan-y">
        <div>
          {!hideButtons && !loading && (
            <ButtonWrapper>
              <OpaqueHoverButton onClick={() => handleCustomizeNowClick()}>{t2.hover.draft()}</OpaqueHoverButton>
              {resourceID?.current ? <TransparentHoverButton onClick={() => moreLikeMe(moreLikeMeBehavior.REFINE)}>{t2.hover.tweaks()}</TransparentHoverButton> : <div></div>}
              {resourceID?.current ? <TransparentHoverButton onClick={() => moreLikeMe(moreLikeMeBehavior.RESTYLE)}>{t2.hover.styles()}</TransparentHoverButton> : <div></div>}
              {resourceID?.current ? <div></div> : <TransparentHoverButton onClick={() => moreLikeMe(moreLikeMeBehavior.LEGACY)}>{t2.hover.reimagine()}</TransparentHoverButton>}
            </ButtonWrapper>
          )}

          <PageSlideAnim
            useSlide={useSlideAnim}
            mobile={mobileOrTablet}
            columns={cols}
            style={{ display: 'flex', flexDirection: 'column', gap: mobile ? '8px' : '16px', alignItems: 'center' }}
          >
            <ResponsivePageScaler hideShadow={hideShadow} useMargin>
              <Page loader={ChampagneLoader} loading={loading} width={5} height={7} page={page} shape={CardDieCut.rectangle} />
            </ResponsivePageScaler>
            <TextV2 typographyVariant="label2">{t2.pricing()}</TextV2>
          </PageSlideAnim>
        </div>
      </Box>
    </div>
  );
};

export default PageWrapper;
