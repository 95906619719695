import { PageVisibility } from '../../components/DashboardContentV2/hooks/useProductRows';

export enum ProductVisibilityEnum {
  Public = 'public',
  Hidden = 'hidden',
  Private = 'private'
}

export const getProductVisibility = (visibility?: PageVisibility) => {
  if (!visibility) return ProductVisibilityEnum.Hidden;

  switch (visibility) {
    case 'hidden':
      return ProductVisibilityEnum.Hidden;
    case 'passwordProtected':
      return ProductVisibilityEnum.Private;
    case 'public':
      return ProductVisibilityEnum.Public;
  }
};
