import { styled } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';

export const TitleBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0 2rem 0;
  gap: 1rem;
`;

export const TitleText = styled.h2`
  flex: 1;
  display: block;
  ${InterFonts.title}
`;
