import React, { useEffect, useState, useContext } from 'react';
import { useSelectGuestsController } from '@apps/playground/components/SelectGuests/SelectGuests.controller';
import { ButtonV2, TextV2, IconV2 } from '@withjoy/joykit';
import { SelectRecipientsEmail } from '@withjoy/joykit/icons';
import { PersonFragment, PostOfficeMessageType, EcardType } from '@graphql/generated';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { PeopleContext } from '@shared/context/people';
interface PeoplePickerProps
  extends Readonly<{
    buttonTextDefault: string;
    buttonTextHover: string;
    eventHandle: string;
    draftType: PostOfficeMessageType | EcardType;
    buttonWidth?: number;
    disabled?: boolean;
    onStartSelection?: () => void;
  }> {}

export const PeoplePicker: React.FC<PeoplePickerProps> = ({ buttonTextDefault, buttonTextHover, eventHandle, draftType, buttonWidth, disabled, onStartSelection }) => {
  const { selectedPeople, setSelectedPeople } = useContext(PeopleContext);

  const [initialSelectedPeople, setInitialSelectedPeople] = useState([] as ReadonlyArray<Partial<PersonFragment>>);
  const [buttonText, setButtonText] = useState<string>(buttonTextDefault);
  useEffect(() => {
    setInitialSelectedPeople(selectedPeople as ReadonlyArray<Partial<PersonFragment>>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, startSelection, error, viewPeople } = useSelectGuestsController({
    eventHandle,
    title: (
      <TextV2 typographyVariant="hed4" color="mono14" display="flex" alignItems="center" fontSize="24px" lineHeight="1">
        <IconV2 size="md" marginRight="12px">
          <SelectRecipientsEmail />
        </IconV2>{' '}
        Select Guests
      </TextV2>
    ),
    onSelectedPeople: setSelectedPeople,
    initialSelectedPeople
  });

  const telemetry = useEcardsTelemetry();

  const onSelectPeopleClick = () => {
    telemetry.selectGuestsClicked(draftType);
    startSelection();
    onStartSelection?.();
  };

  // a hack to add person ids when passing selectedPeople array from local stroage (it only has personIdTags, we need ids)
  useEffect(() => {
    if (!!viewPeople && viewPeople.length) {
      const newSelectedPeople = viewPeople
        .filter(person => person.selected && !person.disabled)
        .map(person => ({ id: person.id, personIdTag: person.personIdTag, full_name: person.labelText }));
      setSelectedPeople(newSelectedPeople);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewPeople]);

  const handleOnMouseEnter: React.MouseEventHandler = () => {
    if (selectedPeople && selectedPeople.length > 0) {
      setButtonText(buttonTextHover);
    }
  };

  const handleOnMouseLeave: React.MouseEventHandler = () => {
    if (selectedPeople && selectedPeople.length > 0) {
      setButtonText(buttonTextDefault);
    }
  };

  useEffect(() => {
    setButtonText(buttonTextDefault);
  }, [buttonTextDefault]);

  return (
    <ButtonV2
      disabled={disabled || loading || !!error}
      variant="outline"
      intent="primary"
      onClick={onSelectPeopleClick}
      width={buttonWidth ?? 'initial'}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {buttonText}
    </ButtonV2>
  );
};
