import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { getLocalStorage } from '@shared/core';
import { isObject } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { omitEmpty } from '@apps/createWedding/utils';
import { DesignLayoutType } from '@graphql/generated';
import { useAllowedDesignParams } from '../components/AllowedDesignParamsProvider';

const supportedKeys = ['font', 'template', 'scenario', 'theme', 'destination', 'createmethod', 'skipIfLoggedIn', 'palette'] as const;

type QueryKey = typeof supportedKeys[number];

export type DesignParams = { [K in QueryKey]?: string };

type DesignParamsValidator = { [K in QueryKey]?: (value: string) => string | undefined };

const DESIGN_PARAMS_KEY = '@@createwedding/design_params';

/**
 * Will return at most all the design params, if none is defined it's just empty object
 */
export const useDesignParams = (): { designParams: DesignParams; settled: boolean; cleanDesignParams: () => void } => {
  const queryParams = useQueryParams();
  const localStorage = getLocalStorage();

  const { themes, fonts, settled } = useAllowedDesignParams();

  const designParamsValidator: DesignParamsValidator = useMemo(() => {
    return {
      template: value => (value in DesignLayoutType ? value : undefined),
      theme: value => (themes.has(value) ? value : undefined),
      font: value => (fonts.has(value) ? value : undefined),
      scenario: value => (['invitation', 'save-the-date', 'registry'].includes(value) ? value : undefined),
      palette: value => {
        try {
          const parsedValue = JSON.parse(value);
          return parsedValue;
        } catch {
          return undefined;
        }
      }
    };
  }, [fonts, themes]);

  const designParamsFromLocalStorage = useMemo(() => {
    const params = localStorage.getItem(DESIGN_PARAMS_KEY);

    if (params) {
      return onlySupportedKeys(JSON.parse(params));
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateDesignParams = useCallback(
    (params: Partial<DesignParams>): Partial<DesignParams> => {
      return Object.keys(params).reduce<Partial<DesignParams>>((prev, current) => {
        if (typeof designParamsValidator[current as QueryKey] === 'function') {
          const prevValue: string = params[current as QueryKey] || '';

          return { ...prev, [current]: designParamsValidator[current as QueryKey]?.(prevValue) };
        }

        return prev;
      }, params);
    },
    [designParamsValidator]
  );

  const designParamsFromQuery = useMemo(() => onlySupportedKeys(queryParams), [queryParams]);

  const cleanDesignParams = useCallback(() => {
    localStorage.setItem(DESIGN_PARAMS_KEY);
  }, [localStorage]);

  const validatedDesignParams = useMemo(() => {
    const localStorageValues = omitEmpty(designParamsFromLocalStorage);
    const queryParamValues = omitEmpty(designParamsFromQuery);

    const params = { ...localStorageValues, ...queryParamValues };

    return validateDesignParams(params);
  }, [designParamsFromLocalStorage, designParamsFromQuery, validateDesignParams]);

  useEffect(() => localStorage.setItem(DESIGN_PARAMS_KEY, JSON.stringify(validatedDesignParams)), [validatedDesignParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return { designParams: validatedDesignParams, settled, cleanDesignParams };
};

const onlySupportedKeys = <T extends Partial<Record<keyof DesignParams, unknown>>>(obj: T): Partial<DesignParams> => {
  if (!isObject(obj)) {
    return {};
  }

  const designParams: DesignParams = {};

  for (const key of supportedKeys) {
    const val = obj[key];
    if (typeof val === 'string') {
      designParams[key] = val;
    }
  }

  return designParams;
};
