import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StationeryDraftFormat, useGetExistingEventStationeryDraftsWithTemplateQuery } from '@graphql/generated';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useDisclosure } from '@withjoy/joykit';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { MinimalStationeryTemplate } from './types';
import { ExistingDraftsWarningDialog } from './ExistingDraftsWarningDialog';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

type ExistingDraftsWarningContext = {
  /**
   * If there are existing drafts for the given template, show a warning dialog.
   * Else, call the createNewDraftCallback.
   */
  checkForExistingDraftOrCreateNew: (
    templateId: string,
    createNewDraftCallback: (existingDraftsCount: number) => void,
    onShowWarningDialog: () => void,
    isDigital?: boolean
  ) => void;
};

const [Provider, useExistingDraftsWarningContext] = createContext<ExistingDraftsWarningContext>({ name: 'ExistingDraftsWarning' });

type StationeryTemplateMap = {
  [templateId_format: string]: {
    template: MinimalStationeryTemplate;
    matchingDraftIds: string[];
  };
};

const getTemplateConfigMapKey = (draft: { format: StationeryDraftFormat; stationeryTemplate: { id: string } }) => {
  return `${draft.stationeryTemplate.id}_${draft.format}`;
};

/**
 * Returns a map of templateId to config object containing the template and matching draft ids
 */
const useTemplateConfigMap = (eventId: string | undefined) => {
  const { data } = useGetExistingEventStationeryDraftsWithTemplateQuery({
    variables: {
      input: {
        eventId: eventId!
      }
    },
    skip: !eventId,
    fetchPolicy: 'cache-and-network',
    batchMode: 'fast'
  });

  return useMemo(() => {
    return (
      data?.stationeryDraftsByEvent.reduce((acc, draft) => {
        const key = getTemplateConfigMapKey(draft);

        // Get or create the config object
        const config = acc[key] ?? {};
        config.template = draft.stationeryTemplate;

        // Get or create the matchingDraftIds array
        const matchingDraftIds = config.matchingDraftIds || [];
        matchingDraftIds.push(draft.id);
        config.matchingDraftIds = matchingDraftIds;

        // Store the config object in the map
        acc[key] = config;

        return acc;
      }, {} as StationeryTemplateMap) || {}
    );
  }, [data]);
};

const ExistingDraftsWarningProvider: React.FC<{ eventId: string | undefined }> = ({ children, eventId }) => {
  const { existingDraftsWarningDialogViewAllOrEditExistingButtonInteracted, existingDraftsWarningDialogCreateNewDraftButtonInteracted } = useCardTelemetry();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Store the createNewDraftCallback in a ref so that it can be called when the user
  // confirms they want to create a new draft in the warning dialog
  const createNewDraftCallbackRef = useRef<((existingDraftsCount: number) => void) | null>(null);

  const templateToConfigMap = useTemplateConfigMap(eventId);
  const { goToCustomizeDraft, goToCardDrafts, goToDigitalCustomizeDraft } = useCardsRouterContext();

  // Store the current template config map key to know which variant dialog to show
  const [currentTemplateConfigKey, setCurrentTemplateConfigKey] = useState<string | null>(null);

  const [isDigital, setIsDigital] = useState<boolean>(false);

  const checkForExistingDraftOrCreateNew = useCallback<ExistingDraftsWarningContext['checkForExistingDraftOrCreateNew']>(
    (templateId, createNewDraftCallback, onShowWarningDialog, isDigital) => {
      const key = getTemplateConfigMapKey({ stationeryTemplate: { id: templateId }, format: isDigital ? StationeryDraftFormat.digital : StationeryDraftFormat.print });
      const config = templateToConfigMap[key];

      // If there are existing drafts, show the dialog
      if (config && config.matchingDraftIds.length > 0) {
        // Store the createNewDraftCallback in a ref so that it can be called after the dialog is closed
        // and the user confirms they want to create a new draft
        createNewDraftCallbackRef.current = createNewDraftCallback;
        setCurrentTemplateConfigKey(key);
        setIsDigital(!!isDigital);
        onShowWarningDialog();
        onOpen();
        return;
      }

      // If there are no existing drafts, create a new draft
      createNewDraftCallback(0);
    },
    [templateToConfigMap, onOpen]
  );

  const templateConfig = currentTemplateConfigKey ? templateToConfigMap[currentTemplateConfigKey] : null;

  const handleOnViewOrEditExistingDraftClick = useEventCallback(() => {
    if (templateConfig) {
      const draftIds = templateConfig.matchingDraftIds;
      // If there are multiple drafts, go to the drafts page
      if (draftIds.length > 1) {
        onClose();
        // Wait for the dialog to close before navigating
        setTimeout(() => {
          existingDraftsWarningDialogViewAllOrEditExistingButtonInteracted({
            themeId: templateConfig.template.themeId,
            variant: 'viewAll',
            templateCategory: templateConfig.template.category,
            existingTemplateDraftsCount: draftIds.length
          });
          goToCardDrafts();
        }, 500);
      } else {
        existingDraftsWarningDialogViewAllOrEditExistingButtonInteracted({
          themeId: templateConfig.template.themeId,
          variant: 'editExisting',
          templateCategory: templateConfig.template.category,
          existingTemplateDraftsCount: draftIds.length
        });
        // If there is only one draft, go to the customize draft page
        if (isDigital) {
          // TODO: check if digital drafts exist instead of all kind of drafts matching the template. (backend required)
          goToDigitalCustomizeDraft(draftIds[0]);
        } else {
          goToCustomizeDraft(draftIds[0]);
        }
      }
    }
  });
  const handleOnCreateNewDraftClick = useEventCallback(() => {
    // Call the createNewDraftCallback that we collected when we determined there 1 or more drafts
    // and that the user should see the dialog
    createNewDraftCallbackRef.current?.(templateConfig?.matchingDraftIds.length ?? 0);
    if (templateConfig) {
      existingDraftsWarningDialogCreateNewDraftButtonInteracted({
        themeId: templateConfig.template.themeId,
        templateCategory: templateConfig.template.category,
        existingTemplateDraftsCount: templateConfig.matchingDraftIds.length
      });
    }
  });

  return (
    <Provider value={{ checkForExistingDraftOrCreateNew }}>
      {children}

      {/* Only show the dialog if there is a template config */}
      {templateConfig && (
        <ExistingDraftsWarningDialog
          isOpen={isOpen}
          handleOnClose={onClose}
          template={templateConfig.template}
          handleOnCreateNewDraftClick={handleOnCreateNewDraftClick}
          handleOnViewOrEditExistingDraftClick={handleOnViewOrEditExistingDraftClick}
          variant={templateConfig.matchingDraftIds.length > 1 ? 'multiple' : 'single'}
        />
      )}
    </Provider>
  );
};

export { ExistingDraftsWarningProvider, useExistingDraftsWarningContext };
