import { routePaths } from '@apps/account/route.paths';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { UserForMePageFragment } from '@graphql/generated';
import { useCallback, useEffect, useMemo } from 'react';
import { useChangePassword } from './hooks/useChangePassword';
import { useDeleteUser } from './hooks/useDeleteUser';
import { useUpdateUser } from './hooks/useUpdateUser';
import { useResetMFA } from './hooks/useResetMFA';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useUpdateSearchParam } from '@shared/utils/updateSearchParam';
import { withWindow } from '@shared/utils/withWindow';

interface Args
  extends Readonly<{
    id: Maybe<string>;
    user: UserForMePageFragment;
    provider?: string;
  }> {}

// i.e. withjoy.com/account/me?dialog=verifyEmailSuccess
const VERIFY_EMAIL_SUCCESS_SEARCH = {
  key: 'dialog',
  value: 'verifyEmailSuccess'
};

export const usePersonalInfoController = (args: Args) => {
  const queryParamHelper = useQueryParamHelper();
  const updateSearchParam = useUpdateSearchParam();
  const { id, user, provider } = args;
  const ownsEvents = !!user?.eventUsers.find(user => user.status == 'owner');
  const { deleteDialogOpen, mustLeaveEventsDialogOpen, handleDialogClose: handleDeleteDialogClose, handleShowDialog: handleDeleteDialogOpen } = useDeleteUser({ ownsEvents });
  const { personalInfo } = useAccountMeTelemetry();
  const {
    requestChangePassword,
    hideSuccess: hideChangePasswordSuccess,
    hideError: hideChangePasswordError,
    errorMessage: changePasswordError,
    showSuccess: changePasswordSuccessDialogIsShown
  } = useChangePassword();
  const { resetMFADialogOpen, handleDialogClose: handleResetMFADialogClose, handleShowDialog: handleResetMFADialogOpen } = useResetMFA();
  const { formik, status: userUpdateStatus, showErrorDialog, handleErrorDialogClose } = useUpdateUser({
    id: id || '',
    profile: { email: user?.email, firstName: user?.firstName, lastName: user?.lastName }
  });

  // TODO: email verified query
  const emailVerified = useMemo(() => user.emailIsVerified, [user.emailIsVerified]);
  const thirdPartyAuth = useMemo(() => provider !== 'auth0', [provider]);

  const handleChangePassword = requestChangePassword;
  const handleDeleteAccount = handleDeleteDialogOpen;
  const verifyEmailURL = `/account/${routePaths.verifyEmail.goToPath()}`;

  useEffect(() => {
    if (provider) {
      personalInfo.enter(provider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const shouldShowVerifyEmailSuccess = queryParamHelper.getValueString(VERIFY_EMAIL_SUCCESS_SEARCH.key) === VERIFY_EMAIL_SUCCESS_SEARCH.value;

  const handleHideVerifyEmailSuccess = useCallback(() => {
    updateSearchParam(VERIFY_EMAIL_SUCCESS_SEARCH.key, null);
  }, [updateSearchParam]);

  useEffect(() => {
    withWindow(() => {
      if (shouldShowVerifyEmailSuccess) {
        setTimeout(handleHideVerifyEmailSuccess, 5000);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formik,
    handleDeleteAccount,
    handleChangePassword,
    verifyEmailURL,
    changePasswordSuccessDialogIsShown,
    changePasswordError,
    hideChangePasswordError,
    hideChangePasswordSuccess,
    userUpdateStatus,
    deleteDialogOpen,
    mustLeaveEventsDialogOpen,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    emailVerified,
    thirdPartyAuth,
    showErrorDialog,
    handleErrorDialogClose,
    shouldShowVerifyEmailSuccess,
    handleHideVerifyEmailSuccess,
    handleResetMFADialogClose,
    handleResetMFADialogOpen,
    resetMFADialogOpen
  };
};
