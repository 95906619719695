import React, { useState } from 'react';
import { Flex } from '@withjoy/joykit';
import { CSSProperties, Children, FC, useLayoutEffect, useRef } from 'react';
import { baseWidth, baseHeight, desiredPadding, desiredPaddingMobile, pageEdgeMargin, pageEdgeMarginMobile, responsiveSettings } from '../ImagineYourCardRoot/cardConstants';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { BOX_SHADOW_16 } from '@apps/card/boxShadow';

// Roles:
// 1) Parent controls width.
// 2) Child controls aspect ratio (via height/width).
//    The Parent should follow with height auto.
// 3) This controls the scale and sets it on the child.
type PageScalerProps = Pick<CSSProperties, 'position'> & {
  onScaleChange?: (scale: number) => void;
  colsOverride?: number | undefined;
  widthCoeff?: number | undefined;
  useMargin?: boolean | undefined;
  onReady?: () => void | undefined;
  marginOverride?: number | undefined;
  hideShadow?: boolean;
};

const maxWidth = 600;
const maxScale = maxWidth / baseWidth;

export const ResponsivePageScaler: FC<PageScalerProps> = props => {
  const { children, onReady, onScaleChange, colsOverride, widthCoeff, useMargin, hideShadow, marginOverride, ...cssProps } = props;
  const columns = useResponsive(responsiveSettings, 1)[0];
  const mobile = columns <= 2;
  const [isScaled, setIsScaled] = useState(false);
  const [isSettled, setIsSettled] = useState(false);

  const gap = (mobile ? desiredPaddingMobile : desiredPadding) * 2;
  const pageMargin = marginOverride ?? mobile ? pageEdgeMarginMobile : pageEdgeMargin;
  const totalWidth = baseWidth + gap;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const self = ref.current;
    if (!self) return;

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      const child = self.firstElementChild as HTMLElement;
      if (!child) return;

      // Defer layout changes to the next frame to avoid Safari's:
      // "ResizeObserver loop completed with undelivered notifications."
      requestAnimationFrame(() => {
        const frameWidth = document.body.clientWidth * (widthCoeff || 1) - (useMargin ? pageMargin * 2 : 0);
        const avaliableWidth = frameWidth / (colsOverride || columns);
        const scale = Math.min(avaliableWidth / totalWidth, maxScale);
        self.style.height = `${baseHeight * scale}px`;
        self.style.width = `${child.clientHeight * scale * (5 / 7)}px`;
        child.style.position = 'absolute';
        child.style.scale = `${scale}`;
        onScaleChange?.(scale);

        if (!isScaled) {
          setIsScaled(true);
        }

        if (isScaled && self.style.display === 'flex') {
          setIsSettled(true);
          if (onReady) {
            onReady!();
          }
        }
      });
    });

    // The scale is a function of self and child, so observing both.
    resizeObserver.observe(document.body);
    return () => resizeObserver.disconnect();
  }, [colsOverride, columns, gap, isScaled, onReady, onScaleChange, pageMargin, ref, totalWidth, useMargin, widthCoeff]);

  return (
    <Flex
      ref={ref}
      position="relative"
      width="100%" // Match parent width by convention.
      {...cssProps} // Allows caller to override position relative.
      alignItems="center"
      justifyContent="center"
      style={{ boxShadow: hideShadow ? undefined : BOX_SHADOW_16, display: isScaled ? 'flex' : 'none', visibility: isSettled ? 'visible' : 'hidden' }} // Do not take up space until size is calculated && do not render until settled.
    >
      {Children.only(children)}
    </Flex>
  );
};
