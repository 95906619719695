import React from 'react';
import { Flex } from '@withjoy/joykit';
import { EcardDesignInputFields } from '../../../Design/Design.controller';
import TypeSelector from './components/TypeSelector';
import TrackGuestList from './components/TrackGuestList';
import MatchingStationery from './components/MatchingStationery';

interface SaveThedateLandingProps
  extends Readonly<{
    ecardInput: EcardDesignInputFields;
    eventHandle: string;
  }> {}

export const SaveThedateLanding: React.FC<SaveThedateLandingProps> = ({ eventHandle, ecardInput }) => {
  return (
    <Flex width="100%" alignItems="center" justifyContent="center" flexDirection="column" overflowX="hidden" paddingY={8}>
      <TypeSelector eventHandle={eventHandle} />
      <TrackGuestList eventHandle={eventHandle} />
      <MatchingStationery ecardInput={ecardInput} />
    </Flex>
  );
};
