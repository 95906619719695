import { useMemo, useEffect } from 'react';
import {
  GetEcardGuestExtraDataDocument,
  useGetEcardByGuidQuery,
  useGetEcardGuestExtraDataQuery,
  useJoinEventAsPersonWithPasswordMutation,
  useLogoutEventSessionMutation
} from '@graphql/generated';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { getFormattedEcardData } from './EcardGuest.utils';
import { useEcardGuestTelemetry } from './EcardGuest.telemetry';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useTranslation } from '@shared/core';

export interface EcardLookupPayload {
  ecardId: string;
  isAnonymous?: boolean;
  eventPassword?: string | null;
}

export const useEcardGuestController = () => {
  const { lookup } = useQueryParams();
  const { t } = useTranslation('emailsAndEcards');

  const { data, loading: dataLoading } = useGetEcardByGuidQuery({
    variables: {
      lookupId: lookup as string
    },
    batchMode: 'fast',
    fetchPolicy: 'no-cache'
  });
  const ecardData = useMemo(() => data?.getEcardRecepientDisplayByLookupId, [data]);
  const eventId = useMemo(() => ecardData?.eventId, [ecardData]);
  const decodedLookup: EcardLookupPayload = useMemo(() => {
    try {
      return JSON.parse(Buffer.from(lookup as string, 'base64').toString('ascii'));
    } catch {
      return {
        eventPassword: '',
        ecardId: '',
        isAnonymous: false
      };
    }
  }, [lookup]);

  const { data: ecardExtraData, loading: extraDataLoading } = useGetEcardGuestExtraDataQuery({
    variables: {
      eventId: eventId as string
    },
    skip: !eventId,
    batchMode: 'fast'
  });

  const [joinEvent] = useJoinEventAsPersonWithPasswordMutation();

  useEffect(() => {
    if (decodedLookup.isAnonymous && eventId) {
      joinEvent({ variables: { eventId, payload: { eventPassword: decodedLookup.eventPassword } }, refetchQueries: [GetEcardGuestExtraDataDocument], awaitRefetchQueries: true });
    }
  }, [decodedLookup, eventId, joinEvent]);

  const { ecardEventDesign, privacyMode, eventPassword, domain, website, linkOptions, displayName } = useMemo(() => {
    const ecardEventDesign = ecardExtraData?.eventById?.eventDesign;
    const privacyMode = ecardExtraData?.eventById?.settings?.privacyMode;
    const eventPassword = ecardExtraData?.eventById?.info.eventPassword;
    const domain = ecardExtraData?.eventById?.domain?.domainName;
    const website = ecardExtraData?.eventById?.website;
    const linkOptions = ecardExtraData?.getEcardLinkOptionsByEventId;
    const displayName = ecardExtraData?.eventById?.info.eventDisplayName;

    return {
      ecardEventDesign,
      privacyMode,
      domain,
      website,
      linkOptions,
      eventPassword,
      displayName
    };
  }, [ecardExtraData]);

  const guestNames = useMemo(() => ecardData?.householdDisplayName, [ecardData]);
  const formattedEcardData = useMemo(
    () => (ecardData && ecardExtraData && linkOptions ? getFormattedEcardData(ecardData, linkOptions, ecardExtraData, domain, website) : undefined),
    [ecardData, ecardExtraData, linkOptions, domain, website]
  );

  const { ECardViewedTrack } = useEcardGuestTelemetry();

  useEffect(() => {
    if (!!formattedEcardData && !!ecardEventDesign) {
      const telemetryData = { eventId: eventId ?? '', website: website ?? '', ecardType: formattedEcardData?.ecardDraftType ?? '' };
      ECardViewedTrack.enter(telemetryData);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedEcardData, ecardEventDesign]);

  const [logoutEvent] = useLogoutEventSessionMutation({
    variables: { eventId: formattedEcardData?.eventId },
    onCompleted: () => {
      performFullPageRedirect(`/${website}`);
    }
  });

  const onLogoutEventHandle = () => {
    logoutEvent();
  };

  const title = t('ecardGuest').title({ event: displayName });

  return {
    formattedEcardData,
    ecardEventDesign,
    guestNames,
    linkOptions,
    website,
    privacyMode,
    eventPassword,
    loading: dataLoading || extraDataLoading,
    onLogoutEventHandle,
    title
  };
};
