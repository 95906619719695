import { styled, Flex, TextV2 } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';

export const HeaderBar = styled(Flex)`
  position: absolute;
  top: 16px;
  left: 30px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    top: 0;
    left: 0;
    padding: 22px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mono3};
    width: 100%;
  }
`;

export const HeaderBarText = styled(TextV2)`
  ${InterFonts.page};
  letter-spacing: -0.015em;
  font-weight: bold;
  padding-left: 28px;
  margin-left: 28px;
  height: 48px;
  border-left: 1px solid ${({ theme }) => theme.colors.mono3};
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding-left: 16px;
    margin-left: 16px;
    font-size: 24px;
    height: 32px;
  }
`;
