import { useMemo } from 'react';
import { useTranslation } from '@shared/core/i18n';
import { withWindow } from '@shared/utils/withWindow';
import { useMarcomAppTelemetry } from '../MarcomApp.telemetry';
import type { HeaderLink, HeaderLinksType, RenderLinkProps } from './AppNavBar.types';
import { useShopStorefrontContext } from '@apps/registry/common/components/Catalog/components/ShopStorefrontProvider';
import { CatalogStorefront } from '@graphql/generated';

export const HEADER_LINKS: HeaderLink = {
  wedding: {
    planInvite: [
      { id: 'features-wedding-website', label: ({ planInvite }) => planInvite.weddingWebsite, href: '/wedding-website', target: '_blank' },
      { id: 'features-guest-list', label: ({ planInvite }) => planInvite.guestList, href: '/guest-list', target: '_blank' },
      { id: 'features-save-the-date', label: ({ planInvite }) => planInvite.saveTheDates, href: '/save-the-date', target: '_blank' },
      { id: 'features-wedding-invitations', label: ({ planInvite }) => planInvite.invitations, href: '/wedding-invitations', target: '_blank' },
      { id: 'features-smart-rsvp', label: ({ planInvite }) => planInvite.smartRsvp, href: '/online-rsvp', target: '_blank' },
      { id: 'features-hotel-room-blocks', label: ({ planInvite }) => planInvite.hotelRoomBlocks, href: '/hotel-room-blocks', target: '_blank' },
      { id: 'features-app', label: ({ planInvite }) => planInvite.mobileApp, href: '/app', target: '_blank' }
    ],
    registry: [
      { id: 'menu2registry', label: ({ registry }) => registry.weddingRegistry, href: '/registry', target: '_blank' },
      { id: 'menu2shop', label: ({ registry }) => registry.shopWedding, href: '/shop', target: '_blank' },
      { id: 'menu2cashfundregistry', label: ({ registry }) => registry.zeroFeeCashFunds, href: '/cash-fund-registry', target: '_blank' },
      { id: 'menu2honeymoonregistry', label: ({ registry }) => registry.honeymoonFunds, href: '/honeymoon-registry', target: '_blank' }
    ],
    expertAdvice: [
      {
        id: 'menu2blog',
        href: 'https://withjoy.com/blog/',
        target: '_blank',
        label: ({ expertAdvice }) => expertAdvice.weddingGuidesIdeas,
        mobileLabel: ({ expertAdvice }) => expertAdvice.weddingGuidesIdeasMobile,
        blogs: [
          {
            id: 'weddingBlogWeddingPlanning',
            href: 'https://withjoy.com/blog/category/wedding-planning/',
            target: '_blank',
            label: ({ expertAdvice }) => expertAdvice.blogs.weddingPlanning
          },
          { id: 'weddingBlogBudget', href: 'https://withjoy.com/blog/category/budget/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.budget },
          { id: 'weddingBlogTipsTricks', href: 'https://withjoy.com/blog/category/tips-and-tricks/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.tipsTricks },
          { id: 'weddingBlogVenues', href: 'https://withjoy.com/blog/category/venues/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.venues },
          { id: 'weddingBlogPhotography', href: 'https://withjoy.com/blog/category/photography/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.photography },
          { id: 'weddingBlogFoodDrink', href: 'https://withjoy.com/blog/category/food-drink/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.foodDrink },
          {
            id: 'weddingBlogWeddingGuests',
            href: 'https://withjoy.com/blog/category/wedding-guests/',
            target: '_blank',
            label: ({ expertAdvice }) => expertAdvice.blogs.weddingGuests
          },
          {
            id: 'weddingBlogRegistryGiftIdeas',
            href: 'https://withjoy.com/blog/category/registries/',
            target: '_blank',
            label: ({ expertAdvice }) => expertAdvice.blogs.registryGiftIdeas
          },
          {
            id: 'weddingBlogRealWeddings',
            href: 'https://withjoy.com/blog/category/real-weddings/',
            target: '_blank',
            label: ({ expertAdvice }) => expertAdvice.blogs.realWeddings
          },
          {
            id: 'weddingBlogProposalIdeas',
            href: 'https://withjoy.com/blog/category/proposal-ideas/',
            target: '_blank',
            label: ({ expertAdvice }) => expertAdvice.blogs.proposalIdeas
          },
          { id: 'weddingBlogHoneymoons', href: 'https://withjoy.com/blog/category/honeymoon/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.honeymoons },
          { id: 'weddingBlogViewAllCategories', href: 'https://withjoy.com/blog/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.viewAllCategories }
        ]
      },
      { id: 'menu2faq', href: '/faq', label: ({ expertAdvice }) => expertAdvice.faq, target: '_blank' },
      { id: 'menu2help', href: 'https://help.withjoy.com/', label: ({ expertAdvice }) => expertAdvice.support, target: '_blank' }
    ]
  },
  baby: {
    planInvite: [],
    registry: [
      { id: 'menu2babyregistry', label: ({ registry }) => registry.babyRegistry, href: '/baby-registry', target: '_blank' },
      { id: 'menu2babyshop', label: ({ registry }) => registry.shopBabyGift, href: '/shop/baby', target: '_blank' },
      { id: 'menu2babycashfundregistry', label: ({ registry }) => registry.zeroFeeCashFunds, href: '/shop/baby/catalog/baby-cash-funds', target: '_blank' }
    ],
    expertAdvice: [
      {
        id: 'menu2blog',
        href: 'https://withjoy.com/baby-guides/',
        label: ({ expertAdvice }) => expertAdvice.babyParentingGuides,
        mobileLabel: ({ expertAdvice }) => expertAdvice.babyParentingGuidesMobile,
        target: '_blank',
        blogs: [
          { id: 'babyBlogPregnancy', href: 'https://withjoy.com/baby-guides/category/pregnancy/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.pregnancy },
          { id: 'babyBlogPlanning', href: 'https://withjoy.com/baby-guides/category/planning/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.planning },
          { id: 'babyBlogParties', href: 'https://withjoy.com/baby-guides/category/parties/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.parties },
          { id: 'babyBlogParenting', href: 'https://withjoy.com/baby-guides/category/parenting/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.parenting },
          { id: 'babyBlogViewAllCategories', href: 'https://withjoy.com/baby-guides/', target: '_blank', label: ({ expertAdvice }) => expertAdvice.blogs.viewAllCategories }
        ]
      },
      { id: 'menu2help', href: 'https://help.withjoy.com/baby-registry/', label: ({ expertAdvice }) => expertAdvice.support, target: '_blank' }
    ]
  }
};

export const useHeaderLinks = (type?: HeaderLinksType) => {
  const { t2 } = useTranslation('marcom');
  const { wwwNavigate } = useMarcomAppTelemetry();
  const { currentStorefront } = useShopStorefrontContext();

  const getLinkProps = useMemo(
    () => (id: string, href: string, nodeText: string, target?: string, mobileNodeText?: string): RenderLinkProps => {
      return {
        id,
        href,
        target,
        onClick: () => {
          wwwNavigate({ elementId: id, to: href, nodeText });
        },
        onKeyDown: (e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            wwwNavigate({ elementId: id, to: href, nodeText });
            if (href) {
              withWindow(global => {
                global.document.location.href = href;
              });
            }
          }
        },
        label: nodeText,
        mobileLabel: mobileNodeText
      };
    },
    [wwwNavigate]
  );

  const headerLinks = useMemo(() => {
    return HEADER_LINKS[currentStorefront];
  }, [currentStorefront]);

  const itemsToRender = useMemo(() => {
    const planInvite = t2('planInvite');
    const registry = t2('registry');
    const expertAdvice = t2('expertAdvice');

    const preparedLinks: Array<RenderLinkProps> = [];

    const headerLinks = type ? HEADER_LINKS[currentStorefront][type] : [];

    headerLinks.forEach(({ id, label, mobileLabel, href, target, blogs }) => {
      const preparedBlogLinks: Array<Omit<RenderLinkProps, 'blogs'>> = [];
      const nodeText = label({ planInvite, registry, expertAdvice });
      const mobileNodeText = mobileLabel?.({ planInvite, registry, expertAdvice });
      blogs?.forEach(({ id: blogId, label: blogLabel, href: blogHref, target: blogTarget }) => {
        const nodeTextBlog = blogLabel({ planInvite, registry, expertAdvice });
        preparedBlogLinks.push(getLinkProps(blogId, blogHref, nodeTextBlog, blogTarget));
      });
      preparedLinks.push({ ...getLinkProps(id, href, nodeText, target, mobileNodeText), blogs: preparedBlogLinks });
    });

    return preparedLinks;
  }, [type, t2, getLinkProps, currentStorefront]);

  const allItemsToRender = useMemo(() => {
    const planInvite = t2('planInvite');
    const registry = t2('registry');
    const expertAdvice = t2('expertAdvice');

    const preparedLinks: Array<RenderLinkProps> = [];

    // To get all menus to make it aways available on mobile and desktop both dom
    const catalogStoreFrontKeys = Object.values(CatalogStorefront);

    catalogStoreFrontKeys.forEach((storefront: CatalogStorefront) => {
      const headerLinks: HeaderLinksType[] = ['planInvite', 'registry', 'expertAdvice'];
      headerLinks.forEach((type: HeaderLinksType) => {
        HEADER_LINKS[storefront][type].forEach(({ id, label, mobileLabel, href, target, blogs }) => {
          const preparedBlogLinks: Array<Omit<RenderLinkProps, 'blogs'>> = [];
          const nodeText = label({ planInvite, registry, expertAdvice });
          const mobileNodeText = mobileLabel?.({ planInvite, registry, expertAdvice });
          blogs?.forEach(({ id: blogId, label: blogLabel, href: blogHref, target: blogTarget }) => {
            const nodeTextBlog = blogLabel({ planInvite, registry, expertAdvice });
            preparedBlogLinks.push(getLinkProps(blogId, blogHref, nodeTextBlog, blogTarget));
          });
          preparedLinks.push({ ...getLinkProps(id, href, nodeText, target, mobileNodeText), blogs: preparedBlogLinks });
        });
      });
    });

    return preparedLinks;
  }, [t2, getLinkProps]);

  return {
    HEADER_LINKS,
    headerLinks,
    itemsToRender,
    allItemsToRender
  } as const;
};
