/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Bell',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M16.387 6.971l.003.02 1.026 6.202c.2 1.209 1.088 2.005 1.762 2.474a2.49 2.49 0 0 1 1.056 1.77H3.767a2.49 2.49 0 0 1 1.056-1.77c.674-.469 1.562-1.265 1.762-2.474l1.027-6.209.003-.019C7.901 4.8 9.757 3.13 12 3.13s4.102 1.674 4.387 3.841zm1.19-.156C17.214 4.058 14.856 1.93 12 1.93S6.79 4.054 6.424 6.807L5.4 12.997c-.119.717-.666 1.27-1.263 1.685-.959.667-1.586 1.776-1.586 3.032 0 .509.413.923.923.923h5.108c0 1.902 1.53 3.432 3.418 3.432a3.42 3.42 0 0 0 3.418-3.418l5.108-.014c.509 0 .923-.413.923-.923 0-1.256-.628-2.366-1.587-3.033-.597-.415-1.144-.967-1.263-1.684l-1.023-6.183zm-3.359 11.823H9.782A2.22 2.22 0 0 0 12 20.869a2.22 2.22 0 0 0 2.218-2.218z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
