import React from 'react';
import { StyledEditButton } from './EditButton.styles';
import { SaveTheDateEditorType } from '@apps/saveTheDate/SaveTheDate.types';

interface EditButtonProps
  extends Readonly<{
    isVisible: boolean;
    type: SaveTheDateEditorType;
    onEditToggle: () => void;
  }> {}

export const EditButton: React.FC<EditButtonProps> = ({ isVisible, type, onEditToggle, children }) => {
  return (
    <StyledEditButton $isVisible={isVisible} $type={type} onClick={onEditToggle}>
      {children}
    </StyledEditButton>
  );
};
