import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './PicturePerfect.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('picturePerfect')?.assetUrl || '';

export const PicturePerfect: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={39.5} left={119.5} width={331} height={376} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex width="100%" height="210px" padding="40px 40px" flexDirection="column" alignItems="flex-end" justifyContent="center">
          <TextV2
            color="#333"
            textAlign="right"
            fontSize="16px"
            fontFamily="Lato"
            fontWeight={600}
            lineHeight="19px"
            letterSpacing="0.1em"
            textTransform="uppercase"
            marginBottom="16px"
          >
            {formattedOwnerName}
            {formattedFianceeName ? ' &' : ''}
            {isFullNamesFormat && !!formattedFianceeName ? <br /> : ' '}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="flex-end">
              {formattedDate ? (
                <TextV2 color="#333" textAlign="right" fontSize={12.5} fontFamily="Lato" fontWeight={400} lineHeight="15px" letterSpacing="0.1em">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="#333" textAlign="right" fontSize={12.5} fontFamily="Lato" fontWeight={400} lineHeight="15px" letterSpacing="0.1em">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2
                  color="#333"
                  textAlign="right"
                  fontSize={12.5}
                  fontFamily="Lato"
                  fontWeight={400}
                  lineHeight="15px"
                  letterSpacing="0.1em"
                  fontStyle="italic"
                  marginTop="16px !important"
                >
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
