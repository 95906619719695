import React from 'react';
import { Box } from '@withjoy/joykit';
import { useCarouselContext } from './Carousel.controller';
import { styles } from './Carousel.styles';
import { CarouselProps } from './Carousel.types';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { CarouselItemsInline } from './components/CarouselItemsInline';
import { CarouselItemsDesktop } from './components/CarouselItemsDesktop';
import { CarouselItem } from './components/CarouselItem';

const Carousel: React.FC<CarouselProps> & { Item: typeof CarouselItem } = (props: CarouselProps) => {
  const isMobile = useIsMobileScreen();
  const content = isMobile ? <CarouselItemsInline {...props} /> : <CarouselItemsDesktop {...props} />;

  return <Main>{content}</Main>;
};

Carousel.Item = CarouselItem;

const Main: React.FC = props => {
  return <Box __css={styles.mainContainer} {...props} />;
};

export const CarouselSkeleton = ({ placeholder }: { placeholder: React.ReactNode }) => {
  const { elementsPerPage } = useCarouselContext();

  return (
    <>
      {Array.from({ length: elementsPerPage + 1 }).map((_, idx) => (
        <CarouselItem key={idx}>{placeholder}</CarouselItem>
      ))}
    </>
  );
};

export { Carousel };
