import { styled, TextV2 } from '@withjoy/joykit';

export const CardTypeRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0px 1.5rem 0px 1.5rem;

  border-bottom: 1px solid #ebebeb;
`;

export const CardType = styled(TextV2)`
  font-size: 1rem;
  padding: 0.75rem 0rem 0.75rem 0rem;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
`;
