import { ReactNode, useReducer } from 'react';

export type previewReducerAction = {
  type: string;
  newPreview?: ReactNode;
  role?: PreviewRole;
};

export enum PreviewRole {
  PREVIEW,
  DUMMY
}

export type Preview = {
  node: ReactNode;
  index: number;
  role: PreviewRole;
};

function previewReducer(state: Preview[], action: previewReducerAction): Preview[] {
  if (action.type === 'addPreview') {
    if (action.newPreview) {
      return [
        {
          node: action.newPreview,
          index: state.length,
          role: action.role ?? PreviewRole.PREVIEW
        },
        ...state
      ];
    }
  } else if (action.type === 'addDummy') {
    if (action.newPreview) {
      return [
        ...state,
        {
          node: action.newPreview,
          index: state.length,
          role: action.role ?? PreviewRole.DUMMY
        }
      ];
    }
  }
  return state;
}

export const usePreviewReducer = () => {
  const [previews, previewDispatcher] = useReducer(previewReducer, []);
  return { previews, previewDispatcher };
};
