import { InterFonts } from '@shared/utils/fonts/interFonts';
import { Box, styled } from '@withjoy/joykit';

export const VerifyEmailWrapper = styled(Box)``;

export const ContentWrapper = styled(Box)``;

export const Content = styled(Box)`
  max-width: 417px;
  & > * {
    margin-top: 44px;
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const StyledHeroImage = styled.img`
  width: 271px;
  height: auto;
`;

export const HeaderTitle = styled.h1`
  ${InterFonts.page}
  text-align: center;
`;

export const ContentCopy = styled(Box)`
  ${InterFonts.body}
  text-align: center;
`;

export const SendingStatus = styled.div`
  ${InterFonts.body}
  color: ${props => props.theme.colors.mono6};
`;
