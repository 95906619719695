import { ButtonV2, ButtonV2Props, Flex, FlexProps, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { StationeryTemplate } from './ProductDetailsPage.types';
import { useTranslation } from '@shared/core';

export type CustomizeNowButtonClickHandler = (template: StationeryTemplate, subtotalInMinorUnits: number) => void;

interface CustomizeNowButtonProps {
  isLoading: boolean;
  onCustomizeClick: () => void;
  size?: ButtonV2Props['size'];
  fullWidth?: boolean;
  marginTop?: FlexProps['marginTop'];
  marginBottom?: FlexProps['marginBottom'];
  hide?: boolean;
  subtext?: string;
  disabled?: boolean;
}
export const CustomizeNowButton = React.forwardRef<HTMLButtonElement, CustomizeNowButtonProps>((props, ref) => {
  const { disabled, fullWidth, hide, isLoading, marginTop, marginBottom, onCustomizeClick, size, subtext } = props;
  const { t } = useTranslation('stationery');
  const customizationPaneTrans = t('pdpModal', 'customizationPane');

  return (
    <Flex display={hide ? 'none' : 'flex'} flexDirection="column" rowGap={3} marginTop={marginTop} marginBottom={marginBottom} width={fullWidth ? '100%' : undefined}>
      <ButtonV2
        intent="neutral"
        shape="rounded"
        loading={isLoading}
        ref={ref}
        size={size}
        fullWidth={fullWidth}
        paddingX={size === 'sm' ? 6 : undefined}
        onClick={onCustomizeClick}
        disabled={disabled}
      >
        {customizationPaneTrans.customizeCta.ctaText()}
      </ButtonV2>
      {subtext && (
        <TextV2 typographyVariant="body1" color="mono12" textAlign="center">
          {subtext}
        </TextV2>
      )}
    </Flex>
  );
});
