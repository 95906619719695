import { styled, Box, Flex } from '@withjoy/joykit';

export const StyledThemeTitle = styled.span`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18.2px;
  letter-spacing: -0.02em;
`;

export const StyledThemeWrapper = styled(Box)<{ selected: boolean }>`
  background: ${props => props.theme.colors.white};
  border: ${props => `2px solid ${props.selected ? props.theme.colors.mono14 : props.theme.colors.mono3}`};
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: border 0.4s;
  &:hover {
    border: ${props => `2px solid ${props.selected ? props.theme.colors.mono14 : props.theme.colors.linkHover}`};

    ${StyledThemeTitle} {
      color: ${props => (props.selected ? props.theme.colors.mono14 : props.theme.colors.linkHover)};
    }
  }
  &:active {
    border: ${props => `2px solid ${props.theme.colors.linkActive}`};

    ${StyledThemeTitle} {
      color: ${props => props.theme.colors.linkActive};
    }
  }
`;

export const StyledThemeImage = styled.img`
  width: 100%;
  height: 196px;
  object-fit: cover;
  border-radius: 2px;
`;

export const StyledThemeTitleContainer = styled(Flex)`
  width: 140px;
  height: 48px;
  justify-content: center;
  align-items: center;
`;
