import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledAssetBackground, DetailsContainer, FloatingNamesConnector } from './GildedCalligraphy.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetArchUrl = getThemeInfo('gildedCalligraphy')?.assetUrl || '';

export const GildedCalligraphy: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage />
      <StyledAssetBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetArchUrl, renditionSize: 'medium' })}>
        <Flex width="100%" height="200px" padding="30px" flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
          <TextV2 color="white" textAlign="right" fontSize={14} fontFamily="Lora" fontWeight={600} lineHeight="25px" letterSpacing="0.2em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName && <FloatingNamesConnector isFullNamesFormat={!!isFullNamesFormat}> + </FloatingNamesConnector>}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="flex-end">
              {formattedDate ? (
                <TextV2 color="white" textAlign="right" fontSize={12} fontFamily="Lora" fontWeight={400} lineHeight="15px" letterSpacing="0.06em" textTransform="none">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="white" textAlign="right" fontSize={12} fontFamily="Lora" fontWeight={400} lineHeight="15px" letterSpacing="0.06em" textTransform="none">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="white" textAlign="right" fontSize={12} fontFamily="Lora" fontWeight={400} lineHeight="15px" letterSpacing="0.06em" textTransform="none">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledAssetBackground>
    </>
  );
};
