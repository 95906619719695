import * as React from 'react';
import { StylingProps } from '../../components/PeopleListItem/PeopleListItem.styles';
import { StyledLabelWrapper, StyledTag } from './LabelsListItem.styles';

interface Props {
  labelText: string;
  isSmall?: boolean;
}

export const LabelsListItem: React.FC<Props> = React.memo(({ labelText, isSmall }) => {
  const subProps: StylingProps = { isSmall };
  return (
    <StyledLabelWrapper {...subProps}>
      <StyledTag>{labelText}</StyledTag>
    </StyledLabelWrapper>
  );
});
