/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Add',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5846 7.45001C13.8884 7.45001 14.1346 7.69625 14.1346 8.00001C14.1346 8.30377 13.8884 8.55001 13.5846 8.55001L8.55002 8.54997L8.55005 13.5846C8.55005 13.8884 8.30381 14.1346 8.00005 14.1346C7.6963 14.1346 7.45005 13.8884 7.45005 13.5846L7.45002 8.54997L2.41535 8.54994C2.1116 8.54993 1.86535 8.30369 1.86536 7.99993C1.86536 7.69617 2.1116 7.44993 2.41536 7.44993L7.45001 7.44997L7.44998 2.41536C7.44997 2.1116 7.69622 1.86536 7.99997 1.86536C8.30373 1.86535 8.54997 2.1116 8.54998 2.41535L8.55001 7.44997L13.5846 7.45001Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M12.6 2.00002C12.6 1.66865 12.3314 1.40002 12 1.40002C11.6687 1.40002 11.4 1.66865 11.4 2.00002V11.4H2.00002C1.66865 11.4 1.40002 11.6687 1.40002 12C1.40002 12.3314 1.66865 12.6 2.00002 12.6H11.4V22C11.4 22.3314 11.6687 22.6 12 22.6C12.3314 22.6 12.6 22.3314 12.6 22V12.6H22C22.3314 12.6 22.6 12.3314 22.6 12C22.6 11.6687 22.3314 11.4 22 11.4H12.6V2.00002Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 4C17 3.44772 16.5523 3 16 3C15.4477 3 15 3.44772 15 4V15H4C3.44772 15 3 15.4477 3 16C3 16.5523 3.44772 17 4 17H15V28C15 28.5523 15.4477 29 16 29C16.5523 29 17 28.5523 17 28V17H28C28.5523 17 29 16.5523 29 16C29 15.4477 28.5523 15 28 15H17V4Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
