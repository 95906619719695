import { animationTransition } from '@shared/utils/animationTransition';
import { Box, BoxProps } from '@withjoy/joykit';
import { HeartFilled, HeartStroke } from '@withjoy/joykit/icons';
import React from 'react';

interface FavoriteButtonProps extends Pick<BoxProps<'button'>, 'top' | 'right' | 'position' | 'onClick'> {
  isLoading: boolean;
  isFavorite: boolean;
}
export const FavoriteButton = ({ isFavorite, isLoading, onClick, ...restProps }: FavoriteButtonProps) => {
  return (
    <Box
      as="button"
      backgroundColor="transparent"
      onClick={isLoading ? undefined : onClick}
      disabled={isLoading}
      opacity={isLoading ? 0.75 : 1}
      cursor={isLoading ? 'progress' : 'pointer'}
      transition={animationTransition('opacity', 'transform')}
      _hover={{
        transform: 'scale(1.15)'
      }}
      {...restProps}
      title={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
      style={{
        WebkitTapHighlightColor: 'transparent'
      }}
    >
      <Box position="relative" size={32} color="#4951EF">
        <HeartFilled left={0} size={32} opacity={isFavorite ? 1 : 0} title="" position="absolute" transition={animationTransition('opacity')} />
        <HeartStroke left={0} size={32} title="" position="absolute" />
      </Box>
    </Box>
  );
};
