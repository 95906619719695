import { Location } from 'history';
import { useEffect, useMemo } from 'react';

import { useHistory } from '@react-router';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';

const updateSearchParamValue = (paramName: string, paramValue: string | null, location: Location, replace: (location: Location) => void) => {
  // Build the new search params from window.location, so that we're always using the latest value.
  // This allows multiple updates to managed search params to happen correctly in the same render cycle.
  const windowLocation = withWindow(window => window.location, null);
  const newSearch = new URLSearchParams(windowLocation?.search);
  if (paramValue === null) {
    newSearch.delete(paramName);
  } else {
    newSearch.set(paramName, paramValue);
  }

  replace({
    ...location,
    search: newSearch.toString()
  });
};

/**
 * Hook which stores and assists with managing state in a URL query parameter.
 *
 * @param searchParamName Name of the search param.
 * @param defaultValue Initial value of the search param if it's not present in the URL.
 */
export function useManagedSearchParam(
  searchParamName: string,
  defaultValue?: string | null
): [queryParamValue: string | null, setQueryParamValue: (nextValue: string | null) => void] {
  const { location, replace } = useHistory();

  const queryParamValue = useMemo(() => {
    return new URLSearchParams(location.search).get(searchParamName);
  }, [location, searchParamName]);

  useEffect(() => {
    if (defaultValue !== undefined && !queryParamValue) {
      updateSearchParamValue(searchParamName, defaultValue, location, replace);
    }

    // This useEffect is intended to only run on first render to set initial search param value.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQueryParamValue = useEventCallback((nextValue: string | null) => {
    updateSearchParamValue(searchParamName, nextValue, location, replace);
  });

  return [queryParamValue, setQueryParamValue];
}
