import { SaveTheDateEmailInputFields } from '@apps/saveTheDate/SaveTheDate.types';
import { ElectronicCardEmailDraftInput } from '@graphql/generated';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '@apps/saveTheDate/constants';

export const getEmailPayload = (emailInput: SaveTheDateEmailInputFields, ecardId: string, displayDateString?: string | null): ElectronicCardEmailDraftInput => {
  return {
    buttonText: emailInput.buttonText,
    date: displayDateString,
    ecardId,
    ecardType: SAVE_THE_DATE_PHOTO_ECARD_TYPE,
    imageUrl: emailInput.imageUrl,
    includeAddToCalendar: emailInput.includeAddToCalendarLink,
    includeDate: emailInput.includeEventDate,
    includeGuestNames: emailInput.includeGuestNames,
    message: emailInput.message,
    replyToEmail: emailInput.replyToEmail,
    salutation: emailInput.salutation || '',
    subject: emailInput.subject,
    subTitle: emailInput.subheadline,
    title: emailInput.headline
  };
};

export const getEmailDraftValues = (emailInputDraft: ElectronicCardEmailDraftInput): SaveTheDateEmailInputFields => {
  return {
    buttonText: emailInputDraft.buttonText,
    imageUrl: emailInputDraft.imageUrl,
    includeAddToCalendarLink: emailInputDraft.includeAddToCalendar,
    includeEventDate: emailInputDraft.includeDate,
    includeGuestNames: !!emailInputDraft.includeGuestNames,
    message: emailInputDraft.message,
    replyToEmail: emailInputDraft.replyToEmail,
    salutation: emailInputDraft.salutation,
    subject: emailInputDraft.subject,
    subheadline: emailInputDraft.subTitle,
    headline: emailInputDraft.title
  };
};
