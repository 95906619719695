import React, { useMemo } from 'react';
import { SubTitle, StyledLabel, InputLengthLabel } from '../Shared.styles';
import { ToggleWrapper, ToggleTitle, ToggleDesc, YourURLBox, YourURLText, InputHelperButton, SelectorHiddenBox } from './EditCardContentForm.styles';
import HeadlessSelector from '../HeadlessSelector';
import { Flex, FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import { FormikProps } from 'formik';
import { Switch } from '@headlessui/react';
import { StyledTextArea } from '@shared/components/EmailsAndEcards/components/shared';
import { prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import clsx from 'classnames';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import useEditCardContentFormController from './EditCardContentForm.controller';
import { SaveTheDateDesignInputFields } from '@apps/saveTheDate/SaveTheDate.types';

interface EditCardContentFormProps {
  formik: FormikProps<SaveTheDateDesignInputFields>;
  handleScrollToFront: () => void;
  handleScrollToBack: () => void;
}

export const EditCardContentForm: React.FC<EditCardContentFormProps> = ({ formik, handleScrollToBack, handleScrollToFront }) => {
  const { coupleNameFormat, dateFormat, locationStringFormat, inviteToFollow, additionalLink1, additionalLink2, additionalMessage, displayEventLink } = formik.values;

  const {
    isDateHidden,
    isLocationHidden,
    locationString,
    dateFormats,
    nameFormats,
    locationFormats,
    ecardLinkOptions,
    handleOpenSettingsClick,
    yourUrl,
    translations
  } = useEditCardContentFormController();

  const ecardLinkOptions1 = useMemo(
    () => (additionalLink2 && additionalLink2 !== additionalLink1 ? ecardLinkOptions.filter(({ value }) => value !== additionalLink2) : ecardLinkOptions),
    [additionalLink2, additionalLink1, ecardLinkOptions]
  );
  const ecardLinkOptions2 = useMemo(
    () => (additionalLink1 && additionalLink1 !== additionalLink2 ? ecardLinkOptions.filter(({ value }) => value !== additionalLink1) : ecardLinkOptions),
    [additionalLink1, additionalLink2, ecardLinkOptions]
  );

  return (
    <>
      <FormControl
        paddingTop="32px"
        label={<StyledLabel>{translations.nameFormatLabel}</StyledLabel>}
        error={formik.errors.coupleNameFormat}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <HeadlessSelector onFocus={handleScrollToFront} options={nameFormats} value={coupleNameFormat} onChange={value => formik.setFieldValue('coupleNameFormat', value)} />
      </FormControl>
      <Flex justifyContent="flex-end">
        <InputHelperButton onClick={() => handleOpenSettingsClick('name')}>{translations.editNameFormatLabel}</InputHelperButton>
      </Flex>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.dateFormatLabel}</StyledLabel>}
        error={formik.errors.dateFormat}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        {isDateHidden ? (
          <SelectorHiddenBox>Date hidden</SelectorHiddenBox>
        ) : (
          <HeadlessSelector onFocus={handleScrollToFront} options={dateFormats} value={dateFormat} onChange={value => formik.setFieldValue('dateFormat', value)} />
        )}
      </FormControl>
      <Flex justifyContent="flex-end">
        <InputHelperButton onClick={() => handleOpenSettingsClick('date')}>{translations.editDateFormatLabel}</InputHelperButton>
      </Flex>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.locationFormatLabel}</StyledLabel>}
        error={formik.errors.locationStringFormat}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        {isLocationHidden ? (
          <SelectorHiddenBox>{locationString || 'Location hidden'}</SelectorHiddenBox>
        ) : (
          <HeadlessSelector
            onFocus={handleScrollToFront}
            options={locationFormats}
            value={locationStringFormat}
            onChange={value => formik.setFieldValue('locationStringFormat', value)}
          />
        )}
      </FormControl>
      <Flex justifyContent="flex-end">
        <InputHelperButton onClick={() => handleOpenSettingsClick('location')}>{translations.editLocationFormatLabel}</InputHelperButton>
      </Flex>
      <FormControl
        paddingTop="8px"
        label={<StyledLabel>{translations.inviteLabel}</StyledLabel>}
        error={formik.errors.inviteToFollow}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <InputV2 {...formik.getFieldProps('inviteToFollow')} maxLength={30} placeholder={translations.invitePlaceholder} onFocus={handleScrollToFront} />
          <InputLengthLabel>{inviteToFollow.length} / 30</InputLengthLabel>
          {formik.getFieldMeta('inviteToFollow').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('inviteToFollow').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <SubTitle>{translations.additionalInformationTitle}</SubTitle>
      <FormControl
        paddingTop="32px"
        label={<StyledLabel>{translations.additionalLinksLabel}</StyledLabel>}
        error={formik.errors.additionalLink1}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <HeadlessSelector onFocus={handleScrollToBack} options={ecardLinkOptions1} value={additionalLink1} onChange={value => formik.setFieldValue('additionalLink1', value)} />
      </FormControl>
      <FormControl paddingTop="8px" error={formik.errors.additionalLink2} overrides={EmailsAndEcardsFormControlOverrides}>
        <HeadlessSelector onFocus={handleScrollToBack} options={ecardLinkOptions2} value={additionalLink2} onChange={value => formik.setFieldValue('additionalLink2', value)} />
      </FormControl>
      <FormControl
        paddingTop="24px"
        label={<StyledLabel>{translations.optionalMessageLabel}</StyledLabel>}
        error={formik.errors.additionalMessage}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <StyledTextArea
            {...formik.getFieldProps('additionalMessage')}
            resize={false}
            minRows={4}
            maxRows={8}
            tabIndex={0}
            maxLength={255}
            minimal={true}
            onFocus={handleScrollToBack}
          />
          <InputLengthLabel>{additionalMessage.length} / 255</InputLengthLabel>
          {formik.getFieldMeta('additionalMessage').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('additionalMessage').error}
            </TextV2>
          )}
        </>
      </FormControl>
      <SubTitle>{translations.websiteOptionsTitle}</SubTitle>
      <ToggleWrapper alignItems="center" justifyContent="space-between" paddingX="16px" paddingY="20px" marginTop="32px">
        <Flex flexDirection="column" alignItems="flex-start" marginRight="16px">
          <ToggleTitle>{translations.displayEventLink.title}</ToggleTitle>
          <ToggleDesc>{translations.displayEventLink.subtitle}</ToggleDesc>
        </Flex>
        <Switch
          checked={displayEventLink}
          onChange={() => {
            handleScrollToBack();
            formik.setFieldValue('displayEventLink', !displayEventLink);
          }}
          className={clsx('switch', displayEventLink && 'switch-active')}
        >
          <span />
        </Switch>
      </ToggleWrapper>
      <FormControl paddingTop="24px" label={<StyledLabel>{translations.yourURLLabel}</StyledLabel>}>
        <>
          <YourURLBox>
            <YourURLText>{prettyUrl(yourUrl)}</YourURLText>
          </YourURLBox>
          <Flex justifyContent="flex-end">
            <InputHelperButton onClick={() => handleOpenSettingsClick('url')}>{translations.editYourURLLabel}</InputHelperButton>
          </Flex>
        </>
      </FormControl>
    </>
  );
};
