import { ImageLayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';
import { StationeryTemplateWithThemeJson } from '@apps/card/routes/Dashboard/components/DesignsGallery/useDesignTemplates';
import { usePdpData } from '@apps/card/routes/Dashboard/components/ProductDetailsPage/usePdpData';
import { AIBackgroundPromptPayload, StationeryTemplateCategoryEnum, useaddAIBackgroundMutation } from '@graphql/generated';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { resolvePlaceholderIndex } from '../ImagineYourCardRoot/ImageStorageManager';
import PageWrapper from '../PageWrapper/PageWrapper';
import { ImageHistoryElement, ImageHistoryVariation } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { absolutePhotoUrl } from '@apps/card/routes/CardCustomizer/components/ImageLayer';
import { Setter } from '@apps/imagineYourCard/imagineYourCard.utils';
import { PreviewContext, PreviewContextPayload } from '../ImagineYourCardRoot/ImagineYourCardRoot';

type AICardPreviewProps = {
  template: StationeryTemplateWithThemeJson;
  promptPayload: AIBackgroundPromptPayload;
  index: number;
  setLoadingCount: Setter<number>;
};

type AICardPreviewContainerProps = {
  promptPayload: AIBackgroundPromptPayload;
  themeID: string;
  category: StationeryTemplateCategoryEnum;
  index: number;
  setLoadingCount: Setter<number>;
};

enum RequestState {
  INIT,
  LOADING,
  DONE,
  ERROR
}

const AICardPreviewContainer = ({ promptPayload, themeID, category, index, setLoadingCount }: AICardPreviewContainerProps) => {
  const { loading, template } = usePdpData(themeID, category);

  if (!loading && template) {
    return (
      <div>
        <AICardPreview setLoadingCount={setLoadingCount} promptPayload={promptPayload} template={template!} index={index} />{' '}
      </div>
    );
  } else if (loading) {
    return <div></div>;
  } else {
    return (
      <div>
        <p>Invalid template provided.</p>
      </div>
    );
  }
};

const AICardPreview = ({ template, promptPayload, index, setLoadingCount }: AICardPreviewProps) => {
  const page = template.themeJSON.card.front;
  const [state, setState] = useState(RequestState.INIT);
  const resource = useRef<string | undefined>('');
  const [get, { called, loading, data, error }] = useaddAIBackgroundMutation({
    fetchPolicy: 'no-cache'
  });

  if (!template.themeJSON.imageHistory) {
    template.themeJSON.imageHistory = new Array<ImageHistoryElement>();
  }

  const { mOverride, oomphOverride } = useContext(PreviewContext) as PreviewContextPayload;

  useEffect(() => {
    if (state === RequestState.INIT) {
      get({
        variables: {
          promptPayload,
          templateId: template.id,
          m: mOverride !== 'none' ? mOverride : undefined,
          oomph: oomphOverride !== 'none' ? Number(oomphOverride) : undefined
        }
      });
      setState(RequestState.LOADING);
      setLoadingCount(old => old + 1);
    } else if (state === RequestState.LOADING) {
      if (called && !loading) {
        if (data && !error) {
          setState(RequestState.DONE);

          const initialImage = (page.layers[0] as ImageLayerData).imageData.src;
          const url = data?.stationeryDraftAddAIBackground?.url!;
          const resourceID = data?.stationeryDraftAddAIBackground?.resourceID;

          template.themeJSON.imageHistory.push({
            type: 'upload',
            url: absolutePhotoUrl(initialImage)
          });
          (page.layers[0] as ImageLayerData).imageData.src = url;
          template.themeJSON.imageHistory.push({
            type: 'ai_prompt',
            url,
            promptPayload,
            resourceID
          } as ImageHistoryVariation);
          resource.current = resourceID;

          setLoadingCount(old => old - 1);
          resolvePlaceholderIndex(index, {
            url,
            category: template.category,
            themeID: template.themeId,
            resourceID,
            promptPayload,
            uploadTime: undefined
          });
        } else {
          setState(RequestState.ERROR);
          setLoadingCount(old => old - 1);
          //   (page.layers[4] as TextLayerData).textData.content = errorText;
          //   (page.layers[0] as ImageLayerData).imageData.src = errorURL;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, called, loading]);

  return <PageWrapper index={index} loading={state === RequestState.LOADING} resourceID={resource} promptPayload={promptPayload} template={template} page={page} />;
};

export default AICardPreviewContainer;
