/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Code',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1584 3.85755C14.2216 3.53227 14.0083 3.22235 13.6818 3.16533C13.3554 3.10831 13.0395 3.32578 12.9763 3.65106L9.7707 20.1424C9.70747 20.4677 9.92083 20.7776 10.2473 20.8346C10.5737 20.8916 10.8896 20.6742 10.9528 20.3489L14.1584 3.85755ZM7.57901 6.69883C7.80672 6.93958 7.79614 7.31933 7.5554 7.54703L3.03717 11.8205C2.87032 11.9783 2.87032 12.2439 3.03717 12.4017L7.5554 16.6752C7.79614 16.9029 7.80672 17.2827 7.57901 17.5234C7.35131 17.7642 6.97156 17.7747 6.73081 17.547L2.21259 13.2735C1.54519 12.6423 1.54519 11.58 2.21259 10.9487L6.73081 6.67522C6.97156 6.44752 7.35131 6.45809 7.57901 6.69883ZM16.4209 6.69883C16.1932 6.93958 16.2038 7.31933 16.4445 7.54703L20.9627 11.8205C21.1296 11.9783 21.1296 12.2439 20.9627 12.4017L16.4445 16.6752C16.2038 16.9029 16.1932 17.2827 16.4209 17.5234C16.6486 17.7642 17.0283 17.7747 17.2691 17.547L21.7873 13.2735C22.4547 12.6423 22.4547 11.58 21.7873 10.9487L17.2691 6.67522C17.0283 6.44752 16.6486 6.45809 16.4209 6.69883Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
