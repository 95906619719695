import { SignIn } from '@apps/createWedding/routes/SignIn';
import { CreateAccount } from '@apps/createWedding/routes/CreateAccount';
import { CreateWeddingForm } from '@apps/createWedding/routes/CreateWeddingForm';
import { SocialAccount } from '@apps/createWedding/routes/SocialAccount';
import { WeddingAlreadyExists } from '@apps/createWedding/routes/WeddingAlreadyExists';
import { ForgotPassword } from '@apps/createWedding/routes/ForgotPassword';
import { createContext } from '@shared/utils/createContext';
import { Survey } from '@apps/createWedding/routes/Survey';

interface ViewContext {
  changeView: (view: SupportedView) => void;
  currentView: SupportedView;
}

export const viewMap = {
  createAccount: CreateAccount,
  socialAccount: SocialAccount,
  createWeddingForm: CreateWeddingForm,
  questionnaire: Survey,
  weddingAlreadyExists: WeddingAlreadyExists,
  forgotPassword: ForgotPassword,
  signIn: SignIn
};

export type SupportedView = keyof typeof viewMap;

const [ViewProvider, useViewContext] = createContext<ViewContext>({ name: 'ViewContext' });

export { ViewProvider, useViewContext };
