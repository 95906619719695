import { Box, styled } from '@withjoy/joykit';
import { PHOTO_ECARD_DIMENSIONS } from '@apps/saveTheDate/constants';

export const PhotoECardWrapper = styled.div<{ $isECardEditPanelShown: boolean }>`
  height: 100%;
  width: ${({ $isECardEditPanelShown }) => ($isECardEditPanelShown ? `calc(100% - 376px)` : `100%`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  // TODO: adjust bottom padding after Bottom Sheet View is integrated
  padding: 0 0 180px 0;
  transition: width 0.4s;
  will-change: width;
  background: ${props => props.theme.colors.mono1};

  @media (max-width: 767px) {
    padding-top: 60px;
    width: 100%;
  }
`;

export const PhotoECardContainer = styled(Box)`
  width: ${PHOTO_ECARD_DIMENSIONS.width}px;
  position: relative;
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const PhotoECardScalableContainer = styled.div`
  will-change: transform;
  transform-origin: center top;
  width: ${PHOTO_ECARD_DIMENSIONS.width}px;
  min-width: ${PHOTO_ECARD_DIMENSIONS.width}px;
`;
