import React from 'react';
import { Box, ButtonV2, Flex, SpacingStack } from '@withjoy/joykit';
import { ReactComponent as EmailSentSvg } from '@assets/icons/sent-plane.svg';
import { useConfirmationScreenController } from './ConfirmationScreen.controller';
import { Hed, Dek, StyledLink, StyledIcon } from './ConfirmationScreen.styles';
import { EcardType, PostOfficeMessageType } from '@graphql/generated';

interface ConfirmationScreenProps
  extends Readonly<{
    eventHandle: string;
    selectedGuestsCount: number;
    redirect?: string;
    draftType?: EcardType | PostOfficeMessageType;
  }> {}

export const ConfirmationScreen: React.FC<ConfirmationScreenProps> = ({ eventHandle, selectedGuestsCount, redirect, draftType }) => {
  const { openGuestList, openPrintPaperCards, tConfirmationScreen, dekText } = useConfirmationScreenController({ eventHandle, selectedGuestsCount, redirect, draftType });
  return (
    <Flex flexDirection="column" alignItems="center" marginBottom={8}>
      <Flex flexDirection={['column', null, 'row']} alignItems={['center', null, 'flex-start']} justifyContent="center" width="100%" gap="4rem">
        <SpacingStack stack="vertical" alignItems="center" spacing={6} width="100%" maxWidth={335}>
          <Flex alignItems="center" justifyContent="center" width="100%" aspectRatio="632 / 462">
            <StyledIcon>
              <EmailSentSvg />
            </StyledIcon>
          </Flex>
          <Hed>{tConfirmationScreen.hed}</Hed>
          <Dek textAlign="center">
            {dekText}
            <StyledLink onClick={openGuestList}>{tConfirmationScreen.linkText}</StyledLink>.
          </Dek>
          <ButtonV2 onClick={openGuestList} variant="outline" intent="neutral" shape="rounded" fontWeight={600}>
            {tConfirmationScreen.buttonText}
          </ButtonV2>
        </SpacingStack>
        <SpacingStack stack="vertical" alignItems="center" spacing={6} width="100%" maxWidth={335}>
          <Box as="img" src="https://withjoy.com/assets/public/paper-invitations-charm.png" width="100%" />
          <Hed>{tConfirmationScreen.printTitle}</Hed>
          <Dek textAlign="center">{tConfirmationScreen.printDesc}</Dek>
          <ButtonV2 onClick={openPrintPaperCards} intent="neutral" shape="rounded" fontWeight={600}>
            {tConfirmationScreen.printAction}
          </ButtonV2>
        </SpacingStack>
      </Flex>
    </Flex>
  );
};
