import { useRef, useState } from 'react';
import { mergeRefs } from '@shared/utils/hooks/setRef';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { createContext } from '@shared/utils/createContext';
import { DescendantsManager } from './DescendantsManager';

const [DescendantsProvider, useDescendantsContext] = createContext<DescendantsManager<HTMLElement>>({ name: 'DescendantsProvider' });

const useDescendants = () => {
  const descendantsRef = useRef(new DescendantsManager());

  useIsomorphicLayoutEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      descendantsRef.current.destroy();
    };
  }, []);

  return descendantsRef.current;
};

const useDescendant = () => {
  const descendants = useDescendantsContext();
  const [index, setIndex] = useState(-1);
  const ref = useRef<HTMLElement>(null);

  useIsomorphicLayoutEffect(
    () => {
      return () => {
        if (!ref.current) {
          return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        descendants.unregister(ref.current);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useIsomorphicLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    const dataIndex = Number(ref.current.dataset.index);
    // eslint-disable-next-line compat/compat
    if (!Number.isNaN(dataIndex) && index != dataIndex) {
      setIndex(dataIndex);
    }
  });

  return {
    descendants,
    index,
    register: mergeRefs(descendants.register, ref)
  };
};

export { DescendantsProvider, useDescendants, useDescendant };
