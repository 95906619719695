import React from 'react';
import { FormikProps } from 'formik';
import { SaveTheDateEmailInputFields, SaveTheDateEditorType } from '@apps/saveTheDate/SaveTheDate.types';
import { ReactComponent as EditEmailSvg } from '@assets/icons/edit-email.svg';
import EmailWrapper from './components/EmailWrapper';
import { fontImportHook } from '@shared/utils/fonts/useFonts';
import { emailFonts } from '@shared/utils/fonts/emailFonts';
import { GuestFooter } from '@shared/components/EmailsAndEcards/components/Emails/components';
import EcardEmail from './components/EcardEmail';
import EditButton from '@apps/saveTheDate/components/EditButton';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

const Fonts = [emailFonts.IBM_Plex_Sans, emailFonts.IBM_Plex_Mono, emailFonts.IBM_Plex_Mono_Bold, emailFonts.Playfair_Display];
const [, FontStyles] = fontImportHook(Fonts);

interface EmailPreviewProps
  extends Readonly<{
    formik: FormikProps<SaveTheDateEmailInputFields>;
    isEmailEditPanelShown: boolean;
    toggleEmailEditPanel: () => void;
    loadingDraftData?: boolean;
    eventDate?: string;
    footerText?: string;
  }> {}

export const EmailPreview: React.FC<EmailPreviewProps> = ({ formik, isEmailEditPanelShown, toggleEmailEditPanel, loadingDraftData, eventDate, footerText }) => {
  const isMobile = useIsMobileScreen();

  if (!!loadingDraftData) {
    return null;
  }

  return (
    <EmailWrapper subject={formik.values.subject} isEmailEditPanelShown={isEmailEditPanelShown}>
      {!!FontStyles && <FontStyles />}
      <EcardEmail formik={formik} eventDate={eventDate} />
      <GuestFooter footerText={footerText} />
      {!isMobile && (
        <EditButton isVisible={!isEmailEditPanelShown} onEditToggle={toggleEmailEditPanel} type={SaveTheDateEditorType.EMAIL}>
          <EditEmailSvg />
        </EditButton>
      )}
    </EmailWrapper>
  );
};
