import { isAuthProviderError } from '@apps/createWedding/utils';
import { useAuth } from '@shared/components/AuthProvider';
import { useTranslation } from '@shared/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import { useAuthRedirectURLs } from '../useAuthRedirectURLs';
import { usePublicCardShopTelemetry } from '@apps/card/PublicShop/PublicShop.telemetry';

type SignInValues = {
  email: string;
  password: string;
};

export const useSignInController = () => {
  const auth = useAuth();
  const { t2 } = useTranslation('createWedding');
  const [submissionError, setSubmissionError] = useState<string>('');
  const errorTranslations = t2('signIn', 'errors');
  const { createOrSignupCtaClicked } = usePublicCardShopTelemetry();

  const generateAuthRedirectURls = useAuthRedirectURLs();
  const formik = useFormik<SignInValues>({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(errorTranslations.emailValid).required(errorTranslations.emailRequired),
      password: Yup.string().required(errorTranslations.passwordRequired)
    }).required(),
    onSubmit: async ({ email, password }) => {
      try {
        createOrSignupCtaClicked('signIn');
        const redirectURLs = generateAuthRedirectURls('loggedin', 'accountfailed');
        await auth.loginManager.loginWithUsernameAndPassword(email, password, redirectURLs.success, redirectURLs.failure);
      } catch (err) {
        if (isAuthProviderError(err)) {
          setSubmissionError(err.description);
        }
      }
    }
  });
  const getFieldErrorProps = useFieldErrors<SignInValues>(formik);

  return {
    formik,
    submissionError,
    getFieldErrorProps
  };
};
