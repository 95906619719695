import React from 'react';
import { Position, isVerticalPosition } from '../../common';
import { Overlay } from '../Overlay/Overlay';
import { DrawerV1Props } from './';
import { getTransitionValues } from './Drawer.utils';
import { useTransition } from 'react-spring';
import { Container as DrawerContainer } from './Drawer.styles';
import { BoxProps } from '../Box';

type DrawerDefaultProps = Required<Pick<DrawerV1Props, 'destroyOnClose' | 'height' | 'position' | 'width' | 'border'>>;

const drawerDefaultProps: DrawerDefaultProps = {
  destroyOnClose: false,
  height: 256,
  position: 'right',
  width: 256,
  border: 'lg'
};
/**
 * -----------------------------------
 * DEPRECATED -- Use `<DrawerV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { DrawerV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/DrawerV2/Drawer.stories.tsx`.
 */
const DrawerV1: React.FC<DrawerV1Props> = props => {
  const {
    border = drawerDefaultProps.border,
    children,
    destroyOnClose = drawerDefaultProps.destroyOnClose,
    className,
    position = drawerDefaultProps.position,
    title,
    height = drawerDefaultProps.height,
    width = drawerDefaultProps.width,
    isOpen,
    containerProps,
    ...overlayableProps
  } = props;

  const directionalStyles: BoxProps = {};
  if (isVerticalPosition(position)) {
    directionalStyles.width = '100%';
    directionalStyles.height = height;
  } else {
    directionalStyles.width = width;
    directionalStyles.height = '100%';
    directionalStyles.overflowY = 'scroll';
    directionalStyles.overflowX = 'hidden';
  }

  const drawerRef = React.createRef<HTMLDivElement>();
  const transitions = useTransition(isOpen, null, {
    ...getTransitionValues(position),
    reset: true,
    trail: isOpen ? 2000 : undefined
  });

  return (
    <Overlay {...overlayableProps} overlayKey="drawer" isOpen={isOpen} containerElement={drawerRef.current} isScrollContainer={true}>
      {transitions.map(({ item, key, props: animatedProps }) => {
        const translateProperty = isVerticalPosition(position as Position) ? 'translateY' : 'translateX';
        return (
          item && (
            <DrawerContainer
              key={key}
              ref={drawerRef}
              className={className}
              border={border}
              style={{
                transform: animatedProps.translate!.interpolate(x => `${translateProperty}(${x}) translateZ(0.01px)`)
              }}
              role="document"
              tabIndex={-1}
              position={position}
              {...directionalStyles}
              {...containerProps}
            >
              {children}
            </DrawerContainer>
          )
        );
      })}
    </Overlay>
  );
};

DrawerV1.displayName = 'Drawer';

export { DrawerV1 };
