import { useTranslation } from '@shared/core';
import { useMemo } from 'react';
import { useHeaderLinks } from '../../useHeaderLinks';
import { useTopLevelLinks } from '../../useTopLevelLinks';
import type { HeaderLinksType } from '../../AppNavBar.types';

export const useMobileView = () => {
  const { t2 } = useTranslation('marcom');
  const topLevelLinks = useTopLevelLinks();
  const { headerLinks } = useHeaderLinks();

  const resourceRedirectLinks = useMemo(() => {
    const headerLinksMenu: HeaderLinksType[] = ['planInvite', 'registry', 'expertAdvice'];
    return headerLinksMenu.reduce((acc, type) => {
      if (headerLinks[type].length > 0) {
        acc.push({ heading: t2('linkGroupHeaders', type), type });
      }
      return acc;
    }, [] as Array<{ heading: string; type: HeaderLinksType }>);
  }, [headerLinks, t2]);

  return {
    topLevelLinks,
    resourceRedirectLinks
  };
};
