import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { moreLikeMeBehavior } from '../PageWrapper/PageWrapper';

type ImagineGenerationArgs = {
  prompt: string;
  category: StationeryTemplateCategoryEnum;
};

type ImagineReimagineArgs = {
  resourceID: string;
  style: string;
  type: moreLikeMeBehavior;
  themeID: string;
};

type ImagineCustomizeArgs = {
  category: StationeryTemplateCategoryEnum;
  themeID: string;
  prompt: string;
  style: string;
};

const ACTION_CATEGORY = 'marketing.messaging.card';

const imagineTelemetry = createTelemetryObject({
  pages: {
    standalone: () => ({
      category: 'ImagineYourCard',
      page: 'standalone',
      pagePrefix: 'marketing'
    }),
    gallery: () => ({
      category: 'ImagineYourCard',
      page: 'gallery',
      pagePrefix: 'marketing'
    }),
    cardCustomizer: () => ({
      category: 'ImagineYourCard',
      page: 'cardCustomizer',
      pagePrefix: 'marketing'
    })
  },
  actions: {
    imagineImageGenerated: ({ category, prompt }: ImagineGenerationArgs) => ({
      action: 'ImagineImageGenerated',
      category: ACTION_CATEGORY,
      extraInfo: {
        prompt,
        category
      }
    }),
    imagineImageReimagined: ({ category, themeID, prompt, style, resourceID, type }: ImagineGenerationArgs & ImagineReimagineArgs) => ({
      action: 'ImagineImageReimagined',
      category: ACTION_CATEGORY,
      extraInfo: {
        prompt,
        style,
        resourceID,
        reimagineType: type,
        themeID,
        category
      }
    }),
    imagineImageCustomized: ({ prompt, style, themeID, category }: ImagineCustomizeArgs) => ({
      action: 'ImagineImageCustomized',
      category: ACTION_CATEGORY,
      extraInfo: {
        themeID,
        category,
        prompt,
        style
      }
    })
  }
});

const { TelemetryProvider: ImagineTelemetryProvider, useTelemetry: useImagineTelemetry } = createTelemetry(imagineTelemetry);

export { ImagineTelemetryProvider, useImagineTelemetry };
