import React from 'react';
import { Flex, ButtonV2, TextV2, Box } from '@withjoy/joykit';
import { CustomFlex, LeftImage, CenterImage, RightImage, Chip } from './Type.styles';
import { useTranslation } from '@shared/core';

interface TypeProps
  extends Readonly<{
    leftImageUrl?: string;
    centerImageUrl?: string;
    rightImageUrl?: string;
    imageUrl?: string;
    withChip?: boolean;
    title: string;
    subtitle: string;
    buttonTitle: string;
    buttonCallback: () => void;
  }> {}

export const Type: React.FC<TypeProps> = ({ leftImageUrl, centerImageUrl, rightImageUrl, imageUrl, withChip = false, title, subtitle, buttonTitle, buttonCallback }) => {
  const { t } = useTranslation('emailsAndEcards');
  const tEcardLandingLeftPane = t('ecardLanding', 'ecardLandingLeftPane');

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" minHeight="253px" width="100%" maxWidth="320px">
      {imageUrl ? (
        <Box as="img" src={imageUrl} width="100%" />
      ) : (
        <CustomFlex alignItems="center" justifyContent="center" width="100%" aspectRatio="632/462">
          <LeftImage width="140px" src={leftImageUrl} />
          <CenterImage width="140px" src={centerImageUrl} />
          <RightImage width="140px" src={rightImageUrl} />
        </CustomFlex>
      )}
      {withChip && (
        <Flex alignItems="center" justifyContent="center" height="36px">
          <Chip alignItems="center" justifyContent="center">
            <span>{tEcardLandingLeftPane.chipNew()}</span>
          </Chip>
        </Flex>
      )}
      <TextV2 typographyVariant="hed2" marginTop={6}>
        {title}
      </TextV2>
      <TextV2 typographyVariant="body2" maxWidth="360px" textAlign="center" marginTop="8px" marginBottom="32px">
        {subtitle}
      </TextV2>
      <ButtonV2 minWidth="224px" intent="neutral" shape="rounded" onClick={buttonCallback}>
        {buttonTitle}
      </ButtonV2>
    </Flex>
  );
};
