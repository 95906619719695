import { ApolloError } from '@apollo/client';
import { InteractionType } from '@apps/suggestions/components/SuggestionCard';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { DashboardPreviewTab } from './';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

const tabToTelemetryLabel: ReadonlyRecord<DashboardPreviewTab, string> = {
  cardFeed: 'Suggestions',
  websitePreview: 'Preview',
  invitesAndPaper: 'InvitesAndPaper'
};

export const dashboardPreviewTelemetry = createTelemetryObject({
  actions: {
    suggestionClick: (identifier: string) => ({
      action: 'SuggestionClick',
      category: 'admin.suggestions',
      label: identifier
    }),
    suggestionClose: (identifier: string) => ({
      action: 'SuggestionClose',
      category: 'admin.suggestions',
      label: identifier
    }),
    suggestionAutoClose: (identifier: string) => ({
      action: 'SuggestionAutoClose',
      category: 'admin.suggestions',
      label: identifier
    }),
    pollResponseClick: (identifier: string, interaction: InteractionType) => ({
      action: 'PollResponseClick',
      category: 'admin.suggestions',
      label: identifier,
      extraInfo: {
        interaction
      }
    }),
    offerEventSuggestionsError: (apolloError: ApolloError) => ({
      action: 'OfferEventSuggestions',
      actionType: 'error',
      category: 'admin.suggestions',
      extraInfo: {
        apolloError
      }
    }),
    updateEventSuggestionsError: (apolloError: ApolloError) => ({
      action: 'UpdateEventSuggestions',
      actionType: 'error',
      category: 'admin.suggestions',
      extraInfo: {
        apolloError
      }
    }),
    toggleViewClick: (tab: DashboardPreviewTab) => ({
      action: 'ToggleView',
      category: 'admin',
      label: tabToTelemetryLabel[tab]
    }),
    viewAllSuggestionsClick: () => ({
      action: 'SeeAllCardsClick',
      category: 'admin.suggestions'
    }),
    viewDisplayed: (tab: DashboardPreviewTab) => ({
      action: 'ViewDisplayed',
      category: 'admin',
      label: tabToTelemetryLabel[tab]
    }),
    previewDeviceToggle: (device: string) => ({
      action: 'PreviewPanelInteractions',
      category: 'DeviceToggle',
      label: device
    }),
    guestSiteViewOpen: () => ({
      action: 'PreviewPanelInteractions',
      category: 'GuestSiteViewLink'
    }),
    shareDialogOpen: () => ({
      action: 'PreviewPanelInteractions',
      category: 'ShareDialog',
      label: 'Open'
    }),
    shareDialogClose: () => ({
      action: 'PreviewPanelInteractions',
      category: 'ShareDialog',
      label: 'Close'
    }),
    shareDialogEdit: (option: string) => ({
      action: 'PreviewPanelInteractions',
      category: 'ShareDialog',
      label: `Edit ${option}`
    }),
    shareDialogCopy: (option: string) => ({
      action: 'PreviewPanelInteractions',
      category: 'ShareDialog',
      label: `Copy ${option}`
    }),
    printStationeryRedirectButtonClicked: (args: { isJoyPrintable: boolean; stationeryTemplateCategory: StationeryTemplateCategoryEnum; themeId: string | undefined }) => ({
      action: 'MessagingButtonInteracted',
      category: 'admin.invitesAndPaper',
      extraInfo: {
        ...args,
        name: 'stationeryPreview_explorePrintCta'
      }
    }),
    printSmartTipClicked: (isMatching: boolean, themeId: string) => ({
      action: 'ButtonInteracted',
      category: 'admin.dashboard',
      extraInfo: {
        name: 'admin.dashboard.printsmarttip',
        isWebsiteThemePrintable: isMatching,
        themeId
      }
    })
  }
});

const { TelemetryProvider, useTelemetry: useDashboardPreviewTelemetry } = createTelemetry(dashboardPreviewTelemetry);
export { TelemetryProvider, useDashboardPreviewTelemetry };
