import React from 'react';
import { FormikProps } from 'formik';
import { EmailInputFields, EmailType } from '@shared/components/EmailsAndEcards/Emails.types';
import { GenericEmail, EcardEmail } from '@shared/components/EmailsAndEcards/components/Emails';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import ThankYouEmail from '../../../Emails/ThankYou';

interface UseEmailPreviewControllerArgs
  extends Readonly<{
    emailType: EmailType;
    formik: FormikProps<EmailInputFields>;
    eventPassword?: Maybe<string>;
    eventDate?: string;
    isPasswordToggleShown?: boolean;
    isMessageLoading?: boolean;
  }> {}

export const useEmailPreviewController = (args: UseEmailPreviewControllerArgs) => {
  const { emailType, formik, eventPassword, eventDate, isPasswordToggleShown, isMessageLoading } = args;
  const { currentStepIndex: bottomSheetStep } = useBottomSheetState();
  const isMobile = useIsMobileScreen();

  const getEmailSpecificPreview = () => {
    switch (emailType) {
      case 'generic':
        return <GenericEmail formik={formik} eventPassword={eventPassword} isPasswordToggleShown={isPasswordToggleShown} />;
      case 'ecard':
        return <EcardEmail formik={formik} eventDate={eventDate} />;
      case 'thankyou':
        return <ThankYouEmail isMessageLoading={isMessageLoading} formik={formik} />;
      default:
        return null;
    }
  };

  return {
    getEmailSpecificPreview,
    isBottomSheetFirstStep: bottomSheetStep === 0,
    isMobile
  };
};
