import React from 'react';
import { useTranslation } from '@shared/core';
import { InheritSizeLink } from '@apps/createWedding/routes/CreateAccount/CreateAccount.styles';

export const PrivacyNoticeLink = () => {
  const { t } = useTranslation('createWedding');

  return (
    <InheritSizeLink typographyVariant="label1" target="_blank" href="https://privacy.withjoy.com/privacy-policy#california-privacy-notice" fontSize="inherit">
      {t('createAccount', 'privacyNotice')()}
    </InheritSizeLink>
  );
};
