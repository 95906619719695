import { useFeatureValue } from '@shared/core/featureFlags';

// const shadow = [
//   '  0px  2px  8px  0px hsla(35, 10%, 50%, 0.15)',
//   ' -8px 12px 14px -8px hsla(35, 10%, 49%, 1.00)',
//   '-14px 12px  8px -8px hsla(33, 13%, 58%, 0.20)',
//   '-26px 20px 11px -5px hsla(33, 13%, 58%, 0.27)'
// ].join(',');

// Sample payload in comments
const defaultGalleryGridStyle = {
  // "aspectRatioFrameBoxShadow": shadow,
  // "assetPreviewAspectRatioMobile": "8 / 10",
  // "assetPreviewFrameBackground": "hsl(0, 0%, 96%)",
  // "assetPreviewFrameGroupHoverBackground": "hsla(0, 0%, 20%, 0.1)",
  // "assetPreviewFramePaddingDesktop": 8,
  // "assetPreviewFramePaddingMobile": 4,
  // "badgeContainer": "frame",
  // "cardTileConciergeVideo": "frameless",
  // "diamondColor": "hsl(40, 60%, 60%)",
  // "diamondSize": 20,
  // "diamondTransform": "translateY(1px)",
  // "galleryGap": "3px",
  // "premiumBadgeAssetPreviewDisplay": "none",
  // "valuePropCardBorder": "1px solid hsl(0, 0%, 90%)",
  // "valuePropSize": "large"
};

export const useGalleryGridStyle = (): Record<string, string | number | undefined> => {
  const { payload } = useFeatureValue('printGalleryGridStyle');

  if (!payload || typeof payload !== 'object') {
    return defaultGalleryGridStyle;
  }

  return { ...defaultGalleryGridStyle, ...payload };
};
