import React from 'react';
import { ButtonV2, DialogV2, Divider, TextV2, Flex } from '@withjoy/joykit';
import { useCustomizationContext } from '../../useCardCustomization';
import { useEditingMode } from '../../useEditingMode';
import { useNavigationContext } from '../../useNavigationState';
import { useTranslation } from '@shared/core';

interface QuantityDialogProps {
  isOpen: boolean;
  onClose: () => void;
  quantity: number;
  recipientsLength: number;
  goToReview?: () => void;
}

const MAX_QUANTITY = 400;
const INTERVAL = 10;

const getQuantityAdittion = (quantity: number, recipientsLength: number) => {
  // this handles scenarios when the quantity selected is lower than the interval (10), e.g. 4
  // in those cases we have to add either 0 or the interval based on the recipientsLength
  if (quantity < INTERVAL) {
    if (recipientsLength <= INTERVAL) {
      return 0;
    } else {
      return INTERVAL;
    }
  }
  return quantity;
};

const QuantityDialog: React.FC<QuantityDialogProps> = ({ isOpen, onClose, quantity, recipientsLength, goToReview }) => {
  const { mode } = useEditingMode();
  const [, updateCustomizations] = useCustomizationContext(draft => draft.config.customizations, mode === 'order');
  const { steps, goToStep } = useNavigationContext();
  const cardsStillNeeded = recipientsLength - quantity;
  const isCardsStillNeededInvalid = recipientsLength > MAX_QUANTITY;
  const getNewQuantity = Math.ceil(cardsStillNeeded / INTERVAL) * 10 + getQuantityAdittion(quantity, recipientsLength);
  const handleOnUpdateQuantity = () => {
    updateCustomizations(draft => {
      draft.quantity = getNewQuantity;
    });
    goToReview?.();
    onClose();
  };
  const { t } = useTranslation('stationery');
  const tQuantityDialog = t('cardCustomizer', 'quantityDialog');

  return (
    <DialogV2 isOpen={isOpen} onClose={onClose} disableCloseOnEscapeKeyClick disableCloseOnOutsideClick>
      <DialogV2.Header>
        <TextV2 typographyVariant="hed3" paddingRight="20px">
          {!!goToReview ? tQuantityDialog.titleGoToReview() : tQuantityDialog.titleChangeQuantity()}
        </TextV2>
        <DialogV2.CloseButton onClick={onClose} />
      </DialogV2.Header>
      <DialogV2.Body>
        {!!!goToReview && !isCardsStillNeededInvalid && (
          <TextV2 marginTop="12px" marginBottom="32px">
            {tQuantityDialog.subtitle({ recipientsLength, quantity })}
          </TextV2>
        )}
        {isCardsStillNeededInvalid && (
          <TextV2 marginTop="12px" marginBottom="32px">
            {tQuantityDialog.cardsNeededInvalid({ recipientsLength, maxQuantity: MAX_QUANTITY })}
          </TextV2>
        )}
        <Flex justifyContent="space-evenly" marginBottom="43px">
          <Flex alignItems="center" justifyContent="center" flexDirection="column" width="64px">
            <TextV2 typographyVariant="hed5" color="mono14">
              {quantity}
            </TextV2>
            <TextV2 typographyVariant="label2" color="mono14" textAlign="center">
              {tQuantityDialog.cardsWithEnvelopes()}
            </TextV2>
          </Flex>
          <Divider orientation="vertical" />
          <Flex alignItems="center" justifyContent="center" flexDirection="column" width="64px">
            <TextV2 typographyVariant="hed5" color="mono14">
              {recipientsLength}
            </TextV2>
            <TextV2 typographyVariant="label2" color="mono14" textAlign="center">
              {tQuantityDialog.recipientsSelected()}
            </TextV2>
          </Flex>
          <Divider orientation="vertical" />
          <Flex alignItems="center" justifyContent="center" flexDirection="column" width="64px">
            <TextV2 typographyVariant="hed5" color="warning6">
              {cardsStillNeeded}
            </TextV2>
            <TextV2 typographyVariant="label2" color="warning6" textAlign="center">
              {tQuantityDialog.cardsStillNeeded()}
            </TextV2>
          </Flex>
        </Flex>
      </DialogV2.Body>
      <Divider />
      <DialogV2.Footer flexDirection="column" alignItems="center" justifyContent="center" rowGap="12px">
        {isCardsStillNeededInvalid || mode === 'order' ? (
          <ButtonV2 shape="rounded" intent="neutral" size="lg" as="a" href="mailto:printsupport@withjoy.com" width="312px" onClick={onClose}>
            {tQuantityDialog.getInTouch()}
          </ButtonV2>
        ) : (
          <ButtonV2 shape="rounded" intent="neutral" size="lg" onClick={handleOnUpdateQuantity} width="312px">
            {tQuantityDialog.increaseQuantity({ getNewQuantity })}
          </ButtonV2>
        )}
        {steps.envelope?.hasCompleted && (
          <ButtonV2
            shape="rounded"
            variant="outline"
            intent="neutral"
            size="lg"
            onClick={() => {
              goToStep('recipients');
              onClose();
            }}
            width="312px"
          >
            {tQuantityDialog.adjustSelection()}
          </ButtonV2>
        )}
      </DialogV2.Footer>
    </DialogV2>
  );
};

export default QuantityDialog;
