/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Design Email',
  definitions: {
    '24': {
      viewBox: '0 0 22 20',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.94043 1.92945V15.3101C0.94043 15.3779 0.951928 15.4453 0.974437 15.5092L1.0375 15.6885C1.04989 15.7237 1.06554 15.7577 1.08424 15.79L2.72388 18.6221C2.74352 18.656 2.76643 18.6879 2.7923 18.7174C2.91732 18.8597 3.07131 18.9736 3.24382 19.0515C3.41632 19.1295 3.60345 19.1698 3.79268 19.1698C3.98191 19.1698 4.16905 19.1295 4.34155 19.0515C4.51405 18.9736 4.66805 18.8597 4.79306 18.7174C4.81893 18.6879 4.84185 18.656 4.86149 18.6221L6.50112 15.79C6.51983 15.7577 6.53547 15.7237 6.54786 15.6885L6.61093 15.5092C6.63343 15.4453 6.64493 15.3779 6.64493 15.3101V1.92945C6.64493 1.53162 6.48604 1.15071 6.20422 0.87036C5.92251 0.590111 5.54109 0.433228 5.14403 0.433228H2.44133C2.04427 0.433228 1.66285 0.590111 1.38114 0.87036C1.09932 1.15071 0.94043 1.53162 0.94043 1.92945ZM2.44133 1.63323C2.36052 1.63323 2.28365 1.66519 2.22746 1.72109C2.17137 1.77689 2.14043 1.8519 2.14043 1.92945V3.82095H5.44493V1.92945C5.44493 1.8519 5.41399 1.77689 5.35791 1.72109C5.30171 1.66519 5.22484 1.63323 5.14403 1.63323H2.44133ZM5.44493 5.02095H2.14043V15.2076L2.15086 15.2373L3.7205 17.9485C3.72602 17.952 3.73186 17.9552 3.73796 17.958C3.75503 17.9657 3.7737 17.9698 3.79268 17.9698C3.81166 17.9698 3.83033 17.9657 3.8474 17.958C3.85351 17.9552 3.85934 17.952 3.86487 17.9485L5.4345 15.2373L5.44493 15.2076V5.02095Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7566 9.98653C18.1798 9.56115 18.1796 9.56099 18.1795 9.56084L18.1785 9.5599L18.1772 9.55863L18.1746 9.55603L18.1689 9.55059L18.156 9.53875C18.1468 9.53052 18.1358 9.52112 18.123 9.51113C18.0988 9.49222 18.0616 9.46587 18.0126 9.44147C17.9246 9.39772 17.702 9.3167 17.4534 9.44036C17.3648 9.48442 17.2885 9.5497 17.2313 9.63039L12.9531 15.6604L12.9524 15.6614C12.8695 15.7779 12.8205 15.9153 12.8113 16.0584C12.802 16.2019 12.8332 16.3449 12.901 16.4715C12.9688 16.5979 13.0705 16.7027 13.1942 16.7743C13.3148 16.8441 13.4518 16.8798 13.5906 16.878H17.6305C17.8221 16.878 18.0064 16.8024 18.1429 16.6667C18.2794 16.5308 18.3566 16.346 18.3566 16.1526V9.98653C18.3566 9.82685 18.2927 9.67345 18.1795 9.56084L17.7566 9.98653ZM14.4119 15.678L17.1566 11.8094V15.678H14.4119Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5938 0.741377C20.4834 0.708437 20.3663 0.70522 20.2543 0.732033C20.1423 0.758844 20.0391 0.814806 19.9554 0.894582C19.9275 0.921116 19.9023 0.950268 19.88 0.981633L7.89804 17.8576L7.89683 17.8593C7.78255 18.0214 7.71491 18.2119 7.70156 18.4101C7.68821 18.6083 7.7297 18.8061 7.82131 18.9822C7.91291 19.1582 8.05099 19.3055 8.22023 19.4084C8.38943 19.5113 8.58346 19.5659 8.78128 19.5667L8.78366 19.5667H20.117C20.3659 19.5667 20.6053 19.4684 20.7822 19.2923C20.9593 19.1162 21.0593 18.8766 21.0593 18.6262V16.2826L21.0594 16.278L21.0593 16.2735V13.5939L21.0594 13.5893L21.0593 13.5848V10.9053L21.0594 10.9007L21.0593 10.8961V8.21659L21.0594 8.212L21.0593 8.20741V5.5279L21.0594 5.52331L21.0593 5.51872V1.35587C21.0593 1.31078 21.0543 1.26584 21.0442 1.22188C21.0182 1.10859 20.9626 1.00455 20.8834 0.920231C20.8041 0.83591 20.7041 0.77432 20.5938 0.741377ZM19.5585 15.678H19.8593V14.1894H19.5585C19.2271 14.1894 18.9585 13.9207 18.9585 13.5893C18.9585 13.258 19.2271 12.9893 19.5585 12.9893H19.8593V11.5007H19.5585C19.2271 11.5007 18.9585 11.2321 18.9585 10.9007C18.9585 10.5693 19.2271 10.3007 19.5585 10.3007H19.8593V8.812H19.5585C19.2271 8.812 18.9585 8.54337 18.9585 8.212C18.9585 7.88063 19.2271 7.612 19.5585 7.612H19.8593V6.12331H19.5585C19.2271 6.12331 18.9585 5.85468 18.9585 5.52331C18.9585 5.19194 19.2271 4.92331 19.5585 4.92331H19.8593V3.08357L9.00824 18.3667H19.8593V16.878H19.5585C19.2271 16.878 18.9585 16.6094 18.9585 16.278C18.9585 15.9467 19.2271 15.678 19.5585 15.678Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
