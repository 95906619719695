import { styled } from '@withjoy/joykit';
import { NavLink } from '@shared/core';
import { animationTransition } from '@shared/utils/animationTransition';
import { animated } from 'react-spring';

export const NAV_HEIGHT = 96;

export const StyledNavRoot = styled(animated.nav)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${NAV_HEIGHT}px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px -8px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  z-index: 10;
  text-transform: capitalize;
`;

export const StyledNavWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-basis: 460px;
`;

export const StyledNavList = styled.ul`
  display: flex;
  flex-basis: 460px;
  justify-content: space-between;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;
  font-weight: 600;

  &.active {
    color: ${props => props.theme.colors.linkText};
  }

  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }
`;

export const Indicator = styled(animated.span)`
  position: absolute;
  bottom: 0;
  height: 4px;
  transition: ${animationTransition('background-color')};
  left: 0;
  width: 0;
  background-color: ${props => props.theme.colors.linkText};
  border-radius: 4px;
  z-index: 0;
`;
