import { Box } from '@withjoy/joykit';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import React from 'react';
import globalWindow from '@shared/core/globals';
import { config } from '@static/js/env.config';

type ButtonType = 'android' | 'apple';

interface Props
  extends Readonly<{
    type: ButtonType;
    width?: string;
  }> {}

function linkFor(type: ButtonType) {
  return `${config.clientUri}/getJoy?src=admin&dest=${type === 'apple' ? 'ios' : 'android'}`;
}

const ButtonOptions = {
  apple: {
    title: 'App Store',
    link: linkFor('apple'),
    logo: 'https://withjoy.com/assets/public/marketing-emails/apple-icon.png?version=1'
  },
  android: {
    title: 'Google Play',
    link: linkFor('android'),
    logo: 'https://withjoy.com/assets/public/marketing-emails/playstore-icon.png?version=1'
  }
};

const onButtonClick = (link: string) => {
  globalWindow.open?.(link);
};

export const AppButton: React.FC<Props> = props => {
  const { type, width } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="12px"
      padding="0px"
      fontSize="17px"
      fontWeight={700}
      backgroundColor="transparent"
      fontFamily={EmailFontFamilies.ibmPlexSans}
      border="solid 1px"
      borderColor="brandWarmGray2"
      onClick={() => onButtonClick(ButtonOptions[type].link)}
      color="mono14"
      width={width ? width : undefined}
      verticalAlign="center"
      height="56px"
      cursor="pointer"
    >
      <img style={{ height: '24px', width: 'auto', marginRight: '4px' }} alt={ButtonOptions[type].title} src={ButtonOptions[type].logo} />
      {ButtonOptions[type].title}
    </Box>
  );
};
