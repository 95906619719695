import React from 'react';
import { DialogV2, ButtonV2, TextV2, SpacingStack, InputV2, FormControl, CancelButton, IconV2 } from '@withjoy/joykit';
import { Email } from '@withjoy/joykit/icons';
import { useSendTestEmailDialog } from './SendTestEmailDialog.controller';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import { darkButtonOverride } from '@apps/postOffice/components/Shared.styles';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

export interface Props
  extends Readonly<{
    dialogId: string;
    isOpen: boolean;
    onClose: () => void;
    handleSendTestEmail: (testEmail: string, testName: string) => void;
    testEmail?: string;
    isMobileEmailEditor?: boolean;
  }> {}

export const SendTestEmailDialog: React.FC<Props> = props => {
  const isMobile = useIsMobileScreen();
  const { dialogId, isOpen, onClose, handleSendTestEmail, testEmail, isMobileEmailEditor = false } = props;
  const { handleCancel, formik, tUnsavedChangesDialog } = useSendTestEmailDialog({ handleSendTestEmail, onClose, testEmail });
  return (
    <DialogV2
      id={dialogId}
      isOpen={isOpen}
      onClose={handleCancel}
      size="sm"
      contentTransition={isMobile ? { out: { opacity: 0, scale: 1 }, in: { opacity: 1, scale: 1 } } : undefined}
    >
      <DialogV2.Header justifyContent="space-between" alignItems="center" paddingTop="24px">
        <TextV2 typographyVariant="hed6" style={{ fontWeight: 700 }}>
          <IconV2 size="md" marginRight="12px">
            <Email />
          </IconV2>{' '}
          {tUnsavedChangesDialog.header}
        </TextV2>
        <DialogV2.CloseButton zIndex="modal" color="mono14" />
      </DialogV2.Header>
      <DialogV2.Body>
        <SpacingStack spacing={4}>
          <FormControl label={tUnsavedChangesDialog.testNameLabel} error={formik.errors.testName} overrides={EmailsAndEcardsFormControlOverrides}>
            <>
              <InputV2
                {...formik.getFieldProps('testName')}
                placeholder={tUnsavedChangesDialog.testNamePlaceholder}
                {...(isMobileEmailEditor && { style: { fontSize: '16px' } })}
              />
              <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                {formik.getFieldMeta('testName').error}
              </TextV2>
            </>
          </FormControl>
          <FormControl label={tUnsavedChangesDialog.testEmailLabel} error={formik.errors.testEmail} overrides={EmailsAndEcardsFormControlOverrides}>
            <>
              <InputV2
                {...formik.getFieldProps('testEmail')}
                placeholder={tUnsavedChangesDialog.testEmailPlaceholder}
                {...(isMobile && isMobileEmailEditor && { style: { fontSize: '16px' } })}
              />
              <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                {formik.getFieldMeta('testEmail').error}
              </TextV2>
            </>
          </FormControl>
        </SpacingStack>
      </DialogV2.Body>
      <DialogV2.Footer flexDirection="column" alignItems="center" justifyContent="center" paddingX="32px" borderTop="1px solid #EBEBEB">
        <ButtonV2 overrides={darkButtonOverride} intent="primary" onClick={formik.submitForm} width="100%" marginBottom="16px">
          {tUnsavedChangesDialog.sendButtonText}
        </ButtonV2>
        <CancelButton onClick={handleCancel} width="100%">
          {tUnsavedChangesDialog.cancelButtonText}
        </CancelButton>
      </DialogV2.Footer>
    </DialogV2>
  );
};
