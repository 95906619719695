import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from '@react-router';
import { RoutePath, RoutesPaths, RoutesProvider, useRouterHelper } from '@shared/core';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import Me from './routes/Me';
import PersonalInfo from './routes/PersonalInfo';
import { AccountMe } from './AccountMe';
import { MustBeSignedIn } from '@shared/components/MustBeSignedIn/MustBeSignedIn';
import { JoyKitV2AdminTheme } from '@shared/utils/style/joykitV2AdminTheme';
import { useToggleIntercomLauncher } from '@shared/core/intercom';
import { useMeEventUsersEventInfoforMePage } from './hooks';

export const accountVendorsBaseUrl = 'account';
/**
 * Example Route name types. Defines the routes that are available
 */
type RoutesNames = 'events' | 'personalInfo';

/**
 * Example route paths. Has a path for the <Router /> and a function for history to push to.
 */
export const accountMeRoutePaths: RoutesPaths<RoutesNames> = {
  events: {
    path: `events`,
    goToPath: () => `events`
  },
  personalInfo: {
    path: `personal-info`,
    goToPath: () => `personal-info`
  }
};

const RoutesAccountMe: React.FC<{ appUrl: string }> = ({ appUrl }) => {
  const routeHelpers = useRouterHelper();
  const { pathname } = useLocation();

  const { data, loading } = useMeEventUsersEventInfoforMePage();
  const user = useMemo(() => data?.me, [data]);

  useToggleIntercomLauncher({ show: true });
  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(accountMeRoutePaths.personalInfo.path),
      component: () => (user && <PersonalInfo userId={user?.id} user={user} provider={user?.activeAlias?.provider} />) || null
    },
    {
      path: routeHelpers.buildPath(accountMeRoutePaths.events.path),
      component: () => <Me user={user} loading={loading} /> || null
    }
  ];

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {routes.map(route => (
        <Route key={route.path} path={route.path} render={route.component} />
      ))}
    </Switch>
  );
};

export const RouterAccountMe: React.FC<{ appUrl: string }> = ({ appUrl }) => {
  return (
    <RoutesProvider appUrl={appUrl}>
      <JoyKitThemeProvider theme={JoyKitV2AdminTheme}>
        <MustBeSignedIn>
          <AccountMe>
            <RoutesAccountMe appUrl={appUrl} />
          </AccountMe>
        </MustBeSignedIn>
      </JoyKitThemeProvider>
    </RoutesProvider>
  );
};
