import { createContext } from '@shared/utils/createContext';
import { callAllHandlers } from '@shared/utils/functions';
import { useControllableState } from '@shared/utils/hooks/useControllableState';
import { useCallback } from 'react';
import { useDescendant, useDescendants } from './useDescendants';
import { AccordionProps, AccordionItemProps } from '.';
import { addItem, removeItem } from '@shared/utils/array';
import { isArray } from '@shared/utils/assertions';
import { useIds } from '@withjoy/joykit/hooks';
import { PropGetter } from '@withjoy/joykit/utils';
import { mergeRefs } from '@shared/utils/hooks/setRef';

//---------------------------------------
// Accordion

type AccordionContext = Omit<ReturnType<typeof useAccordion>, 'descendants' | 'htmlProps'>;
const [AccordionProvider, useAccordionContext] = createContext<AccordionContext>({ name: 'AccordionProvider' });

export const useAccordion = (props: AccordionProps) => {
  const { defaultIndex, enableMulti, index: indexProp, onChange: onChangeProp, ...htmlProps } = props;

  const [expandedIndices, setExpandedIndices] = useControllableState({
    value: indexProp,
    defaultValue: () => {
      if (enableMulti) {
        return defaultIndex ?? [];
      }
      return defaultIndex ?? -1;
    },
    changeHandler: onChangeProp as (idx: number | number[]) => void
  });

  const descendants = useDescendants();

  const getAccordionItemProps = useCallback(
    (idx: number | null) => {
      let isOpen = false;

      if (idx !== null) {
        isOpen = isArray(expandedIndices) ? expandedIndices.includes(idx) : expandedIndices === idx;
      }

      const onToggle = (isOpen: boolean) => {
        if (idx === null) {
          return;
        }
        if (enableMulti && isArray(expandedIndices)) {
          const nextIndex: number[] = isOpen ? addItem(expandedIndices, idx) : removeItem(expandedIndices, idx);
          setExpandedIndices(nextIndex);
        } else if (isOpen) {
          setExpandedIndices(idx);
        } else {
          setExpandedIndices(-1);
        }
      };

      return {
        isOpen,
        onToggle
      } as const;
    },
    [expandedIndices, setExpandedIndices, enableMulti]
  );

  return {
    htmlProps,
    descendants,
    getAccordionItemProps
  };
};

export { AccordionProvider };

//---------------------------------------
// Accordion Item

interface AccordionItemContext extends Omit<ReturnType<typeof useAccordionItem>, 'htmlProps'> {}
const [AccordionItemProvider, useAccordionItemContext] = createContext<AccordionItemContext>({ name: 'AccordionItemProvider' });
export const useAccordionItem = (props: AccordionItemProps) => {
  const { id } = props;
  const { getAccordionItemProps } = useAccordionContext();
  const { index, register } = useDescendant();

  const { isOpen, onToggle } = getAccordionItemProps(index === -1 ? null : index);

  const [buttonId, panelId] = useIds(id, 'accordion-button', 'accordion-panel');

  const onClick = useCallback(() => {
    onToggle(!isOpen);
  }, [isOpen, onToggle]);

  const getButtonProps = useCallback<PropGetter<HTMLButtonElement>>(
    (props = {}, ref) => {
      return {
        ...props,
        id: buttonId,
        'aria-expanded': isOpen,
        'aria-controls': panelId,
        onClick: callAllHandlers(props.onClick, onClick),
        ref: mergeRefs(register, ref)
      };
    },
    [buttonId, panelId, isOpen, onClick, register]
  );

  const getPanelProps = useCallback<PropGetter<HTMLDivElement>>(
    props => {
      return {
        ...props,
        id: panelId,
        'aria-labelledby': buttonId,
        role: 'region'
      };
    },
    [buttonId, panelId]
  );

  return {
    isOpen,
    htmlProps: props,
    getButtonProps,
    getPanelProps
  };
};

export { AccordionItemProvider, useAccordionItemContext };
