import React, { useEffect } from 'react';
import { EcardDesignInputFields, useDesignController } from './Design.controller';
import EcardPreview from './components/EcardPreview';
import EcardEditPanel from './components/EcardEditPanel';
import { EcardEventDesign } from '@apps/ecard/Ecard.types';
import UnsavedChangesDialog from '@shared/components/EmailsAndEcards/components/Dialogs/UnsavedChangesDialog';
import { PrivacyModeType, ElectronicCardCardLinkOptionFragment, EcardType, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { withWindow } from '@shared/utils/withWindow';
import { PremiumDigitalUpsellDialog } from '../../../../../apps/saveTheDate/routes/Design/components/PremiumDigitalUpsell';

interface DesignProps
  extends Readonly<{
    ecardInput: EcardDesignInputFields;
    updateEcardInput?: (ecardInput: EcardDesignInputFields) => void;
    eventDesign?: EcardEventDesign | null;
    eventHandle: string;
    eventDisplayName: string;
    privacyMode?: PrivacyModeType;
    eventPassword?: Maybe<string>;
    linkOptions?: ElectronicCardCardLinkOptionFragment[];
    editCardDesignTrack?: (ecardType: EcardType) => void;
    editCardContentTrack?: (ecardType: EcardType) => void;
  }> {}

export const Design: React.FC<DesignProps> = ({
  ecardInput,
  updateEcardInput,
  eventDesign,
  eventHandle,
  eventDisplayName,
  privacyMode,
  eventPassword,
  linkOptions,
  editCardDesignTrack,
  editCardContentTrack
}) => {
  const {
    ecardFormik: formik,
    handleToggleEdit,
    handleCancel,
    isEditShown,
    selectedEditorType,
    handleSetSelectedEditorType,
    isUnsavedChnangesDialogOpen,
    handleUnsavedChnangesDialogClose,
    handleUnsavedChnangesDialogConfirm,
    previewFrontRef,
    previewBackRef,
    handleScrollToFront,
    handleScrollToBack
  } = useDesignController({
    ecardInput,
    updateEcardInput,
    editCardDesignTrack,
    editCardContentTrack
  });

  const { designCardTrack } = useEcardsTelemetry();

  useEffect(() => {
    designCardTrack.enter(ecardInput.ecardDraftType);
    withWindow(global => {
      global.scrollTo(0, 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <EcardPreview
        isEditShown={isEditShown}
        toggleEdit={handleToggleEdit}
        ecardData={formik.values}
        eventDesign={eventDesign}
        privacyMode={privacyMode}
        eventPassword={eventPassword}
        eventHandle={eventHandle}
        previewFrontRef={previewFrontRef}
        previewBackRef={previewBackRef}
      />
      <EcardEditPanel
        toggleEcardEditor={handleToggleEdit}
        handleCancelClick={handleCancel}
        show={isEditShown}
        formik={formik}
        selectedEditorType={selectedEditorType}
        handleSetSelectedEditorType={handleSetSelectedEditorType}
        eventDesign={eventDesign}
        eventHandle={eventHandle}
        handleScrollToFront={handleScrollToFront}
        handleScrollToBack={handleScrollToBack}
        linkOptions={linkOptions}
        eventDisplayName={eventDisplayName}
      />
      <UnsavedChangesDialog
        isOpen={isUnsavedChnangesDialogOpen}
        onClose={handleUnsavedChnangesDialogClose}
        onConfirm={handleUnsavedChnangesDialogConfirm}
        dialogId="unsaved-changes-dialog-ecard-editor"
        warningMessageContent="ecard"
      />
      <PremiumDigitalUpsellDialog
        eventId={formik.values.eventId}
        eventHandle={eventHandle}
        themeId={formik.values.joyTheme?.themeId || ''}
        templateCategory={formik.values.ecardDraftType === EcardType.savethedate ? StationeryTemplateCategoryEnum.saveTheDate : StationeryTemplateCategoryEnum.invitation}
      />
    </div>
  );
};
