import { useEffect, useState } from 'react';

type UseMarcomAppNavBarArgs = Readonly<{
  isMobile: boolean;
}>;

export const useAppNavBar = ({ isMobile }: UseMarcomAppNavBarArgs) => {
  const [isMobileNavOpen, setMobileNavIsOpen] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setMobileNavIsOpen(false);
    }
  }, [isMobile]);

  return { isMobileNavOpen, setMobileNavIsOpen };
};
