import React from 'react';
import { ButtonV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import capitalize from 'lodash/capitalize';
import { useTranslation } from '@shared/core';
import { SocialAccountWrapper } from './SocialAccount.styles';
import { useAuth } from '@shared/components/AuthProvider';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { wrapWithSocialAccountHealthProvider } from './SocialAccount.health';

export const SocialAccount = wrapWithSocialAccountHealthProvider(() => {
  const { t, t2 } = useTranslation('createWedding');
  const { provideLogout, currentUser } = useAuth();
  const telemetry = useCreateWeddingTelemetry();
  const translations = t2('socialAccount');

  const socialProvider = currentUser.profile?.activeAlias?.provider;
  const handleCtaClick = () => {
    telemetry.socialIntercept(socialProvider);
    provideLogout({}, `${window.location.origin}/${CREATE_WEDDING_APP_NAME}`);
  };

  return (
    <SocialAccountWrapper spacing={12}>
      <SpacingStack spacing={4} paddingX={2}>
        <TextV2 textAlign="center" typographyVariant="hed3" marginBottom={4}>
          {translations.createEventTitle}
        </TextV2>

        <TextV2 textAlign="center" marginBottom={4}>
          {t('socialAccount', 'currentAuthDisclaimer')({ provider: capitalize(socialProvider) })}
        </TextV2>

        <TextV2 textAlign="center" marginBottom={4}>
          {translations.goodNews}
        </TextV2>
        <ButtonV2 onClick={handleCtaClick}>{translations.createEventCta}</ButtonV2>
      </SpacingStack>
    </SocialAccountWrapper>
  );
});
