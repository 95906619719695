import { withWindow } from '@shared/utils/withWindow';
import { ImpactRadiusTrackingPayload } from '@graphql/generated';
import { ImpactRadiusBaseEventPayload, ImpactRadiusEventType } from './impactRadius.types';

const hasActiveClickId = withWindow(window => {
  return window.document.cookie.split(';').some(item => item.trim().startsWith('joy_irclickid='));
}, false);

const getCookie = (name: string) => {
  const match = withWindow(window => {
    return window.document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  }, null);
  if (match) {
    return match[2];
  }
  return;
};

const getBasePayload = ({ campaignId, actionTrackerId, eventId, auth0id }: ImpactRadiusBaseEventPayload): ImpactRadiusTrackingPayload => {
  const now = new Date();
  return {
    campaignId,
    actionTrackerId,
    eventDate: now.toISOString(),
    orderId: getCookie('joyWsid') || 'joyWsid_not_found',
    clickId: getCookie('joy_irclickid') || 'clickId_not_found',
    customerId: eventId || 'eventId_not_found',
    customerEmail: auth0id || 'auth0id_not_found', // auth0id - OPTIONAL
    customerStatus: 'NEW'
  };
};

export const getEventPayload = (campaignId: number, actionTrackerId: number, impactRadiusEventType: ImpactRadiusEventType, eventId?: string, auth0id?: string) => {
  if (hasActiveClickId) {
    return {
      ...getBasePayload({ campaignId, actionTrackerId, eventId, auth0id }),
      itemCategory1: impactRadiusEventType, // Product_Added OR Registry_Linked
      itemSKU1: impactRadiusEventType, // Product_Added OR Registry_Linked
      itemSubtotal1: 0.0,
      itemQuantity1: 1,
      text1: getCookie('joy_irtext1') || 'joy_irtext1_not_found' // registy or wedding-website
    };
  }
  return false;
};

export const getCreateEventPayload = (campaignId: number, actionTrackerId: number, eventId?: string, auth0id?: string) => {
  if (hasActiveClickId) {
    return { ...getBasePayload({ campaignId, actionTrackerId, eventId, auth0id }) };
  }
  return false;
};
