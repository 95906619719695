import { useState } from 'react';
import { OptionType } from '@withjoy/joykit';
import { GeneralMessageLinkType, GeneralMessageLinkTypeLabels } from '@shared/components/EmailsAndEcards/Emails.types';

interface UseLinkSelectorControllerArgs
  extends Readonly<{
    handleLinkSelect: (link: GeneralMessageLinkType) => void;
    initialSelectedLink: GeneralMessageLinkType;
  }> {}

export const useLinkSelectorController = (args: UseLinkSelectorControllerArgs) => {
  const { handleLinkSelect, initialSelectedLink } = args;
  const initialLinkOption = { label: GeneralMessageLinkTypeLabels[initialSelectedLink], value: initialSelectedLink };
  const linkOptions = Object.keys(GeneralMessageLinkType).map(key => ({ label: GeneralMessageLinkTypeLabels[key as GeneralMessageLinkType], value: key }));

  const [selectedLinkOption, setSelectedLinkOption] = useState<OptionType>(initialLinkOption);

  const handleOnLinkOptionChange = (linkOption: OptionType) => {
    setSelectedLinkOption(linkOption);
    handleLinkSelect(linkOption.value as GeneralMessageLinkType);
  };

  return {
    linkOptions,
    handleOnLinkOptionChange,
    selectedLinkOption
  };
};
