import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from '@withjoy/joykit';
import { useHistory } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

import { useFormattedPrintFiltersFacetHref } from '../../useFormattedPrintFiltersFacetHref';
import { FilterPill } from './components/FilterPill';
import { FacetClickSource, FilterFacet } from './Filter.types';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { FiltersDrawer } from './FiltersDrawer';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useDesignGalleryFiltersContext } from '../../DesignGalleryFiltersProvider';

interface FiltersProps {
  facets: FilterFacet[];
}

const isValidFacetSource = (source: string | undefined): source is FacetClickSource => !!source && ['mobileDrawer', 'desktopDropdown'].includes(source);

export const Filters = React.memo((props: FiltersProps) => {
  const { facets } = props;
  const history = useHistory();

  const [defaultActiveDrawerIndex, setDefaultActiveDrawerIndex] = useState(0);

  const isMobileOrTablet = useIsMobileScreen();
  const { isFilterDrawerOpen, closeFilterDrawer, openFiltersDrawer } = useDesignGalleryFiltersContext();

  useEffect(() => {
    if (!isMobileOrTablet) {
      closeFilterDrawer();
    }
  }, [isMobileOrTablet, closeFilterDrawer]);

  const { filterFacetClicked } = useCardTelemetry();

  const { deriveHref } = useFormattedPrintFiltersFacetHref();

  const handleOnFilterChange = useEventCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    const { target } = evt;

    if (!target.disabled && target.dataset.itemId && isValidFacetSource(target.dataset.source)) {
      filterFacetClicked({ facet: 'format', source: target.dataset.source, code: target.dataset.itemId, isChecked: target.checked });
      history.push(deriveHref(target.dataset.itemId, target.checked));
    }
  });

  const filteredFacets = useMemo(() => {
    return facets.filter(facet => facet.options.filter(option => option.matchingItemCount > 0).length > 1);
  }, [facets]);

  const handleOnTriggerFilterDrawer = useEventCallback((groupCode: string) => {
    const targetFacetIndex = filteredFacets.findIndex(facet => facet.groupCode === groupCode);
    setDefaultActiveDrawerIndex(targetFacetIndex !== -1 ? targetFacetIndex : 0);

    openFiltersDrawer();
  });

  if (filteredFacets.length === 0) {
    return null;
  }

  return (
    <Flex paddingX={{ _: 6, sm2: 8 }} flexWrap="nowrap" columnGap={4}>
      {facets.map(facet => (
        <FilterPill
          key={facet.groupCode}
          interaction={isMobileOrTablet ? 'drawer' : 'popover'}
          facet={facet}
          onFilterChange={handleOnFilterChange}
          onTriggerFilterDrawer={handleOnTriggerFilterDrawer}
        />
      ))}

      <FiltersDrawer
        isOpen={isFilterDrawerOpen}
        onChange={handleOnFilterChange}
        defaultIndex={defaultActiveDrawerIndex}
        onClose={closeFilterDrawer}
        filterFacets={filteredFacets}
      />
    </Flex>
  );
});
