/* eslint-disable react/no-find-dom-node */
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export const useUserMenuController = () => {
  const [menuShown, setMenuShown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef && dropdownRef.current) {
      const el = e.target;
      const domNode = ReactDOM.findDOMNode(dropdownRef.current);

      if (!(domNode && el instanceof Node && domNode.contains(el))) {
        setMenuShown(false);
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return {
    dropdownRef,
    menuShown,
    setMenuShown
  };
};
