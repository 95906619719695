import { useCallback, useEffect } from 'react';
import { useOverlayContext, OverlayContextRegisterFunction } from './OverlayContext';

// const debug = makeDebugger('useOverlay');

type ShowOverlay = () => void;
type HideOverlay = () => void;

interface UseOverlayConfig {
  /**
   * Whether or not the overlay element should be unmounted when the parent component (at time of instantiation)
   * is unmounted.
   * @default true
   */
  removeOnUnmount?: boolean;

  /**
   * Inputs that will prompt an update when changed.
   *
   * When provided, this overlay element is driven by inputs from the surrounding scope.
   *
   *  When empty, it would suggest that the element is self sufficient - either it manages its own local state or it
   * pulls from an external store (Apollo client, Redux, etc).
   *
   * @default []
   */
  dependencies: any[];
}

/**
 * -----------------------------------
 * DEPRECATED -- Use `<DialogV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * This is being deprecated in favor of directly using `DialogV2`.
 *
 * ```typescript
 * import { DialogV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/DialogV2/Dialog.stories.tsx`.
 */
export const useOverlayUnstable = <T extends object>(registerFunctionArguments: OverlayContextRegisterFunction<T>, config: UseOverlayConfig): [ShowOverlay, HideOverlay] => {
  const { dependencies, removeOnUnmount = true } = config;

  const { overlayKey, render, props } = registerFunctionArguments;
  const overlayContext = useOverlayContext();
  useEffect(() => {
    overlayContext.register({ overlayKey, render, props });
  }, dependencies);

  useEffect(() => {
    // We keep the unmount separate from updates.
    return () => {
      if (removeOnUnmount) {
        // debug(`Unregistering: ${overlayKey}`);
        overlayContext.unregister(overlayKey);
      }
    };
  }, []);

  const showOverlay = useCallback(() => overlayContext.show(overlayKey), []);
  const hideOverlay = useCallback(() => overlayContext.hide(overlayKey), []);

  return [showOverlay, hideOverlay];
};
