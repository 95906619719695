/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Custom Card',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4395 6.38889C16.8733 5.82263 15.9552 5.82263 15.3889 6.38889L14.8587 6.9191L17.0809 9.14129L17.6111 8.61107C18.1773 8.04482 18.1773 7.12672 17.6111 6.56046L17.4395 6.38889ZM16.3031 9.9191L14.0809 7.69692L7.31208 14.4657C7.26018 14.5176 7.22176 14.5814 7.20018 14.6516L6.24541 17.7546L9.3484 16.7998C9.41856 16.7782 9.48236 16.7398 9.53426 16.6879L16.3031 9.9191ZM14.6111 5.61107C15.6069 4.61524 17.2215 4.61524 18.2173 5.61107L18.3889 5.78265C19.3847 6.77848 19.3847 8.39306 18.3889 9.38889L10.3121 17.4657C10.1333 17.6445 9.91353 17.7768 9.6719 17.8512L5.57596 19.1114C5.38124 19.1714 5.16936 19.1187 5.0253 18.9747C4.88125 18.8306 4.82862 18.6187 4.88853 18.424L6.14882 14.3281C6.22317 14.0864 6.3555 13.8667 6.53426 13.6879L14.6111 5.61107Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M20.256 8.487c-.755-.755-1.979-.755-2.734 0l-5.385 5.385a1.27 1.27 0 0 0-.315.523l-.84 2.731a.6.6 0 0 0 .75.75l2.731-.84a1.27 1.27 0 0 0 .523-.315l5.385-5.384c.755-.755.755-1.979 0-2.734l-.114-.114zm-1.886.849c.286-.286.751-.286 1.037 0l.114.114c.286.286.286.751 0 1.037l-.189.189-1.151-1.151.188-.188zm-1.037 1.037l1.151 1.151-4.347 4.348c-.008.008-.017.013-.028.017l-1.647.507.507-1.648c.003-.01.009-.02.017-.027l4.348-4.347zm8.601-6.705V16.52l2.428 1.867c.173.133.239.336.239.547v9.067c0 .7-.567 1.267-1.267 1.267H4.667c-.7 0-1.267-.567-1.267-1.267v-9.067c0-.211.066-.414.239-.547l2.428-1.867V3.668c0-.515.418-.933.933-.933h18c.515 0 .933.418.933.933zM10.436 23.793L7.267 21V3.934h17.467v17.064l-3.684 3.249-4.394-2.652c-.403-.243-.907-.243-1.309 0l-4.395 2.652-.516-.455zm-.561 1.105l-.232-.205L4.6 20.249v7.752c0 .025.014.047.035.058l5.239-3.162zm-2.931 3.17h18.114l-9.022-5.445c-.021-.013-.048-.013-.069 0l-9.022 5.445zm20.422-.008c.021-.011.035-.033.035-.058v-7.755l-5.274 4.651 5.239 3.162zm-1.432-8.12v-1.906l1.154.888-1.154 1.018zm-19.867.002l-1.156-1.018 1.156-.889v1.908z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.189 12.923c-1.025-1.025-2.687-1.025-3.712 0L18.4 21a1.63 1.63 0 0 0-.404.671l-1.26 4.096c-.068.221-.008.462.155.626a.62.62 0 0 0 .626.155l4.096-1.26a1.63 1.63 0 0 0 .671-.404l8.077-8.077c1.025-1.025 1.025-2.687 0-3.712l-.172-.172zm-2.828.884c.537-.537 1.408-.537 1.944 0l.172.172c.537.537.537 1.408 0 1.944L29 16.4l-2.116-2.116.477-.477zM26 15.168l2.116 2.116L21.4 24a.38.38 0 0 1-.155.093l-2.968.913.913-2.968a.38.38 0 0 1 .093-.155L26 15.168zM38.625 5.5v19.415l3.749 2.883c.191.147.251.372.251.602V42c0 .898-.727 1.625-1.625 1.625H7c-.897 0-1.625-.727-1.625-1.625V28.399c0-.23.06-.455.251-.602l3.749-2.883V5.5c0-.621.504-1.125 1.125-1.125h27c.621 0 1.125.504 1.125 1.125zM15.471 35.894l-4.846-4.272V5.625h26.75V31.62l-5.771 5.089-6.764-4.083c-.516-.312-1.163-.312-1.679 0l-6.764 4.083-.925-.816zm-.197 1.493l-.63-.555-8.019-7.068V42c0 .207.168.375.375.375l8.274-4.989zm-5.847 4.989h29.146l-14.379-8.678c-.119-.072-.268-.072-.388 0L9.427 42.375zm31.565 0a.38.38 0 0 0 .383-.375V29.759l-8.649 7.628 8.266 4.989zm.072-14.008l-2.439-1.875v4.026l2.439-2.151zM9.375 30.52l-2.442-2.151 2.442-1.878v4.029z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
