import React from 'react';
import { useDesktopView } from './useDesktopView';
import { useHeaderLinks } from '../../useHeaderLinks';
import { DesktopMenu } from './components/DesktopMenu';
import { Box, Flex } from '@withjoy/joykit';
import { animationTransitionExt } from '@shared/utils/animationTransition';
import { HeaderLinksGroupButton } from './components/HeaderLinksGroupButton';
import type { HeaderLinksType, MarcomAppNavBarDesktopProps } from '../../AppNavBar.types';
import { ReactComponent as IconBrandLogoDesktop } from '@assets/icons/icon-brand-logo-desktop.svg';
import { DropdownLinksContainer, MEGANAV_STYLE_OVERRIDES, NavContainer, Root } from './DesktopView.styles';
import { pxToRem } from '@withjoy/joykit/theme';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { MobileView } from '../MobileView/MobileView';

export const DesktopView = (props: MarcomAppNavBarDesktopProps) => {
  const {
    getLinksContainerProps,
    getHeaderLinkProps,
    getHeaderLinkGroupButtonProps,
    getRootProps,
    getTranslations,

    linkGroupHeadersRef,
    linksDropdownRef,
    containerRef,
    headerLinksType,
    isOpen
  } = useDesktopView();
  const { linkGroupHeaders } = getTranslations();
  const { headerLinks } = useHeaderLinks();
  const headerLinksMenu: HeaderLinksType[] = ['planInvite', 'registry', 'expertAdvice'];
  const isMobile = useIsMobileScreen();

  return (
    <Root {...getRootProps()} zIndex={25} position="sticky" top="0">
      <NavContainer
        $isOpen={isOpen}
        transition={animationTransitionExt({
          property: 'background-color',
          delay: isOpen ? undefined : '250ms',
          duration: '280ms'
        })}
        height="inherit"
      >
        {!isMobile && (
          <Flex data-container ref={containerRef} borderBottom="1px solid" borderBottomColor="mono3" backgroundColor="white" {...MEGANAV_STYLE_OVERRIDES}>
            <Flex flex={1} alignItems="center">
              <Box as="a" href={'/'}>
                <IconBrandLogoDesktop />
              </Box>
              <Box marginLeft={'48px'}>
                <Box position="relative" ref={linkGroupHeadersRef} zIndex={1} display="flex" columnGap={2}>
                  {headerLinksMenu.map((type, idx) =>
                    headerLinks[type].length > 0 ? (
                      <HeaderLinksGroupButton key={`header-menu-${type}-${idx}`} {...getHeaderLinkGroupButtonProps(type)}>
                        {linkGroupHeaders[type]}
                      </HeaderLinksGroupButton>
                    ) : null
                  )}
                </Box>
              </Box>
            </Flex>
          </Flex>
        )}
        {isMobile && (
          <Flex
            width="100%"
            alignItems={['flex-end', null, 'center']}
            justifyContent="space-between"
            columnGap={[pxToRem(8), null, pxToRem(24)]}
            paddingX={[pxToRem(16), null, pxToRem(40)]}
            height={[pxToRem(54), null, pxToRem(68)]}
          >
            <MobileView {...props} />
          </Flex>
        )}
      </NavContainer>
      <DropdownLinksContainer
        ref={linksDropdownRef}
        paddingY={8}
        transition={animationTransitionExt({ property: 'top', delay: isOpen ? '100ms' : undefined }, { property: 'background-color' })}
        {...getLinksContainerProps()}
      >
        <Box width={['100%']} marginX="auto">
          {headerLinksType && <DesktopMenu type={headerLinksType} getLinkProps={getHeaderLinkProps} />}
        </Box>
      </DropdownLinksContainer>
    </Root>
  );
};

DesktopView.displayName = 'MarcomAppNavBarDesktop';
