import React from 'react';
import { Box } from '@withjoy/joykit';
import { CSSProperties } from 'react';
import { LayerLayoutData, RectangleLayerData } from './Layer.types';

export function layerLayoutDataToCSS(layout: LayerLayoutData): CSSProperties {
  return {
    position: 'absolute',
    touchAction: 'none',
    left: layout.x,
    top: layout.y,
    width: layout.width,
    height: layout.height
  };
}

export const RectangleLayer = (props: { layer: RectangleLayerData }) => {
  const { layer } = props;

  return (
    <Box
      style={{
        ...layerLayoutDataToCSS(layer.layout),
        backgroundColor: layer.fillData.fill ?? 'rgba(255, 255, 255, 0.5)',
        overflow: 'hidden' // Safari not responding to 'clip'
      }}
    />
  );
};
