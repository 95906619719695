import React from 'react';
import { RoutesProvider } from '@shared/core';
import { useEcardGuestController } from './SaveTheDateGuest.controller';
import { Box } from '@withjoy/joykit';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import PhotoECardPreview from '@apps/saveTheDate/components/PhotoECardPreview';
import Error from '@shared/components/EmailsAndEcards/components/Error';
import { TelemetryProvider } from './SaveTheDateGuest.telemetry';
import { Helmet } from 'react-helmet-async';
import { NoSsr } from '@shared/components/NoSsr';

export const SaveTheDateGuest: React.FC = () => {
  const { ecardData, extraData, loading, error, onLogoutEventHandle, guestNames, ogTitle } = useEcardGuestController();

  return (
    <RoutesProvider isGuestRoute={true}>
      <Helmet title={ogTitle}>
        <script src="https://withjoy.com/assets/public/styles/applicator.js"></script>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&display=swap" rel="stylesheet" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:site_name" content="Joy" />
        <meta property="og:url" content="withjoy.com" />
        <meta property="og:image" content="https://withjoy.com/media/envelopes/For%20You.png" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="noindex"></meta>
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/inter-ui/Inter-UI-Regular.woff2" />
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/inter-ui/Inter-UI-SemiBold.woff2" />
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/akkurat/AkkuratLLWeb-Bold.woff2" />
        <link rel="preload" as="font" type="font/woff2" href="https://withjoy.com/assets/public/fonts/AustinNewsHeadline/AustinNewsHeadline-Light-Web.woff2" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap" rel="stylesheet" />
        <link href="https://withjoy.com/assets/public/joykit.normalize.css" rel="stylesheet" />
        <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"></script>
      </Helmet>
      <NoSsr>
        {loading ? (
          <JoyLogoLoader loaderKey="guest-save-the-date" />
        ) : !error ? (
          <TelemetryProvider context={{ eventId: extraData.eventId }}>
            <Box paddingX={24} backgroundColor="mono1">
              <PhotoECardPreview ecardData={ecardData} extraData={extraData} isGuestView onLogoutEventHandle={onLogoutEventHandle} guestNames={guestNames} />
            </Box>
          </TelemetryProvider>
        ) : (
          <Error />
        )}
      </NoSsr>
    </RoutesProvider>
  );
};
