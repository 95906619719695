import { Box, Flex, LinkV2, styled, StyleSystemProps } from '@withjoy/joykit';
import { EMAIL_EDITOR_DRAWER_WIDTH } from '../../../EcardEditPanel/EcardEditPanel.styles';
import { ECARD_DEFAULT_WIDTH } from '@apps/ecard/Ecard.constants';

export const EcardPreviewWrapper = styled(Flex)``;
export const EcardPreviewContainer = styled(Box)``;
export const StyledLink = styled(LinkV2)``;

const getPreviewWrapperStyles = ($isEcardEditorShown: boolean, $isEcardPreview: boolean): StyleSystemProps => ({
  height: '100%',
  width: ['100%', '100%', $isEcardEditorShown ? `calc(100% - ${EMAIL_EDITOR_DRAWER_WIDTH}px)` : `100%`],
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0 180px 0',
  transition: 'width 0.4s',
  willChange: 'width'
});

const previewContainerStyles: StyleSystemProps = {
  position: 'relative',
  width: `${ECARD_DEFAULT_WIDTH}px`,
  marginTop: 7,
  willChange: 'transform',
  transformOrigin: 'top'
};

const styledLinkStyles: StyleSystemProps = {
  marginTop: 2
};

export const styles = {
  previewContainer: previewContainerStyles,
  styledLink: styledLinkStyles,
  getPreviewWrapperStyles
};
