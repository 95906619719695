import { styled, Flex } from '@withjoy/joykit';

export const EcardContainer = styled(Flex)`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.mono1};
  position: relative;
`;

export const EcardWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
