import React from 'react';
import { ButtonV2, Flex, TextV2, IconV2 } from '@withjoy/joykit';
import { Email } from '@withjoy/joykit/icons';
import { outlinedDarkButtonOverrides } from '../Shared.styles';
import { useOptionalTestEmailViewController } from './OptionalTestEmailView.controller';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';

const OptionalTestEmailView: React.FC = () => {
  const { tOptionalTestEmail, handleButtonClick } = useOptionalTestEmailViewController();
  const { transform } = useBottomSheetDescriptionOffset();

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center">
        <IconV2 size="md" marginRight="12px">
          <Email />
        </IconV2>{' '}
        {tOptionalTestEmail.title()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" flex="1" style={{ transform }}>
        {tOptionalTestEmail.description()}
      </TextV2>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={handleButtonClick}>
        {tOptionalTestEmail.sendTest()}
      </ButtonV2>
    </Flex>
  );
};

export default OptionalTestEmailView;
