import { Flex, StyleSystemProps, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { animationTransition } from '@shared/utils/animationTransition';

export const HeaderLinksContainer = styled(Flex)``;
const headerLinksContainerStyles = (isActive: boolean): StyleSystemProps => ({
  color: isActive ? 'mono12' : 'mono14',
  alignItems: 'center',
  fontWeight: 'bold',
  paddingX: 4,
  paddingY: 4,
  whiteSpace: 'nowrap',
  transition: animationTransition('color'),
  cursor: 'default',
  fontFamily: 'Akkurat LL',
  fontSize: pxToRem(17),
  lineHeight: pxToRem(26)
});

export const styles = {
  headerLinksContainer: headerLinksContainerStyles
};
