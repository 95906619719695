import { styled } from '../../../../common';
import { DIALOG_BORDER_RADIUS } from '../../dialog.styles';
import { Box } from '../../../Box';
import { IconV1 } from '../../../IconV1';

export const DialogHeaderContainer = styled(Box)<{ isCloseButtonShown: boolean; transparent?: boolean }>`
  min-height: 64px;
  padding-right: ${props => props.isCloseButtonShown && 72};
  position: relative;
  z-index: 1;
  :first-child {
    ${props => props.theme.mediaQueries.sm} {
      border-top-left-radius: ${DIALOG_BORDER_RADIUS};
      border-top-right-radius: ${DIALOG_BORDER_RADIUS};
    }

    background-color: ${props => (!props.transparent ? props.theme.colors.mono2 : 'transparent !important')};
  }
`;

export const DialogHeaderCloseIcon = styled(IconV1)`
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
