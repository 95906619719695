/* eslint-disable unused-imports/no-unused-imports */
import React from 'react';
import { EcardAdditionalLinkType } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import { Flex, Box, TextV2, LinkV2, SpacingStack } from '@withjoy/joykit';
import { AddToCalendar as AddToCalendarIcon, Gift, Rsvp, Schedule, Plane, Website } from '@withjoy/joykit/icons';
import { BackCardButton, BackCardContainer, BackCardMessageContainer, styles, BackCardLink, BackCardLogo } from './BackCard.styles';
import { AddToCalendar } from '@shared/components/AddToCalendar';
import { prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { Linkify } from '@shared/components/Linkify';
import { SaveTheDateDesignInputFields, SaveTheDateExtraData } from '@apps/saveTheDate/SaveTheDate.types';
import useBackCardController from './BackCard.controller';
// import { getDeviceResolution } from '@shared/utils/deviceResolution';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';

interface Props
  extends Readonly<{
    ecardData: SaveTheDateDesignInputFields;
    extraData: SaveTheDateExtraData;
    isGuestView: boolean;
  }> {}

// const DEVICE_RESOLUTION = getDeviceResolution();

export const BackCard = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { ecardData, extraData, isGuestView } = props;
  const { additionalMessage, additionalLink1, additionalLink2, displayEventLink } = ecardData;
  const { eCardLinkClick } = useSaveTheDateTelemetry();
  const {
    linkToLabelMap,
    date,
    endDate,
    location,
    yourUrl,
    guidId,
    // accentId,
    hasAdditionalLink1,
    hasAdditionalLink2,
    handleLinkClick,
    handleNavigateAdditionalPage
  } = useBackCardController({ ecardData, extraData });

  const getFooterIcon = (type: EcardAdditionalLinkType | string) => {
    switch (type) {
      case EcardAdditionalLinkType.ADD_TO_CALENDAR:
        return <AddToCalendarIcon size="md" />;
      case EcardAdditionalLinkType.RSVP:
        return <Rsvp size="md" />;
      case EcardAdditionalLinkType.BROWSE_REGISTRY:
        return <Gift size="md" />;
      case EcardAdditionalLinkType.VIEW_SCHEDULE:
        return <Schedule size="md" />;
      case EcardAdditionalLinkType.VIEW_TRAVEL_DETAILS:
        return <Plane size="md" />;
      case EcardAdditionalLinkType.VIEW_WEBSITE:
        return <Website size="md" />;
      default:
        return null;
    }
  };

  const getLinkButton = (additionalLink: string) => {
    return additionalLink === EcardAdditionalLinkType.ADD_TO_CALENDAR ? (
      <AddToCalendar start={date} end={endDate} title={extraData.eventDisplayName || ''} location={location || ''} description={ecardData.additionalMessage || ''}>
        <BackCardButton
          overrides={styles.button}
          startIcon={getFooterIcon(additionalLink)}
          variant="outline"
          shape="square"
          onClick={() => isGuestView && eCardLinkClick('calendar', extraData.eventId || '')}
        >
          {linkToLabelMap?.[additionalLink] || additionalLink}
        </BackCardButton>
      </AddToCalendar>
    ) : (
      <BackCardButton
        overrides={styles.button}
        startIcon={getFooterIcon(additionalLink)}
        variant="outline"
        shape="square"
        onClick={() => handleNavigateAdditionalPage(additionalLink, isGuestView)}
      >
        {linkToLabelMap?.[additionalLink] || additionalLink}
      </BackCardButton>
    );
  };

  return (
    <>
      {(hasAdditionalLink1 || hasAdditionalLink2 || additionalMessage) && (
        <BackCardContainer __css={styles.container} ref={ref}>
          <Flex flexDirection={['column', 'row']} alignItems="center">
            {hasAdditionalLink1 && getLinkButton(additionalLink1 as string)}
            {hasAdditionalLink2 && getLinkButton(additionalLink2 as string)}
          </Flex>
          {additionalMessage && (
            <BackCardMessageContainer marginTop={hasAdditionalLink1 || hasAdditionalLink2 ? '40px' : 0} {...styles.messageContainer}>
              <TextV2 as="div" whiteSpace="pre-wrap" fontFamily="Gotham SSm" lineHeight="27.2px">
                <Linkify>{additionalMessage}</Linkify>
              </TextV2>
            </BackCardMessageContainer>
          )}
          {displayEventLink && (
            <Flex marginTop="40px" flexDirection="column" justifyContent="center" alignItems="center">
              <LinkV2 onClick={() => handleLinkClick(`${yourUrl}${guidId ? `?guidId=${guidId}` : ''}`, 'wedding website', true, isGuestView)} {...styles.footerWebsiteUrl}>
                {prettyUrl(yourUrl)}
              </LinkV2>
              {extraData.privacyMode && extraData.eventPassword && extraData.privacyMode !== 'public' && (
                <Flex marginTop="8px" alignItems="center">
                  <TextV2 fontFamily="Gotham SSm" fontSize="15px" lineHeight="22px">
                    Password:{' '}
                  </TextV2>
                  <Box as="span" textTransform="uppercase" fontFamily="Gotham SSm" fontSize="15px" lineHeight="22px" fontWeight={500} marginLeft={1}>
                    {extraData.eventPassword}
                  </Box>
                </Flex>
              )}
            </Flex>
          )}
          {/* {accentId && (
            <Flex marginTop={6} justifyContent="center">
              <GraphicAccentImg src={URLS.graphicAccent(accentId)} pixelDensity={DEVICE_RESOLUTION} />
            </Flex>
          )} */}
        </BackCardContainer>
      )}
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <SpacingStack stack="horizontal">
          <BackCardLink
            as="a"
            $disabled={!isGuestView}
            role="button"
            aria-disabled={!isGuestView}
            onClick={() => handleLinkClick('https://help.withjoy.com/guest-help/?utm_source=e-card-invite&utm_medium=viral&utm_campaign=guest-help', 'guest-help')}
            __css={styles.link}
          >
            <TextV2 typographyVariant="body1">Guest Help</TextV2>
          </BackCardLink>
          <BackCardLink
            as="a"
            $disabled={!isGuestView}
            role="button"
            aria-disabled={!isGuestView}
            marginLeft="24px !important"
            onClick={() => handleLinkClick('https://withjoy.com/?utm_source=viral&utm_medium=guestSite&utm_campaign=ecard', 'about')}
            __css={styles.link}
          >
            <TextV2 typographyVariant="body1">About</TextV2>
          </BackCardLink>
        </SpacingStack>
        <BackCardLink
          as="a"
          $disabled={!isGuestView}
          role="button"
          aria-disabled={!isGuestView}
          onClick={() => handleLinkClick('https://withjoy.com/?utm_source=viral&utm_medium=guestSite&utm_campaign=ecard', 'marcom')}
          __css={styles.link}
        >
          <TextV2 as="span" typographyVariant="body1">
            Made with
          </TextV2>
          <BackCardLogo />
        </BackCardLink>
      </Flex>
    </>
  );
});
