import { Box, Flex } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledImageBackground, StyledNameText, StyledDetailsText } from './AmpersandTop.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetUrl = getThemeInfo('ampersandTop')?.assetUrl || '';

export const AmpersandTop: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const fontSize = isFullNamesFormat ? 16 : 32;
  const lineHeight = isFullNamesFormat ? 20 : 41;
  const letterSpacing = '0.4em';
  const namesBlockMarginBottom = isFullNamesFormat ? '19px' : '20px';
  const firstNameMarginBottom = isFullNamesFormat ? '17px' : '13px';
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage />
      <StyledImageBackground isAdjustingPosition={!!isAdjustingPosition} assetImage={addRendition({ url: assetUrl, renditionSize: 'medium' })}>
        <Flex padding="40px 30px 0px 30px" flexDirection="column" alignItems="center" fontSize={32} color="black" fontFamily="Lora">
          <Flex
            flexDirection="column"
            justifyContent="center"
            marginBottom={namesBlockMarginBottom}
            marginRight={`-${letterSpacing}`} //hack for: text appears not centered because of letter spacing
            __css={{ height: '140px', fontSize, letterSpacing, lineHeight, textAlign: 'center' }}
          >
            <StyledNameText marginBottom={firstNameMarginBottom}>{formattedOwnerName}</StyledNameText>
            <StyledNameText>{formattedFianceeName}</StyledNameText>
          </Flex>
          <Box marginBottom="20px" textAlign="center">
            {formattedDate ? <StyledDetailsText marginBottom="8px">{formattedDate}</StyledDetailsText> : null}
            {formattedLocation ? <StyledDetailsText>{formattedLocation}</StyledDetailsText> : null}
          </Box>
          {inviteToFollow ? <StyledDetailsText fontStyle="italic">{inviteToFollow}</StyledDetailsText> : null}
        </Flex>
      </StyledImageBackground>
    </>
  );
};
