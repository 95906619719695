import React, { useMemo } from 'react';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { createContext } from '@shared/utils/createContext';
import { useRouteMatch } from '@react-router';
import { getStationeryTemplateCategoryFromNamedCategoryPath, isValidShopPageMatch, useCardsRouterContext } from '@apps/card/Card.routes';

const [Provider, useCardCategoryContext] = createContext<{
  currentCategory: StationeryTemplateCategoryEnum;
  // isCardDashboardRoute: boolean;
}>({ name: 'CardCategory' });

/**
 * Temporary until we have explicit routes per card category
 */
export const CardCategoryProvider: React.FC = ({ children }) => {
  const { routes } = useCardsRouterContext();
  const shopRouteMatch = useRouteMatch({ path: routes.designsGallery.paths });

  const currentCategory = useMemo(() => {
    if (shopRouteMatch && isValidShopPageMatch(shopRouteMatch.params)) {
      return getStationeryTemplateCategoryFromNamedCategoryPath(shopRouteMatch.params.category);
    }

    return StationeryTemplateCategoryEnum.saveTheDate;
  }, [shopRouteMatch]);

  return <Provider value={{ currentCategory }}>{children}</Provider>;
};

export { useCardCategoryContext };
