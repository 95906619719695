import { ApolloError } from '@apollo/client';
import { EventType, useGetEventTypeLazyQuery } from '@graphql/generated';
import { useEventInfo } from '../eventInfo';
import { useEffect } from 'react';

export interface EventTypeData {
  loading: boolean;
  eventType?: EventType;
  error?: ApolloError;
}

/**
 * Returns event type information for an event.
 *
 * @param eventHandle The event handle to get the event type for.
 */
export function useEventType(eventHandle?: string): EventTypeData {
  const { eventHandle: eventHandleFromContext } = useEventInfo();
  const [call, response] = useGetEventTypeLazyQuery({
    batchMode: 'off'
  });
  const handleToRequest = eventHandle ?? eventHandleFromContext;
  useEffect(() => {
    if (handleToRequest) {
      call({
        variables: {
          name: handleToRequest
        }
      });
    }
  }, [handleToRequest, call]);

  return {
    loading: response.loading || !response.called,
    error: response.error,
    eventType: response.data?.eventByName?.info?.eventType
  };
}
