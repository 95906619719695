export const getUpdateMenuSettingsQuery = (website: string) => `
  mutation UpdateMenuSettings($pageVisibilitiesInput: UpdatePageVisibilitiesInput!) {
    eventWithName(website: "${website}") {
      settings {
        pages {
          updatePageVisibilities(input: $pageVisibilitiesInput) {
            pages {
              page
              visibility
            }
          }
        }
      }
    }
  }
`;
