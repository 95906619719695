import React, { useState } from 'react';
import { AnchorLinksWrapper, EmptyListContentWrapper, LeftBox, ListsTopSpacerMobile, SelectListsWrapper, StyledSearchWrapper, StyledWrapper } from './PeoplePicker.styles';
import { Box, InputV1, LinkV1, TextV2 } from '@withjoy/joykit';
import { LabelsListItem } from '../LabelsListItem/LabelsListItem';
import { ListItemVo, SelectList } from '../SelectList/SelectList';
import { InGroupPosition, PeopleListItem } from '../PeopleListItem/PeopleListItem';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { useFilteredData } from '../../utils/hooks/useFilteredData';
import { groupAndFlatPeople } from '../../components/PeoplePicker/util';
import { SelectedPeopleDrawer } from '../../components/PeoplePicker/components/SelectedPeopleDrawer';
import { useSelectGuestsTelemetry } from '@shared/components/PeoplePicker/PeoplePicker.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';

export interface PersonViewItem
  extends ListItemVo,
    Readonly<{
      avatarUrl?: string;
      labelText: string;
      sublabelText?: string;
      groupId?: string;
      inGroupPosition?: InGroupPosition;
      personIdTag?: string;
      avatarName?: string;
      initals?: string;
      email?: string;
      disabled?: boolean;
    }> {}

export interface LabelIdsToPeopleIdsMap
  extends Readonly<{
    [key: string]: ReadonlyArray<string>;
  }> {}

export interface LabelViewItem
  extends ListItemVo,
    Readonly<{
      labelText: string;
    }> {}

interface DomainWarning {
  message: string;
  domainList: string[];
  secondaryMessage: string;
}

const isDomainWarning = (warning: unknown) => {
  if (
    warning &&
    typeof warning === 'object' &&
    typeof (warning as DomainWarning).message === 'string' &&
    Array.isArray((warning as DomainWarning).domainList) &&
    typeof (warning as DomainWarning).secondaryMessage === 'string'
  ) {
    return true;
  }
  return false;
};

interface Props
  extends Readonly<{
    labels: ReadonlyArray<LabelViewItem>;
    people: ReadonlyArray<PersonViewItem>;
    onPersonSelectionChange: (personId: string, newValue: boolean) => void;
    onLabelSelectionChange: (label: string, newValue: boolean) => void;
    onSelectAllPeopleClick: () => void;
    onClose?: () => void;
    isDisabled?: boolean;
    isSelectedPeopleDrawerOpen: boolean;
    onSelectedPeopleDrawerClose: () => void;
    eventHandle: string;
    selectedPeopleCount: number;
  }> {}

const peopleFilterKeys = ['labelText'] as const;
const labelsFilterKeys = ['labelText'] as const;

const smallPersonItemRenderer = (person: PersonViewItem) => <PeopleListItem isSmall {...person} />;
const personItemRenderer = (person: PersonViewItem) => <PeopleListItem {...person} />;
const labelItemRenderer = (labelItem: LabelViewItem) => <LabelsListItem labelText={labelItem.labelText} />;

const isAvailableDomain = (email: Maybe<string>, domainList: string[]) => {
  return !domainList.some(domain => email?.includes?.(`${domain}.`));
};

export const PeoplePicker: React.FC<Props> = React.memo(
  ({
    labels = [],
    people = [],
    onPersonSelectionChange,
    onLabelSelectionChange,
    onSelectAllPeopleClick,
    isDisabled,
    isSelectedPeopleDrawerOpen,
    onSelectedPeopleDrawerClose,
    onClose,
    eventHandle,
    selectedPeopleCount
  }) => {
    const [searchString, setSearchString] = useState('');
    const { value: shouldDisplayDomainWarning, payload: domainWarningPayload } = useFeatureValue('messagingEmailDomainUnavailableNotification');

    let domainWarningMessage = '';
    let disabledEmailCount = 0;
    if (shouldDisplayDomainWarning === 'on' && isDomainWarning(domainWarningPayload)) {
      const dWarn = domainWarningPayload as DomainWarning;

      people.forEach(person => {
        const invalidDomain = !isAvailableDomain(person?.email, dWarn.domainList);
        if (invalidDomain) {
          //@ts-ignore
          person.disabled = true;
          //@ts-ignore
          person.sublabelText = 'Email disabled';
          disabledEmailCount += 1;
        }
      });
      domainWarningMessage = `${dWarn.message}${dWarn.domainList.map(domain => ` ${domain}`) ?? ''}. ${dWarn.secondaryMessage ? `${dWarn.secondaryMessage}` : ''}`;
    }
    const showDomainWarning = disabledEmailCount > 0 && domainWarningMessage;
    const [filteredPeople] = useFilteredData(people, searchString, peopleFilterKeys);
    const [filteredLabels] = useFilteredData(labels, searchString, labelsFilterKeys);
    const viewPeople = filteredPeople || [];
    const selectedViewPeople = groupAndFlatPeople(people.filter(person => person.selected));
    const viewLabels = filteredLabels || [];
    const guestsListUrl = `/${eventHandle}/edit/guests`;
    const telemetry = useSelectGuestsTelemetry();

    return (
      <StyledWrapper className={isDisabled ? 'disabled' : ''}>
        <LeftBox>
          <StyledSearchWrapper>
            <InputV1
              value={searchString}
              onChange={event => {
                telemetry.search(event.target.value);
                setSearchString(event.target.value);
              }}
              iconLeft={<SearchIcon />}
              placeholder={'Search by name or tag'}
            />
          </StyledSearchWrapper>
          {showDomainWarning && (
            <Box paddingX={4} margin={6} marginRight={'32px'} marginTop={0} paddingY={3} borderRadius={'6px'} border={'1px solid #EEaa00'}>
              <TextV2 textAlign={'left'} fontSize={11}>
                {domainWarningMessage}
              </TextV2>
            </Box>
          )}
          <AnchorLinksWrapper>
            <LinkV1 href={'#labels'}>Tags</LinkV1>
            <LinkV1 href={'#guests'}>
              Guests <span>({selectedPeopleCount} Selected)</span>
            </LinkV1>
          </AnchorLinksWrapper>
          <ListsTopSpacerMobile />
          <SelectListsWrapper>
            <SelectList
              id={'labels'}
              className={'select-labels-list'}
              showSelectAll={false}
              headerLabel={'My Tags'}
              items={viewLabels}
              onItemSelectedChange={onLabelSelectionChange}
              itemRenderer={labelItemRenderer}
              emptyContentRenderer={() => (
                <EmptyListContentWrapper>
                  No tags were found. Add and edit tags in your{' '}
                  <LinkV1 href={guestsListUrl} onClick={onClose}>
                    guest list
                  </LinkV1>
                  .
                </EmptyListContentWrapper>
              )}
            />
            <SelectList
              id={'guests'}
              showSelectAll={!searchString}
              className={'select-people-list'}
              headerLabel={`My Guests (${viewPeople.length})`}
              items={viewPeople}
              onItemSelectedChange={onPersonSelectionChange}
              onSelectAllClick={onSelectAllPeopleClick}
              itemRenderer={personItemRenderer}
              emptyContentRenderer={() => (
                <EmptyListContentWrapper>
                  No guests were found. Add and edit guests in your{' '}
                  <LinkV1
                    href={guestsListUrl}
                    onClick={() => {
                      telemetry.goToGuestList();
                      onClose && onClose();
                    }}
                  >
                    guest list
                  </LinkV1>
                  .
                </EmptyListContentWrapper>
              )}
            />
          </SelectListsWrapper>
        </LeftBox>
        <SelectList
          className={'selected-people-list'}
          canSelect={false}
          headerLabel={`Selected (${selectedViewPeople.length})`}
          items={selectedViewPeople}
          itemRenderer={smallPersonItemRenderer}
        />
        <SelectedPeopleDrawer selectedPeople={selectedViewPeople} isOpen={isSelectedPeopleDrawerOpen} onClose={onSelectedPeopleDrawerClose} />
      </StyledWrapper>
    );
  }
);
