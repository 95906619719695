import * as yup from 'yup';

import { getLocalStorage } from '@shared/core/storage';

import { ACQUISITION_CONTEXTS_KEY } from './acquisitionContextTracking.constants';
import { logAcquisitionContextTrackingEvent } from './acquisitionContextTracking.logger';
import { StoredAcquisitionContextDetails, StoredAcquisitionContextData } from './acquisitionContextTracking.types';

const acquisitionContextArraySchema: yup.ArraySchema<StoredAcquisitionContextDetails> = yup.array<StoredAcquisitionContextDetails>(
  yup.object<StoredAcquisitionContextDetails>({
    timestamp: yup.number().required(),
    relativeUrl: yup.string().required(),
    referrer: yup.string(),
    data: yup.array<StoredAcquisitionContextData>(
      yup.object<StoredAcquisitionContextData>({
        key: yup.string().required(),
        value: yup.string().required()
      })
    )
  })
);

export function readAcquisitionContexts(): StoredAcquisitionContextDetails[] {
  const storage = getLocalStorage();
  let acquisitionContexts: StoredAcquisitionContextDetails[] = [];
  let acquisitionContextsString: string | null | undefined;
  let error: unknown;

  try {
    acquisitionContextsString = storage.getItem(ACQUISITION_CONTEXTS_KEY);
    if (acquisitionContextsString) {
      const maybeAcquisitionContexts = JSON.parse(acquisitionContextsString);
      acquisitionContexts = acquisitionContextArraySchema.validateSync(maybeAcquisitionContexts);
    }
  } catch (e) {
    error = e;
  }

  if (error) {
    logAcquisitionContextTrackingEvent('error', {
      message: 'Error reading acquisition contexts. Defaulting to empty array.',
      // limit the length of string to log in case it gets very long
      acquisitionContextsString:
        acquisitionContextsString && acquisitionContextsString.length > 10000 ? acquisitionContextsString?.substring(0, 10000) + '...' : acquisitionContextsString,
      error
    });
  }

  return acquisitionContexts;
}

export function writeAcquisitionContexts(acquisitionContexts: StoredAcquisitionContextDetails[]): void {
  const storage = getLocalStorage();
  let error: unknown;

  try {
    storage.setItem(ACQUISITION_CONTEXTS_KEY, JSON.stringify(acquisitionContexts));
  } catch (e) {
    error = e;
  }

  if (error) {
    logAcquisitionContextTrackingEvent('error', { message: 'Error writing acquisition contexts', acquisitionContexts, error });
  } else {
    logAcquisitionContextTrackingEvent('success', {
      message: 'Acquisition contexts written',
      acquisitionContexts,
      acquisitionContextsLength: acquisitionContexts.length
    });
  }
}

export function readAndResetAcquisitionContexts(): StoredAcquisitionContextDetails[] {
  const acquisitionContexts = readAcquisitionContexts();
  writeAcquisitionContexts([]);
  logAcquisitionContextTrackingEvent('success', {
    message: 'Acquisition contexts read and reset',
    acquisitionContexts,
    acquisitionContextsLength: acquisitionContexts.length
  });
  return acquisitionContexts;
}
