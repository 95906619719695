/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Registry',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.23762 5.88666C3.19017 5.37339 3.28209 4.80155 3.53046 4.29486C3.95863 3.4214 4.848 2.7373 6.2512 2.7373C7.74612 2.7373 8.95233 3.37145 9.97752 4.06112C10.3135 4.28716 10.6485 4.53247 10.9659 4.76493C11.1237 4.88055 11.2773 4.99302 11.4244 5.09832C11.6246 5.24159 11.818 5.37547 12.0072 5.49735C12.2071 5.37303 12.4128 5.23654 12.626 5.09088C12.7857 4.98174 12.952 4.86568 13.1225 4.74672C13.4508 4.51758 13.7946 4.27769 14.1361 4.05581C15.1792 3.37814 16.3889 2.7373 17.749 2.7373C19.1522 2.7373 20.0416 3.4214 20.4698 4.29486C20.7182 4.80157 20.8101 5.37344 20.7626 5.88673C21.4753 6.04831 22.0073 6.68558 22.0073 7.44714V9.8895C22.0073 10.5381 21.6214 11.0966 21.0667 11.3478V19.6626C21.0667 20.5463 20.3503 21.2626 19.4667 21.2626H4.53342C3.64976 21.2626 2.93341 20.5463 2.93341 19.6626V11.3478C2.3786 11.0966 1.99261 10.5381 1.99261 9.8895V7.44714C1.99261 6.68548 2.52481 6.04813 3.23762 5.88666ZM10.4125 5.84714C10.3508 5.80207 10.2895 5.75718 10.2285 5.71245C9.92395 5.48929 9.62524 5.27039 9.30772 5.05679C8.35183 4.41375 7.38912 3.9373 6.2512 3.9373C5.30255 3.9373 4.83309 4.3638 4.60797 4.82305C4.43323 5.17951 4.40099 5.56149 4.44101 5.84714H10.4125ZM19.3923 4.82305C19.567 5.17951 19.5992 5.56149 19.5592 5.84714H13.6425L13.8343 5.7134C14.1509 5.49233 14.4632 5.27431 14.7899 5.06209C15.7981 4.40706 16.7573 3.9373 17.749 3.9373C18.6977 3.9373 19.1671 4.3638 19.3923 4.82305ZM11.4001 11.4895H4.13341V19.6626C4.13341 19.8835 4.3125 20.0626 4.53342 20.0626H11.4001V11.4895ZM11.4001 10.2894H3.58523C3.36773 10.2855 3.19261 10.1079 3.19261 9.8895V7.44714C3.19261 7.22622 3.3717 7.04714 3.59261 7.04714H11.4001V10.2894ZM12.6001 11.4895V20.0626H19.4667C19.6876 20.0626 19.8667 19.8835 19.8667 19.6626V11.4895H12.6001ZM12.6001 10.2894V7.04714H20.4073C20.6282 7.04714 20.8073 7.22622 20.8073 7.44714V9.8895C20.8073 10.1079 20.6322 10.2855 20.4147 10.2894H12.6001Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
