import { Flex, styled } from '@withjoy/joykit';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';

export const StyledAssetBackground = styled.div<{ backgroundImage: string; isAdjustingPosition: boolean }>`
  width: 100%;
  height: 100%;
  background: ${props => backgroundImageForUrl(props.backgroundImage)}, linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-size: cover, 100% 200px;
  background-position: center, center bottom;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: ${({ isAdjustingPosition }) => (isAdjustingPosition ? 'none' : 'initial')};
`;

export const DetailsContainer = styled(Flex)`
  margin-top: 12px;

  *:not(:first-child) {
    margin-top: 8px;
  }
`;

export const FloatingNamesConnector = styled.span<{ isFullNamesFormat: boolean }>`
  ${({ isFullNamesFormat }) => (isFullNamesFormat ? ' margin-left: 0; margin-right: -13px; float: right;' : 'margin: 0 -3px;')}
`;
