import { styled, css } from '@shared/joykit/packages/core/common/styledComponents';
import { DIALOG_HEADER_CLASSNAME } from './constants';
import { animated } from 'react-spring';

export const DIALOG_BORDER_RADIUS = `8px`;

export const ANIMATIONS = {
  from: {
    opacity: 0,
    scale: 1.1
  },
  to: {
    opacity: 1,
    scale: 1
  },
  leave: {
    opacity: 0,
    scale: 0.9
  }
};

export const ModalWrapper = styled.div<{ isScrollable: boolean; isOpen: boolean }>`
  position: absolute;
  box-sizing: border-box;
  --webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* z-index: 1500; */
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100%;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  ::-webkit-scrollbar {
    width: 8;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #333;
  }
`;

const fullscreenStyles = css`
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
`;

export const ModalInner = styled(animated.div)<{ width?: string }>`
  opacity: 1;
  pointer-events: all;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;
  border-radius: ${DIALOG_BORDER_RADIUS};
  background-color: #fff;
  will-change: transform;
  @media screen and (max-width: 768px) {
    ${fullscreenStyles}
  }

  ${props => {
    return css`
      @media screen and (min-width: 769px) {
        margin: 40px 0;
        ${() => {
          return {
            minWidth: props.width === undefined ? '480px' : undefined,
            width: props.width
          };
        }}
        max-width: 48rem;
      }
    `;
  }}
  & .${DIALOG_HEADER_CLASSNAME} {
    position: sticky;
    top: 0;
  }
`;
