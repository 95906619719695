import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import React from 'react';
import { outlinedDarkButtonOverrides } from '../Shared.styles';
import { useTranslation } from '@shared/core';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';

const SelectDeliveryMethodView: React.FC = () => {
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tSelectRecipients = tBottomSheet.selectRecipients;
  const { transform } = useBottomSheetDescriptionOffset();
  const { setStep } = useBottomSheetState();

  const onSendByEmailClick = () => {
    setStep('send-by-email');
  };

  const onShareWithLinkClick = () => {
    setStep('share-with-link');
  };

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center" height="24px">
        {tSelectRecipients.deliveryMethod()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" flex="1" style={{ transform }}>
        {tSelectRecipients.deliveryMethodDescription()}
      </TextV2>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={onSendByEmailClick} marginBottom={6}>
        {tSelectRecipients.deliveryMethodEmailCTA()}
      </ButtonV2>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={onShareWithLinkClick}>
        {tSelectRecipients.deliveryMethodLinkCTA()}
      </ButtonV2>
    </Flex>
  );
};

export default SelectDeliveryMethodView;
