import { useEffect, useState } from 'react';
import globalWindow from '@shared/core/globals';
import { EventDesignFragment } from '@graphql/generated';
import { Theme } from '@withjoy/joykit';
import { ecardConfig } from './ecardThemeConfig';
import { googleFontVariations } from '@apps/guest/packages/layout-engine/common/fonts';

export const useEcardDesignTheming = (eventDesign: EventDesignFragment | undefined | null, useEventColor: boolean) => {
  const [theme, setTheme] = useState<Theme | undefined>(eventDesign ? ecardConfig.configureThemeOverrides(eventDesign, ecardConfig.theme, useEventColor) : undefined);
  useEffect(() => {
    if (ecardConfig) {
      // Collect fonts to load
      const layoutFontFamilies = ecardConfig.fonts.google?.map(key => googleFontVariations[key]) || [];
      const eventDesignFontFamilies = [eventDesign?.font.fontFamily];

      // Update JoyKit with `eventDesign` applied to a layout, otherwise use the default layout theme
      const updateTheme = () => {
        setTheme(eventDesign ? ecardConfig.configureThemeOverrides(eventDesign, ecardConfig.theme, useEventColor) : ecardConfig.theme);
      };

      if (globalWindow.WebFont) {
        // 1. Attempt to load fonts first to prevent FOUT
        // 2. Update theme regardless of font load success
        globalWindow.WebFont?.load({
          google: {
            families: [...layoutFontFamilies, ...eventDesignFontFamilies].filter(x => x) as string[]
          },

          // Either the `active` or `inactive` callback will be invoked
          active: () => {
            updateTheme();
          },

          inactive: () => {
            updateTheme();
          }
        });
      } else {
        updateTheme();
      }
    }
  }, [eventDesign, setTheme, useEventColor]);

  return { theme };
};
