import React from 'react';

import { generateComponentDisplayName } from '../../utils';
import { Choice } from '../Choice';
import { CheckSVG } from './components/CheckSVG';
import { CHOICE_INDICATOR } from '../../common';
import { CheckContainer, StyledCheckIcon, StyledInput } from './checkbox.styles';
import { CheckboxV1Props } from './index';

/**
 * -----------------------------------
 * DEPRECATED -- Use `<CheckboxV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { CheckboxV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/CheckboxV2/Checkbox.stories.tsx`.
 */
export const CheckboxV1: React.SFC<CheckboxV1Props> = props => {
  const indicatorMarkup = (
    <CheckContainer className={CHOICE_INDICATOR} disabled={props.disabled}>
      <StyledCheckIcon source={<CheckSVG />} />
    </CheckContainer>
  );

  const labelMarkup = (
    <Choice {...props} indicator={indicatorMarkup}>
      <StyledInput {...props} />
    </Choice>
  );

  return labelMarkup;
};

CheckboxV1.defaultProps = {
  alignIndicator: 'left'
};
CheckboxV1.displayName = generateComponentDisplayName('Checkbox');
