import { useEffect, useState, useRef } from 'react';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import ResizeObserver from 'resize-observer-polyfill';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';

type EcardScalingState = {
  scale: number;
  height: number | null;
};

type UseResizerArgs = {
  containerBaseWidth?: number;
};

export const useEcardScaling = ({ containerBaseWidth = 500 }: UseResizerArgs) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const initialHeight = useRef<number | null>(null);
  const [ecardScalingState, setEcardScalingState] = useState<EcardScalingState>({ scale: 1, height: initialHeight.current });
  const isMounted = useIsMounted();

  const resize = useEventCallback(() => {
    if (nodeRef.current) {
      const { clientWidth, clientHeight } = nodeRef.current;
      if (!initialHeight.current) {
        initialHeight.current = clientHeight;
      }
      const scale = Math.min(clientWidth / containerBaseWidth, 1);
      if (!scale || ecardScalingState.scale === scale) {
        return;
      }
      const nextHeight = initialHeight.current ? initialHeight.current * scale : clientHeight * scale;
      setEcardScalingState({
        scale,
        height: nextHeight
      });
    }
  });

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    const ro = new ResizeObserver(() => {
      resize();
    });

    if (nodeRef.current) {
      ro.observe(nodeRef.current);
    }

    return () => {
      if (ro) {
        ro.disconnect();
      }
    };
  }, [isMounted, resize]);

  return {
    ecardScalingState,
    nodeRef
  };
};
