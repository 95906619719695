import { Box, ButtonV2, styled, StyleSystemProps } from '@withjoy/joykit';
import { ReactComponent as JoyLogo } from '@assets/joy-logo.svg';
import { ECARD_DEFAULT_WIDTH } from '@apps/ecard/Ecard.constants';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';

export const EcardFooterContainer = styled(Box)``;
export const EcardFooterButton = styled(ButtonV2)``;
export const EcardFooterOverlay = styled(Box)``;
export const EcardFooterLink = styled(Box)<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

const containerStyles: StyleSystemProps = {
  boxShadow: '0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04)',
  filter: 'drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.03))',
  position: 'relative',
  marginY: [4, 4, 6],
  paddingX: 9,
  paddingY: 9,
  backgroundColor: 'white',
  color: 'black',
  width: `${ECARD_DEFAULT_WIDTH}px`
};

export const EcardFooterMessageContainer = styled(Box)`
  a {
    color: inherit;
    &:hover {
      color: inherit;
      opacity: 0.8;
    }
    &:active {
      color: inherit;
      opacity: 1;
    }
  }
`;

const buttonStyles: ButtonOverrides = {
  Root: {
    props: {
      borderColor: 'black',
      color: 'black',
      flex: ['unset', 1],
      height: '48px',
      width: ['240px', 'auto'],
      _even: {
        marginLeft: [0, '15px'],
        marginTop: ['24px', 0]
      },
      _odd: {
        marginLeft: [0, '15px'],
        marginTop: ['24px', 0]
      },
      _first: {
        marginTop: 0
      }
    }
  }
};

const linkStyles: StyleSystemProps = {
  color: 'mono8',
  textDecoration: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  _hover: {
    color: 'black'
  }
};

const overlayStyles: StyleSystemProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  zIndex: 'docked'
};

export const EcardFooterLogo = styled(JoyLogo)`
  height: 24px;
  width: 38px;
  margin-left: 8px;
`;

const footerWebsiteUrl: StyleSystemProps = {
  color: 'inherit',
  borderBottom: '1px solid',
  textDecoration: 'none',
  _hover: {
    opacity: 0.8,
    _active: {
      opacity: 1
    }
  },
  _active: {
    opacity: 1
  },
  _visited: {
    color: 'inherit'
  }
};

const messageContainer: StyleSystemProps = {
  paddingX: 5,
  textAlign: 'center'
};

export const styles = {
  messageContainer,
  footerWebsiteUrl,
  container: containerStyles,
  button: buttonStyles,
  link: linkStyles,
  overlay: overlayStyles
};
