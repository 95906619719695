import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';

export type ActivePrintFilters = Record<string, boolean>;

export const activePrintFiltersVar = makeVar<ActivePrintFilters>({});

const getActiveFilterCountByGroupCode = (filterVar: ReactiveVar<ActivePrintFilters>) => (groupCode: string) => {
  const activeFilters = filterVar();
  /**
   * Returns the number of active filters for the group.
   */
  return Object.keys(activeFilters).reduce((acc, compositeCode) => {
    if (compositeCode.startsWith(`${groupCode}:`) && activeFilters[compositeCode]) {
      acc++;
    }
    return acc;
  }, 0);
};

const clearAllPrintSearchFilters = (filterVar: ReactiveVar<ActivePrintFilters>) => () => {
  return filterVar({});
};

const getTotalActiveFilterCount = (filterVar: ReactiveVar<ActivePrintFilters>) => () => {
  const activeFilters = filterVar();
  return Object.keys(activeFilters).reduce((acc, compositeCode) => {
    if (activeFilters[compositeCode]) {
      acc++;
    }

    return acc;
  }, 0);
};

export const activePrintFiltersCacheApi = {
  getActiveFilterCountByGroupCode: getActiveFilterCountByGroupCode(activePrintFiltersVar),
  clearAllPrintSearchFilters: clearAllPrintSearchFilters(activePrintFiltersVar),
  getTotalActiveFilterCount: getTotalActiveFilterCount(activePrintFiltersVar)
};

export const useActivePrintFiltersVar = () => {
  return useReactiveVar(activePrintFiltersVar);
};
