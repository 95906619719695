import React, { useMemo } from 'react';
import { Box, Flex, LinkV2 } from '@withjoy/joykit';
import { EmailPreviewContainer, EmailPreviewWrapper } from './EmailWrapper.styles';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import { EmailType } from '@shared/components/EmailsAndEcards/Emails.types';
import RecipientsBlock from './RecipientsBlock';

interface Props
  extends Readonly<{
    subject: string;
    isEmailEditPanelShown: boolean;
    isBottomSheetFirstStep: boolean;
    openEmailEditor: () => void;
    isMobile: boolean;
    emailType: EmailType;
    recipientName?: string;
  }> {}

export const EmailWrapper: React.FC<Props> = props => {
  const { subject, isEmailEditPanelShown, isBottomSheetFirstStep, openEmailEditor, isMobile, emailType, recipientName } = props;

  const recipientsBlockActiveIndex = useMemo(() => {
    if (emailType === 'ecard') {
      return 2;
    }

    return 1;
  }, [emailType]);

  return (
    <EmailPreviewWrapper $isEmailEditPanelShown={isEmailEditPanelShown}>
      <EmailPreviewContainer marginTop={['77px', '77px', '96px']}>
        <Flex flexDirection="column" fontFamily={EmailFontFamilies.ibmPlexSans} fontSize={'15px'} lineHeight={'19px'} paddingX="16px" paddingY={6}>
          {['generic', 'ecard'].includes(emailType) && <RecipientsBlock activeStepIndex={recipientsBlockActiveIndex} />}
          {recipientName && (
            <Box display="flex" paddingX="16px" paddingY="8px" marginBottom="8px">
              <Box color={'mono10'} marginRight={[4, 4, 5]}>{`To: `}</Box>
              <Box color={'mono13'}>{recipientName}</Box>
            </Box>
          )}
          <Box display="flex" paddingX="16px" alignItems="center">
            <Box color={'mono10'} marginRight={[4, 4, 5]}>{`Subject: `}</Box>
            {emailType !== 'ecard' && isMobile && isBottomSheetFirstStep ? (
              <LinkV2 onClick={openEmailEditor} color="primary7" textDecoration="none" fontSize="15px" lineHeight="22.5px" fontFamily="inherit">
                {subject}
              </LinkV2>
            ) : (
              <Box fontSize="15px" lineHeight="22.5px" color={'mono13'}>
                {subject}
              </Box>
            )}
          </Box>
        </Flex>
        <>{props.children}</>
      </EmailPreviewContainer>
    </EmailPreviewWrapper>
  );
};
