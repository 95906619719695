import React, { useEffect } from 'react';
import { EditPanel } from '@apps/saveTheDate/components/EditPanel';
import { useEmailController } from './Email.controller';
import { FloatingFooterContainer } from '../../components/Shared.styles';
import { ButtonV2, CancelButton } from '@withjoy/joykit';
import EditEmailForm from '../../components/EditEmailForm';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import EmailPreview from '@apps/saveTheDate/components/EmailPreview';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { darkButtonOverride } from '@apps/postOffice/components/Shared.styles';

export const Email = () => {
  const {
    saveTheDateEmailFormik,
    handleCancel,
    handleDone,
    isEditShown,
    toggleEditPanel,
    isUnsavedChangesDialogOpen,
    handleUnsavedChangesDialogClose,
    handleUnsavedChangesDialogConfirm,
    translations,
    footerText
  } = useEmailController();

  const { editEmailTrack } = useSaveTheDateTelemetry();

  useEffect(() => {
    editEmailTrack.enter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <EmailPreview formik={saveTheDateEmailFormik} isEmailEditPanelShown={isEditShown} toggleEmailEditPanel={toggleEditPanel} footerText={footerText} />
        <EditPanel isOpen={isEditShown} onClose={handleCancel} title={translations.header()}>
          <EditEmailForm formik={saveTheDateEmailFormik} />
          <FloatingFooterContainer>
            <ButtonV2 overrides={darkButtonOverride} onClick={handleDone} disabled={!saveTheDateEmailFormik.isValid}>
              {translations.doneButtonText()}
            </ButtonV2>
            <CancelButton onClick={handleCancel}>{translations.cancelButtonText()}</CancelButton>
          </FloatingFooterContainer>
        </EditPanel>
      </div>
      <UnsavedChangesDialog
        isOpen={isUnsavedChangesDialogOpen}
        onClose={handleUnsavedChangesDialogClose}
        onConfirm={handleUnsavedChangesDialogConfirm}
        warningMessageContent="ecard"
      />
    </>
  );
};
