/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Lock',
  definitions: {
    '24': {
      viewBox: '0 0 24 25',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.1 6.69091C8.1 4.81547 9.791 3.2 12 3.2C14.209 3.2 15.9 4.81547 15.9 6.69091V10.1H8.1V6.69091ZM6.9 10.1V6.69091C6.9 4.04766 9.23844 2 12 2C14.7616 2 17.1 4.04766 17.1 6.69091V10.1H18C18.8284 10.1 19.5 10.7716 19.5 11.6V20.6C19.5 21.4284 18.8284 22.1 18 22.1H6C5.17157 22.1 4.5 21.4284 4.5 20.6V11.6C4.5 10.7716 5.17157 10.1 6 10.1H6.9ZM5.7 11.6C5.7 11.4343 5.83431 11.3 6 11.3H18C18.1657 11.3 18.3 11.4343 18.3 11.6V20.6C18.3 20.7657 18.1657 20.9 18 20.9H6C5.83431 20.9 5.7 20.7657 5.7 20.6V11.6Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 31 39',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.125 8.99432C7.125 4.87552 10.8172 1.4375 15.5 1.4375C20.1828 1.4375 23.875 4.87552 23.875 8.99432V14.8125H7.125V8.99432ZM5.875 14.8125V8.99432C5.875 4.07572 10.2416 0.1875 15.5 0.1875C20.7584 0.1875 25.125 4.07572 25.125 8.99432V14.8125H27.5C29.1569 14.8125 30.5 16.1556 30.5 17.8125V35.8125C30.5 37.4694 29.1569 38.8125 27.5 38.8125H3.5C1.84315 38.8125 0.5 37.4694 0.5 35.8125V17.8125C0.5 16.1556 1.84315 14.8125 3.5 14.8125H5.875ZM1.75 17.8125C1.75 16.846 2.5335 16.0625 3.5 16.0625H27.5C28.4665 16.0625 29.25 16.846 29.25 17.8125V35.8125C29.25 36.779 28.4665 37.5625 27.5 37.5625H3.5C2.5335 37.5625 1.75 36.779 1.75 35.8125V17.8125Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
