import { useGetAllowedDesignParamsQuery } from '@graphql/generated';
import React, { createContext, useContext, useMemo } from 'react';

interface DesignParamsInterface {
  settled: boolean;
  themes: Map<string, string>;
  fonts: Set<string>;
}

const DesignParamsContext = createContext<DesignParamsInterface>({
  settled: false,
  themes: new Map(),
  fonts: new Set([])
});

export const AllowedDesignParamsProvider: React.FC = ({ children }) => {
  const result = useGetAllowedDesignParamsQuery();

  const { called, loading, data } = result;

  const themes = useMemo<Map<string, string>>(() => {
    const newThemes = new Map<string, string>();

    data?.themes?.forEach(({ themeId, id }) => newThemes.set(themeId, id));

    return newThemes;
  }, [data]);

  const fonts = useMemo<Set<string>>(() => {
    if (!data) {
      return new Set([]);
    }

    const fonts = data.fonts.map(({ display }) => display);

    return new Set(fonts);
  }, [data]);

  const contextValue = useMemo<DesignParamsInterface>(() => {
    return { settled: called && !loading, themes, fonts };
  }, [called, fonts, loading, themes]);

  return <DesignParamsContext.Provider value={contextValue}>{children}</DesignParamsContext.Provider>;
};

export const useAllowedDesignParams = () => {
  const context = useContext(DesignParamsContext);
  if (context === undefined) {
    throw new Error('useAllowedDesignParams must be used within a AllowedDesignParamsProvider');
  }
  return context;
};
