import marketingFonts from '@shared/utils/fonts/marketingFonts';
import { styled } from '@withjoy/joykit';

export const ImpermissibleWrapper = styled.div``;

export const ImpermissibleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 440px;
  padding: 0 16px;
  margin: 80px auto;
  & > * {
    margin-bottom: 36px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ExplanationText = styled.p`
  ${marketingFonts.FeatureCopy17}
`;
export const PageHeader = styled.h1`
  ${marketingFonts.CalloutHed32Bold}
  text-align: center;
  @media screen and (max-width: 780px) {
    text-align: left;
  }
`;

export const ExplanationAction = styled.span`
  cursor: pointer;
  color: ${props => props.theme.colors.primary7};
  &:hover {
    color: ${props => props.theme.colors.primary6};
  }
  &:hover:active:focus,
  &:active {
    color: ${props => props.theme.colors.primary8};
  }
`;
