import React, { useState } from 'react';
import { ButtonV2, TextV2, InputV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useDeleteEventSuccessController } from './DeleteEventSuccess.controller';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { dialogOverrides } from './DeleteEventSuccess.styles';

interface Props
  extends Readonly<{
    eventId?: string;
    isOpen: boolean;
    onClose: () => void;
  }> {}

export const DeleteEventSuccessDialog: React.FC<Props> = props => {
  const { eventId, isOpen, onClose } = props;
  const { t2 } = useTranslation('account');
  const tDeleteEventSuccess = t2('deleteEventSuccess');
  const { handleDeleteEventSuccessConfirmed } = useDeleteEventSuccessController({ eventId, onClose });
  const [validationInput, setVaildationInput] = useState<string | undefined>();

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={onClose}
      dialogOverrides={dialogOverrides}
      title={tDeleteEventSuccess.title}
      renderFooter={() => (
        <>
          <ButtonV2 intent="neutral" onClick={() => handleDeleteEventSuccessConfirmed(eventId, validationInput)} disabled={validationInput?.length === 0}>
            {tDeleteEventSuccess.confirmButton}
          </ButtonV2>
        </>
      )}
    >
      <TextV2 typographyVariant="body2">{tDeleteEventSuccess.confirmMessage}</TextV2>
      <TextV2 typographyVariant="body2" marginY={5}>
        {tDeleteEventSuccess.message}
      </TextV2>
      <InputV2 placeholder={tDeleteEventSuccess.placeholderText} onChange={e => setVaildationInput(e.target.value)}></InputV2>
    </ResponsiveDialog>
  );
};
