export function capitalize(str?: string, lowercase: boolean = true): { valid: boolean; text?: string } {
  if (typeof str === 'string') {
    str = lowercase ? str.toLowerCase() : str;
    const text = str[0].toUpperCase() + str.slice(1);
    return { valid: true, text };
  }
  return { valid: false };
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}
