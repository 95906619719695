import { styled, TextV2 } from '@withjoy/joykit';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';

export const SubTitle = styled(TextV2)`
  font-family: 'Inter UI';
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.01em;
  padding-top: 30px;
`;

export const StyledLabel = styled.span`
  font-family: 'Inter UI';
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 18.2px;
  letter-spacing: -0.01em;
`;

export const InputLengthLabel = styled.p`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  color: ${props => props.theme.colors.mono8};
  margin-top: 9px;
  text-align: end;
`;

export const FloatingFooterContainer = styled.div`
  height: 128px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0px 4px 12px -2px rgba(44, 41, 37, 0.25), 0px 3px 7px -3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px 8px;
`;

export const linkButtonOverrides: ButtonOverrides = {
  Root: {
    props: {
      color: 'mono14',
      _hover: {
        color: 'mono12'
      },
      _active: {
        color: 'mono13'
      }
    }
  }
};

export const filledButtonOverrides: ButtonOverrides = {
  Root: {
    props: {
      color: 'white',
      backgroundColor: 'mono14',
      _hover: {
        backgroundColor: 'mono12'
      },
      _active: {
        backgroundColor: 'mono13'
      }
    }
  }
};

export const outlinedButtonOverrides: ButtonOverrides = {
  Root: {
    props: {
      color: 'mono14',
      borderColor: 'mono14',
      _hover: {
        color: 'mono12',
        borderColor: 'mono12',
        backgroundColor: 'rgba(77, 77, 77, 0.1)'
      },
      _active: {
        color: 'mono13',
        borderColor: 'mono13',
        backgroundColor: 'rgba(89, 89, 89, 0.2)'
      }
    }
  }
};
