import { JoyLogo } from '@assets/index';
import { TextV2, LinkV2, FormControl, InputV2, ButtonV2 } from '@withjoy/joykit';
import { Email, Lock } from '@withjoy/joykit/icons';
import React from 'react';
import { FormSection } from '../AccountForm.styles';
import { PrivacyPolicyLink, TermsOfServiceLink, PrivacyNoticeLink } from '@shared/createEvent/CreateAccount/components';
import { useTranslation } from '@shared/core';
import useCreateAccountController from './CreateAccount.controller';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { SwitchFormHandler } from '../AccountForm.types';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

interface CreateAccountProps {
  switchForm: SwitchFormHandler;
}
const CreateAccount = ({ switchForm }: CreateAccountProps) => {
  const { t, t2 } = useTranslation('createWedding');
  const translations = t('createAccount');
  const { helpText, heroText } = t2('createAccount', 'scenarios', 'imagine-your-card');
  const termsAndPrivacy = translations.termsAndPrivacy({ PrivacyPolicy: PrivacyPolicyLink, TermsOfService: TermsOfServiceLink, PrivacyNotice: PrivacyNoticeLink });
  const { formik, submissionError, getFieldErrorProps } = useCreateAccountController();
  const mobile = useIsMobileOrTablet();

  const switchToSignInForm = useEventCallback(() => switchForm('signIn'));

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSection gap="24px">
        {!mobile && <JoyLogo />}
        <FormSection gap="40px">
          {!mobile && (
            <FormSection gap="8px">
              <TextV2 textAlign="center" as="h1" typographyVariant="hed6">
                {heroText}
              </TextV2>
              <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
                {helpText}{' '}
                <LinkV2 onClick={switchToSignInForm} textDecoration="none">
                  <TextV2 as="span" typographyVariant="body3" color="linkText">
                    {translations.signIn()}
                  </TextV2>
                </LinkV2>
              </TextV2>
            </FormSection>
          )}
          <FormSection gap="24px" alignment="left">
            <FormControl {...getFieldErrorProps('email')} label={<TextV2 typographyVariant="label3">{translations.email()}</TextV2>}>
              <InputV2 startElement={<Email color="mono10" fill="#767676" size={24} />} {...formik.getFieldProps('email')} />
            </FormControl>
            <FormControl {...getFieldErrorProps('password')} label={<TextV2 typographyVariant="label3">{translations.password()}</TextV2>}>
              <InputV2 type="password" startElement={<Lock color="mono10" fill="#767676" size={24} />} {...formik.getFieldProps('password')} />
            </FormControl>
            {submissionError && (
              <TextV2 textAlign="center" typographyVariant="body1" color="negative6">
                {submissionError}
              </TextV2>
            )}
          </FormSection>
          <FormSection gap="24px">
            <ButtonV2 width="100%" shape="rounded" type="submit" disabled={formik.isSubmitting} intent="primary">
              {translations.createAccountCta()}
            </ButtonV2>
            {mobile && (
              <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
                {helpText}{' '}
                <LinkV2 onClick={switchToSignInForm} textDecoration="none">
                  <TextV2 as="span" typographyVariant="body3" color="linkText">
                    {translations.signIn()}
                  </TextV2>
                </LinkV2>
              </TextV2>
            )}

            <TextV2 typographyVariant="label1">{termsAndPrivacy}</TextV2>
          </FormSection>
        </FormSection>
      </FormSection>
    </form>
  );
};

export default CreateAccount;
