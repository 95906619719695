import { useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { useCharmEditorThemesQuery } from '@graphql/generated';

interface useEcardDesignEditorControllerArgs
  extends Readonly<{
    formik: FormikProps<EcardDesignInputFields>;
  }> {}

export const useEcardDesignEditorController = (args: useEcardDesignEditorControllerArgs) => {
  const [showMyColorToggle, setShowMyColorToggle] = useState(false);

  const { data, loading: themesLoading } = useCharmEditorThemesQuery({
    batchMode: 'off'
  });

  const themes = useMemo(() => data?.themes, [data]);

  return {
    themes,
    themesLoading,
    showMyColorToggle,
    setShowMyColorToggle
  };
};
