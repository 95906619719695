import globalWindow from '@shared/core/globals';
import { useEffect } from 'react';

const useLoadGoogleFont = (fonts: string[]) => {
  useEffect(() => {
    globalWindow.WebFont?.load({
      google: {
        families: fonts
      }
    });
    // Passing in `[fonts]` as a dependency is practically the same as using `[]`
    // since callers don't know to keep a stable reference.
    // Addressoing this by passing in `fonts` directly. Each font string is individually a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, fonts);
};

export default useLoadGoogleFont;
