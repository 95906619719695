import { isArray, isString } from '@shared/utils/assertions';
import qs from 'query-string';

export const FILTER_QS_PARSE_OPTIONS: qs.ParseOptions = { arrayFormat: 'separator', arrayFormatSeparator: '|' };

type ActivePrintFilters = Record<string, boolean>;

const deriveCompositeCode = (groupCode: string, valueCode: string) => `${groupCode}:${valueCode}`;

export const deriveActiveFiltersFromUrl = (url: string) => {
  const { query } = qs.parseUrl(url, FILTER_QS_PARSE_OPTIONS);
  const productFiltersParam = query.pf;

  if (typeof productFiltersParam === 'string') {
    const productFilters = qs.parse(productFiltersParam, FILTER_QS_PARSE_OPTIONS);

    const filtersList = Object.keys(productFilters).reduce((acc, groupCode) => {
      const attributeValue = productFilters[groupCode];
      if (isArray(attributeValue)) {
        attributeValue.forEach(valueCode => {
          acc[deriveCompositeCode(groupCode, valueCode)] = true;
        });
      } else if (isString(attributeValue)) {
        acc[deriveCompositeCode(groupCode, attributeValue)] = true;
      }
      return acc;
    }, {} as ActivePrintFilters);

    return filtersList;
  }
  return {};
};

export const convertObjectToQueryParams = (filters: { [facet: string]: string[] }) => {
  return qs.stringify(filters, FILTER_QS_PARSE_OPTIONS);
};
