import { TextV2, LinkV2, IconV2, styled } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';

export const Hed = styled(TextV2)`
  ${InterFonts.title}
`;

export const Dek = styled(TextV2)`
  ${InterFonts.body};
  max-width: 450px;
  color: ${props => props.theme.colors.mono12};
`;

export const StyledLink = styled(LinkV2)`
  text-decoration: none;
  ${InterFonts.body}
`;

export const StyledIcon = styled(IconV2)`
  width: 196px;
  height: 197px;
`;
