import { CardPaperType } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { animationTransition } from '@shared/utils/animationTransition';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { Box, BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { CARD_TYPES } from '../../config';

interface Context {
  hoveredPaperTypeOption?: CardPaperType;
  handlePaperOptionMouseEnter: (type: CardPaperType) => void;
  handlePaperOptionMouseLeave: () => void;
}

const TooltipContext = createContext<Context>({
  hoveredPaperTypeOption: undefined,
  handlePaperOptionMouseEnter: () => undefined,
  handlePaperOptionMouseLeave: () => undefined
});

const Container: React.FC = ({ children }) => {
  const isMobile = useIsMobileScreen();
  const [hoveredPaperTypeOption, setHoveredPaperTypeOption] = useState<CardPaperType>();

  const handlePaperOptionMouseEnter = (type: CardPaperType) => {
    if (isMobile) return;

    if (hoveredPaperTypeOption !== type) {
      setHoveredPaperTypeOption(type);
    }
  };

  const handlePaperOptionMouseLeave = () => {
    if (isMobile) return;

    if (hoveredPaperTypeOption !== undefined) {
      setHoveredPaperTypeOption(undefined);
    }
  };

  return (
    <TooltipContext.Provider value={{ hoveredPaperTypeOption, handlePaperOptionMouseEnter, handlePaperOptionMouseLeave }}>
      <Box position="relative">{children}</Box>
    </TooltipContext.Provider>
  );
};

interface OptionProps extends BoxProps {
  type: CardPaperType;
}

const Option: React.FC<OptionProps> = ({ type, ...props }) => {
  const context = useContext(TooltipContext);

  return (
    <Flex
      flex={1}
      height="100%"
      alignItems="center"
      justifyContent="center"
      onMouseEnter={() => context.handlePaperOptionMouseEnter(type)}
      onMouseLeave={context.handlePaperOptionMouseLeave}
      {...props}
    >
      <TextV2 typographyVariant="body1">{CARD_TYPES[type].label}</TextV2>
    </Flex>
  );
};

const Tooltip = () => {
  const context = useContext(TooltipContext);
  const [lastHoveredPaperType, setLastHoveredPaperType] = useState<CardPaperType>();

  useEffect(() => {
    if (context.hoveredPaperTypeOption) {
      setLastHoveredPaperType(context.hoveredPaperTypeOption);
    }
  }, [context.hoveredPaperTypeOption]);

  const isVisible = !!context.hoveredPaperTypeOption;
  const cardInfo = lastHoveredPaperType ? CARD_TYPES[lastHoveredPaperType] : null;

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      bottom="-1rem"
      right="0"
      left="0"
      background="white"
      transform="translateY(100%)"
      boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.07), 0px 7px 27px 0px rgba(44, 41, 37, 0.06)"
      borderRadius={4}
      padding={5}
      paddingBottom={6}
      gap={5}
      zIndex={10}
      opacity={isVisible ? 1 : 0}
      visibility={isVisible ? 'visible' : 'hidden'}
      transition={animationTransition('opacity', 'visibility')}
      transitionDelay="0.3s"
    >
      <Box as="img" src={cardInfo?.imageUrl} height="124px" backgroundColor="mono3" borderRadius={3} objectFit="cover" objectPosition="center" />
      <Flex flexDirection="column" gap={3}>
        <TextV2 typographyVariant="button1">{cardInfo?.label}</TextV2>
        <TextV2 typographyVariant="label2">{cardInfo?.description}</TextV2>
      </Flex>
    </Flex>
  );
};

const PaperTypeTooltip = {
  Container,
  Option,
  Tooltip
};

export default PaperTypeTooltip;
