import React from 'react';
import { Box, Flex, LinkV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

export const TermsAndPrivacyAgreement: React.FC<{}> = () => {
  const { t } = useTranslation('account');
  const termsAndCondition = t('termsandprivacy')({
    termsLink: () => (
      <LinkV2 href="https://withjoy.com/EULA" typographyVariant={'label2'} target="__blank">
        {t('terms')()}
      </LinkV2>
    ),
    privacyLink: () => (
      <LinkV2 href="https://withjoy.com/privacy" typographyVariant={'label2'} target="__blank">
        {t('privacyPolicy')()}
      </LinkV2>
    )
  });
  return (
    <Flex justifyContent="center">
      <TextV2 typographyVariant="label2">
        {termsAndCondition}
        {/* .truevault-polaris-dns-link is used as a target to conditionally hide/show a California Privacy notice */}
        <Box as="span" hidden={true} className="truevault-polaris-dns-link">
          {t('privacyNoticeSee')()}
          <LinkV2 href="https://privacy.withjoy.com/privacy-policy#california-privacy-notice" typographyVariant={'label2'} target="__blank">
            {t('privacyNotice')()}
          </LinkV2>
          .
        </Box>
      </TextV2>
    </Flex>
  );
};
