import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';

type PagesForTelemetry = 'impermissible' | 'signIn' | 'signUp' | 'verifyEmail';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  impermissible: () => ({
    category: 'account',
    pagePrefix: 'account',
    page: 'Impermissible',
    action: 'ViewImpermissible'
  }),
  signIn: () => ({
    category: 'account',
    pagePrefix: 'account',
    page: 'SignIn',
    action: 'ViewSignIn'
  }),
  signUp: () => ({
    category: 'account',
    pagePrefix: 'account',
    page: 'SignUp',
    action: 'ViewSignUp'
  }),
  verifyEmail: () => ({
    category: 'account',
    pagePrefix: 'account',
    page: 'VerifyEmail',
    action: 'ViewVerifyEmail'
  })
};

export const accountTelemetry = createTelemetryObject({
  actions: {
    thirdPartyButton: (provider: string) => ({
      action: 'ThirdPartyButton',
      actionType: 'click',
      category: 'account',
      label: provider
    }),
    unHandledThirdPartyButton: (provider?: string) => ({
      action: 'ThirdPartyButton',
      actionType: 'error',
      category: 'account',
      label: provider
    }),
    emailPasswordLogin: (email?: string, isPasswordEntered?: boolean) => ({
      action: 'LoginButton',
      actionType: 'click',
      category: 'account',
      extraInfo: {
        email,
        isPasswordEntered
      }
    }),
    emailPasswordSignUp: (email?: string, isPasswordEntered?: boolean) => ({
      action: 'SignUpButton',
      actionType: 'click',
      category: 'account',
      extraInfo: {
        email,
        isPasswordEntered
      }
    }),
    goToSignUp: () => ({
      action: 'LinkToSignUp',
      actionType: 'click',
      category: 'account'
    }),
    goToSignIn: () => ({
      action: 'LinkToSignIn',
      actionType: 'click',
      category: 'account'
    }),
    trackResendVerifyEmail: () => ({
      category: 'account',
      actionType: 'click',
      action: 'ResendVerificationEmail'
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry } = createTelemetry(accountTelemetry);
const useAccountTelemetry = useTelemetry;
export { TelemetryProvider, useAccountTelemetry };
