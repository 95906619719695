import { styled } from '@withjoy/joykit';
import { pageEdgeMargin, pageEdgeMarginMobile } from './cardConstants';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 32px 0px 0px 0px;
`;

export const StickyLogoContainer = styled.div<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px 0px 32px;
  position: absolute;
  top: calc(24px + 32px / 2);
  left: 64px;
  opacity: 0%;
  opacity: ${props => (props.show ? '100%' : '0%')};
  transition: ${props => (props.show ? '250ms linear' : '')};
  /* display: block; */
`;

export const PromptForm = styled.form<{ mobile?: boolean; islandOpened?: boolean; isSticky?: boolean }>`
  width: 100%;
  top: 0px;
  background-color: white;
  padding-top: 25px;
  padding-bottom: 24px;
  position: sticky;
  ${props => (props.isSticky ? 'border-bottom: solid 1px rgba(0,0,0,0.1);' : '')}
  z-index: 1000;
`;

const shimmerTime = '10s';
export const promptbar = {
  height: '64px',
  buttonHeight: '48px',
  islandMargin: '24px',
  borderWidth: '2px',
  gradient: 'linear-gradient(135deg, rgba(151,90,212,1) 0%, rgba(82,103,236,1) 50%, rgba(212,156,221,1) 100%)',
  shimmer: `
    background: linear-gradient(135deg, #975ad4, #5267ec, #d49cdd);
    background-size: 600% 600%;

    -webkit-animation: Shimmer ${shimmerTime} ease infinite;
    -moz-animation: Shimmer ${shimmerTime} ease infinite;
    animation: Shimmer ${shimmerTime} ease infinite;

    @-webkit-keyframes Shimmer {
        0%{background-position:0% 22%}
        50%{background-position:100% 79%}
        100%{background-position:0% 22%}
    }
    @-moz-keyframes Shimmer {
        0%{background-position:0% 22%}
        50%{background-position:100% 79%}
        100%{background-position:0% 22%}
    }
    @keyframes Shimmer {
        0%{background-position:0% 22%}
        50%{background-position:100% 79%}
        100%{background-position:0% 22%}
    }`
};

const buttonvars = {
  gap: `calc((${promptbar.height} - ${promptbar.buttonHeight}) / 2)`,
  loaderWidth: '50px'
};

export const ImagineButton = styled.button<{ expanded?: boolean; enabled?: boolean }>`
  ${promptbar.shimmer}
  transition: max-width 250ms linear, padding 250ms linear;

  font-size: 17px;
  color: white;

  cursor: ${props => (props.enabled ? 'pointer' : 'wait')};

  float: right;
  margin-left: auto;
  overflow: hidden;

  height: ${promptbar.buttonHeight};
  width: 100%;
  max-width: ${props => (props.expanded ?? true ? '100%' : buttonvars.loaderWidth)};
  padding: ${props => (props.expanded ?? true ? ' 0px 24px 0px 24px;' : ' 0px 8.5px 0px 8.5px;')};
  border-radius: 999px;
`;

export const Blinder = styled.div<{ active?: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  transition: background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  ${props =>
    props.active
      ? `
  background-color: rgb(50 50 50 / 0.4);
  `
      : `
  background-color: unset;
  
  `}
`;

export const PromptBarOutline = styled.div`
  padding: ${promptbar.borderWidth};
  position: relative;
  z-index: 9;
  ${promptbar.shimmer}

  border-radius: calc(${promptbar.height} / 2 + ${promptbar.borderWidth});
`;

export const PreviewsGrid = styled.div<{ $cols: number; useMargin?: boolean; pageMargin: number }>`
  display: grid;

  ${props =>
    props.$cols == 1 &&
    `
    grid-template-columns: 1fr;
    `}

  ${props =>
    props.$cols == 2 &&
    `
    grid-template-columns: 1fr 1fr;
    `}

${props =>
    props.$cols == 4 &&
    `
    grid-template-columns: 1fr 1fr 1fr 1fr;
    `}


  transform: translateX(${props => (props.useMargin ? props.pageMargin : 0)}px);

  gap: 0px;
  /* grid-row-gap: ${props => (props.$cols <= 2 ? '16px' : '32px')}; */
  justify-items: center;
  width: calc(100% - ${props => (props.$cols > 2 ? pageEdgeMargin * 2 : pageEdgeMarginMobile * 2)}px);
  margin: ${props => (props.$cols <= 2 ? '32px' : '40px')} 0px 100px 0px;
`;

export const SpecialInput = styled.input<{ mobile?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  /* text-overflow: ellipsis; */

  :placeholder-shown {
    text-overflow: ellipsis;
  }

  padding: 0px 0px 0px 30px;
  margin: 0px 0px 0px 0px;

  font-size: ${props => (props.mobile ? '16px' : '17px')};

  border-radius: calc(${promptbar.height} / 2) 0px 0px calc(${promptbar.height} / 2);
  border: none;

  color: ${props => (props.disabled ? '#aaaaaa' : 'black')};

  ::placeholder {
    color: #a9a9a9;
  }
`;

export const MenuBar = styled.div<{ mobile: boolean; showJoyLogo: boolean }>`
  height: fit-content;
  ${props =>
    props.mobile
      ? `
      position: unset;
      width: 100%;
      `
      : `
      position: relative;
      width: 80%;

  `}
  max-width: ${props => (props.mobile ? 'none' : '1000px')};
  padding-left: ${props => (props.showJoyLogo ? '120px' : 'unset')};
`;

export const InputBar = styled.div<{ mobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${buttonvars.gap};
  ${props =>
    props.mobile
      ? `
      position: unset;
      `
      : `
      position: relative;

  `}
  z-index: 10;

  * {
    z-index: 10;
  }

  input:focus {
    outline: none;
  }

  background-color: white;

  width: 100%;
  height: ${promptbar.height};
  padding: 0px ${buttonvars.gap} 0px 0px;

  border-radius: calc(${promptbar.height} / 2);
  /* border: 1px solid #d9d9d9; */
`;

export const PromptBarIsland = styled.div<{ mobile: boolean; isSticky: boolean }>`
  z-index: 5 !important;
  position: absolute;

  ${props =>
    props.mobile
      ? `
      width: 100vw;
      left: 0px;
      top: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      `
      : `
      width: calc(100% + ${promptbar.islandMargin} * 2);
      margin-right: -${promptbar.islandMargin};
      left: ${props.isSticky ? `calc(-${promptbar.islandMargin} + 120px)` : `-${promptbar.islandMargin}`};
      top: -${promptbar.islandMargin};
  `}

  min-height: calc(100% + 2 * ${promptbar.islandMargin} + 200px);
  height: fit-content;

  padding-bottom: 50px;

  background-color: #f7f7f7;

  border-radius: calc(${promptbar.height} / 2 + ${promptbar.islandMargin});
  border: 1px solid #d9d9d9;
  transition: opacity 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

export const IslandContent = styled.div`
  /* position: absolute; */
  margin: calc(${promptbar.islandMargin} + ${promptbar.height} + 40px) calc(${promptbar.height} / 4) 0px calc(${promptbar.height} / 4 + ${promptbar.islandMargin});

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const IslandLabel = styled.h3`
  font-weight: 700;
  font-size: 13px;
`;
