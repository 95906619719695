import React from 'react';
import { useSignUpController } from './SignUp.controller';
import { SignInContent } from '../CommonStyles';
import { LinkV1, Flex, ButtonV2, FormControl, InputV2, Box } from '@withjoy/joykit';
import { TermsAndPrivacyAgreement } from '../TermsAndPrivacyAgreement';
import { useTranslation } from '@shared/core';
import { AlternateModeText, Divider, PrivacyWrapper } from './SignUp.styles';
import { Email, Lock } from '@withjoy/joykit/icons';

interface Props
  extends Readonly<{
    redirectUri?: string;
    signInInstead: () => void;
  }> {}

export const SignUp: React.FC<Props> = props => {
  const { signInInstead, redirectUri } = props;
  const { formik, loading, submitError } = useSignUpController(redirectUri);
  const { t2, t } = useTranslation('account');
  const tSignIn = t2('signin');
  const tSignUp = t('signup');
  const signInFragment = t('alreadyAccount')({
    signInLink: () => (
      <LinkV1 style={{ marginLeft: '0.5rem' }} color={'linkActive'} onClick={signInInstead}>
        {tSignIn.signin}
      </LinkV1>
    )
  });

  return (
    <SignInContent>
      <Box as="form" display="grid" rowGap={6} marginBottom={'2rem'} onSubmit={formik.handleSubmit}>
        <FormControl label="" error={formik.errors.email && formik.touched.email ? formik.errors.email : submitError ? submitError : undefined}>
          <InputV2 placeholder={tSignIn.enterEmail} autoComplete="username" endElement={<Email />} {...formik.getFieldProps('email')} />
        </FormControl>
        <FormControl error={formik.errors.password && formik.touched.password ? formik.errors.password : undefined} label="">
          <InputV2 placeholder={tSignIn.enterPassword} autoComplete="new-password" endElement={<Lock />} {...formik.getFieldProps('password')} type={'password'} />
        </FormControl>
        <Flex justifyContent="left">
          <div style={{ flexShrink: 0 }}>
            <ButtonV2 type="submit" intent="primary" disabled={loading}>
              {tSignUp.signup()}
            </ButtonV2>
          </div>
          <PrivacyWrapper>
            <TermsAndPrivacyAgreement />
          </PrivacyWrapper>
        </Flex>
      </Box>
      <Divider />
      <AlternateModeText>{signInFragment}</AlternateModeText>
    </SignInContent>
  );
};
