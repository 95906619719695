import React from 'react';
import { NavItem, NavItemProps } from './components/NavItem';
import { safeInvoke, generateComponentDisplayName } from '../../utils';
import { isElementOfComponentType } from '../../utils/isElementOfComponentType';
import { NAV_NAVITEM_WARN_MISSING_HREF } from '../../common/errors';
import EventListener from 'react-event-listener';
import debounce from 'debounce';
import { NavScrollButton } from './components/NavScrollButton';
import { StyledNavWrapper, StyledNavContainer, StyledNavContent, ChildrenContainer, StyledNavIndicator } from './styles';
import { matchPath } from '@react-router';

// const debug = makeDebugger('<Nav />');

const CONTAINER_BUFFER = 60;

export interface NavProps {
  children: React.ReactNode;
  /**
   * The class to give the NavItem element when it is active.
   *
   * Default given class is `active`.
   */
  activeClassName: string;
  // selectedIndex?: number;
  // itemAs?: React.ComponentType<any> | string
}

type State = Readonly<{
  /**
   * The current active nav item.
   *
   * The `activeClassName` prop will determine which nav item is active.
   *
   * Defaults to `-1` if indeterminate.
   */
  activeNavItemIndex: number;

  /**
   * Styling properties of the indicator.
   *
   * The indicator's placement is determined by the current active nav item.
   */
  indicator: {
    /**
     * The indicator will be left offset from it's container depending on the
     * distance of the active nav link.
     */
    left: number;

    /**
     * The indicator will adjust it's sizing depending upon the width of
     * the active nav link.
     */
    width: number;
  };
  /**
   * Only show the indicator once this component and the contained nav items have been laid out.
   *
   * DOM reads are dependent upon the elements being mounted.
   */
  mounted: boolean;

  /**
   * Whether the left scroll button should be displayed
   */
  showLeftScroll: boolean;

  /**
   * Whether the left scroll button should be displayed
   */
  showRightScroll: boolean;
  hasShownScrollButtonOnce: boolean;
}>;

/**
 * Nav provides the main, top-level navigation.
 *
 * Usually placed in the `<TopBar />`.
 */
export class Nav extends React.PureComponent<NavProps, State> {
  static displayName = generateComponentDisplayName('Nav');
  static defaultProps = {
    activeClassName: 'active'
  };

  /**
   * Because we're only rendering `<NavItem />`, we want to keep track of
   * children indices for immediate access.
   */
  private navItemIndexToChildIndex = new Map();

  navRef = React.createRef<HTMLDivElement>();
  scrollContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: NavProps) {
    super(props);

    this.state = {
      activeNavItemIndex: -1,
      indicator: {
        left: 0,
        width: 0
      },
      mounted: false,
      showLeftScroll: false,
      showRightScroll: false,
      hasShownScrollButtonOnce: false
    };
  }

  // ==============================================================
  // Lifecycle
  // ==============================================================
  componentDidMount() {
    setTimeout(() => {
      requestAnimationFrame(() => {
        this.setState(state => {
          const activeIndex = this.getActiveNavItemIndex(this.props.activeClassName);
          return {
            activeNavItemIndex: activeIndex,
            mounted: true,
            indicator: this.updateIndicatorState(activeIndex) || state.indicator
          };
        });
        this.updateScrollButtonState();
      });
    }, 166);
  }

  componentDidUpdate(prevProps: NavProps, prevState: State) {
    // TODO: check to see if children have changed and update indicator properties + scroll button state
    this.updateScrollButtonState();
    const activeNavItemIndex = this.getActiveNavItemIndex(this.props.activeClassName);
    if (activeNavItemIndex !== prevState.activeNavItemIndex) {
      const nextIndicatorState = this.updateIndicatorState(activeNavItemIndex);
      if (nextIndicatorState && nextIndicatorState !== this.state.indicator) {
        this.setState({
          activeNavItemIndex,
          indicator: nextIndicatorState
        });
      }
    }
  }

  componentWillUnmount() {
    this.handleResizeEvent.clear();
    this.handleScrollEvent.clear();
  }

  // ==============================================================
  // Callback handlers
  // ==============================================================

  handleNavItemClick = (childProps: NavItemProps): React.MouseEventHandler<HTMLAnchorElement> => e => {
    const { enableClickWhenActive, onClick } = childProps;
    if (Boolean(e.currentTarget.getAttribute('aria-disabled'))) {
      // debug('NavItem is disabled - preventing default behavior.');
      e.preventDefault();
      return;
    }

    if (e.currentTarget.classList.contains(this.props.activeClassName) && !enableClickWhenActive) {
      e.preventDefault();
      // debug('NavItem is active - preventing default behavior.');
      return;
    }

    if (!e.currentTarget.hasAttribute('href')) {
      e.preventDefault();
      console.warn(NAV_NAVITEM_WARN_MISSING_HREF);
      return;
    }

    safeInvoke(onClick, e);

    const nextNavItemIndex = Number.parseInt(e.currentTarget.dataset.navitemIndex || '', 10);
    if (!isNaN(nextNavItemIndex)) {
      // debug(`handleNavItemClick() | Animating indicator to index ${nextNavItemIndex}`);

      this.setState(state => {
        const nextState = {
          activeNavItemIndex: nextNavItemIndex,
          indicator: this.updateIndicatorState(nextNavItemIndex) || state.indicator
        };
        return nextState;
      });
    }
  };

  handleScrollButtonClick: React.MouseEventHandler<HTMLElement> = e => {
    const scrollContainer = this.scrollContainerRef.current;
    const direction = e.currentTarget.dataset.direction;
    if (direction && scrollContainer) {
      // Adjust the offset according to the direction of change.
      const multiplier = direction === 'left' ? -1 : 1;
      // Shift scroll container by a multiplier of the *visible* client container subtracted by a minor buffer.
      const nextScrollLeft = scrollContainer.scrollLeft + multiplier * (scrollContainer.clientWidth - CONTAINER_BUFFER);
      requestAnimationFrame(() => {
        // Utilize browser scroll to capabilities.
        scrollContainer.scrollTo({ left: nextScrollLeft > 0 ? nextScrollLeft : 0, behavior: 'smooth' });
      });
    }
  };

  handleResizeEvent = debounce(() => {
    const indicatorState = this.updateIndicatorState(this.state.activeNavItemIndex);

    if (indicatorState) {
      this.setState({ indicator: indicatorState });
    }
    this.updateScrollButtonState();
  }, 166);

  handleScrollEvent = debounce(() => {
    this.updateScrollButtonState();
  }, 166);

  // ==============================================================
  // Render
  // ==============================================================

  render(): React.ReactNode {
    const { mounted, indicator, activeNavItemIndex, showLeftScroll, showRightScroll, hasShownScrollButtonOnce } = this.state;
    const children = this.getChildrenAndStoreIndices();
    return (
      <StyledNavWrapper>
        <EventListener target="window" onResize={this.handleResizeEvent} />
        <StyledNavContainer paddingLeft={[hasShownScrollButtonOnce ? 6 : '0px', null, 5]} paddingRight={[hasShownScrollButtonOnce ? 7 : 0, null, 5]}>
          {showLeftScroll && <NavScrollButton onClick={this.handleScrollButtonClick} direction="left" />}
          <StyledNavContent ref={this.scrollContainerRef} onScroll={this.handleScrollEvent}>
            <ChildrenContainer ref={this.navRef}>{children}</ChildrenContainer>
            {mounted && activeNavItemIndex !== -1 && <StyledNavIndicator style={{ left: indicator.left, width: indicator.width }} />}
          </StyledNavContent>
          {showRightScroll && <NavScrollButton onClick={this.handleScrollButtonClick} direction="right" />}
        </StyledNavContainer>
      </StyledNavWrapper>
    );
  }

  // ==============================================================
  // Utils
  // ==============================================================

  private getActiveNavItemIndex = (activeClassName: string): number => {
    const navRef = this.navRef.current;

    if (navRef) {
      const navItems = navRef.children;
      const reactChildren = React.Children.toArray(this.props.children);

      const resul = Array.from(navItems).findIndex((navItem, childIndex) => {
        const href = navItem.getAttribute('href');
        const reactChildIndex = this.navItemIndexToChildIndex.get(childIndex);
        const reactChild = reactChildIndex !== undefined && (reactChildren[reactChildIndex] as React.ReactElement<NavItemProps>);
        if (href && reactChild) {
          return !!matchPath(location.pathname, {
            path: href,
            exact: reactChild.props.exact,
            sensitive: reactChild.props.sensitive,
            strict: reactChild.props.strict
          });
        }
        return false;
      });
      return resul;
    }
    return -1;
  };

  private getChildrenAndStoreIndices = () => {
    let childIndex = 0;

    return React.Children.map(this.props.children, (child, index) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      if (isElementOfComponentType(child, NavItem)) {
        // For right now, we'll only accept `NavItem`s as children.
        this.navItemIndexToChildIndex.set(childIndex, index);
        const isSelected = childIndex === this.state.activeNavItemIndex;
        childIndex += 1;

        return React.cloneElement(child, {
          ...child.props,
          className: isSelected
            ? child.props.className
              ? child.props.className.indexOf(this.props.activeClassName) === -1
                ? child.props.className + ' ' + this.props.activeClassName
                : this.props.activeClassName
              : this.props.activeClassName
            : child.props.className,

          // Necessary for finding the active nav item and adjusting the indicator/scroll button state
          'data-navitem-index': index,
          // We want to wrap the child on click and process it before allowing it to propagate.
          onClick: this.handleNavItemClick(child.props)
        });
      }
      return null;
    });
  };

  /**
   * Batch all HTMLElement reads here.
   */
  private getNavDimensions = (index: number) => {
    // debug('getNavDimensions();');
    const navRef = this.navRef.current;
    let navMeta;
    let targetNavItemMeta;
    if (navRef) {
      const NavRect = navRef.getBoundingClientRect();
      navMeta = {
        clientWidth: navRef.clientWidth,
        scrollLeft: navRef.scrollLeft,
        scrollWidth: navRef.scrollWidth,
        left: NavRect.left,
        right: NavRect.right
      };
      const children = navRef.children;
      if (children.length > 0) {
        const tab = children[this.navItemIndexToChildIndex.get(index)];
        targetNavItemMeta = tab ? tab.children[0].getBoundingClientRect() : null;
      }
    }
    return { navMeta, targetNavItemMeta };
  };

  /**
   * Perform and apply calculations regarding the indicator's `scrollLeft` and updated `width`.
   */
  private updateIndicatorState = (activeIndex: number): State['indicator'] | undefined => {
    let left = 0;

    const { navMeta, targetNavItemMeta } = this.getNavDimensions(activeIndex);

    if (navMeta && targetNavItemMeta) {
      // Determine how far the indicator should should be scrolled from the left
      left = targetNavItemMeta.left - navMeta.left + navMeta.scrollLeft;
    }

    const nextIndicatorState: State['indicator'] = {
      left,
      width: targetNavItemMeta ? targetNavItemMeta.width : 0
    };

    if (!isNaN(nextIndicatorState.left) && !isNaN(nextIndicatorState.width)) {
      if (nextIndicatorState.left === this.state.indicator.left && nextIndicatorState.width === this.state.indicator.width) {
        // Exact same, return early;
        return this.state.indicator;
      }
      // Adjustments are valid declarations and can be applied to the Element.
      return nextIndicatorState;
    }
    return undefined;
  };

  private updateScrollButtonState = () => {
    const scrollContainer = this.scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollWidth, clientWidth, scrollLeft } = scrollContainer;

      // Container potentially has hidden elements to its left.
      const showLeftScroll = scrollLeft > 0;

      // There are items to the right of the visible container
      const showRightScroll = scrollWidth > clientWidth + scrollLeft;

      if (showLeftScroll !== this.state.showLeftScroll || showRightScroll !== this.state.showRightScroll) {
        this.setState({ showLeftScroll, showRightScroll, hasShownScrollButtonOnce: true });
      }
    }
  };
}

/**
 * Export Nav Items directly for benefit of collocation and inherent relation.
 */
export * from './components/NavItem';
