import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from '@shared/core';
import PeoplePicker from './components/PeoplePicker';
import { ButtonV2, useDisclosure, useToast } from '@withjoy/joykit';
import { PeopleContext } from '@shared/context/people';
import { ReactComponent as TestEmailSvg } from '@assets/icons/test-email.svg';
import { ReactComponent as SendEmailSvg } from '@assets/icons/send-email.svg';
import { ReactComponent as AddGuestsSvg } from '@assets/icons/add-guests.svg';
import { routePaths as accountRoutePaths } from '@apps/account/route.paths';
import { withWindow } from '@shared/utils/withWindow';
import { useSaveTheDateDispatch, useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { getPeopleIds } from '@shared/components/EmailsAndEcards/Emails.utils';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';

const BUTTON_WIDTH = 230;

interface ConfirmAndSendAction {
  label: string;
  hed: string;
  dek: string;
  onClick?: () => void;
  button: React.ReactElement;
  icon: React.ReactElement;
}

export const useConfirmController = () => {
  const { eventHandle, testEmail, emailIsVerified } = useSaveTheDateState();
  const { sendEmail, copyShareableLink } = useSaveTheDateDispatch();
  const { confirmTestEmailTrack } = useSaveTheDateTelemetry();

  const { toast } = useToast();
  const { selectedPeople, clearSelectedPeople } = useContext(PeopleContext);
  const confirmedGuestsCount = useRef<number | null>(null);
  const [isConfirmationScreenShown, setIsConfirmationScreenShown] = useState<boolean>(false);
  const [isSendTestEmailDialogOpen, setIsSendTestEmailShow] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const { value: eCardEnableShareableLinkOn } = useFeatureValue('eCardEnableShareableLink');
  const shareableLinksEnabled = eCardEnableShareableLinkOn === 'on';
  const shareableDialog = useDisclosure();

  const { t } = useTranslation('emailsAndEcards');
  const tConfirmAndSend = t('confirmAndSend');
  const tConfirmAndSendActions = tConfirmAndSend.confirmAndSendActions;
  const tAddGuests = tConfirmAndSendActions.addGuests;
  const tAddGuestsHed = tAddGuests.hed.guest;
  const tAddGuestsButton = tAddGuests.buttonText.guest;

  const verifyEmailURL = `/account/${accountRoutePaths.verifyEmail.goToPath()}`;
  const selectedGuestsCount = !!selectedPeople && selectedPeople.length ? selectedPeople.length : 0;

  const onSendEmail = () => {
    if (selectedPeople.length === 0) {
      toast(tConfirmAndSend.noGuestsSelectedErrorMessage());
    } else {
      setIsSendingEmail(true);
      sendEmail({
        selectedPeopleIds: getPeopleIds(selectedPeople || []),
        onCompleted: () => {
          confirmedGuestsCount.current = selectedGuestsCount;
          setIsConfirmationScreenShown(true);
          clearSelectedPeople();
          setIsSendingEmail(false);
        },
        onError: () => {
          toast(tConfirmAndSend.emailSentErrorMessage());
          setIsSendingEmail(false);
        }
      });
    }
  };

  const handleSendTestEmail = (testEmail: string, testName: string) => {
    sendEmail({
      testEmail,
      testName,
      onCompleted: () => {
        toast(tConfirmAndSend.emailSentSuccessMessage.guest({ count: selectedGuestsCount }));
      },
      onError: () => {
        toast(tConfirmAndSend.emailSentErrorMessage());
      }
    });
  };

  const onVerifyEmailClick = () => withWindow(window => window.open(verifyEmailURL, '_blank'));

  const confirmAndSendActions: Array<ConfirmAndSendAction | null> = [
    !emailIsVerified
      ? {
          label: 'verify email',
          hed: tConfirmAndSendActions.verifyEmail.hed(),
          dek: tConfirmAndSendActions.verifyEmail.dek(),
          button: (
            <ButtonV2 variant="outline" intent="primary" onClick={onVerifyEmailClick} width={BUTTON_WIDTH}>
              {tConfirmAndSendActions.verifyEmail.buttonText()}
            </ButtonV2>
          ),
          icon: <TestEmailSvg />
        }
      : null,
    {
      label: 'add guests',
      hed: tAddGuestsHed({ context: `${selectedGuestsCount}` }),
      dek: tConfirmAndSendActions.addGuests.dek(),
      button: (
        <PeoplePicker
          eventHandle={eventHandle}
          buttonTextDefault={tAddGuestsButton({ count: selectedGuestsCount, context: `${selectedGuestsCount}` })}
          buttonTextHover={tAddGuests.buttonTextHover()}
          buttonWidth={BUTTON_WIDTH}
          disabled={!emailIsVerified}
        />
      ),
      icon: <AddGuestsSvg />
    },
    {
      label: 'test email',
      hed: tConfirmAndSendActions.testEmail.hed(),
      dek: tConfirmAndSendActions.testEmail.dek(),
      button: (
        <ButtonV2
          variant="outline"
          intent="primary"
          onClick={() => {
            setIsSendTestEmailShow(true);
            confirmTestEmailTrack.enter();
          }}
          width={BUTTON_WIDTH}
          disabled={!emailIsVerified}
        >
          {tConfirmAndSendActions.testEmail.buttonText()}
        </ButtonV2>
      ),
      icon: <TestEmailSvg />
    },
    {
      label: 'send',
      hed: tConfirmAndSendActions.send.hed(),
      dek: tConfirmAndSendActions.send.dek(),
      button: (
        <ButtonV2 variant="solid" intent="primary" onClick={onSendEmail} disabled={!selectedGuestsCount || !emailIsVerified} loading={isSendingEmail} width={BUTTON_WIDTH}>
          {tConfirmAndSendActions.send.buttonText()}
        </ButtonV2>
      ),
      icon: <SendEmailSvg />
    }
  ];

  const handleSendTestEmailDialogClose = () => {
    setIsSendTestEmailShow(false);
  };

  return {
    eventHandle,
    testEmail,
    confirmAndSendActions,
    tConfirmAndSend,
    isConfirmationScreenShown,
    selectedGuestsCount: confirmedGuestsCount.current || selectedGuestsCount,
    handleSendTestEmail,
    handleSendTestEmailDialogClose,
    isSendTestEmailDialogOpen,
    copyShareableLink,
    shareableLinksEnabled,
    shareableDialog
  };
};
