import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { MinimalRequiredTemplateDataForDraft } from '../../DesignsGallery/DesignsGallery.types';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useHistory } from '@react-router';
import { useCreateStationeryDraftMutation } from '@graphql/generated';
import { useCallback } from 'react';

const useCreateCardDraftAndRedirectToEditor = () => {
  const { getCustomizeDraftPath } = useCardsRouterContext();
  const history = useHistory();

  const [callCreateDraft] = useCreateStationeryDraftMutation({
    onCompleted: data => {
      const { id } = data.createStationeryDraft;
      const paperRoute = getCustomizeDraftPath(id);
      history.push(paperRoute);
    }
  });

  const createCardDraftAndRedirectToEditor = useCallback(
    (template: MinimalRequiredTemplateDataForDraft) => {
      callCreateDraft({
        variables: {
          payload: {
            cardJSON: template.themeJSON,
            stationeryTemplateId: template.id
          }
        }
      });
    },
    [callCreateDraft]
  );

  return { createCardDraftAndRedirectToEditor };
};

export const useHandleAdminOnCustomizeNowClick = () => {
  const { createCardDraftAndRedirectToEditor } = useCreateCardDraftAndRedirectToEditor();
  const handleAdminOnCustomizeNowClick = useEventCallback((data: MinimalRequiredTemplateDataForDraft) => {
    createCardDraftAndRedirectToEditor(data);
  });

  return { handleAdminOnCustomizeNowClick };
};
