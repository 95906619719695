import React, { useCallback } from 'react';
import { ButtonV2, ButtonV2Props } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useMfaController } from '@shared/components/MfaController/MfaControllerProvider.context';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';

interface ButtonMFAProps extends ButtonV2Props {
  onShowDialog: () => void;
}

const ButtonMFA: React.FC<ButtonMFAProps> = ({ onShowDialog, ...rest }) => {
  const { t } = useTranslation('account');
  const telemetry = useAccountMeTelemetry();
  const { enableMfa, loading: isMfaLoading, isMfaEnabled } = useMfaController();
  const handleClick = useCallback(() => {
    if (isMfaEnabled) {
      return onShowDialog();
    }
    telemetry.trackMFASetUpButtonClick();
    return enableMfa();
  }, [enableMfa, isMfaEnabled, onShowDialog, telemetry]);

  const label = isMfaEnabled ? t('MFASettings', 'disableButtonTitle') : t('MFASettings', 'enableButtonTitle');

  return (
    <ButtonV2
      color="mono14"
      borderColor="mono14"
      variant={isMfaEnabled ? 'link' : 'outline'}
      intent="neutral"
      size={isMfaEnabled ? 'md' : 'lg'}
      _hover={{ color: 'mono14' }}
      _active={{ color: 'mono14' }}
      loading={isMfaLoading}
      onClick={handleClick}
      fontWeight="semibold"
      fontSize={16}
      {...rest}
    >
      {label()}
    </ButtonV2>
  );
};

export { ButtonMFA };
