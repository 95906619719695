import React, { useMemo } from 'react';
import { InputV2, FormControl, SpacingStack, ButtonV2, TextV2, Divider, LinkV2, IconV2, Flex } from '@withjoy/joykit';
import { usePersonalInfoController } from './PersonalInfo.controller';
import { PersonalInfoWrapper, PersonalInfoWrapperStyles, StyledLink, StyledCaption, StyledLabel, StyledInlineWarningMessage } from './PersonalInfo.styles';
import { UserForMePageFragment } from '@graphql/generated';
import SentResetPassword from '@apps/accountMe/components/Dialogs/SentResetPassword';
import DeleteUserDialog from '@apps/accountMe/components/Dialogs/DeleteUser';
import MustLeaveEvents from '@apps/accountMe/components/Dialogs/MustLeaveEvents';
import SavePersonInfoFailure from '@apps/accountMe/components/Dialogs/SavePersonInfoFailure';
import { useTranslation } from '@shared/core';
import VerifyEmailSuccess from '@apps/accountMe/components/Dialogs/VerifyEmailSuccess';
import ButtonMFA from '@apps/accountMe/components/ButtonMFA';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ResetMFADialog } from '@apps/accountMe/components/Dialogs/ResetMFA/ResetMFA';
import { useMfaController } from '@shared/components/MfaController/MfaControllerProvider.context';
import { config } from '@static/js/env.config';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { ReactComponent as HeartLock } from '@assets/icons/heart-lock.svg';
import { ReactComponent as CheckLock } from '@assets/icons/check-lock.svg';

interface Props
  extends Readonly<{
    userId: Maybe<string>;
    user: UserForMePageFragment;
    provider?: string;
  }> {}

export const PersonalInfo: React.FC<Props> = ({ userId, user, provider }) => {
  const {
    formik,
    handleDeleteAccount,
    handleChangePassword,
    verifyEmailURL,
    changePasswordSuccessDialogIsShown,
    hideChangePasswordSuccess,
    deleteDialogOpen,
    mustLeaveEventsDialogOpen,
    handleDeleteDialogClose,
    userUpdateStatus,
    emailVerified,
    thirdPartyAuth,
    showErrorDialog,
    handleErrorDialogClose,
    shouldShowVerifyEmailSuccess,
    handleHideVerifyEmailSuccess,
    resetMFADialogOpen,
    handleResetMFADialogClose,
    handleResetMFADialogOpen
  } = usePersonalInfoController({
    id: userId,
    user,
    provider
  });
  const enableMFASettings = useFeatureValue('enableMFASettings');
  const showMfa = config.joyEnvironment === 'development' || enableMFASettings;

  const { isAuth0Provider, isMfaEnabled } = useMfaController();
  const canEnableMfa = useMemo(() => isAuth0Provider && user.aliases.length === 1, [isAuth0Provider, user]);
  const { t, t2 } = useTranslation('account');
  const { emailTitle, passwordTitle, passwordChangeActionText, firstNameTitle, lastNameTitle, submitButtonText } = t2('personalInfoForm');
  const deleteAccountButtonText = t2('deleteAccountButtonText');

  const verifyEmailWarning = t(
    'verfiyEmailWarning',
    'message'
  )({
    verifyLink: () => (
      <LinkV2 typographyVariant="button1" color="mono14" href={verifyEmailURL} target="_blank">
        {t('verfiyEmailWarning', 'linkText')()}
      </LinkV2>
    )
  });

  const MFANotSupportedText = t(
    'MFASettings',
    'notSupportedText'
  )({
    Link: (
      <LinkV2 typographyVariant="body1" href="mailto:support@withjoy.com" textDecoration="none">
        customer support
      </LinkV2>
    )
  });

  return (
    <PersonalInfoWrapper {...PersonalInfoWrapperStyles}>
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <SpacingStack marginTop="82px" spacing={6}>
          {!emailVerified && (
            <SpacingStack spacing={2}>
              <StyledInlineWarningMessage>
                <IconV2 size="lg" color="#FFA133">
                  <Warning />
                </IconV2>
                <TextV2 marginLeft={16} typographyVariant="body1" color="mono12" textAlign="left">
                  {verifyEmailWarning}
                </TextV2>
              </StyledInlineWarningMessage>
            </SpacingStack>
          )}
          <SpacingStack spacing={6}>
            <FormControl label={<StyledLabel>{firstNameTitle}</StyledLabel>}>
              <>
                <InputV2 key="firstName" {...formik.getFieldProps('firstName')} isInvalid={!!formik.getFieldMeta('firstName').error} />
                <TextV2 typographyVariant="body3" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('firstName').error}>
                  {formik.getFieldMeta('firstName').error}
                </TextV2>
              </>
            </FormControl>

            <FormControl label={<StyledLabel>{lastNameTitle}</StyledLabel>}>
              <>
                <InputV2 key="lastName" {...formik.getFieldProps('lastName')} isInvalid={!!formik.getFieldMeta('lastName').error} />
                <TextV2 typographyVariant="body3" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('lastName').error}>
                  {formik.getFieldMeta('lastName').error}
                </TextV2>
              </>
            </FormControl>
            <FormControl label={<StyledLabel>{emailTitle}</StyledLabel>}>
              <>
                <InputV2 key="email" type="email" {...formik.getFieldProps('email')} isDisabled={thirdPartyAuth} isInvalid={!!formik.getFieldMeta('email').error} />
                <TextV2 typographyVariant="body3" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('email').error}>
                  {formik.getFieldMeta('email').error}
                </TextV2>
              </>
            </FormControl>

            {thirdPartyAuth ? null : (
              <FormControl
                label={<StyledLabel>{passwordTitle}</StyledLabel>}
                caption={
                  <StyledCaption>
                    <StyledLink typographyVariant="label2" color="mono14" onClick={handleChangePassword}>
                      {passwordChangeActionText}
                    </StyledLink>
                  </StyledCaption>
                }
              >
                <InputV2 key="password" value={'******'} type="password" isDisabled={true} />
              </FormControl>
            )}
          </SpacingStack>
          <ButtonV2 marginBottom="16px" intent="neutral" type="submit" disabled={!formik.dirty || (!formik.isValid && !userUpdateStatus.loading)}>
            {submitButtonText}
          </ButtonV2>
          {showMfa && (
            <>
              <Divider marginY="16px" />

              {canEnableMfa && (
                <Flex marginTop={0} marginBottom={16} flexDirection="column" alignItems="center">
                  <IconV2 size="xxl">{isMfaEnabled ? <CheckLock /> : <HeartLock />}</IconV2>
                  <TextV2 marginTop={16} typographyVariant="hed4">
                    {isMfaEnabled ? t('MFASettings', 'titleEnabled')() : t('MFASettings', 'title')()}
                  </TextV2>
                  <TextV2 marginTop={5} typographyVariant="body2" color="mono12" textAlign="center">
                    {isMfaEnabled ? t('MFASettings', 'enabledTextDescription')() : t('MFASettings', 'description')()}
                  </TextV2>
                  <ButtonMFA marginTop={7} fullWidth onShowDialog={handleResetMFADialogOpen} />
                </Flex>
              )}
              {!canEnableMfa && (
                <StyledInlineWarningMessage>
                  <div>
                    <TextV2 typographyVariant="hed2" color="mono12" textAlign="center">
                      {t('MFASettings', 'notSupportedTitle')()}
                    </TextV2>
                    <TextV2 marginTop={5} typographyVariant="body1" color="mono12" textAlign="center">
                      {MFANotSupportedText}
                    </TextV2>
                  </div>
                </StyledInlineWarningMessage>
              )}
              <Divider marginY="40px" />
            </>
          )}
        </SpacingStack>
      </form>
      <div>
        <ButtonV2 marginTop="13px" variant="ghost" intent="destructive" fullWidth={true} onClick={handleDeleteAccount}>
          {deleteAccountButtonText}
        </ButtonV2>
      </div>
      <SentResetPassword email={user?.email || ''} isOpen={changePasswordSuccessDialogIsShown} onClose={hideChangePasswordSuccess} />
      <DeleteUserDialog isOpen={deleteDialogOpen} onClose={handleDeleteDialogClose} />
      <MustLeaveEvents isOpen={mustLeaveEventsDialogOpen} onClose={handleDeleteDialogClose} />
      <SavePersonInfoFailure isOpen={showErrorDialog} onClose={handleErrorDialogClose} errorMessage={userUpdateStatus.error?.message} targetEmail={formik.values.email} />
      <VerifyEmailSuccess isOpen={shouldShowVerifyEmailSuccess} onClose={handleHideVerifyEmailSuccess} />
      <ResetMFADialog isOpen={resetMFADialogOpen} onClose={handleResetMFADialogClose} />
    </PersonalInfoWrapper>
  );
};
