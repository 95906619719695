import React, { Fragment } from 'react';
import { PhotoSelectorOptionType, usePhotoSelectorController, NO_PHOTO_OPTION_KEY, CUSTOM_OPTION_KEY, UPLOAD_CUSTOM_KEY } from './PhotoSelector.controller';
import { EventPageFragment } from '@graphql/generated';
import { Listbox, Transition } from '@headlessui/react';
import {
  PhotoSelectorContainer,
  SelectorButton,
  ButtonImage,
  ButtonText,
  ButtonRightIcon,
  NoPhotoOptionThumbnail,
  PhotoButtonText,
  Divider,
  ButtonRightSlot
} from './PhotoSelector.styles';
import clsx from 'classnames';

import { ChevronDown, Checkmark, Photo } from '@withjoy/joykit/icons';
import { IconV2 } from '@withjoy/joykit';
import { addRendition } from '@shared/utils/photoRendition';

export interface PhotoSelectorProps {
  handlePhotoSelect: (photoUrl: { previewUrl: string; assetId?: string; id: string; width?: number; height?: number }) => void;
  pages?: ReadonlyArray<EventPageFragment>;
  allowCustomUpload?: boolean;
  allowNoPhoto?: boolean;
  containerId?: string;
  photo?: {
    id?: string;
    url?: string;
    assetId?: string;
    width?: number;
    height?: number;
  };
  uploadDifferentPhotoRef?: React.MutableRefObject<(() => void) | undefined>;
}

export const PhotoSelector: React.FC<PhotoSelectorProps> = props => {
  const { allowCustomUpload } = props;
  const { photoOptions, selectedPhotoOption, selectedPhotoOptionIndex, handleOnPhotoOptionChange, handleUpdatePhotoClick } = usePhotoSelectorController(props);

  if (props.uploadDifferentPhotoRef) {
    props.uploadDifferentPhotoRef.current = handleUpdatePhotoClick;
  }

  const handleOnChange = (value: PhotoSelectorOptionType | null) => {
    if (value) {
      handleOnPhotoOptionChange(value);
    }
  };

  const renderThumbnail = (option?: PhotoSelectorOptionType) => {
    if (!option) return null;

    if (option.key === NO_PHOTO_OPTION_KEY || !option.value) {
      return <NoPhotoOptionThumbnail />;
    }

    return <ButtonImage src={addRendition({ url: option.value || '', renditionSize: 'small' })} alt={option.alt || ''} />;
  };

  const renderOption = (option: PhotoSelectorOptionType, index: number) => {
    const isSelected = selectedPhotoOptionIndex === index;
    const isCustomOption = option.key === CUSTOM_OPTION_KEY;

    return (
      <React.Fragment key={option.key}>
        {isCustomOption && <Divider />}
        <Listbox.Option key={option.key} value={option} className={clsx('photoselector-listbox-option', { 'active-option': isSelected })}>
          {renderThumbnail(option)}
          <ButtonText>{option.label}</ButtonText>

          <ButtonRightSlot>
            {isSelected && (
              <ButtonRightIcon>
                <IconV2 size="sm">
                  <Checkmark color="linkActive" />
                </IconV2>
              </ButtonRightIcon>
            )}
          </ButtonRightSlot>
        </Listbox.Option>
      </React.Fragment>
    );
  };

  if (!photoOptions || photoOptions.length < 1) return null;

  return (
    <>
      <PhotoSelectorContainer>
        <Listbox value={selectedPhotoOption} onChange={handleOnChange}>
          {({ open }) => (
            <>
              <SelectorButton>
                {renderThumbnail(selectedPhotoOption)}
                <ButtonText>{selectedPhotoOption?.label}</ButtonText>
                <ButtonRightSlot>
                  <ButtonRightIcon rotate={open ? -180 : 0}>
                    <IconV2 size="md">
                      <ChevronDown />
                    </IconV2>
                  </ButtonRightIcon>
                </ButtonRightSlot>
              </SelectorButton>
              <Transition as={Fragment} enterFrom="opacity-0" enterTo="opacity-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="transition photoselector-listbox-options">
                  {photoOptions.map(renderOption)}
                  {allowCustomUpload && (
                    <React.Fragment key={UPLOAD_CUSTOM_KEY}>
                      <Divider />
                      <Listbox.Option value={{ key: UPLOAD_CUSTOM_KEY }} className="photoselector-listbox-option upload-option">
                        <IconV2 size="md" marginRight={2}>
                          <Photo color="linkText" />
                        </IconV2>
                        <PhotoButtonText>Upload Custom Photo</PhotoButtonText>
                      </Listbox.Option>
                    </React.Fragment>
                  )}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </PhotoSelectorContainer>
    </>
  );
};
