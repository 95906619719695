import React from 'react';
import { LinkV1, Flex, TextV2, Box, InputV2, FormControl, ButtonV2 } from '@withjoy/joykit';
import { useSignInController } from './SignIn.controller';
import { SignInContent, CenteredStack } from '../CommonStyles';
import { TermsAndPrivacyAgreement } from '../TermsAndPrivacyAgreement';
import { useTranslation } from '@shared/core';
import { ErrorMessage } from './SignIn.styles';
import { Email, Lock } from '@withjoy/joykit/icons';

export interface Props
  extends Readonly<{
    redirectUri?: string;
    signUpInstead: () => void;
  }> {}

export const SignIn: React.FC<Props> = props => {
  const { redirectUri, signUpInstead } = props;
  const { t2 } = useTranslation('account');
  const tSignin = t2('signin');
  const { formik, loading, submitError } = useSignInController(redirectUri);
  return (
    <SignInContent>
      <CenteredStack spacing={5} margin={6}>
        <TextV2 component="h2" typographyVariant="hed6">
          {tSignin.signInToJoy}
        </TextV2>
        <div>
          {tSignin.donthaveaccount} <LinkV1 onClick={signUpInstead}>{tSignin.createAnAccount}</LinkV1>
        </div>
      </CenteredStack>
      {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <Box display="grid" rowGap={6}>
          <FormControl label="" error={formik.errors.email && formik.touched.email ? formik.errors.email : undefined}>
            <InputV2 placeholder={tSignin.enterEmail} autoComplete="username" {...formik.getFieldProps('email')} endElement={<Email />} />
          </FormControl>
          <FormControl error={formik.errors.password && formik.touched.password ? formik.errors.password : undefined} label="">
            <InputV2 type="password" placeholder={tSignin.enterPassword} autoComplete="current-password" {...formik.getFieldProps('password')} endElement={<Lock />} />
          </FormControl>
          <Flex justifyContent="center">
            <ButtonV2 fullWidth={true} type="submit" onClick={formik.submitForm} loading={loading}>
              {tSignin.signin}
            </ButtonV2>
          </Flex>
          <TermsAndPrivacyAgreement />
        </Box>
      </form>
    </SignInContent>
  );
};
