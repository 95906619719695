import React, { useCallback, useState } from 'react';
import { useEventCallback } from './useEventCallback';
import { runIfFn } from '@shared/utils/functions';

export interface UseControllableStateProps<T> {
  value?: T;
  defaultValue?: T | (() => T);
  changeHandler?: (value: T) => void;
}

export const useControllableState = <T>({ value, defaultValue, changeHandler }: UseControllableStateProps<T>) => {
  const [stateValue, setState] = useState(defaultValue as T);

  const onChangeProp = useEventCallback(changeHandler);

  const isControlled = value !== undefined;
  const currentValue = isControlled ? (value as T) : stateValue;
  const setValue = useCallback(
    (next: React.SetStateAction<T>) => {
      const newValue = runIfFn(next, currentValue);

      if (newValue === currentValue) {
        return;
      }

      if (!isControlled) {
        setState(newValue);
      }

      onChangeProp(newValue);
    },
    [onChangeProp, currentValue, isControlled]
  );
  return [currentValue, setValue, isControlled] as [T, React.Dispatch<React.SetStateAction<T>>, boolean];
};
