import React from 'react';
import { generateComponentDisplayName } from '../../utils';
import { AbstractButton, AbstractButtonProps, Intent } from '../../common/AbstractButton';
import { StyledButton } from './styles';
import { BaseComponentProps } from '../../common/props';
import { BoxProps } from '@withjoy/joykit';

type InternalButtonProps = {
  borderRadius?: 'none' | 'default';
  type?: 'button' | 'submit';
};

/**
 * -----------------------------------
 * DEPRECATED -- Use `<ButtonV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { ButtonV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/ButtonV2/Button.stories.tsx`.
 */
export interface ButtonV1Props extends BaseComponentProps, Merge<BoxProps<'button'>, AbstractButtonProps & InternalButtonProps> {}

/**
 * -----------------------------------
 * DEPRECATED -- Use `<ButtonV2 />`
 * ----------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { ButtonV2 } from '@withjoy/joykit';
 * ```
 *
 * Check examples here `src/shared/joykit/packages/core/components/ButtonV2/Button.stories.tsx`.
 */
export class ButtonV1 extends AbstractButton<ButtonV1Props> {
  static displayName = generateComponentDisplayName('Button');
  static defaultProps: AbstractButtonProps & ButtonV1Props = {
    fill: false,
    intent: 'primary' as Intent,
    loading: false,
    borderRadius: 'default' as ButtonV1Props['borderRadius']
  };

  render() {
    return (
      <StyledButton as="button" {...this.props} {...this.getCommonButtonProps()}>
        <span>{this.renderChildren()}</span>
      </StyledButton>
    );
  }
}

export class AnchorButton extends AbstractButton<Merge<BoxProps<'a'>, InternalButtonProps>> {
  static displayName = generateComponentDisplayName('Button');
  static defaultProps: AbstractButtonProps & ButtonV1Props = {
    fill: false,
    intent: 'primary' as Intent,
    loading: false,
    borderRadius: 'default' as ButtonV1Props['borderRadius']
  };

  render() {
    return (
      <StyledButton as={'a' as 'a'} {...this.props} {...this.getCommonButtonProps()}>
        <span>{this.renderChildren()}</span>
      </StyledButton>
    );
  }
}
