import React from 'react';
import { generateComponentDisplayName } from '../../utils';
import { BaseComponentProps } from '../../common/props';
import { StyledStack } from './styles';
import { BoxProps } from '../Box';

export interface StackProps extends BaseComponentProps, BoxProps {
  children: React.ReactNode;
  /**
   * When true, this will stack the elements vertically.
   *
   * Accepts an array and object to set mobile-first responsive styles.
   *
   * Defaults to `true`.
   */
  vertical?: boolean;

  /**
   * Amount of spacing to be applied to a Stack's contained components.
   *
   * - A Spacing option or Number reference steps on the spacing scale.
   *  - spacing={1} = theme.space[1]
   * - An array of spacing options references steps on the spacing scale, but applied to the target
   * breakpoints
   *  - spacing={`[2, 5, 6, 2]`}  => {
   *  xs: theme.space[2],
   *  sm: theme.space[5],
   *  md: theme.space[6],
   *  lg: theme.space[2]
   * }
   */
  spacing?: number;

  /**
   * When true, this will override `vertical` when the screen has a min-width: 0px and max-width: 768px.
   *
   * This will typically be provided when `vertical` is set to false, so that it may handle mobile UI.
   *
   * Defaults to the boolean provided for `vertical` - which is true.
   */
  stackVerticalOnMobile?: boolean;

  // /**
  //  * When true, this will wrap overflowed children onto the next line.
  //  *
  //  * This will typically be provided when `vertical` is set to false.
  //  */
  // wrap?: ResponsiveValue<boolean>;
}

export const stackDefaultProps = {
  vertical: true,
  spacing: 7
};

/**
 * ----------------------------------
 * DEPRECATED - use `<SpacingStack />`
 * ----------------------------------
 *
 * ```ts
 * import { SpacingStack } from '@withjoy/joykit'
 * ```
 *
 * -----
 *
 *
 * The overwhelming majority of UI is scrolled vertically. To achieve vertical rhythm, we will aim to
 * uniformly space components in a vertical/horizontal structure.
 *
 *
 * @deprecated
 *
 */
const Stack = React.forwardRef<HTMLDivElement, Merge<React.HTMLAttributes<HTMLDivElement>, StackProps>>((props, ref) => {
  let { children, vertical, spacing, stackVerticalOnMobile, ...rest } = props;
  // const layoutProps = {
  //   [vertical ? 'marginBottom' : 'marginRight']: spacing
  // };
  stackVerticalOnMobile = stackVerticalOnMobile === undefined ? vertical : stackVerticalOnMobile;
  return (
    <StyledStack {...rest} vertical={vertical} stackVerticalOnMobile={stackVerticalOnMobile} spacing={spacing} fowardedRef={ref}>
      {children}
    </StyledStack>
  );
});

Stack.displayName = generateComponentDisplayName('Stack');
Stack.defaultProps = stackDefaultProps;

export { Stack };
