import React from 'react';

export const ImagineIcon = ({ loading }: { loading: boolean }) => {
  if (loading) {
    return (
      <img
        style={{ minWidth: '38px', minHeight: '35px', maxWidth: '38px', maxHeight: '35px', marginLeft: '-2.25px', marginRight: 'auto' }}
        src={'https://withjoy.com/media/raw/print/stars_animated.gif'}
        alt=""
      ></img>
    );
  }

  return (
    <img
      style={{ minWidth: '38px', minHeight: '35px', maxWidth: '38px', maxHeight: '35px', marginLeft: '-2.25px', marginRight: 'auto' }}
      src={'https://withjoy.com/media/raw/print/stars_static.gif'}
      alt=""
    ></img>
  );
};
