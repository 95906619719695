import React, { useMemo } from 'react';
import { EcardContainer, EcardWrapper } from './Ecard.styles';
import globalWindow from '@shared/core/globals';
import { useRouterHelper } from '@shared/core';
import { getEcardTypeDisplayName } from './Ecard.utils';
import { EcardType } from '@graphql/generated';
import Header from '@shared/components/EmailsAndEcards/components/Header';
interface EcardProps
  extends Readonly<{
    ecardDraftType: EcardType;
  }> {}

export const Ecard: React.FC<EcardProps> = ({ children, ecardDraftType }) => {
  if (globalWindow.Intercom) {
    globalWindow.Intercom('update', { hide_default_launcher: true });
  }
  const routerHelper = useRouterHelper();

  const onBackClick = () => {
    routerHelper.goToAdminRoute(ecardDraftType);
  };

  const ecardTypeDisplayName = useMemo(() => getEcardTypeDisplayName(ecardDraftType), [ecardDraftType]);

  return (
    <EcardContainer alignItems="flex-start" justifyContent="center">
      <Header callback={onBackClick} title={ecardTypeDisplayName} toDashboard={false} />
      <EcardWrapper>{children}</EcardWrapper>
    </EcardContainer>
  );
};
