import { useState, useEffect, useRef, MutableRefObject } from 'react';

const useInView = (): [MutableRefObject<null | HTMLDivElement>, boolean] => {
  const [isInView, setIsInView] = useState(false);
  // Required to keep a copy of ref value for cleanup.
  const savedObserverRef = useRef<null | HTMLDivElement>(null);
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
      if (!savedObserverRef.current) {
        savedObserverRef.current = ref.current;
      }
    }

    return () => {
      if (savedObserverRef.current) {
        observer.unobserve(savedObserverRef.current);
      }
    };
  }, []);

  return [ref, isInView];
};

export default useInView;
