/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Rsvp Admin',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path d="M14.706 9.134a.6.6 0 1 0-.911-.781l-2.578 3.008-1.042-1.042a.6.6 0 1 0-.849.849l1.5 1.5a.6.6 0 0 0 .88-.034l3-3.5z" />
          <path
            fillRule="evenodd"
            d="M13.172 3.285a2.6 2.6 0 0 0-3.309.095L3.788 8.695a2.6 2.6 0 0 0-.888 1.957v9.012a1.6 1.6 0 0 0 1.6 1.6h15a1.6 1.6 0 0 0 1.6-1.6v-7l-.005-1.947a2.6 2.6 0 0 0-1.003-2.046l-6.921-5.387zm-2.519.998a1.4 1.4 0 0 1 1.782-.051l6.921 5.387a1.4 1.4 0 0 1 .54 1.102l.004 1.696-4.399 4.399-1.662-1.662a2.6 2.6 0 0 0-3.677 0L8.5 16.815l-4.4-4.4v-1.764a1.4 1.4 0 0 1 .478-1.054l6.074-5.315zm5.696 13.381l3.551-3.551v5.551a.4.4 0 0 1-.4.4h-.751l-2.4-2.4zm.703 2.4H6.949l4.062-4.061a1.4 1.4 0 0 1 1.98 0l4.062 4.061zm-9.4-2.4l-2.4 2.4H4.5a.4.4 0 0 1-.4-.4v-5.551l3.551 3.551z"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path d="M10.135 14.032a.6.6 0 0 1 .846-.069l.758.644 1.197-1.814a.6.6 0 0 1 1.002.661l-1.614 2.438a.6.6 0 0 1-.427.211c-.124.007-.251-.025-.362-.098l-1.332-1.127a.6.6 0 0 1-.069-.846zm5.636.127a.6.6 0 0 1 .6-.6h5.168a.6.6 0 0 1 0 1.2h-5.168a.6.6 0 0 1-.6-.6zm0 7.89a.6.6 0 0 1 .6-.6h5.168a.6.6 0 1 1 0 1.2h-5.168a.6.6 0 0 1-.6-.6zm-1.913-1.842a.6.6 0 0 1 0 .849l-.994.994.992.992a.6.6 0 0 1-.848.849l-.992-.992-.994.994a.6.6 0 1 1-.849-.848l.994-.994-.992-.992a.6.6 0 1 1 .848-.849l.992.992.994-.994a.6.6 0 0 1 .848 0z" />{' '}
          <path
            fillRule="evenodd"
            d="M15.999 1.813c-1.499 0-2.715 1.215-2.715 2.715H7.333c-.368 0-.667.298-.667.667v23.333c0 .368.298.667.667.667h17.333c.368 0 .667-.299.667-.667V5.194c0-.368-.299-.667-.667-.667h-5.952c0-1.499-1.215-2.715-2.715-2.715zM7.866 5.727h1.467v2.711c0 .368.298.667.667.667h12c.368 0 .667-.298.667-.667V5.727h1.467v22.267H7.866V5.727zm9.648-1.201c0-.837-.678-1.515-1.515-1.515s-1.515.678-1.515 1.515a1.2 1.2 0 0 1-1.2 1.2h-2.752v2.177h10.933V5.726h-2.752a1.2 1.2 0 0 1-1.2-1.2z"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M28 7.25h4.75v2.5h-17.5v-2.5H20c1.303 0 2.074-1.04 2.346-1.823a1.75 1.75 0 0 1 3.309 0C25.926 6.21 26.697 7.25 28 7.25zm-1.164-2.232C27.016 5.54 27.448 6 28 6h9a1 1 0 0 1 1 1v35a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h9c.552 0 .984-.46 1.164-.982a3 3 0 0 1 5.671 0zM14 7.25h-2.75v34.5h25.5V7.25H34V10a1 1 0 0 1-1 1H15a1 1 0 0 1-1-1V7.25zm3.442 15.192l4-4c.244-.244.244-.64 0-.884s-.64-.244-.884 0L17 21.116l-1.558-1.558c-.244-.244-.64-.244-.884 0s-.244.64 0 .884l2 2c.244.244.64.244.884 0zM24 20.375c0-.345.28-.625.625-.625h8.75c.345 0 .625.28.625.625s-.28.625-.625.625h-8.75c-.345 0-.625-.28-.625-.625zm.625 10.375c-.345 0-.625.28-.625.625s.28.625.625.625h8.75c.345 0 .625-.28.625-.625s-.28-.625-.625-.625h-8.75zm-4.562-1.637c.244.244.244.64 0 .884l-1.679 1.679 1.503 1.503c.244.244.244.64 0 .884s-.64.244-.884 0L17.5 32.561l-1.503 1.503c-.244.244-.64.244-.884 0s-.244-.64 0-.884l1.503-1.503-1.679-1.679c-.244-.244-.244-.64 0-.884s.64-.244.884 0l1.679 1.679 1.679-1.679c.244-.244.64-.244.884 0z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
