import React from 'react';
import { generateComponentDisplayName } from '../../../../utils';
import { DialogBodyContainer } from './styles';

export interface DialogBodyProps {
  children: React.ReactNode;
}

// const DEFAULT_PROPS: Required<Pick<DialogBodyProps, 'isCloseButtonShown'>> = {
//   isCloseButtonShown: true
// };

export const DialogBody: React.FC<DialogBodyProps> = props => {
  const { children } = props;
  return (
    <DialogBodyContainer paddingY={8} paddingX={[6, null, 8]}>
      {children}
    </DialogBodyContainer>
  );
};

DialogBody.displayName = generateComponentDisplayName(`Dialog.Body`);
