import React from 'react';
import { StyledNavWrapper, StyledNavList, StyledNavLink, StyledNextButton } from './Nav.styles';
import { useNavController } from './Nav.controller';
import { PostOfficeRoutePaths } from '../../PostOffice.route.paths';
import { PostOfficeMessageType } from '@graphql/generated';

interface NavProps
  extends Readonly<{
    eventHandle: string;
    routes: PostOfficeRoutePaths;
    draftType: PostOfficeMessageType;
    fromDashboard?: string;
    isThankYouMessageLoading: boolean;
  }> {}

export const Nav: React.FC<NavProps> = ({ eventHandle, routes, draftType, fromDashboard, isThankYouMessageLoading }) => {
  const { tabItems, postOfficeRouteMatch, showPostOfficeNavBar, goToNextPath } = useNavController({ routes, draftType, eventHandle, fromDashboard });
  if (!showPostOfficeNavBar) return null;
  const isDisabled = draftType === 'thankyou' && isThankYouMessageLoading;

  return (
    <StyledNavWrapper>
      <StyledNavList>
        {tabItems.map(({ label, nextPath, ...tabProps }) => {
          const isActive = postOfficeRouteMatch?.params.page === tabProps.to?.pathname;
          return (
            <li key={label}>
              <StyledNavLink {...tabProps} className={isActive ? 'active' : ''} disabled={isDisabled}>
                {label}
              </StyledNavLink>
              {isActive && !!nextPath && (
                <StyledNextButton variant="solid" intent="primary" onClick={() => goToNextPath(nextPath)} disabled={isDisabled}>
                  {'Next'}
                </StyledNextButton>
              )}
            </li>
          );
        })}
      </StyledNavList>
    </StyledNavWrapper>
  );
};
