import { ButtonV2, styled } from '@withjoy/joykit';

export const CarouselButton = styled(ButtonV2)<{ showing?: boolean }>`
  height: 80px;
  animation-name: ${props => (props.showing ? 'fade-in' : 'fade-out')};
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  padding: 0px 16px 0px 16px;

  @keyframes fade-in {
    0% {
      max-height: 0px;
      opacity: 0;
    }

    50% {
      max-height: 3rem;
      opacity: 0;
    }

    100% {
      max-height: 3rem;
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      max-height: 3rem;
      opacity: 1;
    }

    50% {
      max-height: 3rem;
      opacity: 0;
    }

    100% {
      max-height: 0px;
      opacity: 0;
    }
  }
`;
