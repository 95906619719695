import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const category = 'wedding';

type ECardViewedTrackArgs = {
  eventId: string;
  website: string;
  ecardType: string;
};

export const ecardGuestTelemetry = createTelemetryObject({
  actions: {},
  pages: {
    ECardViewedTrack: (args: ECardViewedTrackArgs) => ({
      category,
      pagePrefix: 'guest',
      page: 'ECardGuestPage',
      action: 'EcardViewed',
      extraPageInfo: {
        eventId: args.eventId,
        website: args.website,
        ecardType: args.ecardType
      }
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(ecardGuestTelemetry, { eventId: '' });

const useEcardGuestTelemetry = useTelemetry;

export { TelemetryProvider, useEcardGuestTelemetry };
