import React from 'react';
import { CardsRoutesProvider } from '@apps/card/Card.routes';
import { CardCategoryProvider } from '@apps/card/routes/Dashboard/components/CardCategoryProvider';
import { Redirect, Route, RoutesProvider, Switch } from '@shared/core';
import { Gallery } from './routes/Gallery';
import ImagineYourCard from '@apps/imagineYourCard';
import { Helmet } from 'react-helmet-async';
import { AICardRouter } from '@apps/imagineYourCard/router';
import { FullScreenLogin } from './components/login/FullScreenLogin';
import { PublicCardShopTelemetryProvider } from './PublicShop.telemetry';

/**
 * TODO:
 * - Consolidate required providers into one wrapper component
 */
export const PublicShop = () => {
  return (
    <>
      <Helmet>
        {/* Hiiding this route from search engines until full public launch */}
        <meta name="robots" content="noindex" />
      </Helmet>
      <PublicCardShopTelemetryProvider>
        <AICardRouter>
          <RoutesProvider>
            <CardsRoutesProvider>
              <CardCategoryProvider>
                <Switch>
                  <Route path="/cards/:occasion/:category/imagine" render={() => <ImagineYourCard />} />
                  <Route path="/cards" render={() => <Gallery eventId={''} />} />
                  <Redirect to="/cards" />
                </Switch>
                <FullScreenLogin />
              </CardCategoryProvider>
            </CardsRoutesProvider>
          </RoutesProvider>
        </AICardRouter>
      </PublicCardShopTelemetryProvider>
    </>
  );
};
