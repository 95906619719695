import { SaveTheDateDesignInputFields } from '@apps/saveTheDate/SaveTheDate.types';
import { FormikProps } from 'formik';
import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { useEffect } from 'react';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { getThemeInfo, getThemeInfoByThemeId } from '@apps/saveTheDate/SaveTheDate.utils';
import { PhotoEcardTheme } from '@apps/saveTheDate/constants';

interface UseUpdatePhotoEcardDesignFromQueryParamArgs {
  saveTheDateFormik: FormikProps<SaveTheDateDesignInputFields>;
}
export const useUpdatePhotoEcardDesignFromQueryParam = (args: UseUpdatePhotoEcardDesignFromQueryParamArgs) => {
  const { saveTheDateFormik } = args;

  const [themeIdQueryParam, setThemeQueryParam] = useManagedSearchParam('theme');
  const [saveQueryParam, setSaveQueryParamValue] = useManagedSearchParam('save');

  const setThemeSelected = useEventCallback((theme: PhotoEcardTheme) => {
    saveTheDateFormik.setFieldValue('themeSelected', theme.id);

    const currentThemeInfo = getThemeInfo(saveTheDateFormik.values.themeSelected);

    if (currentThemeInfo?.containerSize.width !== theme.containerSize.width || currentThemeInfo?.containerSize.height !== theme.containerSize.height) {
      // Reset photo position if the container size changes
      // Taken from https://github.com/joylifeinc/joy-web/blob/develop/src/apps/saveTheDate/components/EditCardDesignForm/EditCardDesignForm.tsx#L33
      saveTheDateFormik.setFieldValue('photoX', null);
      saveTheDateFormik.setFieldValue('photoY', null);
      saveTheDateFormik.setFieldValue('photoScale', 1);
    }

    if (saveQueryParam === 'true') {
      saveTheDateFormik.submitForm();
    }
  });

  const handleThemeIdQueryParam = useEventCallback(() => {
    if (themeIdQueryParam) {
      const theme = getThemeInfoByThemeId(themeIdQueryParam);

      if (theme) {
        setThemeSelected(theme);
      }
    }

    setThemeQueryParam(null);
    setSaveQueryParamValue(null);
  });

  useEffect(() => {
    handleThemeIdQueryParam();
  }, [handleThemeIdQueryParam]);
};
