import React, { LegacyRef } from 'react';
import { OptionType, SelectV1 } from '@withjoy/joykit';
import { useEffect, useMemo, useState } from 'react';
import { withWindow } from '@shared/utils/withWindow';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { CloseIcon } from '@shared/components/LocationInput/LocationInput.styles';
import { debounce } from 'lodash-es';
import { HotelProduct, MapMarker } from '@withjoy/joykit/icons';
import { FormikProps } from 'formik';
import { getPlaceInfoBasic } from '@shared/core/places';
export function useDebouncedValue<T>(wait: number = 500) {
  const [value, setValue] = useState<T>();

  const throttleFn = useMemo(() => debounce(setValue, wait), [wait]);

  return {
    setValue: throttleFn,
    value: value
  };
}

export interface Location {
  place: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  detailedPlace?: string;
  country?: string;
  placeType?: string[];
  address2?: string;
  stateRegionProvince?: string;
  city?: string;
  postalCode?: string;
}

export type LocationInputProps<T> = Readonly<{
  allowCustomPlace?: boolean;
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  defaultValue?: string;
  formik?: FormikProps<T>;
  handleChange?: (val: string) => void;
  iconSize?: number;
  includeCloseIcon?: boolean;
  includeHotelIcon?: boolean;
  includeMapIcon?: boolean;
  name: string;
  noBorder?: boolean;
  noOptionsMessage?: string;
  onLocationChange?: (val: Location | null) => void;
  onLocationOpen?: () => void;
  onInputChange?: (val: string) => void;
  placeholder?: string;
  placePredictionTypes?: string[];
  refInput?: LegacyRef<SelectV1<OptionType>>;
  telemetryPlacesSearched?: (input: string, placeResults: string[]) => void;
  telemetryPlaceSelected?: (input: string, placeSelected: string, locationAutocompleteRequestsMade: number) => void;
  value?: Location | null;
  loading?: boolean;
}>;

const placeToOption = (place: google.maps.places.AutocompletePrediction): OptionType => ({ label: place.description, value: place.place_id });

export const useLocationInput = <T extends {}>({
  onLocationChange,
  value,
  onLocationOpen,
  includeCloseIcon = true,
  includeMapIcon = false,
  includeHotelIcon = false,
  placeholder,
  placePredictionTypes = ['(cities)'],
  formik,
  defaultValue,
  allowCustomPlace = true,
  componentRestrictions = undefined,
  telemetryPlacesSearched = undefined,
  telemetryPlaceSelected = undefined,
  iconSize,
  onInputChange
}: LocationInputProps<T>) => {
  const autocompleteService = useMemo(() => withWindow(() => new window.google.maps.places.AutocompleteService(), undefined), []);
  const telemetry = useCreateWeddingTelemetry();

  const [selectOptions, setSelectOptions] = useState<OptionType[]>([]);
  const [autocompleteQueriesMade, setAutocompleteQueriesMade] = useState<number>(0);
  const { value: debouncedValue, setValue } = useDebouncedValue<string>();
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? '');
  const iconRight =
    value && includeCloseIcon ? (
      <CloseIcon
        size="sm"
        onClick={() => {
          setInputValue('');
          onLocationChange?.(null);
          setSelectOptions([]);
        }}
      />
    ) : (
      // replace fallback search icon with empty fragment
      <></>
    );

  useEffect(() => {
    setInputValue(defaultValue ?? '');
    setValue(defaultValue);
  }, [defaultValue, setValue]);

  const iconLeft = includeHotelIcon ? <HotelProduct size={iconSize || 'md'} /> : includeMapIcon ? <MapMarker size={iconSize || 'md'} /> : undefined;

  useEffect(() => {
    if (!autocompleteService || !debouncedValue || debouncedValue.length < 3) {
      return;
    }

    const placePredictionsParams: google.maps.places.AutocompletionRequest = {
      input: debouncedValue,
      types: placePredictionTypes
    };

    if (componentRestrictions) {
      placePredictionsParams.componentRestrictions = componentRestrictions;
    }

    autocompleteService.getPlacePredictions(placePredictionsParams, (places, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        telemetry.trackError('LocationAutocompleteFailed', status);
        setSelectOptions([]);
        return;
      }
      if (telemetryPlacesSearched) {
        telemetryPlacesSearched(debouncedValue, places?.map(place => place.description) || []);
      } else {
        telemetry.placesSearched(debouncedValue, places?.map(place => place.description) || []);
      }
      setSelectOptions(places?.map(placeToOption) || []);
    });
    setAutocompleteQueriesMade(prev => prev + 1);
  }, [debouncedValue, autocompleteService]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputValue = (value: string) => {
    formik?.setFieldValue('locationValue', value);
    setValue(value);
    setInputValue(value);
    onInputChange?.(value);
  };

  const handleChange = (option: OptionType | null): void => {
    if (!option) {
      setInputValue('');
      onLocationChange?.(null);
      return;
    }

    const { value, label } = option;

    const isPredictedPlace = selectOptions.some(v => v.value === value);

    if (!isPredictedPlace) {
      setInputValue(value);
      onLocationChange?.({ place: value });
      if (telemetryPlaceSelected) {
        telemetryPlaceSelected(debouncedValue || '', '', autocompleteQueriesMade);
      } else {
        telemetry.placeSelected(debouncedValue || '', '', autocompleteQueriesMade);
      }
      return;
    }

    getPlaceInfoBasic(value).then(place => {
      if (!place) {
        telemetry.trackError('LocationDetailsFailed', 'No place found');
        return;
      }
      const lat = place.location.latitude;
      const lng = place.location.longitude;
      const countryComponent = place.addressComponents.find(component => component.types.includes('country'));
      const address2Component =
        place?.addressComponents?.find(component => component.types.includes('administrative_area_level_2')) ||
        place?.addressComponents?.find(component => component.types.includes('administrative_area_level_3'));
      const stateRegionProvinceComponent = place?.addressComponents?.find(component => component.types.includes('administrative_area_level_1'));
      const postalCodeComponent = place?.addressComponents?.find(component => component.types.includes('postal_code'));
      const localityComponent = place?.addressComponents?.find(component => component.types.includes('locality'));

      if (telemetryPlaceSelected) {
        telemetryPlaceSelected(debouncedValue || '', place.formattedAddress || '', autocompleteQueriesMade);
      } else {
        telemetry.placeSelected(debouncedValue || '', place.formattedAddress || '', autocompleteQueriesMade);
      }

      onLocationChange?.({
        place: place.formattedAddress,
        lat,
        lng,
        placeId: place.id,
        detailedPlace: label,
        country: countryComponent?.longText ?? '',
        placeType: place.types,
        address2: address2Component?.longText ?? '',
        stateRegionProvince: stateRegionProvinceComponent?.longText ?? '',
        city: localityComponent?.longText ?? '',
        postalCode: postalCodeComponent?.longText ?? ''
      });
    });
  };

  return {
    options: [...(allowCustomPlace && inputValue ? [{ label: `Use "${inputValue}"`, value: inputValue }] : []), ...selectOptions],
    handleChange,
    handleInputValue,
    onLocationOpen,
    iconRight,
    iconLeft,
    inputValue,
    placeholder,
    placePredictionTypes
  };
};
