import { Box, styled } from '@withjoy/joykit';

export const TopBarWrapper = styled(Box)`
  position: sticky;
  z-index: 1;
  width: 100%;
  height: 5rem;
  padding: 1rem 2.5rem 1rem 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono3};
  gap: 1.5rem;
  ${props => props.theme.mediaQueries.down({ viewport: 'sm' })} {
    gap: 1rem;
    height: 4.5rem;
    padding: 1rem 1.5rem;
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;
