import React from 'react';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { Flex, ButtonV2, TextV2 } from '@withjoy/joykit';
import { ChevronLeftSquare } from '@withjoy/joykit/icons';
import { useMobileViewContext } from '../../MobileView.provider';
import { useHeaderLinks } from '../../../../useHeaderLinks';
import { Wrapper, styles } from './MobileBlogMenu.styles';

export const MobileBlogMenu: React.FC = () => {
  const { t2 } = useTranslation('marcom');
  const footerNav = t2('footerNav');

  const { selectedMenuType, selectedMenuIndex, onClickBack } = useMobileViewContext();
  const { itemsToRender } = useHeaderLinks(selectedMenuType);
  const blogLinks = itemsToRender[selectedMenuIndex]?.blogs;
  const parentLabel = itemsToRender[selectedMenuIndex]?.label;

  return (
    <Wrapper __css={styles.wrapper}>
      <ButtonV2
        typographyVariant="FeatureHead17"
        padding={0}
        height={pxToRem(26)}
        intent="neutral"
        variant="ghost"
        color="mono12"
        startIcon={<ChevronLeftSquare size={16} marginTop={2} />}
        justifyContent="flex-start"
        onClick={() => onClickBack()}
      >
        {footerNav.back}
      </ButtonV2>
      <TextV2 typographyVariant="HedMobile32" lineHeight={pxToRem(64)} marginBottom={3} marginTop={6}>
        {parentLabel}
      </TextV2>
      <Flex flexDirection="column" rowGap={3}>
        {blogLinks &&
          blogLinks.map((item, idx) => (
            <ButtonV2
              key={item.label + idx}
              as="a"
              href={item.href}
              target={item.target}
              onClick={item.onClick}
              typographyVariant="SectionEyebrow20"
              fontWeight={700}
              height={pxToRem(36)}
              padding={0}
              intent="neutral"
              variant="ghost"
              justifyContent="space-between"
            >
              {item.label}
            </ButtonV2>
          ))}
      </Flex>
    </Wrapper>
  );
};
