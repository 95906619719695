import { ButtonV2, styled, TextV2 } from '@withjoy/joykit';
import { pageEdgeMargin, pageEdgeMarginMobile } from '../ImagineYourCardRoot/cardConstants';

const blur = `
  * {
    transition: filter 300ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  > *.hovered {
    opacity: 100%;
    filter: blur(0px);
  }

  > *:not(.hovered) {
    opacity: 0%;
    filter: blur(5px);
  }

`;

export const OpaqueHoverButton = styled(ButtonV2)<{ mobile?: boolean }>`
  width: 80%;
  font-size: ${props => (props.mobile ? '24px' : '16px')};
  height: 48px !important;
  position: absolute;
  min-width: 131px;
  left: 0px;
  right: 0px;
  margin: auto;

  :hover {
    background: rgb(0, 0, 0);
  }

  padding: 0px 12px 0px 12px;
`;

export const PageFade = styled.div<{ dummy?: boolean }>`
  ${props => !props.dummy && 'animation: fadeIn 1500ms;'}

  width: 100%;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TransparentHoverButton = styled(OpaqueHoverButton)`
  color: var(--type-primary-mono-14, #333);
  background: rgba(251, 251, 251, 0.4);
  backdrop-filter: blur(2px);
  outline: 2px solid var(--type-primary-mono-14, #333);

  :hover {
    background: rgba(251, 251, 251, 0.65);
  }
`;

export const ButtonWrapperStyle = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  width: calc(100%);
  height: 100%;

  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${blur}
`;

export const PageSlideAnim = styled.div<{ columns: number; mobile?: boolean; useSlide?: boolean }>`
  animation: ${props => (props.useSlide ? 'slideIn' : 'fadeIn')} 1500ms;
  width: calc(100% + 32px);
  transform: translateX(-16px);
  padding-top: 16px;

  max-height: fit-content;
  overflow-y: hidden;

  @keyframes slideIn {
    0% {
      opacity: 1;
      height: 0px;
    }

    100% {
      height: calc(((100vw - ${props => (props.mobile ? pageEdgeMarginMobile : pageEdgeMargin) * 2}px) / ${props => props.columns}) / 5 * 7 + 2px);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    50% {
      height: calc(((100vw - ${props => (props.mobile ? pageEdgeMarginMobile : pageEdgeMargin) * 2}px) / ${props => props.columns}) / 5 * 7 + 2px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      height: calc(((100vw - ${props => (props.mobile ? pageEdgeMarginMobile : pageEdgeMargin) * 2}px) / ${props => props.columns}) / 5 * 7 + 2px);
    }
  }
`;

export const ContextMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;

  background-color: white;
  color: black;
  padding: 12px 0px 12px 0px;

  position: absolute;
  top: 16px;
  right: calc(16px + 48px + 16px);

  border-radius: 4px;
  box-shadow: 0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
`;

export const CMenuItem = styled(TextV2)`
  padding: 0px 12px 0px 12px;
  cursor: pointer !important;
`;

export const Divider = styled.div`
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  margin: 8px 0px 8px 0px !important;

  background: var(--mono-3-lines, #ebebeb);
`;

export const PromptWrapper = styled.div`
  z-index: 100;
  position: absolute;
  bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  filter: invert(1);
  color: rgb(95 38 155);
  mix-blend-mode: difference;

  ${blur}
`;
