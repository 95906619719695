import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { createTelemetry, createTelemetryObject } from '@shared/core';

const ACTION_CATEGORY = 'marketing.messaging.card';

export type CardDraftCreatedPageValue = 'pdpModal' | 'signupFlow';

interface CardDraftCreatedArgs {
  page: CardDraftCreatedPageValue;
  themeId: string;
  stationeryTemplateCategory: StationeryTemplateCategoryEnum;
  format: 'paper' | 'premiumDigital';
  isDigital: boolean;
  isPremiumDesign: boolean;
}

const publicShopTelemetry = createTelemetryObject({
  pages: {
    createAccount: () => ({
      category: 'PublicCardShop',
      page: 'signup',
      pagePrefix: 'marketing'
    }),
    signIn: () => ({
      category: 'PublicCardShop',
      page: 'login',
      pagePrefix: 'marketing'
    }),
    createWeddingForm: () => ({
      category: 'PublicCardShop',
      page: 'weddingDetailsForm',
      pagePrefix: 'marketing'
    }),
    forgotPassword: () => ({
      category: 'PublicCardShop',
      page: 'forgotPassword',
      pagePrefix: 'marketing'
    })
  },

  actions: {
    inputHandler: (fieldIdentifier: string, inputAction: 'onFocus' | 'onChange') => ({
      action: 'FormFieldInteracted',
      category: ACTION_CATEGORY,
      extraInfo: {
        name: fieldIdentifier,
        inputAction
      }
    }),
    createOrSignupCtaClicked: (form: 'createAccount' | 'signIn') => ({
      action: 'CreateWeddingCTAClicked',
      category: 'PublicCardShop',
      extraInfo: {
        CtaName: 'EmailPassword',
        form
      }
    }),

    cardDraftCreated: ({ page, ...extraInfo }: CardDraftCreatedArgs) => ({
      category: ACTION_CATEGORY,
      action: 'CardDraftCreated',
      page,
      extraInfo: extraInfo
    })
  }
});

const { TelemetryProvider: PublicCardShopTelemetryProvider, useTelemetry: usePublicCardShopTelemetry } = createTelemetry(publicShopTelemetry);

export { PublicCardShopTelemetryProvider, usePublicCardShopTelemetry };
