/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Profile',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M12.805 13.091A6.98 6.98 0 0 0 15 8 7 7 0 1 0 1 8a6.98 6.98 0 0 0 2.195 5.091 7.03 7.03 0 0 0 1.077.835C5.352 14.607 6.63 15 8 15s2.649-.394 3.728-1.074c.386-.243.746-.523 1.077-.835zm-.327-1.249C13.364 10.809 13.9 9.467 13.9 8A5.9 5.9 0 1 0 2.1 8c0 1.467.536 2.809 1.422 3.842a4.52 4.52 0 0 1 1.093-1.537c.405-.373.871-.672 1.376-.888-.769-.599-1.263-1.533-1.263-2.583 0-1.807 1.465-3.272 3.272-3.272s3.272 1.465 3.272 3.272c0 1.05-.494 1.984-1.263 2.583.511.219.982.523 1.39.902a4.52 4.52 0 0 1 1.079 1.523zm-.871.828a3.37 3.37 0 0 0-.957-1.546c-.697-.648-1.649-1.018-2.65-1.018-.995 0-1.943.366-2.639 1.007-.473.436-.803.975-.968 1.556C5.39 13.441 6.641 13.9 8 13.9s2.61-.459 3.607-1.231zM8 4.661c-1.2 0-2.172.973-2.172 2.172S6.8 9.005 8 9.005s2.172-.973 2.172-2.172S9.2 4.661 8 4.661z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-3.594 6.033A8.77 8.77 0 0 0 20.8 12a8.8 8.8 0 1 0-17.6 0 8.77 8.77 0 0 0 2.393 6.033c.326-.979.906-1.876 1.697-2.605.644-.593 1.4-1.052 2.221-1.359-1.206-.805-2-2.178-2-3.737A4.49 4.49 0 0 1 12 5.844a4.49 4.49 0 0 1 4.489 4.489c0 1.559-.795 2.932-2.001 3.737.829.31 1.594.775 2.242 1.377.781.726 1.353 1.615 1.677 2.586zm-.996.907c-.205-.978-.72-1.891-1.498-2.614-1.031-.959-2.437-1.504-3.913-1.504-1.467 0-2.867.539-3.896 1.488-.787.726-1.308 1.644-1.515 2.63A8.76 8.76 0 0 0 12 20.8a8.76 8.76 0 0 0 5.411-1.86zM12 7.044a3.29 3.29 0 0 0-3.289 3.289A3.29 3.29 0 0 0 12 13.622a3.29 3.29 0 0 0 3.289-3.289A3.29 3.29 0 0 0 12 7.044z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M24.53 25.811A12.97 12.97 0 0 0 29 16c0-7.18-5.82-13-13-13S3 8.82 3 16a12.97 12.97 0 0 0 4.47 9.81 13.07 13.07 0 0 0 1.186.918A12.94 12.94 0 0 0 16 29a12.94 12.94 0 0 0 7.344-2.272 13.04 13.04 0 0 0 1.185-.918zm-.228-1.425C26.462 22.247 27.8 19.28 27.8 16c0-6.517-5.283-11.8-11.8-11.8S4.2 9.483 4.2 16c0 3.28 1.338 6.247 3.498 8.386.373-1.432 1.166-2.749 2.302-3.796a8.65 8.65 0 0 1 3.18-1.853c-1.695-.977-2.835-2.807-2.835-4.903 0-3.123 2.532-5.656 5.656-5.656s5.656 2.532 5.656 5.656c0 2.097-1.141 3.926-2.835 4.903 1.192.39 2.291 1.026 3.206 1.877 1.122 1.044 1.905 2.351 2.275 3.772zm-1.034.911c-.225-1.426-.939-2.764-2.058-3.805-1.375-1.278-3.248-2.003-5.209-2.003-1.95 0-3.814.717-5.187 1.983-1.132 1.044-1.854 2.389-2.081 3.825A11.75 11.75 0 0 0 16 27.8a11.75 11.75 0 0 0 7.267-2.503zM16 9.378c-2.461 0-4.456 1.995-4.456 4.456s1.995 4.455 4.456 4.455 4.456-1.995 4.456-4.455S18.461 9.378 16 9.378z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4s20 8.954 20 20zm-7.331 13.822A18.7 18.7 0 0 0 42.75 24c0-10.355-8.395-18.75-18.75-18.75S5.25 13.645 5.25 24a18.7 18.7 0 0 0 6.08 13.822c.445-2.472 1.713-4.765 3.641-6.542 1.545-1.424 3.434-2.445 5.481-2.994-2.867-1.338-4.855-4.246-4.855-7.619 0-4.641 3.762-8.403 8.403-8.403s8.403 3.762 8.403 8.403c0 3.373-1.987 6.282-4.855 7.619 2.064.554 3.968 1.587 5.52 3.03 1.907 1.773 3.159 4.052 3.601 6.506zm-1.126.954c-.257-2.456-1.422-4.774-3.327-6.545-2.172-2.02-5.127-3.162-8.217-3.162-3.073 0-6.013 1.129-8.182 3.129-1.925 1.774-3.103 4.105-3.362 6.578A18.67 18.67 0 0 0 24 42.75a18.67 18.67 0 0 0 11.543-3.974zM24 13.514c-3.95 0-7.153 3.202-7.153 7.153S20.05 27.819 24 27.819s7.153-3.202 7.153-7.153S27.95 13.514 24 13.514z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M48.676 51.948C54.375 47.179 58 40.013 58 32 58 17.641 46.359 6 32 6S6 17.641 6 32c0 8.013 3.624 15.178 9.323 19.948.513.429 1.043.839 1.588 1.229A25.88 25.88 0 0 0 32 58a25.88 25.88 0 0 0 15.089-4.824c.545-.389 1.075-.799 1.587-1.228zm-.212-1.94C53.341 45.547 56.4 39.131 56.4 32 56.4 18.524 45.476 7.6 32 7.6S7.6 18.524 7.6 32c0 7.13 3.058 13.546 7.935 18.008.573-3.225 2.222-6.217 4.735-8.535 2.016-1.858 4.482-3.187 7.154-3.898-3.74-1.73-6.335-5.516-6.335-9.908 0-6.026 4.885-10.911 10.911-10.911s10.911 4.885 10.911 10.911c0 4.392-2.595 8.178-6.335 9.908 2.694.717 5.18 2.063 7.204 3.945 2.486 2.312 4.116 5.286 4.685 8.488zm-1.444 1.222c-.329-3.204-1.847-6.228-4.331-8.538-2.826-2.628-6.671-4.114-10.69-4.114-3.997 0-7.822 1.469-10.645 4.071-2.51 2.314-4.045 5.355-4.377 8.58C21.12 54.469 26.334 56.4 32 56.4s10.88-1.931 15.021-5.17zM32 18.355c-5.142 0-9.311 4.169-9.311 9.311s4.169 9.311 9.311 9.311 9.311-4.169 9.311-9.311-4.169-9.311-9.311-9.311z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
