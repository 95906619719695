import { styled, TextV2 } from '@withjoy/joykit';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';

export const StyledImageBackground = styled.div<{ assetImage: string; isAdjustingPosition: boolean }>`
  width: 100%;
  height: 100%;
  background-image: ${props => backgroundImageForUrl(props.assetImage)};
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: ${({ isAdjustingPosition }) => (isAdjustingPosition ? 'none' : 'initial')};
`;

export const StyledNameText = styled(TextV2)`
  font-family: inherit;
  text-transform: lowercase;
  text-align: center;
  font-weight: 300;
  line-height: 1.2;
`;

export const StyledDetailsText = styled(TextV2)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-family: inherit;
  text-transform: lowercase;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

export const StyledDivider = styled.div`
  height: 1.5px;
  width: 96px;
  background-color: ${props => props.theme.colors.white};
`;
