import { CreateWeddingForm, OnEventCreateSuccessHandler } from '@apps/createWedding/routes/CreateWeddingForm';
import { Box } from '@withjoy/joykit';
import React, { useCallback, useState } from 'react';
import { useLoginStateContext } from '../../Login';
import { useCreateDraftAndRedirectAfterEventCreation } from '../../useCreateDraftAndRedirectAfterEventCreation';
import { LoginState } from '../../types';
import { useAuthLogoutRedirectURL } from '../AccountForm/useAuthRedirectURLs';

const CreateEventForm = () => {
  const { createDraftAndRedirect } = useCreateDraftAndRedirectAfterEventCreation();
  const { changeState } = useLoginStateContext();
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);
  const logoutRedirectUrl = useAuthLogoutRedirectURL();
  const onAfterEventCreateInitiated = useCallback(() => {
    setIsCreatingEvent(true);
    changeState(LoginState.WAITING_FOR_EVENT);
  }, [changeState]);

  const handleOnEventCreateSuccess: OnEventCreateSuccessHandler = useCallback(
    data => {
      createDraftAndRedirect();
    },
    [createDraftAndRedirect]
  );
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} paddingY={6} paddingX={7}>
      <CreateWeddingForm
        disableQuestionnaire
        onEventCreateSuccess={handleOnEventCreateSuccess}
        onAfterEventCreateInitiated={onAfterEventCreateInitiated}
        skipWaitForDesignParamToSettle
        isCreateCtaLoading={isCreatingEvent}
        logoutRedirectUri={logoutRedirectUrl}
      />
    </Box>
  );
};

export { CreateEventForm };
