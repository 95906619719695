import { animationTransition } from '@shared/utils/animationTransition';
import { styled } from '@withjoy/joykit';

export const SliderContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 413px;
  height: 48px;
  bottom: 24px;
  left: 46px;
  background: white;
  box-shadow: 0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07);
  border-radius: 80px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  z-index: 2;
  transition: ${animationTransition('opacity', 'visibility')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  .slider {
    width: 100%;
    margin: 0 16px;
    transform: translateY(-3px);
  }

  .track {
    height: 4px;
    border-radius: 2px;
  }

  .track-0 {
    background-color: ${({ theme }) => theme.colors.mono10};
  }

  .track-1 {
    background-color: ${({ theme }) => theme.colors.mono5};
  }

  .thumb {
    width: 24px;
    height: 24px;
    background: white;
    box-shadow: 0px 1px 5px -1px rgba(44, 41, 37, 0.25), 0px 1px 3px -1px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    transform: translateY(-11px);
  }
`;
