import React, { Fragment, useEffect } from 'react';
import { Wrapper } from './Layout.styles';
import { Transition } from '@headlessui/react';
import { useEcardTranslations } from '@apps/ecard/Ecard.i18n';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '@apps/saveTheDate/constants';
import { useSaveTheDateDispatch, useSaveTheDateState } from '@apps/saveTheDate/state/context';
import Header from '@shared/components/EmailsAndEcards/components/Header';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { useBottomSheetState } from '@shared/components/BottomSheet';

interface LayoutProps {
  onBackClick: () => void;
}

const Layout: React.FC<LayoutProps> = ({ children, onBackClick }) => {
  const { isNavBarOpen, eventDisplayName, isQueryLoading } = useSaveTheDateState();
  const { copyShareableLink } = useSaveTheDateDispatch();
  const { setParentViewState } = useBottomSheetState();
  const { getEcardDefaultTranslations } = useEcardTranslations({ ecardDraftType: SAVE_THE_DATE_PHOTO_ECARD_TYPE });
  const translations = getEcardDefaultTranslations(eventDisplayName);

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, handleCopyShareableLink: copyShareableLink }));
  }, [setParentViewState, copyShareableLink]);

  if (isQueryLoading) {
    return <JoyLogoLoader loaderKey="layout-query" />;
  }

  return (
    <Wrapper>
      <Transition
        as={Fragment}
        appear
        show={isNavBarOpen}
        enter="transition"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Header callback={onBackClick} title={translations.ecardLanding.title} toDashboard={false} />
      </Transition>
      {children}
    </Wrapper>
  );
};

export default Layout;
