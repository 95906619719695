import React from 'react';
import { CloseButton, Divider } from '@withjoy/joykit';
import { closeButtonOverrides, CloseContainer, DialogHeader, DialogTitle } from '@apps/admin/routes/Dashboard/common/commonDialog.styles';
import { DashboardDialogHeaderProps } from './DashboardDialogHeader.types';

export const DashboardDialogHeader: React.FC<DashboardDialogHeaderProps> = ({ title, hasDivider, header, onClose }) => {
  const closeButton = <CloseButton overrides={closeButtonOverrides} onClick={onClose} />;
  const divider = hasDivider && <Divider />;

  return header ? (
    <>
      <DialogHeader customHeader={true}>
        {header}
        <CloseContainer>{closeButton}</CloseContainer>
      </DialogHeader>
      {divider}
    </>
  ) : (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        {closeButton}
      </DialogHeader>
      {divider}
    </>
  );
};
