import React, { useState } from 'react';
import { pxToRem } from '@withjoy/joykit/theme';
import { useHeaderLinks } from '../../../../useHeaderLinks';
import { ChevronRightSquare } from '@withjoy/joykit/icons';
import { ButtonV2, Flex, BoxProps } from '@withjoy/joykit';
import type { HeaderLinksProps } from '../../../../AppNavBar.types';
import { animationTransition } from '@shared/utils/animationTransition';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { StyledList, StyledListItem, StyledBlogList, styles } from './DesktopMenu.styles';

export const DesktopMenu = React.memo((props: HeaderLinksProps & BoxProps) => {
  const [selectedMenuId, setSelectedMenuId] = useState(-1);
  const isMobileOrTablet = useIsMobileOrTablet();
  const { type, getLinkProps, ...rootProps } = props;
  const { itemsToRender } = useHeaderLinks(type);
  const blogLinks = itemsToRender[selectedMenuId]?.blogs;

  return (
    <Flex
      flexDirection={['column', 'column', 'column', 'row']}
      style={{ gap: isMobileOrTablet ? 0 : pxToRem(48) }}
      paddingLeft={[6, 6, 6, 0]}
      paddingRight={6}
      {...getLinkProps?.()}
      {...rootProps}
    >
      <Flex flexDirection="column">
        <StyledList as="ul" __css={styles.list}>
          {itemsToRender.map((item, idx) => (
            <StyledListItem key={item.label} as="li" __css={styles.listItem}>
              <ButtonV2
                as="a"
                intent="neutral"
                variant="ghost"
                typographyVariant="Hed56"
                endIcon={item.blogs?.length && item.blogs.length > 0 ? <ChevronRightSquare marginTop={2} /> : null}
                href={item.href}
                target={item.target}
                onClick={item.onClick}
                onMouseEnter={event => {
                  event.preventDefault();
                  setSelectedMenuId(idx);
                }}
                textDecoration="none"
                cursor="pointer"
                fontWeight="normal"
                fontSize={pxToRem(36)}
                lineHeight={pxToRem(40)}
                height={pxToRem(40)}
                transition={animationTransition('color')}
                paddingX={0}
              >
                {item.label}
              </ButtonV2>
            </StyledListItem>
          ))}
        </StyledList>
      </Flex>
      {blogLinks && (
        <>
          <Flex flexDirection="column">
            <StyledBlogList as="ul" __css={styles.blogList}>
              {blogLinks.slice(0, 6).map(x => (
                <li key={x.label}>
                  <ButtonV2
                    as="a"
                    intent="neutral"
                    variant="ghost"
                    typographyVariant="Hed56"
                    href={x.href}
                    target={x.target}
                    onClick={x.onClick}
                    textDecoration="none"
                    cursor="pointer"
                    fontFamily="Akkurat LL"
                    fontWeight="bold"
                    fontSize={pxToRem(20)}
                    lineHeight={pxToRem(36)}
                    height={pxToRem(36)}
                    transition={animationTransition('color')}
                    paddingX={0}
                  >
                    {x.label}
                  </ButtonV2>
                </li>
              ))}
            </StyledBlogList>
          </Flex>
          <Flex flexDirection="column">
            <StyledBlogList as="ul" __css={styles.blogList}>
              {blogLinks.slice(6).map(x => (
                <li key={x.label}>
                  <ButtonV2
                    as="a"
                    intent="neutral"
                    variant="ghost"
                    typographyVariant="Hed56"
                    href={x.href}
                    target={x.target}
                    onClick={x.onClick}
                    textDecoration="none"
                    cursor="pointer"
                    fontFamily="Akkurat LL"
                    fontWeight="bold"
                    fontSize={pxToRem(20)}
                    lineHeight={pxToRem(36)}
                    height={pxToRem(36)}
                    transition={animationTransition('color')}
                    paddingX={0}
                  >
                    {x.label}
                  </ButtonV2>
                </li>
              ))}
            </StyledBlogList>
          </Flex>
        </>
      )}
    </Flex>
  );
});
