import { Box, ButtonV2, Flex, TextV2, TooltipV2 } from '@withjoy/joykit';
import { Info, AddToCalendar } from '@withjoy/joykit/icons';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import React from 'react';
import { StyledEmailPhoto } from './EcardEmail.styles';
import { addRendition } from '@shared/utils/photoRendition';
import { SaveTheDateEmailInputFields } from '@apps/saveTheDate/SaveTheDate.types';
import { FormikProps } from 'formik';
import { useTranslation } from '@shared/core';

interface Props
  extends Readonly<{
    formik: FormikProps<SaveTheDateEmailInputFields>;
    eventDate?: string;
  }> {}

export const EcardEmail: React.FC<Props> = ({ formik, eventDate }) => {
  const { headline, subheadline, salutation, message, buttonText, imageUrl, includeEventDate, includeAddToCalendarLink, includeGuestNames } = formik.values;
  const { t } = useTranslation('emailsAndEcards');
  const tEcardGuestToolTip = t('ecardGuest', 'toolTip');

  return (
    <>
      {imageUrl && <StyledEmailPhoto src={addRendition({ url: imageUrl, renditionSize: 'medium' })} alt="email photo" />}
      <Flex padding="40px 40px 0" flexDirection="column" alignItems="center">
        <Box padding="0px" textAlign="center" color="brandWarmGray7" fontFamily={EmailFontFamilies.playfairDisplay} fontSize={36} lineHeight="43.2px">
          {headline}
        </Box>
        {subheadline ? (
          <TextV2 color="brandWarmGray7" textAlign="center" fontSize={24} lineHeight="28.8px" fontFamily={EmailFontFamilies.playfairDisplay} padding="16px 0 0">
            {subheadline}
          </TextV2>
        ) : null}
        {eventDate && includeEventDate ? (
          <Box as="p" color="brandWarmGray7" textTransform="uppercase" fontSize={13} fontFamily={EmailFontFamilies.ibmPlexSans} fontWeight={600} padding="32px 0 0">
            {eventDate}
          </Box>
        ) : null}
      </Flex>
      <Box padding="32px 40px 0" display="flex" alignItems="center">
        <Box fontFamily={EmailFontFamilies.ibmPlexSans} color="brandWarmGray6" fontSize="15px" lineHeight="18px">
          {salutation}
        </Box>
        {includeGuestNames ? (
          <>
            <Box fontFamily={EmailFontFamilies.ibmPlexSans} color="brandWarmGray6" fontSize="15px" lineHeight="18px" marginX={1}>
              {'[First Name(s)]'}
            </Box>
            <TooltipV2
              content={
                <Box width="256px" padding={3}>
                  <TextV2 typographyVariant="label2">
                    <b>{tEcardGuestToolTip.firstPart()}</b> <br /> {tEcardGuestToolTip.secondPart()}
                  </TextV2>
                </Box>
              }
            >
              <Info size="sm" color="brandWarmGray6" />
            </TooltipV2>
          </>
        ) : null}
        {','}
      </Box>
      {message ? (
        <Box padding="22px 40px 0">
          <Box color="brandWarmGray6" fontSize={15} lineHeight="24px" fontFamily={EmailFontFamilies.ibmPlexSans} style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </Box>
        </Box>
      ) : null}
      <Box padding="40px 48px 40px" textAlign="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <ButtonV2 variant="solid" _hover={{ backgroundColor: 'brandWarmGray7' }} backgroundColor="brandWarmGray7" color="white" onClick={() => {}}>
            {buttonText}
          </ButtonV2>
        </Box>
        {!!includeAddToCalendarLink ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <ButtonV2
              variant="solid"
              _hover={{ backgroundColor: 'brandWarmGray4' }}
              backgroundColor="white"
              color="mono14"
              border="1px solid"
              borderColor="mono14"
              margin="24px 0 0"
              onClick={() => {}}
              startIcon={<AddToCalendar size="md" />}
              paddingX={5}
            >
              Add to Calendar
            </ButtonV2>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
