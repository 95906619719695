import { ReactComponent as PlusIcon } from '@assets/icons/icon-plus.svg';
import { styled } from '@withjoy/joykit';

export const StyledPlusIcon = styled(PlusIcon)`
  & path {
    fill: #fff;
  }
`;

export const StyledDialogHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledDialogContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  ${props => props.theme.mediaQueries.down({ viewport: 'sm' })} {
    gap: 1rem;
    flex-direction: column;
  }
`;

export const StyledEventOption = styled.div`
  padding: 1.5rem;
  flex: 1;
  border-radius: 0.25rem;
  border: 1px solid ${props => props.theme.colors.mono3};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.strokeHover};
  }
  ${props => props.theme.mediaQueries.down({ viewport: 'sm' })} {
    width: 100%;
    padding: 0 1.5rem;
    min-height: 5rem;
    flex-direction: row;
  }
`;
