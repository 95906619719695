import React from 'react';
import { DialogV2 } from '@withjoy/joykit';
import { Box, ButtonV2, LinkV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

// error messages come from auth0 update user v2 api
// https://auth0.com/docs/api/management/v2#!/Users/patch_users_by_id
type SpecialErrors = 'The specified new email already exists';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    targetEmail: string;
    errorMessage?: Maybe<string>;
  }> {}

export const SavePersonInfoFailure: React.FC<Props> = props => {
  const { isOpen, onClose, targetEmail, errorMessage } = props;
  const { t } = useTranslation('account');

  const userAlreadyExistsSuggestion = t(
    'savePersonInfoFailure',
    'userAlreadyExistsSuggestion'
  )({
    targetEmail,
    userAlreadyExistsLink: () => (
      <LinkV2 href="https://help.withjoy.com/knowledge-base/transfer-event-ownership" target="__blank" allowFollow={true}>
        {t('savePersonInfoFailure', 'userAlreadyExistsLinkText')()}
      </LinkV2>
    )
  });

  const failureBody = (str: Maybe<SpecialErrors | string>) => {
    switch (str) {
      case 'The specified new email already exists' as SpecialErrors:
        return (
          <Box display="flex" flexDirection="column">
            <TextV2 typographyVariant="body1">{t('savePersonInfoFailure', 'userAlreadyExists')({ targetEmail })}</TextV2>
            <TextV2 hidden={!errorMessage} typographyVariant="body1" marginTop={6}>
              {userAlreadyExistsSuggestion}
            </TextV2>
          </Box>
        );
      default:
        return (
          <Box display="flex" flexDirection="column">
            <TextV2 typographyVariant="body1">{t('savePersonInfoFailure', 'message')()}</TextV2>
            <TextV2 hidden={!errorMessage} typographyVariant="body1" marginTop={6}>
              {errorMessage}
            </TextV2>
          </Box>
        );
    }
  };
  return (
    <DialogV2 id="SavePersonInfoFailure" onClose={onClose} isOpen={isOpen}>
      <DialogV2.Header>
        <Box fontWeight={600}>{t('savePersonInfoFailure', 'title')()}</Box>
      </DialogV2.Header>
      <DialogV2.Body>{failureBody(errorMessage)}</DialogV2.Body>
      <DialogV2.Footer>
        <ButtonV2 intent="primary" onClick={onClose}>
          {t('savePersonInfoFailure', 'confirmButton')()}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};
