import React from 'react';
import { CHOICE_INDICATOR } from '../../common';
import { styled, css, ThemeStyledProps } from '@shared/joykit/packages/core/common/styledComponents';
import { animationTransition } from '../../utils';
import { CheckboxV1Props } from '.';
import { IconV1 } from '../IconV1';

const themedStyle = (props: ThemeStyledProps<Pick<CheckboxV1Props, 'disabled'>>) => {
  if (props.disabled) {
    return css`
      color: inherit;
      background-color: #eaeaea;
    `;
  }
  return css`
    color: ${props.theme.colors.fillDark};
  `;
};

export const StyledCheckIcon = styled(IconV1)`
  transition: ${animationTransition('opacity')};
`;

export const CheckContainer = styled(({ disabled, children, currentColor, className, ...restProps }) => {
  return (
    <span {...restProps} className={className}>
      {children}
    </span>
  );
})<{ disabled?: boolean }>`
  height: 1.25rem;
  width: 1.25rem;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: relative;
  background-color: #ffffff;
  transition: ${animationTransition('border-color', 'background-color')};
  ::before {
    background-color: #ffffff;
  }
  ${themedStyle}
`;

const Input: React.FC<CheckboxV1Props> = ({ children, alignIndicator, label, defaultChecked, hideLabel, ...props }) => (
  <input aria-checked={props.checked} onChange={props.onChange} {...props} defaultChecked={defaultChecked} type={'checkbox'} />
);

export const StyledInput = styled(Input)`
  :enabled {
    :focus ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.fillDark};
    }
    :hover ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.fillDark};
    }
    :active ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.fillDark};
    }
  }

  & ~ ${CheckContainer} ${StyledCheckIcon} {
    opacity: 0;
  }

  &:checked ~ ${CheckContainer} ${StyledCheckIcon} {
    opacity: 1;
  }
`;
