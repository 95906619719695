import { withWindow } from '@shared/utils/withWindow';
import { webViewPostMessage } from '.';

/**
 *
 * @param eventId
 * @param productIntents
 */
export const dispatchWeddingCreationResultsToNativeApps = (eventId: string, productIntents: Array<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withWindow((window: any) => {
    // Ios
    webViewPostMessage({
      signature: 'JoyWebViewToNativeMessage',
      type: 'Navigate',
      data: {
        url: `${window.location.origin}/${eventId}?onboardingInterests=${productIntents.join(',')}`
      }
    });

    // Android
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.JoyNative?.weddingCreated?.(eventId);
  });
};

export const dispatchContactCollectorEditGuestListToNativeApps = () => {
  webViewPostMessage({
    signature: 'JoyWebViewToNativeMessage',
    type: 'ButtonClickEvent',
    data: {
      componentAction: 'Clicked',
      componentId: 'ContactCollectorEditGuestList',
      componentType: 'Button'
    }
  });
};

export const dispatchContactCollectorCopyLinkToNativeApps = (url: string) => {
  webViewPostMessage({
    signature: 'JoyWebViewToNativeMessage',
    type: 'ButtonClickEvent',
    data: {
      componentAction: 'Clicked',
      componentId: 'ContactCollectorCopyLink',
      componentType: 'Button',
      url: url
    }
  });
};
