import { useRef, useEffect } from 'react';

export function useOuterClick(callback: (event: MouseEvent | TouchEvent) => void, active: boolean = true) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const innerRef = useRef<any>(null);
  const callbackRef = useRef<(event: MouseEvent | TouchEvent) => void>();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    // read most recent callback and innerRef dom node from refs
    function handleClick(e: MouseEvent | TouchEvent) {
      // console.log(e, innerRef.current, e.target, !innerRef?.current?.contains(e.target as Node));
      if (innerRef.current && callbackRef.current && e.target && !innerRef.current.contains(e.target as Node)) {
        callbackRef.current(e);
      }
    }
    if (active) {
      window.document.addEventListener('touchstart', handleClick);
      window.document.addEventListener('mousedown', handleClick);
      return () => {
        window.document.removeEventListener('touchstart', handleClick);
        window.document.removeEventListener('mousedown', handleClick);
      };
    }
    return;
  }, [active, innerRef]); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
}
