import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { EmailInputFields, EmailType } from '@shared/components/EmailsAndEcards/Emails.types';
import { StyledEditEmailButton, Wrapper } from './EmailPreview.styles';
import { ReactComponent as EditEmailSvg } from '@assets/icons/edit-email.svg';
import EmailWrapper from './components/EmailWrapper';
import { fontImportHook } from '@shared/utils/fonts/useFonts';
import { emailFonts } from '@shared/utils/fonts/emailFonts';
import { useEmailPreviewController } from './EmailPreview.controller';
import { GuestFooter } from '@shared/components/EmailsAndEcards/components/Emails/components';

const Fonts = [emailFonts.IBM_Plex_Sans, emailFonts.IBM_Plex_Mono, emailFonts.IBM_Plex_Mono_Bold, emailFonts.Playfair_Display];
const [, FontStyles] = fontImportHook(Fonts);

interface EmailPreviewProps
  extends Readonly<{
    emailType: EmailType;
    formik: FormikProps<EmailInputFields>;
    isEmailEditPanelShown: boolean;
    toggleEmailEditPanel: () => void;
    eventPassword?: Maybe<string>;
    loadingDraftData?: boolean;
    eventDate?: string;
    footerText?: string;
    isPasswordToggleShown?: boolean;
    isMessageLoading?: boolean;
    recipientName?: string;
  }> {}

export const EmailPreview: React.FC<EmailPreviewProps> = ({
  emailType,
  formik,
  isEmailEditPanelShown,
  toggleEmailEditPanel,
  eventPassword,
  loadingDraftData,
  eventDate,
  footerText,
  isPasswordToggleShown,
  isMessageLoading,
  recipientName
}) => {
  const { getEmailSpecificPreview, isBottomSheetFirstStep, isMobile } = useEmailPreviewController({
    eventPassword,
    formik,
    emailType,
    eventDate,
    isPasswordToggleShown,
    isMessageLoading
  });

  const Children = useMemo(
    () => (
      <>
        {!!FontStyles ? <FontStyles /> : null}
        {getEmailSpecificPreview()}
        <GuestFooter footerText={footerText} />
        {!isEmailEditPanelShown ? (
          <StyledEditEmailButton onClick={toggleEmailEditPanel} disabled={emailType === 'thankyou' && isMessageLoading}>
            <EditEmailSvg />
          </StyledEditEmailButton>
        ) : null}
      </>
    ),
    [emailType, footerText, getEmailSpecificPreview, isEmailEditPanelShown, isMessageLoading, toggleEmailEditPanel]
  );

  if (!!loadingDraftData) {
    return null;
  }

  return (
    <EmailWrapper
      subject={formik.values.subject}
      isEmailEditPanelShown={isEmailEditPanelShown}
      isBottomSheetFirstStep={isBottomSheetFirstStep}
      openEmailEditor={toggleEmailEditPanel}
      isMobile={isMobile}
      emailType={emailType}
      recipientName={recipientName}
    >
      {emailType !== 'ecard' && isMobile && isBottomSheetFirstStep ? <Wrapper onClick={toggleEmailEditPanel}>{Children}</Wrapper> : Children}
    </EmailWrapper>
  );
};
