import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const category = 'widget.peoplePicker';

export const selectGuestsTelemetry = createTelemetryObject({
  actions: {
    labelSelectionChange: (labelName: string, newSelection: boolean) =>
      newSelection
        ? {
            category,
            action: 'selectLabel',
            type: 'click',
            label: labelName
          }
        : {
            category,
            action: 'deselectLabel',
            type: 'click',
            label: labelName
          },
    guestSelectionChange: (newSelection: boolean) =>
      newSelection
        ? {
            category,
            action: 'selectGuest',
            type: 'click'
          }
        : {
            category,
            action: 'deselectGuest',
            type: 'click'
          },
    search: (searchTerm: string) => ({
      category,
      action: 'Search',
      label: searchTerm
    }),
    done: () => ({
      category,
      action: 'Done',
      type: 'click'
    }),
    selectAllGuests: () => ({
      category,
      action: 'selectAllGuests',
      type: 'click'
    }),
    goToGuestList: () => ({
      category,
      action: 'guestList',
      type: 'click'
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(selectGuestsTelemetry);
const useSelectGuestsTelemetry = useTelemetry;
export { TelemetryProvider, useSelectGuestsTelemetry };
