import { useCallback, useState } from 'react';

export const useResetMFA = () => {
  const [resetMFADialogOpen, setResetMFADialogOpen] = useState<boolean>(false);

  const handleDialogClose = useCallback(() => {
    setResetMFADialogOpen(false);
  }, []);

  const handleShowDialog = useCallback(() => {
    setResetMFADialogOpen(true);
  }, []);

  return {
    handleDialogClose,
    handleShowDialog,
    resetMFADialogOpen
  };
};
