import { styled } from '@withjoy/joykit';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: ${props => props.theme.space[6]};
  width: 100%;

  ${props => props.theme.mediaQueries.up({ viewport: 'md' })} {
    flex-direction: row;
  }
`;
