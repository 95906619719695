import { styled, Box, StyleSystemProps, LinkV2 } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';

export const PersonalInfoWrapper = styled(Box)``;
export const PersonalInfoWrapperStyles: StyleSystemProps = {
  maxWidth: '510px',
  margin: 'auto',
  paddingTop: '0px',
  paddingBottom: '120px',
  paddingX: {
    _zero: '24px',
    sm2: '24px',
    md3: '75px'
  }
};

export const StyledLink = styled(LinkV2)`
  ${InterFonts.caption}
`;

export const StyledCaption = styled.span`
  ${InterFonts.caption}
`;
export const StyledLabel = styled.span`
  ${InterFonts.subhead}
`;

export const StyledInlineWarningMessage = styled.div`
  background: ${({ theme }) => theme.colors.warning1};
  border: 1px solid ${({ theme }) => theme.colors.warning3};
  border-radius: ${({ theme }) => theme.radii[3]};
  padding: ${({ theme }) => theme.space[6]};
  align-items: center;
  display: flex;
`;
