import { SaveTheDateDesignInputFields, ECardInputNoEventId } from '@apps/saveTheDate/SaveTheDate.types';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '@apps/saveTheDate/constants';

export const getEcardPayload = (
  ecardInput: SaveTheDateDesignInputFields,
  displayNamesString: string,
  displayLocationString?: string | null,
  displayDateString?: string | null
): ECardInputNoEventId => {
  return {
    title: displayNamesString,
    subTitle: ecardInput.inviteToFollow,
    message: ecardInput.additionalMessage,
    linkOptionPrimary: ecardInput.additionalLink1,
    linkOptionSecondary: ecardInput.additionalLink2,
    displayDateFormat: ecardInput.dateFormat,
    displayDateString: displayDateString,
    coupleNameFormat: ecardInput.coupleNameFormat,
    locationStringFormat: ecardInput.locationStringFormat,
    locationString: displayLocationString,
    displayEventLink: ecardInput.displayEventLink,
    recipientIds: [],
    type: SAVE_THE_DATE_PHOTO_ECARD_TYPE,
    photoEcardTheme: ecardInput.themeSelected,
    photoEcardThemePositionY: ecardInput?.photoY,
    photoEcardThemePositionX: ecardInput?.photoX,
    photoEcardThemeScale: ecardInput?.photoScale
  };
};
