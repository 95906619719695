import { Box, Flex } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledImageBackground, StyledNameText, StyledDetailsText, StyledDivider } from './BigNames.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetUrl = getThemeInfo('bigNames')?.assetUrl || '';

export const BigNames: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const fontSize = isFullNamesFormat ? 44 : 72;
  const letterSpacing = '0.05em';
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage />
      <StyledImageBackground isAdjustingPosition={!!isAdjustingPosition} assetImage={addRendition({ url: assetUrl, renditionSize: 'medium' })}>
        <Flex padding="0px 30px" flexDirection="column" alignItems="center" fontSize={72} color="white" fontFamily="Spectral">
          <StyledNameText fontSize={fontSize} letterSpacing={letterSpacing} marginBottom="35px">
            {formattedOwnerName}
          </StyledNameText>
          <StyledDivider />
          <Box textAlign="center" paddingY="24px">
            {formattedDate ? <StyledDetailsText>{formattedDate}</StyledDetailsText> : null}
            {formattedLocation ? <StyledDetailsText>{formattedLocation}</StyledDetailsText> : null}
            {inviteToFollow ? <StyledDetailsText fontStyle="italic">{inviteToFollow}</StyledDetailsText> : null}
          </Box>
          <StyledDivider />
          <StyledNameText fontSize={fontSize} letterSpacing={letterSpacing} marginTop="35px">
            {formattedFianceeName}
          </StyledNameText>
        </Flex>
      </StyledImageBackground>
    </>
  );
};
