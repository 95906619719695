import { animationTransition } from '@shared/utils/animationTransition';
import { styled } from '@withjoy/joykit';
import { Box } from '../Box';
import { anatomy } from '@shared/joykit/packages/core/common/utils/anatomy';
import { createMultiPartStylesContext, MultiPartStyleConfig, PartsStyleObject } from '@shared/joykit/packages/core/common/utils/styleConfig';
import { AccordionProps } from '.';

export const StyledContainer = styled(Box)``;
export const StyledPanel = styled(Box)``;
export const StyledItem = styled(Box)``;
export const StyledIcon = styled(Box)``;
export const StyledButton = styled(Box)``;

const accordionAnatomy = anatomy('Accordion').parts('container', 'item', 'button', 'panel').extend('icon');

const accordionBaseStyles: PartsStyleObject<typeof accordionAnatomy> = {
  container: {
    position: 'relative',
    _notFirst: {
      _after: {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: 0,
        width: '100%'
      }
    }
  },
  item: {
    _notLastChild: {
      _after: {
        content: '" "',
        display: 'block',
        height: '1px',
        width: 'calc(100% - 32px)',
        backgroundColor: 'mono3',
        margin: '0 auto'
      }
    }
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    outline: 'transparent solid 2px',
    paddingY: [3],
    paddingX: [5],
    transition: animationTransition('color', 'transform', 'background-color'),
    textAlign: 'left',
    color: 'black',
    borderRadius: '28px',
    _hover: {
      backgroundColor: 'mono2'
    },
    _active: {
      backgroundColor: 'mono3'
    }
  },
  panel: {
    paddingX: 5,
    paddingTop: 3,
    paddingBottom: 5
  },
  icon: {
    transition: animationTransition('transform', 'color')
  }
};

const accordionStyles: MultiPartStyleConfig<AccordionProps, typeof accordionAnatomy> = {
  parts: accordionAnatomy.keys,
  baseStyle: accordionBaseStyles
};

const [AccordionStylesProvider, useAccordionStyles] = createMultiPartStylesContext(accordionAnatomy);

export { AccordionStylesProvider, useAccordionStyles, accordionStyles };
