import React, { useMemo, useState, useEffect } from 'react';
import { Anchor, Box, DrawerV2, Flex } from '@withjoy/joykit';
import { MenuSection, MenuSectionProps } from './MenuSection';
import { pxToRem } from '@withjoy/joykit/theme';
import { MenuItem } from './MenuItem';
import { ChevronBack } from '@withjoy/joykit/icons';
import { BrandLogoNoColor } from '@assets/icons/icons';

export type MenuListProps = {
  isOpen: boolean;
  onClose: () => void;
  items: Omit<MenuSectionProps, 'openSubmenu' | 'onClose'>[];
  DrawerHeaderContent?: React.ReactNode;
  DrawerFooterContent?: React.ReactNode;
  drawerAnchor?: Anchor;
  menuRef: React.RefObject<HTMLDivElement>;
};

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box paddingTop={pxToRem(40)} width="100%">
      <MenuItem text="Back" icon={ChevronBack} typographyVariant="hed1" fontWeight={600} onClick={onClick} iconSize="sm" columnGap={13} />
    </Box>
  );
};

const LogoHeader = () => {
  return (
    <Flex flexDirection="column" rowGap={pxToRem(24)}>
      <Flex height={pxToRem(24)} paddingLeft={pxToRem(8)}>
        <BrandLogoNoColor height="24px" width="38px" />
      </Flex>
    </Flex>
  );
};

export const MenuList: React.FC<MenuListProps> = ({ isOpen, onClose, items, DrawerFooterContent, DrawerHeaderContent, drawerAnchor = 'left' }) => {
  const [subItems, setSubItems] = useState<typeof items>([]);
  const [inMainMenu, setInMainMenu] = useState(true);

  useEffect(() => {
    isOpen && setInMainMenu(true);
  }, [isOpen]);

  const menu = useMemo(() => {
    return items.map((item, index) => (
      <MenuSection
        openSubmenu={newItems => {
          setSubItems(newItems);
          setInMainMenu(false);
        }}
        key={index}
        onClose={onClose}
        {...item}
      />
    ));
  }, [items, setSubItems, setInMainMenu, onClose]);

  const subMenu = useMemo(() => {
    return subItems.map((item, index) => (
      <MenuSection
        openSubmenu={newItems => {
          setSubItems(newItems);
        }}
        key={index}
        {...item}
        onClose={onClose}
      />
    ));
  }, [subItems, setSubItems, onClose]);

  return (
    <DrawerV2 isOpen={isOpen} onClose={onClose} anchor={drawerAnchor} overrides={{ Body: { props: { paddingTop: 0 } } }}>
      <DrawerV2.Header paddingTop={pxToRem(24)} paddingX={pxToRem(16)} paddingBottom={5}>
        <LogoHeader />
        <DrawerV2.CloseButton />
      </DrawerV2.Header>
      <DrawerV2.Body paddingY={0} paddingX={0} overflowX="hidden" overflowY="hidden">
        <Flex
          width="200%"
          height="100%"
          transition="all"
          transitionDuration="250ms"
          transform={inMainMenu ? 'translateX(0%)' : 'translateX(-50%)'}
          transitionTimingFunction="ease-out"
        >
          <Flex width="50%" flexDirection="column" overflowY="scroll">
            <Flex flexDirection="column" paddingX={pxToRem(16)} paddingBottom={pxToRem(40)}>
              {DrawerHeaderContent}
              {menu}
              {DrawerFooterContent}
            </Flex>
          </Flex>
          <Flex width="50%" flexDirection="column">
            <Flex flexDirection="column" paddingX={pxToRem(16)} paddingBottom={pxToRem(40)} overflowY="auto">
              <BackButton onClick={() => setInMainMenu(true)} />
              {subMenu}
            </Flex>
          </Flex>
        </Flex>
      </DrawerV2.Body>
    </DrawerV2>
  );
};
