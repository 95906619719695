import { useVerifyEmailController } from './VerifyEmail.controller';
import StyledTopBar from '@shared/components/StyledTopBar';
import { Box, ButtonV2, LinkV1 } from '@withjoy/joykit';
import React from 'react';
import { VerifyEmailWrapper, ContentWrapper, StyledHeroImage, Content, HeaderTitle, ContentCopy, SendingStatus } from './VerifyEmail.styles';
import { useTranslation } from '@shared/core';

import { useToggleIntercomLauncher } from '@shared/core/intercom';

interface Props extends Readonly<{}> {}

export const VerifyEmail: React.FC<Props> = props => {
  const {} = props;
  const { sending, sent, handleResendVerificationEmail, email, error, resent } = useVerifyEmailController({});
  useToggleIntercomLauncher({ show: true });

  const { t } = useTranslation('verifyEmail');
  const EmailAddress = () => <b>{email}</b>;
  return (
    <VerifyEmailWrapper>
      <StyledTopBar.TopBar>
        <LinkV1 href="/">
          <StyledTopBar.StyledJoyLogo path="/account/events" />
        </LinkV1>
      </StyledTopBar.TopBar>
      <ContentWrapper display="flex" flexDirection="column" alignItems="center">
        <Content display="flex" flexDirection="column" alignItems="center" margin="auto">
          <StyledHeroImage src={`https://withjoy.com/assets/public/paper-plane-tail.jpg`} />
          <HeaderTitle>{t('headerText')()}</HeaderTitle>
          {error ? (
            <Box display="flex" flexDirection="column" alignItems="center" margin="auto">
              <ContentCopy as="p" color={'negative6'}>
                {error.message}
              </ContentCopy>
            </Box>
          ) : sending ? (
            <SendingStatus>{t('emailSendingCopy')()}</SendingStatus>
          ) : sent ? (
            <>
              <ContentCopy>{t('emailSentExplanation')({ Email: EmailAddress })}</ContentCopy>
              {!resent ? (
                <Box display="flex" flexDirection="column" alignItems="center" margin="auto">
                  <ContentCopy>{t('emailNotFoundCopy')()}</ContentCopy>
                  <ButtonV2 intent="primary" variant="link" size="sm" onClick={handleResendVerificationEmail}>
                    {t('reSendEmailAction')()}
                  </ButtonV2>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center" margin="auto">
                  <ContentCopy>{t('emailReSentCopy')()}</ContentCopy>
                </Box>
              )}
            </>
          ) : null}
        </Content>
      </ContentWrapper>
    </VerifyEmailWrapper>
  );
};
