import { styled } from '@withjoy/joykit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mkSimplexNoise } from './SimplexNoise';

const width = '100%';
const height = '100%';

const ChampagneDiv = styled.div<{ $background: string }>`
  width: ${width};
  flex: 0 0 ${width};
  height: ${height};
  /* background: rgb(255, 255, 255); */
  background-color: ${props => props.$background};
  background-size: cover;
  overflow: hidden;
  position: relative; // layout parent
`;

const ChampagneCanvas = styled.canvas<{ $duration: number }>`
  position: absolute;
  top: 0;
  left: 0;
  @keyframes percolate {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(calc(${height} * -1));
    }
  }
  animation: percolate ${props => props.$duration}s linear infinite;
`;

export function Champagne(props: { background?: string; isLoading?: boolean; imageURL?: string; fadeIn?: boolean }) {
  const isUsingChampagne = props.isLoading !== undefined && props.imageURL && props.fadeIn !== undefined;
  return (
    <ChampagneDiv $background={'#ffffff'}>
      <ChampagneLayer count={100} duration={2} radius={0.5} fill="hsla(268, 100%, 50%, 100%)" />
      <ChampagneLayer count={75} duration={4} radius={1.0} fill="hsla(268, 100%, 50%,  75%)" />
      <ChampagneLayer count={50} duration={6} radius={2.0} fill="hsla(268, 100%, 50%,  75%)" />
      <ChampagneLayer count={25} duration={8} radius={4.0} fill="hsla(268, 100%, 50%,   25%)" />
      {isUsingChampagne && <ChampagneDissolveEffect isLoading={props.isLoading!} imageURL={props.imageURL!} fill={'#ffffff'} fadeIn={props.fadeIn!} />}
    </ChampagneDiv>
  );
}

const DISSOLVE_PRECISION = 1000;
const DISSOLVE_PITCH = 3;
const DISSOLVE_NOISE_SCALE = 0.05;
const STEP_SIZE = 5;
function ChampagneDissolveEffect(props: { isLoading: boolean; imageURL: string; fill: string; fadeIn: boolean }) {
  const ref = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const init = useRef(false);
  const dissolveCutoff = useRef(DISSOLVE_PRECISION);
  const noiseMap = useRef<Array<number>>([]);
  const [hasFinishedLoading, setHasFinishedLoading] = useState(false);

  // Mask and draw the image with our noisemap
  const draw = useCallback(() => {
    const canvas = ref.current!;
    if (canvas != undefined && imgRef.current != undefined) {
      init.current = true;
    } else {
      return;
    }

    const width = canvas.width;
    const height = canvas.height;

    // If we don't have a noise field, generate one
    if (noiseMap.current.length === 0) {
      const blocksWide = width / DISSOLVE_PITCH;
      const blocksTall = height / DISSOLVE_PITCH;
      const generator = mkSimplexNoise(Math.random);
      for (let i = 0; i < blocksWide * blocksTall; i++) {
        const x = Math.floor(i % blocksWide) * DISSOLVE_NOISE_SCALE;
        const y = Math.floor(i / blocksWide) * DISSOLVE_NOISE_SCALE;
        const myVal = ((generator.noise2D(x, y) + 1) / 2) * DISSOLVE_PRECISION;
        noiseMap.current.push(myVal);
      }
    }

    // If new dissolveCutoff will be in bounds
    if ((hasFinishedLoading && dissolveCutoff.current <= DISSOLVE_PRECISION) || (!hasFinishedLoading && dissolveCutoff.current >= 0)) {
      context!.globalCompositeOperation = 'source-over';
      context!.fillStyle = props.fill;

      // Draw mask with noise
      context!.clearRect(0, 0, width, height);
      for (let x = 0; x < width; x += DISSOLVE_PITCH) {
        for (let y = 0; y < height; y += DISSOLVE_PITCH) {
          const iX = x / DISSOLVE_PITCH;
          const iY = y / DISSOLVE_PITCH;
          const myValue = noiseMap.current[iX + iY * Math.floor(width / DISSOLVE_PITCH)];
          if (dissolveCutoff.current > myValue) {
            context!.fillRect(x, y, DISSOLVE_PITCH, DISSOLVE_PITCH);
          }
        }
      }

      // Fill mask in with our image
      context!.globalCompositeOperation = 'source-in';
      context!.drawImage(imgRef.current!, 0, 0, width, height);

      dissolveCutoff.current += hasFinishedLoading ? STEP_SIZE : -STEP_SIZE;
    }
  }, [context, hasFinishedLoading, props.fill]);

  // Set up render loop on start
  useEffect(() => {
    let animationFrameId = 0;

    if (context) {
      const render = () => {
        if (props.isLoading) {
          draw();
        }

        animationFrameId = window.requestAnimationFrame(render);
      };
      render();
    }
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [context, draw, hasFinishedLoading, props]);

  // Handle canvas resizes
  useEffect(() => {
    const canvas = ref.current;
    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      const canvas = ref.current;
      if (canvas) {
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
      }
    });

    if (canvas) {
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  // Handle image changes
  useEffect(() => {
    if (ref.current) {
      const canvas = ref.current;
      const ctx = canvas.getContext('2d');
      setContext(ctx);
      // eslint-disable-next-line compat/compat
      const image = new Image();
      image.onload = () => {
        imgRef.current = image;

        if (props.fadeIn) {
          dissolveCutoff.current = 0;
          setHasFinishedLoading(true);
        }
      };
      image.src = props.imageURL;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, props.imageURL]);

  return <canvas ref={ref} style={{ overflow: 'hidden', width: 'calc(100% + 9px * 2)', height: 'calc(100% + 9px * 2)', position: 'absolute', top: '-9px', left: '-9px' }} />;
}

function ChampagneLayer(props: { count: number; duration: number; radius: number; fill: string }) {
  const { count, duration, radius, fill } = props;
  const ref = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = ref.current!;
    const ctx = canvas.getContext('2d')!;
    ctx.fillStyle = fill;
    for (let i = 0; i < count; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      ctx.fill();
    }
  }, [count, fill, radius]);

  return <ChampagneCanvas $duration={duration} ref={ref} width={360} height={504 * 2} />;
}
