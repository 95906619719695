import { useCallback, useMemo } from 'react';

interface CachedRandom {
  deps: unknown[];
}

export const useCachedRandom = (args: CachedRandom) => {
  const { deps } = args;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const map = useMemo(() => ({} as Record<string | number, number>), deps);

  const get = useCallback(
    (key: string | number) => {
      const random = Math.random();

      if (map[key] === undefined) {
        map[key] = random;
      }

      return map[key];
    },
    [map]
  );

  return get;
};
