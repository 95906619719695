import { ComponentType } from 'react';

export enum SurveyItems {
  WEB_SITE = 'website',
  REGISTRY = 'registry',
  INVITATION_SAVE_THE_DATES = 'invitation_save_the_dates',
  GUEST_LIST_RSVP = 'guestList_rsvp'
}

export type SurveyOptions = {
  id: SurveyItems;
  labelKey: string;
  SurveyOptionIcon: ComponentType;
};

export type PageVisibility = {
  page: string;
  visibility: string;
};

export type SurveyEventDesign = {
  __typename?: 'EventDesign';
  id: string;
  websiteLayout: {
    __typename?: 'DesignLayout';
    id: string;
  };
};
