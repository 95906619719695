import React from 'react';
import { useAuth } from '@shared/components/AuthProvider';
import { useMount } from '@shared/utils/hooks/useMount';
import { config } from '@static/js/joy';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';

export const SocialLoginComponent: React.FC = () => {
  const authProvider = useAuth();
  // e.g. /account/social-sign-in?prev=/account/events
  const { prev } = useQueryParams();
  useMount(() => {
    if (prev) {
      authProvider.authorize(new URL(`${prev}`, config.clientUri).href, { enableSocialSignIn: true });
    } else {
      authProvider.authorize(new URL('/account-redirect', config.clientUri).href, { enableSocialSignIn: true });
    }
  });

  return null;
};
