import { useFeatureValue } from '@shared/core/featureFlags';

type AutoplayPayload = {
  videoPropsAutoplay: boolean;
  conciergeAutoplay: boolean;
};

const isAutoplayPayload = (value: unknown): value is AutoplayPayload => {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  if (!Object.hasOwn(value, 'videoPropsAutoplay')) {
    return false;
  }

  if (!Object.hasOwn(value, 'conciergeAutoplay')) {
    return false;
  }

  return true;
};

export const useAutoplayValues = () => {
  const { value, payload } = useFeatureValue('printGalleryVideoAutoPlay');

  let output = {
    videoPropsAutoplay: false,
    conciergeAutoplay: false
  };

  if (value === 'on' && isAutoplayPayload(payload)) {
    output = payload;
  }

  return output;
};
