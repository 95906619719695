import { Flex } from '@withjoy/joykit';
import React, { FC, useLayoutEffect, useRef } from 'react';

function calcFitScale(parentwidth: number, parentheight: number, childwidth: number, childheight: number) {
  const parentRatio = parentwidth / parentheight;
  const childRatio = childwidth / childheight;
  return parentRatio < childRatio ? parentwidth / childwidth : parentheight / childheight;
}

// Similar to page scaler. PageScaler inputs only width, and outputs height+scale.
// This one inputs width+height and outputs only scale.
export const FitScaler: FC<{ children: React.ReactNode; padding?: number }> = ({ children, padding = 0 }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const self = ref.current;
    if (!self) return;

    // No issues with Safari's "ResizeObserver loop completed with undelivered notifications."
    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      const child = self.firstElementChild as HTMLElement;
      if (!child) return;
      const scale = calcFitScale(Math.max(0, self.clientWidth - padding * 2), Math.max(0, self.clientHeight - padding * 2), child.clientWidth, child.clientHeight);
      child.style.position = 'absolute';
      child.style.scale = `${scale}`;
    });

    resizeObserver.observe(self);
    return () => resizeObserver.disconnect();
  }, [padding, ref]);

  return (
    <Flex
      ref={ref}
      position="relative"
      width="100%" // Match parent width by convention.
      height="100%" // Match parent height by convention.
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Flex>
  );
};
