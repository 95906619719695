import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { useUpdateUserMutation } from '@graphql/generated';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

interface PersonalInfoProps
  extends Readonly<{
    email: string;
    firstName: string;
    lastName: string;
  }> {}

interface Args
  extends Readonly<{
    id: string;
    profile: Readonly<{
      firstName: Maybe<string>;
      lastName: Maybe<string>;
      email: Maybe<string>;
    }>;
  }> {}

export const useUpdateUser = (args: Args) => {
  const { id, profile } = args;
  const actions = useAccountMeTelemetry();
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const { toast } = useToast();
  const { t2 } = useTranslation('account');
  const { submitToastText } = t2('personalInfoForm');
  const [updateUser, status] = useUpdateUserMutation({
    onCompleted: () => {
      toast(submitToastText, { position: 'top-center' });
      setShowErrorDialog(false);
    },
    onError: () => {
      setShowErrorDialog(true);
    }
  });
  const formik = useFormik<PersonalInfoProps>({
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      email: profile?.email || '',
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || ''
    },
    validationSchema: Yup.object<PersonalInfoProps>({
      email: Yup.string().email().required().label('Email'),
      firstName: profile?.firstName ? Yup.string().required().label('First Name') : Yup.string().label('First Name'),
      lastName: profile?.lastName ? Yup.string().required().label('Last Name') : Yup.string().label('Last Name')
    }),
    onSubmit: values => {
      actions.trackPersonInfoSaveClicked();
      if (!id) {
        return;
      }
      const payload = {
        firstName: values.firstName.length ? values.firstName : null,
        lastName: values.lastName.length ? values.lastName : null,
        email: values.email
      };
      updateUser({
        variables: {
          id,
          payload
        }
      });
    }
  });

  const handleErrorDialogClose = useCallback(() => {
    setShowErrorDialog(false);
  }, []);

  return {
    formik,
    status,
    showErrorDialog,
    handleErrorDialogClose
  };
};
