import React from 'react';
import { useCarouselContext } from '../../Carousel.controller';
import { StyledCarouselItem, styles } from './CarouselItem.styles';

export interface CarouselItemProps {
  children: React.ReactNode;
}

const CarouselItem = ({ children }: CarouselItemProps) => {
  const { itemRef, elementsPerPage, itemSpacing, enablePeekSpacing } = useCarouselContext();
  return (
    <StyledCarouselItem ref={itemRef} __css={styles.itemContainer(itemSpacing)} $itemsPerPage={elementsPerPage} $itemSpacing={enablePeekSpacing ? itemSpacing : null}>
      {children}
    </StyledCarouselItem>
  );
};

export { CarouselItem };
