import { DialogV2Props, styled, ButtonV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { buttonSharedStates } from '@shared/utils/style/buttons';

export const dialogOverrides: NonNullable<DialogV2Props['overrides']> = {
  Content: { props: { width: ['100%', pxToRem(480)] } },
  Body: { props: { paddingX: 7, paddingY: 8 } }
};

export const StyledActionButton = styled(ButtonV2)`
  ${buttonSharedStates};
`;
