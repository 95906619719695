import React, { useState, useMemo } from 'react';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import type { MarcomAppNavBarMobileProps, HeaderLinksType } from '../../AppNavBar.types';

export enum MobileMenuType {
  mainMenu = 'mainMenu',
  subMenu = 'subMenu',
  blogMenu = 'blogMenu'
}

type MobileViewContextProps = MarcomAppNavBarMobileProps & {
  isMobileNavOpen: boolean;
  selectedMenu: MobileMenuType;
  selectedMenuType?: HeaderLinksType;
  selectedMenuIndex: number;
  setMobileNavIsOpen: (isOpen: boolean) => void;
  onClickMainMenu: (type: HeaderLinksType) => void;
  onClickSubMenu: (index: number, type?: HeaderLinksType) => void;
  onClickBack: (shoulGoToMain?: boolean) => void;
};

export const MobileViewContext = React.createContext<MobileViewContextProps>({
  isMobileNavOpen: false,
  selectedMenu: MobileMenuType.mainMenu,
  selectedMenuType: undefined,
  selectedMenuIndex: -1,
  setMobileNavIsOpen: () => {
    throw Error('No MobileView Provider');
  },
  onClickMainMenu: (type: HeaderLinksType) => {
    throw Error('No MobileView Provider');
  },
  onClickSubMenu: (index: number, type?: HeaderLinksType) => {
    throw Error('No MobileView Provider');
  },
  onClickBack: (shoulGoToMain?: boolean) => {
    throw Error('No MobileView Provider');
  }
});

export function useMobileViewContext() {
  const context = React.useContext(MobileViewContext);
  if (!context) {
    throw new Error('useMobileViewContext() can only be used within a MobileViewProvider.');
  }
  return context;
}

export const MobileViewProvider: React.FC<MarcomAppNavBarMobileProps> = ({ isMobileNavOpen, setMobileNavIsOpen, children }) => {
  const [selectedMenu, setSelectedMenu] = useState(MobileMenuType.mainMenu);
  const [selectedMenuType, setSelectedMenuType] = useState<HeaderLinksType | undefined>(undefined);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);

  const onClickMainMenu = useEventCallback((type: HeaderLinksType) => {
    setSelectedMenu(MobileMenuType.subMenu);
    setSelectedMenuType(type);
  });

  const onClickSubMenu = useEventCallback((index: number, type?: HeaderLinksType) => {
    if (type) {
      setSelectedMenuType(type);
    }
    setSelectedMenu(MobileMenuType.blogMenu);
    setSelectedMenuIndex(index);
  });

  const onClickBack = useEventCallback((shoulGoToMain?: boolean) => {
    if (shoulGoToMain) {
      setSelectedMenu(MobileMenuType.mainMenu);
      setSelectedMenuType(undefined);
      setSelectedMenuIndex(-1);
    } else {
      setSelectedMenu(MobileMenuType.subMenu);
      setSelectedMenuIndex(-1);
    }
  });

  const value: MobileViewContextProps = useMemo((): MobileViewContextProps => {
    return {
      isMobileNavOpen,
      selectedMenu,
      selectedMenuType,
      selectedMenuIndex,
      setMobileNavIsOpen,
      onClickMainMenu,
      onClickSubMenu,
      onClickBack
    };
  }, [isMobileNavOpen, selectedMenu, selectedMenuType, selectedMenuIndex, setMobileNavIsOpen, onClickMainMenu, onClickSubMenu, onClickBack]);

  return <MobileViewContext.Provider value={value}>{children}</MobileViewContext.Provider>;
};
