import { useEffect } from 'react';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';
export function useWindowResizeListener(onResize: () => void) {
  const stableOnResize = useEventCallback(() => {
    onResize();
  });

  useEffect(() => {
    return withWindow(
      global => {
        global.addEventListener('resize', stableOnResize);
        return () => {
          global.removeEventListener('resize', stableOnResize);
        };
      },
      () => {}
    );
  }, [stableOnResize]);
}
