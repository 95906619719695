import { css } from '@withjoy/joykit';

const TABLET_WIDTH_MIN_PX = 769;
const MOBILE_WIDTH_MAX_PX = TABLET_WIDTH_MIN_PX - 1;

export const mediaScreenMobile = (...[first, ...interpolations]: Parameters<typeof css>): ReturnType<typeof css> => {
  return css`
    @media (max-width: ${MOBILE_WIDTH_MAX_PX}px) {
      ${css(first, ...interpolations)}
    }
  `;
};

export const mediaScreenNotMobile = (...[first, ...interpolations]: Parameters<typeof css>): ReturnType<typeof css> => {
  return css`
    @media (min-width: ${TABLET_WIDTH_MIN_PX}px) {
      ${css(first, ...interpolations)}
    }
  `;
};
