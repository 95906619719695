import { RefObject, useState } from 'react';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useWindowResizeListener } from '@apps/admin/common/VirtualScroll/hooks/useWindowResizeListener';

export const useRecalculateMenuPosition = (
  navigationMenuRef: RefObject<HTMLDivElement>,
  buttonRef: RefObject<HTMLDivElement>,
  customDefaultPosition?: { x: number; y: number }
) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const [positionMenu, setPositionMenu] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const recalculateMenuPosition = (): void => {
    if (isMobile || !navigationMenuRef?.current || !buttonRef?.current) {
      return;
    }

    const height = navigationMenuRef.current.offsetHeight;
    const width = navigationMenuRef.current.offsetWidth;
    const { x: buttonX, y: buttonY, width: buttonWidth, height: buttonHeight } = buttonRef.current.getBoundingClientRect();
    const { innerHeight: windowHeight, innerWidth: windowWidth } = window;
    const defaultXPercentage = customDefaultPosition ? customDefaultPosition.x : 0.6;
    const defaultYPercentage = customDefaultPosition ? customDefaultPosition.y : 0.9;
    const position = {
      x: buttonX + buttonWidth * defaultXPercentage,
      y: buttonY + buttonHeight * defaultYPercentage
    };
    const gap = 20;

    if (position.y + height > windowHeight) {
      position.y = windowHeight - height - gap;
    }

    if (position.x + width > windowWidth) {
      position.x = windowWidth - width - gap;
    }

    setPositionMenu(position);
  };

  useWindowResizeListener(() => {
    recalculateMenuPosition();
  });

  return { positionMenu, recalculateMenuPosition };
};
