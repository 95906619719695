import React from 'react';
import { Box } from '@withjoy/joykit';
import { StyledEcardEditorTabNavWrapper, StyledEcardEditorTabItem } from './EcardEditorSwitch.styles';
import { EcardEditorType } from '@apps/ecard/Ecard.types';
import { useTranslation } from '@shared/core';

interface Props
  extends Readonly<{
    selectedEditorType: EcardEditorType;
    handleSetSelectedEditorType: (type: EcardEditorType) => void;
  }> {}

export const EcardEditorSwitch: React.FC<Props> = ({ selectedEditorType, handleSetSelectedEditorType }) => {
  const { t2 } = useTranslation('emailsAndEcards');
  const tEcardEditorSwitch = t2('ecardEditor', 'ecardEditorSwitch');
  const onDesignTabClick = () => {
    handleSetSelectedEditorType(EcardEditorType.DESIGN);
  };

  const onContentTabClick = () => {
    handleSetSelectedEditorType(EcardEditorType.CONTENT);
  };

  return (
    <Box display="flex" justifyContent="center">
      <StyledEcardEditorTabNavWrapper>
        <StyledEcardEditorTabItem $isActive={selectedEditorType === EcardEditorType.DESIGN} onClick={onDesignTabClick}>
          {tEcardEditorSwitch.design}
        </StyledEcardEditorTabItem>
        <StyledEcardEditorTabItem $isActive={selectedEditorType === EcardEditorType.CONTENT} onClick={onContentTabClick}>
          {tEcardEditorSwitch.content}
        </StyledEcardEditorTabItem>
      </StyledEcardEditorTabNavWrapper>
    </Box>
  );
};
