import { createContext, useContext } from 'react';
import { BottomSheetStateContext } from './BottomSheet.provider';

export const BottomSheetContext = createContext<BottomSheetStateContext | undefined>(undefined);

export const useBottomSheetState = () => {
  const context = useContext(BottomSheetContext);
  if (context === undefined) {
    throw new Error('useBottomSheetState must be used within a BottomSheetContext Provider');
  }
  return context;
};
