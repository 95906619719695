import { useMemo, useEffect } from 'react';
import { useSaveTheDateGetEcardByGuidQuery, useSaveTheDateGetEcardGuestExtraDataQuery, useLogoutEventSessionMutation } from '@graphql/generated';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { COUPLE_NAME_FORMATS, DISPLAY_DATE_FORMATS, LOCATION_FORMATS } from '@apps/saveTheDate/constants';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useEcardGuestTelemetry } from '@apps/saveTheDate/guest/SaveTheDateGuest.telemetry';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '../constants/index';
import { getLocationAndDate } from '../SaveTheDate.utils';
import { useTranslation } from '@shared/core';

export const useEcardGuestController = () => {
  const { lookup } = useQueryParams();
  const { t } = useTranslation('emailsAndEcards');

  let eventInfo: { location: string; date: string; eventPassword: string } | undefined;

  if (lookup) {
    eventInfo = getLocationAndDate(lookup as string);
  }

  const { data, loading: dataLoading, error: dataError } = useSaveTheDateGetEcardByGuidQuery({
    variables: {
      lookupId: lookup as string
    },
    skip: !lookup,
    batchMode: 'fast',
    fetchPolicy: 'no-cache'
  });
  const ecardDataFromQuery = useMemo(() => data?.getEcardRecepientDisplayByLookupId, [data]);

  const {
    eventId,
    guestNames,
    coupleNameFormat,
    locationStringFormat,
    dateFormat,
    additionalLink1,
    additionalLink2,
    additionalMessage,
    displayEventLink,
    inviteToFollow,
    themeSelected,
    title,
    guidId,
    photoX,
    photoY,
    photoScale,
    eCardPhoto
  } = useMemo(() => {
    const eventId = ecardDataFromQuery?.eventId || '';
    const guestNames = ecardDataFromQuery?.householdDisplayName || '';
    const coupleNameFormat = ecardDataFromQuery?.coupleNameFormat || COUPLE_NAME_FORMATS[0];
    const locationStringFormat = ecardDataFromQuery?.locationStringFormat || LOCATION_FORMATS[1];
    const dateFormat = ecardDataFromQuery?.displayDateFormat || DISPLAY_DATE_FORMATS[1];
    const additionalLink1 = ecardDataFromQuery?.linkOptionPrimary || '';
    const additionalLink2 = ecardDataFromQuery?.linkOptionSecondary || '';
    const additionalMessage = ecardDataFromQuery?.message || '';
    const displayEventLink = !!ecardDataFromQuery?.displayEventLink;
    const inviteToFollow = ecardDataFromQuery?.subTitle || '';
    const themeSelected = ecardDataFromQuery?.photoEcardTheme || 'goldBorderCursive';
    const title = ecardDataFromQuery?.title || '';
    const guidId = ecardDataFromQuery?.guidId || '';
    const photoX = ecardDataFromQuery?.photoEcardThemePositionX || null;
    const photoY = ecardDataFromQuery?.photoEcardThemePositionY || null;
    const photoScale = ecardDataFromQuery?.photoEcardThemeScale || 1;
    const eCardPhoto = ecardDataFromQuery?.photo;

    return {
      eventId,
      guestNames,
      coupleNameFormat,
      locationStringFormat,
      dateFormat,
      additionalLink1,
      additionalLink2,
      additionalMessage,
      displayEventLink,
      inviteToFollow,
      themeSelected,
      title,
      guidId,
      photoX,
      photoY,
      photoScale,
      eCardPhoto
    };
  }, [ecardDataFromQuery]);

  const { data: ecardExtraData, loading: extraDataLoading, error: extraDataError } = useSaveTheDateGetEcardGuestExtraDataQuery({
    variables: {
      eventId: eventId as string
    },
    skip: !eventId,
    batchMode: 'fast',
    fetchPolicy: 'no-cache'
  });

  const loading = dataLoading || extraDataLoading;
  const error = !!dataError || !!extraDataError;

  const { ECardViewedTrack } = useEcardGuestTelemetry();

  useEffect(() => {
    if (!loading && !error) {
      const telemetryData = { eventId, website, ecardType: SAVE_THE_DATE_PHOTO_ECARD_TYPE };
      ECardViewedTrack.enter(telemetryData);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error]);

  const { eventPhoto, eventById, eventPassword, privacyMode, website, domainName, accentId, linkOptions, date, location, eventDisplayName } = useMemo(() => {
    const eventById = ecardExtraData?.eventById;
    const eventPassword = ecardExtraData?.eventById?.info.eventPassword;
    const privacyMode = ecardExtraData?.eventById?.settings?.privacyMode;
    const website = ecardExtraData?.eventById?.website || '';
    const domainName = ecardExtraData?.eventById?.domain?.domainName;
    const accentId = ecardExtraData?.eventById?.eventDesign?.accent || '';
    const linkOptions = ecardExtraData?.getEcardLinkOptionsByEventId;
    const date = ecardExtraData?.eventById?.info.date || eventInfo?.date || '';
    const location = ecardExtraData?.eventById?.info.location || eventInfo?.location || '';
    const eventPhoto = ecardExtraData?.eventById?.pages?.[0]?.photo;
    const eventDisplayName = ecardExtraData?.eventById?.info.eventDisplayName || '';

    return {
      eventById,
      eventPassword,
      privacyMode,
      website,
      domainName,
      accentId,
      linkOptions,
      date,
      location,
      eventPhoto,
      eventDisplayName
    };
  }, [ecardExtraData, eventInfo]);

  const photo = eCardPhoto || eventPhoto;

  const ecardData = {
    coupleNameFormat,
    locationStringFormat,
    dateFormat,
    additionalLink1,
    additionalLink2,
    additionalMessage,
    displayEventLink,
    inviteToFollow,
    themeSelected,
    photoX,
    photoY,
    photoScale,
    photoUrl: photo?.url || '',
    photoHeight: photo?.height,
    photoWidth: photo?.width
  };

  const extraData = {
    hasInitializedOnce: !!ecardDataFromQuery && !!ecardExtraData,
    eventId,
    eventHandle: website,
    eventDisplayName: title,
    eventById,
    eventPassword: eventPassword || eventInfo?.eventPassword,
    privacyMode,
    guidId,
    website,
    domainName,
    date,
    location,
    accentId,
    linkOptions
  };

  const [logoutEvent] = useLogoutEventSessionMutation({
    variables: { eventId },
    onCompleted: () => {
      performFullPageRedirect(`/${website}`);
    }
  });

  const onLogoutEventHandle = () => {
    logoutEvent();
  };

  const ogTitle = t('ecardGuest').title({ event: eventDisplayName });

  return {
    ecardData,
    extraData,
    loading,
    error,
    onLogoutEventHandle,
    guestNames,
    ogTitle
  };
};
