import React, { useEffect } from 'react';
import { ExplanationText, ImpermissibleWrapper, PageHeader, ImpermissibleContent, ExplanationAction } from './Impermissible.styles';
import { ReactComponent as OhNo } from '@assets/icons/icon-ohno.svg';
import StyledTopBar from '@shared/components/StyledTopBar';
import { LinkV1 } from '@withjoy/joykit';
import { useAccountRouteHelper } from '@apps/account/useAccountHelper';
import { useAuth } from '@shared/components/AuthProvider';
import { useQueryParamHelper } from '@shared/core/queryString';
import safeUrlToRedirect from '@shared/utils/safeUrlToRedirect';
import { config } from '@static/js/env.config';
import { useTranslation } from '@shared/core';
import { useAccountTelemetry } from '@apps/account/account.telemetry';

export const Impermissible: React.FC<{}> = props => {
  const { signUpAndGoToPath, signInAndGoToPath } = useAccountRouteHelper();
  const queryParamHelper = useQueryParamHelper();
  const { t } = useTranslation('impermissible');
  const { provideLogout } = useAuth();
  const telemetry = useAccountTelemetry();
  useEffect(() => {
    telemetry.impermissible.enter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToAuthClick = async (signIn: boolean) => {
    await provideLogout({});
    // default redirect is to the home page '/'
    const prev = queryParamHelper.getValueString('prev') || '';
    let pathname = '';
    if (safeUrlToRedirect(prev)) {
      pathname = prev;
    } else {
      pathname = new URL(prev, config.clientUri).toString();
    }
    if (signIn) {
      telemetry.goToSignIn();
      signInAndGoToPath({
        pathname: pathname || '',
        hash: '',
        search: '',
        state: ''
      });
      return;
    }
    telemetry.goToSignUp();
    signUpAndGoToPath({
      pathname: pathname || '',
      hash: '',
      search: '',
      state: ''
    });
  };

  const ExistingAccount = () => <ExplanationAction onClick={() => handleBackToAuthClick(true)}>{t('alreadyHaveAccountAction')()}</ExplanationAction>;
  const NewAccount = () => <ExplanationAction onClick={() => handleBackToAuthClick(false)}>{t('newAccountAction')()}</ExplanationAction>;

  return (
    <ImpermissibleWrapper>
      <StyledTopBar.TopBar>
        <LinkV1>
          <StyledTopBar.StyledJoyLogo />
        </LinkV1>
      </StyledTopBar.TopBar>
      <ImpermissibleContent>
        <OhNo />
        <PageHeader>{t('headerText')()}</PageHeader>
        <ExplanationText>{t('alreadyHaveAccountSuggestion')({ Action: ExistingAccount })}</ExplanationText>
        <ExplanationText>{t('newAccountSuggestion')({ Action: NewAccount })}</ExplanationText>
      </ImpermissibleContent>
    </ImpermissibleWrapper>
  );
};
