import React from 'react';

import type { CheckboxV2Props } from '.';
import { CheckboxIcon } from './CheckboxIcon';
import { checkboxStyles, visuallyHiddenStyle, StyledRoot, StyledControl, StyledInput, StyledLabel } from './Checkbox.styles';
import { useOverrides } from '@shared/utils/overrides';

import { forwardRef } from '@shared/utils/forwardRef';
import { useStyleConfig } from '@shared/joykit/packages/core/common/utils/styleConfig';
import { useCheckbox } from './useCheckbox';

const CheckboxV2 = forwardRef<'input', CheckboxV2Props>((props, ref) => {
  const { children, overrides = {}, spacing = 3, ...restProps } = props;
  const { state, getControlProps, getInputProps, getLabelProps, getRootProps } = useCheckbox(restProps);

  const {
    Root: [Root, rootProps],
    Control: [Control, controlProps],
    Icon: [Icon, iconProps],
    Input: [Input, inputProps],
    Label: [Label, labelProps]
  } = useOverrides({ Root: StyledRoot, Control: StyledControl, Input: StyledInput, Label: StyledLabel, Icon: CheckboxIcon }, overrides);

  const styles = useStyleConfig(checkboxStyles, props);

  return (
    <Root as="label" __css={styles.root} {...getRootProps(rootProps)}>
      <Input as="input" style={visuallyHiddenStyle} {...getInputProps(inputProps, ref)} />
      <Control as="span" __css={styles.control} {...getControlProps(controlProps)}>
        <Icon $isChecked={state.isChecked} $isIndeterminate={state.isIndeterminate} {...iconProps} />
      </Control>
      {children && (
        <Label as="span" __css={{ marginLeft: spacing, ...styles.label }} {...getLabelProps(labelProps)}>
          {children}
        </Label>
      )}
    </Root>
  );
});

CheckboxV2.displayName = 'CheckboxV2';

export { CheckboxV2 };
