import { useAuth } from '@shared/components/AuthProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { isAuthProviderError } from '@apps/createWedding/utils';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';
import { useViewContext } from '@apps/createWedding/components/View/ViewProvider';

type ForgotPasswordValues = {
  email: string;
};

export const useForgotPasswordController = () => {
  const auth = useAuth();
  const { changeView } = useViewContext();
  const [submissionError, setSubmissionError] = useState<string>('');
  const telemetry = useCreateWeddingTelemetry();
  const formik = useFormik<ForgotPasswordValues>({
    initialValues: { email: '' },
    validationSchema: Yup.object({ email: Yup.string().email().required() }).required(),
    onSubmit: async ({ email }) => {
      try {
        await auth.loginManager.changePassword(email);
        changeView('signIn');
      } catch (err) {
        telemetry.trackError('ForgotPassword', err);
        if (isAuthProviderError(err)) {
          setSubmissionError(err.description);
        }
      }
    }
  });

  const focusHandlers = {
    email: () => telemetry.emailClicked('forgotpassword')
  };

  return { formik, submissionError, goToSignIn: () => changeView('signIn'), goToCreateAccount: () => changeView('createAccount'), focusHandlers };
};
