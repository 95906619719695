import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

export const useFilteredData = <T>(dataArray: ReadonlyArray<T>, searchString: string, filterKeys: ReadonlyArray<string>) => {
  const [filteredArray, setFilteredArray] = useState<ReadonlyArray<T>>();
  useEffect(() => {
    if (searchString) {
      const options: Fuse.FuseOptions<T> = {
        shouldSort: false,
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 200,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: filterKeys.slice(), // fuse wants an array instead of a readonly array. not clear whether it modifies input. so just being safe here.
        includeMatches: false,
        includeScore: false
      };
      const fuse = new Fuse(dataArray, options);
      //
      setFilteredArray(fuse.search<T, typeof options>(searchString) as ReadonlyArray<T>);
    } else {
      setFilteredArray(dataArray);
    }
  }, [dataArray, searchString, filterKeys, setFilteredArray]);
  return [filteredArray];
};
