import { styled, Box, StyleSystemProps, DrawerV2Props, Flex } from '@withjoy/joykit';
import { dropdownStates } from '@apps/admin/routes/Dashboard/common/commonDasboard.styles';
import { animationTransition } from '@shared/utils/animationTransition';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledMenuContent = styled(Box)``;

const menuContentStyles: StyleSystemProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: 'black'
};

export const StyledDotDiv = styled(Flex)``;

const dotDivStyles: StyleSystemProps = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  width: pxToRem(40),
  height: pxToRem(40),
  fontSize: pxToRem(22),
  letterSpacing: 'tighter',
  transition: animationTransition('background-color', 'color', 'border-color'),
  color: 'black',
  cursor: 'pointer',
  borderRadius: 2,
  _hover: {
    backgroundColor: 'mono3',
    borderRadius: 2
  },
  _active: {
    color: 'mono13'
  },
  _pressed: {
    backgroundColor: 'mono5',
    borderRadius: 2
  }
};

export const ListDiv = styled.div<{ isOverflowOpen: boolean; isDisabled: boolean }>`
  ${({ theme }) => theme.typography.variants.body2};
  ${dropdownStates};
  align-items: center;
  background: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  display: flex;
  gap: 0.5rem;
  color: ${props => (props.isDisabled ? props.theme.colors.mono8 : 'inherit')};
  justify-content: space-between;
  min-height: 3rem;
  opacity: ${props => (props.isOverflowOpen ? 1 : 0)};
  padding: 1rem 0.5rem;
  white-space: nowrap;
  width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'all')};
  cursor: pointer;

  svg:first-child {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    ${({ theme }) => theme.typography.variants.label2};
    padding: 0.75rem 0.5rem;
  }
`;

export const ListButtonLabel = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  white-space: nowrap;
`;

export const ItemList = styled.div<{ isOpen: boolean; position: { x: number; y: number }; lastOptionNeutralStyle: boolean; isDisabled: boolean; isLastOptionDisabled: boolean }>`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  box-shadow: 0 0.4375rem 1.6875rem rgba(44, 41, 37, 0.06), 0 0.25rem 1rem rgba(0, 0, 0, 0.07);
  cursor: default;
  display: none;
  left: ${props => `${props.position.x}px`};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  padding: 0.5rem;
  position: fixed;
  top: ${props => `${props.position.y}px`};
  transform: scaleY(1);
  transition: ${animationTransition('opacity', 'transform', 'box-shadow', 'height')};
  transition-duration: 0.3s;
  transform-origin: left top;
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  z-index: ${({ theme, isOpen }) => (isOpen ? theme.zIndices.modal : 'unset')};
  max-height: calc(100vh - 7.125rem);
  width: 13.75rem;
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm2}) {
    display: block;
  }

  div:last-child {
    div:hover {
      div > svg {
        path {
          fill: ${props => (props.lastOptionNeutralStyle ? '' : props.isDisabled || props.isLastOptionDisabled ? props.theme.colors.negative3 : props.theme.colors.negative6)};
        }
      }
    }
    div > div {
      color: ${props => (props.lastOptionNeutralStyle ? '' : props.isDisabled || props.isLastOptionDisabled ? props.theme.colors.negative3 : props.theme.colors.negative6)};
    }
  }
`;

export const DialogBody = styled.div<{ isDisabled: boolean; isLastOptionDisabled: boolean }>`
  padding: 2rem;
  div:last-child {
    div:hover {
      div > svg {
        path {
          fill: ${props => (props.isDisabled || props.isLastOptionDisabled ? props.theme.colors.negative3 : props.theme.colors.negative6)};
        }
      }
    }
    div > div {
      color: ${props => (props.isDisabled || props.isLastOptionDisabled ? props.theme.colors.negative3 : props.theme.colors.negative6)};
    }
  }
`;

export const drawerOverrides: NonNullable<DrawerV2Props['overrides']> = {
  Root: {
    props: {
      background: 'rgb(0 0 0 / 0.3)'
    }
  },
  Content: {
    props: {
      height: 'unset',
      borderRadius: '12px 12px 0 0',
      width: '100vw',
      padding: '8px 0',
      maxHeight: 'calc(100vh - 9.5rem)',
      overflow: 'auto'
    }
  }
};

export const styles = {
  menuContent: menuContentStyles,
  dotDiv: dotDivStyles
};
