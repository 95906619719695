import React from 'react';
import { ReactComponent as CheckLarge } from '@assets/icons/checklist-large.svg';
import { MainText } from './VerifyEmailSuccess.styles';
import { DialogV2 } from '@withjoy/joykit';
import { Box } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
  }> {}

export const VerifyEmailSuccess: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation('account');
  return (
    <DialogV2 id="VerifyEmailSuccess" onClose={onClose} isOpen={isOpen}>
      <DialogV2.CloseButton onClick={onClose} />
      <DialogV2.Body>
        <Box alignItems="center" display="flex" flexDirection="column" paddingY={8}>
          <Box height="72px" width="72px">
            <CheckLarge />
          </Box>
          <MainText>{t('verifyEmailSuccess', 'message')()}</MainText>
        </Box>
      </DialogV2.Body>
    </DialogV2>
  );
};
