import { useDisclosure } from '@withjoy/joykit';
import { useCallback, useState } from 'react';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { PRODUCT_OFFERINGS } from '../ConciergeServiceSection/ConciergeServiceSection.config';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { ProductOffering } from '../ConciergeServiceSection/ConciergeServiceSection.types';

export const useConciergeDisclosure = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentCategory } = useCardCategoryContext();
  const [selectedOffering, setSelectedOffering] = useState<{ selectedOfferingSortIndex: number | undefined; selectedOffering: ProductOffering | undefined }>({
    selectedOfferingSortIndex: undefined,
    selectedOffering: undefined
  });

  const handleOnTileClick = useEventCallback((id: string) => {
    setSelectedOffering(getSelectedOffering(id, currentCategory));
    onOpen();
  });

  const getSelectedOffering = useCallback((id: string, category: StationeryTemplateCategoryEnum) => {
    const sortIndex = PRODUCT_OFFERINGS[category].findIndex(offering => offering.id === id);
    return {
      selectedOfferingSortIndex: sortIndex,
      selectedOffering: PRODUCT_OFFERINGS[category][sortIndex]
    };
  }, []);

  return { isOpen, onOpen, onClose, handleOnTileClick, selectedOffering };
};
