import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NameFormat, SaveTheDateDesignInputFields, SaveTheDateExtraData } from '@apps/saveTheDate/SaveTheDate.types';
import {
  AmpersandTop,
  GoldBorderCursive,
  BigNames,
  Arch1,
  Arch2,
  GildedCalligraphy,
  SimpleRetroDark,
  ModularTexture,
  SideCursive,
  GoldWreath,
  Simplicity,
  ArtDecoNavy,
  ArtDecoWhite,
  PicturePerfect,
  BrushedEdge,
  ScriptDark,
  InkGold
} from './templates';
import { getFormattedDate } from '@apps/ecard/Ecard.utils';
import { getFormattedLocation, getFormattedName, getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import useLoadGoogleFont from '@apps/saveTheDate/hooks/useLoadGoogleFont';
import { EcardAdditionalLinkType } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import GoldSwash from './templates/GoldSwash';

const templateMap = {
  ampersandTop: AmpersandTop,
  goldBorderCursive: GoldBorderCursive,
  bigNames: BigNames,
  arch2: Arch2,
  arch1: Arch1,
  gildedCalligraphy: GildedCalligraphy,
  simpleRetroDark: SimpleRetroDark,
  modularTexture: ModularTexture,
  sideCursive: SideCursive,
  goldWreath: GoldWreath,
  simplicity: Simplicity,
  artDecoNavy: ArtDecoNavy,
  artDecoWhite: ArtDecoWhite,
  goldSwash: GoldSwash,
  picturePerfect: PicturePerfect,
  brushedEdge: BrushedEdge,
  scriptDark: ScriptDark,
  inkGold: InkGold
};

export type Template = keyof typeof templateMap;

interface PhotoECardPreviewArgs {
  ecardData: SaveTheDateDesignInputFields;
  extraData: SaveTheDateExtraData;
  isAdjustingPosition?: boolean;
  toggleAdjustment?: () => void;
  savePosition?: (x: number, y: number, scale: number) => void;
}

export const usePhotoECardPreviewController = ({ ecardData, extraData, isAdjustingPosition, toggleAdjustment, savePosition }: PhotoECardPreviewArgs) => {
  const { themeSelected, photoUrl, inviteToFollow, dateFormat, coupleNameFormat, locationStringFormat, additionalLink1, additionalLink2, displayEventLink } = ecardData;
  const adjustmentRef = useRef<ReactZoomPanPinchRef>(null);
  const [isScaleControlVisible, setIsScaleControlVisible] = useState(true);
  const [isPanningPhoto, setIsPanningPhoto] = useState(false);
  const eventById = extraData.eventById;
  const eventInfo = eventById?.info;
  const TemplateComponent = templateMap[themeSelected as Template];
  const showNotYou = displayEventLink || additionalLink1 === EcardAdditionalLinkType.VIEW_WEBSITE || additionalLink2 === EcardAdditionalLinkType.VIEW_WEBSITE;

  useLoadGoogleFont([
    'Lora:400,500,500italic,600',
    'Quicksand:400,600,700',
    'Spectral:300,500,500italic',
    'Lato:400',
    'Playfair+Display:600',
    'Poiret+One:400',
    'Spectral:500,500italic,600',
    'Mrs+Saint+Delafield:400'
  ]);

  useEffect(() => {
    if (isAdjustingPosition) {
      setIsScaleControlVisible(true);
    }
  }, [isAdjustingPosition]);

  const { isFullNamesFormat, roundTopCorners, formattedDate, formattedOwnerName, formattedFianceeName, formattedLocation } = useMemo(() => {
    const date = extraData.date;
    const { ownerFirstName = '', ownerFullName = '', fianceeFirstName = '', fianceeFullName = '' } = eventInfo || {};
    const roundTopCorners = themeSelected === 'arch2';
    const isFullNamesFormat = coupleNameFormat === NameFormat.FULL;
    return {
      isFullNamesFormat,
      roundTopCorners,
      formattedDate: getFormattedDate(date, dateFormat),
      formattedOwnerName: getFormattedName({ firstName: ownerFirstName, fullName: ownerFullName, format: coupleNameFormat }),
      formattedFianceeName: getFormattedName({ firstName: fianceeFirstName, fullName: fianceeFullName, format: coupleNameFormat }),
      formattedLocation: getFormattedLocation({ locationInfo: eventInfo?.locationInfo, location: extraData.location, format: locationStringFormat })
    };
  }, [coupleNameFormat, dateFormat, eventInfo, extraData.date, extraData.location, locationStringFormat, themeSelected]);

  const { centerX, centerY, newWidth, newHeight } = useMemo(() => {
    const themeInfo = getThemeInfo(themeSelected);

    if (!themeInfo) {
      return { centerX: 0, centerY: 0, newWidth: 0, newHeight: 0 };
    }

    const { width: CONTAINER_WIDTH, height: CONTAINER_HEIGHT } = themeInfo.containerSize;

    if (!ecardData.photoWidth || !ecardData.photoHeight) {
      return { centerX: 0, centerY: 0, newWidth: CONTAINER_WIDTH, newHeight: CONTAINER_HEIGHT };
    }

    const widthRatio = CONTAINER_WIDTH / ecardData.photoWidth;
    const heightRatio = CONTAINER_HEIGHT / ecardData.photoHeight;
    const bestRatio = Math.max(widthRatio, heightRatio);

    const newWidth = ecardData.photoWidth * bestRatio;
    const newHeight = ecardData.photoHeight * bestRatio;

    const centerX = -(newWidth - CONTAINER_WIDTH) / 2;
    const centerY = -(newHeight - CONTAINER_HEIGHT) / 2;

    return { centerX, centerY, newWidth, newHeight };
  }, [ecardData.photoHeight, ecardData.photoWidth, themeSelected]);

  useEffect(() => {
    adjustmentRef?.current?.setTransform(ecardData?.photoX ?? centerX, ecardData?.photoY ?? centerY, ecardData?.photoScale ?? 1);
  }, [centerX, centerY, ecardData?.photoScale, ecardData?.photoX, ecardData?.photoY]);

  const cancelAdjustment = () => {
    adjustmentRef?.current?.resetTransform();
    toggleAdjustment?.();
  };

  const applyAdjustment = () => {
    toggleAdjustment?.();

    if (!adjustmentRef?.current?.state) {
      return;
    }

    const { positionX, positionY, scale } = adjustmentRef.current.state;

    savePosition?.(positionX, positionY, scale);
  };

  const handleEcardMouseEnter = () => {
    setIsScaleControlVisible(true);
  };

  const handleEcardMouseLeave = () => {
    setIsScaleControlVisible(false);
  };

  const template = (
    <TemplateComponent
      formattedDate={formattedDate}
      formattedOwnerName={formattedOwnerName}
      formattedFianceeName={formattedFianceeName}
      formattedLocation={formattedLocation}
      imageUrl={photoUrl || ''}
      inviteToFollow={inviteToFollow}
      isFullNamesFormat={isFullNamesFormat}
      isAdjustingPosition={isAdjustingPosition}
      adjustmentRef={adjustmentRef}
      positionX={ecardData?.photoX ?? centerX}
      positionY={ecardData?.photoY ?? centerY}
      photoWidth={newWidth}
      photoHeight={newHeight}
      // photoScale prop does not exist on all templates (templateMap[themeSelected as Template])
      // @ts-ignore
      photoScale={ecardData?.photoScale || 1}
      setIsPanningPhoto={setIsPanningPhoto}
    />
  );

  return {
    template,
    roundTopCorners,
    cancelAdjustment,
    applyAdjustment,
    handleEcardMouseEnter,
    handleEcardMouseLeave,
    isScaleControlVisible,
    isPanningPhoto,
    showNotYou
  };
};
