import { Box, Flex, styled, TextV2, StyleSystemProps } from '@withjoy/joykit';
import { ECARD_LANDING_LEFT_PANE_WIDTH_DESKTOP } from '@shared/components/EmailsAndEcards/components/EcardLanding/EcardLanding.constants';
import { InterFonts } from '@shared/utils/fonts/interFonts';

export const EcardLandingHeaderContainer = styled(Flex)``;
export const EcardLandingHeaderDivider = styled(Box)``;
export const EcardLandingHeaderText = styled(TextV2)`
  ${InterFonts.page};
  letter-spacing: -0.015em;
  font-weight: bold;
`;
export const EcardLandingHeaderDesc = styled(TextV2)``;

export const ecardLandingHeaderContainerStyles: StyleSystemProps = {
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: '48px',
  paddingX: '18px',
  paddingY: '16px',
  borderBottom: ['1px solid', 'none'],
  borderColor: 'mono3',
  position: ['sticky', 'absolute'],
  top: 0,
  zIndex: 'sticky',
  backgroundColor: 'white',
  width: ['100%', ECARD_LANDING_LEFT_PANE_WIDTH_DESKTOP]
};

export const ecardLandingHeaderDividerStyles: StyleSystemProps = {
  width: '1px',
  height: 9,
  marginX: [4, '28px'],
  marginY: 0,
  backgroundColor: 'mono3'
};

export const ecardLandingHeaderTextStyles: StyleSystemProps = {
  color: 'mono14'
};

export const styles = {
  ecardLandingHeaderContainer: ecardLandingHeaderContainerStyles,
  ecardLandingHeaderDivider: ecardLandingHeaderDividerStyles,
  ecardLandingHeaderText: ecardLandingHeaderTextStyles
};
