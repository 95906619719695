import { CheckIcon } from '@assets/icons';
import { useTranslation } from '@shared/core';
import { ButtonV2, DialogV2, Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';

interface ShareableDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCopy?: () => Promise<void>;
  isCopying?: boolean;
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
}

const ShareableDialog: React.FC<ShareableDialogProps> = ({ isOpen, onClose, onCopy, isCopying, onConfirmClick, onCancelClick }) => {
  const { t } = useTranslation('emailsAndEcards');
  const tConfirmAndSend = t('confirmAndSend');

  const handleCopyButtonClick = async () => {
    await onCopy?.();
    onClose();
    onConfirmClick?.();
  };

  const handleCancelClick = () => {
    onCancelClick?.();
    onClose();
  };

  return (
    <DialogV2 isOpen={isOpen} onClose={onClose} size="lg">
      <DialogV2.Header>
        <DialogV2.CloseButton />
      </DialogV2.Header>
      <DialogV2.Body>
        <TextV2 typographyVariant="hed5" textAlign="center" marginBottom={6} marginTop={['3rem', null, 0]}>
          {tConfirmAndSend.shareableDialog.title()}
        </TextV2>
        <Flex paddingBottom={5} marginBottom={5} borderBottom="1px solid rgba(235, 235, 235, 1)">
          <Flex background="#333" padding="6px" width={6} height={6} alignItems="center" justifyItems="center" borderRadius="12px" flex="none" marginRight={5} color="white">
            <CheckIcon />
          </Flex>
          <TextV2>{tConfirmAndSend.shareableDialog.specific()}</TextV2>
        </Flex>
        <Flex paddingBottom={5} marginBottom={5} borderBottom="1px solid rgba(235, 235, 235, 1)">
          <Flex background="#333" padding="6px" width={6} height={6} alignItems="center" justifyItems="center" borderRadius="12px" flex="none" marginRight={5} color="white">
            <CheckIcon />
          </Flex>
          <Flex flexDirection="column">
            <TextV2>{tConfirmAndSend.shareableDialog.visible()}</TextV2>
            <TextV2 typographyVariant="label2">{tConfirmAndSend.shareableDialog.includes()}</TextV2>
          </Flex>
        </Flex>
        <Flex paddingBottom={5}>
          <Flex background="#333" padding="6px" width={6} height={6} alignItems="center" justifyItems="center" borderRadius="12px" flex="none" marginRight={5} color="white">
            <CheckIcon />
          </Flex>
          <TextV2>{tConfirmAndSend.shareableDialog.privacy()}</TextV2>
        </Flex>
      </DialogV2.Body>
      <DialogV2.Footer flexDirection={['column-reverse', null, 'row']}>
        <ButtonV2 width={['100%', null, 'auto']} intent="neutral" variant="ghost" onClick={handleCancelClick}>
          {tConfirmAndSend.shareableDialog.cancel()}
        </ButtonV2>
        <ButtonV2 width={['100%', null, 'auto']} marginBottom={[4, null, 0]} intent="neutral" onClick={handleCopyButtonClick} loading={isCopying}>
          {tConfirmAndSend.shareableDialog.copy()}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};

export default ShareableDialog;
