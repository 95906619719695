import { useUpdateSearchParam } from '@shared/utils/updateSearchParam';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useCallback, useEffect } from 'react';

export function useOnParam(operation: () => void | PromiseLike<unknown>, args: { paramKey: string; checkValue: (val: Maybe<string>) => boolean; newValue?: string | null }) {
  const { paramKey, checkValue, newValue = null } = args;
  const paramHelper = useQueryParamHelper();
  const updateSearchParam = useUpdateSearchParam();
  const value = paramHelper.getValueString(paramKey);

  const call = useCallback(async () => {
    await operation();
    if (value) {
      updateSearchParam(paramKey, newValue, true);
    }
  }, [operation, updateSearchParam, paramKey, value, newValue]);

  useEffect(() => {
    if (checkValue(value)) {
      call();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {};
}
