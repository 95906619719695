import { useTranslation } from '@shared/core';
import { TextV2 } from '@withjoy/joykit';
import { Colors } from '@withjoy/joykit/theme';
import React from 'react';
import { LogoParticlesAnimation } from './LogoParticlesAnimation';
import { Container, ParticlesContainer } from './MessageLoader.styles';

interface MessageLoaderProps {
  color?: keyof Colors;
  withParticles?: boolean;
}

const Particles = () => {
  const { t } = useTranslation('postOffice');

  return (
    <ParticlesContainer>
      <LogoParticlesAnimation />
      <TextV2 typographyVariant="hed2" marginTop={10}>
        {t('emailEditor').generatingDraft()}
      </TextV2>
    </ParticlesContainer>
  );
};

const MessageLoader: React.FC<MessageLoaderProps> = ({ color = 'purple8', withParticles = false }) => {
  return (
    <Container color={color}>
      <div className="ml-animation-wrapper">
        <div className="ml-particle ml-particle-1"></div>
        <div className="ml-particle ml-particle-2"></div>
        <div className="ml-particle ml-particle-3"></div>
        <div className="ml-particle ml-particle-4"></div>
      </div>
      {withParticles ? (
        <Particles />
      ) : (
        <div className="ml-spinner">
          <div className="ml-bar1"></div>
          <div className="ml-bar2"></div>
          <div className="ml-bar3"></div>
          <div className="ml-bar4"></div>
          <div className="ml-bar5"></div>
          <div className="ml-bar6"></div>
          <div className="ml-bar7"></div>
          <div className="ml-bar8"></div>
          <div className="ml-bar9"></div>
          <div className="ml-bar10"></div>
          <div className="ml-bar11"></div>
          <div className="ml-bar12"></div>
        </div>
      )}
    </Container>
  );
};

export default MessageLoader;
