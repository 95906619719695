import React from 'react';
import { SAVE_THE_DATE_APP_NAME } from '@apps/saveTheDate/constants';
import { useRouterHelper } from '@shared/core';
import NavBarBottom from '@shared/components/NavBarBottom';
import { routePaths } from '@apps/saveTheDate/route.paths';
import { useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { useEcardTranslations } from '@apps/ecard/Ecard.i18n';
import { EcardType } from '@graphql/generated';

const NavBar = () => {
  const routeHelpers = useRouterHelper();
  const { isNavBarOpen } = useSaveTheDateState();
  const { ecardNavBarTranslations } = useEcardTranslations({ ecardDraftType: EcardType.savethedatephoto });
  const routesWithLabels = [
    {
      path: routeHelpers.buildPath(`${SAVE_THE_DATE_APP_NAME}/${routePaths.design.path}`),
      label: ecardNavBarTranslations.design,
      value: 'design'
    },
    {
      path: routeHelpers.buildPath(`${SAVE_THE_DATE_APP_NAME}/${routePaths.email.path}`),
      label: ecardNavBarTranslations.email,
      value: 'email'
    },
    {
      path: routeHelpers.buildPath(`${SAVE_THE_DATE_APP_NAME}/${routePaths.confirm.path}`),
      label: ecardNavBarTranslations.confirm,
      value: 'confirm'
    }
  ];
  return <NavBarBottom routes={routesWithLabels} isOpen={isNavBarOpen} />;
};

NavBar.displayName = 'SaveTheDateNavBar';

export { NavBar };
