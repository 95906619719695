import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { ApolloError } from '@apollo/client';
import { PostOfficeEmailPayload } from './PostOffice.utils';

type PagesForTelemetry = 'editEmail' | 'confirmAndSend';

const category = 'admin.postOffice';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  editEmail: emailType => ({
    category: 'postOffice',
    pagePrefix: 'admin',
    page: 'EditEmail',
    action: 'EditEmailViewed',
    extraPageInfo: {
      emailType
    }
  }),
  confirmAndSend: emailType => ({
    category: 'postOffice',
    pagePrefix: 'admin',
    page: 'ConfirmAndSend',
    action: 'ConfirmAndSendViewed',
    extraPageInfo: {
      emailType
    }
  })
};

export const postOfficeTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category,
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),
    launchEditor: (emailType: string) => ({
      action: 'LaunchEditor',
      actionType: undefined,
      category,
      label: emailType
    }),
    emailSave: (emailType: string) => ({
      action: 'EmailSave',
      actionType: 'click',
      category,
      label: emailType
    }),
    editEmailClicked: (emailType: string) => ({
      action: 'EditEmail',
      actionType: 'click',
      category,
      label: emailType
    }),
    editEmailDoneClicked: (emailType: string) => ({
      action: 'EditEmailDone',
      actionType: 'click',
      category,
      label: emailType
    }),
    editEmailCancelClicked: (emailType: string) => ({
      action: 'EditEmailCancel',
      actionType: 'click',
      category,
      label: emailType
    }),
    selectGuestsClicked: (emailType: string) => ({
      action: 'SelectGuests',
      actionType: 'click',
      category,
      label: emailType
    }),
    sendTestEmail: (emailType: string, name: string, email: string) => ({
      action: 'SendTest',
      actionType: 'click',
      category,
      label: emailType,
      extraInfo: {
        name,
        email
      }
    }),
    sendEmail: (emailType: string, payload: PostOfficeEmailPayload & { selectedGuestsIds: string[] }, adminReferrer: string) => ({
      action: 'SendEmail',
      actionType: 'click',
      category,
      label: emailType,
      extraInfo: {
        ...payload,
        totalRecipients: payload.selectedGuestsIds?.length || 0,
        adminReferrer
      }
    }),
    noGuestsIdsError: (selectedGuests: Maybe<string>[]) => ({
      category,
      action: 'noGuestsIdsError',
      actionType: 'error',
      extraInfo: {
        selectedGuests
      }
    }),
    sendThankYouPrompt: (prompt: string) => ({
      action: 'PromptSentToAI',
      category,
      label: 'thankyou',
      extraInfo: {
        prompt
      }
    }),
    modifyThankYouNote: (prompt: string, originalNote: string, modifiedNote: string) => ({
      action: 'AIDraftEdited',
      category,
      label: 'thankyou',
      extraInfo: {
        prompt,
        originalNote,
        modifiedNote
      }
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry } = createTelemetry(postOfficeTelemetry);
const usePostOfficeTelemetry = useTelemetry;
export { TelemetryProvider, usePostOfficeTelemetry };
