import { styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const Wrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.mono1};
  position: relative;

  & > .transition {
    transition: ${animationTransition('opacity')};
  }

  .opacity-100 {
    opacity: 1;
  }

  .opacity-0 {
    opacity: 0;
  }
`;
