import { EmailType, EmailInputFields, EmailEditorTranslations } from './Emails.types';
import { PersonFragment } from '@graphql/generated';

import * as Yup from 'yup';

export const notEmailOrUrlRegex = /^((?!([-a-za-z0-9@:%_\+~#=]{2,256}\.(?!\.)[a-z]{2,6}\b([-a-za-z0-9@:%_\+.~#?&//=]*)|(([^<>()\[\]\\.,;:\s@"]+(\.(?!\.)[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.(?!\.)[0-9]{1,3}\.(?!\.)[0-9]{1,3}\.(?!\.)[0-9]{1,3}])|(([a-za-z\-0-9]+\.(?!\.))+[a-za-z]{2,}))))[\s\S])*$/i;

const getBaseValidationSchema = (tEmailEditor: EmailEditorTranslations) => {
  return {
    eventId: Yup.string(),
    subject: Yup.string().matches(notEmailOrUrlRegex, { message: tEmailEditor.invalidSubjectIsEmailOrUrlError }).required(tEmailEditor.emailSubjectErrorMessage),
    headline: Yup.string().matches(notEmailOrUrlRegex, { message: tEmailEditor.invalidHeadlineIsEmailOrUrlError }),
    salutation: Yup.string().matches(notEmailOrUrlRegex, { message: tEmailEditor.invalidSalutationIsEmailOrUrlError }),
    includeGuestNames: Yup.boolean(),
    replyToEmail: Yup.string().email().required(tEmailEditor.replyToErrorMessage),
    testName: Yup.string(),
    testEmail: Yup.string().email(),
    imageUrl: Yup.string().url()
  };
};

const getSpecificValidationSchema = (emailType: EmailType, tEmailEditor: EmailEditorTranslations) => {
  switch (emailType) {
    case 'generic':
      return {
        draftId: Yup.string(),
        includeWebsiteDetails: Yup.boolean(),
        includeAppInstructions: Yup.boolean(),
        includeEventPassword: Yup.boolean(),
        message: Yup.string()
        // post office v2 future functionality
        // selectedLink: Yup.mixed<GeneralMessageLinkType>()
      };
    case 'ecard':
      return {
        includeEventDate: Yup.boolean(),
        includeAddToCalendarLink: Yup.boolean(),
        buttonText: Yup.string().matches(notEmailOrUrlRegex, { message: tEmailEditor.invalidButtonTextIsEmailOrUrlError }).required(tEmailEditor.buttonTextError),
        subheadline: Yup.string().matches(notEmailOrUrlRegex, { message: tEmailEditor.invalidSubheadlineIsEmailOrUrlError }),
        message: Yup.string().matches(notEmailOrUrlRegex, { message: tEmailEditor.invalidMessageIsEmailOrUrlError })
      };
    default:
      return {
        draftId: Yup.string(),
        includeWebsiteDetails: Yup.boolean(),
        includeAppInstructions: Yup.boolean(),
        includeEventPassword: Yup.boolean(),
        message: Yup.string()
      };
  }
};

export const getFormikValidationSchema = (emailType: EmailType, tEmailEditor: EmailEditorTranslations) => {
  return Yup.object<EmailInputFields>({
    ...getBaseValidationSchema(tEmailEditor),
    ...getSpecificValidationSchema(emailType, tEmailEditor)
  });
};

export const EmailsAndEcardsFormControlOverrides = {
  Hint: {
    props: { display: 'none' }
  }
};

export const getFooterTextWithCouplesNames = (nameA: Maybe<string>, nameB: Maybe<string>, email: Maybe<string>) => {
  let names = '';

  if (!!nameA) {
    names = nameA;
  }

  if (!!nameA && !!nameB) {
    names = `${nameA} and ${nameB}`;
  }

  const emailPart = email ? `Send replies to ${email}.` : '';

  return `This message was sent from withjoy.com on behalf of ${names}. ${emailPart}`;
};

export const getPeopleIds = (selectedPeople: ReadonlyArray<Partial<PersonFragment>>) => {
  if (selectedPeople.length > 0) {
    return selectedPeople.filter(selectedPerson => selectedPerson.id).map(selectedPerson => selectedPerson.id!);
  }
  return [];
};
