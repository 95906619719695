import React, { Fragment } from 'react';
import { ButtonV2, TextV2, SpacingStack, InputV2, FormControl, CancelButton } from '@withjoy/joykit';
import { useSendTestEmailDialog } from './SendTestEmailDialog.controller';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import { Dialog, Transition } from '@headlessui/react';
import { DialogSection, StyledDialog } from './SendTestEmailDialog.styles';
import { darkButtonOverride } from '@apps/postOffice/components/Shared.styles';

export interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    handleSendTestEmail: (testEmail: string, testName: string) => void;
    testEmail?: string;
  }> {}

export const SendTestEmailDialog: React.FC<Props> = props => {
  const { isOpen, onClose, handleSendTestEmail, testEmail } = props;
  const { handleCancel, formik, tUnsavedChangesDialog } = useSendTestEmailDialog({ handleSendTestEmail, onClose, testEmail });
  return (
    <Transition appear show={isOpen} as={Fragment}>
      {/* @ts-ignore */}
      <StyledDialog onClose={handleCancel}>
        <Transition.Child as={Fragment} enter="transition" enterFrom="opacity-0" enterTo="opacity-100" leave="transition" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="overlay" />
        </Transition.Child>
        <Transition.Child as={Fragment} enter="transition" enterFrom="opacity-0" enterTo="opacity-100" leave="transition" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Dialog.Panel className="panel">
            <DialogSection>
              <TextV2 typographyVariant="hed1">{tUnsavedChangesDialog.header}</TextV2>
            </DialogSection>
            <DialogSection>
              <SpacingStack spacing={4}>
                <FormControl label={tUnsavedChangesDialog.testNameLabel} error={formik.errors.testName} overrides={EmailsAndEcardsFormControlOverrides}>
                  <>
                    <InputV2 {...formik.getFieldProps('testName')} placeholder={tUnsavedChangesDialog.testNamePlaceholder} />
                    <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                      {formik.getFieldMeta('testName').error}
                    </TextV2>
                  </>
                </FormControl>
                <FormControl label={tUnsavedChangesDialog.testEmailLabel} error={formik.errors.testEmail} overrides={EmailsAndEcardsFormControlOverrides}>
                  <>
                    <InputV2 {...formik.getFieldProps('testEmail')} placeholder={tUnsavedChangesDialog.testEmailPlaceholder} />
                    <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                      {formik.getFieldMeta('testEmail').error}
                    </TextV2>
                  </>
                </FormControl>
              </SpacingStack>
            </DialogSection>
            <DialogSection flex style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <ButtonV2 overrides={darkButtonOverride} intent="primary" onClick={formik.submitForm} width="100%">
                {tUnsavedChangesDialog.sendButtonText}
              </ButtonV2>
              <CancelButton onClick={handleCancel} width="100%">
                {tUnsavedChangesDialog.cancelButtonText}
              </CancelButton>
            </DialogSection>
          </Dialog.Panel>
        </Transition.Child>
      </StyledDialog>
    </Transition>
  );
};
