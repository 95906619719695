import { useEffect, useState } from 'react';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

export const useScrollPosition = (onlyMobile = false, element?: HTMLElement | null) => {
  const isMobile = useIsMobileScreen();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (onlyMobile && !isMobile) return;
    const elementToListen = element || window;
    const updatePosition = () => {
      const position = element?.scrollTop || window?.pageYOffset;
      setScrollPosition(position);
    };
    elementToListen?.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => elementToListen?.removeEventListener('scroll', updatePosition);
  }, [element, isMobile, onlyMobile]);

  return scrollPosition;
};
