import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../../shared/components/AuthProvider';
import { useCallback, useState } from 'react';
import { useAccountTelemetry } from '@apps/account/account.telemetry';
import { useTranslation } from '@shared/core';
import { Provider } from '@apps/account/types';
interface SignInFields
  extends Readonly<{
    email: string;
    password: string;
  }> {}

export function useSignInController(redirectUri: string = '/') {
  const authProvider = useAuth();
  const [submitError, setSubmitError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const telemetry = useAccountTelemetry();
  const { t2 } = useTranslation('account');
  const tFormWarnings = t2('formWarnings');
  const formik = useFormik<SignInFields>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object<SignInFields>({
      email: Yup.string().email().required(tFormWarnings.required),
      password: Yup.string().required(tFormWarnings.required)
    }),
    onSubmit: async values => {
      setLoading(true);
      try {
        telemetry.emailPasswordLogin(values.email, !!values.password);
        await authProvider.loginManager.loginWithUsernameAndPassword(values.email, values.password, redirectUri, '/');
      } catch (err) {
        console.error(err);
        setSubmitError(tFormWarnings.invalidCredentials);
        setLoading(false);
      }
      setLoading(false);
    }
  });

  const thirdPartyClickHandler = useCallback(
    (type: Provider) => {
      switch (type) {
        case 'google':
          telemetry.thirdPartyButton(type);
          authProvider.loginManager.loginWithGoogle(redirectUri, '/');
          return;
        case 'facebook':
          telemetry.thirdPartyButton(type);
          authProvider.loginManager.loginWithFacebook(redirectUri, '/');
          return;
        default:
          telemetry.unHandledThirdPartyButton(type);
          return;
      }
    },
    [authProvider, telemetry, redirectUri]
  );

  return {
    formik,
    thirdPartyClickHandler,
    loading,
    submitError
  };
}
