export enum LoginState {
  WAITING_FOR_AUTH,
  WAITING_FOR_EVENT,
  WAITING_FOR_DRAFT,
  WAITING_FOR_ACCOUNT,
  PRESENT_EVENT_FORM,
  PRESENT_AUTH_FORM
}

export type LoginStateContext = {
  state: LoginState;
  changeState: (nextState: LoginState) => void;
};
