import { useState, useEffect, RefObject } from 'react';
/**
 * detects when an HTML element is less than a specific y position of viewport
 * @param {object} ref optional react ref. if not provided, a new one will be used instead.
 */
export const useDetectPosition = (ref: RefObject<HTMLElement>, position: number = 0) => {
  const [isAtPosition, setIsAtPosition] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      if (window?.scrollY > 0) {
        const rect = ref?.current?.getBoundingClientRect?.();
        if (rect) {
          setIsAtPosition(rect.top <= position);
        }
      }
    };

    window?.addEventListener('scroll', scrollHandler);
    // cleanup event listener on unmount
    return () => {
      window?.removeEventListener('scroll', scrollHandler);
    };
  }, [position, ref]);

  return [isAtPosition];
};
