import { Flex, styled } from '@withjoy/joykit';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';

export const StyledFrameBackground = styled.div<{ backgroundImage: string; isAdjustingPosition: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => backgroundImageForUrl(props.backgroundImage)};
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: ${({ isAdjustingPosition }) => (isAdjustingPosition ? 'none' : 'initial')};
`;

export const DetailsContainer = styled(Flex)`
  margin-top: 20px;

  *:not(:first-child) {
    margin-top: 8px;
  }
`;
