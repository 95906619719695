import { Box, Flex, styled, TextV2, StyleSystemProps } from '@withjoy/joykit';

export const LeftPaneContainer = styled(Flex)``;
export const LeftPaneCardTitle = styled(TextV2)``;
export const LeftPaneCardDesc = styled(TextV2)``;
export const LeftPaneDivider = styled(Box)``;
export const LeftPanePrintPartnersWrapper = styled(Flex)``;
export const LeftPanePrintPartner = styled(Flex)``;
export const LeftPanePrintPartnerText = styled(TextV2)``;
export const LeftPaneCardsWrapperDesktop = styled(Box)``;

const leftPaneContainerStyles: StyleSystemProps = {
  flexDirection: 'column',
  alignItems: 'center',
  flex: ['unset', 1],
  padding: ['48px 24px', '48px 40px 80px'],
  overflowY: ['unset', 'auto'],
  borderTop: ['1px solid', 'none'],
  borderColor: 'mono3'
};

export const leftPaneCardTitleStyles: StyleSystemProps = {
  // typographyVariant not working here for some reason so adding it as a prop to the components too...
  typographyVariant: 'hed3',
  color: 'mono14',
  marginTop: 5,
  display: 'flex',
  alignItems: 'center'
};

export const leftPanePrintTooltipStyles: StyleSystemProps = {
  display: 'inline'
};

export const leftPaneCardDescStyles: StyleSystemProps = {
  color: 'mono12',
  marginTop: 4
};

export const leftPaneDividerStyles: StyleSystemProps = {
  width: '100%',
  height: '1px',
  backgroundColor: 'mono3',
  marginTop: 9
};

export const leftPanePrintPartnersWrapperStyles: StyleSystemProps = {
  marginTop: 6
};

export const leftPanePrintPartnerStyles: StyleSystemProps = {
  justifyContent: 'center',
  alignItems: 'center',
  height: '120px',
  border: '1px solid',
  borderColor: 'mono3',
  borderRadius: '4px',
  cursor: 'pointer',
  _hover: {
    backgroundColor: 'mono1',
    border: '2px solid',
    borderColor: 'linkHover'
  },
  _active: {
    backgroundColor: 'mono2',
    border: '2px solid',
    borderColor: 'linkActive'
  }
};

export const leftPanePrintPartnerTextStyles: StyleSystemProps = {
  typographyVariant: 'label2',
  color: 'mono12',
  marginTop: 2
};

export const leftPaneAlertTextStyles: StyleSystemProps = {
  typographyVariant: 'body1',
  color: 'mono14',
  marginTop: 4
};

export const styles = {
  leftPaneContainer: leftPaneContainerStyles,
  leftPaneCardTitle: leftPaneCardTitleStyles,
  leftPanePrintTooltip: leftPanePrintTooltipStyles,
  leftPaneCardDesc: leftPaneCardDescStyles,
  leftPaneDivider: leftPaneDividerStyles,
  leftPanePrintPartnersWrapper: leftPanePrintPartnersWrapperStyles,
  leftPanePrintPartner: leftPanePrintPartnerStyles,
  leftPanePrintPartnerText: leftPanePrintPartnerTextStyles,
  leftPaneAlertText: leftPaneAlertTextStyles
};
