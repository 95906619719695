import React from 'react';
import { UserMenuWrapper, UserMenuContentWrapper, UserMenuDropdownWrapper, MenuText, OptionItem } from './UserMenu.styles';
import { useUserMenuController } from './UserMenu.controller';

export type Option = {
  title: string;
  onClick: () => void;
  icon?: JSX.Element;
};

interface Props
  extends Readonly<{
    title?: string;
    routes?: Option[];
  }> {}

export const UserMenu: React.FC<Props> = ({ title, routes = [] }) => {
  const { menuShown, setMenuShown, dropdownRef } = useUserMenuController();
  return (
    <UserMenuWrapper>
      <UserMenuContentWrapper tabIndex={0} onClick={() => setMenuShown(true)}>
        <MenuText className="admin-menu-avatar-wrapper">{title}</MenuText>
      </UserMenuContentWrapper>
      {menuShown ? (
        <UserMenuDropdownWrapper ref={dropdownRef}>
          {routes.map(route => (
            <OptionItem
              key={route.title}
              onClick={e => {
                e.preventDefault();
                setMenuShown(false);
                route.onClick();
              }}
            >
              {route.icon}
              {route.title}
            </OptionItem>
          ))}
        </UserMenuDropdownWrapper>
      ) : null}
    </UserMenuWrapper>
  );
};
