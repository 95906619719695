import { useTranslation } from '@shared/core';
import { Invitation, Share, Website, Envelope, MailingAddress, Code } from '@withjoy/joykit/icons';
import React, { useMemo } from 'react';
import { CustomizeNowButton } from '../../CustomizeNowButton';
import { InlineValueProps, ListValueProps } from '../ValueProps/ValueProps';
import { ButtonV2 } from '@shared/joykit/packages';
interface DigitalCustomizationPaneProps {
  onCustomizeClick: () => void;
  onTryPremiumClick: () => void;
  isCreatingDraft: boolean;
  intersectionRef: React.RefObject<HTMLButtonElement>;
  hideCustomizeNowButton?: boolean;
  disableCustomizeNowButton?: boolean;
}

export const DigitalCustomizationPane = (props: DigitalCustomizationPaneProps) => {
  const { disableCustomizeNowButton, onCustomizeClick, hideCustomizeNowButton, isCreatingDraft, intersectionRef, onTryPremiumClick } = props;
  const { t2 } = useTranslation('stationery');

  const { listValueProps, inlineValueProps, tryPremiumCta } = useMemo(() => {
    const { listValueProps, inlineValueProps, tryPremiumCta } = t2('pdpModal', 'customizationPane', 'digitalTemplateConfig');
    return {
      listValueProps: {
        sectionTitle: listValueProps.sectionTitle,
        items: [
          {
            icon: <Website size={24} />,
            description: listValueProps.designs
          },
          {
            icon: <Invitation size={24} />,
            description: listValueProps.tracking
          },
          {
            icon: <Share size={24} />,
            description: listValueProps.contactCollector
          },
          {
            icon: <Envelope size={24} />,
            description: listValueProps.linking
          }
        ]
      },
      inlineValueProps: [
        { icon: <Website />, description: inlineValueProps.websiteDesign },
        { icon: <MailingAddress />, description: inlineValueProps.contactCollector },
        { icon: <Code />, description: inlineValueProps.delivery }
      ],
      tryPremiumCta
    };
  }, [t2]);

  return (
    <>
      <CustomizeNowButton
        ref={intersectionRef}
        hide={hideCustomizeNowButton}
        isLoading={isCreatingDraft}
        onCustomizeClick={onCustomizeClick}
        disabled={disableCustomizeNowButton}
      />
      <ButtonV2 intent="neutral" shape="rounded" variant="ghost" marginTop={2} onClick={onTryPremiumClick}>
        {tryPremiumCta}
      </ButtonV2>
      <InlineValueProps items={inlineValueProps} />
      <ListValueProps items={listValueProps.items} sectionTitle={listValueProps.sectionTitle} />
    </>
  );
};
