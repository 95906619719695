import { Box, styled, css } from '@withjoy/joykit';

export const StyledEditEmailButton = styled(Box)<{ disabled?: boolean }>`
  position: absolute;
  top: 50vh;
  left: 97%;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    display: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 75%;
    `}
`;

export const Wrapper = styled.div``;
