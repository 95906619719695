import React from 'react';
import { postOfficeRoutePaths } from './PostOffice.route.paths';
import Nav from './components/Nav';
import { PostOfficeContainer, PostOfficeWrapper } from './PostOffice.styles';
import { useTranslation } from '@shared/core';
import globalWindow from '@shared/core/globals';
import { PostOfficeMessageType } from '@graphql/generated';
import { UniversalBurgerMenu } from '@apps/admin/components/UniversalBurgerMenu/UniversalBurgerMenu';
import { HeaderBar, HeaderBarText } from '@shared/components/EmailsAndEcards/components/Header/Header.styles';
import { Hamburger } from '@withjoy/joykit/icons';

interface PostOfficeProps
  extends Readonly<{
    eventHandle: string;
    draftType: PostOfficeMessageType;
    fromDashboard?: string;
    isThankYouMessageLoading: boolean;
  }> {}

export const getReferrer = () => {
  // We want to return empty if the referrer is the same as the current page
  let referrer = '';
  const href = window?.location?.href;
  if (document?.referrer && document?.referrer !== href) {
    referrer = document.referrer;
  }
  return referrer;
};

export const PostOffice: React.FC<PostOfficeProps> = ({ eventHandle, children, draftType, fromDashboard, isThankYouMessageLoading }) => {
  const { t2 } = useTranslation('postOffice');
  const tHeader = t2('header');

  if (globalWindow.Intercom) {
    globalWindow.Intercom('update', { hide_default_launcher: true });
  }

  return (
    <PostOfficeContainer alignItems="flex-start" justifyContent="center">
      <HeaderBar alignItems="center">
        <UniversalBurgerMenu>
          <Hamburger size="lg" />
        </UniversalBurgerMenu>
        <HeaderBarText>{tHeader[draftType as keyof typeof tHeader]}</HeaderBarText>
      </HeaderBar>
      <PostOfficeWrapper>{children}</PostOfficeWrapper>
      <Nav eventHandle={eventHandle} routes={postOfficeRoutePaths} draftType={draftType} fromDashboard={fromDashboard} isThankYouMessageLoading={isThankYouMessageLoading} />
    </PostOfficeContainer>
  );
};
