import React from 'react';
import { StationeryTemplate } from '../../../DesignsGallery.types';
import { GalleryTile } from '../../../../GalleryTile';
import { templateToTitle } from '@apps/card/Card.utils';
import { maybeApplyCallback } from '@shared/utils/maybeApplyCallback';
import { Box } from '@withjoy/joykit';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { PRICE_CONFIG } from '@apps/card/config';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useCardDiscountContext } from '../../../useCardDiscountContext';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { CardTilePriceLabel } from '../../CardTilePriceLabel';
import { usePremiumDigitalPriceConfig } from '@apps/card/routes/DigitalCardCustomizer/hooks/usePremiumDigitalPriceConfig';
import { desktopAspect, mobileAspect, tileRowGap } from '../../CardsConfig';

export type CardTileSource = 'shop' | 'favoritesGallery';

type CardTileProps = {
  data: StationeryTemplate & { type: 'template' };
  eventId?: string;
  eventDesignThemeId?: string;
  onFavoriteClick?: (template: StationeryTemplate) => Promise<void>;
  hideTag?: boolean;
  cardTileSource: CardTileSource;
};

export const CardTile = (props: CardTileProps) => {
  const { currentUser } = useAuth();
  const isSuperAdmin = currentUser && currentUser.profile?.superAdmin;
  const { t } = useTranslation('stationery');
  const isMobile = useIsMobileScreen();
  const { value: printEnableDigitalEditorEnabled } = useFeatureValue('printEnableDigitalEditor');
  const isFavoritesRouteEnabled = useFeatureValue('printFavoritesRouteEnabled').value === 'on';

  const translations = t('dashboard', 'designsGallery');
  const { cardTileSource, data, eventDesignThemeId } = props;
  const { category, approved, format, thumbnailUrl, enabled, digitalDesignThumbnailUrl } = data;
  const cardPricingConfig = PRICE_CONFIG.quantity[category];
  const { priceEachInMinorUnits: digitalPrice } = usePremiumDigitalPriceConfig();
  const cardDiscounts = useCardDiscountContext()!;
  const taylorDiscounts = cardDiscounts.taylor.getPrice(cardPricingConfig[100].totalPriceInMinorUnits / 100);
  const premiumDigitalDiscounts = cardDiscounts.joy_digital.getPrice(digitalPrice);

  const { designGalleryTileClicked, markTemplateAsFavorite } = useCardTelemetry();

  const { goToProductDetailsPage } = useCardsRouterContext();

  const handleOnViewTemplatePdpClick = useEventCallback((template: StationeryTemplate) => {
    designGalleryTileClicked({ themeId: template.themeId, templateCategory: template.category, format: template.format, isPremiumDesign: template.premium });
    goToProductDetailsPage('wedding', category, template.themeId);
  });

  const isPremium = printEnableDigitalEditorEnabled === 'on' && (data.premium || format === 'paper');

  const paperPrice = Math.floor(cardPricingConfig[100].totalPriceInMinorUnits / 100);
  const priceElement = (
    <CardTilePriceLabel
      paperPriceInMinorUnits={paperPrice}
      premiumDigitalPriceInMinorUnits={digitalPrice}
      paperPromo={taylorDiscounts}
      premiumDigitalPromo={premiumDigitalDiscounts}
    />
  );

  const handleOnToggleFavorite = useEventCallback((isFavorite: boolean) => {
    markTemplateAsFavorite({ themeId: data.themeId, templateCategory: data.category, isFavorite, source: cardTileSource });
  });

  return enabled || isSuperAdmin ? (
    <GalleryTile
      aspectRatio={isMobile ? mobileAspect : desktopAspect}
      alignContent="start"
      rowGap={tileRowGap}
      onClick={maybeApplyCallback(!!(approved || isSuperAdmin), () => handleOnViewTemplatePdpClick(data))}
    >
      {eventDesignThemeId === data.themeId && (
        <Box
          typographyVariant="label1"
          fontWeight={600}
          position="absolute"
          paddingX={4}
          paddingY={2}
          left={5}
          top={4}
          backgroundColor="mono14"
          color="white"
          userSelect="none"
          borderRadius={5}
          zIndex={1}
        >
          {translations.websiteMatch()}
        </Box>
      )}
      <GalleryTile.AssetPreview assetUrl={(thumbnailUrl || digitalDesignThumbnailUrl) ?? ''}>
        {isPremium && <GalleryTile.PremiumBadge />}
        {isFavoritesRouteEnabled && <GalleryTile.FavoriteBadge eventId={props.eventId} onToggle={handleOnToggleFavorite} template={data} />}
      </GalleryTile.AssetPreview>
      <GalleryTile.Content title={templateToTitle(data)} label={priceElement} />
    </GalleryTile>
  ) : null;
};
