import { styled, Box } from '@withjoy/joykit';

export const EcardDesignEditorDropzone = styled(Box)`
  width: 100%;
  height: 89px;
  border: 1px dashed ${props => props.theme.colors.mono5};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 'border .24s ease-in-out';
`;

export const EcardDesignEditorDropInput = styled.input``;

export const EcardDesignEditorGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 30px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

export const LineBreak = styled.br``;
