import { useHistory, useLocation } from '@react-router';
import { config } from '@static/js/joy';
import { withWindow } from '@shared/utils/withWindow';
import { LocationState } from 'history';

type ReturnType = (
  param: string,
  value: string | null,
  dynamic?: boolean | 'replace',
  baseUrl?: string
) => Readonly<{
  hash: string;
  pathname: string;
  search: string;
  state: LocationState;
  url: string;
}>;

export const useUpdateSearchParam = () => {
  const location = useLocation();
  const history = useHistory();
  return withWindow<ReturnType>(
    window => {
      return (param, value, dynamic, baseUrl) => {
        const url = new URL(baseUrl || window.location.href); // or construct from window.location
        // eslint-disable-next-line compat/compat
        const params = new URLSearchParams(url.search.slice(1));

        if (value === null) {
          params.delete(param);
        } else {
          params.set(param, value);
        }

        if (dynamic !== false) {
          const nextLocation = {
            pathname: location.pathname,
            search: params.toString() ? params.toString() : undefined,
            hash: location.hash
          };
          if (dynamic === 'replace') {
            history.replace(nextLocation);
          } else {
            history.push(nextLocation);
          }
        }

        return {
          hash: location.hash,
          pathname: location.pathname,
          search: params.toString(),
          state: location.state,
          url: new URL(`${location.pathname}${params.toString().length ? `?${params.toString()}` : ''}${location.hash}`, config.clientUri).toString()
        };
      };
    },
    () => {
      return {
        hash: '',
        pathname: '',
        search: '',
        state: null,
        url: ''
      };
    }
  );
};
