import { SpacingStack, styled, Box, css } from '@withjoy/joykit';

export const SurveyWrapper = styled(SpacingStack)`
  max-width: 360px;
`;

const injectSelectedState = ({ selected }: { selected?: boolean }) =>
  selected &&
  css`
    background-color: ${({ theme }) => theme.colors.mono2};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.mono14};
  `;
export const SurveyOption = styled(Box)<{ selected?: boolean }>`
  height: 84px;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space[6]};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.mono3};
  border-radius: ${({ theme }) => theme.radii[2]};
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.strokeHover};
    ${injectSelectedState}
  }
  ${injectSelectedState}
`;
