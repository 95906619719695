import React from 'react';
import { Flex } from '@withjoy/joykit';
import { CSSProperties, Children, FC, useLayoutEffect, useRef } from 'react';

// Roles:
// 1) Parent controls width.
// 2) Child controls aspect ratio (via height/width).
//    The Parent should follow with height auto.
// 3) This controls the scale and sets it on the child.
type PageScalerProps = Pick<CSSProperties, 'position'> & { onScaleChange?: (scale: number) => void };

export const PageScaler: FC<PageScalerProps> = props => {
  const { children, onScaleChange, ...cssProps } = props;
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const self = ref.current;
    if (!self) return;

    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      const child = self.firstElementChild as HTMLElement;
      if (!child) return;

      // Defer layout changes to the next frame to avoid Safari's:
      // "ResizeObserver loop completed with undelivered notifications."
      requestAnimationFrame(() => {
        const scale = self.clientWidth / child.clientWidth;
        self.style.height = `${child.clientHeight * scale}px`;
        child.style.position = 'absolute';
        child.style.scale = `${scale}`;
        onScaleChange?.(scale);
      });
    });

    // The scale is a function of self and child, so observing both.
    resizeObserver.observe(self);
    if (self.firstElementChild) {
      // In Envelope, the firstElementChild clientWidth does not immediately reflect the style width.
      // Thus observing firstElementChild so we update when clientWidth updates.
      resizeObserver.observe(self.firstElementChild);
    }
    return () => resizeObserver.disconnect();
  }, [onScaleChange, ref]);

  return (
    <Flex
      ref={ref}
      position="relative"
      width="100%" // Match parent width by convention.
      {...cssProps} // Allows caller to override position relative.
      alignItems="center"
      justifyContent="center"
    >
      {Children.only(children)}
    </Flex>
  );
};
