import React from 'react';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { useDisclosure } from '@withjoy/joykit';
import { AUTH_REDIRECT_PARAM } from '@apps/card/PublicShop/components/login/Components/AccountForm/useAuthRedirectURLs';

export enum routes {
  HOME,
  LOGIN
}

type AICardRouterContext = {
  goToLogin: () => void;
  isLoginModalOpen: boolean;
  onCloseLoginModal: () => void;
};

const [Provider, useAIRouterContext] = createContext<AICardRouterContext>({ name: 'AICardRouterContext' });

const AICardRouter = ({ children }: { children: React.ReactNode }) => {
  const query = useQueryParams();

  const { isOpen: isLoginModalOpen, onClose: onCloseLoginModal, onOpen: onOpenLoginModal } = useDisclosure({ defaultIsOpen: query?.[AUTH_REDIRECT_PARAM] === 'true' });

  const goToLogin = useEventCallback(() => {
    onOpenLoginModal();
  });

  return <Provider value={{ isLoginModalOpen, onCloseLoginModal, goToLogin }}>{children}</Provider>;
};

export { AICardRouter, useAIRouterContext };
