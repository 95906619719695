import { space as spaceFunction } from 'styled-system';

import { animationTransition } from '../../utils';
import { ButtonV1Props } from '.';
import { BUTTON_SPINNER } from '../../common';
import { styled, ThemeStyledProps, css } from '@shared/joykit/packages/core/common/styledComponents';
import { getButtonnVariantStyle } from '../../common/styles';
import { styleFunction, getStyledBaseComponentProps } from '../../common/styledSystem/styledFunctions';
// debounce
import { CSSObject } from 'styled-components';
import { AbstractButtonProps } from '../../common/AbstractButton';
import { Box } from '../Box';

type StyledButtonProps = ThemeStyledProps<AbstractButtonProps & ButtonV1Props>;

// ==============================================================
// Style Functions
// ==============================================================

const borderStyle = (props: StyledButtonProps): CSSObject => {
  return {
    borderRadius: props.borderRadius === 'none' ? 0 : 4
  };
};

const sizeStyle = (props: StyledButtonProps) => {
  if (props.size === 'medium') {
    return {
      minHeight: 48,
      minWidth: 132,
      padding: `0 ${props.theme.space[6]}`
    };
  }
  return {
    minHeight: 40,
    minWidth: 129,
    padding: `0 ${props.theme.space[7]}`
  };
};

const responsiveFillStyle = styleFunction({
  prop: 'width',
  alias: 'fill',
  transformValue: val => {
    return val ? '100%' : 'auto';
  }
});

const fillStyle = (props: StyledButtonProps) =>
  props.fill
    ? css`
        ${responsiveFillStyle}
        display: flex;
      `
    : undefined;

const loadingStyle = (props: StyledButtonProps) => {
  return props.loading
    ? css`
        > span > *:not(.${BUTTON_SPINNER}) {
          visibility: hidden;
        }
        > .${BUTTON_SPINNER} {
          position: absolute;
        }
      `
    : undefined;
};

// Color

const intentStyle = (props: StyledButtonProps) => {
  const config = getButtonnVariantStyle(props.theme, props.variant);
  switch (props.intent || 'primary') {
    case 'primary': {
      return css`
        color: ${config.text};
        background-color: ${config.fill};
        :hover:enabled {
          background-color: ${config.hover};
        }
        :active:enabled {
          background-color: ${config.active};
        }
        :disabled {
          background-color: ${props.theme.colors.buttonDisabledFill};
          color: ${props.theme.colors.typeDisabledDark};
        }

        ${_ => {
          if (props.disabled) {
            const disableState = {
              backgroundColor: props.theme.colors.buttonDisabledFill,
              color: props.theme.colors.typeDisabledDark
            };
            return disableState;
          }
          return null;
        }}
      `;
    }
    case 'secondary': {
      return css`
        background-color: #ffffff;
        border: 1px solid #eaeaea;
        color: ${config.fill};
        :hover:enabled {
          border-color: ${config.fill};
          background-color: #e9e8f0;
        }
        :active:enabled {
          background-color: #d4d2e1;
        }
        :disabled {
          color: #bbbbbb;
          background-color: #f8f8f6;
          border-color: #eaeaea;
        }

        ${_ => {
          if (props.disabled) {
            return {
              color: '#bbbbbb',
              'background-color': '#f8f8f6',
              'border-color': '#eaeaea'
            };
          }
          return null;
        }}
      `;
    }
    default:
      return undefined;
  }
};

// ==============================================================
// Components
// ==============================================================

export const StyledButton = styled(Box).attrs<ButtonV1Props>(props => {
  return {
    typographyVariant: props.typographyVariant || props.intent === 'primary' ? 'button1' : 'body1'
  };
})<ButtonV1Props>`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  & > span {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 auto;
    white-space: nowrap;
    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  transition: ${animationTransition('color', 'border', 'background-color', 'border-color')};

  ${getStyledBaseComponentProps}

  ${spaceFunction}
  ${props => sizeStyle(props)}
  ${props => fillStyle(props)}
  ${props => loadingStyle(props)}
  ${props => intentStyle(props)}
  ${borderStyle}

  :disabled {
    cursor: not-allowed;
    outline: none;
  }
  ${props => {
    if (props.disabled) {
      return { cursor: 'not-allowed', outline: 'none' };
    }
    return null;
  }}
`;

StyledButton.defaultProps = {
  intent: 'primary',
  size: 'medium'
};
