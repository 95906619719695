import { Box, BoxProps, styled, css } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const Root = styled(Box)``;

const navBackdrop = css`
  background-color: rgba(255, 255, 255, 0.92);

  /* if backdrop support: very transparent and blurred */
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: saturate(180%) blur(20px);
  }
`;

export const NavContainer = styled(Box)<{ $isOpen: boolean }>`
  position: relative;
  z-index: 2;

  ${({ theme, $isOpen }) => {
    return $isOpen
      ? {
          backgroundColor: theme.colors.white
        }
      : navBackdrop;
  }}
`;

export const DropdownLinksContainer = styled(Box)`
  position: absolute;
  width: 100%;
  left: 0;
  background-color: white;
  z-index: 3;
`;

export const MEGANAV_STYLE_OVERRIDES: BoxProps = {
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: 5,
  height: pxToRem(80),
  width: '100%',
  marginX: [null, null, null, null, 'auto'],
  paddingX: {
    _: 0,
    sm2: 7
  }
};
