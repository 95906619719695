import React, { useEffect, useMemo } from 'react';
import { Flex, SpacingStack } from '@withjoy/joykit';
import { EcardDesignEditorGrid } from './EcardDesignEditor.styles';
import { FormikProps } from 'formik';
import { useEcardDesignEditorController } from './EcardDesignEditor.controller';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { EcardEditorThemeType, EcardEventDesign } from '@apps/ecard/Ecard.types';
import EcardThemeCard from '@shared/components/EmailsAndEcards/components/Design/components/EcardEditPanel/components/EcardThemeCard';
import { CharmThemeFragment } from '@graphql/generated';
import { EcardThemesSkeletonScreens } from './components/EcardThemesSkeletonScreens';

interface Props
  extends Readonly<{
    formik: FormikProps<EcardDesignInputFields>;
    eventDesign?: EcardEventDesign | null;
  }> {}

export const EcardDesignEditor: React.FC<Props> = ({ formik, eventDesign }) => {
  const { themes, showMyColorToggle, setShowMyColorToggle, themesLoading } = useEcardDesignEditorController({ formik });
  const { joyTheme, selectedThemeType, useMyColor } = formik.values;

  useEffect(() => {
    if (!!joyTheme && !!joyTheme.websiteLayoutAlohaSettings?.alohaColorPreference?.supportsCustomization) {
      setShowMyColorToggle(true);
    } else {
      setShowMyColorToggle(false);
    }
  }, [joyTheme, setShowMyColorToggle]);

  const reorderedThemes = useMemo(() => {
    if (!themes) {
      return [];
    }
    const index = themes.findIndex(theme => theme.themeId === eventDesign?.theme?.themeId);
    if (index > -1) {
      const newThemes = themes.slice();
      const yourTheme = newThemes.splice(index, 1)[0];
      return [yourTheme, ...newThemes];
    }
    return themes;
  }, [themes, eventDesign]);

  const handleClickJoyTheme = (theme: CharmThemeFragment, alreadySelected: boolean) => {
    if (!alreadySelected) {
      formik.setFieldValue('joyTheme', theme);
      formik.setFieldValue('selectedThemeType', EcardEditorThemeType.JOY);
    }
  };

  const handleUseMyColor = () => {
    formik.setFieldValue('useMyColor', !useMyColor);
  };

  return (
    <SpacingStack marginTop={7}>
      <Flex flexDirection="column" alignItems="center">
        <EcardThemesSkeletonScreens isReady={!themesLoading}>
          <EcardDesignEditorGrid>
            {reorderedThemes.map((theme, index) => (
              <EcardThemeCard
                key={index}
                theme={theme}
                yourTheme={eventDesign?.theme?.themeId}
                selected={selectedThemeType === EcardEditorThemeType.JOY && !!joyTheme && joyTheme.themeId === theme.themeId}
                useMyColor={useMyColor}
                onClickUseMyColor={handleUseMyColor}
                showMyColorToggle={showMyColorToggle}
                onClick={alreadySelected => handleClickJoyTheme(theme, alreadySelected)}
              />
            ))}
          </EcardDesignEditorGrid>
        </EcardThemesSkeletonScreens>
      </Flex>
    </SpacingStack>
  );
};
