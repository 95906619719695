import React from 'react';
import { Anchor, Box, useDisclosure } from '@withjoy/joykit';
import { BurgerIconWrapper } from './BurgerMenu.styles';
import { MenuList, MenuListProps } from './MenuList';
import { useOuterClick } from '@shared/utils/hooks/useOuterClick';

export type BurgerMenuProps = {
  enableMobileFirst?: boolean;
  items: MenuListProps['items'];
  DrawerHeaderContent?: React.ReactNode;
  DrawerFooterContent?: React.ReactNode;
  drawerAnchor?: Anchor;
};

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ items, DrawerFooterContent, DrawerHeaderContent, drawerAnchor, children }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const menuRef = useOuterClick(onToggle, isOpen);
  return (
    <Box position="relative">
      <BurgerIconWrapper isActive={isOpen} onClick={() => onToggle()}>
        {children}
      </BurgerIconWrapper>
      <MenuList
        menuRef={menuRef}
        isOpen={isOpen}
        onClose={onClose}
        items={items}
        DrawerFooterContent={DrawerFooterContent}
        DrawerHeaderContent={DrawerHeaderContent}
        drawerAnchor={drawerAnchor}
      />
    </Box>
  );
};
