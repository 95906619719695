import { ModalProps, Flex, TextV2, styled, CloseButtonProps, defaultTheme, CheckboxV2Props } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const dialogOverrides: NonNullable<ModalProps['overrides']> = {
  Header: {
    props: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: 'transparent',
      paddingTop: { _: '1rem !important', sm: '1rem' },
      paddingRight: { _: '1rem !important', sm: '1rem' }
    }
  },
  Body: { props: { padding: '0 !important' } }
};

export const drawerOverrides: NonNullable<ModalProps['overrides']> = {
  Content: { props: { borderTopLeftRadius: '12px', borderTopRightRadius: '12px', height: 'auto' } },
  Header: {
    props: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: 'transparent',
      paddingTop: '1rem !important',
      paddingRight: '1rem !important'
    }
  },
  Body: { props: { padding: '0 !important' } }
};

export const closeButtonOverrides: NonNullable<CloseButtonProps['overrides']> = {
  Root: {
    props: {
      borderRadius: '50%',
      height: `${pxToRem(36)}`,
      minWidth: `${pxToRem(36)}`,
      outline: `0.125rem solid ${defaultTheme.colors.transparent}`,
      padding: '0px !important',
      width: `${pxToRem(36)}`,
      background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' },
      _focusVisible: {
        background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' }
      },
      _hover: {
        background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' }
      },
      _active: {
        background: { _: `${defaultTheme.colors.white} !important`, sm: 'transparent !important' }
      }
    }
  },
  Icon: {
    props: {
      color: defaultTheme.colors.black
    }
  }
};

export const ContentContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  opacity: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

export const checkboxOverrides: CheckboxV2Props['overrides'] = {
  Root: {
    props: {
      _focus: { boxShadow: 'none' },
      _hover: {
        backgroundColor: 'mono2'
      },
      _active: {
        backgroundColor: 'mono3'
      }
    }
  },
  Label: { props: { typographyVariant: 'button1', _focus: { boxShadow: 'none' } } },
  Control: {
    props: {
      _focus: { boxShadow: 'none' },
      color: 'mono3',
      borderColor: 'mono4',
      backgroundColor: 'white',
      borderRadius: '100%',
      _groupActive: {
        color: 'black',
        backgroundColor: 'mono2',
        borderColor: 'transparent',
        _checked: {
          color: 'black'
        }
      },
      _checked: {
        color: 'white',
        backgroundColor: 'black',
        borderColor: 'transparent'
      }
    }
  }
};

export const ImageWrapper = styled(Flex)`
  position: relative;
  height: ${pxToRem(188)};
  background-position: 0% 70%;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 34%;
    height: unset;
    border-top-left-radius: ${pxToRem(12)};
    border-bottom-left-radius: ${pxToRem(12)};
    background-position: 73% 70%;
  }
`;

export const InfoWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${pxToRem(32)};
  background-color: ${props => props.theme.colors.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 66%;
    padding: ${pxToRem(56)} ${pxToRem(40)};
    border-top-right-radius: ${pxToRem(12)};
    border-bottom-right-radius: ${pxToRem(12)};
  }
`;

export const TitleText = styled(TextV2)``;

export const InfoText = styled(TextV2)`
  font-family: 'Gotham SSm', sans-serif;
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 300;
  line-height: ${pxToRem(24.4)};
  padding-bottom: ${pxToRem(8)};
`;

export const SubText = styled(TextV2)`
  font-family: 'Gotham SSm';
  font-size: ${pxToRem(13)};
  font-style: normal;
  font-weight: 500;
  line-height: ${pxToRem(24.4)};
`;

export const CTAContainer = styled(Flex)``;

export const RadioButton = styled(Flex)<{ active: boolean }>`
  justify-content: space-between;
  padding: ${pxToRem(16)};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: ${pxToRem(4)};
  height: ${pxToRem(56)};
  cursor: pointer;
  transition: background 0.1s ease-in-out, border-color 0.1s ease-in;

  &:hover {
    padding: ${pxToRem(15)};
    border: 2px solid ${props => props.theme.colors.gray14};
  }
  &:focus {
    padding: ${pxToRem(15)};
    border: 2px solid ${props => props.theme.colors.primary7};
  }
  &:focus-within {
    padding: ${pxToRem(15)};
    border: 2px solid ${props => props.theme.colors.primary7};
  }
  &:active {
    padding: ${pxToRem(15)};
  }
  ${props =>
    props.active &&
    `
    padding: ${pxToRem(15)};
    background-color: ${props.theme.colors.gray1} !important;
    border: 2px solid ${props.theme.colors.mono14} !important;
  `}
`;
