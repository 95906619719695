import { PageVisibility } from '@apps/createWedding/routes/Survey/Survey.types';
import { getUpdateMenuSettingsQuery } from '@apps/createWedding/routes/Survey/menuSettings/menuSettings';
import { config } from '@static/js/env.config';
import { addError } from '@shared/utils/logger';

const ceremonyAPI = config.legacyCeremonyUri;

const getPublishedPage = (page: string): PageVisibility => ({
  page,
  visibility: 'published'
});

export const setInitialPagesVisibility = async (selection: string[], website: string, token: Nullable<string>) => {
  if (ceremonyAPI) {
    const body = {
      query: getUpdateMenuSettingsQuery(website),
      variables: {
        pageVisibilitiesInput: {
          pages: [
            { page: 'welcome', visibility: 'unpublished' },
            { ...getPublishedPage('travel') },
            { ...getPublishedPage('faq') },
            { ...getPublishedPage('story') },
            { ...getPublishedPage('registry') },
            { ...getPublishedPage('schedule') },
            { ...getPublishedPage('moments') },
            { ...getPublishedPage('vip') },
            { ...getPublishedPage('RSVP') }
          ]
        }
      }
    };

    // We are using fetch for the ceremonyAPI instead of the ApolloClient for maintainability
    // and ensure no additional dependencies are taken on this API
    try {
      await fetch(`${ceremonyAPI}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
      });
    } catch (error) {
      addError(error, {
        surveySelection: selection,
        requestBody: body
      });
    }
  }
};
