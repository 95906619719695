/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Mailing Address',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M13.888 5.143h3.883a1 1 0 0 0 .96-.72l.583-2a1 1 0 0 0-.96-1.28h-4.667a1 1 0 0 0-1 1v5H7a4 4 0 0 0-4 4v7.5a.5.5 0 0 0 .449.497l6.586.003v3.113a.6.6 0 1 0 1.2 0v-3.112h1.8v2.494a.6.6 0 1 0 1.2 0v-2.495H20a1 1 0 0 0 1-1v-7a4 4 0 0 0-4-4h-3.113v-2zm0-2.8h4.2l-.467 1.6h-3.733v-1.6zm-1.2 6v5.2a.6.6 0 0 0 1.2 0v-5.2H17a2.8 2.8 0 0 1 2.8 2.8v6.8h-8.55v-6.8c0-1.09-.436-2.078-1.143-2.8h2.581zM7 8.343h.25a2.8 2.8 0 0 1 2.8 2.8v6.8H4.2v-6.8a2.8 2.8 0 0 1 2.8-2.8z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 36 36',
      path: (
        <>
          <path
            d="M4.08931 17.2883C4.08931 13.5713 7.10257 10.5581 10.8196 10.5581C14.5366 10.5581 17.5499 13.5713 17.5499 17.2883V28.7747C17.5499 28.9234 17.4294 29.0439 17.2807 29.0439H4.08931V17.2883Z"
            stroke="currentColor"
            strokeWidth="0.897372"
            fill="transparent"
          />{' '}
          <path d="M15.8458 33.7961V29.0703H20.8711V32.8888" stroke="currentColor" strokeWidth="0.897372" strokeLinecap="round" fill="transparent" />{' '}
          <mask id="path-3-inside-1_32089_168013" fill="white">
            {' '}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.0239 7.24425V20.1523C23.0239 20.9452 22.3811 21.5881 21.5881 21.5881C20.7952 21.5881 20.1523 20.9452 20.1523 20.1523V2.92273C20.1523 2.52624 20.4738 2.20483 20.8702 2.20483H21.5378C21.5543 2.2037 21.5709 2.20312 21.5877 2.20312H28.429C28.8911 2.20312 29.2329 2.63337 29.1283 3.08348L28.2907 6.68882C28.2151 7.01405 27.9253 7.24425 27.5914 7.24425H23.0239Z"
            />{' '}
          </mask>{' '}
          <path
            d="M23.0239 7.24425V6.34688H22.1266V7.24425H23.0239ZM21.5378 2.20483V3.1022H21.5686L21.5993 3.1001L21.5378 2.20483ZM29.1283 3.08348L28.2542 2.88041V2.88041L29.1283 3.08348ZM28.2907 6.68882L29.1648 6.89189L28.2907 6.68882ZM22.1266 7.24425V20.1523H23.9213V7.24425H22.1266ZM22.1266 20.1523C22.1266 20.4496 21.8855 20.6907 21.5881 20.6907V22.4854C22.8767 22.4854 23.9213 21.4408 23.9213 20.1523H22.1266ZM21.5881 20.6907C21.2908 20.6907 21.0497 20.4496 21.0497 20.1523H19.255C19.255 21.4408 20.2996 22.4854 21.5881 22.4854V20.6907ZM21.0497 20.1523V2.92273H19.255V20.1523H21.0497ZM21.0497 2.92273C21.0497 3.02185 20.9694 3.1022 20.8702 3.1022V1.30746C19.9782 1.30746 19.255 2.03064 19.255 2.92273H21.0497ZM20.8702 3.1022H21.5378V1.30746H20.8702V3.1022ZM21.5993 3.1001C21.5953 3.10037 21.5915 3.1005 21.5877 3.1005V1.30575C21.5504 1.30575 21.5133 1.30703 21.4764 1.30956L21.5993 3.1001ZM21.5877 3.1005H28.429V1.30575H21.5877V3.1005ZM28.429 3.1005C28.3135 3.1005 28.2281 2.99294 28.2542 2.88041L30.0024 3.28656C30.2377 2.2738 29.4688 1.30575 28.429 1.30575V3.1005ZM28.2542 2.88041L27.4166 6.48574L29.1648 6.89189L30.0024 3.28656L28.2542 2.88041ZM27.4166 6.48574C27.4355 6.40443 27.5079 6.34688 27.5914 6.34688V8.14162C28.3427 8.14162 28.9947 7.62367 29.1648 6.89189L27.4166 6.48574ZM27.5914 6.34688H23.0239V8.14162H27.5914V6.34688Z"
            fill="currentColor"
            mask="url(#path-3-inside-1_32089_168013)"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1542 10.1016H10.8216C6.85672 10.1016 3.64258 13.3157 3.64258 17.2805V29.1258C3.64258 29.3241 3.80329 29.4848 4.00153 29.4848H31.6406C32.0371 29.4848 32.3585 29.1634 32.3585 28.7669V17.2805C32.3585 13.3157 29.1443 10.1016 25.1795 10.1016H23.0258V10.9989H25.1795C28.6487 10.9989 31.4611 13.8113 31.4611 17.2805V28.5874H4.53995V17.2805C4.53995 13.8113 7.35232 10.9989 10.8216 10.9989H20.1542V10.1016Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
