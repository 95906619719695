import { isAuthProviderError } from '@apps/createWedding/utils';
import { useAuth } from '@shared/components/AuthProvider';
import { useTranslation } from '@shared/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import { useAuthRedirectURLs } from '../useAuthRedirectURLs';
import { usePublicCardShopTelemetry } from '@apps/card/PublicShop/PublicShop.telemetry';

type CreateAccountFields = {
  email: string;
  password: string;
};

// TODO -- add telemetry.
const useCreateAccountController = () => {
  const authProvider = useAuth();
  const [submissionError, setSubmissionError] = useState<string>('');
  const { t2 } = useTranslation('createWedding');
  const { createOrSignupCtaClicked } = usePublicCardShopTelemetry();

  const formikErrors = t2('createAccount', 'errors');

  const generateAuthRedirectURls = useAuthRedirectURLs();

  const formik = useFormik<CreateAccountFields>({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email(formikErrors.emailValid).required(formikErrors.emailRequired),
      password: Yup.string().required(formikErrors.passwordRequired)
    }).required(),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ email, password }) => {
      try {
        createOrSignupCtaClicked('createAccount');
        const redirectURLs = generateAuthRedirectURls('accountcreated', 'accountfailure');
        await authProvider.loginManager.signupWithUsernameAndPassword(email, password, redirectURLs.success, redirectURLs.failure);
      } catch (err) {
        if (isAuthProviderError(err)) {
          setSubmissionError(err.description);
        }
      }
    }
  });
  const getFieldErrorProps = useFieldErrors<CreateAccountFields>(formik);

  return {
    formik,
    submissionError,
    getFieldErrorProps
  };
};

export default useCreateAccountController;
