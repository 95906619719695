import React, { useEffect, useRef, useState } from 'react';
import { createContext } from '@shared/utils/createContext';
import { CreateEventMutationFn, useCreateEventMutation, CreateEventMutation } from '@graphql/generated';
import { ApolloError, FetchResult } from '@apollo/client';
import { addError } from '@shared/utils/logger';

type EventPromise = Nullable<Promise<FetchResult<CreateEventMutation>>>;

interface EventListenerCallbackExtraParams {}

type EventListenerCallback<T> = (mutation: T, extraParams: EventListenerCallbackExtraParams) => Promise<unknown>;

interface EventStateContext<T, R> {
  createJoyEvent: T;
  createdEventData?: Nullable<R>;
  isEventCreationSettled: boolean;
  createError?: ApolloError;
  addEventCreatedListener: (cb: EventListenerCallback<R>) => Promise<void>;
}

interface PendingEventListeners {
  cb: EventListenerCallback<CreateEventMutation>;
  inProgress: boolean;
}

type JoyEventStateContext = EventStateContext<CreateEventMutationFn, CreateEventMutation>;

const [Provider, useCreateEventState] = createContext<JoyEventStateContext>({ name: 'CreateEventState' });

const CreateEventStateProvider: React.FC = ({ children }) => {
  const [mutation, { data: createdEventData, called, loading, error }] = useCreateEventMutation();
  const [pendingEventListeners, setPendingEventListeners] = useState<Set<PendingEventListeners>>(new Set());
  const eventPromise = useRef<EventPromise>(null);

  useEffect(() => {
    if (!loading && createdEventData) {
      pendingEventListeners.forEach(eventListener => {
        if (!eventListener.inProgress) {
          eventListener.inProgress = true;
          eventListener.cb(createdEventData, {}).finally(() => {
            setPendingEventListeners(prev => {
              prev.delete(eventListener);
              return new Set(prev);
            });
          });
        }
      });
    }
  }, [createdEventData, loading, pendingEventListeners]);

  const createJoyEvent: CreateEventMutationFn = options => {
    const promise = mutation({
      ...options,
      onError: error => {
        addError(error, options?.variables?.payload);
      }
    });

    eventPromise.current = promise;

    return promise;
  };

  const addEventCreatedListener = async (cb: EventListenerCallback<CreateEventMutation>) => {
    setPendingEventListeners(prev => {
      prev.add({ cb, inProgress: false });
      return new Set(prev);
    });
  };

  const isEventCreationSettled = called && !error && !loading && pendingEventListeners.size === 0;

  return <Provider value={{ createJoyEvent, addEventCreatedListener, isEventCreationSettled, createdEventData, createError: error }}>{children}</Provider>;
};

export { useCreateEventState, CreateEventStateProvider };
