import { useCallback, useEffect, useMemo } from 'react';
import { LOCAL_STORAGE_DIALOG_PRESENTED_TIMESTAMP, LOCAL_STORAGE_HAS_PRESENTED_TRAVEL_DIALOG, LOCAL_STORAGE_SHOULD_TRACK_TRAVEL_EDIT } from '../DashboardContentV2.constants';
import { useDisclosure } from '@withjoy/joykit';
import { useGetAdminDashboardNonCriticalDataQuery } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';

interface TravelBookingDialogState {
  dialogPresentedTimestamp: string;
  hasPresentedTravelDialog: boolean;
}

/**
 * Created this hook to solely manage the travel booking dialog.
 * This hook should avoid using any contexts since it'll be used in non-dashboard views (eg. print area).
 */
export const useTravelBookingDialogState = (eventHandle: string) => {
  const { isOpen: isBookingDialogOpen, onClose: onBookingDialogClose, onOpen: onBookingDialogOpen } = useDisclosure();

  const { value: hotelBlockEnabledValue } = useFeatureValue('hotelBlockEnabled');

  useEffect(() => {
    // this local storage value is read in the monorepo
    localStorage.setItem(LOCAL_STORAGE_SHOULD_TRACK_TRAVEL_EDIT, `${hotelBlockEnabledValue === 'on' ? 'true' : 'false'}`);
  }, [eventHandle, hotelBlockEnabledValue]);

  const { data: nonCriticalData } = useGetAdminDashboardNonCriticalDataQuery({ batchMode: 'off', variables: { eventHandle } });
  const hotelRoomBlockSubmitted = !!nonCriticalData?.eventByName?.hotelRoomBlocks?.submittedDateTime || false;

  const travelBookingDialogState = useMemo<TravelBookingDialogState>(() => {
    return {
      dialogPresentedTimestamp: localStorage.getItem(LOCAL_STORAGE_DIALOG_PRESENTED_TIMESTAMP) || '',
      hasPresentedTravelDialog: localStorage.getItem(LOCAL_STORAGE_HAS_PRESENTED_TRAVEL_DIALOG) === 'true'
    };
  }, []);

  const onPresentTravelBookingDialog = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_HAS_PRESENTED_TRAVEL_DIALOG, 'true');
    localStorage.setItem(LOCAL_STORAGE_DIALOG_PRESENTED_TIMESTAMP, Date.now().toString());
  }, []);

  const openTravelBookingDialog = useCallback(
    (disableLocalStorageTracking?: boolean) => {
      if (!disableLocalStorageTracking) {
        onPresentTravelBookingDialog();
      }

      onBookingDialogOpen();
    },
    [onPresentTravelBookingDialog, onBookingDialogOpen]
  );

  const shouldPresentTravelBookingDialog = !travelBookingDialogState.hasPresentedTravelDialog && !hotelRoomBlockSubmitted;

  return {
    isBookingDialogOpen,
    isLoadingBookingDataOrWaitingAssignment: !hotelBlockEnabledValue || !nonCriticalData?.eventByName,
    hotelBlockEnabledValue,
    nonCriticalData,
    onBookingDialogClose,
    openTravelBookingDialog,
    shouldPresentTravelBookingDialog,
    travelBookingDialogState,
    onPresentTravelBookingDialog
  };
};
