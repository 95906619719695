import React, { useEffect } from 'react';
import { Route, Switch } from '@react-router';
import { RoutePath, RoutesPaths, RoutesProvider, useRouterHelper } from '@shared/core';
import { AccountPage, AuthCallbackPage, AuthCallbackProps } from './routes/SignIn';
import Impermissible from './routes/Impermissible';
import VerifyEmail from './routes/VerifyEmail';
import { useIntercom } from '@shared/core/intercom';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { MustBeSignedIn } from '@shared/components/MustBeSignedIn/MustBeSignedIn';
import { SocialLoginComponent } from './routes/SocialSignIn';

/**
 * Example Route name types. Defines the routes that are available
 */
type RoutesNames = 'account' | 'impermissible' | 'verifyEmail';

/**
 * Example route paths. Has a path for the <Router /> and a function for history to push to.
 */
export const routePaths: RoutesPaths<RoutesNames> = {
  account: {
    path: '',
    goToPath: () => ''
  },
  impermissible: {
    path: 'impermissible',
    goToPath: () => 'impermissible'
  },
  verifyEmail: {
    path: 'verify-email',
    goToPath: () => 'verify-email'
  }
};

const RoutesAccount: React.FC = () => {
  const routeHelpers = useRouterHelper();
  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(routePaths.impermissible.path),
      component: () => <Impermissible />
    },
    {
      path: routeHelpers.buildPath(routePaths.verifyEmail.path),
      component: () => (
        <MustBeSignedIn>
          <VerifyEmail />
        </MustBeSignedIn>
      )
    },
    {
      path: routeHelpers.buildPath(routePaths.account.path),
      component: ({ history }) => <AccountPage history={history} />
    }
  ];

  return (
    <Switch>
      {routes.map(route => (
        <Route key={route.path} path={route.path} render={route.component} />
      ))}
    </Switch>
  );
};

export const RouterAccount: React.FC<{ appUrl: string }> = ({ appUrl }) => {
  const intercom = useIntercom();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  useEffect(() => {
    if (!isMobile) {
      intercom.boot();
    }
    return () => {
      intercom.shutdown();
    };
  }, [intercom, isMobile]);
  return (
    <RoutesProvider appUrl={appUrl}>
      <RoutesAccount />
    </RoutesProvider>
  );
};

export const RouterAccountCallback: React.FC<{ appUrl: string }> = ({ appUrl }) => {
  function render(props: AuthCallbackProps) {
    return <AuthCallbackPage history={props.history} />;
  }
  // simplified, because there will only be one Route
  return (
    <RoutesProvider appUrl={appUrl}>
      <Switch>
        <Route path="" render={render} />
      </Switch>
    </RoutesProvider>
  );
};

export const RouterSocialLogin: React.FC<{ appUrl: string }> = ({ appUrl }) => {
  // simplified, because there will only be one Route
  return (
    <RoutesProvider appUrl={appUrl}>
      <Switch>
        <Route path="" render={() => <SocialLoginComponent />} />
      </Switch>
    </RoutesProvider>
  );
};
