import { mediaScreenMobile, mediaScreenNotMobile } from '@shared/utils/media/mediaQueriesUtils';
import { css, styled, Tag, Theme } from '@withjoy/joykit';

export interface StylingProps {
  isSmall?: boolean;
}

const primaryColor = ({ theme }: { theme: Theme }) => theme.colors.primary8;

export const StyledTag = styled(Tag)`
  border: 1px solid ${primaryColor};
  color: ${primaryColor};

  ${mediaScreenMobile`${css`
    background-color: white;
    border-radius: 15px;
  `}`}

  ${mediaScreenNotMobile`${css`
    background-color: #faf7ff;
    border-radius: 3px;
  `}`}
`;

export const StyledLabelWrapper = styled.span<StylingProps>`
  display: flex;
  flex: 1;
  margin-right: 1rem;
  padding: 0.375rem 0;
  align-items: center;
`;
