import React, { useEffect, useRef, useState } from 'react';
import type { AnimationItem, AnimationSegment } from 'lottie-web';
import loadable from '@loadable/component';
import { withWindow } from '@shared/utils/withWindow';

interface LottiePlayerProps {
  loop?: boolean;
  autoplay?: boolean;
  animationProgress?: number;
  restart?: boolean;
  lottieUrl: string;
  play?: boolean;
  delay?: number;
  playSegment?: AnimationSegment;
}

const Lottie = loadable.lib(
  () =>
    import(
      /* webpackChunkName: "static/webpack/vendor/lottie-web" */
      'lottie-web'
    ),
  {
    ssr: false
  }
);

const Root: React.FC<LottiePlayerProps & { lottie: typeof import('lottie-web').default }> = ({
  loop = false,
  autoplay = false,
  animationProgress = 0,
  restart,
  lottieUrl,
  play,
  lottie,
  delay,
  playSegment
}) => {
  const animationContainer = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);

  useEffect(() => {
    if (!animation && animationContainer.current) {
      setAnimation(
        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop,
          autoplay,
          path: lottieUrl,
          rendererSettings: {
            progressiveLoad: false,
            preserveAspectRatio: 'xMidYMid slice'
          }
        })
      );
    }
    return () => animation?.destroy();
  }, [animationContainer, animation, autoplay, loop, lottieUrl, lottie]);

  useEffect(() => {
    let animationDelay: number;
    if (animation) {
      if (play) {
        if (delay) {
          withWindow(window => {
            animationDelay = window.setTimeout(() => {
              if (playSegment) {
                animation.playSegments(playSegment, true);
              } else {
                animation.play();
              }
            }, delay);
          });
        } else if (playSegment) {
          animation.playSegments(playSegment, true);
        } else {
          animation.play();
        }
      } else if (play === false) {
        animation.pause();
      }
      if (animationProgress) {
        const totalFrames = animation.getDuration(true) - 1;
        const currentFrame = Math.round(animationProgress * totalFrames);
        animation.goToAndStop(currentFrame, true);
      }
    }

    return () => {
      withWindow(window => {
        window.clearTimeout(animationDelay);
      });
    };
  }, [animation, animationProgress, play, delay, playSegment]);

  useEffect(() => {
    if (animation && restart) {
      animation.goToAndPlay(1, true);
    }
  }, [restart, animation]);

  return <div ref={animationContainer} />;
};

export const LottiePlayer: React.FC<LottiePlayerProps> = props => {
  return <Lottie fallback={<div></div>}>{({ default: lottie }) => <Root {...props} lottie={lottie} />}</Lottie>;
};
