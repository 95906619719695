import React from 'react';
import { CardLinkAsset } from '../../../usePropValues';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { GalleryTile } from '../../../../GalleryTile';
import { PRICE_CONFIG } from '@apps/card/config';

type CardLinkProps = {
  data: CardLinkAsset & { type: 'cardlink' };
};

export const CardLink = (props: CardLinkProps) => {
  const isMobile = useIsMobileScreen();
  const { data } = props;
  const { desktop, mobile, onClick, titleText, category } = data;

  const currentPriceConfig = PRICE_CONFIG.quantity[category];

  return (
    <GalleryTile onClick={onClick}>
      <GalleryTile.AssetPreview assetUrl={(isMobile ? mobile : desktop) ?? ''} />
      <GalleryTile.Content title={titleText} label={currentPriceConfig?.[100] ? `From ${currentPriceConfig[100].individualPriceString}` : ''} />
    </GalleryTile>
  );
};
