import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';

type LoadingPageProps = {
  reason: string;
};

export const LoadingPage = ({ reason }: LoadingPageProps) => {
  const mobile = useIsMobileOrTablet();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh" width={mobile ? '100vw' : '40vw'}>
      <TextV2 typographyVariant="HedHero72">Hang tight!</TextV2>
      <TextV2 typographyVariant="Hed38">{reason}</TextV2>
    </Flex>
  );
};
