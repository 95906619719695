import { useMemo, useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import {
  useSavePostOfficeGenericEmailDraftMutation,
  useSavePostOfficeReminderEmailDraftMutation,
  PostOfficeMessageType,
  useSendPostOfficeGenericEmailMutation,
  useSendPostOfficeReminderEmailMutation,
  usePostOfficeDraftsByEventIdQuery,
  useSavePostOfficeThankYouEmailDraftMutation,
  useSendPostOfficeThankYouEmailMutation,
  EventType,
  EventPageType
} from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import { useTranslation } from '@shared/core';
import { useContext } from 'react';
import { getPostOfficeEmailInput, emailStateDefault, getPostOfficeEmailPayload } from './PostOffice.utils';
import { usePostOfficeTelemetry } from '@apps/postOffice/PostOffice.telemetry';
import { PeopleContext } from '@shared/context/people';
import { getPeopleIds } from '@shared/components/EmailsAndEcards/Emails.utils';
import { getFooterTextWithCouplesNames } from '@shared/components/EmailsAndEcards/Emails.utils';
import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useQueryParamHelper } from '@shared/core/queryString';
import useGenerateThankYouNote from './hooks/useGenerateThankYouNote';
import { useToast } from '@withjoy/joykit';
import { pick } from 'lodash-es';
import { getReferrer } from './PostOffice';
import { useEventInfo } from '@shared/utils/eventInfo';

interface HandleSendEmailArgs {
  testEmail?: string;
  testName?: string;
  onCompleted?: () => void;
  onError?: () => void;
}

interface UsePostOfficeControllerArgs
  extends Readonly<{
    eventId: string;
    draftType: PostOfficeMessageType;
    eventDisplayName: string;
    eventHandle: string;
    setIsThankYouMessageLoading: Dispatch<SetStateAction<boolean>>;
  }> {}

const DEFAULT_ASC_TYPE = 'THANK_YOU';

export const usePostOfficeController = (args: UsePostOfficeControllerArgs) => {
  const { toast } = useToast();
  const telemetry = usePostOfficeTelemetry();
  const isMobile = useIsMobileScreen();
  const { setParentViewState } = useBottomSheetState();
  const { getValueString } = useQueryParamHelper();
  const [tone] = useState(getValueString('tone'));
  const [context] = useState(getValueString('context'));
  const [items] = useState(getValueString('items'));
  const [shortenProductNames] = useState(getValueString('shortenProductNames'));
  const [name] = useState(getValueString('name'));
  const [email] = useState(getValueString('email'));
  const [redirect] = useState(getValueString('redirect') || document.referrer);
  const [ascId] = useState(getValueString('ascId'));
  const [ascType] = useState(getValueString('ascType') ?? DEFAULT_ASC_TYPE);

  const { eventId, draftType, eventDisplayName, eventHandle, setIsThankYouMessageLoading } = args;

  const { data, loading: loadingDraftData } = usePostOfficeDraftsByEventIdQuery({
    batchMode: 'off',
    variables: { eventId, messageType: draftType }
  });

  const {
    currentUser: { profile: userProfile }
  } = useAuth();

  const { eventPassword, eventPhotoUrl, isPasswordToggleShown, mostRecentDarft, footerText, pages } = useMemo(() => {
    const eventById = data?.eventById;
    const eventInfo = eventById?.info;
    const privacyMode = eventById?.settings?.privacyMode;
    const pages = eventById?.pages;
    const ownerFirstName = eventInfo?.ownerFirstName;
    const fianceeFirstName = eventInfo?.fianceeFirstName;
    const postOfficeDrafts = data?.getPostOfficeDraftsByEventId;
    const mostRecentDarft = !!postOfficeDrafts && postOfficeDrafts.length ? postOfficeDrafts[0] : undefined;

    return {
      eventPassword: eventInfo?.eventPassword,
      eventPhotoUrl: eventById?.photo?.url,
      isPasswordToggleShown: !!(privacyMode && privacyMode !== 'public'),
      footerText: getFooterTextWithCouplesNames(ownerFirstName, fianceeFirstName, userProfile?.email),
      mostRecentDarft,
      pages
    };
  }, [data?.eventById, data?.getPostOfficeDraftsByEventId, userProfile?.email]);

  const useSavePostOfficeEmailDraftMutation = (draftType: PostOfficeMessageType) => {
    switch (draftType) {
      case PostOfficeMessageType.generic:
        return useSavePostOfficeGenericEmailDraftMutation;
      case PostOfficeMessageType.reminder:
        return useSavePostOfficeReminderEmailDraftMutation;
      case PostOfficeMessageType.thankyou:
        return useSavePostOfficeThankYouEmailDraftMutation;
      default:
        return useSavePostOfficeGenericEmailDraftMutation;
    }
  };

  const [savePostOfficeEmailDraft] = useSavePostOfficeEmailDraftMutation(draftType)({
    onError: err => {
      telemetry.trackError('useSavePostOfficeEmailDraftMutation', err, { label: draftType });
      toast(tEmailEditor.saveDraftError);
    }
  });

  const { t2 } = useTranslation('postOffice');
  const tEmailEditor = t2('emailEditor');
  const { t } = useTranslation('emailsAndEcards');
  const tConfirmAndSend = t('confirmAndSend');

  const { selectedPeople } = useContext(PeopleContext);
  const personIds = useMemo(() => getPeopleIds(selectedPeople), [selectedPeople]);

  const [emailInputState, setEmailInputState] = useState<EmailInputFields>(emailStateDefault);

  const { eventInfo } = useEventInfo();
  useEffect(() => {
    if (!data) return;
    const newEmailInputState = getPostOfficeEmailInput(
      eventId,
      draftType,
      userProfile?.email!,
      isPasswordToggleShown,
      eventDisplayName,
      eventInfo?.eventType === EventType.babyRegistry ? pages?.find(p => p.type === EventPageType.registry)?.photo?.url : eventPhotoUrl,
      mostRecentDarft
    );
    // Thank you email should save only imageUrl, subject and replyToEmail between sessions
    setEmailInputState(state =>
      draftType === 'thankyou' ? { ...state, ...pick(newEmailInputState, ['eventId', 'imageUrl', 'subject', 'replyToEmail', 'assetId', 'containerId']) } : newEmailInputState
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { isGeneratedMessageLoading } = useGenerateThankYouNote({
    tone,
    context,
    name,
    items,
    skip: draftType !== 'thankyou',
    shortenProductNames: shortenProductNames === 'true',
    user: eventInfo?.fianceeFirstName ? `${eventInfo?.ownerFirstName} & ${eventInfo?.fianceeFirstName}` : eventInfo?.ownerFirstName || '',
    hasSecondName: !!eventInfo?.fianceeFirstName,
    onCompleted: note => setEmailInputState(prev => ({ ...prev, message: note }))
  });

  useEffect(() => setIsThankYouMessageLoading(isGeneratedMessageLoading), [isGeneratedMessageLoading, setIsThankYouMessageLoading]);

  const updateEmailInput = useEventCallback(async (emailInput: EmailInputFields) => {
    setEmailInputState(emailInput);
    telemetry.emailSave(draftType);
    try {
      const emailPayload = getPostOfficeEmailPayload(emailInput);
      savePostOfficeEmailDraft({
        variables: {
          ...emailPayload,
          draftId: emailInput.draftId,
          personIds
        }
      });
    } catch (error) {
      telemetry.trackError('savePostOfficeEmailDraftMutation', error);
    }
  });

  const useSendPostOfficeEmailMutation = (draftType: PostOfficeMessageType) => {
    switch (draftType) {
      case PostOfficeMessageType.generic:
        return useSendPostOfficeGenericEmailMutation;
      case PostOfficeMessageType.reminder:
        return useSendPostOfficeReminderEmailMutation;
      case PostOfficeMessageType.thankyou:
        return useSendPostOfficeThankYouEmailMutation;
      default:
        return useSendPostOfficeGenericEmailMutation;
    }
  };
  const [sendPostOfficeEmail] = useSendPostOfficeEmailMutation(draftType)();

  const handleSendEmail = useCallback(
    (args: HandleSendEmailArgs) => {
      const { testEmail, testName, onCompleted, onError } = args;
      const emailPayload = getPostOfficeEmailPayload(emailInputState, footerText, testEmail, testName);
      if (!testEmail && personIds.length === 0 && !email) {
        toast(tConfirmAndSend.noGuestsSelectedErrorMessage());
        telemetry.noGuestsIdsError(getPeopleIds(selectedPeople));
      } else {
        if (testEmail) {
          telemetry.sendTestEmail(draftType, testName!, testEmail);
        } else {
          const adminReferrer = getReferrer();
          telemetry.sendEmail(draftType, { ...emailPayload, selectedGuestsIds: personIds }, adminReferrer);
        }

        let personEmails = {};
        let relationData = {};

        if (draftType === PostOfficeMessageType.thankyou) {
          // We don't want to populate personEmails if this is a test email
          personEmails = { personEmails: !testEmail && email ? [email] : [] };
          // We don't want to create association if this is a test email
          if (!testEmail && ascId) {
            relationData = { relation: { relationshipTag: ascType, externalMessageId: ascId, redirectUri: redirect } };
          }
        }

        sendPostOfficeEmail({
          variables: {
            ...emailPayload,
            personIds: !!testEmail ? [] : personIds,
            ...personEmails,
            ...relationData
          }
        })
          .then(() => {
            !!onCompleted && onCompleted();
          })
          .catch(error => {
            telemetry.trackError('useSendPostOfficeEmailMutation', error, { label: draftType });
            !!onError && onError();
          });
      }
    },
    [toast, draftType, email, emailInputState, footerText, personIds, selectedPeople, sendPostOfficeEmail, tConfirmAndSend, telemetry, ascType, ascId, redirect]
  );

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, handleSendEmail, eventHandle, email, recipientName: name, redirect }));
  }, [handleSendEmail, setParentViewState, eventHandle, email, name, redirect]);

  return {
    handleSendEmail,
    testEmail: userProfile?.email ?? undefined,
    emailIsVerified: !!userProfile?.emailIsVerified,
    isPasswordToggleShown,
    footerText,
    eventPassword,
    loadingDraftData,
    emailInputState,
    updateEmailInput,
    pages,
    isMobile,
    hideGuestSelector: !!email,
    isMessageLoading: isGeneratedMessageLoading,
    redirect,
    recipientName: name
  };
};
