import React, { useState } from 'react';
import { ButtonV2, TextV2 } from '@withjoy/joykit';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { ReactComponent as JoyLogo } from '@assets/icons/logo.svg';
import { ReactComponent as RingsIcon } from '@assets/icons/rings.svg';
import { ReactComponent as GiftsIcon } from '@assets/icons/multiple-gifts.svg';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { StyledDialogContentWrapper, StyledDialogHeaderWrapper, StyledEventOption, StyledPlusIcon } from './CreateEventButton.styles';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useTranslation } from '@shared/core';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import qs from 'query-string';

const deriveCreateRedirectPath = (basePath: string, destination?: string) => {
  return basePath + (destination ? `?${qs.stringify({ destination })}` : '');
};

const CreateEventButton = ({ renderFloating = false, redirectDestination }: { renderFloating?: boolean; redirectDestination?: string }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isMobile = useIsMobileScreen();
  const enableBabyRegistry = useFeatureValue('enableBabyRegistry');
  const { t2 } = useTranslation('account');
  const { createEventButton, newEventOptions, createDialogTitle } = t2('eventsPage');
  const { trackMePageClick } = useAccountMeTelemetry();

  const renderDialogTitle = () => {
    return (
      <StyledDialogHeaderWrapper>
        <JoyLogo width="4rem" height="2.5rem" />
        <TextV2 color="mono14" typographyVariant="hed5" maxWidth="21rem" textAlign="center" marginTop="0.5rem">
          {createDialogTitle}
        </TextV2>
      </StyledDialogHeaderWrapper>
    );
  };

  const renderEventOptions = () => {
    return [
      {
        title: newEventOptions.wedding,
        icon: <RingsIcon height="3rem" width="3rem" fill="#333" />,
        path: deriveCreateRedirectPath('/createwedding', redirectDestination),
        trackFn: () => trackMePageClick('CreateWedding', 'CreateEvent')
      },
      {
        title: newEventOptions.baby,
        icon: <GiftsIcon height="3rem" width="3rem" />,
        path: deriveCreateRedirectPath('/createbabyregistry', redirectDestination),
        trackFn: () => trackMePageClick('CreateBaby', 'CreateEvent')
      }
    ].map(({ icon, title, path, trackFn }) => (
      <StyledEventOption
        tabIndex={0}
        key={title}
        onClick={() => {
          trackFn();
          window.location.href = path;
        }}
      >
        {icon}
        <TextV2 typographyVariant="button2">{title}</TextV2>
      </StyledEventOption>
    ));
  };

  return (
    <>
      <ButtonV2
        onClick={() => {
          if (enableBabyRegistry) {
            setIsDialogOpen(true);
          } else {
            trackMePageClick('CreateWedding', 'CreateEvent');
            window.location.href = '/createwedding';
          }
        }}
        intent="neutral"
        variant="solid"
        startIcon={<StyledPlusIcon />}
        {...(renderFloating
          ? {
              shape: 'rounded',
              position: 'fixed',
              bottom: '2rem',
              left: '50%',
              transform: 'translateX(-50%)'
            }
          : {})}
      >
        {createEventButton.value}
      </ButtonV2>
      {enableBabyRegistry && (
        <ResponsiveDialog
          hideDivider
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          renderTitle={renderDialogTitle}
          dialogOverrides={{
            Header: {
              props: {
                paddingBottom: '0 !important'
              }
            },
            Content: {
              props: { width: '33.75rem' }
            },
            Body: {
              props: { paddingBottom: isMobile ? '2rem' : '3.5rem !important' }
            }
          }}
        >
          <StyledDialogContentWrapper>{renderEventOptions()}</StyledDialogContentWrapper>
        </ResponsiveDialog>
      )}
    </>
  );
};

export default CreateEventButton;
