import React, { useEffect } from 'react';
import { ButtonV2, Flex } from '@withjoy/joykit';
import { useConfirmController } from './Confirm.controller';
import { ConfirmContainer, ConfirmWrapper, StyledActionWrapper, StyledTitle, StyledBodyTitle, StyledBody, styles } from './Confirm.styles';
import ConfirmationScreen from './components/ConfirmationScreen';
import SendTestEmailDialog from './components/SendTestEmailDailog';
import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import ShareableDialog from '@shared/components/EmailsAndEcards/components/Confirm/components/ShareableDialog';
import { Copy } from '@withjoy/joykit/icons';
import useHandleCopyShareableLink from '@shared/components/EmailsAndEcards/components/Confirm/components/ShareableDialog/useHandleCopyShareableLink';

export const Confirm: React.FC = () => {
  const {
    eventHandle,
    testEmail,
    confirmAndSendActions,
    tConfirmAndSend,
    isConfirmationScreenShown,
    selectedGuestsCount,
    handleSendTestEmail,
    handleSendTestEmailDialogClose,
    isSendTestEmailDialogOpen,
    copyShareableLink,
    shareableDialog,
    shareableLinksEnabled
  } = useConfirmController();

  const { confirmAndSendTrack, aboutShareableLinksClick } = useSaveTheDateTelemetry();
  const handleCopyButtonClick = useHandleCopyShareableLink({ copy: copyShareableLink, openDialog: shareableDialog.onOpen });

  useEffect(() => {
    confirmAndSendTrack.enter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmContainer alignItems="center" justifyContent="center" __css={styles.confirmContainer}>
      {isConfirmationScreenShown ? (
        <ConfirmationScreen eventHandle={eventHandle} selectedGuestsCount={selectedGuestsCount} />
      ) : (
        <ConfirmWrapper flexDirection="column" __css={styles.confirmWrapper}>
          {!shareableLinksEnabled && (
            <>
              <StyledTitle typographyVariant="hed5">{tConfirmAndSend.header()}</StyledTitle>
              <StyledBody marginBottom={4} typographyVariant="body1">
                {tConfirmAndSend.body()}
              </StyledBody>
            </>
          )}
          {shareableLinksEnabled && (
            <Flex flexDirection="column" alignItems="center">
              <img src="https://withjoy.com/assets/public/social_icons.png" alt="Social" style={{ height: '24px', width: '184px' }} />
              <StyledTitle marginTop={5} typographyVariant="hed5" textAlign="center">
                {tConfirmAndSend.shareTitle()}
              </StyledTitle>
              <StyledBody marginBottom={6} marginTop={3} typographyVariant="body1" textAlign="center">
                {tConfirmAndSend.shareBody()}
              </StyledBody>
              <ButtonV2 onClick={handleCopyButtonClick} marginBottom={8} marginX="auto" maxWidth="212px" width="100%" variant="outline">
                <Copy marginRight={3} />
                {tConfirmAndSend.shareAction()}
              </ButtonV2>
              <StyledTitle typographyVariant="hed5" textAlign="center">
                {tConfirmAndSend.emailHeader()}
              </StyledTitle>
              <StyledBody marginBottom={6} marginTop={3} marginX="auto" typographyVariant="body1" textAlign="center" maxWidth="490px">
                {tConfirmAndSend.emailBody()}
              </StyledBody>
              <ShareableDialog
                isOpen={shareableDialog.isOpen}
                onClose={shareableDialog.onClose}
                onCopy={copyShareableLink}
                isCopying={false}
                onCancelClick={() => aboutShareableLinksClick('Cancel')}
                onConfirmClick={() => aboutShareableLinksClick('I Understand')}
              />
            </Flex>
          )}
          {confirmAndSendActions.filter(notNullOrUndefined).map(action => (
            <StyledActionWrapper key={action.label} alignItems="center" justifyContent="space-between" __css={styles.actionWrapper}>
              <Flex>
                {action.icon}
                <Flex flexDirection="column" marginLeft={6}>
                  <StyledBodyTitle marginBottom={0} typographyVariant="hed2">
                    {action.hed}
                  </StyledBodyTitle>
                  <StyledBody typographyVariant="body1">{action.dek}</StyledBody>
                </Flex>
              </Flex>
              {action.button}
            </StyledActionWrapper>
          ))}
        </ConfirmWrapper>
      )}
      <SendTestEmailDialog isOpen={isSendTestEmailDialogOpen} onClose={handleSendTestEmailDialogClose} handleSendTestEmail={handleSendTestEmail} testEmail={testEmail} />
    </ConfirmContainer>
  );
};
