import { styled, TextV2 } from '@withjoy/joykit';
import { promptbar } from '../ImagineYourCardRoot/ImagineYourCardRoot.styles';

export const PromptHintText = styled.div<{ mobile?: boolean }>`
  font-size: ${props => (props.mobile ? '16px' : '17px')};
  z-index: 1000;
  position: absolute;
  top: calc(${promptbar.height} / 2 - 1.5rem / 2 + 2px);
  left: 30px;
  margin-top: auto;
  margin-bottom: auto;

  color: #000000;
`;

export const FadeChar = styled(TextV2)<{ isSpace?: boolean; show?: boolean; fadeOut?: boolean }>`
  opacity: 0;
  ${props => props.show && 'animation: textFadeIn 250ms;'}
  ${props => props.fadeOut && 'animation: textFadeOut 250ms;'}
  animation-fill-mode: forwards;

  ${props => props.isSpace && 'min-width: 0.2rem;'}

  @keyframes textFadeIn {
    0% {
      opacity: 0;
      scale: 1;
    }
    50% {
      opacity: 0.5;
      scale: 1.2;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }

  @keyframes textFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
