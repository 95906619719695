import { useMemo, useCallback } from 'react';
import { useTranslation } from '@shared/core/i18n';
import { EcardType } from '@graphql/generated';

interface UseEcardTranslationsArgs
  extends Readonly<{
    ecardDraftType: EcardType;
  }> {}

export interface DefaultTranslations
  extends Readonly<{
    ecardContent: {
      title: string;
      subtitle: string;
      message: string;
      additionalMessage: string;
    };
    emailContent: {
      headline: string;
      message: string;
      subject: string;
      subheadline: string;
      salutation: string;
      buttonText: string;
    };
    ecardLanding: {
      title: string;
    };
  }> {}

export const useEcardTranslations = (args: UseEcardTranslationsArgs) => {
  const { ecardDraftType } = args;
  const { t } = useTranslation('emailsAndEcards');
  const tEcardDefaults = t('ecardDefaults', ecardDraftType);
  const tEcardContent = tEcardDefaults['ecardContent'];
  const tEmailContent = tEcardDefaults['emailContent'];
  const tEcardLanding = tEcardDefaults['ecardLanding'];
  const tEcardNavBar = t('ecardNavBar');

  const getEcardContentTranslations = useCallback(
    (eventDisplayName: string) => {
      return {
        title: tEcardContent.title({ eventDisplayName }),
        subtitle: tEcardContent.subtitle({ eventDisplayName }),
        message: tEcardContent.message(),
        additionalMessage: tEcardContent.message()
      };
    },
    [tEcardContent]
  );

  const getEcardDefaultTranslations: (eventDisplayName: string) => DefaultTranslations = useCallback(
    (eventDisplayName: string) => {
      return {
        ecardContent: {
          ...getEcardContentTranslations(eventDisplayName)
        },
        emailContent: {
          headline: tEmailContent.headline({ eventDisplayName }),
          message: tEmailContent.message(),
          subject: tEmailContent.subject(),
          subheadline: tEmailContent.subheadline(),
          salutation: tEmailContent.salutation(),
          buttonText: tEmailContent.buttonText()
        },
        ecardLanding: {
          title: tEcardLanding.title()
        }
      };
    },
    [tEcardLanding, tEmailContent, getEcardContentTranslations]
  );

  const ecardNavBarTranslations = useMemo(() => {
    return {
      design: tEcardNavBar.design(),
      email: tEcardNavBar.email(),
      confirm: tEcardNavBar.confirm()
    };
  }, [tEcardNavBar]);

  return {
    getEcardDefaultTranslations,
    ecardNavBarTranslations,
    getEcardContentTranslations
  };
};
