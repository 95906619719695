import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './SimpleRetroDark.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('simpleRetroDark')?.assetUrl || '';

export const SimpleRetroDark: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={89.5} left={89.5} width={321} height={421} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex width="100%" height="190px" padding="30px 90px" flexDirection="column" alignItems="flex-end" justifyContent="center">
          <TextV2 color="white" textAlign="right" fontSize={14} fontFamily="Quicksand" fontWeight={700} lineHeight="21px" letterSpacing="0.2em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName ? ' & ' : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="flex-end">
              {formattedDate ? (
                <TextV2 color="white" textAlign="right" fontSize={12} fontFamily="Quicksand" fontWeight={400} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="white" textAlign="right" fontSize={12} fontFamily="Quicksand" fontWeight={400} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="white" textAlign="right" fontSize={12} fontFamily="Quicksand" fontWeight={400} lineHeight="15px" letterSpacing="0.2em" textTransform="uppercase">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
