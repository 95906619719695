import { DrawerV1, styled } from '@withjoy/joykit';
import { StyledItemWrapper, StyledListHeader, StyledListWrapper } from '../../../../components/SelectList/SelectList.styles';

export const StyledDrawer = styled(DrawerV1)`
  display: flex;
  flex-direction: column;
  top: 30px;
  height: auto;
  ${StyledListHeader} {
    display: none;
  }
  ${StyledListWrapper} {
    margin-top: 1rem;
  }
  ${StyledItemWrapper} {
    padding-left: 1.5rem;
  }
`;

export const SelectListWrapper = styled.div`
  overflow: scroll;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  height: 64px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px ${props => props.theme.colors.mono3} solid;
`;
export const DragIconWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const HeaderTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #333333;
`;
