import React from 'react';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { DesktopView } from './components/MarcomNavBar';
import { useAppNavBar } from './useMarcomAppNavBar';

export const MarcomAppNavBar = () => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const appNavBarProps = useAppNavBar({ isMobile: isMobileOrTablet });

  return <DesktopView {...appNavBarProps} />;
};
