import React from 'react';
import { TextV2, Flex, Divider, ButtonV2, IconV2, Box, TooltipV2 } from '@withjoy/joykit';
import { Info } from '@withjoy/joykit/icons';
import { EcardType } from '@graphql/generated';
import { withWindow } from '@shared/utils/withWindow';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { useTranslation } from '@shared/core';
import { EcardDesignInputFields } from '../../../../../Design/Design.controller';
import { PaperProviderEnum } from '@apps/ecard/Ecard.types';

interface MatchingStationeryProps
  extends Readonly<{
    ecardInput: EcardDesignInputFields;
  }> {}

export const MatchingStationery: React.FC<MatchingStationeryProps> = ({ ecardInput }) => {
  const { t } = useTranslation('emailsAndEcards');
  const tEcardLandingLeftPane = t('ecardLanding', 'ecardLandingLeftPane');
  const telemetry = useEcardsTelemetry();
  const printProvider = ecardInput?.joyTheme?.printProviders?.[0];
  const printProviderName = printProvider?.provider?.name;
  const printImageUrl = printProvider?.stockPreviewUrl;

  if (!printProviderName || (printProviderName !== PaperProviderEnum.paperlust && printProviderName !== PaperProviderEnum.paperculture)) return null;

  const handleOnClick = () => {
    withWindow(() => {
      if (ecardInput?.joyTheme?.printProviders?.length) {
        telemetry.viewMatchingPrintsFromVendor(EcardType.savethedate, ecardInput.joyTheme.themeId);
        window.open(ecardInput.joyTheme.printProviders[0].printUrl);
      }
    });
  };

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" width={['calc(100% - 48px)', '100%']}>
      <Divider marginY="48px" width={['calc(100% + 48px)', '720px']} />
      {printImageUrl && (
        <Box marginBottom="16px">
          <img width="224px" alt="stationary" src={printImageUrl} />
        </Box>
      )}
      <Flex alignItems="center" justifyContent="center">
        <TextV2 typographyVariant="hed3" marginRight="5px" textAlign="center">
          {tEcardLandingLeftPane.printsLandingTitle()}
        </TextV2>
        <TooltipV2
          overrides={{ Content: { props: { marginRight: ['5px', 0] } } }}
          content={
            <Box maxWidth="128px">
              <TextV2 typographyVariant="label2" tagName="p" textAlign="center">
                {printProviderName === PaperProviderEnum.paperlust ? tEcardLandingLeftPane.printsPaperlustTooltipText() : tEcardLandingLeftPane.printsPaperCultureTooltipText()}
              </TextV2>
            </Box>
          }
          placement="top"
          onMouseEnterDelay={500}
        >
          <IconV2 size="sm">
            <Info />
          </IconV2>
        </TooltipV2>
      </Flex>
      <TextV2 typographyVariant="body2" maxWidth="480px" textAlign="center" marginTop="8px" marginBottom="32px">
        {printProviderName === PaperProviderEnum.paperlust
          ? `${tEcardLandingLeftPane.printsLandingDesc()} ${tEcardLandingLeftPane.paperlustDesc()}`
          : `${tEcardLandingLeftPane.printsLandingDesc()} ${tEcardLandingLeftPane.paperCultureDesc()}`}
      </TextV2>
      <ButtonV2 width={['100%', 'auto']} variant="outline" onClick={handleOnClick}>
        {tEcardLandingLeftPane.printsButtonText()}
      </ButtonV2>
    </Flex>
  );
};
