import React, { useState } from 'react';
import { FormContainer, FormSideContainer } from './AccountForm.styles';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import CreateAccount from './CreateAccount/CreateAccount';
import SignIn from './LogIn/SignIn';
import { SupportedView } from './AccountForm.types';
import { usePublicCardShopTelemetry } from '@apps/card/PublicShop/PublicShop.telemetry';
import ForgotPassword from './ForgotPassword/ForgotPassword';

const PageMap = {
  createAccount: CreateAccount,
  signIn: SignIn,
  forgotPassword: ForgotPassword
};

export const AccountForm = () => {
  const mobile = useIsMobileOrTablet();
  const [page, setPage] = useState<SupportedView>('createAccount');
  const PageElement = PageMap[page];

  usePublicCardShopTelemetry({ page: page });

  return (
    <FormSideContainer mobile={mobile}>
      <FormContainer>
        <PageElement switchForm={setPage} />
      </FormContainer>
    </FormSideContainer>
  );
};
