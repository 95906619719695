import { useDeleteUserMutation } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import { useState, useEffect, useCallback } from 'react';

interface Args
  extends Readonly<{
    onClose: () => void;
  }> {}

export const useDeleteUserController = (args: Args) => {
  const { onClose } = args;
  const [deleteUser, { loading, error, called }] = useDeleteUserMutation();
  const { provideLogout } = useAuth();
  const [text, setText] = useState<string>('');

  const handleTextUpdate: React.ChangeEventHandler<HTMLInputElement> = event => {
    const text = event.target.value;
    setText(text || '');
  };

  const handleConfirmDelete = () => {
    deleteUser();
  };

  const logout = useCallback(async () => {
    await provideLogout({});
  }, [provideLogout]);

  useEffect(() => {
    if (error?.message) {
      setText('');
      return;
    }
    if (called && !loading && !error) {
      onClose();
      logout();
      return;
    }
  }, [error, called, loading, onClose, logout]);
  return {
    text,
    handleTextUpdate,
    handleConfirmDelete,
    loading,
    error
  };
};
