import { styled, TextV2 } from '@withjoy/joykit';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';

export const StyledImageBackground = styled.div<{ backgroundImage: string }>`
  width: 100%;
  height: 100%;
  background-image: ${props => backgroundImageForUrl(props.backgroundImage)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;

export const StyledBorderBackground = styled.div<{ backgroundImage: string; isAdjustingPosition: boolean }>`
  width: 100%;
  height: 100%;
  background-image: ${props => backgroundImageForUrl(props.backgroundImage)};
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${({ isAdjustingPosition }) => (isAdjustingPosition ? 'none' : 'initial')};
`;

export const StyledTitleBackground = styled.div<{ backgroundImage: string }>`
  width: 100%;
  background: ${props => backgroundImageForUrl(props.backgroundImage)}, linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-size: 80%, 100%;
  background-position: center top, center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

export const StyledDetailsText = styled(TextV2)`
  pointer-events: none;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-family: inherit;
  text-align: center;
  color: inherit;
  &:not(:last-of-type) {
    margin-bottom: 9px;
  }
`;
