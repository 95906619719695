import React from 'react';
import { IconV2, TooltipV2 } from '@withjoy/joykit';
import { Home } from '@withjoy/joykit/icons';
import { HeaderBar, HeaderBarText } from './Header.styles';
import { ReactComponent as ChevronLeft } from '@assets/icons/back-chevron.svg';

interface HeaderProps {
  callback: () => void;
  title: string;
  toDashboard: boolean;
}

export const Header = React.forwardRef<HTMLDivElement, HeaderProps & React.HTMLAttributes<HTMLDivElement>>(({ callback, title, toDashboard }, ref) => {
  return (
    <HeaderBar alignItems="center" onClick={callback} ref={ref}>
      <TooltipV2 content={`Back ${toDashboard ? 'to Dashboard' : ''}`} placement="bottom" onMouseEnterDelay={500}>
        <IconV2 color="mono13" size="md">
          {toDashboard ? <Home /> : <ChevronLeft />}
        </IconV2>
      </TooltipV2>
      <HeaderBarText>{title}</HeaderBarText>
    </HeaderBar>
  );
});
