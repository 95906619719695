import React from 'react';
import { BorderProps, BorderRadiusProps } from 'styled-system';
import { ResponsiveSpaceProps, ResponsiveValue, ResponsiveFlexProps, ResponsiveOrderProps } from './styledSystem';
import type { Property } from 'csstype';

export type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;

/**
 * Interface for shorthand margin props
 *
 * Useful for applying contextual spacing.
 */
export interface SpacingExternalProps extends Pick<ResponsiveSpaceProps, 'margin' | 'marginTop' | 'marginRight' | 'marginBottom' | 'marginLeft' | 'marginX' | 'marginY'> {}
export interface SpacingInternalProps extends Pick<ResponsiveSpaceProps, 'padding' | 'paddingTop' | 'paddingRight' | 'paddingBottom' | 'paddingLeft' | 'paddingX' | 'paddingY'> {}

export interface Props {
  className?: string;
}

export interface BaseComponentProps extends Props, SpacingExternalProps, ResponsiveFlexProps, ResponsiveOrderProps {
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.
   */
  alignSelf?: ResponsiveValue<Property.AlignSelf>;
}

export const Intent = {
  PRIMARY: 'primary' as 'primary',
  SUCCESS: 'success' as 'success',
  DANGER: 'danger' as 'danger',
  INFO: 'info' as 'info'
};

export type Intent = typeof Intent[keyof typeof Intent];

export interface BaseControlProps<T> {
  grouped?: boolean;
  /** Whether the control is non-interactive */
  disabled?: boolean;

  onChange?: React.ChangeEventHandler<T>;
  onBlur?: React.FocusEventHandler<T>;
  onFocus?: React.FocusEventHandler<T>;

  /** Disable editing of the input */
  readonly?: boolean;

  // value?: any;
}

export interface LabelledProps {
  /**
   * Label associated with the form control.
   */
  label: string;
  /**
   * Use `labelInfo` to provide additional information that is secondary to the label.
   */
  labelInfo?: string;
  /**
   * If the intent of a FormField is obvious from context and a label would negatively affect the design,
   * you can use `hideLabel` to visually hide the label while retaining accessibility for screen readers.
   */
  hideLabel?: boolean;
}

/**
 * Interface for a component whose internal and external properties
 * depend on context.
 *
 * These props are useful for applying consistent values multiple components.
 *
 * @example applying a border of none to grouped controls
 */
export interface ComposedProps extends SpacingExternalProps, BorderProps, BorderRadiusProps {}
