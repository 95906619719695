import { Box, BoxProps } from '@withjoy/joykit';
import React from 'react';
import { useGalleryGridStyle } from '../DesignsGallery/useGalleryGridStyle';

interface GalleryProps {
  children: React.ReactNode;
  columns?: BoxProps['gridTemplateColumns'];
}

const DEFAULT_RESPONSIVE_COLUMNS: GalleryProps['columns'] = {
  _: 'repeat(2, 1fr)',
  sm2: 'repeat(3, 1fr)',
  md3: 'repeat(4, 1fr)',
  lg: 'repeat(5, 1fr)',
  xl: 'repeat(6, 1fr)'
};

export const Gallery = (props: GalleryProps) => {
  const { children, columns = DEFAULT_RESPONSIVE_COLUMNS } = props;

  const { galleryGap } = useGalleryGridStyle();

  return (
    <Box display="grid" maxWidth="100%" gridTemplateColumns={columns} alignItems="start" columnGap={galleryGap ?? 3} rowGap="3px" paddingBottom={8}>
      {children}
    </Box>
  );
};
