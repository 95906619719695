import { AIBackgroundPromptPayload, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { CustomizeTemplateData } from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { getLocalStorage } from '@shared/core';

const LOCAL_STORAGE_KEY = 'ReplicateGenHistory';
const LOCAL_STORAGE_STASH_KEY = 'ReplicateGenStash';
const LOCAL_STORAGE_DEMO_KEY = 'HasPlayedDemo';
const IMAGE_EXPIRATION_TIME_MS = 1000 * 60 * 60;

type addImageToStorageProps = {
  url: string;
  themeID: string;
  category: StationeryTemplateCategoryEnum;
  promptPayload: AIBackgroundPromptPayload | undefined;
  uploadTime?: number | undefined;
  demo?: boolean | undefined;
  resourceID?: string;
};

const stashCardTemplate = (template: CustomizeTemplateData) => {
  const ls = getLocalStorage();
  const data = JSON.stringify(template);
  ls.setItem(LOCAL_STORAGE_STASH_KEY, data);
};

const checkDemo = () => {
  const ls = getLocalStorage();
  const data = ls.getItem(LOCAL_STORAGE_DEMO_KEY);

  if (data === 'true') {
    return true;
  } else {
    ls.setItem(LOCAL_STORAGE_DEMO_KEY, 'true');
    return false;
  }
};

const isTemplate = (value: unknown): value is CustomizeTemplateData => {
  if (!value || !value.hasOwnProperty('template') || !value.hasOwnProperty('format')) {
    return false;
  }

  const { format, template } = value as CustomizeTemplateData;

  switch (format) {
    case 'digital': {
      if (!template.themeId) {
        return false;
      }
      break;
    }

    case 'paper':
    case 'premiumDigital': {
      return ['themeId', 'category', 'themeJSON'].every(key => template.hasOwnProperty(key));
    }

    case 'concierge': {
      return ['id', 'shortName', 'longName', 'identifier', 'thumbnailUrl'].every(key => template.hasOwnProperty(key));
    }

    default:
      return false;
  }
  return true;
};

const fetchStashedTemplate = (): CustomizeTemplateData | undefined => {
  const ls = getLocalStorage();
  const data = ls.getItem(LOCAL_STORAGE_STASH_KEY);

  if (data) {
    const json = JSON.parse(data);
    if (isTemplate(json)) {
      return json;
    }
  }

  return undefined;
};

const purgeExpiredImages = () => {
  const ls = getLocalStorage();

  const data = ls.getItem(LOCAL_STORAGE_KEY);
  let dataJSON;
  if (!data) {
    dataJSON = [];
  } else {
    dataJSON = JSON.parse(data);
  }

  for (let i = 0; i < dataJSON.length; i++) {
    const image = dataJSON[i];
    if (image.uploadTime) {
      const diff = Date.now() - image.uploadTime;
      if (diff > IMAGE_EXPIRATION_TIME_MS || !image.url) {
        dataJSON.splice(i--, 1);
      }
    }

    if (image.url === 'PLACEHOLDER' || image.themeID === 'PLACEHOLDER') {
      dataJSON.splice(i--, 1);
    }
  }

  const dataStringified = JSON.stringify(dataJSON);
  ls.setItem(LOCAL_STORAGE_KEY, dataStringified);
};

const getCount = (): number => {
  const ls = getLocalStorage();
  const data = ls.getItem(LOCAL_STORAGE_KEY);
  let dataJSON;
  if (!data) {
    dataJSON = [];
  } else {
    dataJSON = JSON.parse(data);
  }

  return dataJSON.length;
};

const reserveImageIndex = (): number => {
  const ls = getLocalStorage();
  const data = ls.getItem(LOCAL_STORAGE_KEY);
  let dataJSON;
  if (!data) {
    dataJSON = [];
  } else {
    dataJSON = JSON.parse(data);
  }
  dataJSON.push({
    url: 'PLACEHOLDER',
    themeID: 'PLACEHOLDER',
    category: StationeryTemplateCategoryEnum.saveTheDate,
    promptPayload: undefined,
    uploadTime: undefined
  });
  const dataStringified = JSON.stringify(dataJSON);
  ls.setItem(LOCAL_STORAGE_KEY, dataStringified);

  return dataJSON.length - 1;
};

const resolvePlaceholderIndex = (index: number, entry: addImageToStorageProps) => {
  const ls = getLocalStorage();
  const data = ls.getItem(LOCAL_STORAGE_KEY);
  if (data) {
    const dataJSON = JSON.parse(data) as addImageToStorageProps[];
    dataJSON[index] = entry;
    dataJSON[index].uploadTime = Date.now();
    const dataStringified = JSON.stringify(dataJSON);
    ls.setItem(LOCAL_STORAGE_KEY, dataStringified);
  }
};

const addImageToStorage = ({ url, themeID, category, promptPayload, uploadTime, demo, resourceID }: addImageToStorageProps) => {
  const ls = getLocalStorage();
  const entry = {
    url,
    themeID,
    category,
    promptPayload,
    uploadTime,
    demo,
    resourceID
  };

  const data = ls.getItem(LOCAL_STORAGE_KEY);

  if (data && entry.url) {
    const dataJSON = JSON.parse(data) as addImageToStorageProps[];
    dataJSON.push(entry);
    const dataStringified = JSON.stringify(dataJSON);
    ls.setItem(LOCAL_STORAGE_KEY, dataStringified);
  } else {
    const dataArray = new Array<addImageToStorageProps>();
    dataArray.push(entry);
    const dataStringified = JSON.stringify(dataArray);
    ls.setItem(LOCAL_STORAGE_KEY, dataStringified);
  }
};

const getImagesFromStorage = () => {
  const ls = getLocalStorage();
  purgeExpiredImages();
  const data = ls.getItem(LOCAL_STORAGE_KEY);
  if (data) {
    const dataJSON = JSON.parse(data) as addImageToStorageProps[];

    return dataJSON;
  } else {
    return null;
  }
};

export { checkDemo, getCount, addImageToStorage, getImagesFromStorage, reserveImageIndex, resolvePlaceholderIndex, stashCardTemplate, fetchStashedTemplate };
