import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';

export const darkButtonOverride: ButtonOverrides = {
  Root: {
    props: {
      color: 'white',
      backgroundColor: 'mono14',
      _hover: {
        backgroundColor: 'mono12'
      },
      _active: {
        backgroundColor: 'mono13'
      }
    }
  }
};

export const outlinedDarkButtonOverrides: ButtonOverrides = {
  Root: {
    props: {
      color: 'mono14',
      borderColor: 'mono14',
      backgroundColor: 'white',
      _hover: {
        color: 'mono14',
        borderColor: 'mono14',
        backgroundColor: 'white'
      },
      _active: {
        color: 'mono14',
        borderColor: 'mono14',
        backgroundColor: 'white'
      }
    }
  }
};
