import { Box, styled, StyleSystemProps } from '@withjoy/joykit';
import { animationTransition, animationTransitionExt } from '@shared/utils/animationTransition';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

export const StyledArrowButton = styled(Box)<{ $dir: 'left' | 'right'; $hideArrows: boolean }>`
  grid-area: ${({ $dir }) => `${$dir}Arrow`};
  opacity: ${({ $hideArrows }) => ($hideArrows ? 0 : 1)};
`;

export const CarouselContainer = styled(Box)`
  @media (hover: hover) {
    &:hover {
      ${StyledArrowButton} {
        opacity: 1;
      }
    }
  }
`;

export const ItemsContainer = styled(Box)``;

const arrowButtonStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: 'mono2',
  color: 'mono14',
  padding: 4,
  borderRadius: '50%',
  boxShadow: '0px 10px 27px -5px rgba(44, 41, 37, 0.25), 0px 7px 16px -8px rgba(0, 0, 0, 0.3);',
  transition: animationTransition('all'),
  _hover: {
    backgroundColor: 'mono3'
  },
  _active: {
    backgroundColor: '#ffffff'
  }
};

const dotsContainerStyles: StyleSystemProps = {
  marginTop: 6,
  display: 'flex',
  justifyContent: 'center',
  columnGap: 3
};

const carouselContainerStyles: StyleSystemProps = {
  position: 'relative',
  flexDirection: 'row',
  width: '100%',
  display: 'flex',
  alignItems: 'center'
};

const dotButtonStyles = (isActive: boolean): StyleSystemProps => ({
  size: 3,
  borderRadius: '50%',
  cursor: isActive ? 'default' : 'pointer',
  backgroundColor: isActive ? 'mono14' : 'mono5'
});

const itemsListStyles = (containerEdgeSize: number): CSSObject => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  width: '100%',
  paddingX: containerEdgeSize,
  transition: animationTransitionExt({ property: 'transform', duration: '0.75s', timingFunction: 'ease' })
});

export const styles = {
  carouselContainer: carouselContainerStyles,
  itemsList: itemsListStyles,
  arrowButton: arrowButtonStyles,
  dotsContainer: dotsContainerStyles,
  dotButton: dotButtonStyles
};
