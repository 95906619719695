import React from 'react';
import { FC, createContext, useContext } from 'react';
import { Updater, useImmer } from 'use-immer';

export function createImmerContext<T>(createDefaultState: () => T) {
  const ImmerContext = createContext<[T, Updater<T>]>([createDefaultState(), () => {}]);

  const ImmerProvider: FC = ({ children }) => {
    const stateAndUpdate = useImmer<T>(createDefaultState());
    return <ImmerContext.Provider value={stateAndUpdate}>{children}</ImmerContext.Provider>;
  };

  const useImmerContext = () => {
    return useContext(ImmerContext);
  };

  return [ImmerProvider, useImmerContext] as const;
}
