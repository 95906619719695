import React from 'react';
import { useForgotPasswordController } from './ForgotPassword.controller';
import { SignInContent, CenteredStack } from '../CommonStyles';
import { TextV1, Stack, ButtonV1, FormField, InputV1, Flex, LinkV1 } from '@withjoy/joykit';
import { TermsAndPrivacyAgreement } from '../TermsAndPrivacyAgreement';
import { useTranslation } from '@shared/core';

interface Props extends Readonly<{}> {}

export const ForgotPassword: React.FC<Props> = () => {
  const { formik } = useForgotPasswordController();
  const { t2 } = useTranslation('account');
  const tSignin = t2('signin');
  const tForgotpassword = t2('forgotpassword');
  const tSignUp = t2('signup');

  return (
    <SignInContent>
      <CenteredStack spacing={5} margin={6}>
        <TextV1 component="h2" variant="hed6">
          {tForgotpassword.forgotPassword}
        </TextV1>
        <div>
          {tSignin.donthaveaccount} <LinkV1>{tSignUp.signup}</LinkV1>
        </div>
      </CenteredStack>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6} margin={10}>
          <FormField label="" error={formik.errors.email && formik.touched.email ? formik.errors.email : undefined}>
            <InputV1 placeholder={tSignin.enterEmail} {...formik.getFieldProps('email')} iconRight="email" />
          </FormField>
          <Stack spacing={5}>
            <Flex justifyContent="center">
              <ButtonV1 fill={true} {...{ type: 'submit' }}>
                {tForgotpassword.sendPasswordReset}
              </ButtonV1>
            </Flex>
            <TermsAndPrivacyAgreement />
          </Stack>
        </Stack>
      </form>
    </SignInContent>
  );
};
