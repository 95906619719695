import { Layout, SidePanelProps } from '@apps/card/components/Layout';
import React, { useEffect, useRef } from 'react';
import { useEditingMode } from '../useEditingMode';
import TextRevisionMessage from './TextRevisionMessage';

interface CustomizerLayoutSidePanelProps {
  allowOrderEdit?: boolean;
  hideTextRevision?: boolean;
}

const CustomizerLayoutSidePanel: React.FC<React.PropsWithChildren<CustomizerLayoutSidePanelProps & SidePanelProps>> = ({
  children,
  allowOrderEdit,
  hideTextRevision,
  ...sidePanelProps
}) => {
  const { mode } = useEditingMode();
  const sidePanelRef = useRef<HTMLDivElement>(null);

  const canEdit = mode === 'draft' || allowOrderEdit;

  useEffect(() => {
    const sidePanelElement = sidePanelRef.current;
    if (!sidePanelElement || canEdit) return;

    const observerCallback: MutationCallback = () => {
      if (sidePanelElement.style.pointerEvents !== 'none') {
        sidePanelElement.style.pointerEvents = 'none';
      }

      if (sidePanelElement.style.userSelect !== 'none') {
        sidePanelElement.style.userSelect = 'none';
      }
    };

    const observer = new MutationObserver(observerCallback);

    observer.observe(sidePanelElement, { attributes: true });

    sidePanelElement.style.pointerEvents = 'none';
    sidePanelElement.style.userSelect = 'none';

    return () => {
      observer.disconnect();
    };
  }, [canEdit]);

  const allowEdit = allowOrderEdit || mode === 'draft';

  return (
    <Layout.SidePanel {...sidePanelProps} overflowY={!canEdit ? 'hidden' : undefined} ref={sidePanelRef}>
      {children}
      {!hideTextRevision && !allowEdit && <TextRevisionMessage />}
    </Layout.SidePanel>
  );
};

export default CustomizerLayoutSidePanel;
