/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Print Card',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path d="M7.598 16.78a.6.6 0 0 1 0-1.2h8.793a.6.6 0 0 1 0 1.2H7.598zm2.199 2.088a.6.6 0 0 1 0-1.2h4.397a.6.6 0 0 1 0 1.2H9.797z" />
          <path
            d="M7.201 8.023a3.46 3.46 0 0 0 2.776 3.727 3.46 3.46 0 1 0 4.037-4.19 3.46 3.46 0 0 0-6.812.463zm5.554-.478a3.46 3.46 0 0 0-2.839 2.949 2.26 2.26 0 1 1 2.839-2.949zm.373 5.659a2.26 2.26 0 0 1-1.893-1.44 3.46 3.46 0 0 0 2.839-2.949 2.26 2.26 0 0 1-.946 4.389zm-.258-4.459a2.26 2.26 0 0 0-1.751 1.82 2.26 2.26 0 0 0 1.751-1.82zM3.995 3.941A1.36 1.36 0 0 1 5.356 2.58h13.288a1.36 1.36 0 0 1 1.361 1.361V20.06a1.36 1.36 0 0 1-1.361 1.361H5.356a1.36 1.36 0 0 1-1.361-1.361V3.941zm1.361-.161c-.089 0-.161.072-.161.161V20.06c0 .089.072.161.161.161h13.288c.089 0 .161-.072.161-.161V3.941c0-.089-.072-.161-.161-.161H5.356z"
            fillRule="evenodd"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path d="M18.609 5.772a.6.6 0 1 0-1.149-.346l-.264.877a.6.6 0 1 0 1.149.346l.264-.877z" />
          <path
            fillRule="evenodd"
            d="M13.651 14.77a3.88 3.88 0 0 1 .951-7.699c1.77.171 3.147 1.505 3.447 3.172a3.88 3.88 0 0 1-.951 7.699c-1.77-.171-3.147-1.505-3.447-3.172zm-.024-1.225c.236-1.706 1.56-3.015 3.196-3.291a2.68 2.68 0 0 0-5.261.422 2.68 2.68 0 0 0 2.065 2.869zm1.25 1.215a2.68 2.68 0 0 0 5.261-.422 2.68 2.68 0 0 0-2.065-2.869c-.236 1.705-1.56 3.015-3.196 3.291zm1.978-3.289c-.998.235-1.799 1.034-2.011 2.071.998-.235 1.8-1.033 2.011-2.071z"
          />
          <path d="M14.076 20.3a.6.6 0 0 1-.482-.698l.165-.901a.6.6 0 1 1 1.181.216l-.165.901a.6.6 0 0 1-.698.483zm-4.128-2.373a.6.6 0 1 0 .935.752l1.016-1.264a.6.6 0 0 0-.935-.752l-1.016 1.264zm.063-3.251a.6.6 0 0 1-.338.778l-.852.336a.6.6 0 1 1-.44-1.116l.852-.336a.6.6 0 0 1 .778.338zm12.101-7.999a.6.6 0 0 1 .054.847l-1.072 1.217a.6.6 0 0 1-.901-.793l1.072-1.217a.6.6 0 0 1 .847-.054zm.231 3.318a.6.6 0 1 0 .372 1.141l.871-.284a.6.6 0 1 0-.373-1.141l-.871.284zM10.601 23.199a.6.6 0 0 1 0-1.2h10.872a.6.6 0 0 1 0 1.2H10.601zm1.781 2.54a.6.6 0 0 1 0-1.2h6.644a.6.6 0 0 1 0 1.2h-6.644z" />
          <path
            fillRule="evenodd"
            d="M6.674 3.013c-.924 0-1.673.749-1.673 1.673v22.627c0 .924.749 1.673 1.673 1.673h18.652c.924 0 1.673-.749 1.673-1.673V4.686c0-.924-.749-1.673-1.673-1.673H6.674zm-.473 1.673c0-.261.212-.473.473-.473h18.652c.261 0 .473.212.473.473v22.627c0 .261-.212.473-.473.473H6.674c-.261 0-.473-.212-.473-.473V4.686z"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path d="M26.838 7.032a.62.62 0 0 1 .416.775l-.467 1.528a.63.63 0 0 1-.782.42.62.62 0 0 1-.416-.775l.468-1.528a.63.63 0 0 1 .782-.42zm-11.086 12.85c.129.32-.028.681-.35.806l-1.527.592c-.322.125-.687-.034-.816-.354s.028-.681.35-.806l1.527-.592c.322-.125.687.034.816.354zm5.816 7.759a.62.62 0 0 1-.501-.725l.291-1.568a.63.63 0 0 1 .729-.504.62.62 0 0 1 .501.725l-.291 1.568a.63.63 0 0 1-.729.504zm-5.654-2.842a.61.61 0 0 0 .095.871.64.64 0 0 0 .886-.097l1.626-1.988a.61.61 0 0 0-.095-.871.64.64 0 0 0-.886.096l-1.626 1.988zm16.361-14.741a.61.61 0 0 0-.055-.874.64.64 0 0 0-.89.056l-1.716 1.914a.61.61 0 0 0 .055.874.64.64 0 0 0 .89-.056l1.716-1.914zm-.064 4.896c-.109-.328.069-.679.398-.784l1.561-.501c.329-.105.683.075.792.402s-.069.679-.398.784l-1.561.501c-.329.105-.683-.075-.792-.402z" />
          <path
            fillRule="evenodd"
            d="M16.308 14.525c-.272 2.772 1.718 5.245 4.488 5.654.41 2.24 2.293 4.033 4.713 4.262 2.916.276 5.507-1.823 5.788-4.688.272-2.772-1.718-5.245-4.488-5.653-.411-2.24-2.293-4.033-4.713-4.262-2.916-.276-5.507 1.823-5.788 4.688zm4.429 4.228l-.012.144c-1.996-.423-3.369-2.251-3.173-4.253.212-2.158 2.173-3.775 4.422-3.562 1.767.167 3.14 1.41 3.545 2.982-2.484.227-4.534 2.16-4.782 4.69zm4.893 4.444c-1.767-.167-3.14-1.41-3.545-2.981 2.484-.227 4.534-2.16 4.782-4.69l.012-.144c1.996.423 3.369 2.251 3.173 4.253-.212 2.158-2.173 3.775-4.422 3.562zm-3.649-4.326c.187-1.907 1.74-3.391 3.65-3.564l-.008.1c-.187 1.907-1.74 3.39-3.65 3.564l.009-.1z"
          />
          <path d="M14.602 34.225c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h18.796c.345 0 .625.28.625.625s-.28.625-.625.625H14.602zm3.655 5.805c-.345 0-.625-.28-.625-.625s.28-.625.625-.625h11.487c.345 0 .625.28.625.625s-.28.625-.625.625H18.257z" />
          <path
            fillRule="evenodd"
            d="M8.463 2.975a2.49 2.49 0 0 0-2.489 2.489v37.073a2.49 2.49 0 0 0 2.489 2.489h31.073a2.49 2.49 0 0 0 2.489-2.489V5.463a2.49 2.49 0 0 0-2.489-2.489H8.463zM7.225 5.463a1.24 1.24 0 0 1 1.239-1.239h31.073a1.24 1.24 0 0 1 1.239 1.239v37.073a1.24 1.24 0 0 1-1.239 1.239H8.463a1.24 1.24 0 0 1-1.239-1.239V5.463z"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path d="M35.753 9.366a.79.79 0 0 1 .533.992l-.643 2.101a.81.81 0 0 1-1.001.538.79.79 0 0 1-.533-.992l.643-2.101a.81.81 0 0 1 1.001-.538zM20.991 26.478a.79.79 0 0 1-.448 1.031l-2.098.813a.81.81 0 0 1-1.044-.453.79.79 0 0 1 .448-1.032l2.098-.813a.81.81 0 0 1 1.044.453zM28.79 36.86c-.435-.078-.722-.493-.641-.928l.401-2.157c.081-.434.499-.723.934-.645s.722.493.641.928l-.401 2.157c-.081.434-.499.723-.934.645zm-7.54-3.728c-.28.342-.225.841.121 1.115s.855.218 1.134-.123l2.21-2.702c.28-.342.225-.841-.121-1.115s-.855-.218-1.134.123l-2.21 2.702zm21.755-19.789c.295-.329.263-.83-.071-1.119a.82.82 0 0 0-1.139.072l-2.333 2.602c-.295.329-.263.83.071 1.119a.82.82 0 0 0 1.139-.072l2.332-2.602zm-.047 6.627a.79.79 0 0 1 .509-1.004l2.145-.688a.81.81 0 0 1 1.014.515.79.79 0 0 1-.509 1.004l-2.145.688a.81.81 0 0 1-1.014-.515z" />
          <path
            fillRule="evenodd"
            d="M21.745 19.366c-.362 3.695 2.291 6.993 5.984 7.538.547 2.987 3.057 5.377 6.283 5.682 3.888.368 7.343-2.43 7.718-6.251.362-3.695-2.291-6.993-5.984-7.538-.547-2.987-3.058-5.377-6.284-5.682-3.888-.368-7.343 2.43-7.718 6.25zm5.906 5.637l-.021.259c-2.698-.567-4.558-3.037-4.292-5.745.286-2.915 2.935-5.096 5.969-4.809 2.388.226 4.245 1.908 4.788 4.036-3.342.274-6.111 2.863-6.444 6.259zm6.518 5.991c-2.388-.226-4.245-1.908-4.788-4.036 3.343-.273 6.111-2.862 6.444-6.259l.02-.259c2.698.567 4.558 3.036 4.292 5.744-.286 2.915-2.935 5.096-5.969 4.809zm-4.925-5.84c.255-2.601 2.392-4.618 5.005-4.818l-.017.213c-.255 2.601-2.392 4.618-5.005 4.818l.017-.213z"
          />
          <path d="M19.469 45.6a.8.8 0 1 1 0-1.6h25.062a.8.8 0 1 1 0 1.6H19.469zm4.874 7.74a.8.8 0 0 1 0-1.6h15.315a.8.8 0 1 1 0 1.6H24.343z" />
          <path
            fillRule="evenodd"
            d="M11.285 4C9.47 4 8 5.47 8 7.285v49.431c0 1.814 1.471 3.285 3.285 3.285h41.431c1.814 0 3.285-1.471 3.285-3.285V7.285C56 5.47 54.53 4 52.716 4H11.285zM9.6 7.285c0-.931.754-1.685 1.685-1.685h41.431c.931 0 1.685.754 1.685 1.685v49.431c0 .93-.754 1.685-1.685 1.685H11.285c-.931 0-1.685-.754-1.685-1.685V7.285z"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
