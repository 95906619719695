import { useEcardThemeByThemeIdQuery } from '@graphql/generated';
import { FormikProps } from 'formik';
import { EcardDesignInputFields } from '../Design.controller';
import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';

interface UseUpdateEcardDesignFromQueryParamArgs {
  ecardFormik: FormikProps<EcardDesignInputFields>;
}

/**
 * https://withjoy.atlassian.net/browse/PRNT-1167
 * https://withjoy.atlassian.net/browse/MSG-565
 *
 */
export const useUpdateEcardDesignFromQueryParam = (args: UseUpdateEcardDesignFromQueryParamArgs) => {
  const { ecardFormik } = args;
  const [themeQueryParam, setThemeQueryParam] = useManagedSearchParam('theme');
  const [saveQueryParam, setSaveQueryParamValue] = useManagedSearchParam('save');

  useEcardThemeByThemeIdQuery({
    variables: {
      themeId: themeQueryParam!
    },
    skip: !themeQueryParam,
    batchMode: 'fast',
    onCompleted(themeData) {
      const theme = themeData?.themeByThemeId;

      if (theme) {
        // Only set the `joyTheme` field if the `themeId` is valid
        ecardFormik.setFieldValue('joyTheme', theme);
        ecardFormik.setFieldValue('useMyColor', false);

        if (saveQueryParam === 'true') {
          ecardFormik.submitForm();
        }
      }

      setThemeQueryParam(null);
      setSaveQueryParamValue(null);
    }
  });
};
