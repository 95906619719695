import React, { useMemo, useState } from 'react';
import { BottomSheetContext } from './BottomSheet.state';
import { useScrollPosition } from '@shared/utils/hooks/useScrollPosition';

type StateObject = Readonly<{ [key: string]: unknown } | undefined>;

export type NonEmptyArray<T> = [T, ...T[]];

export type BottomSheetView = {
  id: string;
  children: React.ReactNode;
  backButtonDirection?: string;
  disableNextButton?: boolean;
};

export interface BottomSheetStateContext
  extends Readonly<{
    scrollPosition: number;
    maxHeight?: number;
    minHeight?: number;
    backgroundColor?: string;
    step: string;
    currentStepIndex: number;
    setStep: React.Dispatch<React.SetStateAction<string>>;
    parentViewState: StateObject | undefined;
    setParentViewState: React.Dispatch<React.SetStateAction<StateObject>>;
    views: NonEmptyArray<BottomSheetView>;
  }> {}

interface BottomSheetProviderProps
  extends Readonly<{
    children: React.ReactNode;
    maxHeight?: number;
    minHeight?: number;
    backgroundColor?: string;
    views: NonEmptyArray<BottomSheetView>;
  }> {}

/**
 * Wrap our target with the Provider and pass the different props.
 * Since the BottomSheet works only on Mobile, make sure to have a Mobile View to communicate with the Sheet and
 * hide whatever existent Desktop Navigation Component.
 *
 * ```js
 * <BottomSheetProvider
 *   views={[
 *     <GenericView key={0} name="FIRST VIEW" />,
 *     <GenericView key={1} name="SECOND VIEW" />
 *   ]}
 * >
 *  <DesktopView />
 *  <MobileView />
 * </BottomSheetProvider>
 * ```
 *
 * Just an example for the implementation. This component will show the different views basing on the active step.
 * We use parentViewState to share information between the parent views and the bottom sheet views.
 *
 * ```js
 * const MobileView: React.FC = () => {
 * const isMobile = useIsMobileScreen();
 * const { step, parentViewState, setParentViewState } = useBottomSheetState();
 *
 * useEffect(() => {
 *   setParentViewState({ guests: 10, showDialog: false });
 * }, [setParentViewState]);
 *
 * if (!isMobile) return null;
 *
 * return (
 *   <div style={{ display: 'flex', marginTop: '200px', height: '1000px', flexDirection: 'column', alignItems: 'center' }}>
 *     <div>MOBILE VIEW - Step: {step}</div>
 *     <div>GUESTS: {parentViewState?.guests}</div>
 *     <div>SHOW DIALOG: {parentViewState?.showDialog ? 'true' : 'false'}</div>
 *   </div>
 * );
 * };
 * ```
 *
 * Just an example for the implementation. Here we will have the different views for the bottom sheet passed as props to the Provider.
 * We use parentViewState to share information between the parent views and the bottom sheet views.
 *
 * ```js
 * const GenericView: React.FC<{ name: string }> = ({ name }) => {
 * const { parentViewState, setParentViewState } = useBottomSheetState();
 *
 * return (
 *   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '10px' }}>
 *     <div>{name}</div>
 *     <div>GUESTS:{parentViewState?.guests}</div>
 *     <button
 *       style={{ borderColor: 'black', borderWidth: '2px', padding: '2px', borderRadius: '5%' }}
 *       onClick={() => setParentViewState(prevState => ({ ...prevState, showDialog: true }))}
 *     >
 *       Open Dialog
 *     </button>
 *   </div>
 * );
 * };
 * ```
 *
 * */

export const BottomSheetProvider: React.FC<BottomSheetProviderProps> = ({ children, ...props }) => {
  const scrollPosition = useScrollPosition(true);
  const [step, setStep] = useState<string>(props.views?.[0]?.id || '');
  const [parentViewState, setParentViewState] = useState<StateObject>(undefined);
  const currentStepIndex = useMemo(() => props.views.findIndex(view => view.id === step), [step, props.views]);

  return (
    <BottomSheetContext.Provider value={{ scrollPosition, currentStepIndex, step, setStep, parentViewState, setParentViewState, ...props }}>{children}</BottomSheetContext.Provider>
  );
};
