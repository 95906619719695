import React, { useEffect, useCallback } from 'react';
import { useTranslation } from '@shared/core';
import { ButtonV2, TextV2 } from '@withjoy/joykit';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { GetMeEventUsersEventInfoForMePageDocument, useLeaveEventMutation } from '@graphql/generated';
import { CancelButton } from '@withjoy/joykit';
import { useToast } from '@withjoy/joykit';
import { addError } from '@shared/utils/logger';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { WarningMessage, dialogOverrides } from './LeaveEventV2.styles';

interface Props
  extends Readonly<{
    eventId?: string;
    eventName?: string | null;
    onClose: () => void;
    isOpen: boolean;
  }> {}

export const LeaveEventDialogV2: React.FC<Props> = ({ onClose, isOpen, eventId, eventName }) => {
  const { t2 } = useTranslation('account');
  const tLeaveEvent = t2('leaveEvent');

  const tLeaveEventSuccessToast = t2('leaveEvent', 'successToast');
  const tLeaveEventFailureToast = t2('leaveEvent', 'failureToast');

  const { trackLeaveEventConfirmClick, trackError } = useAccountMeTelemetry();
  const [leaveEvent, { error: apolloError, loading, called }] = useLeaveEventMutation({
    variables: { id: eventId || '' },
    refetchQueries: [{ query: GetMeEventUsersEventInfoForMePageDocument }],
    onCompleted: () => {
      toast(tLeaveEventSuccessToast);
      onClose();
    }
  });
  const { toast } = useToast();

  const handleLeaveEventConfirmed = () => {
    if (!eventId) {
      addError('Missing eventId to leave event');
      return;
    }
    trackLeaveEventConfirmClick();
    leaveEvent();
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (called && !loading && !apolloError) {
      onClose();
    } else if (apolloError) {
      addError(apolloError);
      toast(tLeaveEventFailureToast);
      onClose();
    }
  }, [loading, called, apolloError, onClose, trackError, tLeaveEventFailureToast, toast]);

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={handleClose}
      dialogOverrides={dialogOverrides}
      title={tLeaveEvent.titleV2}
      renderFooter={() => (
        <>
          <CancelButton marginRight="auto" onClick={onClose} disabled={loading}>
            {tLeaveEvent.cancelButton}
          </CancelButton>
          <ButtonV2 intent="destructive" onClick={handleLeaveEventConfirmed} disabled={loading}>
            {tLeaveEvent.confirmButton}
          </ButtonV2>
        </>
      )}
    >
      <>
        <TextV2 tagName="label" typographyVariant="label3">
          {tLeaveEvent.label}
        </TextV2>
        <TextV2 typographyVariant="body2" marginTop={3}>
          {eventName}
        </TextV2>
        <WarningMessage>
          <TextV2 typographyVariant="label3">{tLeaveEvent.warningLabel}</TextV2>
          <TextV2 typographyVariant="label2">{tLeaveEvent.warningMessage}</TextV2>
        </WarningMessage>
      </>
    </ResponsiveDialog>
  );
};
