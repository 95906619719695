import { ImageLayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';
import { StationeryTemplateWithThemeJson } from '@apps/card/routes/Dashboard/components/DesignsGallery/useDesignTemplates';
import { usePdpData } from '@apps/card/routes/Dashboard/components/ProductDetailsPage/usePdpData';
import { AIBackgroundPromptPayload, StationeryTemplateCategoryEnum, usemodifyAIBackgroundMutation } from '@graphql/generated';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { resolvePlaceholderIndex } from '../ImagineYourCardRoot/ImageStorageManager';
import PageWrapper from '../PageWrapper/PageWrapper';
import { ImageHistoryElement, ImageHistoryVariation } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { absolutePhotoUrl } from '@apps/card/routes/CardCustomizer/components/ImageLayer';
import { Setter } from '@apps/imagineYourCard/imagineYourCard.utils';
import { ReimagineWrapper } from './AICardReimagined.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { responsiveSettings } from '../ImagineYourCardRoot/cardConstants';
import { MEDIA_SERVICE } from '@apps/card/routes/CardCustomizer/steps/CardDesign/config';
import { PreviewContext, PreviewContextPayload } from '../ImagineYourCardRoot/ImagineYourCardRoot';

type AICardReimaginedProps = {
  template: StationeryTemplateWithThemeJson;
  promptPayload: AIBackgroundPromptPayload;
  index: number;
  setLoadingCount: Setter<number>;
  targetRow?: number;
  myRow?: number;
  resourceID: string;
  oomph?: number;
  m?: string;
};

type AICardReimaginedContainerProps = {
  promptPayload: AIBackgroundPromptPayload;
  themeID: string;
  category: StationeryTemplateCategoryEnum;
  index: number;
  targetRow?: number;
  myRow?: number;
  setLoadingCount: Setter<number>;
  resourceID: string;
  oomph?: number;
  m?: string;
};

enum RequestState {
  INIT,
  LOADING,
  DONE,
  ERROR
}

const AICardReimaginedContainer = ({ promptPayload, targetRow, myRow, resourceID, themeID, category, index, setLoadingCount, oomph, m }: AICardReimaginedContainerProps) => {
  const { loading, template } = usePdpData(themeID, category);

  if (!loading && template) {
    return (
      <div>
        <AICardReimagined
          myRow={myRow}
          targetRow={targetRow}
          resourceID={resourceID}
          setLoadingCount={setLoadingCount}
          promptPayload={promptPayload}
          template={template!}
          index={index}
          oomph={oomph}
          m={m}
        />{' '}
      </div>
    );
  } else if (loading) {
    return <div></div>;
  } else {
    return (
      <div>
        <p>Invalid template provided.</p>
      </div>
    );
  }
};

const AICardReimagined = ({ template, targetRow, resourceID, promptPayload, myRow, index, setLoadingCount, oomph, m }: AICardReimaginedProps) => {
  const page = template.themeJSON.card.front;
  const [state, setState] = useState(RequestState.INIT);
  const resource = useRef<string | undefined>('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const cols = useResponsive(responsiveSettings, 1)[0];

  const shouldAnimate = targetRow !== undefined && myRow !== undefined;
  const [isAnimating, setIsAnimating] = useState(shouldAnimate);
  const { cardType } = useContext(PreviewContext) as PreviewContextPayload;
  const [hasChangedCat, setHasChangedCat] = useState(false);

  if (!hasChangedCat && cardType !== template.category) {
    setHasChangedCat(true);
  }

  const [get, { called, loading, data, error }] = usemodifyAIBackgroundMutation({
    fetchPolicy: 'no-cache'
  });

  const [demoPosition, setDemoPosition] = useState(targetRow);
  const hasAnimated = useRef(false);

  if (!template.themeJSON.imageHistory) {
    template.themeJSON.imageHistory = new Array<ImageHistoryElement>();
  }

  if (resourceID && state !== RequestState.DONE) {
    if (page.layers[0].type == 'image') {
      (page.layers[0] as ImageLayerData).imageData.src = `${MEDIA_SERVICE}/imagine/${resourceID}`;
    }
  }

  useEffect(() => {
    if (state === RequestState.INIT) {
      get({
        variables: {
          promptPayload,
          resourceID,
          oomph,
          m
        }
      });
      setState(RequestState.LOADING);
      setLoadingCount(old => old + 1);
    } else if (state === RequestState.LOADING) {
      if (called && !loading) {
        if (data && !error) {
          setState(RequestState.DONE);

          const initialImage = (page.layers[0] as ImageLayerData).imageData.src;
          const url = data?.stationeryDraftModifyAIBackground?.url!;
          const resourceID = data?.stationeryDraftModifyAIBackground?.resourceID;
          resource.current = resourceID;

          template.themeJSON.imageHistory.push({
            type: 'upload',
            url: absolutePhotoUrl(initialImage)
          });
          (page.layers[0] as ImageLayerData).imageData.src = url;
          template.themeJSON.imageHistory.push({
            type: 'ai_prompt',
            url,
            promptPayload
          } as ImageHistoryVariation);

          setLoadingCount(old => old - 1);
          resolvePlaceholderIndex(index, {
            url,
            category: template.category,
            themeID: template.themeId,
            promptPayload,
            resourceID,
            uploadTime: undefined
          });
        } else {
          setState(RequestState.ERROR);
          setLoadingCount(old => old - 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, called, loading]);

  if (!hasAnimated.current && targetRow !== undefined) {
    setTimeout(() => {
      setDemoPosition(myRow);
      setIsAnimating(false);
    }, 3000);

    hasAnimated.current = true;
  }

  useLayoutEffect(() => {
    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(() => {
      if (isAnimating && containerRef.current) {
        const grid = containerRef.current?.parentElement?.parentElement?.parentElement;
        setContainerWidth(grid!.clientWidth);
      }
    });
    resizeObserver.observe(document.body);
    return () => resizeObserver.disconnect();
  }, [isAnimating]);

  return (
    <div ref={containerRef}>
      {shouldAnimate && cols === 4 ? (
        <ReimagineWrapper position={demoPosition!} index={myRow!} spacing={containerWidth / cols} onMouseEnter={() => {}}>
          <PageWrapper useSlideAnim={!hasChangedCat} index={index} loading={loading} resourceID={resource} promptPayload={promptPayload} template={template} page={page} />{' '}
        </ReimagineWrapper>
      ) : (
        <PageWrapper index={index} loading={state === RequestState.LOADING} resourceID={resource} promptPayload={promptPayload} template={template} page={page} />
      )}
    </div>
  );
};

export default AICardReimaginedContainer;
