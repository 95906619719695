import type { Draft } from 'immer';
import * as ACTIONS from './SaveTheDate.actions';
import { SaveTheDateState } from './context';
import { SaveTheDateEmailInputFields, ECardDraft } from '../SaveTheDate.types';
import { SaveTheDateGetEcardDataQuery } from '@graphql/generated';

export type SaveTheDateAction =
  // Initialize
  | ReducerActionWithPayload<typeof ACTIONS.INITIALIZE_SAVE_THE_DATE_STATE, { queryResult: SaveTheDateGetEcardDataQuery; isQueryLoading: boolean }>
  // General
  | ReducerActionWithPayload<typeof ACTIONS.SAVE_EMAIL_DRAFT, { emailDraft: SaveTheDateEmailInputFields }>
  | ReducerActionWithPayload<typeof ACTIONS.SAVE_ECARD_DRAFT, { eCardDraft: ECardDraft }>
  | ReducerAction<typeof ACTIONS.TOGGLE_IS_NAV_BAR_OPEN>
  | ReducerAction<typeof ACTIONS.TOGGLE_IS_ADJUSTING_POSITION>
  | ReducerAction<typeof ACTIONS.TOGGLE_EDIT_PANEL>;

export const useSaveTheDateReducer = (draft: Draft<SaveTheDateState>, action: SaveTheDateAction) => {
  switch (action.type) {
    case ACTIONS.INITIALIZE_SAVE_THE_DATE_STATE: {
      const { queryResult, isQueryLoading } = action.payload;
      if (queryResult) {
        if (!draft.hasInitializedOnce) {
          draft.hasInitializedOnce = true;
          draft.eCardDataQuery = queryResult;
          draft.isQueryLoading = isQueryLoading;
        } else {
          console.error('Unable to initialize save the date');
        }
      }
      break;
    }

    case ACTIONS.SAVE_ECARD_DRAFT: {
      const { eCardDraft } = action.payload;
      draft.eCardDraft = eCardDraft;

      break;
    }

    case ACTIONS.SAVE_EMAIL_DRAFT: {
      const { emailDraft } = action.payload;
      draft.emailDraft = emailDraft;

      break;
    }

    case ACTIONS.TOGGLE_IS_NAV_BAR_OPEN:
      draft.isNavBarOpen = !draft.isNavBarOpen;
      break;

    case ACTIONS.TOGGLE_EDIT_PANEL:
      draft.isEditPanelOpen = !draft.isEditPanelOpen;
      break;

    case ACTIONS.TOGGLE_IS_ADJUSTING_POSITION:
      draft.isEditPanelOpen = !draft.isEditPanelOpen;
      draft.isAdjustingPosition = !draft.isAdjustingPosition;

    default: {
      break;
    }
  }
};
