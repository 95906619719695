import React, { ReactNode } from 'react';
import { ButtonV2 } from '@withjoy/joykit';
import { StickyCtaBar } from '../../components/StickyCtaBar';
import { useSurface } from './useSurface';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import CustomizerLayoutSidePanel from '../../components/CustomizerLayoutSidePanel';

export const CardFrontConfigPanel = ({
  hideStickyCtaBar,
  renderCustomizations,
  onNext,
  allowOrderEdit
}: {
  hideStickyCtaBar: boolean;
  renderCustomizations: () => ReactNode;
  onNext: () => void;
  allowOrderEdit?: boolean;
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const [{ activeLayerIndex }, updateSurface] = useSurface();

  return (
    <CustomizerLayoutSidePanel
      title="Edit Card"
      withBlur={hideStickyCtaBar}
      allowOrderEdit={allowOrderEdit}
      onClickCallback={() => {
        if (activeLayerIndex !== undefined && isMobileOrTablet) {
          updateSurface(surface => {
            surface.hoverLayerIndex = undefined;
            surface.activeLayerIndex = undefined;
            surface.editingLayerIndex = undefined;
          });
        }
      }}
    >
      {renderCustomizations()}
      {!hideStickyCtaBar && (
        <StickyCtaBar>
          <ButtonV2 fullWidth={true} intent="neutral" shape="rounded" onClick={onNext}>
            Next: Back of Card
          </ButtonV2>
        </StickyCtaBar>
      )}
    </CustomizerLayoutSidePanel>
  );
};
