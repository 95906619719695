import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './InkGold.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('inkGold')?.assetUrl || '';

export const InkGold: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage left={104.5} top={59.5} width={291} height={366} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex width="100%" height="255px" padding="25px 30px 0" flexDirection="column" alignItems="center" justifyContent="flex-start">
          <TextV2
            color="#333"
            textAlign="center"
            fontSize={isFullNamesFormat ? 40 : 50}
            fontFamily="Mrs Saint Delafield"
            fontWeight={400}
            lineHeight={isFullNamesFormat ? '49px' : '76px'}
            marginBottom="20px"
            display="inline"
          >
            {formattedOwnerName}
            {formattedFianceeName ? <span style={{ padding: '0 20px' }}>&</span> : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="center">
              {formattedDate ? (
                <TextV2 color="#333" textAlign="center" fontSize={12.5} fontFamily="Quicksand" fontWeight={400} lineHeight="16px" letterSpacing="0.1em" textTransform="uppercase">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="#333" textAlign="center" fontSize={12.5} fontFamily="Quicksand" fontWeight={400} lineHeight="16px" letterSpacing="0.1em" textTransform="uppercase">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2
                  color="#333"
                  textAlign="center"
                  fontSize={12.5}
                  fontFamily="Quicksand"
                  fontWeight={400}
                  lineHeight="16px"
                  letterSpacing="0.1em"
                  textTransform="uppercase"
                  marginTop="20px !important"
                >
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
