import React from 'react';
import { HeaderContainer, Underline } from './Header.styles';
import { Flex, TextV2 } from '@withjoy/joykit';
import { JoyLogo } from '@assets/index';
import { NavLink, useLocation } from '@react-router';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

const HeaderOption = ({ text, to }: { text: string | Array<string>; to?: string | undefined }) => {
  const mobile = useIsMobileScreen();
  const { pathname } = useLocation();
  const selected = pathname == to;
  // If passed an array, use it for mobile responsiveness. If passed a string, just use it.
  const displayText = typeof text == 'object' && text.length >= 2 ? (mobile ? text[0] : text[1]) : text;

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" position="relative" width="max-content">
      <TextV2 typographyVariant="hed1" fontSize={['17px', '20px']} userSelect="none">
        {to ? <NavLink to={to}>{displayText}</NavLink> : displayText}
      </TextV2>
      {selected && <Underline />}
    </Flex>
  );
};

const Header = () => {
  const mobile = useResponsive({ values: { desktop: false, tablet: false, mobile: true } })[0];
  return (
    <HeaderContainer mobile={mobile}>
      <JoyLogo color="#330066"></JoyLogo>
      <HeaderOption text={['Imagine', 'Imagine Your Card']} to="/cards/imagine" />
      <HeaderOption text={['Gallery', 'Browse Gallery']} to="/cards/wedding/save-the-date" />
    </HeaderContainer>
  );
};

export default Header;
