import React, { FC, useCallback, useState } from 'react';
import { createContext } from '@shared/utils/createContext';
import { useDisclosure } from '@withjoy/joykit';
import { EventPageType } from '@graphql/generated';

export type PageVisibility = 'hidden' | 'passwordProtected' | 'public';

interface PageInfo {
  id: string;
  pageSlug: string;
  visibility: PageVisibility;
  type?: EventPageType;
  pageTitle?: string;
}

interface CustomPageDialogData {
  pages: PageInfo[];
}

type AddCustomPageDialogProviderContext = {
  eventId?: string;
  isOpenAddCustomPageDialog: boolean;
  customPageDialogData: CustomPageDialogData;
  setCustomPageDialogData: (customPageDialogData: CustomPageDialogData) => void;
  openAddCustomPageDialog: () => void;
  closeAddCustomPageDialog: () => void;
};

const [Provider, useAddCustomPageDialogContext] = createContext<AddCustomPageDialogProviderContext>({ name: 'AddCustomPageDialogProviderContext' });

interface AddCustomPageDialogProviderProps {
  eventId?: string;
}

const AddCustomPageDialogProvider: FC<AddCustomPageDialogProviderProps> = ({ eventId, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customPageDialogData, setCustomPageDialogData] = useState<CustomPageDialogData>({ pages: [] });

  const handleOpen = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Provider
      value={{
        eventId,
        isOpenAddCustomPageDialog: isOpen,
        customPageDialogData,
        setCustomPageDialogData,
        openAddCustomPageDialog: handleOpen,
        closeAddCustomPageDialog: handleClose
      }}
    >
      {children}
    </Provider>
  );
};

AddCustomPageDialogProvider.displayName = 'AddCustomPageDialogProvider';

export { AddCustomPageDialogProvider, useAddCustomPageDialogContext };
