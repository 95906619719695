import React from 'react';
import { EcardLandingHeaderContainer, EcardLandingHeaderDivider, EcardLandingHeaderText, styles } from './EcardLandingHeader.styles';
import { useEcardTranslations } from '@apps/ecard/Ecard.i18n';
import { EcardType } from '@graphql/generated';
import { ECARD_LANDING_LEFT_PANE_WIDTH_DESKTOP } from '@shared/components/EmailsAndEcards/components/EcardLanding/EcardLanding.constants';
import { UniversalBurgerMenu } from '@apps/admin/components/UniversalBurgerMenu/UniversalBurgerMenu';
import { Hamburger } from '@withjoy/joykit/icons';
interface EcardLandingHeaderProps
  extends Readonly<{
    ecardDraftType: EcardType;
    isSaveTheDate: boolean;
  }> {}

export const EcardLandingHeader: React.FC<EcardLandingHeaderProps> = ({ ecardDraftType, isSaveTheDate }) => {
  const { getEcardDefaultTranslations } = useEcardTranslations({ ecardDraftType });
  const { title } = getEcardDefaultTranslations('').ecardLanding;

  return (
    <EcardLandingHeaderContainer
      __css={styles.ecardLandingHeaderContainer}
      position={isSaveTheDate ? 'sticky' : ['sticky', 'absolute']}
      width={isSaveTheDate ? '100%' : ['100%', ECARD_LANDING_LEFT_PANE_WIDTH_DESKTOP]}
    >
      <UniversalBurgerMenu>
        <Hamburger size="lg" />
      </UniversalBurgerMenu>
      <EcardLandingHeaderDivider __css={styles.ecardLandingHeaderDivider} />
      <EcardLandingHeaderText __css={styles.ecardLandingHeaderText}>{title}</EcardLandingHeaderText>
    </EcardLandingHeaderContainer>
  );
};
