import React, { useState, useEffect } from 'react';
import { InputLabel } from '../InputLabel';
import { InlineError } from '../InlineError';
import { generateComponentDisplayName, isString, getPropKeyValues } from '../../utils';
import { Stack } from '../Stack';
import { SpacingExternalProps, LabelledProps, BaseComponentProps } from '../../common/props';
import { FormHelpTextProps, FormHelpText } from '../FormHelpText';
import { Box } from '../Box';
import { SPACE_LAYOUT_PROPS } from '../../utils/removeNonHTMLProps';
import { createUniqueIDFactory } from '../../utils/createUniqueIDFactory';
export interface BaseFormFieldProps extends BaseComponentProps {
  children: React.ReactNode;

  /** Whether this form group is non-interactive.  */
  disabled?: boolean;

  /**
   * The error state of the control.
   *
   * If a boolean is passed, the container border will change colors.
   * If a message is passed, it will be rendered in place of the `helpText`.
   */
  error?: string | boolean;
  focused?: boolean;

  helpText?: string | FormHelpTextProps;

  /**
   * If set to `true` and the <FormField /> is nested within another <FormField />,
   * you can remove the container style so that the content is not further indented.
   *
   * This will typically be set by a parent `<ControlGroup />` and not need manual
   * intervention.
   */
  nested?: boolean;

  required?: boolean;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
}

export const generateErrorID = (id: string) => `${id}Error`;
export const generateLabelID = (id: string) => `${id}Label`;

export interface FormFieldProps extends BaseFormFieldProps, LabelledProps {
  id?: string;
}

type InternalFormFieldProps = FormFieldProps & SpacingExternalProps;

const generateHelpTextMarkup = (props: FormFieldProps): JSX.Element | null => {
  if (!props.helpText) {
    return null;
  }
  if (isString(props.helpText)) {
    return <FormHelpText disabled={props.disabled} message={props.helpText} />;
  } else {
    return <FormHelpText disabled={props.disabled} {...props.helpText} />;
  }
};

const getUniqueID = createUniqueIDFactory('FormField');

/**
 * ---------------------------------------------------------------
 * NOTE: Soon to be deprecated, replaced by `<FormControl />`
 * ------------------------------------------------------------
 * The `<FormField />` component that a enhances a form input with an accessible label above and help text below.
 *
 * It is intended to wrap around a single control - such as an input or a select.
 *
 * If you're looking to group *several* controls, refer to the `<ControlGroup />` component.
 *
 * @deprecated
 *
 */
export const FormField: React.SFC<InternalFormFieldProps> = React.memo(props => {
  const hasID = !!props.id;
  const [fieldID, setFieldID] = useState(props.id || getUniqueID());

  useEffect(() => {
    setFieldID(props.id || getUniqueID());
  }, [props.id]);

  const { children, error, label, labelInfo, required } = props;
  const spacingExternalProps = getPropKeyValues(props, SPACE_LAYOUT_PROPS);

  const labelledBy: string[] = [];
  const describedBy: string[] = [];

  let labelData: { id: string; markup: React.ReactElement<any> } | null = null;
  let errorData: { id: string; markup: React.ReactElement<any> } | null = null;

  if (label) {
    const labelID = generateLabelID(fieldID);
    labelledBy.push(labelID);
    labelData = {
      id: labelID,
      markup: (
        <InputLabel id={labelID} fieldID={hasID ? props.id : fieldID} error={!!error} labelInfo={labelInfo} required={required} hidden={props.hideLabel}>
          {label}
        </InputLabel>
      )
    };
  }

  if (error && typeof error === 'string') {
    const errorID = generateErrorID(fieldID);
    describedBy.push(errorID);
    errorData = {
      id: errorID,
      markup: <InlineError id={errorID} message={error} />
    };
  }

  const helpTextMarkup = generateHelpTextMarkup(props);
  const childrenMarkup = React.Children.map(children, (child, index) => {
    if (typeof child === 'object') {
      // const newChild = child as React.ReactElement<any>;

      return React.cloneElement(child as React.ReactElement<any>, {
        'aria-labelledby': labelledBy.join(' '),
        'aria-invalid': Boolean(error),
        error: Boolean(error)
      });
    }
    return child;
  });

  // const describedBy = getDescribedByIDs(props);

  return (
    <Box className={props.className} {...spacingExternalProps}>
      <Stack spacing={3} vertical={true}>
        {labelData && labelData.markup}
        {childrenMarkup}
        {(errorData && errorData.markup) || helpTextMarkup}
      </Stack>
    </Box>
  );
});

FormField.displayName = generateComponentDisplayName('FormField');
