import { useBottomSheetState } from '../../context/BottomSheet.state';
import { MAX_HEIGHT } from '../../BottomSheet.styles';

export const useStepsController = () => {
  const { step, setStep, views, maxHeight = MAX_HEIGHT } = useBottomSheetState();

  const showNavComponent = views.length !== 1;

  const viewToShow = views.find(view => view.id === step);

  const currentIndex = viewToShow ? views.indexOf(viewToShow) : 0;

  const showLeftArrow = !!currentIndex;

  const showRightArrow = currentIndex !== views.length - 1;

  const handleLeftArrow = () => {
    if (viewToShow?.backButtonDirection) {
      setStep(viewToShow.backButtonDirection);
      return;
    }

    setStep(() => views[currentIndex - 1].id);
  };

  const handleRightArrow = () => setStep(() => views[currentIndex + 1].id);

  return {
    step,
    setStep,
    views,
    maxHeight,
    showNavComponent,
    viewToShow,
    showLeftArrow,
    disableNextButton: viewToShow?.disableNextButton,
    showRightArrow,
    handleLeftArrow,
    handleRightArrow,
    currentIndex
  };
};
