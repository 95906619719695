import { useRouterHelper } from '@shared/core';
import { PlanningRoutes } from '../Planning.routes';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useMemo } from 'react';

export function usePlanningAccommodationsRoutesPath() {
  const planningAccommodationsExperimentEnabled = useFeatureValue('planningAccommodationsExperiment').value === 'treatment';
  const hotelBlockPath = planningAccommodationsExperimentEnabled ? PlanningRoutes.accommodations : PlanningRoutes.hotelblock;
  return { hotelBlockPath, planningAccommodationsExperimentEnabled };
}

export function usePlanningRoutes() {
  const { buildPath } = useRouterHelper();
  const { hotelBlockPath: hotelBlockRoute, planningAccommodationsExperimentEnabled } = usePlanningAccommodationsRoutesPath();
  const hotelBlockPath = useMemo(() => buildPath(hotelBlockRoute), [buildPath, hotelBlockRoute]);
  return { hotelBlockPath, hotelBlockRoute, planningAccommodationsExperimentEnabled };
}
