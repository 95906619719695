import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './ModularTexture.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('modularTexture')?.assetUrl || '';

export const ModularTexture: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={39.5} width={381} height={461} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex height="200px" width="100%" padding="30px" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <TextV2 color="black" textAlign="left" fontSize={14} fontFamily="Quicksand" fontWeight={700} lineHeight="21px" letterSpacing="0.2em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName ? ' & ' : ''}
            {isFullNamesFormat && !!formattedFianceeName && <br />}
            {formattedFianceeName || ''}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="left">
              {formattedDate ? (
                <TextV2 color="black" textAlign="left" fontSize={12} fontFamily="Quicksand" lineHeight="16px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="black" textAlign="left" fontSize={12} fontFamily="Quicksand" lineHeight="16px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="black" textAlign="left" fontSize={12} fontFamily="Quicksand" lineHeight="16px" letterSpacing="0.2em" textTransform="uppercase">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
