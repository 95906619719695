import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OnCustomizeOfferingClickHandler, ProductOffering } from './ConciergeServiceSection.types';
import { Box, BoxProps, ButtonV2, DialogV2, Flex, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { LottiePlayer } from '@shared/components';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useVimeoPlayerContext } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import { PRODUCT_OFFERINGS } from './ConciergeServiceSection.config';
import { useFeatureValue } from '@shared/core/featureFlags';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

interface SectionProps extends BoxProps {
  title: string;
  children: React.ReactNode;
}
const Section = ({ title, children, ...restProps }: SectionProps) => {
  return (
    <Flex flexDirection="column" rowGap={6} {...restProps}>
      <TextV2 typographyVariant="hed4">{title}</TextV2>
      {children}
    </Flex>
  );
};

const Video = ({ url: urlProp, thumbnailUrl }: { url: string; thumbnailUrl: string }) => {
  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const playerRef = useRef<HTMLIFrameElement>(null);
  const videoUrl = useMemo(() => {
    const url = new URL(urlProp);
    const searchParams = url.searchParams;
    searchParams.append('muted', '1');
    searchParams.append('loop', '1');
    searchParams.append('background', '1');
    return url.href;
  }, [urlProp]);

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  useEffect(() => {
    // Opting to manually play the video instead of leveraging `autoplay=1` to resolve chrome issue on windows machine
    if (playerRef.current && VimeoPlayer) {
      const player = new VimeoPlayer(playerRef.current);
      player.play().catch(() => {
        //
      });
    }
  }, [VimeoPlayer]);

  return (
    <Box
      position="relative"
      paddingTop="177.78%"
      backgroundColor="mono1"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundImage={`url('${thumbnailUrl}')`}
    >
      <Box ref={playerRef} as="iframe" border="none" outline="none" width="100%" height="100%" position="absolute" top={0} left={0} title="Product Overview Video" src={videoUrl} />
    </Box>
  );
};

const OverviewScreen = ({
  data,
  handleOnNotifyMeClick,
  offeringSortIndex,
  category,
  onCustomizeOfferingClick
}: {
  data: ProductOffering;
  handleOnNotifyMeClick: () => void;
  offeringSortIndex: number;
  category: StationeryTemplateCategoryEnum;
  onCustomizeOfferingClick: OnCustomizeOfferingClickHandler;
}) => {
  const { paperlustConciergeServiceDialogButtonInteracted } = useCardTelemetry();
  const { value: printDesignConciergeCheckoutEnabledValue } = useFeatureValue('printDesignConciergeCheckoutEnabled');
  const isPrintDesignConciergeCheckoutEnabled = printDesignConciergeCheckoutEnabledValue === 'on';

  const { t } = useTranslation('stationery');

  const trans = t('dashboard', 'conciergeServiceSection', 'dialog', 'overviewScreen');
  const sectionTrans = trans.sections;

  const { longName, descriptionHighlights, videoUrl: videoUrlProp, imageUrl, suiteHighlights, videoThumbnailUrl, printingBusinessDaysQuantity } = data;

  const stepList = useMemo(() => {
    return sectionTrans.howItWorks.steps.map((step, idx) => ({
      title: step.title({ quantity: printingBusinessDaysQuantity }),
      description: step.description()
    }));
  }, [sectionTrans, printingBusinessDaysQuantity]);

  return (
    <>
      <DialogV2.Body>
        <Flex flexDirection={{ _: 'column', sm2: 'row' }} rowGap={7} columnGap={8}>
          {/* Left Panel */}
          <Flex flexDirection="column" width={{ _: '100%', sm2: '40%' }} flexShrink={0} rowGap={6}>
            <Video thumbnailUrl={videoThumbnailUrl} url={videoUrlProp} />
            <Box backgroundImage={`url('${imageUrl}')`} backgroundPosition="center" backgroundSize={'cover'} width="100%" paddingTop="100%" />
          </Flex>

          {/* Right Panel */}
          <Flex flexDirection="column" paddingRight={{ sm2: 8 }}>
            <Flex flexDirection="column" rowGap={3}>
              {!printDesignConciergeCheckoutEnabledValue && <TextV2 typographyVariant="label3">{trans.comingSoonLabel()}</TextV2>}
              <TextV2 maxWidth={320} typographyVariant="display1" fontWeight="semibold">
                {longName}
              </TextV2>
              <TextV2 typographyVariant="label2">{trans.costLabel[category]({ cost: data.costPerHundredString })}</TextV2>
            </Flex>

            <Box marginTop={5} as="ul" typographyVariant="body1" listStyle="disc" paddingLeft={5} style={{ listStylePosition: 'outside' }}>
              {descriptionHighlights.map((highlight, idx) => {
                return <li key={idx}>{highlight.text}</li>;
              })}
            </Box>
            {suiteHighlights && (
              <Section title={sectionTrans.suiteHighlights.title[category]()} marginTop={6}>
                <Box as="ul" typographyVariant="body1" listStyle="disc" paddingLeft={5} style={{ listStylePosition: 'outside' }}>
                  {suiteHighlights.map((highlight, idx) => (
                    <li key={idx}>{highlight.text}</li>
                  ))}
                </Box>
              </Section>
            )}

            <Section title={sectionTrans.overview.title()} marginTop={6}>
              <TextV2 typographyVariant="body1">{sectionTrans.overview.description()}</TextV2>
            </Section>

            <Section marginTop={8} title={sectionTrans.howItWorks.title()}>
              <Flex flexDirection="column" rowGap={6}>
                {stepList.map((item, idx) => (
                  <Flex flexDirection="column" key={item.title} rowGap={2}>
                    <Flex columnGap={3} alignItems="center">
                      <Flex size={28} alignItems="center" justifyContent="center" borderRadius="full" backgroundColor="mono2">
                        {idx + 1}
                      </Flex>
                      <TextV2 typographyVariant="button1">{item.title}</TextV2>
                    </Flex>
                    <Box paddingLeft={36}>
                      <TextV2 typographyVariant={'body1'}>{item.description}</TextV2>
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Section>
          </Flex>
        </Flex>
      </DialogV2.Body>
      <DialogV2.Footer justifyContent="center" borderTop="1px solid" borderTopColor="mono3">
        <ButtonV2
          intent="neutral"
          shape="rounded"
          width="332px"
          onClick={() => {
            paperlustConciergeServiceDialogButtonInteracted({
              type: isPrintDesignConciergeCheckoutEnabled ? 'orderNow' : 'notifyMe',
              suite: longName,
              cost: data.costPerHundredString,
              sortIndex: offeringSortIndex,
              orderOfItems: PRODUCT_OFFERINGS[category].map(offering => offering.longName)
            });
            isPrintDesignConciergeCheckoutEnabled ? onCustomizeOfferingClick(data) : handleOnNotifyMeClick();
          }}
        >
          {isPrintDesignConciergeCheckoutEnabled ? trans.orderNowCta() : trans.notifyMeCta()}
        </ButtonV2>
      </DialogV2.Footer>
    </>
  );
};

interface SuccessScreenProps {
  handleOnClose: () => void;
}
const SuccessScreen = ({ handleOnClose }: SuccessScreenProps) => {
  const { t2 } = useTranslation('stationery');

  const trans = t2('dashboard', 'conciergeServiceSection', 'dialog', 'successScreen');
  return (
    <DialogV2.Body>
      <Flex flexDirection="column" rowGap={7} alignItems="center" textAlign="center" width={{ sm2: 360 }} marginX="auto">
        <Box width={240}>
          <LottiePlayer autoplay={true} lottieUrl="https://withjoy.com/assets/public/messaging/partnerships/paperlust/lottie/notify-success.json" />
        </Box>

        <Box>
          <TextV2 typographyVariant="display1" fontWeight={600} textAlign="center">
            {trans.title}
          </TextV2>
          <TextV2 marginTop={3} typographyVariant="body1">
            {trans.description}
          </TextV2>
        </Box>

        <ButtonV2 intent="neutral" fullWidth onClick={handleOnClose}>
          {trans.allDoneCta}
        </ButtonV2>
      </Flex>
    </DialogV2.Body>
  );
};
interface RootProps {
  category: StationeryTemplateCategoryEnum;
  selectedOffering: ProductOffering;
  offeringSortIndex: number;
  step: 1 | 2;
  setStep: (step: 1 | 2) => void;
  onClose: () => void;
  onCustomizeOfferingClick: OnCustomizeOfferingClickHandler;
}
const Root = (props: RootProps) => {
  const { category, onClose, selectedOffering, offeringSortIndex, onCustomizeOfferingClick, step, setStep } = props;

  const handleOnNotifyMeClick = useCallback(() => {
    setStep(2);
  }, [setStep]);

  return step === 1 ? (
    <OverviewScreen
      onCustomizeOfferingClick={onCustomizeOfferingClick}
      category={category}
      offeringSortIndex={offeringSortIndex}
      data={selectedOffering}
      handleOnNotifyMeClick={handleOnNotifyMeClick}
    />
  ) : (
    <SuccessScreen handleOnClose={onClose} />
  );
};

interface ProductOfferingDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedOffering: ProductOffering | undefined;
  offeringSortIndex: number;
  category: StationeryTemplateCategoryEnum;
  onCustomizeOfferingClick: OnCustomizeOfferingClickHandler;
}

export const ProductOfferingDialog = (props: ProductOfferingDialogProps) => {
  const { isOpen, onClose, selectedOffering, offeringSortIndex, onCustomizeOfferingClick, category } = props;

  // Managing step state here so that we can drive the dialog size based on the step
  const [step, setStep] = useState<1 | 2>(1);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        // Wrapping this in a set timeout to prevent the state reset from happening before the animation finishes
        setStep(1);
      }, 500);
    }
  }, [isOpen]);

  return (
    <DialogV2
      overrides={{
        Body: {
          props: {
            paddingTop: [60, 60, 8]
          }
        },
        CloseButton: {
          props: {
            top: [12, null, 6]
          }
        }
      }}
      stickyBehavior="footer"
      isOpen={isOpen}
      onClose={onClose}
      size={step === 1 ? '3xl' : 'xl'}
    >
      {selectedOffering && (
        <>
          <DialogV2.CloseButton />
          <Root
            onCustomizeOfferingClick={onCustomizeOfferingClick}
            category={category}
            offeringSortIndex={offeringSortIndex}
            onClose={onClose}
            setStep={setStep}
            step={step}
            selectedOffering={selectedOffering}
          />
        </>
      )}
    </DialogV2>
  );
};
