import React from 'react';
import { pxToRem } from '@withjoy/joykit/theme';
import { useHeaderLinks } from '../../../../useHeaderLinks';
import { ChevronRightSquare } from '@withjoy/joykit/icons';
import { useMobileViewContext } from '../../MobileView.provider';
import type { HeaderLinksType } from '../../../../AppNavBar.types';
import { Flex, ButtonV2, SpacingStack, TextV2 } from '@withjoy/joykit';

export const MobileGuestSubMenu: React.FC<{ heading: string; type: HeaderLinksType }> = ({ heading, type }) => {
  const { onClickSubMenu } = useMobileViewContext();
  const { itemsToRender } = useHeaderLinks(type);
  return (
    <SpacingStack spacing={3}>
      <TextV2 typographyVariant="FeatureHead17">{heading}</TextV2>
      <Flex flexDirection="column">
        {itemsToRender.map((item, idx) => (
          <ButtonV2
            key={item.label + idx}
            as="a"
            href={item.href}
            target={item.target}
            typographyVariant="HedMobile32"
            height={pxToRem(64)}
            padding={0}
            intent="neutral"
            variant="ghost"
            justifyContent="flex-start"
            endIcon={item.blogs?.length && item.blogs.length > 0 ? <ChevronRightSquare marginTop={2} /> : null}
            onClick={e => {
              if (item.blogs?.length && item.blogs.length > 0) {
                e.preventDefault();
                onClickSubMenu(idx, type);
              } else {
                item.onClick(e);
              }
            }}
          >
            {item.mobileLabel ? item.mobileLabel : item.label}
          </ButtonV2>
        ))}
      </Flex>
    </SpacingStack>
  );
};
