import { Box, styled } from '@withjoy/joykit';

import { EMAIL_EDITOR_DRAWER_WIDTH } from '../../../EmailEditPanel/EmailEditPanel.styles';

export const EmailPreviewWrapper = styled.div<{ $isEmailEditPanelShown: boolean }>`
  height: 100%;
  width: ${({ $isEmailEditPanelShown }) => ($isEmailEditPanelShown ? `calc(100% - ${EMAIL_EDITOR_DRAWER_WIDTH}px)` : `100%`)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 180px 0;
  transition: width 0.4s;
  will-change: width;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 0 96px 0;
  }
`;

export const EmailPreviewContainer = styled(Box)`
  width: 100%;
  max-width: 450px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.03), 0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  position: relative;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    border-radius: 0;
    overflow: hidden;
  }
`;
