import { Flex } from '@withjoy/joykit';
import { Close } from '@withjoy/joykit/icons';
import React from 'react';

export const ClearButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Flex alignItems="center" width="fit-content" height="100%" onClick={onClick}>
      <div style={{ padding: '10px', backgroundColor: '#f7f7f7', borderRadius: '999px' }}>
        <Close color="black" />
      </div>
    </Flex>
  );
};
