import React from 'react';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import ImagineYourCardRoot from './Components/ImagineYourCardRoot/ImagineYourCardRoot';
import Header from '@apps/imagineYourCard/Components/Header/Header';

const ImagineYourCard = (props: { themeID?: string; category?: StationeryTemplateCategoryEnum }) => {
  return (
    <>
      <Header />
      <ImagineYourCardRoot />
    </>
  );
};

export default ImagineYourCard;
