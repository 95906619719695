import React, { useEffect } from 'react';
import { EditPanel } from '@apps/saveTheDate/components/EditPanel';
import { useDesignController } from './Design.controller';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { filledButtonOverrides, FloatingFooterContainer, linkButtonOverrides } from '../../components/Shared.styles';
import { TabWrapper, TabListWrapper } from './Design.styles';
import { ButtonV2, CancelButton } from '@withjoy/joykit';
import EditCardContentForm from '../../components/EditCardContentForm';
import EditCardDesignForm from '../../components/EditCardDesignForm';
import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import PhotoECardPreview from '@apps/saveTheDate/components/PhotoECardPreview';
import { useSaveTheDateDispatch, useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { PremiumDigitalUpsellBanner, PremiumDigitalUpsellDialog } from './components/PremiumDigitalUpsell';
import { StationeryTemplateCategoryEnum } from '../../../../graphql/generated';

export const Design: React.FC = () => {
  const {
    saveTheDateFormik,
    handleCancel,
    handleDone,
    isEditShown,
    openEditPanel,
    isUnsavedChangesDialogOpen,
    handleUnsavedChangesDialogClose,
    handleUnsavedChangesDialogConfirm,
    translations,
    selectedTabIndex,
    handleSelectedTabIndexChange,
    previewFrontRef,
    previewBackRef,
    handleScrollToFront,
    handleScrollToBack,
    extraData,
    savePosition
  } = useDesignController();
  const { isAdjustingPosition, eventId, eventHandle } = useSaveTheDateState();
  const { toggleIsAdjustingPosition } = useSaveTheDateDispatch();

  const { designCardTrack } = useSaveTheDateTelemetry();
  useEffect(() => {
    designCardTrack.enter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <PhotoECardPreview
          isAdjustingPosition={isAdjustingPosition}
          ecardData={saveTheDateFormik.values}
          isECardEditPanelShown={isEditShown}
          toggleEditECardPanel={openEditPanel}
          previewFrontRef={previewFrontRef}
          previewBackRef={previewBackRef}
          extraData={extraData}
          toggleAdjustment={toggleIsAdjustingPosition}
          savePosition={savePosition}
        />
        <EditPanel isOpen={isEditShown} onClose={handleCancel} title={translations.ecardEditPanel.header()}>
          <TabWrapper>
            <Tab.Group selectedIndex={selectedTabIndex} onChange={handleSelectedTabIndexChange}>
              <TabListWrapper>
                <Tab.List className="tab-list">
                  <Tab className={({ selected }) => clsx('tab-item', selected && 'tab-item-active')}>{translations.ecardEditorSwitch.design()}</Tab>
                  <Tab className={({ selected }) => clsx('tab-item', selected && 'tab-item-active')}>{translations.ecardEditorSwitch.content()}</Tab>
                </Tab.List>
              </TabListWrapper>
              <PremiumDigitalUpsellBanner
                eventId={eventId}
                eventHandle={eventHandle}
                themeId={saveTheDateFormik.values.themeSelected}
                templateCategory={StationeryTemplateCategoryEnum.saveTheDate}
              />
              <Tab.Panels className="tab-panels">
                <Tab.Panel className="tab-panel">
                  <EditCardDesignForm formik={saveTheDateFormik} />
                </Tab.Panel>
                <Tab.Panel className="tab-panel">
                  <EditCardContentForm formik={saveTheDateFormik} handleScrollToFront={handleScrollToFront} handleScrollToBack={handleScrollToBack} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </TabWrapper>
          <FloatingFooterContainer>
            <ButtonV2 onClick={handleDone} loading={saveTheDateFormik.isSubmitting} overrides={filledButtonOverrides}>
              {translations.ecardEditPanel.doneButtonText()}
            </ButtonV2>
            <CancelButton onClick={handleCancel} overrides={linkButtonOverrides}>
              {translations.ecardEditPanel.cancelButtonText()}
            </CancelButton>
          </FloatingFooterContainer>
        </EditPanel>
      </div>
      <PremiumDigitalUpsellDialog
        eventId={eventId}
        eventHandle={eventHandle}
        themeId={saveTheDateFormik.values.themeSelected}
        templateCategory={StationeryTemplateCategoryEnum.saveTheDate}
      />
      <UnsavedChangesDialog
        isOpen={isUnsavedChangesDialogOpen}
        onClose={handleUnsavedChangesDialogClose}
        onConfirm={handleUnsavedChangesDialogConfirm}
        warningMessageContent="ecard"
      />
    </>
  );
};
