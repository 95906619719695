import { Box, styled } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';

export const StyledEcardEditorTabNavWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.mono2};
  border-radius: 200px;
  padding: 6px;
`;

export const StyledEcardEditorTabItem = styled(Box)<{ $isActive?: boolean }>`
  ${InterFonts.button}
  display: block;
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.black : theme.colors.mono10)};
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.white : 'none')};
  box-shadow: ${({ $isActive }) => ($isActive ? '0px 1px 3px -1px rgba(51, 51, 51, 0.1)' : 'none')};
  padding: 8px 32px;
  border-radius: 200px;
  transition: color 0.4s;
  cursor: pointer;
  font-weight: 400;
  font-size: 17px;
  &:hover {
    color: ${props => props.theme.colors.black};
    &:active {
      color: ${props => props.theme.colors.mono6};
    }
  }
`;
