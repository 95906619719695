import React from 'react';
import { useTranslation } from '@shared/core';
import { InheritSizeLink } from '@apps/createWedding/routes/CreateAccount/CreateAccount.styles';

export const PrivacyPolicyLink = () => {
  const { t } = useTranslation('createWedding');

  return (
    <InheritSizeLink typographyVariant="label1" target="_blank" href="https://withjoy.com/privacy">
      {t('createAccount', 'privacyPolicy')()}
    </InheritSizeLink>
  );
};
