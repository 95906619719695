import React, { useMemo } from 'react';
import { RightPanelPrintsPreview, RightPanelPrintsPreviewImage, RightPanelTabItem, RightPanelTabNavWrapper, RightPaneContainer, styles } from './EcardLandingRightPane.styles';
import { EcardEventDesign, EcardLandingRightPanelTabItem } from '@apps/ecard/Ecard.types';
import { EcardDesignInputFields, useDesignController } from '../../../Design/Design.controller';
import { PrivacyModeType } from '@graphql/generated';
import EcardPreview from '../../../Design/components/EcardPreview';
import {
  GenericEcardEditButton,
  genericEcardEditButtonOverrides,
  GenericEcardOverlay,
  styles as genericEcardStyles
} from '../../../Design/components/EcardPreview/components/GenericEcard/GenericEcard.styles';
import { ReactComponent as OpenLinkIcon } from '@assets/icons/open-link.svg';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { EcardType } from '@graphql/generated';

interface EcardLandingRightPaneProps
  extends Readonly<{
    selectedTab: EcardLandingRightPanelTabItem;
    setSelectedTab: (type: EcardLandingRightPanelTabItem) => void;
    ecardInput: EcardDesignInputFields;
    eventDesign: EcardEventDesign;
    ecardDraftType: EcardType;
    privacyMode?: PrivacyModeType;
    eventPassword?: Maybe<string>;
  }> {}

export const EcardLandingRightPane: React.FC<EcardLandingRightPaneProps> = ({
  selectedTab,
  setSelectedTab,
  ecardInput,
  eventDesign,
  ecardDraftType,
  privacyMode,
  eventPassword
}) => {
  const { t2 } = useTranslation('emailsAndEcards');
  const tEcardLandingRightPane = t2('ecardLanding', 'ecardLandingRightPane');
  const telemetry = useEcardsTelemetry();
  const supportsPrints = useMemo(() => {
    if (ecardInput.joyTheme) {
      return ecardInput.joyTheme.supportsPrints;
    }
    return false;
  }, [ecardInput]);

  const printImageUrl = useMemo(() => {
    if (ecardInput.joyTheme && ecardInput.joyTheme.printProviders && ecardInput.joyTheme.printProviders.length > 0) {
      return ecardInput.joyTheme.printProviders[0].stockPreviewUrl;
    }
    return null;
  }, [ecardInput]);

  const { ecardFormik: formik } = useDesignController({
    ecardInput
  });

  const onSelectEcardTab = () => {
    setSelectedTab(EcardLandingRightPanelTabItem.ECARD);
    telemetry.previewTabClick(ecardDraftType, EcardLandingRightPanelTabItem.ECARD);
  };

  const onSelectPrintTab = () => {
    setSelectedTab(EcardLandingRightPanelTabItem.PRINT);
    telemetry.previewTabClick(ecardDraftType, EcardLandingRightPanelTabItem.PRINT);
  };

  const onExplorePrintsClick = () => {
    withWindow(() => {
      if (ecardInput.joyTheme && ecardInput.joyTheme.printProviders && ecardInput.joyTheme.printProviders.length > 0) {
        telemetry.explorePrintsClick(ecardDraftType, ecardInput.joyTheme.themeId);
        window.open(ecardInput.joyTheme.printProviders[0].printUrl);
      }
    });
  };

  const isEcardTabActive = selectedTab === EcardLandingRightPanelTabItem.ECARD;
  const isPrintTabActive = selectedTab === EcardLandingRightPanelTabItem.PRINT;

  return (
    <RightPaneContainer __css={styles.rightPaneContainer}>
      {supportsPrints && (
        <RightPanelTabNavWrapper __css={styles.rightPanelTabNavWrapper}>
          <RightPanelTabItem __css={styles.getRightPanelTabItemStyles(isEcardTabActive)} onClick={onSelectEcardTab}>
            {tEcardLandingRightPane.ecard}
          </RightPanelTabItem>
          <RightPanelTabItem __css={styles.getRightPanelTabItemStyles(isPrintTabActive)} onClick={onSelectPrintTab}>
            {tEcardLandingRightPane.print}
          </RightPanelTabItem>
        </RightPanelTabNavWrapper>
      )}

      {isEcardTabActive && <EcardPreview ecardData={formik.values} eventDesign={eventDesign} privacyMode={privacyMode} eventPassword={eventPassword} isEcardPreview={true} />}
      {isPrintTabActive && (
        <RightPanelPrintsPreview __css={styles.rightPanelPrintsPreview}>
          {printImageUrl && <RightPanelPrintsPreviewImage src={printImageUrl} alt="prints" />}
          <GenericEcardOverlay __css={genericEcardStyles.genericEcardOverlay}>
            <GenericEcardEditButton overrides={genericEcardEditButtonOverrides} startIcon={<OpenLinkIcon />} onClick={onExplorePrintsClick}>
              {tEcardLandingRightPane.explorePrints}
            </GenericEcardEditButton>
          </GenericEcardOverlay>
        </RightPanelPrintsPreview>
      )}
    </RightPaneContainer>
  );
};
