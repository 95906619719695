import React, { useMemo } from 'react';
import { FormControl, SpacingStack, InputV2, TextV2, Flex, Box } from '@withjoy/joykit';
import {
  StyledLabel,
  StyledTextArea,
  InputLengthLabel,
  ToggleTitle,
  ToggleDesc,
  ToggleWrapper,
  SubTitle,
  YourURLBox,
  YourURLText,
  LineBreak,
  RevertToJoy
} from './EcardContentEditor.styles';
import { FormikProps } from 'formik';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { useTranslation } from '@shared/core';
import { EcardEditorDesc, EcardEditorDescLink } from '../../EcardEditPanel.styles';
import { SelectV1, Toggle } from '@withjoy/joykit';
import { useEcardContentEditorController } from './EcardContentEditor.controller';
import { EcardEditorThemeType } from '@apps/ecard/Ecard.types';
import { ReactComponent as YourCardIcon } from '@assets/icons/your-card.svg';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import { getFormattedDate } from '@apps/ecard/Ecard.utils';
import { ElectronicCardCardLinkOptionFragment } from '@graphql/generated';
import { useEcardTranslations } from '@apps/ecard/Ecard.i18n';
import { prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';

interface Props
  extends Readonly<{
    formik: FormikProps<EcardDesignInputFields>;
    eventHandle: string;
    eventDisplayName: string;
    handleScrollToFront: () => void;
    handleScrollToBack: () => void;
    linkOptions?: ElectronicCardCardLinkOptionFragment[];
  }> {}

export const EcardContentEditor: React.FC<Props> = ({ formik, eventHandle, eventDisplayName, handleScrollToFront, handleScrollToBack, linkOptions }) => {
  const {
    title,
    subtitle,
    date,
    dateFormat,
    location,
    message,
    additionalMessage,
    yourUrl,
    customTheme,
    selectedThemeType,
    displayEventLink,
    additionalLink1,
    additionalLink2,
    ecardDraftType
  } = formik.values;
  const { dateFormats, ecardLinkOptions, isCustomECard, onGoToSettingsClick } = useEcardContentEditorController({ date, ecardDraftType, eventHandle, linkOptions });

  const { t2 } = useTranslation('emailsAndEcards');
  const tEcardContentEditor = t2('ecardEditor', 'ecardContentEditor');
  const { getEcardContentTranslations } = useEcardTranslations({ ecardDraftType });
  const eCardPlaceholders = getEcardContentTranslations(eventDisplayName);

  const handleRevertToJoy = () => {
    formik.setFieldValue('selectedThemeType', EcardEditorThemeType.JOY);
  };

  const ecardLinkOptions1 = useMemo(
    () =>
      additionalLink2?.value && additionalLink2?.value !== additionalLink1?.value ? ecardLinkOptions.filter(({ value }) => value !== additionalLink2?.value) : ecardLinkOptions,
    [additionalLink1?.value, additionalLink2?.value, ecardLinkOptions]
  );
  const ecardLinkOptions2 = useMemo(
    () =>
      additionalLink1?.value && additionalLink1?.value !== additionalLink2?.value ? ecardLinkOptions.filter(({ value }) => value !== additionalLink1?.value) : ecardLinkOptions,
    [additionalLink1?.value, additionalLink2?.value, ecardLinkOptions]
  );

  return (
    <SpacingStack spacing={6} marginBottom={8} marginTop={9}>
      {customTheme && selectedThemeType === EcardEditorThemeType.CUSTOM && (
        <Flex flexDirection="row" alignItems="center">
          <Flex flexDirection="column" alignItems="center" width="100%">
            <YourCardIcon />
            <Box marginTop={4} fontSize="15px">
              {tEcardContentEditor.usingCustomDesign}
            </Box>
            <EcardEditorDesc>
              {tEcardContentEditor.usingCustomDesignDesc1}
              <LineBreak />
              {tEcardContentEditor.usingCustomDesignDesc2}
            </EcardEditorDesc>
            <RevertToJoy onClick={handleRevertToJoy}>{tEcardContentEditor.revertToJoyTheme}</RevertToJoy>
          </Flex>
        </Flex>
      )}

      {!(customTheme && selectedThemeType === EcardEditorThemeType.CUSTOM) && (
        <>
          <Flex flexDirection={['column', 'column', 'row']}>
            <FormControl
              marginRight={[0, 0, 4]}
              marginBottom={[4, 4, 0]}
              flex={1}
              label={<StyledLabel>{tEcardContentEditor.titleLabel}</StyledLabel>}
              error={formik.errors.title}
              overrides={EmailsAndEcardsFormControlOverrides}
            >
              <>
                <InputV2 {...formik.getFieldProps('title')} placeholder={eCardPlaceholders.title} maxLength={50} onFocus={handleScrollToFront} />
                <InputLengthLabel>{title.length} / 50</InputLengthLabel>
                {formik.getFieldMeta('title').error && (
                  <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                    {formik.getFieldMeta('title').error}
                  </TextV2>
                )}
              </>
            </FormControl>
            <FormControl
              flex={1}
              label={<StyledLabel>{tEcardContentEditor.subtitleLabel}</StyledLabel>}
              error={formik.errors.subtitle}
              overrides={EmailsAndEcardsFormControlOverrides}
            >
              <>
                <InputV2 {...formik.getFieldProps('subtitle')} placeholder={eCardPlaceholders.subtitle} maxLength={50} onFocus={handleScrollToFront} />
                <InputLengthLabel>{subtitle.length} / 50</InputLengthLabel>
                {formik.getFieldMeta('subtitle').error && (
                  <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                    {formik.getFieldMeta('subtitle').error}
                  </TextV2>
                )}
              </>
            </FormControl>
          </Flex>

          <Flex flexDirection={['column', 'column', 'row']}>
            <FormControl
              flex={1}
              marginRight={[0, 0, 4]}
              marginBottom={[4, 4, 0]}
              label={<StyledLabel>{isCustomECard ? tEcardContentEditor.dateLabel : tEcardContentEditor.dateFormatLabel}</StyledLabel>}
              overrides={EmailsAndEcardsFormControlOverrides}
            >
              <>
                {isCustomECard ? (
                  <>
                    <InputV2 {...formik.getFieldProps('date')} placeholder={tEcardContentEditor.datePlaceholder} maxLength={50} onFocus={handleScrollToFront} />
                    <InputLengthLabel>{date.length} / 50</InputLengthLabel>
                    {formik.getFieldMeta('date').error && (
                      <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                        {formik.getFieldMeta('date').error}
                      </TextV2>
                    )}
                  </>
                ) : !!date ? (
                  <SelectV1
                    defaultValue={dateFormats[0]}
                    options={dateFormats}
                    value={{
                      label: getFormattedDate(date, dateFormat),
                      value: dateFormat
                    }}
                    searchable={false}
                    onMenuOpen={handleScrollToFront}
                    onChange={e => {
                      if (e) {
                        formik.setValues({ ...formik.values, dateFormat: e.value });
                      }
                    }}
                  />
                ) : (
                  <InputV2 isDisabled={true} value={tEcardContentEditor.dateHidden} />
                )}
                {!isCustomECard && (
                  <Box marginTop={3}>
                    <EcardEditorDesc>
                      <EcardEditorDescLink onClick={() => onGoToSettingsClick('date')}>{tEcardContentEditor.editDate}</EcardEditorDescLink>
                    </EcardEditorDesc>
                  </Box>
                )}
              </>
            </FormControl>
            <FormControl flex={1} label={<StyledLabel>{tEcardContentEditor.locationLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
              <>
                <InputV2
                  {...formik.getFieldProps('location')}
                  isDisabled={!isCustomECard}
                  placeholder={tEcardContentEditor.locationPlaceholder}
                  maxLength={50}
                  onFocus={handleScrollToFront}
                  value={!location && !isCustomECard ? tEcardContentEditor.locationHidden : location}
                />
                {isCustomECard && (
                  <>
                    <InputLengthLabel>{location.length} / 50</InputLengthLabel>
                    {formik.getFieldMeta('location').error && (
                      <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                        {formik.getFieldMeta('location').error}
                      </TextV2>
                    )}
                  </>
                )}
                {!isCustomECard && (
                  <Box marginTop={3}>
                    <EcardEditorDesc>
                      <EcardEditorDescLink onClick={() => onGoToSettingsClick('location')}>{tEcardContentEditor.editLocation}</EcardEditorDescLink>
                    </EcardEditorDesc>
                  </Box>
                )}
              </>
            </FormControl>
          </Flex>

          <FormControl
            paddingTop={5}
            label={<StyledLabel>{tEcardContentEditor.messageLabel}</StyledLabel>}
            error={formik.errors.message}
            overrides={EmailsAndEcardsFormControlOverrides}
          >
            <>
              <StyledTextArea
                {...formik.getFieldProps('message')}
                placeholder={eCardPlaceholders.message}
                resize={false}
                minRows={4}
                maxRows={8}
                tabIndex={0}
                maxLength={225}
                minimal={true}
                onFocus={handleScrollToFront}
              />
              <InputLengthLabel>{message.length} / 225</InputLengthLabel>
              {formik.getFieldMeta('message').error && (
                <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
                  {formik.getFieldMeta('message').error}
                </TextV2>
              )}
            </>
          </FormControl>
        </>
      )}

      <ToggleWrapper alignItems="center" justifyContent="space-between" paddingX={4} paddingY={5}>
        <Flex flexDirection="column" alignItems="flex-start" paddingRight={4}>
          <ToggleTitle>{tEcardContentEditor.linkOptions[customTheme && selectedThemeType === EcardEditorThemeType.CUSTOM ? 1 : 0].hed}</ToggleTitle>
          <ToggleDesc>{tEcardContentEditor.linkOptions[customTheme && selectedThemeType === EcardEditorThemeType.CUSTOM ? 1 : 0].dek}</ToggleDesc>
        </Flex>
        <Toggle
          hideLabel={true}
          label="displayEventLink"
          checked={displayEventLink}
          name={formik.getFieldProps('displayEventLink').name}
          onChange={() => {
            formik.setFieldValue('displayEventLink', !displayEventLink);
            handleScrollToFront();
          }}
        />
      </ToggleWrapper>

      <SubTitle>{tEcardContentEditor.additionalInformation}</SubTitle>
      <Box paddingTop={2}>
        <StyledLabel>{tEcardContentEditor.chooseLinkTitle}</StyledLabel>
        <Flex marginTop={2} flexDirection={['column', 'column', 'row']}>
          <FormControl flex={1} marginRight={[0, 0, 4]} marginBottom={[4, 4, 0]} overrides={EmailsAndEcardsFormControlOverrides}>
            <>
              <SelectV1
                defaultValue={ecardLinkOptions1[0]}
                options={ecardLinkOptions1}
                value={additionalLink1}
                searchable={false}
                onMenuOpen={handleScrollToBack}
                onChange={e => {
                  if (e) {
                    formik.setValues({ ...formik.values, additionalLink1: e });
                  }
                }}
              />
            </>
          </FormControl>
          <FormControl flex={1} overrides={EmailsAndEcardsFormControlOverrides}>
            <>
              <SelectV1
                defaultValue={ecardLinkOptions2[0]}
                options={ecardLinkOptions2}
                value={additionalLink2}
                searchable={false}
                onMenuOpen={handleScrollToBack}
                onChange={e => {
                  if (e) {
                    formik.setValues({ ...formik.values, additionalLink2: e });
                    handleScrollToBack();
                  }
                }}
              />
            </>
          </FormControl>
        </Flex>
      </Box>
      <FormControl
        paddingTop={5}
        label={<StyledLabel>{tEcardContentEditor.additionalMessageLabel}</StyledLabel>}
        error={formik.errors.additionalMessage}
        overrides={EmailsAndEcardsFormControlOverrides}
      >
        <>
          <StyledTextArea
            {...formik.getFieldProps('additionalMessage')}
            placeholder={tEcardContentEditor.additionalMessagePlaceholder}
            resize={false}
            minRows={4}
            maxRows={8}
            tabIndex={0}
            maxLength={550}
            minimal={true}
            onFocus={handleScrollToBack}
          />
          <InputLengthLabel>{additionalMessage.length} / 550</InputLengthLabel>
          {formik.getFieldMeta('additionalMessage').error && (
            <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
              {formik.getFieldMeta('additionalMessage').error}
            </TextV2>
          )}
        </>
      </FormControl>

      <SubTitle>{tEcardContentEditor.websiteOptions}</SubTitle>
      {(displayEventLink || additionalLink1?.value || additionalLink2?.value) && (
        <FormControl paddingTop={5} label={<StyledLabel>{tEcardContentEditor.yourURL}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
          <>
            <YourURLBox>
              <YourURLText>{prettyUrl(yourUrl)}</YourURLText>
            </YourURLBox>
            <Box marginTop={3}>
              <EcardEditorDesc>
                <EcardEditorDescLink onClick={() => onGoToSettingsClick('url')}>{tEcardContentEditor.editURL}</EcardEditorDescLink>
              </EcardEditorDesc>
            </Box>
          </>
        </FormControl>
      )}
    </SpacingStack>
  );
};
