import { Box, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const DEFAULT_ITEM_SPACING = 24;

export const StyledCarouselItem = styled(Box)<{ $itemsPerPage: Maybe<number>; $itemSpacing: Maybe<number> }>`
  flex: 0 0 auto;
  width: ${({ $itemsPerPage, $itemSpacing }) => {
    if (!$itemsPerPage) return '100%';
    if (!$itemSpacing) return `calc(100% / ${$itemsPerPage})`;
    const spacingDelta = -1;
    const spacingCalc = $itemSpacing * ($itemsPerPage + spacingDelta);
    return `calc((100% - ${spacingCalc}px) / ${$itemsPerPage})`;
  }};
`;

const itemContainerStyles = (itemSpacing: Maybe<number>): StyleSystemProps => ({
  display: 'inline-block',
  _notFirst: {
    marginLeft: pxToRem(itemSpacing ?? DEFAULT_ITEM_SPACING)
  }
});

export const styles = {
  itemContainer: itemContainerStyles
};
