import { getFormattedDate } from '@apps/ecard/Ecard.utils';
import { ECARD_DATE_FORMATS } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import { useMemo } from 'react';
import { withWindow } from '@shared/utils/withWindow';
import { useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { getEventWebsiteUrl, getFilteredLinkOptions } from '@apps/ecard/Ecard.utils';
import { COUPLE_NAME_FORMATS, LOCATION_FORMATS, SAVE_THE_DATE_PHOTO_ECARD_TYPE } from '@apps/saveTheDate/constants';
import { useTranslation } from '@shared/core';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { LinkLabel } from '@apps/saveTheDate/SaveTheDate.types';
import { getFormattedCoupleNames, getFormattedLocation } from '@apps/saveTheDate/SaveTheDate.utils';

const useEditCardContentFormController = () => {
  const { eCardDataQuery, eventHandle } = useSaveTheDateState();
  const { t2 } = useTranslation('emailsAndEcards');
  const translations = t2('ecardEditorPhotoSaveTheDate', 'ecardContentEditor');
  const { eCardAdminLinkClick } = useSaveTheDateTelemetry();

  const { isDateHidden, isLocationHidden, locationString, linkOptions, eventDate, location, yourUrl } = useMemo(() => {
    const eventById = eCardDataQuery?.eventById;
    const eventInfo = eventById?.info;
    const filteredLinkOptions = eCardDataQuery?.getEcardLinkOptionsByEventId
      ? getFilteredLinkOptions(eCardDataQuery?.getEcardLinkOptionsByEventId, SAVE_THE_DATE_PHOTO_ECARD_TYPE)
      : [];
    const isLocationHidden = !eventById?.info?.locationInfo?.country;
    const isDateHidden = !eventById?.info?.date;

    return {
      isDateHidden,
      isLocationHidden,
      locationString: eventInfo?.location,
      linkOptions: filteredLinkOptions,
      eventDate: eventInfo?.date,
      location: eventInfo?.location,
      yourUrl: getEventWebsiteUrl(eventById?.website, eventById?.domain?.domainName)
    };
  }, [eCardDataQuery]);

  const nameFormats = useMemo(() => {
    const { ownerFirstName = '', fianceeFirstName = '', ownerFullName = '', fianceeFullName = '' } = eCardDataQuery?.eventById?.info || {};

    return COUPLE_NAME_FORMATS.map((format: string) => ({
      label: getFormattedCoupleNames({ ownerFirstName, fianceeFirstName, ownerFullName, fianceeFullName, format }),
      value: format
    })).filter(option => !!option.label);
  }, [eCardDataQuery?.eventById?.info]);

  const dateFormats = useMemo(() => {
    return ECARD_DATE_FORMATS.map((dateFormat: string) => {
      return {
        label: getFormattedDate(eventDate, dateFormat),
        value: dateFormat
      };
    });
  }, [eventDate]);

  const locationFormats = useMemo(() => {
    const locationInfo = eCardDataQuery?.eventById?.info?.locationInfo;

    return LOCATION_FORMATS.map((format: string) => ({ label: getFormattedLocation({ locationInfo, format }), value: format }));
  }, [eCardDataQuery?.eventById?.info]);

  const ecardLinkOptions = useMemo(() => {
    if (!linkOptions) return [];
    return [
      {
        label: 'None',
        value: ''
      },
      ...linkOptions.map(item => ({
        label: item.label,
        value: item.name
      }))
    ];
  }, [linkOptions]);

  const handleOpenSettingsClick = (label: LinkLabel) => {
    eCardAdminLinkClick(label);

    withWindow(() => {
      window.open(`${window.location.origin}/${eventHandle}/edit/settings`, '_blank');
    });
  };

  return {
    nameFormats,
    dateFormats,
    locationFormats,
    ecardLinkOptions,
    handleOpenSettingsClick,
    location,
    yourUrl,
    translations,
    isLocationHidden,
    locationString,
    isDateHidden
  };
};

export default useEditCardContentFormController;
