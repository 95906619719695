import { animationTransition } from '@shared/utils/animationTransition';
import { Box, FlexProps, BoxProps } from '@withjoy/joykit';
import React, { KeyboardEvent } from 'react';
import { HeaderLinksContainer, styles } from './HeaderLinksGroupButton.styles';

const HeartArrow = (props: BoxProps) => {
  return (
    <Box as="svg" width="10px" height="7px" viewBox="0 0 10 7" fill="none" {...props}>
      <path d="M2 1.828l2.828 2.829 2.829-2.829" stroke="currentColor" style={{ transition: animationTransition('stroke') }} strokeWidth="3" strokeLinecap="round"></path>
    </Box>
  );
};

interface HeaderLinksGroupButtonProps extends FlexProps {
  isActive: boolean;
  onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void;
}

export const HeaderLinksGroupButton = ({ children, isActive, ...restProps }: React.HtmlHTMLAttributes<HTMLElement> & HeaderLinksGroupButtonProps) => {
  return (
    <HeaderLinksContainer tabIndex={0} __css={styles.headerLinksContainer(isActive)} {...restProps} style={{ 'font-synthesis': 'none' }}>
      {children} <HeartArrow marginLeft="12px" transition={animationTransition('transform', 'color')} transform={isActive ? 'rotate(-180deg)' : 'rotate(0deg)'} />
    </HeaderLinksContainer>
  );
};
