import { Flex, styled } from '@withjoy/joykit';

export const LeftImage = styled.img`
  position: absolute;
  transition: all 0.5s ease-in-out;
  right: 50%;
  transform: rotate(-12deg);
  filter: drop-shadow(0px 7px 27px rgba(44, 41, 37, 0.06)) drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.07));
`;

export const CenterImage = styled.img`
  position: absolute;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  filter: drop-shadow(0px 7px 27px rgba(44, 41, 37, 0.06)) drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.07));
`;

export const RightImage = styled.img`
  position: absolute;
  transition: all 0.5s ease-in-out;
  left: 50%;
  transform: rotate(12deg);
  filter: drop-shadow(0px 7px 27px rgba(44, 41, 37, 0.06)) drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.07));
`;

export const CustomFlex = styled(Flex)`
  position: relative;

  &:hover {
    ${LeftImage} {
      transform: rotate(-24deg);
    }
    ${CenterImage} {
      transform: scale(1.1, 1.1);
    }
    ${RightImage} {
      transform: rotate(24deg);
    }
  }
`;

export const Chip = styled(Flex)`
  background: ${({ theme }) => theme.colors.positive6};
  border-radius: 24px;
  height: 24px;
  width: 45px;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.0025em;
    color: ${({ theme }) => theme.colors.mono1};
  }
`;
