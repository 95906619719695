import { createContext, useContext } from 'react';
import { SaveTheDateState } from './SaveTheDate.provider';

export const SaveTheDateStateContext = createContext<SaveTheDateState | undefined>(undefined);

export const useSaveTheDateState = () => {
  const context = useContext(SaveTheDateStateContext);
  if (context === undefined) {
    throw new Error('useSaveTheDateState must be used within a SaveTheDateStateContext Provider');
  }
  return context;
};
