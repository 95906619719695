import { Box, DialogV2Props, styled } from '@withjoy/joykit';

export const dialogOverrides: NonNullable<DialogV2Props['overrides']> = {
  Content: { props: { width: ['100%', '375px'] } }
};

export const WarningMessage = styled(Box)`
  background-color: #fff7f8;
  padding: 1rem;
  margin-top: 1.5em;
  color: ${props => props.theme.colors.negative6};
`;
