import { useTranslation } from '@shared/core';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useForgotPasswordController } from './ForgotPassword.controller';
import React from 'react';
import { FormSection } from '../AccountForm.styles';
import { JoyLogo } from '@assets/index';
import { SwitchFormHandler } from '../AccountForm.types';
import { ButtonV2, InputV2, LinkV2, TextV2 } from '@withjoy/joykit';
import { Email } from '@withjoy/joykit/icons';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

interface ForgotPasswordProps {
  switchForm: SwitchFormHandler;
}

const ForgotPassword = ({ switchForm }: ForgotPasswordProps) => {
  const { t, t2 } = useTranslation('createWedding');
  const translations = t('createAccount');
  const { formik, getFieldErrorProps } = useForgotPasswordController({ switchForm });
  const forgotPasswordTranslations = t2('forgotPassword');
  const mobile = useIsMobileOrTablet();

  const switchToCreateAccountForm = useEventCallback(() => switchForm('createAccount'));
  const switchToSignInForm = useEventCallback(() => switchForm('signIn'));

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSection gap="24px">
        {!mobile && <JoyLogo />}
        <FormSection gap="8px">
          <TextV2 typographyVariant="hed6">{forgotPasswordTranslations.heroText}</TextV2>
          <TextV2 typographyVariant="body1">{forgotPasswordTranslations.helpText}</TextV2>
        </FormSection>
        <FormSection gap="40px" alignment="left">
          <InputV2
            {...getFieldErrorProps('email')}
            startElement={<Email color="mono10" fill="#767676" size={24} />}
            placeholder={forgotPasswordTranslations.email}
            {...formik.getFieldProps('email')}
          />
          <FormSection gap="24px">
            <ButtonV2 width="100%" shape="rounded" type="submit" disabled={formik.isSubmitting} intent="primary">
              {forgotPasswordTranslations.resetPassword}
            </ButtonV2>
            <TextV2 textAlign="center" typographyVariant="body1" color="mono12">
              {forgotPasswordTranslations.accountExists}{' '}
              <LinkV2 onClick={switchToSignInForm} textDecoration="none">
                <TextV2 as="span" typographyVariant="body1" color="linkText">
                  {translations.signIn()}
                </TextV2>
              </LinkV2>
            </TextV2>
            <TextV2 textAlign="center" typographyVariant="body1" color="mono12">
              {forgotPasswordTranslations.createAccountCta}{' '}
              <LinkV2 onClick={switchToCreateAccountForm} textDecoration="none">
                <TextV2 as="span" typographyVariant="body1" color="linkText">
                  {translations.createAccountCta()}
                </TextV2>
              </LinkV2>
            </TextV2>
          </FormSection>
        </FormSection>
      </FormSection>
    </form>
  );
};

export default ForgotPassword;
