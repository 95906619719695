import React, { useContext } from 'react';
import ReactSlider from 'react-slider';
import { SliderContainer } from './ScaleSlider.styles';
import { ReactComponent as ThumbIcon } from '../../../../assets/icons/slider-thumb.svg';
import { ReactComponent as IconSmall } from '../../../../assets/icons/slider-small.svg';
import { ReactComponent as IconBig } from '../../../../assets/icons/slider-big.svg';
import { ScaleContext } from '../../PhotoECardPreview';

interface ScaleSliderProps {
  value: number;
  onChange: (scale: number) => void;
}

const ScaleSlider: React.FC<ScaleSliderProps> = ({ value, onChange }) => {
  const { isVisible } = useContext(ScaleContext);

  return (
    <SliderContainer isVisible={isVisible}>
      <IconSmall />
      <ReactSlider
        value={value}
        onChange={onChange}
        min={1}
        max={3}
        step={0.01}
        renderThumb={props => (
          <div {...props}>
            <ThumbIcon />
          </div>
        )}
      />
      <IconBig />
    </SliderContainer>
  );
};

export default ScaleSlider;
