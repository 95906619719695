import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { ImagineButton } from '../ImagineYourCardRoot/ImagineYourCardRoot.styles';
import { ImagineIcon } from './ImagineIcon';

type PromptBarButtonProps = {
  onClick?: () => void;
  text: string;
  btnProps?: { [index: string]: string };
  enabled?: boolean;
  loading: boolean;
};

const PromptBarButton = ({ onClick, loading, text, btnProps, enabled = true }: PromptBarButtonProps) => {
  //   const gradient = promptbar.gradient;
  return (
    <Flex flexDirection="column" justifyContent="center" zIndex={10} marginLeft="auto" style={{ float: 'right' }}>
      <ImagineButton type="submit" formAction="submit" enabled={enabled} expanded={false} onClick={enabled ? onClick : () => {}} {...btnProps}>
        <Flex alignItems="center" gap="8px">
          <ImagineIcon loading={loading} />
          {enabled && <TextV2 typographyVariant="button2">{text}</TextV2>}
        </Flex>
      </ImagineButton>
    </Flex>
  );
};

export default PromptBarButton;
