import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';

export const RightPaneContainer = styled(Flex)``;
export const RightPanelTabNavWrapper = styled(Flex)``;
export const RightPanelTabItem = styled(Box)<{ $isActive?: boolean }>``;
export const RightPanelPrintsPreview = styled(Flex)``;

const rightPaneContainerStyles: StyleSystemProps = {
  backgroundColor: 'mono1',
  flexDirection: 'column',
  alignItems: 'center',
  flex: ['unset', 1],
  height: ['fit-content', '100vh'],
  padding: [4, '80px 67.5px 75px'],
  overflowY: ['unset', 'auto'],
  borderLeft: ['none', '1px solid'],
  borderColor: ['transparent', 'mono4'],
  width: '100%'
};

const rightPanelTabNavWrapperStyles: StyleSystemProps = {
  backgroundColor: 'mono2',
  borderRadius: '200px',
  padding: 2,
  width: 'fit-content'
};

const rightPanelPrintsPreviewStyles: StyleSystemProps = {
  position: 'relative',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: [6, 9]
};

const getRightPanelTabItemStyles = ($isActive: boolean): StyleSystemProps => ({
  display: 'block',
  color: $isActive ? 'black' : 'mono10',
  backgroundColor: $isActive ? 'white' : 'transparent',
  boxShadow: $isActive ? '0px 1px 3px -1px rgba(51, 51, 51, 0.1)' : 'none',
  paddingX: 7,
  paddingY: 3,
  borderRadius: '200px',
  transition: 'color 0.4s',
  cursor: 'pointer',
  typographyVariant: 'button3',
  _hover: {
    color: 'black'
  },
  _active: {
    color: 'mono6'
  }
});

export const styles = {
  rightPaneContainer: rightPaneContainerStyles,
  rightPanelTabNavWrapper: rightPanelTabNavWrapperStyles,
  rightPanelPrintsPreview: rightPanelPrintsPreviewStyles,
  getRightPanelTabItemStyles
};

export const RightPanelPrintsPreviewImage = styled.img`
  width: 100%;
  height: auto;
`;
