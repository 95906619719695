import React from 'react';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { Props } from '../../common/props';

const LabelInfo: React.FC<Props> = props => {
  return <span className={props.className}>{props.children}</span>;
};

export const StyledLabelInfo = styled(LabelInfo).attrs({})`
  margin-left: 8px;
  color: ${props => props.theme.colors.typeTertiaryDark};
  font-weight: 400;
`;
