import { styled } from '@withjoy/joykit';

export const StyledSelect = styled.select`
  color: var(--type-tertiary-mono-10, #767676);

  /* Body/Body 2 */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;

  width: 100%;

  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #d9d9d9;
  padding: 0.75em 1rem;
  padding-right: 2.5em;
  border-radius: 4px;
  background-color: white; // For Firefox.
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7"><path d="M13.2515 0.369095C13.4858 0.60341 13.4858 0.983309 13.2515 1.21762L8.1302 6.33895C7.50536 6.96379 6.49229 6.96379 5.86745 6.33895L0.748002 1.21949C0.513687 0.98518 0.513687 0.605281 0.748002 0.370966C0.982316 0.136652 1.36222 0.136651 1.59653 0.370966L6.71598 5.49042C6.87219 5.64663 7.12546 5.64663 7.28167 5.49042L12.403 0.369095C12.6373 0.134781 13.0172 0.134781 13.2515 0.369095Z" fill="hsl(0, 0%, 20%)"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
`;
