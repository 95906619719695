import { useHistory } from '@shared/core';
import { fetchStashedTemplate } from '@apps/imagineYourCard/Components/ImagineYourCardRoot/ImageStorageManager';
import { LoginState } from './types';
import { useLoginStateContext } from './Login';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useCardCategoryContext } from '@apps/card/routes/Dashboard/components/CardCategoryProvider';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCreateDraftAndRedirectToEditor } from '../../hooks/useCreateDraftAndRedirectToEditor';

export const useCreateDraftAndRedirectAfterEventCreation = () => {
  const history = useHistory();
  const { changeState } = useLoginStateContext();
  const { currentCategory } = useCardCategoryContext();
  const { getShopPath } = useCardsRouterContext();
  const { createDraftAndRedirectToEditor } = useCreateDraftAndRedirectToEditor('signupFlow');

  const createDraftAndRedirect = useEventCallback(() => {
    const data = fetchStashedTemplate();
    if (data) {
      changeState(LoginState.WAITING_FOR_DRAFT);
      createDraftAndRedirectToEditor(data);
    } else {
      // When there is no stashed template, redirect to the card dashboard
      history.push(`/event/edit` + getShopPath('wedding', currentCategory));
    }
  });

  return { createDraftAndRedirect };
};
