import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { RemoteFeatureFlagKeys, useFeatureValue } from '@shared/core/featureFlags';
import { useHistory } from '@react-router';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { Category } from '../../CardTypeSelector/CardTypeSelector';
import { DefaultImageStyles } from '../../styles';

export type theme = {
  themeID: string;
  category: StationeryTemplateCategoryEnum;
};

type Styles = string[];

export type seed = {
  prompt: string;
  themes: {
    themeID: string;
    category: StationeryTemplateCategoryEnum;
    resourceID: string;
    oomph?: number;
    m?: string;
  }[];
};

interface OccasionPayload {
  promptList: string[];
  themeList: theme[];
  cannedPromptSeedImages: seed[];
  categories: Category[];
  styles: Styles;
}

const isStyles = (value: unknown): value is Styles => {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (value.some((item: unknown) => typeof item !== 'string')) {
    return false;
  }
  return true;
};

const isCategories = (value: unknown): value is Category[] => {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (value.some((item: unknown) => !isCategory(item))) {
    return false;
  }
  return true;
};

const isCategory = (value: unknown): value is Category => {
  if (typeof value !== 'object') {
    return false;
  }
  if (!value?.hasOwnProperty('name')) {
    return false;
  }
  if (!value?.hasOwnProperty('category')) {
    const asCategory = value as Category;
    if (!(asCategory.category in StationeryTemplateCategoryEnum)) {
      return false;
    }
    return false;
  }
  return true;
};

const isSeed = (value: unknown): value is seed => {
  if (typeof value !== 'object') {
    return false;
  }
  if (!value?.hasOwnProperty('prompt')) {
    return false;
  }
  if (!value?.hasOwnProperty('themes')) {
    return false;
  }

  const themes = (value as seed).themes;
  if (!Array.isArray(themes)) {
    return false;
  }

  const areAnyInvalid = themes.some((item: object) => {
    !item.hasOwnProperty('themeID') || !item.hasOwnProperty('category') || !item.hasOwnProperty('resourceID');
  });

  if (areAnyInvalid) {
    return false;
  }

  return true;
};

const isSeedList = (value: unknown): value is theme[] => {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (value.some((item: unknown) => !isSeed(item))) {
    return false;
  }
  return true;
};

const isPromptList = (value: unknown): value is string[] => {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (value.some((item: unknown) => typeof item !== 'string')) {
    return false;
  }
  return true;
};

const isTheme = (value: unknown): value is theme => {
  if (typeof value !== 'object') {
    return false;
  }
  if (!value?.hasOwnProperty('themeID')) {
    return false;
  }
  if (!value?.hasOwnProperty('category')) {
    return false;
  }
  return true;
};

const isThemeList = (value: unknown): value is theme[] => {
  if (!value) {
    return false;
  }
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length === 0) {
    return false;
  }
  if (value.some((item: unknown) => !isTheme(item))) {
    return false;
  }
  return true;
};

const isOccasion = (value: unknown): value is OccasionPayload => {
  if (!value) {
    return false;
  }
  if (typeof value !== 'object') {
    return false;
  }
  if (value?.hasOwnProperty('promptList') && !isPromptList((value as OccasionPayload).promptList)) {
    return false;
  }
  if (value?.hasOwnProperty('themeList') && !isThemeList((value as OccasionPayload).themeList)) {
    return false;
  }
  if (value?.hasOwnProperty('cannedPromptSeedImages') && !isSeedList((value as OccasionPayload).cannedPromptSeedImages)) {
    return false;
  }
  if (value?.hasOwnProperty('categories') && !isCategories((value as OccasionPayload).categories)) {
    return false;
  }
  if (value?.hasOwnProperty('styles') && !isStyles((value as OccasionPayload).styles)) {
    return false;
  }

  return true;
};

type OccasionConfig = {
  occasionName: 'wedding' | 'holiday';
  occasionFeatureFlag: RemoteFeatureFlagKeys;
};

const occasions: readonly OccasionConfig[] = [
  {
    occasionName: 'wedding',
    occasionFeatureFlag: 'printImagineOptions'
  },
  {
    occasionName: 'holiday',
    occasionFeatureFlag: 'printImagineHoliday'
  }
] as const;

export type OccasionName = OccasionConfig['occasionName'];

const isValidOccasion = (value: string): { isValid: boolean; firstMatch?: OccasionName; featureFlagKey: RemoteFeatureFlagKeys } => {
  let featureFlagKey = occasions[0].occasionFeatureFlag;
  const occasion = occasions.find(o => {
    if (o.occasionName === value) {
      featureFlagKey = o.occasionFeatureFlag;
      return true;
    }
    return false;
  });
  return {
    isValid: occasion !== undefined,
    firstMatch: occasion === undefined ? occasions[0].occasionName : undefined,
    featureFlagKey
  };
};

const defaultThemeList: theme[] = [
  {
    themeID: 'vineyard_vignette',
    category: StationeryTemplateCategoryEnum.saveTheDate
  },
  {
    themeID: 'boho_arch',
    category: StationeryTemplateCategoryEnum.saveTheDate
  },
  {
    themeID: 'bohemian_eucalyptus',
    category: StationeryTemplateCategoryEnum.saveTheDate
  },
  {
    themeID: 'lavender_yard',
    category: StationeryTemplateCategoryEnum.saveTheDate
  }
];

const defaultPrompts: string[] = [
  'Dreamy Italian coastal town',
  'Countryside rustic wedding scenery',
  'Wildflower nouveau blooming bouquet',
  'Hand drawn nautical patterns',
  'Bow border with gold accents',
  'Alpine dreamy landscape at sunrise',
  'Chateau outline',
  'Tropical leaves drawing',
  'Sunflowers in different sizes bird view angle'
];

export const useImagineOptions = (occasion: string) => {
  const history = useHistory();
  const { getCardImaginePath } = useCardsRouterContext();
  const occasionValidator = isValidOccasion(occasion);
  const { value, payload } = useFeatureValue(occasionValidator.featureFlagKey);

  let trendingPrompts = defaultPrompts;
  let themes = defaultThemeList;
  let seeds = Array<seed>();
  let categories = undefined;
  const defaultStyles = DefaultImageStyles.map(style => style.label);
  let styles = defaultStyles;

  if (!occasionValidator.isValid) {
    history.push(getCardImaginePath(occasionValidator.firstMatch));
  }
  if (value === 'on') {
    if (isOccasion(payload)) {
      trendingPrompts = payload.promptList;
      themes = payload.themeList;
      seeds = payload.cannedPromptSeedImages;
      categories = payload.categories;
      styles = payload.styles ?? defaultStyles;
    }
  }

  return { trendingPrompts, themes, seeds, categories, styles };
};
