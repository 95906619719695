import { useCreateStationeryDraftMutation } from '@graphql/generated';
import { useCardsRouterContext } from '@apps/card/Card.routes';

import { useExistingDraftsWarningContext } from '../ExistingDraftsWarningProvider';
import { useRestrictedInternationalShippingWarningContext } from '../RestrictedInternationalShippingWarningProvider';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFeatureValue } from '@shared/core/featureFlags';
import { cloneDeep } from 'lodash-es';
import { CardEnvelopeRecipientAddressLayout, ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';

import { resetCardBackAndEnvelope } from '../DesignsGallery/utils';
import { Format, MinimalRequiredTemplateDataForDraft } from '../DesignsGallery/DesignsGallery.types';

type UseCustomizePaperCardArgs = {
  eventId?: string;
  supportedFormats: Format;
};

export const useCustomizePaperCard = (args: UseCustomizePaperCardArgs) => {
  const { eventId, supportedFormats } = args;
  const { existingDraftsWarningDialog, restrictedInternationalShippingWarningDialog, cardDraftCreated, pdpCustomizeNowClicked } = useCardTelemetry({});

  const { value: enablePrintRecipientsStep } = useFeatureValue('printEnableRecipientsStep');
  const { goToCustomizeDraft } = useCardsRouterContext();
  const { checkForExistingDraftOrCreateNew } = useExistingDraftsWarningContext();
  const { checkIfUserIsRestricted } = useRestrictedInternationalShippingWarningContext();

  const [createStationeryDraft, { loading }] = useCreateStationeryDraftMutation({
    refetchQueries: () => ['GetDigitalAndPaperDraftsByEventId', 'GetExistingEventStationeryDraftsWithTemplate']
  });

  // TODO: https://withjoy.atlassian.net/browse/PRNT-1214
  const handleOnCustomizeCardClick = useEventCallback((template: MinimalRequiredTemplateDataForDraft, subtotalInMinorUnits?: number) => {
    checkIfUserIsRestricted(
      { themeId: template.themeId, category: template.category },
      () =>
        // This callback is called when the RestrictedInternationalShipping warning dialog is about to be shown.
        restrictedInternationalShippingWarningDialog.enter(),
      () =>
        checkForExistingDraftOrCreateNew(
          template.id,
          async existingDraftsCount => {
            try {
              pdpCustomizeNowClicked({ themeId: template.themeId, supportedFormats, format: 'paper' });
              const cardJSON = cloneDeep(template.themeJSON) as ThemeJson;
              resetCardBackAndEnvelope(cardJSON);
              if (enablePrintRecipientsStep === 'on') {
                // If delivery step is enabled, set the recipient address envelope defaults (this need to be made on the BE)
                cardJSON.card.envelope.recipientAddressLayout = CardEnvelopeRecipientAddressLayout.none;
                cardJSON.card.envelope.recipientAddressLayers = [];
                cardJSON.card.envelope.recipientIdList = [];
              }
              const response = await createStationeryDraft({
                variables: {
                  payload: {
                    eventId,
                    cardJSON,
                    stationeryTemplateId: template.id
                  }
                }
              });
              const maybeDraftId = response.data?.createStationeryDraft.id;

              if (maybeDraftId) {
                cardDraftCreated({
                  draftId: maybeDraftId,
                  themeId: template.themeId,
                  stationeryTemplateCategory: template.category,
                  page: 'pdpModal',
                  existingDraftsCount,
                  subtotalInMinorUnits,
                  isFavorite: !!template.isFavorite,
                  isDigital: false,
                  isPremiumDesign: template.premium,
                  format: 'paper'
                });
                goToCustomizeDraft(maybeDraftId);
              }
            } catch (error) {
            } finally {
            }
          },
          () => {
            // This callback is called when the warning dialog is about to be shown.
            existingDraftsWarningDialog.enter();
          }
        )
    );
  });

  return {
    handleOnCustomizeCardClick,
    loading
  };
};
