import { Listbox } from '@headlessui/react';
import { animationTransition } from '@shared/utils/animationTransition';

/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

export const SelectorButton = styled(Listbox.Button)`
  width: 100%;
  height: 56px;
  padding: 0 6px;
  border: 1px solid ${({ theme }) => theme.colors.mono5};
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline-color: ${({ theme }) => theme.colors.purple7};
`;

export const ButtonImage = styled.img`
  height: 44px;
  width: 44px;
  object-fit: cover;
  border-radius: 2px;
`;

export const NoPhotoOptionThumbnail = styled.div`
  height: 44px;
  width: 44px;
  border: 1px solid ${({ theme }) => theme.colors.mono5};
  border-radius: 2px;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 63px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.mono5};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
`;

export const ButtonText = styled.p`
  font-size: ${({ theme }) => theme.typography.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.variants.body2.fontWeight};
  line-height: ${({ theme }) => theme.typography.variants.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.variants.body2.letterSpacing};
  margin-left: 12px;
  transition: ${animationTransition('color')};
`;

export const ButtonRightSlot = styled.div`
  margin-left: auto;
  margin-right: 10px;
  display: flex;

  & > * {
    margin-left: 12px;
  }
`;

export const ButtonRightIcon = styled.div<{ rotate?: number }>`
  transition: ${animationTransition('transform')};
  transform: rotate(${({ rotate }) => rotate || 0}deg);
`;

export const Divider = styled.div`
  height: 1px;
  margin: 8px 6px;
  background-color: ${({ theme }) => theme.colors.mono3};
`;

export const PhotoButtonText = styled.span`
  font-size: ${({ theme }) => theme.typography.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.typography.variants.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.variants.body2.letterSpacing};
  color: ${({ theme }) => theme.colors.linkText};
  transition: ${animationTransition('color')};
`;

export const UpdatePhotoButton = styled.button`
  font-size: ${({ theme }) => theme.typography.variants.body2.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.typography.variants.body2.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.variants.body2.letterSpacing};
  color: ${({ theme }) => theme.colors.linkText};
  transition: ${animationTransition('color')};

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.linkActive};
  }
`;

export const PhotoSelectorContainer = styled.div`
  position: relative;

  .photoselector-listbox-options {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 60px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07);
    padding: 10px;
    outline-color: ${({ theme }) => theme.colors.purple7};
  }

  .transition {
    transition: ${animationTransition('opacity')} !important;
  }

  .opacity-100 {
    opacity: 1;
    visibility: visible;
  }

  .opacity-0 {
    opacity: 0;
    visibility: hidden;
  }

  .photoselector-listbox-option {
    width: 100%;
    height: 56px;
    padding: 0 6px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: ${animationTransition('background-color')};

    &.upload-option {
      justify-content: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.mono1};

      ${ButtonText} {
        color: ${({ theme }) => theme.colors.purple6};
      }

      ${PhotoButtonText}, .upload-option svg {
        color: ${({ theme }) => theme.colors.linkHover};
      }
    }

    &:active,
    &.active-option {
      background-color: ${({ theme }) => theme.colors.mono2};

      ${ButtonText} {
        color: ${({ theme }) => theme.colors.purple8};
      }

      ${PhotoButtonText}, .upload-option svg {
        color: ${({ theme }) => theme.colors.linkActive};
      }
    }
  }
`;
