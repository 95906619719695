import React, { useMemo } from 'react';
import { AssetContainer, CardPreviewPaneProps, GalleryAsset, GenericPreviewPane } from '../GenericPreviewPane';
import { StationeryDraftFormat } from '@graphql/generated';
import { Shadow } from '@apps/card/routes/CardCustomizer/steps/CardDesign/CardPagePreview';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { PageScaler } from '@apps/card/routes/CardCustomizer/components/PageScaler';
import { FitScaler } from '@apps/card/routes/CardCustomizer/components/FitScaler';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { CardPreviewFavoriteButton } from '../CardCustomizationContent/CardPreviewFavoriteButton';
import { useGalleryGridStyle } from '../../../DesignsGallery/useGalleryGridStyle';

const CARD_EDITOR_PREVIEW_URL = 'https://withjoy.com/assets/public/card-editor-preview.jpg';
const SMS_DELIVERY_PREVIEW_URL = 'https://withjoy.com/assets/public/text-delivery-preview.jpg';

export const CardPreviewPane = (props: CardPreviewPaneProps) => {
  const isMobile = useIsMobileScreen();
  const { cardFront, cardShape, thumbnailUrl, eventId, templateId, isFavorite, themeId, templateCategory } = props;
  const { aspectRatioFrameBoxShadow } = useGalleryGridStyle();

  const assets: Array<GalleryAsset> = useMemo(() => {
    return [
      {
        render: () => {
          const content = (
            <Shadow shadow={aspectRatioFrameBoxShadow as string} shape={cardShape}>
              <Page width={5} height={7} page={cardFront} shape={cardShape} format={StationeryDraftFormat.digital} />
            </Shadow>
          );

          return (
            <AssetContainer
              key="cardFront"
              label="Front of the Card"
              enforceAspectRatio={!isMobile}
              badge={<CardPreviewFavoriteButton themeId={themeId} templateCategory={templateCategory} eventId={eventId || ''} templateId={templateId} isFavorite={isFavorite} />}
            >
              {isMobile ? <PageScaler>{content}</PageScaler> : <FitScaler>{content}</FitScaler>}
            </AssetContainer>
          );
        },
        thumbnailUrl: thumbnailUrl
      },
      {
        render: () => <AssetContainer enforceAspectRatio key="cardEditor" label="Card Editor" backgroundImageUrl={CARD_EDITOR_PREVIEW_URL} />,
        thumbnailUrl: CARD_EDITOR_PREVIEW_URL
      },
      {
        render: () => <AssetContainer enforceAspectRatio key="smsDelivery" label="SMS Delivery" backgroundImageUrl={SMS_DELIVERY_PREVIEW_URL} />,
        thumbnailUrl: SMS_DELIVERY_PREVIEW_URL
      }
    ];
  }, [thumbnailUrl, aspectRatioFrameBoxShadow, cardShape, cardFront, isMobile, themeId, templateCategory, eventId, templateId, isFavorite]);

  return <GenericPreviewPane {...props} assets={assets} />;
};
