import { JoyLogo } from '@assets/index';
import { TextV2, LinkV2, FormControl, InputV2, ButtonV2 } from '@withjoy/joykit';
import { Email, Lock } from '@withjoy/joykit/icons';
import React from 'react';
import { FormSection } from '../AccountForm.styles';
import { useTranslation } from '@shared/core';
import { useSignInController } from './SignIn.controller';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { SwitchFormHandler } from '../AccountForm.types';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

interface SignInProps {
  switchForm: SwitchFormHandler;
}

const SignIn = ({ switchForm }: SignInProps) => {
  const { t, t2 } = useTranslation('createWedding');
  const translations = t('createAccount');
  const { formik, submissionError, getFieldErrorProps } = useSignInController();
  const signInTranslations = t2('signIn');
  const mobile = useIsMobileOrTablet();

  const switchToCreateAccountForm = useEventCallback(() => switchForm('createAccount'));
  const switchToForgotPasswordForm = useEventCallback(() => switchForm('forgotPassword'));

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormSection gap="24px">
        {!mobile && <JoyLogo />}
        <FormSection gap="40px">
          {!mobile && (
            <FormSection gap="8px">
              <TextV2 textAlign="center" as="h1" typographyVariant="hed6">
                {signInTranslations.heroText}{' '}
              </TextV2>
              <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
                {signInTranslations.helpText}{' '}
                <LinkV2 onClick={switchToCreateAccountForm} textDecoration="none">
                  <TextV2 as="span" typographyVariant="body3" color="linkText">
                    {translations.createAccountCta()}
                  </TextV2>
                </LinkV2>
              </TextV2>
            </FormSection>
          )}
          <FormSection gap="24px" alignment="left">
            <FormControl label={<TextV2 typographyVariant="label3">{signInTranslations.email}</TextV2>}>
              <InputV2
                {...getFieldErrorProps('email')}
                startElement={<Email color="mono10" fill="#767676" size={24} />}
                placeholder={signInTranslations.email}
                {...formik.getFieldProps('email')}
              />
            </FormControl>
            <FormControl label={<TextV2 typographyVariant="label3">{signInTranslations.password}</TextV2>}>
              <InputV2
                type="password"
                {...getFieldErrorProps('password')}
                startElement={<Lock color="mono10" fill="#767676" size={24} />}
                placeholder={signInTranslations.password}
                {...formik.getFieldProps('password')}
              />
            </FormControl>
            {submissionError && (
              <TextV2 textAlign="center" typographyVariant="body1" color="negative6">
                {submissionError}
              </TextV2>
            )}
          </FormSection>
          <FormSection gap="24px">
            <ButtonV2 width="100%" shape="rounded" type="submit" disabled={formik.isSubmitting} intent="primary">
              {signInTranslations.login}
            </ButtonV2>
            <LinkV2 textDecoration="none" onClick={switchToForgotPasswordForm}>
              <TextV2 typographyVariant="body3">{signInTranslations.forgotCredentials}</TextV2>
            </LinkV2>
            {mobile && (
              <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
                {signInTranslations.helpText}{' '}
                <LinkV2 onClick={switchToCreateAccountForm} textDecoration="none">
                  <TextV2 as="span" typographyVariant="body3" color="linkText">
                    {translations.createAccountCta()}
                  </TextV2>
                </LinkV2>
              </TextV2>
            )}
          </FormSection>
        </FormSection>
      </FormSection>
    </form>
  );
};

export default SignIn;
