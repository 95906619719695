import React, { createContext, useEffect, useRef, useState } from 'react';
import { Setter } from '@apps/imagineYourCard/imagineYourCard.utils';
import { pageEdgeMargin, pageEdgeMarginMobile, responsiveSettings } from './cardConstants';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { categoryPrompts, examplePromptData } from '../HintText/FirstTimeExperienceConfig';
import { AICardDemoContainer } from '../AICardPreview/AICardDemo';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useAddStarterCards } from '@apps/imagineYourCard/hooks/useAddStarterCards';
import { cloneDeep } from 'lodash-es';
import { PromptBar } from '../PromptBar/PromptBar';
import { previewReducerAction, usePreviewReducer } from './hooks/usePreviewReducer';
import { usePreviews } from './hooks/usePreviews';
import { OccasionName, theme, useImagineOptions } from './hooks/useImagineOptions';
import { Flex, TextV2 } from '@withjoy/joykit';
import { CardTypeSelector } from '../CardTypeSelector/CardTypeSelector';
import { HeaderContainer, PreviewsGrid } from './ImagineYourCardRoot.styles';
import { checkDemo } from './ImageStorageManager';
import { useTranslation } from '@shared/core';
import { useRouteMatch } from '@react-router';
import { ImagineTelemetryProvider, useImagineTelemetry } from './ImagineYourCard.telemetry';
import { useGetImagineContext } from './hooks/useGetImagineContext';
import { MinimalRequiredTemplateDataForDraft } from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { useAuth } from '@shared/components/AuthProvider';
import { ControlCenter } from './ControlCenter/ControlCenter';
import { useHistory } from '@react-router';
import { NAMED_CATEGORY_TO_STATIONERY_TEMPLATE_ENUM_MAP, useCardsRouterContext } from '@apps/card/Card.routes';
import { useQueryParamHelper } from '@shared/core/queryString';
export const genQuantity = 4;

export enum supportedModels {
  SDXL = 'SDXL',
  SD3 = 'SD3',
  LCM = 'LCM'
}

export type PreviewContextPayload = {
  previewDispatcher: React.Dispatch<previewReducerAction>;
  previewLength: number;
  setLoadingCount: Setter<number>;
  cardType: StationeryTemplateCategoryEnum;
  setPromptField: Setter<string>;
  handleCustomizeNowClickOverride?: (data: MinimalRequiredTemplateDataForDraft) => void;
  styles: string[];

  mOverride: supportedModels | 'none';
  oomphOverride: number | 'none';
  styleOverride: string | null;
  themeOverride: string | null;
  themes: theme[];
  setMOverride: Setter<supportedModels | 'none'>;
  setOomphOverride: Setter<number | 'none'>;
  setStyleOverride: Setter<string | null>;
  setThemeOverride: Setter<string | null>;
};

type ImagineYourCardRootProps = {
  handleCustomizeNowClickOverride?: (data: MinimalRequiredTemplateDataForDraft) => void;
};

const defaultCategory = StationeryTemplateCategoryEnum.saveTheDate;
const defaultOccasion = 'wedding' as OccasionName;
const defaultPrompts = categoryPrompts(defaultCategory);
const demoPrompts = cloneDeep(defaultPrompts);

type ValidImaginePageMatch = {
  occasion: OccasionName;
  category: StationeryTemplateCategoryEnum;
};

const routeHasValidCategory = (matchParams: Record<string, string>): matchParams is ValidImaginePageMatch => {
  return matchParams.occasion !== undefined && matchParams.category in NAMED_CATEGORY_TO_STATIONERY_TEMPLATE_ENUM_MAP;
};

const useGetCategory = () => {
  const history = useHistory();
  const { getCardImaginePath, routes } = useCardsRouterContext();
  const cardCategoryMatch = useRouteMatch({ path: routes.imagine.paths });
  if (cardCategoryMatch && routeHasValidCategory(cardCategoryMatch.params)) {
    const category = NAMED_CATEGORY_TO_STATIONERY_TEMPLATE_ENUM_MAP[cardCategoryMatch.params.category];
    const occasion = cardCategoryMatch.params.occasion;
    return { category, occasion };
  }

  if (!cardCategoryMatch) {
    history.push(getCardImaginePath('wedding', defaultCategory));
  }

  return { category: defaultCategory, occasion: defaultOccasion };
};

export const PreviewContext = createContext<PreviewContextPayload | null>(null);
export const DemoStateContext = createContext<boolean | null>(null);
const ImagineYourCardRoot = (props?: ImagineYourCardRootProps) => {
  const { t } = useTranslation('stationery');
  const { heading, subheading } = t('imagineCardAiApp', 'header');
  const { handleCustomizeNowClickOverride } = props as ImagineYourCardRootProps;
  const history = useHistory();
  const { getCardImaginePath } = useCardsRouterContext();
  const { category: cardType, occasion } = useGetCategory();

  const demoIndex = useRef(0);
  const [isDemoRunning, setIsDemoRunning] = useState(false);

  const isFirstTime = useRef(false);
  const hasCheckedLocalStorage = useRef(false);

  const [prompt, setPrompt] = useState('');

  const [mOverride, setMOverride] = useState<supportedModels | 'none'>('none');
  const [oomphOverride, setOomphOverride] = useState<number | 'none'>('none');
  const [styleOverride, setStyleOverride] = useState<string | null>(null);
  const [themeOverride, setThemeOverride] = useState<string | null>(null);

  const { previews, previewDispatcher } = usePreviewReducer();
  const { trendingPrompts, themes, seeds, styles, categories } = useImagineOptions(occasion);
  const { addImage, loadingCount, setLoadingCount, addLocalStorage } = usePreviews({ previewDispatcher, themes, cardType, seeds, styles, styleOverride, themeOverride });
  const { addCards, prepLocalStorage } = useAddStarterCards({ previewDispatcher });

  const cols = useResponsive(responsiveSettings, 1)[0];
  const mobile = cols <= 2;
  const page = useGetImagineContext();
  const { imagineImageGenerated } = useImagineTelemetry({ page });

  const { currentUser } = useAuth();
  const isSuperAdmin = Boolean(currentUser && currentUser.profile?.superAdmin);
  const params = useQueryParamHelper();
  const adminPanelEnabled = isSuperAdmin && !mobile && params.getValue('admin') == 'true';

  useEffect(() => {
    if (!isFirstTime.current) {
      const hasDemo = checkDemo();
      if (!hasDemo) {
        isFirstTime.current = true;
        setIsDemoRunning(true);
        prepLocalStorage();
        hasCheckedLocalStorage.current = true;
      } else {
        if (!hasCheckedLocalStorage.current) {
          hasCheckedLocalStorage.current = true;
          addLocalStorage();
        }
      }
    }
  }, [addLocalStorage, prepLocalStorage]);

  const generate = (prompt: string) => {
    imagineImageGenerated({
      category: cardType,
      prompt
    });
    addImage(prompt);
  };

  const onPromptFocus = () => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      addCards(true);
    }
  };

  const onHintCompletion = (prompt: string) => {
    let index = -1;
    const item = examplePromptData.find((entry, i) => {
      if (entry.prompt === prompt) {
        index = i;
        return true;
      }
      return false;
    });
    // Track and splice prompts that are already used.
    examplePromptData.splice(index, 1);

    if (item) {
      const images = item.images;
      for (let i = images.length - 1; i >= 0; i--) {
        const image = images[i];
        previewDispatcher({
          type: 'addPreview',
          newPreview: (
            <AICardDemoContainer
              index={genQuantity - (i % genQuantity)}
              promptPayload={{ prompt, style: image.style }}
              src={image.src}
              templateId={image.theme}
              category={image.category}
            />
          )
        });

        demoIndex.current++;
      }
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <ImagineTelemetryProvider>
      <div style={{ minHeight: '100vh' }}>
        <PreviewContext.Provider
          value={{
            previewDispatcher,
            setLoadingCount,
            cardType,
            setPromptField: setPrompt,
            previewLength: previews.length,
            handleCustomizeNowClickOverride,
            styles,
            mOverride,
            oomphOverride,
            styleOverride,
            setMOverride,
            setOomphOverride,
            setStyleOverride,
            themes,
            themeOverride,
            setThemeOverride
          }}
        >
          <CardTypeSelector
            cardType={cardType}
            onSelectType={type => {
              history.push(getCardImaginePath(occasion, type));
              if (isFirstTime.current) {
                addCards();
                isFirstTime.current = false;
                setIsDemoRunning(false);
              }
            }}
            categoryList={categories}
          />
          {adminPanelEnabled && <ControlCenter />}
          <HeaderContainer>
            <Flex flexDirection="column" gap={mobile ? '4px' : '8px'} textAlign="center" padding="0px 20px 0px 20px">
              <TextV2 typographyVariant={mobile ? 'HedMobile32' : 'Hed56'}>{heading()}</TextV2>
              <TextV2 typographyVariant={mobile ? 'FeatureCopy17' : 'display1'} color="mono12" fontWeight="regular">
                {subheading()}
              </TextV2>
            </Flex>
          </HeaderContainer>
          <PromptBar
            isDemoRunning={isDemoRunning}
            onSubmit={(prompt: string) => {
              generate(prompt);
            }}
            handleStopDemo={() => {
              setIsDemoRunning(false);
            }}
            demoPrompts={demoPrompts}
            handleHintCompletion={onHintCompletion}
            onFocus={onPromptFocus}
            prompt={prompt}
            inputFieldProps={{
              value: prompt,
              onChange: value => {
                setPrompt(value);
              }
            }}
            trendingPrompts={trendingPrompts}
            onSetPrompt={prompt => {
              setPrompt(prompt);
            }}
            enabled={loadingCount === 0}
          />
          <PreviewsGrid $cols={cols} useMargin={true} pageMargin={mobile ? pageEdgeMarginMobile : pageEdgeMargin}>
            {previews.map(preview => {
              return <div key={preview.index}>{preview.node}</div>;
            })}
          </PreviewsGrid>
        </PreviewContext.Provider>
      </div>
    </ImagineTelemetryProvider>
  );
};

export default ImagineYourCardRoot;
