/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Select Recipients Email',
  definitions: {
    '24': {
      viewBox: '0 0 20 20',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.54991 8.20222C9.37245 8.20222 10.8499 6.72476 10.8499 4.90222C10.8499 3.07968 9.37245 1.60222 7.54991 1.60222C5.72737 1.60222 4.24991 3.07968 4.24991 4.90222C4.24991 6.72476 5.72737 8.20222 7.54991 8.20222ZM12.0499 4.90222C12.0499 6.47641 11.2416 7.86181 10.0174 8.66601C10.5572 8.87422 11.0709 9.15053 11.5456 9.48963C11.8352 9.69646 11.7776 10.1284 11.4779 10.3202C11.4401 10.3444 11.4027 10.3689 11.3655 10.3939C11.1766 10.5204 10.9278 10.5184 10.7398 10.3906C9.80184 9.75283 8.69265 9.40629 7.54992 9.40629C6.04288 9.40623 4.59374 10.0089 3.51889 11.0881C2.44467 12.1665 1.8315 13.6338 1.81719 15.1719V17.2905C1.81719 17.5667 2.04105 17.7905 2.31719 17.7905H8.50583C8.71968 17.7905 8.90804 17.9273 8.98843 18.1255C8.99939 18.1525 9.01053 18.1794 9.02185 18.2062C9.17259 18.5636 8.92673 18.9905 8.53886 18.9905H1.73691C1.43994 18.9905 1.15513 18.8711 0.945146 18.6584C0.735158 18.4458 0.617188 18.1574 0.617188 17.8566V15.1666C0.632895 13.3151 1.37023 11.5448 2.66868 10.2412C3.36248 9.54465 4.18645 9.01147 5.08235 8.66596C3.85818 7.86175 3.04991 6.47637 3.04991 4.90222C3.04991 2.41694 5.06463 0.402222 7.54991 0.402222C10.0352 0.402222 12.0499 2.41694 12.0499 4.90222ZM15.1984 11.2529C15.5297 11.2529 15.7984 11.5215 15.7984 11.8529V14.7021L18.7828 14.7021C19.1142 14.7021 19.3828 14.9707 19.3828 15.3021C19.3828 15.6335 19.1142 15.9021 18.7828 15.9021H15.7984V18.9978C15.7984 19.3292 15.5297 19.5978 15.1984 19.5978C14.867 19.5978 14.5984 19.3292 14.5984 18.9978V15.9021H11.6378C11.3065 15.9021 11.0378 15.6335 11.0378 15.3021C11.0378 14.9707 11.3065 14.7021 11.6378 14.7021H14.5984V11.8529C14.5984 11.5215 14.867 11.2529 15.1984 11.2529Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
