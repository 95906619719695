import { createContext } from '@shared/utils/createContext';
import React, { useMemo } from 'react';
import { useFormattedPrintFiltersFacetHref } from './useFormattedPrintFiltersFacetHref';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useHistory } from '@shared/core';
import { useDisclosure } from '@withjoy/joykit';
import { activePrintFiltersCacheApi } from './utils/activePrintFiltersCache';

type DesignGalleryFiltersProviderContext = {
  isFilterDrawerOpen: boolean;
  openFiltersDrawer: () => void;
  closeFilterDrawer: () => void;
  clearAllFilters: () => void;

  getActiveFilterCountByGroupCode: (groupCode: string) => number;
  getTotalActiveFilterCount: () => number;
};
const [Provider, useDesignGalleryFiltersContext] = createContext<DesignGalleryFiltersProviderContext>({ name: 'StationeryFiltersProvider' });

export const DesignGalleryFiltersProvider: React.FC = ({ children }) => {
  const { resetFiltersHref } = useFormattedPrintFiltersFacetHref();

  const reactHistory = useHistory();

  const { isOpen: isFilterDrawerOpen, onOpen: onOpenFilterDrawer, onClose: onCloseFilterDrawer } = useDisclosure();

  const handleOnClearAllFilters = useEventCallback(() => {
    reactHistory.push(resetFiltersHref());
  });

  const handleOnOpenFiltersDrawer = useEventCallback(() => {
    onOpenFilterDrawer();
  });

  const ctx = useMemo<DesignGalleryFiltersProviderContext>(() => {
    return {
      clearAllFilters: handleOnClearAllFilters,
      openFiltersDrawer: handleOnOpenFiltersDrawer,
      isFilterDrawerOpen,
      closeFilterDrawer: onCloseFilterDrawer,
      getActiveFilterCountByGroupCode: activePrintFiltersCacheApi.getActiveFilterCountByGroupCode,
      getTotalActiveFilterCount: activePrintFiltersCacheApi.getTotalActiveFilterCount
    };
  }, [handleOnClearAllFilters, handleOnOpenFiltersDrawer, isFilterDrawerOpen, onCloseFilterDrawer]);
  return <Provider value={ctx}>{children}</Provider>;
};

export { useDesignGalleryFiltersContext };
