import React, { CSSProperties, useState } from 'react';
import ReactContentEditable from 'react-contenteditable';
import { CurrentSelectionStrategy } from '../steps/CardDesign/SelectionStrategy';

interface ContentEditableProps {
  value: string;
  isEditing: boolean;
  onChange?: (value: string) => void;
  style?: CSSProperties;
}

// `value` snapshot on init. This suspends 2-way binding.
// If we set ContentEditable.html to something other than what was previously typed,
// the browser will reset the caret position. Thus we want to prevent that loop.
export const ContentEditable = (props: ContentEditableProps) => {
  const { value, isEditing, onChange, style } = props;
  const ref = CurrentSelectionStrategy.useSelectAllText(isEditing);
  const [html, setHtml] = useState(`${value}\n`);

  return (
    <div style={style}>
      <ReactContentEditable
        innerRef={ref}
        html={html}
        onChange={e => {
          setHtml(e.target.value);
          const innertText = ref.current?.innerText ?? '';
          onChange?.(innertText.replace(/\n$/, ''));
        }}
        style={
          {
            outline: 'none',
            display: 'inline-block'
          } as CSSProperties
        }
      />
    </div>
  );
};
