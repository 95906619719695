import { Flex, StyleSystemProps, styled } from '@withjoy/joykit';

export const Wrapper = styled(Flex)``;
const wrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  flexGrow: 1,
  padding: 6,
  paddingTop: 5
};

export const styles = {
  wrapper: wrapperStyles
};
