/**
 * Returns true if the given JSX element matches the given component type.
 *
 * NOTE: This function only checks equality of `displayName` for performance and
 * to tolerate multiple minor versions of a component being included in one
 * application bundle.
 * @param element JSX element in question
 * @param ComponentType desired component type of element
 */
export function isElementOfComponentType<P = {}>(element: any, ComponentType: React.ComponentType<P>): element is React.ReactElement<P> {
  return element !== null && element.type !== null && element.type.displayName !== null && element.type.displayName === ComponentType.displayName;
}
