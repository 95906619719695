import React, { useCallback } from 'react';
import { Flex, TextV2, CheckboxV2, ButtonV2 } from '@withjoy/joykit';
import { BookingDialogStep, OptionReminder } from '../BookingDialog.const';
import { ImageWrapper, InfoWrapper, TitleText, RadioButton, checkboxOverrides, CTAContainer } from '../BookingDialog.styles';
import { useTranslation } from '@shared/core';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { useBookingDialogReminderOptions } from '../hooks/useBookingDialogReminderOptions';

export const BookingDialogReminder: React.FC<{ reminderSelected: OptionReminder; setReminderSelected: (reminder: OptionReminder) => void; setStep: (step: number) => void }> = ({
  setReminderSelected,
  reminderSelected,
  setStep
}) => {
  const { t2 } = useTranslation('bookingDialog');
  const { titleText, ctaConfirm } = t2('reminder');

  const { bookingDialogReminder } = useDashboardTelemetry();

  const OPTIONS_REMINDER = useBookingDialogReminderOptions();

  const handleCTAClick = useCallback(() => {
    bookingDialogReminder(+reminderSelected.value);
    setStep(BookingDialogStep.Success);
  }, [reminderSelected, bookingDialogReminder, setStep]);

  return (
    <>
      <ImageWrapper
        display={{ _: 'none', sm: 'unset' }}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        backgroundImage={`url('https://withjoy.com/assets/public/booking-hotel-variant3.png')`}
        backgroundPosition={['0% 0%', '0% 0%', '50% 50%']}
      />
      <InfoWrapper rowGap={{ _: 24, sm: 40 }}>
        <TitleText typographyVariant={'HedMobile32'} paddingBottom={0} paddingTop={{ _: 32, sm: 'initial' }}>
          {titleText}
        </TitleText>
        <Flex flexDirection="column" rowGap={16}>
          {OPTIONS_REMINDER.map(opt => (
            <RadioButton key={opt.id} as="label" htmlFor={opt.id} active={opt.id === reminderSelected.id} onClick={() => setReminderSelected(opt)}>
              <TextV2 typographyVariant="hed2" color="mono14">
                {opt.label}
              </TextV2>
              <CheckboxV2
                isChecked={reminderSelected.id == opt.id}
                pointerEvents={'none'}
                type="radio"
                checked={reminderSelected.id == opt.id}
                value={opt.value}
                overrides={checkboxOverrides}
                borderRadius="100%"
                id={opt.id}
              />
            </RadioButton>
          ))}
        </Flex>
        <CTAContainer>
          <ButtonV2 width={['100%']} intent="neutral" variant="solid" onClick={handleCTAClick}>
            {ctaConfirm}
          </ButtonV2>
        </CTAContainer>
      </InfoWrapper>
    </>
  );
};
