import React, { useEffect } from 'react';
import { History } from 'history';
import { useAuth } from '@shared/components/AuthProvider';

export interface AuthCallbackProps
  extends Readonly<{
    history: History<History.PoorMansUnknown>;
  }> {}

export const AuthCallbackPage: React.FC<AuthCallbackProps> = props => {
  const { history } = props;
  const authProvider = useAuth();

  useEffect(() => {
    async function provideAuthentication(): Promise<void> {
      const loginVariables = {}; // no active Event
      await authProvider.provideAuthentication({ history, loginVariables });
    }
    provideAuthentication(); // don't wait on it
    // avoid history and authprovider as deps, otherwise it will provide authentication
    // with every history or authProvider change, e.g. 2x if a redirect route is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};
