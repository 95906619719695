import React, { useCallback } from 'react';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { styles, StyledRoot, StyledBackground, StyledArrow } from './BackToTop.styles';
import { useShouldShowOnScrollYPosition } from '@shared/utils/hooks/useShouldShowOnScrollYPosition';

const THRESHOLD = 100;

export const BackToTop = () => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const shouldShow = useShouldShowOnScrollYPosition(THRESHOLD);

  const handleOnClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return isMobileOrTablet ? null : (
    <StyledRoot className={shouldShow ? 'is-visible' : undefined} __css={styles.root}>
      <StyledBackground as="span" __css={styles.background} onClick={shouldShow ? handleOnClick : undefined} />
      <StyledArrow size={20} __css={styles.arrow} />
    </StyledRoot>
  );
};
