import { styled } from '@withjoy/joykit';

export const StyledEmailPhoto = styled.img`
  width: 100%;
  height: auto;
  background-color: #f7f7f7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: multiply;
  box-sizing: border-box;
`;
