import React from 'react';
import { DialogV2 } from '@withjoy/joykit';
import { Box, ButtonV2, FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useDeleteUserController } from './DeleteUserController';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
  }> {}

export const DeleteUserDialog: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation('account');
  const { error, text, loading, handleConfirmDelete, handleTextUpdate } = useDeleteUserController({ onClose });

  return (
    <DialogV2 id="DeleteUserDialog" onClose={onClose} isOpen={isOpen}>
      <DialogV2.Header>
        <TextV2 typographyVariant="hed1">{t('deleteUser', 'title')()}</TextV2>
      </DialogV2.Header>
      <DialogV2.Body>
        <Box display="flex" flexDirection="column" paddingY={4}>
          <TextV2 typographyVariant="body1">{t('deleteUser', 'message')()}</TextV2>
          <TextV2 typographyVariant="body1">&nbsp;</TextV2>
          <FormControl
            label={
              <TextV2 as="span" typographyVariant="label1">
                {t('deleteUser', 'fieldTitle')()}
              </TextV2>
            }
          >
            <InputV2 key="firstName" onChange={handleTextUpdate} />
          </FormControl>
          {error ? (
            <TextV2 typographyVariant="body1" color={'negative6'}>
              {error?.message}
            </TextV2>
          ) : null}
        </Box>
      </DialogV2.Body>
      <DialogV2.Footer>
        <ButtonV2 intent="primary" onClick={handleConfirmDelete} disabled={text !== 'DELETE' || loading}>
          {t('deleteUser', 'confirmButton')()}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};
