import React from 'react';
import { Box, ButtonV2, Flex } from '@withjoy/joykit';
import { ReactComponent as EmailSentSvg } from '@assets/icons/sent-plane.svg';
import { useConfirmationScreenController } from './ConfirmationScreen.controller';
import { Hed, Dek, StyledLink, StyledIcon } from './ConfirmationScreen.styles';

interface ConfirmationScreenProps
  extends Readonly<{
    eventHandle: string;
    selectedGuestsCount: number;
  }> {}

export const ConfirmationScreen: React.FC<ConfirmationScreenProps> = ({ eventHandle, selectedGuestsCount }) => {
  const { openGuestList, openPaperCard, tConfirmationScreen, dekText } = useConfirmationScreenController({ eventHandle, selectedGuestsCount });
  return (
    <Flex flexDirection="column" alignItems="center" marginBottom={8}>
      <Flex flexDirection="row" gap="4rem">
        <Flex flexDirection="column" alignItems="center" gap={5} width="100%" maxWidth={330}>
          <StyledIcon marginBottom={2}>
            <EmailSentSvg />
          </StyledIcon>
          <Hed textAlign="center">{tConfirmationScreen.hed}</Hed>
          <Dek textAlign="center">
            {dekText}
            <StyledLink onClick={openGuestList}>{tConfirmationScreen.linkText}</StyledLink>.
          </Dek>
          <ButtonV2 onClick={openGuestList} variant="outline" intent="neutral" shape="rounded" fontWeight={600} marginTop={6}>
            {tConfirmationScreen.buttonText}
          </ButtonV2>
        </Flex>
        <Flex flexDirection="column" alignItems="center" gap={5} width="100%" maxWidth={330}>
          <Box as="img" src="https://withjoy.com/assets/public/paper-savethedate-charm.png" width="100%" marginBottom={2} />
          <Hed textAlign="center">{tConfirmationScreen.printTitle}</Hed>
          <Dek textAlign="center">{tConfirmationScreen.printDesc}</Dek>
          <ButtonV2 onClick={openPaperCard} variant="solid" intent="neutral" shape="rounded" fontWeight={600} marginTop={6}>
            {tConfirmationScreen.printAction}
          </ButtonV2>
        </Flex>
      </Flex>
    </Flex>
  );
};
