import React, { useState } from 'react';
import { getPostOfficeDraftType } from './PostOffice.utils';
import { useQueryParamHelper } from '@shared/core/queryString';
import DesignEmailView from './components/DesignEmailView';
import SelectRecipientsView from './components/SelectRecipientsView';
import OptionalTestEmailView from './components/OptionalTestEmailView';
import SendAndTrackView from './components/SendAndTrackView';
import { MIN_HEIGHT, MAX_HEIGHT } from '@shared/components/BottomSheet/BottomSheet.styles';
import { BottomSheetView, NonEmptyArray } from '@shared/components/BottomSheet/context/BottomSheet.provider';

export const usePostOfficeRouterController = () => {
  const { getValueString } = useQueryParamHelper();
  const draftType = getPostOfficeDraftType(getValueString('draftType'));
  const fromDashboard = getValueString('fromDashboard');
  const [email] = useState(getValueString('email'));
  const [isThankYouMessageLoading, setIsThankYouMessageLoading] = useState<boolean>(false);

  const views: NonEmptyArray<BottomSheetView> = [
    { id: 'email', children: <DesignEmailView /> },
    ...(!email ? [{ id: 'recipients', children: <SelectRecipientsView /> }] : []),
    { id: 'test-email', children: <OptionalTestEmailView /> },
    { id: 'send', children: <SendAndTrackView /> }
  ];

  return {
    draftType,
    fromDashboard,
    MIN_HEIGHT,
    MAX_HEIGHT,
    views,
    isThankYouMessageLoading,
    setIsThankYouMessageLoading
  };
};
