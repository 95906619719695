import React, { useMemo } from 'react';
import { LeftPaneCardDesc, LeftPaneCardTitle, LeftPaneContainer, LeftPaneCardsWrapperDesktop, styles } from './EcardLandingLeftPane.styles';
import { Box, ButtonV2, Flex } from '@withjoy/joykit';
import { ReactComponent as TrackDelivery } from '@assets/icons/track-delivery.svg';
import { getEcardTypeDisplayName } from '@apps/ecard/Ecard.utils';
import { useHistory } from '@react-router';
import { EcardDesignInputFields } from '../../../Design/Design.controller';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { EcardType } from '@graphql/generated';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useFeatureValue } from '@shared/core/featureFlags';

interface EcardLandingLeftPaneProps
  extends Readonly<{
    ecardDraftType: EcardType;
    ecardInput: EcardDesignInputFields;
    eventHandle: string;
  }> {}

export const paperLustInfo = {
  url: 'https://paperlust.co/?utm_source=joy&utm_medium=link&utm_campaign=partnership',
  logoUrl: 'https://withjoy.com/assets/public/stationery/pl-logo.svg',
  logoAlt: 'paper-lust'
};

export const paperCultureInfo = {
  url: 'https://www.paperculture.com/?utm_source=joy&utm_medium=link&utm_campaign=partnership',
  logoUrl: 'https://withjoy.com/assets/public/stationery/pc-logo.svg',
  logoAlt: 'paper-culture'
};

export const EcardLandingLeftPane: React.FC<EcardLandingLeftPaneProps> = ({ ecardDraftType, ecardInput, eventHandle }) => {
  const history = useHistory();
  const { t } = useTranslation('emailsAndEcards');
  const tEcardLandingLeftPane = t('ecardLanding', 'ecardLandingLeftPane');
  const isNewPrintExperienceEnabled = useFeatureValue('enableNewPrintExperience').value === 'on';

  const ecardTypeDisplayName = useMemo(() => getEcardTypeDisplayName(ecardDraftType), [ecardDraftType]);
  const telemetry = useEcardsTelemetry();
  const { getValueString } = useQueryParamHelper();
  const fromDashboard = getValueString('fromDashboard');

  const onClickCustomize = () => {
    telemetry.designAndSendClick(ecardDraftType);
    history.push(`${ecardDraftType}/design${fromDashboard ? `?fromDashboard=true` : ''}`);
  };

  const onClickTrack = () => {
    telemetry.viewGuestListClick(ecardDraftType);
    withWindow(() => {
      window.location.pathname = `${eventHandle}/edit/guests`;
    });
  };

  const onClickPaper = () => {
    withWindow(() => {
      window.location.href = `${window.location.origin}/${eventHandle}/edit/cards?ctgy=invitation`;
    });
  };

  return (
    <LeftPaneContainer __css={styles.leftPaneContainer}>
      <Flex flexDirection="column" width="100%">
        <LeftPaneCardsWrapperDesktop>
          <Flex flexDirection={['column', null, 'row']} alignItems={['center', null, 'flex-start']} justifyContent="center" width="100%" gap="4rem">
            <Flex flexDirection="column" alignItems="center" textAlign="center" width="100%" maxWidth={335}>
              <Box as="img" src="https://withjoy.com/assets/public/digital-invitations-charm.png" width="100%" />
              <LeftPaneCardTitle __css={styles.leftPaneCardTitle} typographyVariant="hed3">
                {tEcardLandingLeftPane.title()}
              </LeftPaneCardTitle>
              <LeftPaneCardDesc __css={styles.leftPaneCardDesc} typographyVariant="body2">
                {tEcardLandingLeftPane.desc({ ecardTypeDisplayName })}
              </LeftPaneCardDesc>
              <ButtonV2 intent="neutral" shape="rounded" marginTop={6} onClick={onClickCustomize}>
                {tEcardLandingLeftPane.customize()}
              </ButtonV2>
            </Flex>
            {isNewPrintExperienceEnabled && (
              <Flex flexDirection="column" alignItems="center" textAlign="center" width="100%" maxWidth={335}>
                <Box as="img" src="https://withjoy.com/assets/public/paper-invitations-charm.png" width="100%" />
                <LeftPaneCardTitle __css={styles.leftPaneCardTitle} typographyVariant="hed3">
                  {tEcardLandingLeftPane.landingPrintTitle()}
                </LeftPaneCardTitle>
                <LeftPaneCardDesc __css={styles.leftPaneCardDesc} typographyVariant="body2">
                  {tEcardLandingLeftPane.landingPrintSubTitle()}
                </LeftPaneCardDesc>
                <ButtonV2 intent="neutral" shape="rounded" marginTop={6} onClick={onClickPaper}>
                  {tEcardLandingLeftPane.landingPrintButtonTitle()}
                </ButtonV2>
              </Flex>
            )}
          </Flex>
          <Flex flexDirection="column" alignItems="center" textAlign="center" paddingTop={10} borderTop="1px solid #EBEBEB" marginTop={9}>
            <TrackDelivery />
            <LeftPaneCardTitle __css={styles.leftPaneCardTitle} typographyVariant="hed3">
              {tEcardLandingLeftPane.trackLandingTitle()}
            </LeftPaneCardTitle>
            <LeftPaneCardDesc __css={styles.leftPaneCardDesc} typographyVariant="body2" maxWidth={480}>
              {tEcardLandingLeftPane.trackDesc()}
            </LeftPaneCardDesc>
            <ButtonV2 intent="neutral" shape="rounded" variant="outline" marginTop={6} onClick={onClickTrack}>
              {tEcardLandingLeftPane.trackButtonText()}
            </ButtonV2>
          </Flex>
        </LeftPaneCardsWrapperDesktop>
      </Flex>
    </LeftPaneContainer>
  );
};
