import { useEffect, useMemo, useState } from 'react';
import { useSaveTheDateDispatch, useSaveTheDateState } from '@apps/saveTheDate/state/context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SaveTheDateEmailInputFields } from '@apps/saveTheDate/SaveTheDate.types';
import { getEmailPayload } from './Email.utils';
import { useTranslation } from '@shared/core';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { getEcardInputFromDraft } from '@apps/saveTheDate/SaveTheDate.utils';
import { getFooterTextWithCouplesNames } from '@shared/components/EmailsAndEcards/Emails.utils';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useToast } from '@withjoy/joykit';

export const useEmailController = () => {
  const { toast } = useToast();
  const { toggleIsNavBarOpen, saveEmail, saveEcard } = useSaveTheDateDispatch();
  const { eCardDraft, emailDraft, eCardDataQuery } = useSaveTheDateState();
  const [isEditShown, setIsEditShown] = useState<boolean>(false);
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation('emailsAndEcards');
  const tEmailEditor = t('emailEditor');
  const translations = t('emailEditorPhotoSaveTheDate');
  const date = eCardDataQuery?.eventById?.info.date;
  const ecardId = eCardDraft.id;
  const { launchEmailEditor, editEmailDoneClick, editEmailCancelClick, editEmailContentTrack } = useSaveTheDateTelemetry();
  const { setParentViewState } = useBottomSheetState();

  const initialValues = useMemo(() => {
    return {
      ...emailDraft
    };
  }, [emailDraft]);

  const saveTheDateEmailFormik = useFormik<SaveTheDateEmailInputFields>({
    initialValues,
    validationSchema: Yup.object<SaveTheDateEmailInputFields>({
      subject: Yup.string(),
      replyToEmail: Yup.string().email().required(translations.replyToErrorMessage),
      imageUrl: Yup.string(),
      headline: Yup.string(),
      subheadline: Yup.string(),
      salutation: Yup.string(),
      includeGuestNames: Yup.boolean(),
      message: Yup.string(),
      buttonText: Yup.string(),
      includeEventDate: Yup.boolean(),
      includeAddToCalendarLink: Yup.boolean()
    }),
    enableReinitialize: true,
    onSubmit: async values => {
      let emailPayload;
      if (!ecardId) {
        // if the ecard was never saved, we won't have eCardId to associate with email draft
        // we need to save ecard first
        const newECardId = await saveEcard(getEcardInputFromDraft(eCardDraft));
        if (newECardId) {
          emailPayload = getEmailPayload(values, newECardId, date);
        }
      } else {
        emailPayload = getEmailPayload(values, ecardId, date);
      }
      if (emailPayload) {
        saveEmail(emailPayload);
      }
    }
  });

  const footerText = useMemo(
    () => getFooterTextWithCouplesNames(eCardDataQuery?.eventById?.info?.ownerFirstName, eCardDataQuery?.eventById?.info?.fianceeFirstName, initialValues.replyToEmail),
    [eCardDataQuery?.eventById?.info?.fianceeFirstName, eCardDataQuery?.eventById?.info?.ownerFirstName, initialValues.replyToEmail]
  );

  const toggleEditPanel = () => {
    if (!isEditShown) {
      launchEmailEditor();
      editEmailContentTrack.enter();
    }

    toggleIsNavBarOpen();
    setIsEditShown(!isEditShown);
  };

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, openEmailEditor: toggleEditPanel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    saveTheDateEmailFormik.resetForm();
    setIsEditShown(false);
    toggleIsNavBarOpen();
  };

  const handleUnsavedChangesDialogClose = () => {
    setIsUnsavedChangesDialogOpen(false);
  };

  const handleUnsavedChangesDialogConfirm = () => {
    handleUnsavedChangesDialogClose();
    onCancel();
  };

  const handleCancel = () => {
    editEmailCancelClick();

    if (saveTheDateEmailFormik.dirty) {
      setIsUnsavedChangesDialogOpen(true);
      return;
    }

    onCancel();
  };

  const handleDone = async () => {
    editEmailDoneClick();
    const wasDirty = saveTheDateEmailFormik.dirty;

    if (wasDirty) {
      await saveTheDateEmailFormik.submitForm().then(() => {
        setIsEditShown(false);
        toggleIsNavBarOpen();
      });
    }

    toast(tEmailEditor.doneTooltipText(), {
      icon: '✅'
    });

    if (wasDirty) {
      return;
    }

    handleCancel();
  };

  return {
    saveTheDateEmailFormik,
    handleCancel,
    handleDone,
    isEditShown,
    toggleEditPanel,
    isUnsavedChangesDialogOpen,
    handleUnsavedChangesDialogClose,
    handleUnsavedChangesDialogConfirm,
    translations,
    footerText
  };
};
