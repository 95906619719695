import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core';
import { PersonFragment } from '@graphql/generated';
import { useMemo, useState } from 'react';

interface UseConfirmationScreenControllerArgs
  extends Readonly<{
    eventHandle: string;
    redirect?: string;
    recipientName?: string;
    selectedPeople: readonly Partial<
      PersonFragment & {
        full_name: string;
      }
    >[];
  }> {}

export const useConfirmationScreenController = (args: UseConfirmationScreenControllerArgs) => {
  const { eventHandle, redirect, recipientName } = args;
  const [selectedPeople] = useState(args.selectedPeople);

  const openGuestList = () => {
    withWindow(() => {
      const defaultUrl = `${window.location.origin}/${eventHandle}/edit/email/create?draftType=thankyou`;
      window.location.href = redirect || defaultUrl;
    });
  };

  const { t, t2 } = useTranslation('emailsAndEcards');
  const tConfirmationScreen = t2('confirmAndSend', 'confirmationScreenThankYou');
  const tDekName = t('confirmAndSend', 'confirmationScreenThankYou', 'dek_name');
  const tDekNumber = t('confirmAndSend', 'confirmationScreenThankYou', 'dek_number');

  const dekText = useMemo(() => {
    if (recipientName) {
      return tDekName({ name: recipientName });
    }

    if (selectedPeople.length === 1) {
      return tDekName({ name: selectedPeople[0].full_name });
    }

    return tDekNumber({ number: selectedPeople.length });
  }, [recipientName, selectedPeople, tDekName, tDekNumber]);

  return {
    openGuestList,
    tConfirmationScreen,
    dekText
  };
};
