import { RoutesPaths } from '@shared/core';
import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { EmailsAndEcardsTranslations } from '@locales/generated';
import { EventDesignFragment, EcardType } from '@graphql/generated';

export type EcardRouteNames = 'design' | 'email' | 'confirm';

export type EcardRoutePaths = RoutesPaths<EcardRouteNames>;

export const ecardDraftTypeType = ['savethedate', 'invitation', 'changethedate', 'custom', 'savethedatephoto'] as const;

export enum EcardTypeDisplay {
  savethedate = 'Send Save the Dates',
  invitation = 'Send Invitations',
  changethedate = 'Send Change the Dates',
  custom = 'Send Custom eCards',
  savethedatephoto = 'Send Save the Dates'
}

export enum EcardEditorType {
  DESIGN = 'design',
  CONTENT = 'content'
}

export enum EcardEditorThemeType {
  CUSTOM = 'custom',
  JOY = 'joy',
  NONE = 'none'
}

export interface EcardState
  extends Readonly<{
    emailInput: EmailInputFields;
    ecardInput: EcardDesignInputFields;
    adminReferrer: string;
  }> {}

export type EcardDefaultsTranslations = EmailsAndEcardsTranslations<string>['ecardDefaults'][EcardType];

export type EcardEventDesign = EventDesignFragment;

export type EcardEventInfo = {
  __typename?: 'EventInfo';
  date?: Maybe<string>;
  eventPassword?: Maybe<string>;
  location?: Maybe<string>;
};

export enum EcardLandingRightPanelTabItem {
  ECARD = 'ecard',
  PRINT = 'print'
}

export enum PaperProviderEnum {
  paperlust = 'Paperlust',
  paperculture = 'Paper Culture'
}
