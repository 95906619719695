import React, { useState } from 'react';
import { useDisclosure } from '@withjoy/joykit';
import { useFeatureValue } from '@shared/core/featureFlags';
import { createContext } from '@shared/utils/createContext';
import QuantityDialog from './components/QuantityDialog';

interface Context {
  openQuantityDialog: (quantity: number, recipientsLength: number, goToReview?: () => void) => void;
}

const [Provider, useQuantityDialog] = createContext<Context>({ name: 'QuantityDialog' });

const QuantityDialogProvider: React.FC<{ isInsidePDP?: boolean; isRecipientsStepEnabledFromConfig?: boolean }> = ({
  children,
  isInsidePDP = false,
  isRecipientsStepEnabledFromConfig = false
}) => {
  const { value: printEnableRecipientsStepValue } = useFeatureValue('printEnableRecipientsStep');
  const isRecipientsStepEnabled = printEnableRecipientsStepValue === 'on' || isRecipientsStepEnabledFromConfig;
  const quantityDialog = useDisclosure();
  const [quantity, setQuantity] = useState<number>(10);
  const [recipientsLength, setRecipientsLength] = useState<number>(0);
  const [goToReview, setGoToReview] = useState<() => void>();
  const handleOnOpenQuantityDialog = (quantity: number, recipientsLength: number, goToReview?: () => void) => {
    setQuantity(quantity);
    setRecipientsLength(recipientsLength);
    if (goToReview) {
      // https://react.dev/reference/react/useState#im-trying-to-set-state-to-a-function-but-it-gets-called-instead
      // setState accepst a value or a callback (updater function) as an argument, if we want to set a callback itself
      // as a state, we have to pass a callback that returns the reference of the callback we want to set as the new state
      // this is necessary because when we pass a callback as an argument, react tries to call that and store the result
      // to actually store a function, we have to put () => before that, then react will store the function we pass
      setGoToReview(() => goToReview);
    }
    quantityDialog.onOpen();
  };
  const value = { openQuantityDialog: handleOnOpenQuantityDialog };

  return (
    <Provider value={value}>
      {children}
      {isRecipientsStepEnabled && !isInsidePDP && (
        <QuantityDialog isOpen={quantityDialog.isOpen} onClose={quantityDialog.onClose} quantity={quantity} recipientsLength={recipientsLength} goToReview={goToReview} />
      )}
    </Provider>
  );
};

export { QuantityDialogProvider, useQuantityDialog };
