import React from 'react';
import { ButtonV2, Flex, TextV2, IconV2 } from '@withjoy/joykit';
import { DesignEmail } from '@withjoy/joykit/icons';
import { outlinedDarkButtonOverrides } from '../Shared.styles';
import { useDesignEmailViewController } from './DesignEmailView.controller';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';

interface DesignEmailViewProps {
  onVisible?: () => void;
}

const DesignEmailView: React.FC<DesignEmailViewProps> = props => {
  const { tDesignEmail, handleButtonClick, disableEditEmail } = useDesignEmailViewController(props);
  const { transform } = useBottomSheetDescriptionOffset();

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center">
        <IconV2 size="md" marginRight="12px" display={['block', 'block', 'none !important']}>
          <DesignEmail />
        </IconV2>{' '}
        {tDesignEmail.title()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" flex="1" style={{ transform }}>
        {tDesignEmail.description()}
      </TextV2>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={handleButtonClick} disabled={disableEditEmail}>
        {tDesignEmail.editEmail()}
      </ButtonV2>
    </Flex>
  );
};

export default DesignEmailView;
