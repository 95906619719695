import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useAuth } from '@shared/components/AuthProvider';
import { fetchStashedTemplate } from '@apps/imagineYourCard/Components/ImagineYourCardRoot/ImageStorageManager';
import { LoginRouter } from './LoginRouter';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useManagedSearchParam } from '@apps/admin/common/hooks/useManagedSearchParam';
import { createContext } from '@shared/utils/createContext';
import { LoginState, LoginStateContext } from './types';
import { useCreateDraftAndRedirectAfterEventCreation } from './useCreateDraftAndRedirectAfterEventCreation';
import { useAIRouterContext } from '@apps/imagineYourCard/router';
import { AUTH_REDIRECT_PARAM } from './Components/AccountForm/useAuthRedirectURLs';

export const [Provider, useLoginStateContext] = createContext<LoginStateContext>({ name: 'LoginStateContext' });

type LoginRootProps = {};

const Root = (props: LoginRootProps) => {
  const { onCloseLoginModal } = useAIRouterContext();
  const { state, changeState } = useLoginStateContext();
  const template = useMemo(() => {
    return fetchStashedTemplate();
  }, []);

  const { createDraftAndRedirect } = useCreateDraftAndRedirectAfterEventCreation();
  const { isCurrentUserSettled, currentUser, isLoggedIn } = useAuth();
  const hasHandledStateRef = useRef(false);
  const userHasNoEvents = currentUser?.eventMemberships.length === 0;

  const [, setAuthRedirectParam] = useManagedSearchParam(AUTH_REDIRECT_PARAM);
  const [stateQueryParam, setStateQueryParam] = useManagedSearchParam('state');
  const stateQueryParamRef = useRef(stateQueryParam);

  useEffect(() => {
    setAuthRedirectParam(null);
    setStateQueryParam(null);
  }, [setAuthRedirectParam, setStateQueryParam]);

  const handleLoginStateAfterUserAuth = useEventCallback(() => {
    if (state === LoginState.WAITING_FOR_EVENT || state === LoginState.WAITING_FOR_DRAFT) {
      return;
    }

    if (isLoggedIn) {
      // User is visiting this page logged in
      if (stateQueryParamRef.current) {
        const stateQueryParam = stateQueryParamRef.current;
        // User is returning from redirect
        if (stateQueryParam === 'accountcreated' || stateQueryParam === 'loggedin') {
          // User is coming from account creation page
          // Fresh account that needs an event.
          if (userHasNoEvents) {
            // User has no events. Create one.
            changeState(LoginState.PRESENT_EVENT_FORM);
          } else {
            // User has an event. Just redirect.
            createDraftAndRedirect();
          }
        } else {
          // User shouldn't be here
          // Visited the page with an invalid query string param
          onCloseLoginModal();
        }
      } else {
        // Already logged in, present event form.
        changeState(LoginState.PRESENT_EVENT_FORM);
      }
    } else {
      // User doesn't have an account and is visiting to log in or sign up.
      changeState(LoginState.PRESENT_AUTH_FORM);
    }
  });

  useEffect(() => {
    if (!isCurrentUserSettled || hasHandledStateRef.current) {
      return;
    }

    handleLoginStateAfterUserAuth();
    hasHandledStateRef.current = true;
  }, [handleLoginStateAfterUserAuth, isCurrentUserSettled]);

  return <LoginRouter template={template} />;
};

// This component provides a login + redirect for public facing card designs.
// Previews template prop, and then redirects to a new draft after user authenticates.
export const Login = ({}: LoginRootProps) => {
  const [state, setState] = useState(LoginState.WAITING_FOR_AUTH);

  return (
    <Provider value={{ state, changeState: setState }}>
      <Root />
    </Provider>
  );
};
