import React from 'react';
import { EmailInputFields, EmailEditorTranslations, EmailEditPanelOption, GeneralMessageLinkType } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormikProps } from 'formik';
import { Toggle } from '@withjoy/joykit';
import { Box, Flex, FormControl, InputV2, TextV2, CheckboxV2, TooltipV2 } from '@withjoy/joykit';
import {
  StyledOptionsWrapper,
  StyledOptionContainer,
  StyledBodyTitle,
  StyledBody,
  StyledLabel,
  StyledTextArea
} from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/EmailEditPanel.styles';
import type { ResponsiveValue } from '@withjoy/joykit/styled-system';
import LinkSelector from './components/LinkSelector';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import PhotoSelector from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector';
import { EventPageFragment } from '@graphql/generated';
import { filterTidbitsPage } from '../PhotoSelector/PhotoSelector.controller';

export interface GeneralMessageEmailFormProps
  extends Readonly<{
    formik: FormikProps<EmailInputFields>;
    isPasswordToggleShown: Maybe<boolean>;
    tEmailEditor: EmailEditorTranslations;
    generalMessageEmailOptions: Array<EmailEditPanelOption>;
    handleIncludeGuestNamesToggle: () => void;
    handleLinkSelect?: (link: GeneralMessageLinkType) => void;
    initialSelectedLink?: GeneralMessageLinkType;
    handlePhotoSelect: (photoUrl: string) => void;
    pages?: ReadonlyArray<EventPageFragment>;
  }> {}

export const GeneralMessageEmailForm: React.FC<GeneralMessageEmailFormProps> = ({
  formik,
  isPasswordToggleShown,
  tEmailEditor,
  generalMessageEmailOptions,
  handleIncludeGuestNamesToggle,
  handleLinkSelect,
  initialSelectedLink,
  handlePhotoSelect,
  pages
}) => {
  return (
    <>
      <FormControl label={<StyledLabel>{tEmailEditor.emailSubjectLabel}</StyledLabel>} error={formik.errors.subject} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('subject')} placeholder={tEmailEditor.emailSubjectPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('subject').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.replyToLabel}</StyledLabel>} error={formik.errors.replyToEmail} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <InputV2 {...formik.getFieldProps('replyToEmail')} placeholder={tEmailEditor.emailSubjectPlaceholder} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('replyToEmail').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.photoLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <PhotoSelector pages={filterTidbitsPage(pages)} handlePhotoSelect={({ previewUrl }) => handlePhotoSelect(previewUrl)} photo={{ url: formik.values.imageUrl || '' }} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('imageUrl').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.salutationLabel}</StyledLabel>} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <Flex flexDirection="column">
            <Box marginBottom="18px">
              <InputV2 {...formik.getFieldProps('salutation')} placeholder={tEmailEditor.salutationPlaceholder} />
            </Box>
            <CheckboxV2 id="includeGuestNames" isChecked={!!formik.getFieldMeta('includeGuestNames').value} onChange={handleIncludeGuestNamesToggle}>
              {tEmailEditor.includeGuestNamesLabel}
            </CheckboxV2>
          </Flex>
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('salutation').error}
          </TextV2>
        </>
      </FormControl>
      <FormControl label={<StyledLabel>{tEmailEditor.messageLabel}</StyledLabel>} error={formik.errors.message} overrides={EmailsAndEcardsFormControlOverrides}>
        <>
          <StyledTextArea {...formik.getFieldProps('message')} placeholder={tEmailEditor.messagePlaceholder} resize={false} minRows={8} maxRows={8} tabIndex={0} minimal={true} />
          <TextV2 marginTop={2} color={'inputBorderError'} height={2} typographyVariant="label1">
            {formik.getFieldMeta('message').error}
          </TextV2>
        </>
      </FormControl>
      {/* post office v2 future functionality */}
      {!!initialSelectedLink && !!handleLinkSelect && (
        <LinkSelector handleLinkSelect={handleLinkSelect} initialSelectedLink={initialSelectedLink} linkLabel={tEmailEditor.linkLabel} />
      )}
      <StyledOptionsWrapper paddingX={4} paddingY={0}>
        {generalMessageEmailOptions.map(option => {
          if (!!option) {
            return option.id !== 'includeEventPassword-toggle' || (option.id === 'includeEventPassword-toggle' && !!isPasswordToggleShown) ? (
              <StyledOptionContainer key={option.formikName} alignItems="center" justifyContent="space-between" paddingY={5}>
                <Flex flexDirection="column" alignItems="flex-start">
                  <StyledBodyTitle>{option.hed}</StyledBodyTitle>
                  <StyledBody>{option.dek}</StyledBody>
                </Flex>
                {option.id === 'includeEventPassword-toggle' ? (
                  <TooltipV2
                    overrides={{
                      Root: {
                        props: {
                          display: !!formik.values.includeAppInstructions ? 'block' : 'none',
                          zIndex: 'tooltip' as ResponsiveValue<'tooltip'>,
                          pointerEvents: 'none'
                        }
                      }
                    }}
                    content={
                      <Box width={'256px'} padding={3}>
                        <TextV2 typographyVariant="label2">{tEmailEditor.passwordToggleTooltip}</TextV2>
                      </Box>
                    }
                  >
                    <div>
                      <Toggle hideLabel={true} label={option.name} checked={option.isOn} name={option.formikName} onChange={option.onCange} disabled={!!option.isDisabled} />
                    </div>
                  </TooltipV2>
                ) : (
                  <Toggle hideLabel={true} label={option.name} checked={option.isOn} name={option.formikName} onChange={option.onCange} disabled={!!option.isDisabled} />
                )}
              </StyledOptionContainer>
            ) : null;
          } else {
            return null;
          }
        })}
      </StyledOptionsWrapper>
    </>
  );
};
