import React from 'react';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';

import { removeNonHTMLProps, animationTransition } from '../../../../utils';
import { getStyledBaseComponentProps, getStyledLayoutInternalSpacing } from '../../../../common/styledSystem/styledFunctions';
import { BASE_COMPONENT_PROPS } from '../../../../common/styledSystem/types';
import { NavItemProps } from '.';

const NavItemComponent: React.FC<NavItemProps> = props => {
  const { component: Component = 'a', disabled, selected, forwardedRef, enableClickWhenActive, paddingX, ...restProps } = props;
  const filteredProps = { ...removeNonHTMLProps(restProps, BASE_COMPONENT_PROPS) };
  return <Component {...filteredProps} className={props.className} ref={forwardedRef} />;
};

const ARIA_DISABLED = `[aria-disabled="true"]`;
const ARIA_CURRENT = `[aria-current="page"]`;

export const StyledNavItem = styled(NavItemComponent)`
  color: ${props => props.theme.colors.buttonDisabledText};
  outline: none;
  cursor: pointer;
  text-decoration: none;
  height: 64px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${animationTransition('color')};
  ${props => props.theme.typography.variants.body1}
  ${getStyledLayoutInternalSpacing}

  &${ARIA_CURRENT} {
    color: ${props => props.theme.colors.fillDark};
    cursor: default;
  }

  &${ARIA_DISABLED} {
    color: ${props => props.theme.colors.buttonDisabledText};
    &${ARIA_CURRENT} {
      color: ${props => props.theme.colors.mono4};
    }
    &:focus {
      outline: none;
    }
    cursor: default;
    ${props => props.theme.typography.variants.body1}
  }
  :hover:not(${ARIA_DISABLED}):not(${ARIA_CURRENT}) {
    color: ${props => props.theme.colors.typePrimaryDark};
  }

  ${props => {
    if (props.disabled) {
      return {
        color: props.theme.colors.buttonDisabledText,
        cursor: 'default'
      };
    }
    return null;
  }}

  ${getStyledBaseComponentProps};
`;
