import React, { useMemo } from 'react';
import { PreviewSide } from './Components/PreviewSide/PreviewSide';
import { AccountForm } from './Components/AccountForm/AccountForm';
import { StationeryTemplateCategoryEnum, useGetPublicCardShopLoginFallbackTemplateQuery } from '@graphql/generated';
import { useLoginStateContext } from './Login';
import { LoadingPage } from './Components/LoadingPage/LoadingPage';
import { CustomizeTemplateData } from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { CreateEventForm } from './Components/CreateEventForm/CreateEventForm';
import { EventStateProvider } from '@apps/createWedding/EventState';
import { LoginState } from './types';
import { Box } from '@withjoy/joykit';

const StateMap = {
  [LoginState.WAITING_FOR_AUTH]: () => <LoadingPage reason="Getting auth info" />,
  [LoginState.PRESENT_EVENT_FORM]: () => <CreateEventForm />,
  [LoginState.WAITING_FOR_EVENT]: () => <LoadingPage reason="Creating your event..." />,
  [LoginState.WAITING_FOR_DRAFT]: () => <LoadingPage reason="Creating a draft..." />,
  [LoginState.WAITING_FOR_ACCOUNT]: () => <LoadingPage reason="Creating your account..." />,
  [LoginState.PRESENT_AUTH_FORM]: () => <AccountForm />
};

type LoginPageProps = {
  template?: CustomizeTemplateData;
};

export const LoginRouter = ({ template }: LoginPageProps) => {
  const { data: fallback, loading } = useGetPublicCardShopLoginFallbackTemplateQuery({
    variables: {
      themeId: 'bohemian_eucalyptus',
      categories: [StationeryTemplateCategoryEnum.saveTheDate]
    },
    skip: !!template,
    batchMode: 'fast'
  });

  const { state } = useLoginStateContext();

  const Component = StateMap[state];

  const fallbackPage: CustomizeTemplateData | null = useMemo(() => {
    if (!fallback || !fallback.stationeryTemplatesByThemeId[0]) {
      return null;
    }
    return {
      format: 'paper',
      template: fallback.stationeryTemplatesByThemeId[0]
    };
  }, [fallback]);

  if (!loading) {
    return (
      <Box display="flex" flexDirection={{ _: 'column', md: 'row' }}>
        {<PreviewSide page={template! || fallbackPage!} />}
        <EventStateProvider>
          <Component />
        </EventStateProvider>
      </Box>
    );
  } else {
    return null;
  }
};
