import { PRICE_CONFIG } from '@apps/card/config';
import React, { useCallback } from 'react';
import { useCardCategoryContext } from '../CardCategoryProvider';
import { createContext } from '@shared/utils/createContext';
import { useSalesConfig } from '@apps/card/routes/CardCustomizer/useSalesConfig';
import { useMinorUnitsToPriceString } from '@apps/card/hooks/useMinorUnitsToPriceString';
import { isPromoValidForVendor, PromotionVendor } from '@apps/card/routes/CardCustomizer/usePromotionConfig';
import { isNumber } from 'lodash-es';

export type VariablePriceDiscount = { getPrice: ReturnType<typeof useGetDiscountedPrice> };

type CardDiscountContext = {
  [key in PromotionVendor]: VariablePriceDiscount;
};

type useCardDiscountContextProps = {
  children: string | JSX.Element | JSX.Element[];
};

export const priceStringToMinorUnits = (priceString: string): number => {
  const price = priceString.split('$')[1].split('.');
  const majorUnits = Number(price[0]);
  const minorUnits = Number(price[1]);
  return majorUnits * 100 + minorUnits;
};

const [Provider, useCardDiscountContext] = createContext<CardDiscountContext | undefined>({ name: 'CardDiscountProvider' });

const useGetDiscountedPrice = (vendor?: PromotionVendor) => {
  const { currentCategory } = useCardCategoryContext();
  const cardPricingConfig = PRICE_CONFIG.quantity[currentCategory];
  const { currentSale, isEnabled } = useSalesConfig(currentCategory, { occasion: 'wedding', category: currentCategory, vendor });
  const priceToString = useMinorUnitsToPriceString();
  return useCallback(
    (price?: number) => {
      let newPriceMultiplier = 1.0;

      const originalPrice = isNumber(price) ? price : cardPricingConfig[100].totalPriceInMinorUnits / 100;
      const formattedOriginalPrice = priceToString(originalPrice, { canShowFreeLabel: false });

      if (isEnabled && currentSale && (vendor ? isPromoValidForVendor(currentSale, vendor) : true)) {
        newPriceMultiplier = 1.0 - currentSale.discountPercentage;
      } else {
        return { isEnabled: false, originalPrice: formattedOriginalPrice };
      }

      const discountedPrice = Math.round(originalPrice * newPriceMultiplier);
      const formattedDiscountedPrice = priceToString(discountedPrice, { canShowFreeLabel: false });

      return {
        originalPrice: formattedOriginalPrice,
        discountedPrice: formattedDiscountedPrice,
        isEnabled,
        currentSale,
        currentCategory
      };
    },
    [cardPricingConfig, currentCategory, currentSale, isEnabled, priceToString, vendor]
  );
};

export const CardDiscountProvider = ({ children }: useCardDiscountContextProps) => {
  const getDiscountedPriceTaylor = useGetDiscountedPrice('taylor');
  const getDiscountedPricePaperlust = useGetDiscountedPrice('paperlust');
  const getDiscountedPricePremiumDigital = useGetDiscountedPrice('joy_digital');

  const taylor = {
    getPrice: getDiscountedPriceTaylor
  };

  const paperlust = {
    getPrice: getDiscountedPricePaperlust
  };

  const joy_digital = {
    getPrice: getDiscountedPricePremiumDigital
  };

  return <Provider value={{ taylor, paperlust, joy_digital }}>{children}</Provider>;
};

export { useCardDiscountContext, useGetDiscountedPrice };
