import React, { ReactNode } from 'react';
import { animationTransition } from '@shared/utils/animationTransition';
import { Box, BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import { Clock, Diamond } from '@withjoy/joykit/icons';
import { BOX_SHADOW_60_02, BOX_SHADOW_8 } from '@apps/card/boxShadow';
import { Skeleton } from '@shared/components/Skeleton';
import { AspectRatioImage } from '../AspectRatioImage';
import { useGalleryGridStyle } from '../DesignsGallery/useGalleryGridStyle';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { StationeryDesignGalleryTemplateFragment } from '@graphql/generated';
import useFavoriting from '../DesignsGallery/useFavoriting';
import { FavoriteButton } from '@apps/card/components/FavoriteButton/FavoriteButton';

interface AssetPreview {
  assetUrl: string;
  children?: ReactNode;
}

const AssetPreviewFrame = (props: { children?: React.ReactNode }) => {
  const {
    assetPreviewAspectRatioMobile,
    assetPreviewFrameBackground,
    assetPreviewFrameGroupHoverBackground,
    assetPreviewFramePaddingDesktop,
    assetPreviewFramePaddingMobile
  } = useGalleryGridStyle();
  const isMobile = useIsMobileScreen();
  const aspectRatio = isMobile && typeof assetPreviewAspectRatioMobile === 'string' ? assetPreviewAspectRatioMobile : '9 / 10';
  const assetPreviewFramePadding = isMobile ? assetPreviewFramePaddingMobile : assetPreviewFramePaddingDesktop;
  return (
    <Flex
      aspectRatio={aspectRatio}
      overflow={assetPreviewFrameBackground ? 'hidden' : undefined} // Needed for Safari
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative" // For badges
      paddingX={assetPreviewFramePadding ?? 5}
      paddingY={assetPreviewFramePaddingDesktop ?? 5}
      background={assetPreviewFrameBackground}
      transition={animationTransition('background')}
      _groupHover={{
        background: assetPreviewFrameGroupHoverBackground
      }}
    >
      {props.children}
    </Flex>
  );
};

export const AssetPreview = (props: AssetPreview) => {
  const { assetUrl, children } = props;
  const { aspectRatioFrameBoxShadow, badgeContainer } = useGalleryGridStyle();
  return (
    <AssetPreviewFrame>
      <AspectRatioImage
        src={assetUrl}
        borderRadius={1}
        boxShadow={aspectRatioFrameBoxShadow ?? BOX_SHADOW_8}
        transition={animationTransition('box-shadow')}
        _groupHover={{
          boxShadow: aspectRatioFrameBoxShadow ?? `${BOX_SHADOW_8},${BOX_SHADOW_60_02}`
        }}
      >
        {badgeContainer !== 'frame' && children}
      </AspectRatioImage>
      {badgeContainer === 'frame' && children}
    </AssetPreviewFrame>
  );
};

const AssetPreviewSkeleton = () => {
  // Matches AssetPreviewFrame dimensions.
  const { assetPreviewAspectRatioMobile } = useGalleryGridStyle();
  const isMobile = useIsMobileScreen();
  const aspectRatio = isMobile && typeof assetPreviewAspectRatioMobile === 'string' ? assetPreviewAspectRatioMobile : '9 / 10';
  return <Skeleton aspectRatio={aspectRatio} />;
};
AssetPreview.Skeleton = AssetPreviewSkeleton;

export type TagVariant = 'comingSoon' | 'printAvailable' | 'disabled' | 'printAndDigitalAvailable' | 'digitalOnly' | 'printAndPremiumDigitalAvailable';

interface TagProps {
  variant: TagVariant;
}

const TAG_VARIANT_MAP: Record<TagProps['variant'], { Icon: () => JSX.Element | null; label: string }> = {
  comingSoon: {
    Icon: () => <Clock size="md" color="positive7" />,
    label: 'Coming Soon'
  },
  printAvailable: {
    Icon: () => null,
    label: 'Paper Only'
  },
  printAndPremiumDigitalAvailable: {
    Icon: () => null,
    label: 'Paper & Digital'
  },
  disabled: {
    Icon: () => <Clock size="md" color="positive7" />,
    label: 'Disabled (Admin View Only)'
  },
  printAndDigitalAvailable: {
    Icon: () => null,
    label: 'Paper & Digital'
  },
  digitalOnly: {
    Icon: () => null,
    label: 'Digital Only'
  }
};

const Tag = (props: TagProps) => {
  const { Icon, label } = TAG_VARIANT_MAP[props.variant];
  return (
    <Flex alignItems="center" columnGap={1} paddingX={3} paddingY={1} borderRadius={40} backgroundColor="mono2">
      <Icon />
      <TextV2 as="span" typographyVariant="label2">
        {label}
      </TextV2>
    </Flex>
  );
};

interface ContentProps {
  title: string;
  label: string | JSX.Element;
  children?: React.ReactNode;
  alignment?: 'left' | 'center';
}

const ContentContainer: React.FC<{ alignment: 'left' | 'center'; header: React.ReactNode; height?: BoxProps['height'] }> = ({ alignment, header, height, children }) => {
  return (
    <Flex flexDirection="column" rowGap={{ _: 1, md: 3 }} alignItems={alignment === 'left' ? 'flex-start' : 'center'} height={height}>
      <Flex flexDirection="column" rowGap={1} alignItems={alignment === 'left' ? 'flex-start' : 'center'} textAlign={alignment}>
        {header}
      </Flex>
      {children}
    </Flex>
  );
};
const Content = ({ children, label, title, alignment = 'center' }: ContentProps) => {
  return (
    <ContentContainer
      alignment={alignment}
      header={
        <>
          <TextV2 typographyVariant={{ _: 'hed1', md: 'hed2' }}>{title}</TextV2>
          {typeof label === 'string' ? <TextV2 typographyVariant="label2">{label}</TextV2> : <div>{label}</div>}
        </>
      }
    >
      {children}
    </ContentContainer>
  );
};

Content.Skeleton = ({ children, alignment = 'center' }: { children: React.ReactNode; alignment?: 'left' | 'center' }) => {
  return (
    <ContentContainer
      height={80}
      alignment={alignment}
      header={
        <>
          <Skeleton width={120} height={18} />
          <Skeleton width={50} height={16} />
        </>
      }
    >
      {children}
    </ContentContainer>
  );
};

interface GalleryTileProps {
  onClick?: React.MouseEventHandler;
  rowGap?: BoxProps['rowGap'];
  children: React.ReactNode;
  alignContent?: BoxProps['alignContent'];
  aspectRatio?: BoxProps['aspectRatio'];
}

export const GalleryTile = (props: GalleryTileProps) => {
  const { children, onClick, rowGap, alignContent, aspectRatio } = props;

  return (
    <Box
      display="grid"
      alignContent={alignContent}
      alignItems="start"
      aspectRatio={aspectRatio}
      role="group"
      position="relative"
      rowGap={rowGap}
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
    >
      {children}
    </Box>
  );
};

const PremiumBadge = () => {
  const { badgeContainer } = useGalleryGridStyle();
  if (badgeContainer === 'frame') {
    // Inverted colors for light backgrounds.
    return (
      <Box
        typographyVariant="label1"
        fontWeight={600}
        position="absolute"
        display={'flex'}
        alignItems="center"
        right={4}
        bottom={4}
        color="white"
        userSelect="none"
        zIndex={1}
        title="Premium Digital Available"
      >
        <Diamond color="hsl(40, 60%, 60%)" size={20} transform="translateY(1px)" />
      </Box>
    );
  }

  return (
    <Box
      typographyVariant="label1"
      fontWeight={600}
      position="absolute"
      borderRadius="50%"
      display={'flex'}
      alignItems="center"
      backdropFilter="blur(2px)"
      right={3}
      bottom={4}
      backgroundColor="rgba(0, 0, 0, 0.40)"
      color="white"
      userSelect="none"
      zIndex={1}
      title="Premium Digital Available"
    >
      <Diamond color="#DFC080" size={24} />
    </Box>
  );
};

interface FavoriteBadgeProps {
  eventId?: string;
  template: StationeryDesignGalleryTemplateFragment;
  onToggle?: (isFavorite: boolean) => void;
}

const FavoriteBadge = ({ eventId, onToggle, template }: FavoriteBadgeProps) => {
  const { isFavorite, toggleFavorite, isLoading } = useFavoriting({
    isFavorite: template.isFavorite,
    templateId: template.id,
    eventId: eventId || ''
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    onToggle?.(!isFavorite);

    toggleFavorite();
  };

  if (!eventId) {
    return null;
  }

  return <FavoriteButton position="absolute" top={3} right={3} isFavorite={isFavorite} isLoading={isLoading} onClick={handleClick} />;
};

GalleryTile.Skeleton = () => {
  return (
    <GalleryTile rowGap={6}>
      <AssetPreview.Skeleton />
      <Content.Skeleton>
        <Skeleton width={70} height={18} />
      </Content.Skeleton>
    </GalleryTile>
  );
};

GalleryTile.AssetPreview = AssetPreview;
GalleryTile.AssetPreviewFrame = AssetPreviewFrame;
GalleryTile.Content = Content;
GalleryTile.Tag = Tag;
GalleryTile.PremiumBadge = PremiumBadge;
GalleryTile.FavoriteBadge = FavoriteBadge;
