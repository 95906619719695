import React from 'react';
import { LottiePlayer } from '@shared/components';
import { Flex, ButtonV2, TextV2 } from '@withjoy/joykit';
import { InfoWrapper, InfoText, CTAContainer } from '../BookingDialog.styles';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { OptionReminder } from '../BookingDialog.const';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';

export const BookingDialogSuccess: React.FC<{ reminderSelected?: OptionReminder; onBtnSuccessClicked: () => void }> = ({ reminderSelected, onBtnSuccessClicked }) => {
  const { t } = useTranslation('bookingDialog');
  const translations = t('success');
  const { bookingDialogCTAClicked } = useDashboardTelemetry();
  const auth = useAuth();

  const infoText = translations.infoText({
    Email: (
      <TextV2 as={'label'} fontWeight="bold">
        {auth.currentUser.profile?.email}
      </TextV2>
    ),
    Selection: (
      <TextV2 as={'label'} fontWeight="bold">
        {reminderSelected?.label}.
      </TextV2>
    )
  });

  return (
    <>
      <InfoWrapper borderTopLeftRadius={12} borderBottomLeftRadius={12} rowGap={{ _: 32, sm: 40 }} paddingTop={{ _: 32, sm: 'unset' }} minWidth="100%" alignItems="center">
        <Flex marginTop={{ _: 32, sm: 'unset' }} alignSelf="center" width={241} height={153}>
          <LottiePlayer autoplay={true} lottieUrl="https://withjoy.com/assets/public/lottie/hotel-lottie.json" />
        </Flex>
        <Flex width="100%" rowGap={3} flexDirection="column" maxWidth={360}>
          <Flex typographyVariant={'Hed56'} paddingBottom={0} justifyContent="center" alignItems="center">
            {translations.titleText()}
          </Flex>
          <Flex justifyContent="center" alignItems="center" textAlign="center">
            <InfoText>{infoText}</InfoText>
          </Flex>
        </Flex>

        <CTAContainer width="100%" justifyContent="center" alignItems="center">
          <ButtonV2
            width={{ _: '100%' }}
            maxWidth={{ sm: 330 }}
            intent="neutral"
            variant="solid"
            onClick={() => {
              bookingDialogCTAClicked('Done');
              onBtnSuccessClicked();
            }}
          >
            {translations.ctaConfirm()}
          </ButtonV2>
        </CTAContainer>
      </InfoWrapper>
    </>
  );
};
