import { Flex, TextV2, ButtonV2, IconV2, useDisclosure } from '@withjoy/joykit';
import React from 'react';
import { outlinedDarkButtonOverrides } from '../Shared.styles';
import { Copy } from '@withjoy/joykit/icons';
import ShareableDialog from '@shared/components/EmailsAndEcards/components/Confirm/components/ShareableDialog';
import { useTranslation } from '@shared/core';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';
import useShareWithLinkController from './ShareWithLinkView.controller';
import useHandleCopyShareableLink from '@shared/components/EmailsAndEcards/components/Confirm/components/ShareableDialog/useHandleCopyShareableLink';

const ShareLinkScreen: React.FC = () => {
  const shareableDialog = useDisclosure();
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tSelectRecipients = tBottomSheet.selectRecipients;
  const { transform } = useBottomSheetDescriptionOffset();
  const { isCreatingShareableLink, handleCopyShareableLink } = useShareWithLinkController();
  const handleCopyButtonClick = useHandleCopyShareableLink({ copy: handleCopyShareableLink, openDialog: shareableDialog.onOpen });

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center" height="24px">
        <IconV2 size="md" marginRight="12px">
          <Copy />
        </IconV2>{' '}
        {tSelectRecipients.shareLink()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" style={{ transform }}>
        {tSelectRecipients.shareLinkDescription()}
      </TextV2>
      <Flex flex="1" alignItems="center" flexDirection="column">
        <img src="https://withjoy.com/assets/public/social_icons.png" alt="Social" width="183px" />
      </Flex>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={handleCopyButtonClick} loading={isCreatingShareableLink}>
        {tSelectRecipients.shareLinkCTA()}
      </ButtonV2>
      <ShareableDialog {...shareableDialog} onCopy={handleCopyShareableLink} isCopying={isCreatingShareableLink} />
    </Flex>
  );
};

export default ShareLinkScreen;
