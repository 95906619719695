import React from 'react';
import SendTestEmailDialog from '@shared/components/EmailsAndEcards/components/Confirm/components/SendTestEmailDailog';
import { useMobileSendTestEmailDialogController } from './MobileSendTestEmailDialog.controller';

const MobileSendTestEmailDialog: React.FC = () => {
  const { isSendTestEmailDialogOpen, testEmail, handleSendTestEmailDialogClose, handleSendTestEmail, isMobile } = useMobileSendTestEmailDialogController();

  if (!isMobile) return null;

  return (
    <SendTestEmailDialog
      dialogId="send-test-email-dialog-ecards"
      isOpen={isSendTestEmailDialogOpen}
      onClose={handleSendTestEmailDialogClose}
      handleSendTestEmail={handleSendTestEmail}
      testEmail={testEmail}
      isMobileEmailEditor
    />
  );
};

export default MobileSendTestEmailDialog;
