import React from 'react';
import { StyledDialog } from './BottomSheet.styles';
import { useBottomSheetController } from './BottomSheet.controller';
import { Steps } from './components/Steps/Steps';

/**
 * This component works only on Mobile.
 * Make sure the container who wraps this component has overflow hidden.
 *
 * ```js
 * <Box overflow="hidden">
 *  <BottomSheet />
 * </Box>
 * ```
 * */
export const BottomSheet: React.FC<{ disableNavigation?: boolean; forceClosed?: boolean }> = ({ disableNavigation = false, forceClosed = false }) => {
  const { isMobile, scrollPosition, maxHeight, minHeight, backgroundColor } = useBottomSheetController();

  if (!isMobile) return null;

  return (
    <StyledDialog $scrollPosition={scrollPosition} $maxHeight={maxHeight} $minHeight={minHeight} $backgroundColor={backgroundColor} $forceClosed={forceClosed}>
      <Steps disableNavigation={disableNavigation} />
    </StyledDialog>
  );
};
