import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';

interface Args
  extends Readonly<{
    handleSendTestEmail: (testEmail: string, testName: string) => void;
    onClose: () => void;
    testEmail?: string;
  }> {}

export interface SendTestEmailDialogFormikValues
  extends Readonly<{
    testName?: string;
    testEmail?: string;
  }> {}

export const useSendTestEmailDialog = (args: Args) => {
  const { handleSendTestEmail, onClose, testEmail } = args;

  const { t2 } = useTranslation('emailsAndEcards');
  const tUnsavedChangesDialog = t2('confirmAndSend', 'sendTestEmailDialog');

  const handleCancel = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik<SendTestEmailDialogFormikValues>({
    initialValues: {
      testName: '',
      testEmail: testEmail || ''
    },
    validationSchema: Yup.object<SendTestEmailDialogFormikValues>({
      testName: Yup.string().required(tUnsavedChangesDialog.testNameErrorMessage),
      testEmail: Yup.string().email().required(tUnsavedChangesDialog.testEmailErrorMessage)
    }),
    onSubmit: values => {
      if (formik.isValid) {
        handleSendTestEmail(values.testEmail!, values.testName!);
        handleCancel();
      }
    }
  });

  return {
    handleCancel,
    formik,
    tUnsavedChangesDialog
  };
};
