import { useEffect } from 'react';
import { useHistory, useLocation } from '@react-router';
import type { Location } from 'history';
import { deriveActiveFiltersFromUrl } from './utils/deriveActiveFiltersFromUrl';
import { activePrintFiltersVar } from './utils/activePrintFiltersCache';

import isEqual from 'react-fast-compare';

const syncFiltersFromLocation = (location: Location) => {
  const composedUrl = `${location.pathname}${location.search}${location.hash}`;
  const nextFilters = deriveActiveFiltersFromUrl(composedUrl);
  const prevFilters = activePrintFiltersVar();
  if (!isEqual(prevFilters, nextFilters)) {
    activePrintFiltersVar(nextFilters);
  }
};

/**
 * This hook will keep the reactive `activeFilters` var in sync with the URL.
 * The URL serves as the source of truth for determining active filters.
 */
export const useUrlSyncedFilters = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Only want to run once on mount
    syncFiltersFromLocation(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unregister = history.listen((loc, action) => {
      syncFiltersFromLocation(loc);
    });

    return () => {
      unregister();
    };
  }, [history]);
};
