import { useQueryParams } from '@shared/utils/hooks/useQueryParams';

const supportedScenarios = ['default', 'save-the-date', 'invitation', 'wedding-website', 'guest-list', 'registry', 'app'] as const;

type Scenario = typeof supportedScenarios[number];

export const useScenario = (): Scenario => {
  const queryParams = useQueryParams();

  return supportedScenarios.includes(queryParams.scenario as Scenario) ? (queryParams.scenario as Scenario) : 'default';
};
