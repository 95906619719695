import React from 'react';
import { StyledLabel } from './StyledLabel';
import { SpacingExternalProps, Props } from '../../common/props';
import { StyledLabelInfo } from './StyledLabelInfo';
import { generateComponentDisplayName } from '../../utils';
import { BoxProps } from '@withjoy/joykit';

export interface InputLabelProps
  extends Props,
    Merge<
      BoxProps<'label'>,
      {
        /**
         * If true, the label will be displayed in disabled state.
         */
        disabled?: boolean;

        /**
         * If true, the label will be displayed in error state.
         */
        error?: boolean;

        /**
         * A unique identifier for the label;
         */
        id: string;

        /**
         * Optional secondary text to be display to the right of the label.
         */
        labelInfo?: string;

        /**
         * If true, the label will indicate that the input is required.
         */
        required?: boolean;
      }
    > {}

/**
 * A set of dynamic style props that can be used to ensure
 * consistent InputLabel styling.
 */
interface ComposedInputLabelProps extends SpacingExternalProps {}

interface InternalInputLabelProps extends InputLabelProps, ComposedInputLabelProps {
  /**
   * Label Content
   */
  children?: string;

  /**
   * Unique identifier of the form control that the label is labelling.
   *
   * A <InputLabel /> can have both a `fieldID` and a contained control as long as
   * the `fieldID` points to the contained control element.
   */
  fieldID?: string;
  hidden?: boolean;
}

export const InputLabel: React.FC<InternalInputLabelProps> = React.memo(props => {
  const { fieldID, ...rest } = props;
  return (
    <StyledLabel {...rest} id={props.id} htmlFor={props.fieldID}>
      {props.children}
      {props.labelInfo && <StyledLabelInfo>{props.labelInfo}</StyledLabelInfo>}
      {props.required && <span>{'\u2009*'}</span>}
    </StyledLabel>
  );
});

InputLabel.displayName = generateComponentDisplayName('InputLabel');
