import { Box, ButtonV2, Divider, TextV2, TooltipV2 } from '@withjoy/joykit';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import React from 'react';
import { AppButton, StyledEmailPhoto } from '../components';
import { Info } from '@withjoy/joykit/icons';
import { addRendition } from '@shared/utils/photoRendition';
import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormikProps } from 'formik';
import { useTranslation } from '@shared/core';
import { isValidUrl } from '@shared/utils/urlHelpers';

interface Props
  extends Readonly<{
    formik: FormikProps<EmailInputFields>;
    eventPassword?: Maybe<string>;
    isPasswordToggleShown?: boolean;
  }> {}

export const GenericEmail: React.FC<Props> = props => {
  const { formik, eventPassword, isPasswordToggleShown } = props;
  const { headline, subheadline, salutation, message, includeWebsiteDetails, includeAppInstructions, includeEventPassword, imageUrl, includeGuestNames } = formik.values;
  const { t } = useTranslation('emailsAndEcards');
  const tEcardGuestToolTip = t('ecardGuest', 'toolTip');

  /**
   * NOTE: This component looks funky, but it is coded in a way to closely resemble the way ReactMjml's layout components operate
   * In this way it should be easier to get to visual parity between the preview and the actual email
   */
  return (
    <>
      {imageUrl && isValidUrl(imageUrl) && <StyledEmailPhoto src={addRendition({ url: imageUrl, renditionSize: 'medium' })} alt="email photo" />}
      <Box padding="40px 40px 0">
        <Box padding="0px" textAlign="center" color="brandWarmGray7" fontSize={36} fontFamily={EmailFontFamilies.playfairDisplay} lineHeight="43.2px">
          {headline}
        </Box>
        {subheadline ? (
          <TextV2 color="brandWarmGray7" textAlign="center" fontSize={24} lineHeight="28.8px" fontFamily={EmailFontFamilies.playfairDisplay} padding="16px 0 0">
            {subheadline}
          </TextV2>
        ) : null}
      </Box>
      <Box padding="32px 40px 0" display="flex" alignItems="center">
        <Box fontFamily={EmailFontFamilies.ibmPlexSans} color="brandWarmGray6" fontSize="15px" lineHeight="18px">
          {salutation}
        </Box>
        {includeGuestNames ? (
          <>
            <Box fontFamily={EmailFontFamilies.ibmPlexSans} color="brandWarmGray6" fontSize="15px" lineHeight="18px" marginX={1}>
              {'[First Name(s)]'}
            </Box>
            <TooltipV2
              content={
                <Box width="256px" padding={3}>
                  <TextV2 typographyVariant="label2">
                    <b>{tEcardGuestToolTip.firstPart()}</b> <br /> {tEcardGuestToolTip.secondPart()}
                  </TextV2>
                </Box>
              }
            >
              <Info size="sm" color="brandWarmGray6" />
            </TooltipV2>
          </>
        ) : null}
        {','}
      </Box>
      {message ? (
        <Box padding="22px 40px 0">
          <Box color="brandWarmGray6" fontSize={15} lineHeight="24px" fontFamily={EmailFontFamilies.ibmPlexSans} style={{ whiteSpace: 'pre-wrap' }}>
            {message}
          </Box>
        </Box>
      ) : null}
      <Box paddingX="40px" textAlign="center">
        {/* post office v2 future functionality */}
        {/* {selectedLink && GeneralMessageLinkType[selectedLink] && selectedLink !== ('none' as GeneralMessageLinkType) ? (
          <Box padding="0px 0px 40px" display="flex" flexDirection="column" alignItems="center">
            <ButtonV2 variant="solid" _hover={{ backgroundColor: 'brandWarmGray7' }} backgroundColor={'brandWarmGray7'} color="white" margin="0 0 24px" onClick={() => {}}>
              {GeneralMessageLinkType[selectedLink!]}
            </ButtonV2>
          </Box>
        ) : null} */}
        {includeWebsiteDetails && (
          <Box padding="40px 0 0" display="flex" flexDirection="column" alignItems="center">
            {!!eventPassword && includeEventPassword && !!isPasswordToggleShown && (
              <Box marginBottom="16px" fontSize="15px" lineHeight="17px" color="brandWarmGray6" textAlign="center" fontFamily={EmailFontFamilies.ibmPlexSans}>
                Event Password:{' '}
                <Box as="span" textTransform="uppercase" fontWeight={700} color="brandWarmGray6" fontFamily={EmailFontFamilies.ibmPlexMono}>
                  {eventPassword}
                </Box>
              </Box>
            )}
            <ButtonV2 variant="solid" _hover={{ backgroundColor: 'brandWarmGray7' }} backgroundColor={'brandWarmGray7'} color={'white'} onClick={() => {}}>
              View Our Website
            </ButtonV2>
          </Box>
        )}
        {includeAppInstructions && (
          <>
            <Divider marginTop="40px" borderColor={'humana2'} color={'humana2'} />
            <Box padding="32px 0px 24px">
              <Box fontFamily={EmailFontFamilies.ibmPlexSans} fontSize="15px" lineHeight={'26px'} fontWeight={600} color={'mono13'} padding="0px 0px 16px">
                Download Our App
              </Box>
              <Box fontFamily={EmailFontFamilies.ibmPlexSans} padding="0px" fontSize={'15px'} lineHeight={'24px'} color={'brandWarmGray6'}>
                Get the Joy app to view and share photos and stay up-to-date with details on the go.
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <Box margin="8px 6px">
                <AppButton type="apple" width="160px" />
              </Box>
              <Box margin="8px 6px">
                <AppButton type="android" width="160px" />
              </Box>
            </Box>
            {!!eventPassword && includeEventPassword && !!isPasswordToggleShown && (
              <>
                <Divider marginTop="24px" borderColor={'humana2'} color={'humana2'} />
                <Box fontSize={15} lineHeight="24px" color="mono14" textAlign="center" fontFamily={EmailFontFamilies.ibmPlexSans} paddingTop="24px">
                  Event Password:{' '}
                  <Box as="span" textTransform="uppercase" fontWeight={700} color={'brandWarmGray6'} fontFamily={EmailFontFamilies.ibmPlexMono}>
                    {eventPassword}
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <Box padding="12px 0" />
    </>
  );
};
