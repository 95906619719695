import React, { useCallback, useMemo } from 'react';
import { ItemInfoWrapper, StyledItemWrapper, StyledListHeader, StyledListWrapper } from './SelectList.styles';
import { CheckboxV1, LinkV1 } from '@withjoy/joykit';
import SeqNumUtil from '../../utils/SeqNum.util';

export interface ListItemVo
  extends Readonly<{
    /** unique id */
    id: string;
    selected: boolean;
    notSelectable?: boolean;
    disabled?: boolean;
  }> {}

interface Props<T extends ListItemVo>
  extends Readonly<{
    id?: string;
    className?: string;
    canSelect?: boolean;
    showSelectAll?: boolean;
    headerLabel?: string;
    items: ReadonlyArray<T>;
    itemRenderer: (item: T, index: number) => React.ReactNode;
    emptyContentRenderer?: () => React.ReactNode;
    onItemSelectedChange?: (itemKey: string, newValue: boolean) => void;
    onSelectAllClick?: () => void;
  }> {}

interface ListRowProps<T extends ListItemVo>
  extends Readonly<{
    canSelect: boolean;
    uniqPrefixForCheckboxesIds: string;
    item: T;
    index: number;
    itemRenderer: (item: T, index: number) => React.ReactNode;
    handleSelectedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }> {}

const ListRow = <T extends ListItemVo>({ canSelect, uniqPrefixForCheckboxesIds, item, index, itemRenderer, handleSelectedChange }: ListRowProps<T>) => {
  const checkboxId = uniqPrefixForCheckboxesIds + item.id;
  return (
    <StyledItemWrapper htmlFor={checkboxId}>
      {canSelect && (
        <CheckboxV1 id={checkboxId} data-item-id={item.id} label={''} checked={item.selected} onChange={handleSelectedChange} disabled={item.disabled || item.notSelectable} />
      )}
      <ItemInfoWrapper>{itemRenderer(item, index)}</ItemInfoWrapper>
    </StyledItemWrapper>
  );
};

const ListRowMemo = React.memo(ListRow) as typeof ListRow;

export const SelectList = <T extends ListItemVo>({
  canSelect = true,
  showSelectAll = true,
  headerLabel,
  items,
  itemRenderer,
  emptyContentRenderer,
  onItemSelectedChange,
  onSelectAllClick,
  ...props
}: Props<T>) => {
  const uniqPrefixForCheckboxesIds = useMemo(() => `select_list_${SeqNumUtil.get()}_checkbox__`, []);
  const handleSelectedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      if (canSelect && onItemSelectedChange && !target.disabled) {
        onItemSelectedChange(target.dataset.itemId!, target.checked);
      }
    },
    [canSelect, onItemSelectedChange]
  );
  const isEmpty = !items || !items.length;
  return (
    <StyledListWrapper {...props}>
      <div>
        <StyledListHeader>
          {headerLabel ? <span className={'list-header-label'}>{headerLabel}</span> : canSelect && showSelectAll ? <span /> : null}
          {canSelect && showSelectAll && (
            <LinkV1 disabled={isEmpty} className={'list-header-link'} onClick={onSelectAllClick}>
              Select All
            </LinkV1>
          )}
        </StyledListHeader>
        {(isEmpty && emptyContentRenderer && emptyContentRenderer()) ||
          items.map((item, index) => {
            const rowProps: ListRowProps<T> = { canSelect, uniqPrefixForCheckboxesIds, item, index, itemRenderer, handleSelectedChange };
            return <ListRowMemo key={item.id} {...rowProps} />;
          })}
      </div>
    </StyledListWrapper>
  );
};
