import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { HandleSendEmailArgs } from '@shared/components/EmailsAndEcards/components/Confirm/Confirm.controller';
import { useToast } from '@withjoy/joykit';

export const useMobileSendTestEmailDialogController = () => {
  const { t } = useTranslation('emailsAndEcards');
  const tConfirmAndSend = t('confirmAndSend');
  const {
    currentUser: { profile: userProfile }
  } = useAuth();
  const { toast } = useToast();
  const { parentViewState, setParentViewState } = useBottomSheetState();
  const isMobile = useIsMobileScreen();
  const [isSendTestEmailDialogOpen, setIsSendTestEmailShow] = useState<boolean>(false);
  const testEmail = useMemo(() => userProfile?.email ?? undefined, [userProfile?.email]);
  const handleSendEmail = parentViewState?.handleSendEmail as (args: HandleSendEmailArgs) => void | undefined;
  const handleSendTestEmailDialogOpen = useCallback(() => {
    setIsSendTestEmailShow(true);
  }, []);
  const handleSendTestEmailDialogClose = useCallback(() => {
    setIsSendTestEmailShow(false);
  }, []);
  const handleSendTestEmail = useCallback(
    (testEmail: string, testName: string) => {
      handleSendEmail({
        testEmail,
        testName,
        onCompleted: () => {
          toast(tConfirmAndSend.emailSentSuccessMessage.guest({ count: 1 }), { icon: '✈️' });
        },
        onError: () => {
          toast(tConfirmAndSend.emailSentErrorMessage());
        }
      });
    },
    [toast, handleSendEmail, tConfirmAndSend]
  );

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, handleSendTestEmailDialogClose, handleSendTestEmailDialogOpen }));
  }, [handleSendTestEmailDialogClose, handleSendTestEmailDialogOpen, setParentViewState]);

  return { isSendTestEmailDialogOpen, testEmail, handleSendTestEmailDialogClose, handleSendTestEmail, isMobile };
};
