/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Send And Track Email',
  definitions: {
    '24': {
      viewBox: '0 0 21 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.3473 0.643255C20.3075 0.440106 21.0329 1.50068 20.4954 2.32183L12.6428 14.3194C12.3081 14.8308 11.6208 14.9713 11.1123 14.6323L8.285 12.7474L5.17724 15.2512C5.05971 15.3458 4.9071 15.3959 4.74717 15.3816C4.45145 15.355 4.2196 15.1162 4.20185 14.8198L3.87852 9.42172C3.87579 9.37602 3.87824 9.33121 3.88537 9.28786L0.682792 6.37365C0.0196488 5.77022 0.31824 4.66949 1.19542 4.48389L19.3473 0.643255ZM5.80216 13.2067L7.23835 12.0497L6.24496 11.3874L5.80216 13.2067ZM5.2784 10.2843C5.28672 10.2463 5.29884 10.2088 5.31486 10.1722C5.34352 10.1065 5.38316 10.0479 5.43101 9.99794C5.46887 9.95827 5.51136 9.92468 5.55705 9.89745L10.5722 6.67666L5.06156 9.24827C5.06927 9.28114 5.07429 9.31512 5.07638 9.34997L5.16119 10.7659L5.2784 10.2843ZM4.47398 8.20101L16.9571 2.37556L1.63481 5.61749L4.47398 8.20101ZM11.694 13.5779L6.96023 10.422L18.6901 2.88888L11.694 13.5779Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
