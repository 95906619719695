import { styled, Flex } from '@withjoy/joykit';

export const PostOfficeContainer = styled(Flex)`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.mono1};
  position: relative;

  @media screen and (max-width: 767px) {
    background-color: white;
  }
`;

export const PostOfficeWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
