import { StationeryTemplateCategoryEnum } from '@graphql/generated';

// Speeds + Delays for text animations:
export const msBetweenCharacters = 80; // Time between characters popping in
export const msBetweenPrompts = 10000; // Time from first prompt starts generating to second prompt starts generating
export const msBeforeGeneration = 500; // How long after prompt finishes displaying until generation begins
export const msChampagne = 5000; // How many ms to show champagne loader for before revealing design

// Configure card pop-in animations in PageWrapper.styles.tsx!

// Configure example prompts here.
// Prompt -- Configures what is shown in the text box.
// Images -- Array of image and template data.
// Theme and Category are REQUIRED (in image) to show card overlay.
// Category as a part of the prompt is optional, but it will include it with the
// animations that trigger when you switch categories.
export const examplePromptData = [
  {
    prompt: 'Vineyard Hills',
    category: StationeryTemplateCategoryEnum.invitation,
    images: [
      {
        src: 'https://withjoy.com/media/imagine/6a290d5d-15a7-4933-b7c1-72faac908909',
        resourceID: '6a290d5d-15a7-4933-b7c1-72faac908909',
        theme: 'wreath',
        style: 'Pastel',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/1b1bc8fb-efd2-4581-9b51-4ff3d0914de2',
        resourceID: '1b1bc8fb-efd2-4581-9b51-4ff3d0914de2',
        theme: 'northwest',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/04d8934a-947b-47ba-a177-4884c2921c01',
        resourceID: '04d8934a-947b-47ba-a177-4884c2921c01',
        theme: 'harvest_wreath',
        style: 'Bohemian',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/542b74db-cc62-497d-bc50-6bcacd8c7fcc',
        resourceID: '542b74db-cc62-497d-bc50-6bcacd8c7fcc',
        theme: 'estate_light',
        style: 'Pastel',
        category: StationeryTemplateCategoryEnum.invitation
      }
    ]
  },
  {
    prompt: 'Cactus Desert',
    category: StationeryTemplateCategoryEnum.invitation,
    images: [
      {
        src: 'https://withjoy.com/media/imagine/fe13c8ef-7194-4813-a86b-3cfc26690ccc',
        resourceID: 'fe13c8ef-7194-4813-a86b-3cfc26690ccc',
        theme: 'estate_light',
        style: 'Pastel',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/3532f454-a828-47cc-99de-9eaf96f5be0d',
        resourceID: '3532f454-a828-47cc-99de-9eaf96f5be0d',
        theme: 'gold_foil_frame',
        style: 'InkPen',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/ddcb0f0e-627e-41e3-9ffd-bdd086ed42c5',
        resourceID: 'ddcb0f0e-627e-41e3-9ffd-bdd086ed42c5',
        theme: 'northwest',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/652788df-19d2-47fe-ba3e-acaab371cd17',
        resourceID: '652788df-19d2-47fe-ba3e-acaab371cd17',
        theme: 'alpine_dream',
        style: 'InkPen',
        category: StationeryTemplateCategoryEnum.invitation
      }
    ]
  },
  {
    prompt: 'Mountain lake',
    category: StationeryTemplateCategoryEnum.invitation,
    images: [
      {
        src: 'https://withjoy.com/media/imagine/6e70b9d7-1668-4636-935b-50548f0ba5e0',
        resourceID: '6e70b9d7-1668-4636-935b-50548f0ba5e0',
        theme: 'gold_foil_frame',
        style: 'InkPen',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/151f9676-4bd9-4bcc-9522-2a1dca2b21b1',
        resourceID: '151f9676-4bd9-4bcc-9522-2a1dca2b21b1',
        theme: 'alpine_dream',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/8fc561ed-ffbe-4aae-bc65-8b2f90e5b8e7',
        resourceID: '8fc561ed-ffbe-4aae-bc65-8b2f90e5b8e7',
        theme: 'sunflowers',
        style: 'Pencil',
        category: StationeryTemplateCategoryEnum.invitation
      },
      {
        src: 'https://withjoy.com/media/imagine/30f66a92-7fb3-457b-899b-e85242f8da45',
        resourceID: '30f66a92-7fb3-457b-899b-e85242f8da45',
        theme: 'northwest',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.invitation
      }
    ]
  },
  {
    prompt: 'Italian Coastal Town',
    category: StationeryTemplateCategoryEnum.saveTheDate,
    images: [
      {
        src: 'https://withjoy.com/media/imagine/b94b945d-097c-4636-b699-00827e620cb7',
        resourceID: 'b94b945d-097c-4636-b699-00827e620cb7',
        theme: 'estate_light',
        style: 'Pastel',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/8eaf5d1c-73d0-46c2-9e4f-3d75996ef386',
        resourceID: '8eaf5d1c-73d0-46c2-9e4f-3d75996ef386',
        theme: 'sunflowers',
        style: 'Pencil',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/9d49b9a6-3acf-4344-bd8f-6f3e771920ff',
        resourceID: '9d49b9a6-3acf-4344-bd8f-6f3e771920ff',
        theme: 'northwest',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/cfcdad9f-2165-41b9-ae5f-ef3445c62e09',
        resourceID: 'cfcdad9f-2165-41b9-ae5f-ef3445c62e09',
        theme: 'gilded_maple',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.saveTheDate
      }
    ]
  },
  {
    prompt: 'Wildflowers',
    category: StationeryTemplateCategoryEnum.saveTheDate,
    images: [
      {
        src: 'https://withjoy.com/media/imagine/d54ef222-27d8-423b-92ca-9d0836f71ac0',
        resourceID: 'd54ef222-27d8-423b-92ca-9d0836f71ac0',
        theme: 'gold_foil_frame',
        style: 'Oil Painting',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/3c8de7de-3cc6-45ad-ba30-b78291c803e9',
        resourceID: '3c8de7de-3cc6-45ad-ba30-b78291c803e9',
        theme: 'northwest',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/d4f1d3c7-35d1-4f5a-a3ba-49515511a28e',
        resourceID: 'd4f1d3c7-35d1-4f5a-a3ba-49515511a28e',
        theme: 'classical_peonies',
        style: 'Bohemian',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/f652bad6-6652-4d7c-adcd-ca53641c7a4a',
        resourceID: 'f652bad6-6652-4d7c-adcd-ca53641c7a4a',
        theme: 'alpine_dream',
        style: 'InkPen',
        category: StationeryTemplateCategoryEnum.saveTheDate
      }
    ]
  },
  {
    prompt: 'Tropical Beach Sunset',
    category: StationeryTemplateCategoryEnum.saveTheDate,
    images: [
      {
        src: 'https://withjoy.com/media/imagine/61007ce3-521a-45e7-8d35-5e354dc24885',
        resourceID: '61007ce3-521a-45e7-8d35-5e354dc24885',
        theme: 'northwest',
        style: 'Watercolor',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/0b7c00db-cf0f-46b0-bed1-0e1b0d5b56af',
        resourceID: '0b7c00db-cf0f-46b0-bed1-0e1b0d5b56af',
        theme: 'classical_peonies',
        style: 'Bohemian',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/4b2d895f-9fbf-4b79-ac50-ddb5592dd257',
        resourceID: '4b2d895f-9fbf-4b79-ac50-ddb5592dd257',
        theme: 'estate_light',
        style: 'Pastel',
        category: StationeryTemplateCategoryEnum.saveTheDate
      },
      {
        src: 'https://withjoy.com/media/imagine/9ccc039b-d1f7-4555-8e70-08ff9c54c48b',
        resourceID: '9ccc039b-d1f7-4555-8e70-08ff9c54c48b',
        theme: 'sunflowers',
        style: 'Pencil',
        category: StationeryTemplateCategoryEnum.saveTheDate
      }
    ]
  }
];

export const examplePrompts = examplePromptData.map(entry => {
  return entry.prompt;
});

export const categoryPrompts = (category: StationeryTemplateCategoryEnum) => {
  return examplePromptData
    .filter(prompt => {
      return prompt.category == category;
    })
    .map(entry => {
      return entry.prompt;
    });
};

export const exampleImagesMap = examplePromptData.map((entry, index) => {
  return { [entry.prompt]: entry.images };
});
