import React from 'react';
import { EcardType } from '@graphql/generated';
import { Divider, ButtonV2, IconV2, TextV2 } from '@withjoy/joykit';
import { ReactComponent as TrackDelivery } from '@assets/icons/track-delivery.svg';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';

interface TrackGuestListProps
  extends Readonly<{
    eventHandle: string;
  }> {}

export const TrackGuestList: React.FC<TrackGuestListProps> = ({ eventHandle }) => {
  const { t } = useTranslation('emailsAndEcards');
  const tEcardLandingLeftPane = t('ecardLanding', 'ecardLandingLeftPane');
  const telemetry = useEcardsTelemetry();

  const handleOnClick = () => {
    telemetry.viewGuestListClick(EcardType.savethedate);
    withWindow(() => {
      window.location.pathname = `${eventHandle}/edit/guests`;
    });
  };

  return (
    <>
      <Divider marginY="48px" width={['100%', '720px']} />
      <IconV2 size="xl" marginBottom="16px">
        <TrackDelivery />
      </IconV2>
      <TextV2 typographyVariant="hed3">{tEcardLandingLeftPane.trackLandingTitle()}</TextV2>
      <TextV2 typographyVariant="body2" maxWidth="480px" textAlign="center" marginTop="8px" marginBottom="32px">
        {tEcardLandingLeftPane.trackDesc()}
      </TextV2>
      <ButtonV2 variant="outline" intent="neutral" shape="rounded" onClick={handleOnClick}>
        {tEcardLandingLeftPane.trackButtonText()}
      </ButtonV2>
    </>
  );
};
