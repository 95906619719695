import { ApolloError } from '@apollo/client';
import { EventTypeConfig, getEventTypeConfig } from '@config';
import { useMemo } from 'react';
import { useEventType } from './useEventType';

export type UseEventTypeConfigReturn = {
  loading: boolean;
  config: Maybe<EventTypeConfig>;
  error: Maybe<ApolloError>;
};

export function useEventTypeConfig(eventHandle?: string): UseEventTypeConfigReturn {
  const { eventType: maybeEventTypeFromCache, loading, error } = useEventType(eventHandle);

  const config = useMemo(() => {
    return maybeEventTypeFromCache ? getEventTypeConfig(maybeEventTypeFromCache) : null;
  }, [maybeEventTypeFromCache]);
  return {
    config,
    loading,
    error
  };
}
