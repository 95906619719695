import { InterFonts } from '@shared/utils/fonts/interFonts';
import { styled } from '@withjoy/joykit';

export const UserMenuWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const UserMenuContentWrapper = styled.div`
  display: flex;
  align-items: center;
  outline: none;
  border-radius: 50%;
  background: ${props => props.theme.colors.mono14};
  &:hover {
    box-shadow: 0 0 0 6px ${props => props.theme.colors.buttonOutlinePrimaryFillHover};
  }
  &:active {
    box-shadow: 0 0 0 6px ${props => props.theme.colors.buttonOutlinePrimaryFillPress};
  }
  &:focus {
    box-shadow: 0 0 0 6px ${props => props.theme.colors.purple8};
  }
`;

export const UserMenuDropdownWrapper = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  width: 16rem;
  padding: 1.5rem 1rem;
  background-color: ${props => props.theme.colors.fillLight};
  box-shadow: 0 7px 27px rgba(44, 41, 37, 0.06), 0 4px 16px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
`;

export const MenuText = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: ${props => props.theme.colors.mono14};
  text-transform: uppercase;
`;

export const OptionItem = styled.div`
  ${InterFonts.body}
  letter-spacing: 0.01em;
  padding: 0.75rem;
  color: ${props => props.theme.colors.mono14};
  display: flex;
  gap: 8px;

  &:hover {
    color: ${props => props.theme.colors.linkHover};
    background: ${props => props.theme.colors.mono2};
    & > svg {
      fill: ${props => props.theme.colors.linkHover};
    }
  }
`;
