import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  useSendEcardGuestEmailMutation,
  useSaveEcardMutation,
  useSaveEcardEmailMutation,
  EcardType,
  useSaveEcardDesignMutation,
  useGetEcardDataQuery,
  EventDesignFragment
} from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import {
  getEcardInputState,
  getEcardPayload,
  getFormattedDate,
  getInitialEcardState,
  getEcardEmailPayload,
  getEmailInputState,
  isValidDate,
  getEventWebsiteUrl,
  getEcardDesignPayload,
  isCustomECardType,
  getFilteredLinkOptions,
  ecardStateDefault
} from '@apps/ecard/Ecard.utils';
import { useImmer } from 'use-immer';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { EcardState } from '@apps/ecard/Ecard.types';
import { useEcardTranslations } from '@apps/ecard/Ecard.i18n';
import { PeopleContext } from '@shared/context/people';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { getFooterTextWithCouplesNames } from '@shared/components/EmailsAndEcards/Emails.utils';
import { getReferrer } from '@apps/postOffice/PostOffice';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useCopyToClipboard } from '@shared/utils/hooks/useCopyToClipboard';
import { useToast } from '@withjoy/joykit';
import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core';

interface UseEcardControllerArgs
  extends Readonly<{
    eventId: string;
    eventDisplayName: string;
    ecardDraftType: EcardType;
    eventHandle: string;
  }> {}

interface HandleSendEmailArgs {
  testEmail?: string;
  testName?: string;
  onCompleted?: () => void;
  onError?: () => void;
}

export interface EcardLookupPayload {
  ecardId: string;
  personId: string;
  isAnonymous?: boolean;
  eventPassword?: string | null;
}

export const useEcardController = (args: UseEcardControllerArgs) => {
  const { eventId, eventDisplayName, ecardDraftType, eventHandle } = args;
  const [isCreatingShareableLink, setIsCreatingShareableLink] = useState(false);
  const { getValueString } = useQueryParamHelper();
  const referrer = getValueString('referrer');
  const { setParentViewState } = useBottomSheetState();

  const { selectedPeople } = useContext(PeopleContext);
  const {
    currentUser: { profile: userProfile }
  } = useAuth();

  const telemetry = useEcardsTelemetry();

  const { data: ecardData, loading: loadingDraftData } = useGetEcardDataQuery({
    batchMode: 'off',
    fetchPolicy: 'no-cache',
    variables: { eventId, ecardType: ecardDraftType },
    onError(error) {
      telemetry.trackError('Ecard.controller - GetEcardData - error loading data', error, ecardDraftType);
    }
  });

  const [sendEcardGuestEmail] = useSendEcardGuestEmailMutation();
  const [saveEcard] = useSaveEcardMutation();
  const [saveEcardEmail] = useSaveEcardEmailMutation();
  const [saveEcardDesign] = useSaveEcardDesignMutation();

  const {
    eventPassword,
    eventPhotoUrl,
    isPasswordToggleShown,
    pages,
    eventDesign,
    eventInfo,
    privacyMode,
    domainName,
    website,
    linkOptions,
    footerText,
    eventDate,
    location
  } = useMemo(() => {
    const eventById = ecardData?.eventById;
    const eventInfo = eventById?.info;
    const privacyMode = eventById?.settings?.privacyMode;
    const ownerFirstName = eventInfo?.ownerFirstName;
    const fianceeFirstName = eventInfo?.fianceeFirstName;
    const filteredLinkOptions = ecardData?.getEcardLinkOptionsByEventId ? getFilteredLinkOptions(ecardData?.getEcardLinkOptionsByEventId, ecardDraftType) : [];

    return {
      eventPassword: eventById?.info.eventPassword,
      eventPhotoUrl: eventById?.photo?.url,
      pages: eventById?.pages,
      eventDesign: eventById?.eventDesign,
      eventInfo,
      privacyMode,
      isPasswordToggleShown: !!(privacyMode && privacyMode !== 'public'),
      domainName: eventById?.domain?.domainName,
      website: eventById?.website,
      linkOptions: filteredLinkOptions,
      footerText: getFooterTextWithCouplesNames(ownerFirstName, fianceeFirstName, userProfile?.email),
      eventDate: eventInfo?.finalizedDate?.timestamp,
      location: eventInfo?.location
    };
  }, [ecardData?.eventById, ecardData?.getEcardLinkOptionsByEventId, ecardDraftType, userProfile?.email]);

  const { t } = useTranslation('emailsAndEcards');
  const { getEcardDefaultTranslations, ecardNavBarTranslations } = useEcardTranslations({ ecardDraftType });

  const [state, setState] = useImmer<EcardState>(ecardStateDefault);

  const isCustomECard = useMemo(() => isCustomECardType(ecardDraftType), [ecardDraftType]);
  const formattedECardDate = useMemo(() => (isCustomECard ? state.ecardInput.date : getFormattedDate(state.ecardInput.date, state.ecardInput.dateFormat)), [isCustomECard, state]);
  const ecardTheme = useMemo(() => state.ecardInput.joyTheme?.themeId || '', [state]);

  let adminReferrer = getReferrer();
  if (referrer) {
    adminReferrer = referrer;
  }

  useEffect(() => {
    const newEcardState: EcardState = getInitialEcardState({
      eventId,
      userProfile,
      eventPhotoUrl,
      tEcardDefaults: getEcardDefaultTranslations(eventDisplayName),
      ecardDraftType,
      eventHandle,
      eventDate,
      linkOptions,
      eventDesign,
      location,
      adminReferrer
    });

    let newEcardInput: EcardState['ecardInput'] = { ...newEcardState.ecardInput };
    let newEmailInput: EcardState['emailInput'] = { ...newEcardState.emailInput };

    if (!ecardData) {
      setState(prev => ({
        ...prev,
        ecardInput: newEcardInput,
        emailInput: newEmailInput,
        adminReferrer: newEcardState.adminReferrer
      }));

      return;
    }

    if (!ecardData?.getMostRecentEcardByEventId) {
      newEcardInput = {
        ...newEcardState.ecardInput
      };
    } else {
      const savedEcard = ecardData?.getMostRecentEcardByEventId;
      newEcardInput = getEcardInputState(newEcardInput, savedEcard, linkOptions ?? [], location, eventDate);
    }

    if (ecardData?.getEcardEmailDraftsByEventId.length === 0) {
      newEmailInput = {
        ...newEcardState.emailInput
      };
      if (!newEmailInput.imageUrl && eventPhotoUrl) {
        newEmailInput = {
          ...newEmailInput,
          imageUrl: eventPhotoUrl
        };
      }
    } else {
      const savedEmail = ecardData?.getEcardEmailDraftsByEventId[0];
      newEmailInput = getEmailInputState(newEmailInput, savedEmail, newEcardInput.date, pages, eventPhotoUrl);
    }

    if (domainName || website) {
      newEcardInput = {
        ...newEcardInput,
        yourUrl: getEventWebsiteUrl(website, domainName)
      };
    }

    setState(prev => ({
      ...prev,
      ecardInput: newEcardInput,
      emailInput: newEmailInput,
      adminReferrer: newEcardState.adminReferrer
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecardData]);

  const onSaveEcardDesign = useCallback(
    (eventDesign: Maybe<EventDesignFragment>, ecardId: string, ecardInput: EcardState['ecardInput']) => {
      if (ecardInput.joyTheme && ecardInput.joyTheme.themeId && eventDesign) {
        const ecardDesignPayload = getEcardDesignPayload(eventDesign, ecardId, ecardInput);
        try {
          saveEcardDesign({
            variables: {
              payload: ecardDesignPayload
            }
          });
        } catch (error) {
          telemetry.trackError('SaveEcardDesignMutation', error, ecardDraftType);
        }
      }
    },
    [ecardDraftType, saveEcardDesign, telemetry]
  );

  const updateEmailInput = useEventCallback(async (emailInput: EcardState['emailInput']) => {
    setState(draft => {
      draft.emailInput = emailInput;
    });
    let ecardId = state.ecardInput.id;
    if (!ecardId) {
      // no ecardId - couple never edited their ecard and never saved it but they are saving ecard email - save ecard data and design
      const eCardPayload = getEcardPayload(state.ecardInput);
      telemetry.eCardSave(ecardDraftType, eCardPayload, ecardTheme);
      try {
        const res = await saveEcard({
          variables: {
            payload: eCardPayload
          }
        });
        ecardId = res.data?.saveEcard?.id;
        if (ecardId) {
          onSaveEcardDesign(eventDesign, ecardId, state.ecardInput);
        }
      } catch (error) {
        telemetry.trackError('SaveEcardMutation', error as Error, ecardDraftType);
        console.error(error);
      }
    }
    if (ecardId) {
      const emailPayload = {
        ...getEcardEmailPayload(emailInput, ecardId, ecardDraftType, state.ecardInput.date),
        ecardType: ecardDraftType as EcardType
      };
      telemetry.emailSave(emailPayload);
      try {
        saveEcardEmail({
          variables: {
            payload: emailPayload
          }
        });
      } catch (error) {
        telemetry.trackError('SaveEcardEmailMutation', error, ecardDraftType);
      }
    }
  });

  const updateEcardInput = useEventCallback(async (ecardInput: EcardState['ecardInput']) => {
    setState(draft => {
      if (draft.ecardInput.date !== ecardInput.date) {
        draft.emailInput.includeAddToCalendarLink = isValidDate(ecardInput.date) ? draft.emailInput.includeAddToCalendarLink : false;
      }
      draft.ecardInput = ecardInput;
    });

    const eCardPayload = getEcardPayload(ecardInput);
    telemetry.eCardSave(ecardDraftType, eCardPayload, ecardTheme);
    try {
      const res = await saveEcard({
        variables: {
          payload: eCardPayload
        }
      });
      const ecardId = res.data?.saveEcard?.id;
      if (ecardId) {
        setState(draft => {
          draft.ecardInput.id = ecardId;
        });
        onSaveEcardDesign(eventDesign, ecardId, ecardInput);
      }
    } catch (error) {
      telemetry.trackError('SaveEcardMutation', error, ecardDraftType);
    }
  });

  const handleSendEmail = useCallback(
    async (args: HandleSendEmailArgs) => {
      telemetry.sendECardClick(ecardDraftType, { eCardData: state.ecardInput, emailData: state.emailInput }, selectedPeople.length.toString(), state.adminReferrer);
      let ecardId = state.ecardInput.id;
      if (!ecardId) {
        // no ecardId - couple never edited their ecard and never saved it but they are sending ecard email - save ecard data and design
        const eCardPayload = getEcardPayload(state.ecardInput);
        telemetry.eCardSave(ecardDraftType, eCardPayload, ecardTheme);
        try {
          const res = await saveEcard({
            variables: {
              payload: eCardPayload
            }
          });
          ecardId = res.data?.saveEcard?.id;
          if (ecardId) {
            setState(draft => {
              draft.ecardInput.id = ecardId;
            });
            onSaveEcardDesign(eventDesign, ecardId, state.ecardInput);
          }
        } catch (error) {
          telemetry.trackError('SaveEcardMutation', error, ecardDraftType);
        }
      }
      const { testEmail, testName, onCompleted, onError } = args;

      const emailPayload = getEcardEmailPayload(
        state.emailInput,
        ecardId!,
        ecardDraftType,
        formattedECardDate || undefined,
        testName,
        testEmail,
        selectedPeople,
        footerText,
        eventInfo?.location || ''
      );
      if (testName && testEmail) {
        telemetry.sendTestEmail(ecardDraftType, testName, testEmail);
      } else {
        telemetry.sendEmail(ecardDraftType, emailPayload);
      }
      await sendEcardGuestEmail({
        variables: {
          payload: emailPayload
        }
      })
        .then(() => {
          !!onCompleted && onCompleted();
        })
        .catch(error => {
          telemetry.trackError('SendEcardGuestEmailMutation', error, ecardDraftType);
          !!onError && onError();
        });
    },
    [
      ecardDraftType,
      ecardTheme,
      eventDesign,
      eventInfo?.location,
      footerText,
      formattedECardDate,
      onSaveEcardDesign,
      saveEcard,
      selectedPeople,
      sendEcardGuestEmail,
      setState,
      state.adminReferrer,
      state.ecardInput,
      state.emailInput,
      telemetry
    ]
  );

  const copyToClipboard = useCopyToClipboard();
  const { toast } = useToast();

  const handleCopyShareableLink = useCallback(async () => {
    try {
      setIsCreatingShareableLink(true);
      const eCardPayload = getEcardPayload(state.ecardInput);
      const res = await saveEcard({
        variables: {
          payload: eCardPayload
        }
      });
      const ecardId = res.data?.saveEcard?.id;
      if (ecardId) {
        onSaveEcardDesign(eventDesign, ecardId, state.ecardInput);
        const lookupPayload: EcardLookupPayload = { ecardId, personId: 'anonymous', isAnonymous: true, eventPassword };
        const lookupId = Buffer.from(JSON.stringify(lookupPayload)).toString('base64');
        withWindow(window => {
          const link = `${window.location.origin}/ecard?lookup=${lookupId}`;
          copyToClipboard(link);
          telemetry.copyShareableLinkClicked(ecardDraftType, link);
        });
        toast(t('ecardDefaults', 'copiedLinkTooltip')());
      }
    } catch (error) {
      telemetry.trackError('Shareable link - creation', error, ecardDraftType);
    } finally {
      setIsCreatingShareableLink(false);
    }
  }, [copyToClipboard, ecardDraftType, eventDesign, eventPassword, onSaveEcardDesign, saveEcard, state.ecardInput, t, telemetry, toast]);

  useEffect(() => {
    setParentViewState(prev => ({ ...prev, handleSendEmail, eventHandle, handleCopyShareableLink, isCreatingShareableLink }));
  }, [handleSendEmail, setParentViewState, eventHandle, handleCopyShareableLink, isCreatingShareableLink]);

  return {
    aboutShareableLinksClick: telemetry.aboutShareableLinksClick,
    handleSendEmail,
    loadingDraftData,
    eventPassword,
    eventPhotoUrl,
    isPasswordToggleShown,
    pages,
    eventDesign,
    eventInfo,
    ecardState: state,
    updateEmailInput,
    updateEcardInput,
    ecardNavBarTranslations,
    testEmail: userProfile?.email ?? undefined,
    privacyMode,
    eventDate: formattedECardDate,
    footerText,
    linkOptions,
    emailIsVerified: !!userProfile?.emailIsVerified,
    handleCopyShareableLink,
    isCreatingShareableLink
  };
};
