import { useMarkStationeryTemplateAsFavoriteMutation, useUnmarkStationeryTemplateAsFavoriteMutation } from '@graphql/generated';
import { useEffect, useState } from 'react';

interface UseFavoritingProps {
  isFavorite: boolean;
  templateId: string;
  eventId: string;
}

const useFavoriting = ({ isFavorite: initialIsFavorite, templateId, eventId }: UseFavoritingProps) => {
  const [isFavorite, setIsFavorite] = useState(initialIsFavorite);

  const [markTemplateAsFavorite, { loading: isMarkingAsFavorite }] = useMarkStationeryTemplateAsFavoriteMutation();
  const [unmarkTemplateAsFavorite, { loading: isUnmarkingAsFavorite }] = useUnmarkStationeryTemplateAsFavoriteMutation();

  useEffect(() => {
    setIsFavorite(initialIsFavorite);
  }, [initialIsFavorite]);

  const toggleFavorite = async () => {
    if (!eventId) {
      return;
    }

    if (isFavorite) {
      setIsFavorite(false);
      await unmarkTemplateAsFavorite({ variables: { templateId, eventId }, refetchQueries: ['GetStationeryEntitiesCountForNavigationByEventId'], awaitRefetchQueries: true });
    } else {
      setIsFavorite(true);
      await markTemplateAsFavorite({ variables: { templateId, eventId }, refetchQueries: ['GetStationeryEntitiesCountForNavigationByEventId'], awaitRefetchQueries: true });
    }
  };

  return { isFavorite, toggleFavorite, isLoading: isMarkingAsFavorite || isUnmarkingAsFavorite };
};

export default useFavoriting;
