import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { PriceConfig } from './CardCustomizer.types';
import { getEntries } from '@shared/utils/object';
import { useFeatureValue } from '@shared/core/featureFlags';
import { PRINT_SAMPLE_ORDER_QUANTITY } from '@apps/card/config';
import { useMemo } from 'react';

export const usePrintCardsQuantityOptions = (priceConfig: PriceConfig, templateCategory: StationeryTemplateCategoryEnum) => {
  const { value: printEnableSampleOrderValue } = useFeatureValue('printEnableSampleOrder');

  return useMemo(() => {
    const entries = getEntries(priceConfig.quantity[templateCategory]).filter(entry => {
      const [, config] = entry;

      if (printEnableSampleOrderValue !== 'on' && config.quantity === PRINT_SAMPLE_ORDER_QUANTITY) {
        return false;
      }

      return true;
    });

    return entries.reduce((acc, [, config]) => {
      acc.push({
        key: config.quantity.toString(),
        label: `${config.quantity} Cards ${config.totalPriceString} (${config.individualPriceString} each)`,
        value: config.quantity
      });
      return acc;
    }, [] as Array<{ key: string; label: string; value: number }>);
  }, [priceConfig, templateCategory, printEnableSampleOrderValue]);
};
