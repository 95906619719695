import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from '@shared/core';
import PeoplePicker from './components/PeoplePicker';
import { ButtonV2, useDisclosure, useToast } from '@withjoy/joykit';
import { PeopleContext } from '@shared/context/people';
import { ReactComponent as TestEmailSvg } from '@assets/icons/test-email.svg';
import { ReactComponent as SendEmailSvg } from '@assets/icons/send-email.svg';
import { ReactComponent as AddGuestsSvg } from '@assets/icons/add-guests.svg';
import { EcardType, PostOfficeMessageType } from '@graphql/generated';
import { routePaths as accountRoutePaths } from '@apps/account/route.paths';
import { withWindow } from '@shared/utils/withWindow';
import { useFeatureValue } from '@shared/core/featureFlags';

const BUTTON_WIDTH = 230;

export interface HandleSendEmailArgs {
  testEmail?: string;
  testName?: string;
  onCompleted?: () => void;
  onError?: () => void;
}

interface ConfirmControllerArgs
  extends Readonly<{
    eventHandle: string;
    handleSendEmail: (args: HandleSendEmailArgs) => void;
    emailIsVerified: boolean;
    draftType: EcardType | PostOfficeMessageType;
    hideGuestSelector?: boolean;
    handleCopyShareableLink?: () => Promise<void>;
    isCreatingShareableLink?: boolean;
    confirmSelectGuestsTrack?: (ecardType: EcardType) => void;
    confirmTestEmailTrack?: (ecardType: EcardType) => void;
    confirmAndSendTrack?: (ecardType: EcardType) => void;
  }> {}

interface ConfirmAndSendAction {
  label: string;
  hed: string;
  dek: string;
  onClick?: () => void;
  button: React.ReactElement;
  icon: React.ReactElement;
}

export const useConfirmController = (args: ConfirmControllerArgs) => {
  const { selectedPeople, clearSelectedPeople } = useContext(PeopleContext);
  const { eventHandle, handleSendEmail, emailIsVerified, draftType, handleCopyShareableLink, confirmAndSendTrack, confirmSelectGuestsTrack, confirmTestEmailTrack } = args;
  const selectedGuestsCount = !!selectedPeople && selectedPeople.length ? selectedPeople.length : 0;
  const { t } = useTranslation('emailsAndEcards');
  const tConfirmAndSend = t('confirmAndSend');
  const tConfirmAndSendActions = tConfirmAndSend.confirmAndSendActions;
  const tAddGuests = tConfirmAndSendActions.addGuests;
  const tAddGuestsHed = tAddGuests.hed.guest;
  const tAddGuestsButton = tAddGuests.buttonText.guest;
  const { toast } = useToast();
  const [isConfirmationScreenShown, setIsConfirmationScreenShown] = useState<boolean>(false);
  const [isSendTestEmailDialogOpen, setIsSendTestEmailShow] = useState<boolean>(false);
  const [isSendEmailLoading, setIsSendEmailLoading] = useState<boolean>(false);
  const confirmedGuestsCount = useRef<number | null>(null);
  const verifyEmailURL = `/account/${accountRoutePaths.verifyEmail.goToPath()}`;
  const { value: eCardEnableShareableLinkOn } = useFeatureValue('eCardEnableShareableLink');
  const shareableLinksEnabled = handleCopyShareableLink && eCardEnableShareableLinkOn === 'on';
  const shareableDialog = useDisclosure();
  const isEcardDraftType =
    draftType === EcardType.savethedate ||
    draftType === EcardType.changethedate ||
    draftType === EcardType.custom ||
    draftType === EcardType.invitation ||
    draftType === EcardType.savethedatephoto;

  useEffect(() => {
    if (isEcardDraftType) {
      confirmAndSendTrack?.(draftType);
    }
  }, [confirmAndSendTrack, draftType, isEcardDraftType]);

  const onSendEmail = () => {
    if (selectedPeople.length === 0 && !args.hideGuestSelector) {
      toast(tConfirmAndSend.noGuestsSelectedErrorMessage());
    } else {
      setIsSendEmailLoading(true);
      handleSendEmail({
        onCompleted: () => {
          confirmedGuestsCount.current = selectedGuestsCount;
          setIsConfirmationScreenShown(true);
          setIsSendEmailLoading(false);
          clearSelectedPeople();
        },
        onError: () => {
          toast(tConfirmAndSend.emailSentErrorMessage());
          setIsSendEmailLoading(false);
        }
      });
    }
  };

  const handleSendTestEmail = (testEmail: string, testName: string) => {
    handleSendEmail({
      testEmail,
      testName,
      onCompleted: () => {
        toast(tConfirmAndSend.emailSentSuccessMessage.guest({ count: selectedGuestsCount }));
      },
      onError: () => {
        toast(tConfirmAndSend.emailSentErrorMessage());
      }
    });
  };

  const onVerifyEmailClick = () => withWindow(window => window.open(verifyEmailURL, '_blank'));

  const confirmAndSendActions: Array<ConfirmAndSendAction | null> = [
    !emailIsVerified
      ? {
          label: 'verify email',
          hed: tConfirmAndSendActions.verifyEmail.hed(),
          dek: tConfirmAndSendActions.verifyEmail.dek(),
          button: (
            <ButtonV2 variant="outline" intent="primary" onClick={onVerifyEmailClick} width={BUTTON_WIDTH}>
              {tConfirmAndSendActions.verifyEmail.buttonText()}
            </ButtonV2>
          ),
          icon: <TestEmailSvg />
        }
      : null,
    !args.hideGuestSelector
      ? {
          label: 'add guests',
          hed: tAddGuestsHed({ context: `${selectedGuestsCount}` }),
          dek: tConfirmAndSendActions.addGuests.dek(),
          button: (
            <PeoplePicker
              eventHandle={eventHandle}
              buttonTextDefault={tAddGuestsButton({ count: selectedGuestsCount, context: `${selectedGuestsCount}` })}
              buttonTextHover={tAddGuests.buttonTextHover()}
              buttonWidth={BUTTON_WIDTH}
              draftType={draftType}
              disabled={!emailIsVerified}
              onStartSelection={() => {
                if (isEcardDraftType) {
                  confirmSelectGuestsTrack?.(draftType);
                }
              }}
            />
          ),
          icon: <AddGuestsSvg />
        }
      : null,
    {
      label: 'test email',
      hed: tConfirmAndSendActions.testEmail.hed(),
      dek: tConfirmAndSendActions.testEmail.dek(),
      button: (
        <ButtonV2
          variant="outline"
          intent={draftType === 'thankyou' ? 'neutral' : 'primary'}
          onClick={() => {
            setIsSendTestEmailShow(true);

            if (isEcardDraftType) {
              confirmTestEmailTrack?.(draftType);
            }
          }}
          width={BUTTON_WIDTH}
          disabled={!emailIsVerified}
        >
          {tConfirmAndSendActions.testEmail.buttonText()}
        </ButtonV2>
      ),
      icon: <TestEmailSvg />
    },
    {
      label: 'send',
      hed: tConfirmAndSendActions.send.hed(),
      dek: tConfirmAndSendActions.send.dek(),
      button: (
        <ButtonV2
          variant="solid"
          intent="primary"
          onClick={onSendEmail}
          disabled={(!selectedGuestsCount && !args.hideGuestSelector) || !emailIsVerified}
          loading={isSendEmailLoading}
          width={BUTTON_WIDTH}
        >
          {tConfirmAndSendActions.send.buttonText()}
        </ButtonV2>
      ),
      icon: <SendEmailSvg />
    }
  ];

  const handleSendTestEmailDialogClose = () => {
    setIsSendTestEmailShow(false);
  };

  return {
    confirmAndSendActions,
    tConfirmAndSend,
    isConfirmationScreenShown,
    selectedPeople,
    selectedGuestsCount: confirmedGuestsCount.current || selectedGuestsCount,
    handleSendTestEmail,
    handleSendTestEmailDialogClose,
    isSendTestEmailDialogOpen,
    shareableLinksEnabled,
    shareableDialog
  };
};
