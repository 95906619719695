import { PostOfficeMessageType } from '@graphql/generated';

export const postOfficeDefaults: Record<
  PostOfficeMessageType,
  | {
      subject: string;
      message: string;
      includeAppInstructions: boolean;
      includeWebsiteDetails: boolean;
      salutation?: string;
    }
  | undefined
> = {
  generic: {
    subject: 'A new outdoor location',
    message: 'With the storm no longer in the forecast, we’re happy to be able to move the reception outside.\n\nAfter the ceremony, please arrive at the park by 6pm.',
    includeAppInstructions: true,
    includeWebsiteDetails: true,
    salutation: 'Hello'
  },
  reminder: {
    subject: 'It’s almost time…',
    message:
      'We know you’re busy (trust us, we’re planning a wedding, so we know busy), but we just wanted to send a friendly reminder about some of our wedding details.\n\nWe can’t wait to see you at the wedding!',
    includeAppInstructions: true,
    includeWebsiteDetails: true,
    salutation: 'Hello'
  },
  thankyou: {
    subject: 'Thank you!',
    message:
      'Thank you so much for celebrating with us on our special day! We are grateful for your presence, warm wishes, and generous gifts.\n\nWe look forward to our future together and making more memories with you!',
    includeAppInstructions: false,
    includeWebsiteDetails: false
  },
  giftWrap: undefined,
  greetingCard: undefined
};
