import { useCustomizationContext } from '@apps/card/routes/CardCustomizer';

const useGetCardDimensions = () => {
  const [layout] = useCustomizationContext(draft => draft.config.card.front.dimensions ?? draft.config.card.layout);
  const { width, height } = layout;
  let prefix = '';

  if (width && height) {
    switch (`${width}x${height}`) {
      case `5x7`:
        prefix = 'Classic ';
        break;
      case `7x5`:
        prefix = 'Classic ';
        break;
    }

    return {
      width: layout.width,
      height: layout.height,
      prefix
    };
  }

  return undefined;
};

export default useGetCardDimensions;
