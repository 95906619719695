import { useBottomSheetState } from '@shared/components/BottomSheet';

const useBottomSheetDescriptionOffset = () => {
  const { scrollPosition } = useBottomSheetState();

  return {
    transform: `translateY(${!!scrollPosition ? 20 : 0}px)`
  };
};

export default useBottomSheetDescriptionOffset;
