import { Box, styled } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

export const ItemsContainer = styled(Box)``;

const itemsListInlineStyles = (containerEdgeSizeLeft: number, containerEdgeSizeRight: number): CSSObject => ({
  display: 'flex',
  height: 'fit-content',
  whiteSpace: 'nowrap',
  width: '100%',
  paddingLeft: containerEdgeSizeLeft,
  paddingRight: containerEdgeSizeRight ?? containerEdgeSizeLeft
});

export const styles = {
  itemsListInline: itemsListInlineStyles
};
