import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { useRequestChangePasswordMutation } from '@graphql/generated';
import { useCallback, useEffect, useState } from 'react';

export const useChangePassword = () => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [requestChangePasswordMutation, { called, loading, error }] = useRequestChangePasswordMutation();
  const actions = useAccountMeTelemetry();

  useEffect(() => {
    if (called && !loading) {
      if (error) {
        setErrorMessage(error.message);
      } else {
        setShowSuccess(true);
      }
    } else {
      setErrorMessage(null);
      setShowSuccess(false);
    }
  }, [called, loading, error]);

  const hideError = useCallback(() => {
    setErrorMessage(null);
  }, []);

  const hideSuccess = useCallback(() => {
    setShowSuccess(false);
  }, []);

  const requestChangePassword = useCallback(() => {
    actions.trackChangePasswordClicked();
    requestChangePasswordMutation();
  }, [requestChangePasswordMutation, actions]);

  return {
    requestChangePassword,
    errorMessage,
    showSuccess,
    hideError,
    hideSuccess
  };
};
