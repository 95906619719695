import React, { useCallback } from 'react';
import { DialogV2, Divider, SpacingStack } from '@withjoy/joykit';
import { Box, ButtonV2, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useMfaController } from '@shared/components/MfaController/MfaControllerProvider.context';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
  }> {}

export const ResetMFADialog: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation('account');
  const telemetry = useAccountMeTelemetry();

  const { disableMfa, loading } = useMfaController();

  const handleDisableMfa = useCallback(() => {
    telemetry.trackMFARemoveButtonClick();
    disableMfa();
    onClose();
  }, [disableMfa, onClose, telemetry]);

  return (
    <DialogV2 size="md" id="ResetMFADialog" onClose={onClose} isOpen={isOpen}>
      <DialogV2.Header>
        <TextV2 textAlign="center" typographyVariant="hed4">
          {t('resetMFA', 'title')()}
        </TextV2>
      </DialogV2.Header>
      <DialogV2.Body>
        <Box display="flex" flexDirection="column" paddingY={4}>
          <TextV2 typographyVariant="body1">{t('resetMFA', 'message')()}</TextV2>
        </Box>
      </DialogV2.Body>
      <Divider />
      <DialogV2.Footer justifyContent="center">
        <SpacingStack flexDirection="column">
          <ButtonV2 intent="destructive" onClick={handleDisableMfa} disabled={loading}>
            {t('resetMFA', 'confirmButton')()}
          </ButtonV2>
          <ButtonV2 variant="ghost" color="mono14" onClick={onClose} disabled={loading}>
            {t('resetMFA', 'cancelButton')()}
          </ButtonV2>
        </SpacingStack>
      </DialogV2.Footer>
    </DialogV2>
  );
};
