import { CSSObject, pxToRem } from '@withjoy/joykit/theme';
import { styled, StyleSystemProps } from '@withjoy/joykit';
import { ReactComponent as IconBrandLogoNoColor } from '@assets/icons/icon-brand-logo-no-color.svg';

export const StyledIconBrandLogoNoColor = styled(IconBrandLogoNoColor)``;
const iconBrandLogoNoColorStyles: CSSObject = {
  width: pxToRem(45),
  height: pxToRem(32)
};

const storefrontNavigatorPlacementStyles: StyleSystemProps = {
  zIndex: 6,
  color: 'white',
  height: pxToRem(43),
  backgroundColor: 'mono14'
};

export const styles = {
  iconBrandLogoNoColor: iconBrandLogoNoColorStyles,
  storefrontNavigatorPlacement: storefrontNavigatorPlacementStyles
};
