import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { ReactComponent as GoogleLogo } from '@assets/icons/google-word-logo.svg';

export const PoweredByGoogle: React.FC = () => {
  return (
    <Flex justifyContent={'flex-end'} padding={3}>
      <TextV2 typographyVariant={'label1'}>Powered by</TextV2>
      <Flex __css={{ '& > svg': { paddingBottom: '3px' } }}>
        <GoogleLogo />
      </Flex>
    </Flex>
  );
};
