import { getEventWebsiteUrl } from '@apps/ecard/Ecard.utils';
import { useSaveTheDateTelemetry } from '@apps/saveTheDate/SaveTheDate.telemetry';
import { SaveTheDateDesignInputFields, SaveTheDateExtraData } from '@apps/saveTheDate/SaveTheDate.types';
import { EcardAdditionalLinkType, ECARD_ADDITIONAL_NAV_LINKS } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import { withWindow } from '@shared/utils/withWindow';
import { useMemo } from 'react';

interface UseBackCardControllerProps {
  ecardData: SaveTheDateDesignInputFields;
  extraData: SaveTheDateExtraData;
}

const useBackCardController = (props: UseBackCardControllerProps) => {
  const {
    ecardData,
    extraData: { guidId, website, domainName, date, location, accentId, linkOptions, eventId, eventHandle }
  } = props;
  const { additionalLink1, additionalLink2 } = ecardData;

  const { eCardLinkClick } = useSaveTheDateTelemetry();

  const yourUrl = getEventWebsiteUrl(website, domainName);

  const hasAdditionalLink1 = additionalLink1 && additionalLink1 !== EcardAdditionalLinkType.NONE;
  const hasAdditionalLink2 = additionalLink2 && additionalLink2 !== EcardAdditionalLinkType.NONE;

  const linkToLabelMap = useMemo(
    () =>
      linkOptions?.reduce((acc: Record<string, string>, curr) => {
        return {
          ...acc,
          [curr.name]: curr.label
        };
      }, {}),
    [linkOptions]
  );

  const handleNavigateAdditionalPage = (type: EcardAdditionalLinkType | string, isGuestView: boolean) => {
    if (ECARD_ADDITIONAL_NAV_LINKS[type] && eventHandle) {
      isGuestView && eCardLinkClick(ECARD_ADDITIONAL_NAV_LINKS[type], eventId);
      withWindow(global => global.open(`${global.location.origin}/${eventHandle}/${ECARD_ADDITIONAL_NAV_LINKS[type]}${guidId ? `?guidId=${guidId}` : ''}`, '_blank'));
    }
  };

  const handleLinkClick = (url: string, type: string, tracking: boolean = false, isGuestView: boolean = false) => {
    tracking && isGuestView && eCardLinkClick(type, eventId);
    if (!url.match(/^https?:\/\//i)) {
      url = `http://${url}`;
    }
    withWindow(global => global.open(url, '_blank'));
  };

  return {
    handleLinkClick,
    handleNavigateAdditionalPage,
    linkToLabelMap,
    date,
    // make event a 24 hour period as current behavior
    // see https://github.com/joylifeinc/legacy-joy-monorepo/blob/57da0ad293570f1b6c5f2dc59875b0a1ef1585d9/modules/app-browser/widgets/ecard/ecard.js#L188
    endDate: new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toDateString(),
    location,
    guidId,
    yourUrl,
    accentId,
    hasAdditionalLink1,
    hasAdditionalLink2
  };
};

export default useBackCardController;
