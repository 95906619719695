import React from 'react';
import { IconV1 } from '../../../IconV1';
import { StyledNavScrollButton } from './StyledNavScrollButton';

export interface NavScrollButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: never;
  direction: 'left' | 'right';
}

export const NavScrollButton: React.FC<NavScrollButtonProps> = React.memo(props => {
  return (
    <StyledNavScrollButton {...props} width={48} data-direction={props.direction} offset={['-1rem', null, '0px']}>
      {props.direction === 'left' ? <IconV1 source={'chevronLeft'} /> : <IconV1 source="chevronRight" />}
    </StyledNavScrollButton>
  );
});
