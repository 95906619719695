import React, { useCallback, useState, useEffect } from 'react';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { dialogOverrides, drawerOverrides, closeButtonOverrides, ContentContainer } from './BookingDialog.styles';
import { useDashboardTelemetry } from '../../../Dashboard.telemetry';
import { BookingDialogStep, OptionReminder } from './BookingDialog.const';
import { BookingDialogIntro } from './sections/BookingDialogIntro';
import { BookingDialogReminder } from './sections/BookingDialogReminder';
import { BookingDialogSuccess } from './sections/BookingDialogSuccess';
import { useBookingDialogReminderOptions } from './hooks/useBookingDialogReminderOptions';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    sourceIsBanner: boolean;
    openIntroStepLinkInNewTab?: boolean;
    onIntroStepLinkClick?: () => void;
  }> {}

export const BookingDialog: React.FC<Props> = props => {
  const { isOpen, onClose, onIntroStepLinkClick, openIntroStepLinkInNewTab, sourceIsBanner } = props;
  const [step, setStep] = useState(BookingDialogStep.Intro);
  const OPTIONS_REMINDER = useBookingDialogReminderOptions();
  const [reminderSelected, setReminderSelected] = useState(OPTIONS_REMINDER[0]);
  const { bookingDialogDismissClicked, travelDialog } = useDashboardTelemetry();

  const onSetReminderSelectedHandle = useCallback(
    (reminder: OptionReminder) => {
      setReminderSelected(reminder);
    },
    [setReminderSelected]
  );

  const onSetStepHandle = useCallback(
    (step: BookingDialogStep) => {
      setStep(step);
    },
    [setStep]
  );

  const handleDialogDismiss = useCallback(() => {
    bookingDialogDismissClicked();
    onClose();
  }, [bookingDialogDismissClicked, onClose]);

  useEffect(() => {
    if (isOpen) {
      travelDialog.enter();
    }
  }, [isOpen, travelDialog]);

  useEffect(() => {
    if (sourceIsBanner) {
      setStep(BookingDialogStep.Reminder);
    }
  }, [setStep, sourceIsBanner]);

  return (
    <ResponsiveDialog
      dialogOverrides={dialogOverrides}
      drawerOverrides={drawerOverrides}
      closeButtonOverrides={closeButtonOverrides}
      disableCloseOnEscapeKeyClick={true}
      disableCloseOnOutsideClick={true}
      isOpen={isOpen}
      onClose={handleDialogDismiss}
      hideDivider={true}
      removeBodyPadding={true}
    >
      <ContentContainer>
        {step === BookingDialogStep.Intro && <BookingDialogIntro openInNewTab={openIntroStepLinkInNewTab} onRedirectClick={onIntroStepLinkClick} setStep={onSetStepHandle} />}
        {step === BookingDialogStep.Reminder && (
          <BookingDialogReminder setReminderSelected={onSetReminderSelectedHandle} reminderSelected={reminderSelected} setStep={onSetStepHandle} />
        )}
        {step === BookingDialogStep.Success && <BookingDialogSuccess reminderSelected={reminderSelected} onBtnSuccessClicked={onClose} />}
      </ContentContainer>
    </ResponsiveDialog>
  );
};
