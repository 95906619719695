import { useBottomSheetState } from '@shared/components/BottomSheet';
import { useTranslation } from '@shared/core';
import { useEffect } from 'react';

interface ControllerProps {
  onVisible?: () => void;
}

export const useDesignECardViewController = (props: ControllerProps) => {
  const { t } = useTranslation('postOffice');
  const tBottomSheet = t('bottomSheet');
  const tDesignECard = tBottomSheet.designEcard;
  const { parentViewState } = useBottomSheetState();
  const handleButtonClick = parentViewState?.openECardEditor as () => void;
  const disableEditEmail = parentViewState?.disableEditEmail as boolean;

  useEffect(() => {
    props.onVisible?.();
  }, [props, props.onVisible]);

  return { tDesignECard, handleButtonClick, disableEditEmail };
};
