import { BackButton, StyledJoyLogo, StyledUserMenu } from './StyledTopBar';
import { StyledTopBar, ActionButtonsContainer, BackButtonContainer, CloseIcon } from './StyledTopBar.styles';

export default {
  BackButton,
  TopBar: StyledTopBar,
  ActionButtonsContainer,
  BackButtonContainer,
  StyledJoyLogo,
  StyledUserMenu,
  CloseIcon
};
