/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Faq',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.672 2.922h.002 12.655c.996.003 1.95.402 2.652 1.11a3.79 3.79 0 0 1 1.101 2.663v7.697c0 1-.394 1.96-1.097 2.67s-1.658 1.1-2.656 1.113h-4.243L12.6 20.776a.6.6 0 0 1-1.042 0l-1.495-2.61h-4.4c-.996-.003-1.95-.402-2.652-1.11s-1.098-1.665-1.1-2.663v-7.7c.003-.998.398-1.956 1.1-2.663s1.656-1.106 2.652-1.11zm.003 1.2c-.676.002-1.324.273-1.804.755s-.75 1.136-.752 1.82V14.4c.002.683.273 1.338.752 1.82s1.127.753 1.803.755H10.4a.6.6 0 0 1 .521.302l1.147 2.003 1.14-1.994a.6.6 0 0 1 .521-.302h4.59c.677-.002 1.327-.274 1.806-.758s.75-1.14.75-1.825V6.697c-.002-.683-.273-1.338-.752-1.82s-1.128-.753-1.804-.755H5.674zm6.57 3.935a1.12 1.12 0 0 0-.65.064 1.13 1.13 0 0 0-.507.419c-.124.187-.19.408-.19.634a.6.6 0 0 1-1.2 0c0-.462.136-.914.39-1.298a2.33 2.33 0 0 1 1.045-.862 2.32 2.32 0 0 1 1.347-.133c.452.09.867.314 1.193.64a2.34 2.34 0 0 1 .636 1.197 2.35 2.35 0 0 1-.132 1.35c-.176.427-.474.793-.857 1.05-.214.144-.45.25-.695.316v.783a.6.6 0 0 1-1.2 0v-1.304a.6.6 0 0 1 .6-.6 1.12 1.12 0 0 0 .626-.191c.185-.125.33-.302.416-.51a1.15 1.15 0 0 0 .065-.66 1.14 1.14 0 0 0-.31-.584c-.158-.16-.36-.267-.577-.31zm-.22 6.58a.68.68 0 1 0 0-1.362.68.68 0 1 0 0 1.362z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
