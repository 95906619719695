import { FontDefinition } from './fonts';

const IBM_Plex_Sans: FontDefinition = {
  id: 'IBM_Plex_Sans',
  url: 'https://withjoy.com/assets/public/fonts/ibm-plex-sans-v9-latin/ibm-plex-sans-v9-latin-regular.woff',
  format: 'woff',
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'regular',
  fontWeight: 'normal'
};

const IBM_Plex_Sans_Light: FontDefinition = {
  id: 'IBM_Plex_Sans_Light',
  url: 'https://withjoy.com/assets/public/fonts/ibm-plex-sans-v9-latin/ibm-plex-sans-v9-latin-300.woff',
  format: 'woff',
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'regular',
  fontWeight: '300'
};

const IBM_Plex_Sans_Bold: FontDefinition = {
  id: 'IBM_Plex_Sans_Bold',
  url: 'https://withjoy.com/assets/public/fonts/ibm-plex-sans-v9-latin/ibm-plex-sans-v9-latin-600.woff',
  format: 'woff',
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'regular',
  fontWeight: '600'
};

const IBM_Plex_Mono: FontDefinition = {
  id: 'IBM_Plex_Mono',
  url: 'https://withjoy.com/assets/public/fonts/ibm-plex-mono-v7-latin/ibm-plex-mono-v7-latin-regular.woff',
  format: 'woff',
  fontFamily: 'IBM Plex Mono',
  fontStyle: 'regular',
  fontWeight: 'normal'
};

const IBM_Plex_Mono_Bold: FontDefinition = {
  id: 'IBM_Plex_Mono_Bold',
  url: 'https://withjoy.com/assets/public/fonts/ibm-plex-mono-v7-latin/ibm-plex-mono-v7-latin-700.woff',
  format: 'woff',
  fontFamily: 'IBM Plex Mono',
  fontStyle: 'regular',
  fontWeight: '700'
};

const IBM_Plex_Mono_Light: FontDefinition = {
  id: 'IBM_Plex_Mono_Light',
  url: 'https://withjoy.com/assets/public/fonts/ibm-plex-mono-v7-latin/ibm-plex-mono-v7-latin-300.woff',
  format: 'woff',
  fontFamily: 'IBM Plex Mono',
  fontStyle: 'regular',
  fontWeight: '300'
};

// missing woff in aws, have woff2
const Playfair_Display: FontDefinition = {
  id: 'PLAYFAIR_DISPLAY',
  url: 'https://withjoy.com/assets/public/fonts/playfair.woff',
  format: 'woff',
  fontFamily: 'Playfair Display',
  fontStyle: 'regular',
  fontWeight: '400'
};

export const emailFonts = {
  IBM_Plex_Sans,
  IBM_Plex_Sans_Bold,
  IBM_Plex_Sans_Light,
  IBM_Plex_Mono,
  IBM_Plex_Mono_Bold,
  IBM_Plex_Mono_Light,
  Playfair_Display
} as const;

export const EmailFontFamilies = {
  playfairDisplay: 'Playfair Display, Didot, Bodoni MT, Times New Roman, Serif',
  ibmPlexSans: 'IBM Plex Sans, Helvetica, sans-serif',
  ibmPlexMono: 'IBM Plex Mono, Helvetica, sans-serif'
};
