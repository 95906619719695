import React, { useCallback } from 'react';
import { useSelectGuestsController } from '@apps/playground/components/SelectGuests/SelectGuests.controller';
import { useBottomSheetState } from '@shared/components/BottomSheet';
import { PeopleContext } from '@shared/context/people';
import { useContext } from 'react';
import { TextV2, IconV2 } from '@withjoy/joykit';
import { SelectRecipientsEmail } from '@withjoy/joykit/icons';
import { usePostOfficeTelemetry } from '@apps/postOffice/PostOffice.telemetry';
import { getPostOfficeDraftType } from '@apps/postOffice/PostOffice.utils';
import { useQueryParamHelper } from '@shared/core/queryString';

const useSelectRecipientsController = () => {
  const { parentViewState } = useBottomSheetState();
  const { selectedPeople, setSelectedPeople } = useContext(PeopleContext);
  const telemetry = usePostOfficeTelemetry();
  const { getValueString } = useQueryParamHelper();
  const draftType = getPostOfficeDraftType(getValueString('draftType'));

  const { startSelection } = useSelectGuestsController({
    eventHandle: (parentViewState?.eventHandle as string) || '',
    title: (
      <TextV2 typographyVariant="hed4" color="mono14" display="flex" alignItems="center" fontSize="24px" lineHeight="1">
        <IconV2 size="md" marginRight="12px">
          <SelectRecipientsEmail />
        </IconV2>{' '}
        Select Guests
      </TextV2>
    ),
    onSelectedPeople: setSelectedPeople,
    initialSelectedPeople: selectedPeople
  });

  const onSelectGuestsClick = useCallback(() => {
    telemetry.selectGuestsClicked(draftType);
    startSelection();
  }, [draftType, startSelection, telemetry]);

  return {
    onSelectGuestsClick
  };
};

export default useSelectRecipientsController;
