import React from 'react';
import { Box, CheckboxV1 } from '@withjoy/joykit';
import {
  StyledThemeImage,
  StyledThemeSelected,
  StyledThemeTitle,
  StyledThemeTitleContainer,
  StyledThemeWrapper,
  StyledThemeContainer,
  StyledUseMyColor,
  StyledUseMyColorText
} from './EcardThemeCard.styles';
import { CharmThemeFragment } from '@graphql/generated';

interface Props
  extends Readonly<{
    isCustom?: boolean;
    selected: boolean;
    useMyColor?: boolean;
    onClickUseMyColor?: () => void;
    showMyColorToggle?: boolean;
    theme: CharmThemeFragment;
    onClick?: (selected: boolean) => void;
    yourTheme?: string;
  }> {}

export const EcardThemeCard: React.FC<Props> = props => {
  const { isCustom, selected, useMyColor, onClickUseMyColor, showMyColorToggle, theme, onClick, yourTheme } = props;
  const { name, themeId, thumbnailUrl } = theme;

  const toggleUseMyColor = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClickUseMyColor) {
      onClickUseMyColor();
    }
  };

  return (
    <StyledThemeWrapper
      selected={selected}
      onClick={() => {
        if (onClick) {
          onClick(selected);
        }
      }}
    >
      <StyledThemeContainer>
        <Box position="relative" flex={1}>
          {thumbnailUrl && <StyledThemeImage src={thumbnailUrl + '?resize=h400%2Cw400'} />}
          {!isCustom && themeId === yourTheme && (
            <StyledThemeSelected>
              <StyledThemeTitle>Your Website Theme</StyledThemeTitle>
            </StyledThemeSelected>
          )}
          {selected && showMyColorToggle && (
            <StyledUseMyColor onClick={toggleUseMyColor}>
              <CheckboxV1 label="" readOnly checked={useMyColor} />
              <StyledUseMyColorText>Use My Color</StyledUseMyColorText>
            </StyledUseMyColor>
          )}
        </Box>
        <StyledThemeTitleContainer>
          <StyledThemeTitle>{name}</StyledThemeTitle>
        </StyledThemeTitleContainer>
      </StyledThemeContainer>
    </StyledThemeWrapper>
  );
};
