import { config } from '@static/js/env.config';
import { useState } from 'react';

export const ShowDevAlert = () => {
  const [shouldShowDevAlert, setShouldShowDevAlert] = useState(config.joyEnvironment === 'development');

  if (shouldShowDevAlert) {
    if (
      !window.confirm('This is a test environment for the Joy engineering team. Are you sure you want to create an event here? Click cancel to be redirected to our main site.')
    ) {
      window.location.href = 'https://withjoy.com/createwedding';
    }

    setShouldShowDevAlert(false);
  }

  return null;
};
