import { useMemo } from 'react';
import { ECARD_DATE_FORMATS } from '@shared/components/EmailsAndEcards/components/Design/Design.constants';
import { ElectronicCardCardLinkOptionFragment, EcardType } from '@graphql/generated';
import { getFormattedDate } from '@apps/ecard/Ecard.utils';
import { withWindow } from '@shared/utils/withWindow';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { isCustomECardType } from '@apps/ecard/Ecard.utils';

interface useEcardContentEditorControllerArgs
  extends Readonly<{
    date: string;
    ecardDraftType: EcardType;
    eventHandle: string;
    linkOptions?: ElectronicCardCardLinkOptionFragment[];
  }> {}

export const useEcardContentEditorController = (args: useEcardContentEditorControllerArgs) => {
  const { date, ecardDraftType, eventHandle, linkOptions } = args;

  const isCustomECard = isCustomECardType(ecardDraftType);

  const dateFormats = useMemo(() => {
    if (isCustomECard) {
      return [];
    }
    return ECARD_DATE_FORMATS.map((dateFormat: string) => {
      return {
        label: getFormattedDate(date, dateFormat),
        value: dateFormat
      };
    });
  }, [date, isCustomECard]);

  const ecardLinkOptions = useMemo(() => {
    if (!linkOptions) return [];
    return [
      {
        label: 'None',
        value: ''
      },
      ...linkOptions.map(item => ({
        label: item.label,
        value: item.name
      }))
    ];
  }, [linkOptions]);

  const telemetry = useEcardsTelemetry();
  const onGoToSettingsClick = (linkLabel: 'date' | 'location' | 'url') => {
    telemetry.eCardAdminLinkClick(ecardDraftType, linkLabel);
    withWindow(() => {
      window.open(`${window.location.origin}/${eventHandle}/edit/settings`, '_blank');
    });
  };

  return {
    dateFormats,
    ecardLinkOptions,
    isCustomECard,
    onGoToSettingsClick
  };
};
