import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useRouteMatch } from '@react-router';

export const useGetImagineContext = () => {
  const { routes, isPublicShopRoute } = useCardsRouterContext();
  const imagineRouteMatch = useRouteMatch(routes.imagine.paths);
  const cardCustomizerRouteMatch = useRouteMatch(routes.paperCardCustomizer.paths);

  if (imagineRouteMatch && !isPublicShopRoute) {
    return 'gallery';
  }

  if (imagineRouteMatch && isPublicShopRoute) {
    return 'standalone';
  }

  if (cardCustomizerRouteMatch) {
    return 'cardCustomizer';
  }

  return undefined;
};
