export const ECARD_DATE_FORMATS = ['dd MMMM yyyy', 'EEEE, dd MMMM yyyy', 'MMMM dd, yyyy', 'EEEE, MMMM dd, yyyy'];
export enum EcardAdditionalLinkType {
  ADD_TO_CALENDAR = 'calendar',
  RSVP = 'rsvp',
  BROWSE_REGISTRY = 'registry',
  VIEW_SCHEDULE = 'schedule',
  VIEW_TRAVEL_DETAILS = 'travel',
  VIEW_WEBSITE = 'website',
  MAILING_ADDRESS = 'mailingAddress',
  NONE = 'none'
}
export const ECARD_ADDITIONAL_NAV_LINKS: Record<string, string> = {
  [EcardAdditionalLinkType.BROWSE_REGISTRY]: 'registry',
  [EcardAdditionalLinkType.VIEW_WEBSITE]: 'welcome',
  [EcardAdditionalLinkType.VIEW_SCHEDULE]: 'schedule',
  [EcardAdditionalLinkType.VIEW_TRAVEL_DETAILS]: 'travel',
  [EcardAdditionalLinkType.RSVP]: 'rsvp'
};
