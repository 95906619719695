import { LabelIdsToPeopleIdsMap, LabelViewItem, PersonViewItem } from '@shared/components/PeoplePicker/PeoplePicker';
import { useCallback, useEffect, useReducer } from 'react';
import { useSelectGuestsTelemetry } from '@shared/components/PeoplePicker/PeoplePicker.telemetry';

interface PeoplePickerState
  extends Readonly<{
    labels: ReadonlyArray<LabelViewItem>;
    people: ReadonlyArray<PersonViewItem>;
    isSelectedPeopleShown: boolean;
  }> {}

interface ReinitializeStateAction
  extends Readonly<{
    type: 'REINITIALIZE_STATE';
    payload: PeoplePickerState;
  }> {}

interface ChangePersonSelectionAction
  extends Readonly<{
    type: 'CHANGE_PERSON_SELECTION';
    payload: Readonly<{
      personId: string;
      newValue: boolean;
    }>;
  }> {}

interface ChangeLabelSelectionAction
  extends Readonly<{
    type: 'CHANGE_LABEL_SELECTION';
    payload: Readonly<{
      labelId: string;
      newValue: boolean;
    }>;
  }> {}

interface ToggleSelectedPeopleShownAction
  extends Readonly<{
    type: 'TOGGLE_SELECTED_PEOPLE_SHOWN';
  }> {}

interface SelectAllPeopleAction
  extends Readonly<{
    type: 'SELECT_ALL_PEOPLE';
  }> {}

type PeoplePickerAction = Readonly<ChangePersonSelectionAction | ChangeLabelSelectionAction | SelectAllPeopleAction | ReinitializeStateAction | ToggleSelectedPeopleShownAction>;

const peoplePickerReducer = (state: PeoplePickerState, action: PeoplePickerAction) => {
  const { people, labels } = state;
  const shouldSelect = people.some(person => !person.disabled && !person.selected);
  switch (action.type) {
    case 'CHANGE_PERSON_SELECTION':
      return {
        ...state,
        people: people.map(personViewItem => {
          if (personViewItem.id === action.payload.personId) {
            return { ...personViewItem, selected: action.payload.newValue };
          }
          return personViewItem;
        })
      };
    case 'CHANGE_LABEL_SELECTION':
      return {
        ...state,
        labels: labels.map(labelViewItem => {
          if (labelViewItem.id === action.payload.labelId) {
            return { ...labelViewItem, selected: action.payload.newValue };
          }
          return labelViewItem;
        })
      };
    case 'SELECT_ALL_PEOPLE':
      return {
        ...state,
        people: people.map(personViewItem => {
          return { ...personViewItem, selected: !personViewItem.disabled && shouldSelect };
        })
      };
    case 'REINITIALIZE_STATE':
      return {
        ...action.payload
      };
    case 'TOGGLE_SELECTED_PEOPLE_SHOWN':
      return {
        ...state,
        isSelectedPeopleShown: !state.isSelectedPeopleShown
      };
    default:
      throw new Error();
  }
};

export const usePeoplePickerController = (
  initialLabels: ReadonlyArray<LabelViewItem>,
  initialPeople: ReadonlyArray<PersonViewItem>,
  labelIdsToPeopleIdsMap: LabelIdsToPeopleIdsMap
) => {
  const [state, dispatch] = useReducer(peoplePickerReducer, { people: [], labels: [], isSelectedPeopleShown: false });
  const { people, labels: viewLabels, isSelectedPeopleShown } = state;
  const telemetry = useSelectGuestsTelemetry();
  const changeLabelSelection = useCallback(
    (labelId: string, newValue: boolean) => {
      telemetry.labelSelectionChange(`labelText ${Date.now().toString()}`, newValue);
      dispatch({ type: 'CHANGE_LABEL_SELECTION', payload: { labelId, newValue } });
    },
    [dispatch, telemetry]
  );
  const changePersonSelection = useCallback(
    (personId: string, newValue: boolean) => {
      telemetry.guestSelectionChange(newValue);
      dispatch({ type: 'CHANGE_PERSON_SELECTION', payload: { personId, newValue } });
    },
    [dispatch, telemetry]
  );

  const selectAllPeople = useCallback(() => {
    telemetry.selectAllGuests();
    dispatch({ type: 'SELECT_ALL_PEOPLE' });
  }, [dispatch, telemetry]);
  const toggleSelectedPeopleShown = useCallback(() => {
    dispatch({ type: 'TOGGLE_SELECTED_PEOPLE_SHOWN' });
  }, [dispatch]);
  const peopleIdsToSelectByLabels: string[] = [];
  viewLabels.forEach(label => {
    if (label.selected) {
      const peopleIdsInLabel = labelIdsToPeopleIdsMap[label.id] || [];
      peopleIdsToSelectByLabels.push(...peopleIdsInLabel);
    }
  });
  const viewPeople: PersonViewItem[] = people.map(person => {
    if (peopleIdsToSelectByLabels.includes(person.id) && !person.disabled) {
      person = { ...person, notSelectable: true, selected: true };
    }
    return person;
  });
  const selectedPeopleCount = viewPeople.filter(person => person.selected).length;
  const getOutputData = useCallback(() => {
    const mapPersonForOutput = (person: PersonViewItem) => {
      const newPerson = { ...person };
      delete newPerson.notSelectable;
      return newPerson;
    };
    const newPeople = people.map(mapPersonForOutput);
    const mergedSelectionPeople = viewPeople.map(mapPersonForOutput);
    const newLabels = viewLabels.map(label => {
      const newLabel = { ...label };
      delete newLabel.notSelectable;
      return newLabel;
    });
    return { people: newPeople, labels: newLabels, mergedSelectionPeople };
  }, [people, viewPeople, viewLabels]);
  useEffect(() => {
    dispatch({ type: 'REINITIALIZE_STATE', payload: { people: initialPeople, labels: initialLabels, isSelectedPeopleShown: false } });
  }, [initialLabels, initialPeople]);
  return {
    selectedPeopleCount,
    changeLabelSelection,
    changePersonSelection,
    selectAllPeople,
    viewLabels,
    viewPeople,
    isSelectedPeopleShown,
    toggleSelectedPeopleShown,
    getOutputData
  };
};
