/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Rsvp',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.705 9.391a.6.6 0 1 0-.911-.781l-2.578 3.008-1.042-1.042a.6.6 0 0 0-.848.849l1.5 1.5a.6.6 0 0 0 .88-.034l3-3.5z"
            fill="currentColor"
          ></path>{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.171 3.541a2.6 2.6 0 0 0-3.309.095L3.788 8.951a2.6 2.6 0 0 0-.888 1.957v9.012a1.6 1.6 0 0 0 1.6 1.6h15a1.6 1.6 0 0 0 1.6-1.6v-7l-.005-1.947a2.6 2.6 0 0 0-1.003-2.046l-6.921-5.387zm-2.519.998a1.4 1.4 0 0 1 1.782-.051l6.921 5.387a1.4 1.4 0 0 1 .54 1.102l.004 1.696-4.399 4.399-1.662-1.662a2.6 2.6 0 0 0-3.677 0L8.5 17.072l-4.4-4.4v-1.764a1.4 1.4 0 0 1 .478-1.054l6.074-5.315zm5.696 13.381l3.551-3.551v5.551a.4.4 0 0 1-.4.4h-.751l-2.4-2.4zm.703 2.4H6.948l4.062-4.061a1.4 1.4 0 0 1 1.98 0l4.062 4.061zm-9.4-2.4l-2.4 2.4H4.5a.4.4 0 0 1-.4-.4v-5.551l3.551 3.551z"
            fill="currentColor"
          ></path>
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M10.1355 14.0282C10.3501 13.7757 10.7288 13.745 10.9813 13.9596L11.739 14.6035L12.9363 12.7893C13.1189 12.5127 13.491 12.4365 13.7676 12.619C14.0442 12.8015 14.1204 13.1737 13.9379 13.4503C13.4129 14.2456 12.3244 15.8883 12.3244 15.8883C12.2128 16.0195 12.057 16.0909 11.8976 16.099C11.7739 16.1056 11.6471 16.074 11.5358 16.0006C11.5358 16.0006 10.6383 15.2429 10.2042 14.874C9.95169 14.6594 9.92094 14.2807 10.1355 14.0282Z"
            fill="currentColor"
          />
          <path
            d="M15.7718 14.1551C15.7718 13.8237 16.0404 13.5551 16.3718 13.5551H21.5395C21.8708 13.5551 22.1395 13.8237 22.1395 14.1551C22.1395 14.4865 21.8708 14.7551 21.5395 14.7551H16.3718C16.0404 14.7551 15.7718 14.4865 15.7718 14.1551Z"
            fill="currentColor"
          />
          <path
            d="M15.7718 22.0453C15.7718 21.7139 16.0404 21.4453 16.3718 21.4453H21.5395C21.8708 21.4453 22.1395 21.7139 22.1395 22.0453C22.1395 22.3767 21.8708 22.6453 21.5395 22.6453H16.3718C16.0404 22.6453 15.7718 22.3767 15.7718 22.0453Z"
            fill="currentColor"
          />
          <path
            d="M13.8589 20.2031C14.0932 20.4374 14.0932 20.8173 13.8589 21.0517L12.8652 22.0453L13.8574 23.0375C14.0917 23.2719 14.0917 23.6518 13.8574 23.8861C13.6231 24.1204 13.2432 24.1204 13.0089 23.8861L12.0167 22.8938L11.023 23.8875C10.7887 24.1218 10.4088 24.1218 10.1745 23.8875C9.94019 23.6532 9.94019 23.2733 10.1745 23.039L11.1681 22.0453L10.1758 21.053C9.94146 20.8186 9.94146 20.4387 10.1758 20.2044C10.4101 19.9701 10.79 19.9701 11.0243 20.2044L12.0167 21.1968L13.0103 20.2031C13.2446 19.9688 13.6245 19.9688 13.8589 20.2031Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9999 1.80908C14.5006 1.80908 13.2852 3.0245 13.2852 4.5238H7.33335C6.96516 4.5238 6.66669 4.82228 6.66669 5.19047V28.5238C6.66669 28.892 6.96516 29.1905 7.33335 29.1905H24.6667C25.0349 29.1905 25.3334 28.892 25.3334 28.5238V5.19047C25.3334 4.82228 25.0349 4.5238 24.6667 4.5238H18.7147C18.7147 3.0245 17.4992 1.80908 15.9999 1.80908ZM7.86669 5.7238H9.33331V8.43448C9.33331 8.80267 9.63179 9.10115 9.99998 9.10115H22C22.3682 9.10115 22.6666 8.80267 22.6666 8.43448V5.7238H24.1334V27.9905H7.86669V5.7238ZM17.5147 4.52253C17.5147 3.68598 16.8366 3.00781 16 3.00781C15.1635 3.00781 14.4853 3.68598 14.4853 4.52253C14.4853 5.17459 13.9587 5.72254 13.2853 5.72254H10.5334V7.89988H21.4667V5.72254H18.7147C18.052 5.72254 17.5147 5.18528 17.5147 4.52253Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 7.25H32.75V9.75H15.25V7.25H20C21.3026 7.25 22.0744 6.21026 22.3456 5.42706C22.5834 4.74027 23.236 4.25 24 4.25C24.764 4.25 25.4166 4.74027 25.6544 5.42706C25.9256 6.21026 26.6974 7.25 28 7.25ZM26.8356 5.01811C27.0163 5.54 27.4477 6 28 6H37C37.5523 6 38 6.44772 38 7V42C38 42.5523 37.5523 43 37 43H11C10.4477 43 10 42.5523 10 42V7C10 6.44771 10.4477 6 11 6H20C20.5523 6 20.9837 5.54 21.1644 5.01811C21.5711 3.84347 22.687 3 24 3C25.313 3 26.4289 3.84347 26.8356 5.01811ZM14 7.25H11.25V41.75H36.75V7.25H34V10C34 10.5523 33.5523 11 33 11H15C14.4477 11 14 10.5523 14 10V7.25ZM17.4419 22.4419L21.4419 18.4419C21.686 18.1979 21.686 17.8021 21.4419 17.5581C21.1979 17.314 20.8021 17.314 20.5581 17.5581L17 21.1161L15.4419 19.5581C15.1979 19.314 14.8021 19.314 14.5581 19.5581C14.314 19.8021 14.314 20.1979 14.5581 20.4419L16.5581 22.4419C16.8021 22.686 17.1979 22.686 17.4419 22.4419ZM24 20.375C24 20.0298 24.2798 19.75 24.625 19.75H33.375C33.7202 19.75 34 20.0298 34 20.375C34 20.7202 33.7202 21 33.375 21H24.625C24.2798 21 24 20.7202 24 20.375ZM24.625 30.75C24.2798 30.75 24 31.0298 24 31.375C24 31.7202 24.2798 32 24.625 32H33.375C33.7202 32 34 31.7202 34 31.375C34 31.0298 33.7202 30.75 33.375 30.75H24.625ZM20.0633 29.1135C20.3073 29.3576 20.3073 29.7533 20.0633 29.9974L18.3839 31.6768L19.8865 33.1794C20.1306 33.4234 20.1306 33.8192 19.8865 34.0632C19.6424 34.3073 19.2467 34.3073 19.0026 34.0632L17.5 32.5606L15.9974 34.0632C15.7533 34.3073 15.3576 34.3073 15.1135 34.0632C14.8694 33.8192 14.8694 33.4234 15.1135 33.1794L16.6161 31.6768L14.9367 29.9974C14.6927 29.7533 14.6927 29.3576 14.9367 29.1135C15.1808 28.8694 15.5765 28.8694 15.8206 29.1135L17.5 30.7929L19.1794 29.1135C19.4235 28.8694 19.8192 28.8694 20.0633 29.1135Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
