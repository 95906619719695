import React from 'react';
import { useTranslation } from '@shared/core';
import { useScenario } from '@apps/createWedding/hooks';
import { SpacingStack, TextV2 } from '@withjoy/joykit';

const WeddingHeroText = ({ ownerFirstName, partnerFirstName }: { ownerFirstName: string; partnerFirstName: string }) => {
  const { t } = useTranslation('createWedding');
  const scenario = useScenario();

  const { heroText, helpText } = t('weddingForm', 'scenarios', scenario);

  return (
    <SpacingStack spacing={1}>
      <TextV2 textAlign="center" typographyVariant="hed6">
        {heroText({ ownerFirstName, partnerFirstName })}
      </TextV2>
      <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
        {helpText()}
      </TextV2>
    </SpacingStack>
  );
};

export default WeddingHeroText;
