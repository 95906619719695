import { useMemo } from 'react';
import { EventDesignFragment } from '@graphql/generated';
import { useStyleApplicator } from '@apps/guest/packages/layout-engine/common/utils/useStyleApplicator';
import { ecardConfig, getEcardApplicatorConfig } from './ecardThemeConfig';

export const useEcardDesign = (eventDesign: EventDesignFragment | undefined | null, themeId: string = 'blank', useEventColor: boolean) => {
  const { colorPalette } = eventDesign || {};

  // Get applicator config based on user design preferences
  const applicatorConfig = useMemo(() => {
    return colorPalette ? getEcardApplicatorConfig(colorPalette, useEventColor) : undefined;
  }, [colorPalette, useEventColor]);

  useStyleApplicator(
    styleApplicator => {
      if (themeId) {
        const { simple } = ecardConfig.applicatorHtmlProps;

        // Apply styles to frames
        return [styleApplicator.applyCompositionSprites(simple.selector, themeId, 'simple', 'content', applicatorConfig?.simple)];
      }
      return [];
    },
    [themeId, applicatorConfig]
  );

  return { applicatorConfig };
};
