import { styled, IconV1, TopBar } from '@withjoy/joykit';
import { ReactComponent as JoyLogo } from '@assets/joy-logo.svg';
import { ReactComponent as BackChevron } from '@assets/icons/back-chevron.svg';

export const CloseIcon = styled(IconV1)`
  cursor: pointer;
`;

export const StyledJoyLogoIcon = styled(JoyLogo)``;

export const StyledBackChevron = styled(BackChevron)``;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${StyledBackChevron} {
    margin-right: 0.5rem;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  & > *:not(:first-child) {
    margin-left: 48px;
  }
`;

export const StyledTopBar = styled(TopBar)`
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(32px);
  ${StyledJoyLogoIcon} {
    height: 40px;
  }
`;
