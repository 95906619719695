import { Box, styled, StyleSystemProps } from '@withjoy/joykit';

export const Wrapper = styled.div``;

export const PageNavWrapper = styled(Box)``;
export const PageNavWrapperStyleProps: StyleSystemProps = {
  marginTop: 7,
  display: 'block',
  marginBottom: {
    _zero: 9,
    sm2: 9,
    md3: 10
  }
};
