import React from 'react';
import { ButtonV2, Flex, SpacingStack } from '@withjoy/joykit';
import { ReactComponent as EmailSentSvg } from '@assets/icons/sent-plane.svg';
import { useConfirmationScreenController } from './ConfirmationThankYou.controller';
import { Hed, Dek, StyledIcon } from './ConfirmationThankYou.styles';
import { PersonFragment } from '@graphql/generated';

interface ConfirmationScreenProps
  extends Readonly<{
    eventHandle: string;
    selectedPeople: readonly Partial<
      PersonFragment & {
        full_name: string;
      }
    >[];
    redirect?: string;
    recipientName?: string;
  }> {}

export const ConfirmationThankYou: React.FC<ConfirmationScreenProps> = ({ eventHandle, selectedPeople, redirect, recipientName }) => {
  const { openGuestList, tConfirmationScreen, dekText } = useConfirmationScreenController({ eventHandle, redirect, selectedPeople, recipientName });
  return (
    <Flex flexDirection="column" alignItems="center" marginBottom={8}>
      <StyledIcon>
        <EmailSentSvg />
      </StyledIcon>
      <SpacingStack stack="vertical" alignItems="center" spacing={6}>
        <Hed>{tConfirmationScreen.hed}</Hed>
        <Dek textAlign="center">{dekText}</Dek>
      </SpacingStack>
      <ButtonV2 onClick={openGuestList} variant="solid" intent="primary" fontWeight={600} marginTop={8}>
        {tConfirmationScreen.buttonText}
      </ButtonV2>
    </Flex>
  );
};
