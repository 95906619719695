import React, { useCallback, useEffect, useState } from 'react';

import { accountMeRoutePaths } from '@apps/accountMe/AccountMe.route.paths';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { GetMeEventUsersEventInfoForMePageDocument, useDeleteEventMutation } from '@graphql/generated';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useTranslation } from '@shared/core';
import { qs } from '@shared/core/queryString';
import { withWindow } from '@shared/utils/withWindow';
import { Box, CloseButton, Flex, InputV2, SpacingStack, TextV2, useToast } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

import { StyledActionButton, dialogOverrides } from './DeleteEvent.styles';
import { addError } from '@shared/utils/logger';
import { closeButtonOverrides, drawerOverrides } from '@shared/components/ResponsiveDialog/ResponsiveDialog.styles';

interface Props
  extends Readonly<{
    eventId?: string;
    onClose: () => void;
    isOpen: boolean;
    category?: string;
  }> {}

export const DeleteEventDialog: React.FC<Props> = ({ onClose, isOpen, eventId, category }) => {
  const { t2 } = useTranslation('joykit');
  const tDeleteEvent = t2('deleteEvent');

  const { toast } = useToast();
  const [validationInput, setValidationInput] = useState<string | undefined>();
  const { trackLeaveEventConfirmClick } = useAccountMeTelemetry();

  const [deleteEvent, { error: apolloError, loading, called }] = useDeleteEventMutation({
    variables: { eventId: eventId || '' },
    refetchQueries: [{ query: GetMeEventUsersEventInfoForMePageDocument }],
    onCompleted: () => {
      withWindow(global => {
        const path = qs.stringifyUrl({
          url: `/account/${accountMeRoutePaths.events.path}`,
          query: {
            eventDeleteSuccess: 'true'
          }
        });
        global.location.href = global.location.origin + path;
      });
    }
  });

  const handleClose = useCallback(() => {
    setValidationInput(undefined);
    onClose();
  }, [onClose]);

  const handleDeleteEventConfirmed = () => {
    if (!eventId) {
      toast(tDeleteEvent.deleteEventFailureMessage);
      handleClose();
      addError('Missing eventId to leave event');
      return;
    }
    trackLeaveEventConfirmClick(category);
    deleteEvent();
  };

  useEffect(() => {
    if (called && !loading && !apolloError) {
      handleClose();
    } else if (apolloError) {
      toast(tDeleteEvent.deleteEventFailureMessage);
      addError(apolloError);
      handleClose();
    }
  }, [loading, called, apolloError, handleClose, toast, tDeleteEvent.deleteEventFailureMessage]);

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={handleClose}
      hasCloseButton={false}
      hideDivider={true}
      removeBodyPadding={true}
      dialogOverrides={dialogOverrides}
      drawerOverrides={{
        ...drawerOverrides,
        Content: {
          props: {
            ...drawerOverrides.Content?.props,
            boxShadow: '0px -7px 16px -8px rgba(0, 0, 0, 0.3), 0px -10px 27px -5px rgba(44, 41, 37, 0.25)'
          }
        },
        Body: { props: { ...drawerOverrides.Body?.props, paddingY: 10 } }
      }}
    >
      <Flex width="100%" justifyContent="center">
        <Flex flexDirection="column" width="100%" maxWidth={pxToRem(320)}>
          <SpacingStack spacing={6} maxWidth={pxToRem(320)}>
            <Box>
              <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
                {tDeleteEvent.title}
              </TextV2>
              <CloseButton
                overrides={{
                  Root: { props: { ...closeButtonOverrides.Root?.props, position: 'absolute', top: pxToRem(20), right: pxToRem(20) } },
                  Icon: { props: { ...closeButtonOverrides.Icon?.props } }
                }}
                onClick={handleClose}
                disabled={loading}
                aria-label={'close dialog'}
              />
            </Box>
            <TextV2 typographyVariant="body2" color="mono12">
              {tDeleteEvent.message1}
            </TextV2>
            <TextV2 typographyVariant="body2" color="mono12">
              {tDeleteEvent.message2}
            </TextV2>
            <TextV2 typographyVariant="body2" color="mono12">
              {tDeleteEvent.message3}
            </TextV2>

            <Flex flexDirection="column" gap={3}>
              <TextV2 typographyVariant="label3" color="mono14">
                {tDeleteEvent.inputTitle}
              </TextV2>
              <InputV2 placeholder="DELETE" onChange={e => setValidationInput(e.target.value)} value={validationInput}></InputV2>
            </Flex>
          </SpacingStack>
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="100%" gap={5} marginTop={8}>
        <StyledActionButton intent="destructive" onClick={handleDeleteEventConfirmed} loading={loading} disabled={loading || validationInput !== 'DELETE'}>
          {tDeleteEvent.deleteMyJoyEvent}
        </StyledActionButton>
        <StyledActionButton color="mono14" variant="ghost" backgroundColor="transparent" onClick={handleClose} disabled={loading}>
          {tDeleteEvent.cancel}
        </StyledActionButton>
      </Flex>
    </ResponsiveDialog>
  );
};
