import React, { useState, useCallback, useEffect, forwardRef } from 'react';
import { generateComponentDisplayName, safeInvoke } from '../../utils';
import { StyledTextArea, TextAreaContainer } from './StyledTextArea';

interface TextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean;
  error?: boolean;

  inputRef?: React.Ref<HTMLTextAreaElement>;

  /**
   * Base amount of rows to be displayed.
   *
   * Default: 3.
   */
  minRows?: number;
  maxRows?: number;

  // TODO:
  maxLength?: number;

  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;

  /**
   * Whether or not the input has a border.
   *
   * Commonly used when the input is part of a larger component with more context.
   */
  minimal?: boolean;

  readonly?: boolean;

  /**
   * Whether the user is allowed to resize the textarea, also by direction
   *
   * Defaults to `true`.
   */
  resize?: boolean | 'vertical' | 'horizontal';
  value: string;
  name?: HTMLTextAreaElement['name'];
}

const TextArea = React.memo(
  forwardRef<HTMLTextAreaElement, TextAreaProps>(({ onChange, onBlur, onFocus, ...props }, ref) => {
    const [focused, setFocused] = useState(false);

    const handleOnBlur = useCallback<React.FocusEventHandler<HTMLTextAreaElement>>(
      e => {
        setFocused(false);
        safeInvoke(onBlur, e);
      },
      [focused]
    );

    const handleOnFocus = useCallback<React.FocusEventHandler<HTMLTextAreaElement>>(
      e => {
        setFocused(true);
        safeInvoke(onFocus, e);
      },
      [focused]
    );

    useEffect(() => {
      if (focused) {
        setFocused(false);
      }
    }, [props.disabled]);

    const commonProps = {
      onChange: props.disabled ? undefined : onChange,
      onBlur: props.disabled ? undefined : handleOnBlur,
      onFocus: props.disabled ? undefined : handleOnFocus
    };

    return (
      <TextAreaContainer resizeEnabled={Boolean(props.resize)} disabled={props.disabled} minimal={props.minimal} error={props.error} focused={focused}>
        <StyledTextArea {...props} {...commonProps} ref={ref} />
      </TextAreaContainer>
    );
  })
) as React.ForwardRefExoticComponent<React.PropsWithoutRef<TextAreaProps> & React.RefAttributes<HTMLTextAreaElement>>;

TextArea.defaultProps = {
  minimal: false,
  resize: true,
  minRows: 3
};

TextArea.displayName = generateComponentDisplayName('TextArea');

export { TextArea, TextAreaProps };
