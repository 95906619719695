import React from 'react';
import { styled } from '../../common';
import { PageHeader } from '../PageHeader';
import { Nav as NavComponent } from '../Nav';
import { Box } from '../Box';

export interface TopBarProps {
  children: React.ReactNode;
  className?: string;
  sticky?: boolean;
}

const StyledTopBarContainer = styled(Box)<{ sticky: boolean }>`
  border-bottom: thin solid #efefef;
  ${props => {
    if (props.sticky) {
      return {
        backgroundColor: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 5
      };
    }
    return null;
  }}
`;

const topBarDefaultProps = {
  sticky: true
};

export class TopBar extends React.PureComponent<TopBarProps & typeof topBarDefaultProps> {
  static defaultProps = topBarDefaultProps;
  static Header = PageHeader;
  static Nav = NavComponent;

  render(): React.ReactNode {
    return (
      <StyledTopBarContainer className={this.props.className} sticky={this.props.sticky} paddingX={[6, null, 8]}>
        {this.props.children}
      </StyledTopBarContainer>
    );
  }
}
