import { DEFAULT_DIGITAL_PRICE_CONFIG, DigitalPriceConfig } from '@apps/card/config';
import { useFeatureValue } from '@shared/core/featureFlags';

type DigitalDeliveryMethod = DigitalPriceConfig['delivery'][string];
const isValidDeliveryMethod = (value: unknown): value is DigitalDeliveryMethod => {
  if (!value) {
    return false;
  }

  const asDelivery = value as DigitalDeliveryMethod;

  // Must have valid price
  if (!Object.hasOwn(value, 'price') || typeof asDelivery.price !== 'number') {
    return false;
  }

  // Must have valid quantity
  if (!Object.hasOwn(value, 'quantity') || typeof asDelivery.quantity !== 'number') {
    return false;
  }

  // Must have features array of only strings
  if (!Object.hasOwn(value, 'features') || !Array.isArray(asDelivery.features)) {
    return false;
  }

  if (asDelivery.features.some(item => typeof item !== 'string')) {
    return false;
  }

  // Must have a valid line item description
  if (!Object.hasOwn(value, 'lineItemText') || typeof asDelivery.lineItemText !== 'string') {
    return false;
  }

  // If an optional titleOverride is supplied, it must be a string.
  if (Object.hasOwn(value, 'titleOverride') && typeof asDelivery.titleOverride !== 'string') {
    return false;
  }

  return true;
};

const isValidDigitalPriceConfig = (payload: unknown): payload is DigitalPriceConfig => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  const priceConfig = payload as DigitalPriceConfig;

  // Must have a base price 'premiumPrice'
  if (!Object.hasOwn(payload, 'premiumPrice') || typeof priceConfig.premiumPrice !== 'number') {
    return false;
  }

  // Must have a delivery method list with at least 1 option
  if (!Object.hasOwn(payload, 'delivery') || typeof priceConfig.delivery !== 'object') {
    return false;
  }

  const deliveryMethods = Object.keys(priceConfig.delivery);
  if (deliveryMethods.length < 1) {
    return false;
  }

  // All delivery methods must be valid
  if (deliveryMethods.some(key => !isValidDeliveryMethod(priceConfig.delivery[key]))) {
    return false;
  }

  return true;
};

type PremiumDigitalPriceConfig = { digitalPriceConfig: DigitalPriceConfig; priceEachInMinorUnits: number };

export const usePremiumDigitalPriceConfig = (): PremiumDigitalPriceConfig => {
  const { value, payload } = useFeatureValue('printPremiumDigitalPricing');

  const results: PremiumDigitalPriceConfig = {
    digitalPriceConfig: DEFAULT_DIGITAL_PRICE_CONFIG,
    priceEachInMinorUnits: 0
  };

  if (value === 'on' && isValidDigitalPriceConfig(payload)) {
    results.digitalPriceConfig = payload as DigitalPriceConfig;
  }

  // Get all delivery options with a quantity and sort by price.
  const deliveryOptions = Object.keys(results.digitalPriceConfig.delivery)
    .map(key => {
      const delivery = results.digitalPriceConfig.delivery[key];
      return delivery;
    })
    .filter(delivery => !!delivery.quantity)
    .sort((a, b) => a.price - b.price);

  const priceWithBasicDelivery = results.digitalPriceConfig.premiumPrice + deliveryOptions[0].price;
  results.priceEachInMinorUnits = Math.floor(priceWithBasicDelivery / deliveryOptions[0].quantity!);

  return results;
};
