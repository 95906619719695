import React from 'react';
import { TileTypes } from '../../usePropValues';
import { CardTileConcierge } from './components/CardTileConcierge';
import { CardLink } from './components/CardLink';
import { CardTile, CardTileSource } from './components/CardTile';
import { ValuePropCard } from './components/ValueProps/ValuePropCard';

export type ConciergeSelectedEvent = {
  id: string;
};

export type CardEventType = ConciergeSelectedEvent & { type: 'conciergeselectedevent' };

type GalleryItemProps = {
  eventId?: string;
  data: TileTypes;
  eventDesignThemeId?: string;
  onCardSelect: (event: CardEventType) => void;
  hideCardTileTag?: boolean;
  cardTileSource: CardTileSource;
};

export const GalleryItem = (props: GalleryItemProps) => {
  const { data, cardTileSource, eventDesignThemeId, onCardSelect, eventId, hideCardTileTag } = props;
  switch (data.type) {
    case 'prop':
      return <ValuePropCard data={data} />;
      break;
    case 'cardlink':
      return <CardLink data={data} />;
      break;
    case 'template':
      return <CardTile cardTileSource={cardTileSource} data={data} hideTag={hideCardTileTag} eventDesignThemeId={eventDesignThemeId} eventId={eventId} />;
      break;
    case 'concierge':
      return <CardTileConcierge data={data} onSelect={onCardSelect} />;
      break;
  }
};
