import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core';
import { EcardType, PostOfficeMessageType } from '@graphql/generated';

interface UseConfirmationScreenControllerArgs
  extends Readonly<{
    eventHandle: string;
    selectedGuestsCount: number;
    redirect?: string;
    draftType?: EcardType | PostOfficeMessageType;
  }> {}

export const useConfirmationScreenController = (args: UseConfirmationScreenControllerArgs) => {
  const { eventHandle, selectedGuestsCount, redirect, draftType } = args;

  const openGuestList = () => {
    withWindow(() => {
      let redirectUrl = `${window.location.origin}/${eventHandle}/edit/guests`;
      if (draftType === 'thankyou') {
        redirectUrl = redirect ? redirect : `${window.location.origin}/${eventHandle}/edit/email/create?draftType=thankyou`;
      }
      window.location.href = redirectUrl;
    });
  };

  const openPrintPaperCards = () => {
    withWindow(() => {
      window.location.pathname = `${eventHandle}/edit/cards`;
    });
  };

  const { t, t2 } = useTranslation('emailsAndEcards');
  const tConfirmationScreen = t2('confirmAndSend', 'confirmationScreen');
  const tConfirmationScreenDek = t('confirmAndSend', 'confirmationScreen', 'dek', 'dek');
  const dekText = tConfirmationScreenDek({ count: selectedGuestsCount });

  return {
    openGuestList,
    openPrintPaperCards,
    tConfirmationScreen,
    dekText
  };
};
