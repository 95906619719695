import { styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tab-panels {
    width: 100%;
  }

  .tab-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const TabListWrapper = styled.div`
  .tab-list {
    display: flex;
    background-color: ${props => props.theme.colors.mono2};
    border-radius: 200px;
    padding: 6px;
    height: 52px;
  }
  .tab-item {
    display: block;
    color: ${props => props.theme.colors.mono10};
    padding: 8px 32px;
    border-radius: 200px;
    transition: color 0.4s;
    cursor: pointer;
    font-weight: 400;
    font-size: 17px;
    &:hover {
      color: ${props => props.theme.colors.black};
      &:active {
        color: ${props => props.theme.colors.mono6};
      }
    }
  }
  .tab-item-active {
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.white};
    box-shadow: 'rgb(51 51 51 / 10%) 0px 1px 3px -1px';
    transition: ${animationTransition('all')};
  }
`;
