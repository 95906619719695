import React, { useState, createContext, Dispatch, SetStateAction, useContext } from 'react';
import { PersonFragment } from '@graphql/generated';
import { getLocalStorage } from '@shared/core';

const localStorage = getLocalStorage();
const getInitialSelectedPeople = () => {
  const storedValue = localStorage.getItem('selectedGuests');
  const storedIdTags = !!storedValue && typeof storedValue === 'string' ? JSON.parse(storedValue) : [];
  const initialSelectedIdTags = !!storedIdTags && storedIdTags.length ? storedIdTags : undefined;
  const initialSelectedPeople =
    !!initialSelectedIdTags && initialSelectedIdTags.length
      ? (initialSelectedIdTags.map((idTag: string) => ({
          personIdTag: idTag
        })) as ReadonlyArray<Partial<PersonFragment>>)
      : [];
  return initialSelectedPeople;
};

export type SelectedPeopleDispatch = Dispatch<SetStateAction<ReadonlyArray<Partial<PersonFragment>>>>;

export const PeopleContext = createContext<{
  selectedPeople: ReadonlyArray<Partial<PersonFragment & { full_name: string; hasPhone?: boolean }>>;
  setSelectedPeople: SelectedPeopleDispatch;
  clearSelectedPeople: () => void;
}>({
  selectedPeople: [],
  setSelectedPeople() {},
  clearSelectedPeople() {}
});

export const PeopleProvider = (props: { children: React.ReactNode }) => {
  const initialSelectedPeople = getInitialSelectedPeople();
  const [selectedPeople, setSelectedPeople] = useState<ReadonlyArray<Partial<PersonFragment>>>(initialSelectedPeople);

  const clearSelectedPeople = () => {
    setSelectedPeople([]);
    localStorage.setItem('selectedGuests', undefined);
  };

  return (
    <PeopleContext.Provider
      value={{
        selectedPeople,
        setSelectedPeople,
        clearSelectedPeople
      }}
    >
      {props.children}
    </PeopleContext.Provider>
  );
};

export const usePeopleContext = () => {
  return useContext(PeopleContext);
};
