import React, { useCallback, useEffect, useMemo } from 'react';
import { StationeryDraftFormat, StationeryTemplateCategoryEnum, useCardPdpDataQuery, useCreateStationeryDraftMutation } from '@graphql/generated';
import { getLocalStorage, useHistory, useTranslation } from '@shared/core';
import { Box, ButtonV2, DialogV2, DrawerV2, Flex, TextV2, useDisclosure } from '@shared/joykit/packages';
import { Diamond, Pencil, Photos, Theme } from '@shared/joykit/packages/core/icons';
import { useSaveTheDateTelemetry } from '../../../SaveTheDate.telemetry';
import { getThemeInfo } from '../../../SaveTheDate.utils';
import { AspectRatioImage } from '../../../../card/routes/Dashboard/components/AspectRatioImage';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { BOX_SHADOW_16 } from '../../../../card/boxShadow';

const localStorage = getLocalStorage();

const getStorageKey = (eventId: string) => `upsell-premium-digitall-seen-${eventId}`;

interface PremiumDigitalUpsellProps {
  themeId: string;
  eventId: string;
  eventHandle: string;
  templateCategory: StationeryTemplateCategoryEnum.saveTheDate | StationeryTemplateCategoryEnum.invitation;
}

const usePremiumDigitalUpsell = ({ themeId, eventHandle, eventId, templateCategory }: PremiumDigitalUpsellProps) => {
  const { tryPremiumClicked, cardDraftCreated } = useSaveTheDateTelemetry();
  const history = useHistory();

  const ecardThemeId = useMemo(() => {
    if (templateCategory === 'saveTheDate') {
      return getThemeInfo(themeId)?.themeId || themeId;
    }

    return themeId;
  }, [templateCategory, themeId]);

  const { data } = useCardPdpDataQuery({
    variables: { themeId: ecardThemeId!, category: templateCategory, skipDigitalDesign: false },
    batchMode: 'fast',
    skip: !ecardThemeId
  });

  const stationeryTemplate = data?.stationeryTemplatesByThemeId[0];
  const premiumDigitalAssetUrl = stationeryTemplate?.thumbnailUrl;

  const [createDraft, { loading }] = useCreateStationeryDraftMutation({
    onCompleted: data => {
      const { id } = data.createStationeryDraft;
      history.push(`/${eventHandle}/edit/cards/digital/customize/${id}`);
      if (stationeryTemplate) {
        cardDraftCreated({
          page: 'modernEcardEditor',
          draftId: data.createStationeryDraft.id,
          themeId: stationeryTemplate.themeId,
          stationeryTemplateCategory: templateCategory,
          isFavorite: stationeryTemplate.isFavorite,
          isDigital: true,
          isPremiumDesign: stationeryTemplate.premium,
          format: 'premiumDigital'
        });
      }
    }
  });

  const onTryPremiumUpsellClick = useCallback(
    (templateCategory: 'saveTheDate' | 'invitation', from: 'banner' | 'dialog') => {
      if (stationeryTemplate) {
        tryPremiumClicked({ templateCategory, from, themeId: stationeryTemplate.themeId });
        createDraft({
          variables: {
            payload: {
              cardJSON: stationeryTemplate.themeJSON,
              eventId,
              format: StationeryDraftFormat.digital,
              stationeryTemplateId: stationeryTemplate.id
            }
          }
        });
      }
    },
    [createDraft, eventId, stationeryTemplate, tryPremiumClicked]
  );

  return { isCreatingDraft: loading, premiumDigitalAssetUrl, onTryPremiumUpsellClick };
};

export const PremiumDigitalUpsellBanner: React.FC<PremiumDigitalUpsellProps> = ({ themeId, templateCategory, eventHandle, eventId }) => {
  const { t } = useTranslation('emailsAndEcards');
  const translations = t('ecardEditorPhotoSaveTheDate');
  const { premiumDigitalAssetUrl, onTryPremiumUpsellClick } = usePremiumDigitalUpsell({ themeId, eventHandle, eventId, templateCategory });

  if (!premiumDigitalAssetUrl) {
    return null;
  }
  return (
    <Flex
      paddingY={5}
      paddingX={6}
      marginTop={4}
      backgroundColor="mono2"
      borderRadius={4}
      width="100%"
      flexDirection="row"
      onClick={() => onTryPremiumUpsellClick(templateCategory, 'banner')}
      cursor="pointer"
    >
      <Box height="8rem" position="relative">
        <Box
          typographyVariant="label1"
          fontWeight={600}
          position="absolute"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          backdropFilter="blur(2px)"
          right={-10}
          top={-10}
          backgroundColor="rgba(0, 0, 0, 0.40)"
          color="white"
          userSelect="none"
          zIndex={1}
          title="Premium Digital Available"
        >
          <Diamond color="hsl(40, 60%, 60%)" size={40} transform="translateY(1px)" />
        </Box>
        <AspectRatioImage src={premiumDigitalAssetUrl} />
      </Box>
      <Flex gap={3} flexDirection="column" marginLeft={6}>
        <TextV2 typographyVariant="hed1">{translations.premiumUpsell.banner.title()}</TextV2>
        <TextV2>{translations.premiumUpsell.banner.description()}</TextV2>
        <ButtonV2 variant="link" intent="neutral" size="sm" typographyVariant="hed1" justifyContent="flex-start">
          {translations.premiumUpsell.banner.cta()}
        </ButtonV2>
      </Flex>
    </Flex>
  );
};

export const PremiumDigitalUpsellDialog: React.FC<PremiumDigitalUpsellProps> = ({ themeId, eventId, eventHandle, templateCategory }) => {
  const { isCreatingDraft, premiumDigitalAssetUrl, onTryPremiumUpsellClick } = usePremiumDigitalUpsell({ themeId, eventHandle, eventId, templateCategory });
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { t } = useTranslation('emailsAndEcards');
  const translations = t('ecardEditorPhotoSaveTheDate');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { premiumDigitalUpsellDialogTrack } = useSaveTheDateTelemetry();

  const hasSeenPremiumUpsellDialog = !!localStorage.getItem(getStorageKey(eventId));

  useEffect(() => {
    if (eventId && !hasSeenPremiumUpsellDialog) {
      onOpen();
      localStorage.setItem(getStorageKey(eventId), 'true');
      premiumDigitalUpsellDialogTrack.enter(themeId, templateCategory);
    }
  }, [eventId, hasSeenPremiumUpsellDialog, onOpen, premiumDigitalUpsellDialogTrack, templateCategory, themeId]);

  if (!premiumDigitalAssetUrl) {
    return null;
  }
  const content = (
    <>
      <Flex height={['15rem', '25rem']} padding={[6, 3]} justifyContent="center" backgroundColor={['mono2', 'white']}>
        <AspectRatioImage src={premiumDigitalAssetUrl} borderRadius={4} boxShadow={BOX_SHADOW_16}>
          <Box
            typographyVariant="label1"
            fontWeight={600}
            position="absolute"
            borderRadius="50%"
            display="flex"
            alignItems="center"
            backdropFilter="blur(2px)"
            right={-10}
            top={-10}
            backgroundColor="rgba(0, 0, 0, 0.40)"
            color="white"
            userSelect="none"
            zIndex={1}
            title="Premium Digital Available"
          >
            <Diamond color="hsl(40, 60%, 60%)" size={56} transform="translateY(1px)" padding="10px" />
          </Box>
        </AspectRatioImage>
      </Flex>
      <Flex flexDirection="column" gap="2rem" paddingX={['2rem', 0]}>
        <TextV2 typographyVariant="hed6">{translations.premiumUpsell.dialog.title()}</TextV2>
        <Flex flexDirection="column" gap="1rem">
          <TextV2 typographyVariant="body2" display="flex">
            <Pencil size={20} marginRight={2} />
            {translations.premiumUpsell.dialog.valuePropEdit()}
          </TextV2>
          <TextV2 typographyVariant="body2" display="flex">
            <Photos size={20} marginRight={2} />
            {translations.premiumUpsell.dialog.valuePropCustomize()}
          </TextV2>
          <TextV2 typographyVariant="body2" display="flex">
            <Theme size={20} marginRight={2} />
            {translations.premiumUpsell.dialog.valuePropSilhoutte()}
          </TextV2>
        </Flex>
        <Flex gap="0.75rem" paddingY={['1.5rem', 0]} flexDirection="column">
          <ButtonV2 fullWidth intent="neutral" shape="rounded" onClick={() => onTryPremiumUpsellClick(templateCategory, 'dialog')} loading={isCreatingDraft}>
            {translations.premiumUpsell.dialog.cta()}
          </ButtonV2>
          <ButtonV2 fullWidth intent="neutral" onClick={onClose} shape="rounded" variant="outline" disabled={isCreatingDraft}>
            {translations.premiumUpsell.dialog.cancel()}
          </ButtonV2>
        </Flex>
      </Flex>
    </>
  );

  return isMobile ? (
    <DrawerV2 enableReturnFocusOnClose onClose={onClose} useBackdrop={true} isOpen={isOpen} anchor="bottom" size="auto">
      <DrawerV2.CloseButton />
      <Flex gap="2.5rem" flexDirection={'column'}>
        {content}
      </Flex>
    </DrawerV2>
  ) : (
    <DialogV2 onClose={onClose} isOpen={isOpen} size="2xl">
      <DialogV2.CloseButton />
      <Flex gap="2.5rem" flexDirection={'row'} paddingX={8} paddingY={10}>
        {content}
      </Flex>
    </DialogV2>
  );
};

export default usePremiumDigitalUpsell;
