import React from 'react';
import { DialogV2, DrawerV2, useDisclosure } from '@withjoy/joykit';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useOuterClick } from '@shared/utils/hooks/useOuterClick';
import { DialogBody, DialogFooter, drawerOverrides } from '@apps/admin/routes/Dashboard/common/commonDialog.styles';
import { dialogOverrides } from './DashboardDialog.styles';
import { DashboardDialogProps } from './DashboardDialog.types';
import { DashboardDialogHeader } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/DashboardDialog/DashboardDialogHeader/DashboardDialogHeader';

export const DashboardDialog: React.FC<DashboardDialogProps> = ({ title, hasDivider = true, header, footer, maxWidth, isOpen: isDialogOpen, onClose: onCloseDialog, children }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { isOpen, onClose, onToggle } = useDisclosure({ isOpen: isDialogOpen, onClose: onCloseDialog });
  const modalRef = useOuterClick(onToggle, !isMobile && isOpen);
  const drawerRef = useOuterClick(onToggle, isMobile && isOpen);

  const content = (
    <div>
      <DashboardDialogHeader title={title} hasDivider={hasDivider} header={header} onClose={onClose} />
      <DialogBody>{children}</DialogBody>
      {footer && <DialogFooter>{footer}</DialogFooter>}
    </div>
  );

  return (
    <>
      {isMobile ? (
        <DrawerV2 disableAutoFocus enableReturnFocusOnClose useBackdrop={true} overrides={drawerOverrides} isOpen={isOpen} anchor="bottom">
          <div ref={drawerRef}>{content}</div>
        </DrawerV2>
      ) : (
        <DialogV2 ref={modalRef} overrides={dialogOverrides(maxWidth)} onClose={onClose} isOpen={isOpen}>
          {content}
        </DialogV2>
      )}
    </>
  );
};
