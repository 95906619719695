/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Guest List',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M12 2.735c-2.246 0-4.066 1.821-4.066 4.066 0 1.409.717 2.651 1.806 3.381a5.8 5.8 0 0 0-1.844 1.362l-.031.034c-.281-.318-.6-.586-.945-.797.647-.534 1.059-1.342 1.059-2.246a2.91 2.91 0 1 0-5.822 0c0 .904.412 1.712 1.059 2.246a4.19 4.19 0 0 0-1.064.937C1.405 12.635 1 13.855 1 15.108v2.726c0 .306.098.619.299.865s.509.423.861.423H6.2v.614a1.58 1.58 0 0 0 .401 1.058 1.4 1.4 0 0 0 1.039.471h8.72a1.4 1.4 0 0 0 1.039-.471 1.58 1.58 0 0 0 .401-1.058v-.614h4.04c.352 0 .658-.173.861-.422s.299-.559.299-.865v-2.726c0-1.253-.404-2.473-1.15-3.389-.309-.38-.669-.697-1.064-.937.646-.534 1.059-1.342 1.059-2.246a2.91 2.91 0 1 0-5.822 0c0 .904.412 1.712 1.059 2.246-.345.21-.664.479-.945.797l-.014-.015a5.8 5.8 0 0 0-1.861-1.382c1.089-.73 1.806-1.971 1.806-3.381 0-2.246-1.82-4.066-4.066-4.066zM9.134 6.801c0-1.583 1.283-2.866 2.866-2.866s2.866 1.283 2.866 2.866S13.583 9.668 12 9.668 9.134 8.384 9.134 6.801zM6.2 17.921v-1.938c.009-1.214.335-2.394.932-3.404l-.089-.113c-.551-.67-1.265-1.015-1.977-1.015s-1.434.348-1.986 1.026-.881 1.626-.881 2.631v2.726a.21.21 0 0 0 .018.087H6.2zm10.667-5.342c.606 1.025.932 2.226.932 3.458v1.884h3.983c.009-.019.017-.048.017-.088v-2.726c0-1.005-.326-1.95-.88-2.631s-1.27-1.026-1.986-1.026-1.427.344-1.977 1.015l-.089.113zm-8.085-.226c.867-.952 2.025-1.473 3.217-1.473 1.199 0 2.363.527 3.231 1.489s1.368 2.283 1.368 3.669v3.698a.38.38 0 0 1-.091.254c-.052.058-.107.076-.148.076H7.64c-.041 0-.096-.018-.149-.076a.38.38 0 0 1-.091-.254V15.99c.011-1.378.514-2.684 1.382-3.637zm10.15-5.528a1.71 1.71 0 1 0 0 3.422 1.71 1.71 0 1 0 0-3.422zm-13.865 0a1.71 1.71 0 1 1 0 3.422 1.71 1.71 0 1 1 0-3.422z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M28.577 23.994C29.488 22.135 30 20.045 30 17.836c0-7.732-6.268-14-14-14s-14 6.268-14 14c0 2.209.512 4.299 1.423 6.157a4.73 4.73 0 0 0-.023.467v1.546a1.28 1.28 0 0 0 1.267 1.267h5.226c.24.536.788.892 1.377.892h9.44c.589 0 1.137-.357 1.377-.892h5.245c.336 0 .658-.133.896-.371s.371-.56.371-.896v-1.546a4.74 4.74 0 0 0-.023-.466zm-.545-1.78c.497-1.366.768-2.84.768-4.377 0-7.069-5.731-12.8-12.8-12.8s-12.8 5.731-12.8 12.8c0 1.537.271 3.011.768 4.377.216-.4.49-.77.817-1.096.385-.385.829-.696 1.311-.926-.748-.597-1.227-1.517-1.227-2.549a3.26 3.26 0 0 1 3.26-3.26 3.26 3.26 0 0 1 3.26 3.26c0 1.032-.479 1.952-1.228 2.549.317.151.617.337.895.556a6.26 6.26 0 0 1 2.596-1.973c-1.138-.757-1.888-2.052-1.888-3.521a4.23 4.23 0 0 1 4.227-4.227 4.23 4.23 0 0 1 4.227 4.227c0 1.47-.75 2.764-1.888 3.521 1.024.415 1.931 1.105 2.604 1.982.281-.223.585-.412.907-.565-.748-.597-1.227-1.517-1.227-2.549a3.26 3.26 0 0 1 3.26-3.26 3.26 3.26 0 0 1 3.26 3.26c0 1.032-.479 1.952-1.227 2.549a4.73 4.73 0 0 1 1.311.926c.327.327.601.697.817 1.097zM15.99 12.225a3.03 3.03 0 0 0-3.027 3.027 3.03 3.03 0 0 0 3.027 3.027 3.03 3.03 0 0 0 3.027-3.027 3.03 3.03 0 0 0-3.027-3.027zM9.761 26.072H4.667c-.035 0-.067-.031-.067-.067v-1.546a3.53 3.53 0 0 1 3.528-3.528 3.53 3.53 0 0 1 2.286.84 6.23 6.23 0 0 0-.652 2.722v1.579zm12.458-1.525a6.23 6.23 0 0 0-.647-2.764 3.53 3.53 0 0 1 4.794.182 3.53 3.53 0 0 1 1.033 2.495v1.546c0 .035-.031.067-.067.067h-5.113v-1.525zm-6.229-5.029a5.03 5.03 0 0 0-5.029 4.983v2.154a.32.32 0 0 0 .309.309h9.44a.32.32 0 0 0 .309-.309v-2.108a5.03 5.03 0 0 0-5.029-5.029zm5.822-1.876a2.06 2.06 0 0 1 2.06-2.06 2.06 2.06 0 0 1 2.06 2.06 2.06 2.06 0 0 1-2.06 2.06 2.06 2.06 0 0 1-2.06-2.06zm-11.624 0a2.06 2.06 0 0 0-2.06-2.06 2.06 2.06 0 0 0-2.06 2.06 2.06 2.06 0 0 0 2.06 2.06 2.06 2.06 0 0 0 2.06-2.06z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M42.621 36.608A20.91 20.91 0 0 0 45 26.891c0-11.598-9.402-21-21-21s-21 9.402-21 21a20.91 20.91 0 0 0 2.378 9.717c-.027.845-.003 1.693-.003 2.537 0 .885.74 1.625 1.625 1.625h8.026a2.01 2.01 0 0 0 1.88 1.339h14.16a2.01 2.01 0 0 0 1.88-1.339H41c.885 0 1.625-.74 1.625-1.625 0-.845.024-1.692-.003-2.537zm-.398-2.089c.984-2.347 1.527-4.925 1.527-7.629 0-10.908-8.842-19.75-19.75-19.75S4.25 15.983 4.25 26.891a19.69 19.69 0 0 0 1.527 7.628c.337-.936.878-1.797 1.595-2.514.669-.669 1.462-1.184 2.326-1.524a4.61 4.61 0 0 1-2.12-3.883c0-2.549 2.066-4.615 4.615-4.615s4.615 2.066 4.615 4.615a4.61 4.61 0 0 1-2.12 3.883c.704.277 1.362.671 1.943 1.17.29-.401.614-.781.97-1.134a9.07 9.07 0 0 1 3.509-2.16c-1.899-1.025-3.19-3.032-3.19-5.342 0-3.35 2.716-6.066 6.066-6.066s6.066 2.716 6.066 6.066c0 2.309-1.29 4.317-3.19 5.342a9.07 9.07 0 0 1 3.537 2.188c.35.35.668.725.953 1.122.586-.506 1.25-.905 1.961-1.185a4.61 4.61 0 0 1-2.12-3.883c0-2.549 2.066-4.615 4.615-4.615s4.615 2.066 4.615 4.615a4.61 4.61 0 0 1-2.12 3.883c.863.34 1.657.855 2.325 1.524.717.717 1.258 1.578 1.595 2.514zm-18.237-16.32c-2.659 0-4.815 2.156-4.815 4.816s2.156 4.816 4.816 4.816 4.816-2.156 4.816-4.815-2.156-4.816-4.815-4.816zm-9.068 18.685v2.636H7c-.199 0-.375-.176-.375-.375v-2.319c0-1.476.587-2.892 1.631-3.936s2.46-1.631 3.936-1.631a5.57 5.57 0 0 1 3.77 1.471 9.05 9.05 0 0 0-1.045 4.155zm18.137.073a9.07 9.07 0 0 0-1.036-4.21c1.028-.956 2.382-1.489 3.79-1.489 1.477 0 2.893.587 3.937 1.631s1.63 2.46 1.63 3.936v2.319c0 .199-.176.375-.375.375h-7.945v-2.563zm-9.069-7.819a7.82 7.82 0 0 0-7.819 7.749v3.233a.76.76 0 0 0 .739.739h14.16a.76.76 0 0 0 .739-.739v-3.163a7.82 7.82 0 0 0-7.819-7.819zm8.458-2.54c0-1.858 1.506-3.365 3.365-3.365s3.365 1.506 3.365 3.365-1.507 3.365-3.365 3.365-3.365-1.506-3.365-3.365zm-16.886 0c0-1.858-1.506-3.365-3.365-3.365s-3.365 1.506-3.365 3.365 1.506 3.365 3.365 3.365 3.365-1.506 3.365-3.365z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
