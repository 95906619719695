import React from 'react';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { BaseComponentProps } from '../../common/props';
import { Box } from '../Box';

export interface InlineErrorProps extends BaseComponentProps {
  message: string;

  /** A unique identifier for the error */
  id: string;
}

const StyledError = styled(Box)``;

/**
 * Brief
 */
export const InlineError: React.SFC<InlineErrorProps> = React.memo(props => {
  return (
    <StyledError typeVariant="label2" color="red6" {...props}>
      {props.message}
    </StyledError>
  );
});
