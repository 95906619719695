import React from 'react';
import { styled, css } from '../../common';
import { animationTransition } from '../../utils';
import { TagVariant, TagIntent } from './';
import { ThemeStyledProps } from '@shared/joykit/packages/core/common/styledComponents';

const DeleteRoot = styled.svg`
  transition: ${animationTransition('color')};
  &:hover {
    color: ${props => props.theme.colors.negative6};
  }
`;

export const Delete: React.FC<React.HTMLAttributes<HTMLOrSVGElement> & { className?: string }> = props => {
  return (
    <DeleteRoot {...props} width="10" height="10" viewBox="0 0 9.5 9.5" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89565 1.10435C8.75652 0.965217 8.53623 0.965217 8.4087 1.10435L5 4.51304L1.5913 1.10435C1.45217 0.965217 1.23188 0.965217 1.10435 1.10435C0.965217 1.24348 0.965217 1.46377 1.10435 1.5913L4.51304 5L1.10435 8.4087C0.965217 8.54783 0.965217 8.76812 1.10435 8.89565C1.17391 8.96522 1.26667 9 1.34783 9C1.42899 9 1.52174 8.96522 1.5913 8.89565L5 5.48696L8.4087 8.89565C8.47826 8.96522 8.57101 9 8.65217 9C8.73333 9 8.82609 8.96522 8.89565 8.89565C9.03478 8.75652 9.03478 8.53623 8.89565 8.4087L5.48696 5L8.89565 1.5913C9.03478 1.45217 9.03478 1.24348 8.89565 1.10435Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </DeleteRoot>
  );
};

const getOutlinedVariantStyles = (props: ContainerProps) => {
  let interactiveStyles;

  const borderColor = props.theme.colors.primary8;
  const backgroundColor = props.theme.colors.primary3;
  const backgroundColorHover = props.theme.colors.primary2;
  const color = props.theme.colors.primary8;

  if (props.isInteractive) {
    interactiveStyles = css`
      &:hover {
        background-color: ${backgroundColorHover};
        cursor: pointer;
      }

      &:active {
        background-color: ${backgroundColor};
      }
    `;
  }

  return css`
    border: 1px solid ${borderColor};
    background-color: ${backgroundColor};
    color: ${color};
    ${interactiveStyles}
  `;
};
const getSolidVariantStyles = (props: ContainerProps) => {
  let interactiveStyles;
  const backgroundColor = props.theme.colors.primary8;
  const backgroundColorHover = props.theme.colors.primary7;

  if (props.isInteractive) {
    interactiveStyles = css`
      &:hover {
        background-color: ${backgroundColorHover};
        cursor: pointer;
      }
      &:active {
        background-color: ${backgroundColor};
      }
    `;
  }

  return css`
    background-color: ${backgroundColor};
    color: white;
    ${interactiveStyles}
  `;
};

type ContainerProps = ThemeStyledProps<{ isInteractive: boolean; variant: TagVariant; intent: TagIntent; disabled?: boolean }>;

export const Container = styled.span<ContainerProps>`
  ${DeleteRoot} {
    margin-left: ${props => props.theme.space[4]};
    cursor: pointer;
  }
  align-items: center;
  border-radius: ${props => props.theme.radii[2]};
  display: inline-flex;
  flex-direction: row;
  min-height: 32px;
  outline: none;
  padding: 0 ${props => props.theme.space[4]};
  transition: ${animationTransition('background-color', 'color', 'border')};
  ${props => {
    switch (props.variant) {
      case 'outlined':
        return getOutlinedVariantStyles(props);
      default:
        return getSolidVariantStyles(props);
    }
  }}
`;
