import React from 'react';

import { styled, CHOICE_INDICATOR } from '../../common';
import { ToggleProps } from './';
import { animationTransition } from '../../utils';
import { ThemedStyledProps, DefaultTheme } from 'styled-components';

const getBackgroundColor = ({ intent = 'primary', theme }: ThemedStyledProps<ToggleProps, DefaultTheme>) => {
  switch (intent) {
    case 'primary':
      return theme.colors.primary8;
    case 'neutral':
      return theme.colors.mono14;
    default:
      return '#502080';
  }
};

export const Indicator = styled.div`
  width: 52px;
  position: relative;
  height: 32px;
  border-radius: 60px;
  background-color: ${props => (props.theme.colors.mono3 ? props.theme.colors.mono3 : '#949494')};
  transition: ${animationTransition('background-color')};

  :before {
    background: #fff;
    position: absolute;
    content: ' ';
    display: block;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%) translateZ(0.1px) translateX(3px);
    height: 26px;
    width: 26px;
    transition: ${animationTransition('transform')};
  }
`;

const Input: React.FC<ToggleProps> = ({ children, alignIndicator, label, defaultChecked, hideLabel, ...props }) => (
  <input aria-checked={props.checked} onChange={props.onChange} {...props} defaultChecked={defaultChecked} type={'checkbox'} />
);

export const StyledInput = styled(Input)`
  :enabled {
    /* :focus ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.buttonPrimaryFillDark};
    }
    :hover ~ .${CHOICE_INDICATOR} {
      border-color: ${props => props.theme.colors.buttonPrimaryFillDark};
    }
    :active ~ .${CHOICE_INDICATOR} {
      background: red;
    } */
  }

  &:checked ~ ${Indicator} {
    background-color: ${props => getBackgroundColor(props)};
    &:before {
      transform: translateY(-50%) translateZ(0.1px) translateX(23px);
    }
  }

  &:disabled ~ ${Indicator} {
    background-color: ${props => (props.theme.colors.buttonDisabledFill ? props.theme.colors.buttonDisabledFill : '#ebebeb')};
  }
`;
