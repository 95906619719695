import React from 'react';
import { SkeletonText, SkeletonThumbnail, SkeletonGroup } from '@shared/components/Skeleton';
import { Box } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { skeletonOverrides } from './EventCardSkeletonGroup.styles';

const EventCardSkeleton = () => {
  return (
    <>
      <SkeletonThumbnail
        borderRadius={3}
        overrides={{
          PlaceholderRoot: {
            props: {
              width: '100%',
              paddingTop: '75%',
              backgroundColor: 'white'
            }
          }
        }}
      />

      <Box width="100%" height={pxToRem(221)} padding={pxToRem(24)}>
        <SkeletonText rows={1} width={pxToRem(136)} skeletonHeight={pxToRem(26)} paddingBottom={3} />
        <SkeletonText rows={1} width={pxToRem(250)} skeletonHeight={pxToRem(26)} paddingBottom={3} />
        <SkeletonText rows={1} width={pxToRem(206)} skeletonHeight={pxToRem(42)} paddingBottom={6} />
        <SkeletonText width={'100%'} rows={1} skeletonHeight={pxToRem(40)} />
      </Box>
    </>
  );
};

interface EventCardSkeletonGroupProps
  extends Readonly<{
    loading: boolean;
  }> {}

export const EventCardSkeletonGroup: React.FC<EventCardSkeletonGroupProps> = ({ loading }) => {
  return (
    <>
      <SkeletonGroup isReady={loading} width="100%" height="100%" placeholder={<EventCardSkeleton />} overrides={skeletonOverrides}>
        <EventCardSkeleton />
      </SkeletonGroup>
      <SkeletonGroup isReady={loading} width="100%" height="100%" placeholder={<EventCardSkeleton />} overrides={skeletonOverrides}>
        <EventCardSkeleton />
      </SkeletonGroup>
      <SkeletonGroup isReady={loading} width="100%" height="100%" placeholder={<EventCardSkeleton />} overrides={skeletonOverrides}>
        <EventCardSkeleton />
      </SkeletonGroup>
    </>
  );
};
