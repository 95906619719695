import React from 'react';
import { FacetClickSource, FilterFacetOption } from '../Filter.types';
import { CheckboxV2, Flex } from '@withjoy/joykit';

interface ListCheckboxRowProps extends FilterFacetOption {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const getCheckboxInputProps = (compositeCode: string, source: FacetClickSource) => ({
  ['data-item-id']: compositeCode,
  ['data-source']: source
});

export const ListCheckboxRow = (props: ListCheckboxRowProps) => {
  const { compositeCode, label, isActive, onChange, matchingItemCount } = props;
  return (
    <Flex height={8}>
      <CheckboxV2
        overrides={{
          Root: {
            props: {
              width: '100%'
            }
          },
          Label: { props: { typographyVariant: 'button1' } },
          Control: {
            props: {
              color: 'mono3',
              borderColor: 'transparent',
              backgroundColor: 'mono3',
              _groupActive: {
                color: 'black',
                backgroundColor: 'mono2',
                borderColor: 'transparent',
                _checked: {
                  color: 'black'
                }
              },
              _checked: {
                color: 'black',
                backgroundColor: 'mono3',
                borderColor: 'transparent'
              }
            }
          },
          Input: {
            props: getCheckboxInputProps(compositeCode, 'desktopDropdown')
          }
        }}
        isChecked={isActive}
        onChange={onChange}
      >
        {label} ({matchingItemCount})
      </CheckboxV2>
    </Flex>
  );
};
