import { getConfig } from '@config';
import { addError } from '@shared/utils/logger';
const { locationServiceApiKey, locationServiceUri } = getConfig();

export interface PlaceInfoBasic {
  addressComponents: Array<{
    languageCode: string;
    types: Array<string>;
    longText: string;
    shortText: string;
  }>;
  businessStatus: string;
  displayName: {
    text: string;
    languageCode: string;
  };
  error?: ErrorResult;
  formattedAddress: string;
  id: string;
  location: {
    latitude: number;
    longitude: number;
  };
  name: string;
  types: Array<string>;
}

export interface PlaceInfoAdvanced extends PlaceInfoBasic {
  id: string;
  internationalPhoneNumber: string;
  photos: Array<{
    height: number;
    width: number;
    htmlAttributions: Array<string>;
    name: string;
  }>;
  rating: number;
  userRatingCount: number;
  websiteUri: string;
}

export interface PlaceInfoPreferred extends PlaceInfoAdvanced {
  editorialSummary: {
    text: string;
    languageCode: string;
  };
}

type ErrorResult = {
  code: number;
  message: string;
  status: string;
};

/**
 * Fields to fetch from google places api
 * Grouped by cost per 1000 requests in increasing order respectively
 * @see https://developers.google.com/maps/documentation/places/web-service/usage-and-billing
 */
const fieldsBasic = ['id', 'name', 'displayName', 'formattedAddress', 'location', 'addressComponents', 'types', 'businessStatus'] as const;
const fieldsAdvanced = [...fieldsBasic, 'websiteUri', 'rating', 'internationalPhoneNumber', 'userRatingCount', 'photos'] as const;
const fieldsPreferred = [...fieldsAdvanced, 'editorialSummary'] as const;

/**
 *
 * @param placeId google place id
 * @param fields comma separated fields to fetch (fieldsBasic, fieldsAdvanced, fieldsPreferred)
 * @returns <TPlace> | null, null if place not found, otherwise PlaceInfoBasic, PlaceInfoAdvanced, PlaceInfoPreferred
 */
const getPlaceInfo = async <TPlace extends { error?: ErrorResult }>(placeId: string, fields: string): Promise<TPlace | null> => {
  const _getPlaceInfo = async (placeId: string, serviceUrl: string): Promise<TPlace | null> => {
    const response = await fetch(`${serviceUrl}/places/${placeId}?fields=${fields}`, {
      headers: {
        'x-goog-api-key': locationServiceApiKey
      }
    });
    if (!response.ok) {
      try {
        const error = await response.json();
        return error;
      } catch (error) {
        throw new Error(response.statusText);
      }
    }
    return response.json();
  };
  try {
    // calling location service first for cached placeId info
    const place = await _getPlaceInfo(placeId, locationServiceUri);
    if (!place) {
      return null;
    }
    if (place.error) {
      addError('Failed to fetch place info from location service', { placeId, fields });
      return null;
    }
    return place;
  } catch (error) {
    addError('Failed to fetch place info from location service', { placeId, fields });
    // falling back to google places api directly
    try {
      const place = await _getPlaceInfo(placeId, 'https://places.googleapis.com/v1');
      return place;
    } catch (error) {
      addError('Failed to fetch place info from googleapis', { placeId, fields });
      throw error;
    }
  }
};

/**
 * Get Place Info at $4 per 1000 requests (uncached rate)
 * @param placeId
 * @returns Promise<PlaceInfo> (Basic Place Information)
 */
export const getPlaceInfoBasic = async (placeId: string): Promise<PlaceInfoBasic | null> => {
  return getPlaceInfo<PlaceInfoBasic>(placeId, fieldsBasic.join(','));
};

/**
 * Get Advanced Place Info at $16 per 1000 requests (uncached rate)
 * @param placeId
 * @returns Promise<PlacedInfoAdvanced> (Advanced Place Information)
 */
export const getPlaceInfoAdvanced = async (placeId: string): Promise<PlaceInfoAdvanced | null> => {
  return getPlaceInfo<PlaceInfoAdvanced>(placeId, fieldsAdvanced.join(','));
};

/**
 * Get Preferred Place Info at $20 per 1000 requests (uncached rate)
 * @param placeId
 * @returns Promise<PlacedInfoAdvanced> (Preferred Place Information)
 */
export const getPlaceInfoPreferred = async (placeId: string): Promise<PlaceInfoPreferred | null> => {
  return getPlaceInfo<PlaceInfoPreferred>(placeId, fieldsPreferred.join(','));
};

/**
 * NOTES:
 *  we've made 3 different fetches for basic, advanced and preferred place info. The way google pricing works is that
 *  we are charged at the highest rate of the fields we fetch. So if we fetch advanced fields, we are charged at $16 per 1000 requests
 *  even if we don't use all the fields. So we've made 3 different fetches to optimize cost. We are caching these REST calls
 *  for location service in cloudfront, so by bundling the fields we can optimize cost even further.
 */
