import React, { Fragment, useCallback, useMemo } from 'react';
import { Link } from '@react-router';
import { addRendition } from '@shared/utils/photoRendition';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';
import {
  EventCardImage as StyledEventCardImage,
  StyledTitleText,
  StyledBodyText,
  EventCardBadge,
  AttendingBadge,
  AttendingIcon,
  NotAttendingIcon,
  AttendingText,
  AttendingBadgeContainer,
  StyledCard
} from './EventCard.styles';
import { Box, Flex, IconV2, TextV2 } from '@withjoy/joykit';
import { useDisclosure } from '@withjoy/joykit';
import { EventCardModel, cardLink } from '@apps/accountMe/routes/Me/Me.controller';
import { useAccountMeTelemetry } from '@apps/accountMe/AccountMe.telemetry';
import { isWithinInterval, addWeeks } from 'date-fns';
import { ReactComponent as CalendarIcon } from '@assets/icons/icon-calendar.svg';
import { ReactComponent as MapMarkerIcon } from '@assets/icons/map-marker.svg';
import { useSegmentGroupCallForEvent, useTranslation } from '@shared/core';
import { EventType, EventUserStatus, useRegistryPhotoByEventIdQuery } from '@graphql/generated';
import { ResponsiveMenu } from '@shared/components/ResponsiveMenu';
import { pxToRem } from '@withjoy/joykit/theme';
interface Props
  extends Readonly<{
    eventCard: EventCardModel;
    useEventLinkAsIs?: boolean;

    /**
     * With the new `/event` route, we can send users to any product area -- some of which may not be
     * handled in joy-web. This prop will allow us to perform a full page redirect.
     */
    performFullPageRedirect?: boolean;
  }> {}

// Only a temporary solution geared towards baby registry
// Will still need to address this ticket https://withjoy.atlassian.net/browse/REGACQ-2840
// Event Photo should be in respect to product verticals
const EventCardImage: React.FC<{ eventCard: EventCardModel }> = ({ eventCard }) => {
  const { postgresEventId, eventType, imageColor, imageUrl } = eventCard;

  const shouldUseRegistryPhoto = eventType === EventType.babyRegistry;

  const { data } = useRegistryPhotoByEventIdQuery({
    batchMode: 'fast',
    variables: {
      eventId: postgresEventId
    },
    skip: !shouldUseRegistryPhoto
  });

  const resolvedImageUrl = useMemo(() => {
    if (shouldUseRegistryPhoto) {
      const maybeRegistryPhotoUrl = data?.eventById?.pages[0]?.photo?.url;

      return maybeRegistryPhotoUrl || '';
    }

    return imageUrl;
  }, [data, imageUrl, shouldUseRegistryPhoto]);
  return <StyledEventCardImage style={{ backgroundColor: imageColor, backgroundImage: backgroundImageForUrl(addRendition({ url: resolvedImageUrl, renditionSize: 'medium' })) }} />;
};

export const EventCard: React.FC<Props> = ({ eventCard, useEventLinkAsIs, performFullPageRedirect }) => {
  const { name, dateString, datems, location, firebaseId, roles, eventLink, id, actions, eventType, role, attending: guestAttending } = eventCard;
  const { isOpen: isDotsOpen, onClose: onCloseDots, onOpen: onOpenDots, onToggle: onToggleDots } = useDisclosure();
  const { trackEventCardClicked } = useAccountMeTelemetry();
  const { t2 } = useTranslation('account');
  const { eventComingUp, eventAttending, eventNotAttending, event, manageEvent } = t2('eventsPage');
  const { executeSegmentGroupCallForEvent } = useSegmentGroupCallForEvent();

  const onCardClick = useCallback(() => {
    executeSegmentGroupCallForEvent(firebaseId, {
      postgresEventId: id,
      eventType: eventType
    });
    trackEventCardClicked();
  }, [executeSegmentGroupCallForEvent, firebaseId, eventType, id, trackEventCardClicked]);

  const isComingSoon = datems && isWithinInterval(datems, { start: Date.now(), end: addWeeks(Date.now(), 1) });
  const menuTitle = role?.status === EventUserStatus.guest ? event : manageEvent;
  const redirectPath = useEventLinkAsIs ? eventLink : cardLink(roles, eventLink);
  const rootLinkProps = performFullPageRedirect
    ? {
        as: 'a',
        href: redirectPath
      }
    : {
        as: Link,
        to: redirectPath
      };

  return (
    <StyledCard>
      {/* applying a ts-ignore because of the polymorphic 'as' being determined at runtime triggers a warning  */}
      {/* @ts-ignore */}
      <Box {...rootLinkProps} onClick={onCardClick} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box>
          <EventCardImage eventCard={eventCard} />
        </Box>
        <Box padding={6}>
          <Flex justifyContent="space-between" alignItems="center">
            <TextV2 textTransform="capitalize" typographyVariant="label6">
              {eventType.replace(/([A-Z])/g, ' $1').trim()}
            </TextV2>
            {isComingSoon && (
              <TextV2 textTransform="capitalize" typographyVariant="label6" backgroundColor="positive1" color="buttonProductiveHover" paddingX={3} paddingY={1} borderRadius={2}>
                {eventComingUp}
              </TextV2>
            )}
          </Flex>
          <StyledTitleText color="mono14" component="h2" marginBottom={3} typographyVariant={'hed2'}>
            {name}
          </StyledTitleText>
          {dateString ? (
            <StyledBodyText color="mono12" component="p" marginBottom={1} typographyVariant="body1" display="flex" minHeight={pxToRem(22)}>
              {dateString && <CalendarIcon height="16" width="16" />}
              {dateString}
            </StyledBodyText>
          ) : location ? (
            <StyledBodyText color="mono12" component="p" marginBottom={1} typographyVariant="body1" minHeight={pxToRem(22)}>
              {<MapMarkerIcon height="16" width="16" />}
              {location}
            </StyledBodyText>
          ) : (
            <Flex minHeight={pxToRem(22)} marginBottom={1} />
          )}
          <StyledBodyText color="mono12" component="p" marginBottom={6} typographyVariant="body1" minHeight={pxToRem(22)}>
            {dateString && location && <MapMarkerIcon height="16" width="16" />}
            {dateString ? location : ''}
          </StyledBodyText>
          <Flex marginTop="auto" justifyContent="space-between" alignItems="center">
            <Flex justifyContent="space-between" height={7} alignItems={'flex-start'}>
              <Flex columnGap={2} height={7}>
                <EventCardBadge status={role?.status || EventUserStatus.guest}>{role?.label}</EventCardBadge>
                {role?.status === EventUserStatus.guest && (
                  <AttendingBadge>
                    <Flex columnGap={2} justifyContent={'center'}>
                      {guestAttending ? (
                        <AttendingBadgeContainer>
                          <IconV2 as={Fragment}>
                            <AttendingIcon />
                          </IconV2>
                          <AttendingText> {eventAttending}</AttendingText>
                        </AttendingBadgeContainer>
                      ) : (
                        <AttendingBadgeContainer>
                          <IconV2 as={Fragment}>
                            <NotAttendingIcon />
                          </IconV2>
                          <AttendingText> {eventNotAttending} </AttendingText>
                        </AttendingBadgeContainer>
                      )}
                    </Flex>
                  </AttendingBadge>
                )}
              </Flex>
            </Flex>
            {!!actions.length && (
              <ResponsiveMenu
                isOpen={isDotsOpen}
                onOpen={onOpenDots}
                onClose={onCloseDots}
                onToggle={onToggleDots}
                actions={actions}
                title={menuTitle}
                lastOptionNeutralStyle={role?.status === EventUserStatus.admin}
              />
            )}
          </Flex>
        </Box>
      </Box>
    </StyledCard>
  );
};
