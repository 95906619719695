import { StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const eventWidgetPhotoStyles = (photoUrl?: string): StyleSystemProps => ({
  display: 'flex',
  height: pxToRem(71),
  width: pxToRem(71),
  borderRadius: pxToRem(71),
  backgroundColor: 'gray5',
  backgroundImage: photoUrl && `url('${photoUrl}')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
});

export const styles = {
  eventWidgetPhoto: eventWidgetPhotoStyles
};
