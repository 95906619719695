import { Flex, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { addRendition } from '@shared/utils/photoRendition';
import { TemplateProps } from '../templates.types';
import { StyledFrameBackground, DetailsContainer } from './Arch1.styles';
import { getThemeInfo } from '@apps/saveTheDate/SaveTheDate.utils';
import useAdjustableImage from '../../../../hooks/useAdjustableImage';

const assetFrameBackground = getThemeInfo('arch1')?.assetUrl || '';

export const Arch1: React.FC<TemplateProps> = ({
  imageUrl,
  inviteToFollow,
  formattedDate,
  formattedOwnerName,
  formattedFianceeName,
  formattedLocation,
  isFullNamesFormat,
  isAdjustingPosition,
  ...rest
}) => {
  const namesDivider = isFullNamesFormat ? (
    <>
      <br />
      {'&'}
      <br />
    </>
  ) : (
    ' & '
  );
  const { AdjustableImage } = useAdjustableImage({ imageUrl, isAdjustingPosition, ...rest });

  return (
    <>
      <AdjustableImage top={55} left={60} width={380} height={415} />
      <StyledFrameBackground isAdjustingPosition={!!isAdjustingPosition} backgroundImage={addRendition({ url: assetFrameBackground, renditionSize: 'medium' })}>
        <Flex height="200px" padding="30px" flexDirection="column" alignItems="center" justifyContent="center">
          <TextV2 color="black" textAlign="center" fontSize={16} fontFamily="Lora" fontWeight={500} lineHeight="20px" letterSpacing="0.2em" textTransform="uppercase">
            {formattedOwnerName}
            {formattedFianceeName ? namesDivider : null}
            {formattedFianceeName}
          </TextV2>
          {(formattedDate || formattedLocation || inviteToFollow) && (
            <DetailsContainer flexDirection="column" alignItems="center">
              {formattedDate ? (
                <TextV2 color="black" textAlign="center" fontSize={13} fontFamily="Lora" lineHeight="16px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedDate}
                </TextV2>
              ) : null}
              {formattedLocation ? (
                <TextV2 color="black" textAlign="center" fontSize={13} fontFamily="Lora" lineHeight="16px" letterSpacing="0.2em" textTransform="uppercase">
                  {formattedLocation}
                </TextV2>
              ) : null}
              {inviteToFollow ? (
                <TextV2 color="black" textAlign="center" fontSize={13} fontFamily="Lora" lineHeight="16px" letterSpacing="0.2em" textTransform="uppercase">
                  {inviteToFollow}
                </TextV2>
              ) : null}
            </DetailsContainer>
          )}
        </Flex>
      </StyledFrameBackground>
    </>
  );
};
