import {
  CustomizationFormat,
  CustomizeNowClickOverrideHandler,
  Format,
  MinimalRequiredTemplateDataForDraft,
  ValidDigitalDesignApiData
} from '../DesignsGallery/DesignsGallery.types';
import { useState } from 'react';
import { useCustomizeDigitalCard } from './useCustomizeDigitalCard';
import { useCustomizePaperCard } from './useCustomizePaperCard';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { ProductOffering } from '../ConciergeServiceSection/ConciergeServiceSection.types';
import { useCustomizePaperlustConcierge } from './useCustomizePaperlustConcierge';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { useCustomizePremiumDigitalCard } from './useCustomizePremiumDigitalCard';
import { useAuth } from '@shared/components/AuthProvider';

interface UseCustomizeClickHandlerArgs {
  currentCategory: StationeryTemplateCategoryEnum;
  eventId?: string;
  onCustomizeCardClickOverride?: CustomizeNowClickOverrideHandler;
  supportedFormats: Format;
}
/**
 * Intended to be revisited after the demo -- not a permanent solution.
 */
export const useCustomizeClickHandler = (args: UseCustomizeClickHandlerArgs) => {
  const { currentCategory, eventId, onCustomizeCardClickOverride, supportedFormats } = args;

  const [isCreatingDraftForDemo, setIsCreatingDraft] = useState(false);
  const { isLoggedIn, isCurrentUserSettled, currentUser } = useAuth();
  const isAuthenticated = isCurrentUserSettled && isLoggedIn;
  const userMustSignUp = !(isAuthenticated && currentUser.eventMemberships.length === 0);

  const { pdpCustomizeNowClicked } = useCardTelemetry();
  const { handleOnCustomizeDigitalClick } = useCustomizeDigitalCard({ stationeryTemplate: currentCategory, supportedFormats });
  const { handleOnCustomizeCardClick, loading } = useCustomizePaperCard({ eventId, supportedFormats });
  const { handleOnCustomizePremiumDigitalCardClick, loading: isCreatingDigitalDraft } = useCustomizePremiumDigitalCard({ eventId, supportedFormats });
  const { handleCustomizePaperlustConciergeClick } = useCustomizePaperlustConcierge();

  function onCustomizeClick(format: 'digital', template: ValidDigitalDesignApiData, supportedTemplateFormat: Format): void;
  function onCustomizeClick(format: 'paper', template: MinimalRequiredTemplateDataForDraft, supportedTemplateFormat: Format): void;
  function onCustomizeClick(format: 'premiumDigital', template: MinimalRequiredTemplateDataForDraft, supportedTemplateFormat: Format): void;
  function onCustomizeClick(format: 'concierge', template: ProductOffering): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCustomizeClick(format: CustomizationFormat | 'concierge', template: any, supportedTemplateFormat?: Format): void {
    if (onCustomizeCardClickOverride) {
      setIsCreatingDraft(true);
      if (format === 'concierge') {
        // Concierge modal will fire the required telemetry when the user clicks the button
        onCustomizeCardClickOverride({ format, template: { ...template, thumbnailUrl: template.imageUrl } }, () => {});
      } else {
        pdpCustomizeNowClicked({
          themeId: template.themeId,
          supportedFormats: supportedTemplateFormat!,
          format
        });
        onCustomizeCardClickOverride({ format, template: template }, () => {});
      }

      if (userMustSignUp) {
        setIsCreatingDraft(false);
      }
    } else {
      if (format === 'digital') {
        handleOnCustomizeDigitalClick(template.themeId);
      } else if (format === 'concierge') {
        handleCustomizePaperlustConciergeClick(template);
      } else if (format === 'premiumDigital') {
        handleOnCustomizePremiumDigitalCardClick(template as MinimalRequiredTemplateDataForDraft);
      } else {
        handleOnCustomizeCardClick(template as MinimalRequiredTemplateDataForDraft);
      }
    }
  }

  return {
    isCreatingDraft: loading || isCreatingDraftForDemo || isCreatingDigitalDraft,
    onCustomizeClick
  };
};
