import { styled, TextV2, ButtonV2, Box } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';
import { EventUserStatus } from '@graphql/generated';
import { CheckedGreenProductive } from '@assets/index';
import { ReactComponent as CloseButtonIcon } from '@assets/icons/circle-cancel-fill.svg';
import { ReactComponent as OpenNewWindowIcon } from '@assets/icons/open-in-new-window.svg';
import { css, pxToRem } from '@withjoy/joykit/theme';
import { Fragment } from 'react';

export const getColorByStatus = (status: EventUserStatus) => {
  switch (status) {
    case EventUserStatus.owner:
      return '#5F2698';
    case EventUserStatus.admin:
      return '#F9B686';
    case EventUserStatus.guest:
      return `#189289`;
    default:
      return '#189289';
  }
};

export const StyledCard = styled(Box)`
  border-radius: 0.75rem;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07), 0px 7px 27px 0px rgba(44, 41, 37, 0.06);
`;

export const EventCardImage = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center;
  padding-top: 67%;
  background-color: ${props => props.theme.colors.mono3};
  border-radius: ${props => props.theme.space[1]};
  border-radius: 0.75rem 0.75rem 0 0;
`;

export const StyledTitleText = styled(TextV2)`
  ${InterFonts.head}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledBodyText = styled(TextV2)`
  ${InterFonts.body}
  // prevent long strings from overflowing, spaces should trigger wraps
  word-break: break-word;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const EventCardBadge = styled(TextV2)<{ status: EventUserStatus }>`
  ${InterFonts.body}
  color: ${props => props.theme.colors.mono14};
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px ${props => getColorByStatus(props.status)};
  border-radius: 20px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: ${pxToRem(13)};
`;

export const StyledDotBotton = styled(ButtonV2)`
  width: 32px;
  color: ${props => props.theme.colors.black};
  display: inline-table;
  float: right;
  padding: 0px 0px 8px 0px;
  min-width: 32px;
  min-height: 0px;
  font-size: 22px;
  letter-spacing: -0.05rem;
  transform: rotate(90deg);
`;

export const AttendingBadge = styled(TextV2)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 4px;
  gap: 8px;
  font-size: ${pxToRem(13)};
  border-radius: 20px;
  ${props => css`
    background-color: ${props.theme.colors.gray2};
    color: ${props.theme.colors.black};
  `}
`;

export const AttendingBadgeContainer = styled(Fragment)``;

export const AttendingText = styled.span`
  font-size: ${pxToRem(13)};
`;
export const AttendingIcon = styled(CheckedGreenProductive)`
  width: 20px;
  height: 20px;
`;

export const NotAttendingIcon = styled(CloseButtonIcon)`
  width: 20px;
  height: 20px;
`;

export const OpenWindowIcon = styled(OpenNewWindowIcon)``;
