import React, { useCallback } from 'react';
import { SpacingStack, TextV2 } from '@withjoy/joykit';
import { FindCoupleLink } from '../CreateAccount.styles';
import { useTranslation } from '@shared/core';
import { useCreateWeddingTelemetry } from '@apps/createWedding/CreateWedding.telemetry';

export const FindCouple = () => {
  const { t } = useTranslation('createWedding');
  const translations = t('createAccount');

  const findACoupleUrl = `${window.location.origin}/find`;

  const telemetry = useCreateWeddingTelemetry();

  const handleFindCouple = useCallback(() => {
    telemetry.findACoupleClicked();
  }, [telemetry]);

  return (
    <SpacingStack>
      <TextV2 textAlign="center" typographyVariant="label3" fontWeight={500}>
        {translations.findCoupleText()}
        <FindCoupleLink typographyVariant="label3" textDecoration="none" paddingLeft={3} href={findACoupleUrl} onClick={handleFindCouple}>
          {translations.findCoupleLink()}
        </FindCoupleLink>
      </TextV2>
    </SpacingStack>
  );
};
