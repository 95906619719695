import { CatalogStorefront } from '@graphql/generated';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import React, { useMemo, useState } from 'react';
import { useRouteMatch } from '@react-router';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParamHelper } from '@shared/core/queryString';
import {
  AddFromFriendsRegistryBannerData,
  AddFromFriendsRegistryHowItWorksData,
  BrandMarqueeData,
  DepartmentNavSectionData,
  OverviewBillboardCurations,
  OverviewHighlights,
  ValuePropCardsData,
  ValuePropSectionLinks,
  ValuePropsTopBarData,
  ValuePropsTopBarOrder
} from './storefront.config.types';
import {
  ADD_FROM_FRIENDS_REGISTRY_BANNER,
  ADD_FROM_FRIENDS_REGISTRY_HOW_IT_WORJS,
  ADD_FROM_FRIENDS_REGISTRY_SCANNER_DIALOG,
  BRAND_MARQUEE_DATA_BY_STOREFRONT,
  DEPARTMENT_NAV_SECTION_DATA_BY_STOREFRONT,
  FALLBACK_HERO,
  OVERVIEW_BILLBOARD_CURATIONS,
  OVERVIEW_HIGHLIGHTS,
  STATIC_HERO_AT_START,
  VALUE_PROPS_SECTION_LINKS_BY_STOREFRONT,
  VALUE_PROPS_TOP_BAR_BY_STOREFRONT,
  VALUE_PROP_CARDS_BY_STOREFRONT
} from './storefront.config';
import { HeroDataProps } from '../../routes/CatalogProductsIndex/components/ShopHeroSlider/components/Hero';

type ShopStorefrontContext = {
  currentStorefront: CatalogStorefront;
  changeStorefront: (nextStorefront: CatalogStorefront) => void;
  isBabyStorefront: boolean;
  isWeddingStorefront: boolean;
  config: {
    homePageUrl: string;
    brandMarqueeData: BrandMarqueeData;
    createEventPathname: '/createbabyregistry' | '/createwedding';
    departmentNavSectionData: DepartmentNavSectionData;
    rootShopPathname: '/shop/baby' | '/shop';
    valuePropsTopBarOrder: ValuePropsTopBarOrder;
    valuePropsTopBarData: ValuePropsTopBarData;
    valuePropCardsData: ValuePropCardsData;
    valuePropSectionLinks: ValuePropSectionLinks;
    overviewBillboardCurations: OverviewBillboardCurations;
    overviewHighlights: OverviewHighlights;
    staticHeroAtStart: HeroDataProps;
    addFromFriendsRegistryBanner: AddFromFriendsRegistryBannerData;
    addFromFriendsRegistryScannerDialog: AddFromFriendsRegistryBannerData;
    addFromFriendsRegistryHowItWorks: AddFromFriendsRegistryHowItWorksData;
    fallbackHero: HeroDataProps;
  };
};

const [Provider, useShopStorefrontContext] = createContext<ShopStorefrontContext>({ name: 'ShopStorefrontProvider' });

type ShopStorefrontProviderProps = {
  initialStorefront?: CatalogStorefront;
  children: React.ReactNode;
};

const CONFIG_BY_STOREFRONT: Record<CatalogStorefront, ShopStorefrontContext['config']> = {
  baby: {
    homePageUrl: '/shop/baby',
    brandMarqueeData: BRAND_MARQUEE_DATA_BY_STOREFRONT.baby,
    createEventPathname: '/createbabyregistry',
    departmentNavSectionData: DEPARTMENT_NAV_SECTION_DATA_BY_STOREFRONT.baby,
    rootShopPathname: '/shop/baby',
    valuePropsTopBarData: VALUE_PROPS_TOP_BAR_BY_STOREFRONT.baby,
    valuePropsTopBarOrder: ['completionDiscount', 'shipping', 'easyReturns', 'priceMatchPromise'],
    valuePropCardsData: VALUE_PROP_CARDS_BY_STOREFRONT.baby,
    valuePropSectionLinks: VALUE_PROPS_SECTION_LINKS_BY_STOREFRONT.baby,
    overviewBillboardCurations: OVERVIEW_BILLBOARD_CURATIONS.baby,
    overviewHighlights: OVERVIEW_HIGHLIGHTS.baby,
    staticHeroAtStart: STATIC_HERO_AT_START.baby,
    addFromFriendsRegistryBanner: ADD_FROM_FRIENDS_REGISTRY_BANNER.baby,
    addFromFriendsRegistryScannerDialog: ADD_FROM_FRIENDS_REGISTRY_SCANNER_DIALOG.baby,
    addFromFriendsRegistryHowItWorks: ADD_FROM_FRIENDS_REGISTRY_HOW_IT_WORJS.baby,
    fallbackHero: FALLBACK_HERO.baby
  },
  wedding: {
    homePageUrl: '/shop',
    brandMarqueeData: BRAND_MARQUEE_DATA_BY_STOREFRONT.wedding,
    createEventPathname: '/createwedding',
    departmentNavSectionData: DEPARTMENT_NAV_SECTION_DATA_BY_STOREFRONT.wedding,
    rootShopPathname: '/shop',
    valuePropsTopBarData: VALUE_PROPS_TOP_BAR_BY_STOREFRONT.wedding,
    valuePropsTopBarOrder: ['completionDiscount', 'shipping', 'easyReturns', 'priceMatchPromise'],
    valuePropCardsData: VALUE_PROP_CARDS_BY_STOREFRONT.wedding,
    valuePropSectionLinks: VALUE_PROPS_SECTION_LINKS_BY_STOREFRONT.wedding,
    overviewBillboardCurations: OVERVIEW_BILLBOARD_CURATIONS.wedding,
    overviewHighlights: OVERVIEW_HIGHLIGHTS.wedding,
    staticHeroAtStart: STATIC_HERO_AT_START.wedding,
    addFromFriendsRegistryBanner: ADD_FROM_FRIENDS_REGISTRY_BANNER.wedding,
    addFromFriendsRegistryScannerDialog: ADD_FROM_FRIENDS_REGISTRY_SCANNER_DIALOG.wedding,
    addFromFriendsRegistryHowItWorks: ADD_FROM_FRIENDS_REGISTRY_HOW_IT_WORJS.wedding,
    fallbackHero: FALLBACK_HERO.wedding
  }
};

export const ShopStorefrontProvider = (props: ShopStorefrontProviderProps) => {
  const isBabyRegistryEnabled = useFeatureValue('enableBabyRegistrySoftLaunchFeatures');
  const isBabyShop = !!useRouteMatch({ path: ['/shop/baby', '/:eventHandle/edit/registry/shop/baby'] });
  const isPdpPage = !!useRouteMatch({ path: ['/shop/item/:catalogItemId', '/:eventHandle/edit/registry/shop/item/:catalogItemId'] });

  const { getValueString } = useQueryParamHelper();
  const [storefront, setStorefront] = useState<CatalogStorefront>(() => {
    if (isPdpPage) {
      // All PDPs are found at `/shop/item/:catalogItemId` regardless of storefront,
      // so we want to try to derive storefront from the query params
      const maybeStorefrontFromQueryParams = getValueString('shopStorefront');
      if (maybeStorefrontFromQueryParams && maybeStorefrontFromQueryParams in CatalogStorefront) {
        return maybeStorefrontFromQueryParams as CatalogStorefront;
      }
    }
    return isBabyShop ? CatalogStorefront.baby : props.initialStorefront ?? CatalogStorefront.wedding;
  });

  const changeStorefront = useEventCallback((nextStorefront: CatalogStorefront) => {
    setStorefront(nextStorefront);
  });

  const currentStorefront = isBabyRegistryEnabled ? storefront : CatalogStorefront.wedding;

  const context = useMemo<ShopStorefrontContext>(() => {
    return {
      currentStorefront,
      changeStorefront,
      config: CONFIG_BY_STOREFRONT[currentStorefront],
      isBabyStorefront: currentStorefront === CatalogStorefront.baby,
      isWeddingStorefront: currentStorefront === CatalogStorefront.wedding
    };
  }, [currentStorefront, changeStorefront]);
  return <Provider value={context}>{props.children}</Provider>;
};

export { useShopStorefrontContext };
