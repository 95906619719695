import React, { useCallback } from 'react';
import { BaseComponentProps } from '../../../../common/props';
import { generateComponentDisplayName } from '../../../../utils';
import { StyledNavItem } from './StyledNavItem';
import { callAllHandlers } from '@shared/utils/functions';
import { isKeyboardConfirmTrigger } from '../../../../common';

export interface NavItemProps extends BaseComponentProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  /**
   * React element used for NavItem.
   *
   * When passing a different element, be sure to provide
   * the appropriate prop. IE `ReactRouterLink` uses the `to` prop.
   */
  component?: React.ComponentType<any>;
  disabled?: boolean;
  enableClickWhenActive?: boolean;

  /**
   * When providing another different element - IE `ReactRouterLink` - the NavItem
   * should accept all props.
   */
  [x: string]: any;
}

/**
 * Use `<NavItem />` within a `<Nav />` to define the navigation options. To be contained within a `<Nav />`.
 *
 * This is intended to wrap a component that renders an `<a />` element - such as a React Router Link.
 *
 * Any other properties supplied will be spread to the root element.
 *
 * @example
 *
 * ```
 * <Nav.Item component={NavLink} to="/joykit">
 *    Joykit
 * </Nav.Item>
 * ```
 *
 */
export const NavItem: React.FC<NavItemProps> = props => {
  const { disabled, className = '', onKeyDown, ...restProps } = props;

  const handleOnKeydown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    if (isKeyboardConfirmTrigger(e.which)) {
      e.preventDefault();
      e.currentTarget.click();
    }
    // keyCode === 36
    if (e.key === 'Home' && e.currentTarget?.parentElement?.hasChildNodes) {
      e.preventDefault();
      (e.currentTarget.parentElement.firstChild as HTMLElement).focus();
    }
    // keyCode === 35
    if (e.key === 'End' && e.currentTarget?.parentElement?.hasChildNodes) {
      e.preventDefault();
      (e.currentTarget.parentElement.lastElementChild as HTMLElement).focus();
    }

    // keyCode = 39
    if (e.key === 'ArrowRight' && e.currentTarget?.parentElement?.hasChildNodes) {
      (e.currentTarget.nextElementSibling as HTMLElement).focus();
    }

    // keyCode = 37
    if (e.key === 'ArrowLeft' && e.currentTarget?.parentElement?.hasChildNodes) {
      (e.currentTarget.previousSibling as HTMLElement).focus();
    }
  }, []);

  const linkProps = {
    'aria-disabled': disabled,
    'aria-current': className.includes('active') ? 'page' : undefined,
    // onClick: disabled ? undefined : props.onClick,
    onKeyDown: disabled ? undefined : callAllHandlers(onKeyDown, handleOnKeydown),
    focusable: !disabled,
    tabIndex: disabled ? -1 : 0
  };
  return (
    <StyledNavItem {...restProps} className={className} {...linkProps} paddingX={[0, null, 6]}>
      <span>{props.children}</span>
    </StyledNavItem>
  );
};

NavItem.displayName = generateComponentDisplayName('NavItem');
