import { Box, TextV2, JoyKitThemeProvider } from '@withjoy/joykit';
import React from 'react';
import {
  GenericEcardContainer,
  GenericEcardContent,
  GenericEcardOverlay,
  GenericEcardEditButton,
  genericEcardEditButtonOverrides,
  styles,
  GenericEcardLink,
  GenericEcardMessageContainer
} from './GenericEcard.styles';
import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { EcardEditorThemeType, EcardEventDesign } from '@apps/ecard/Ecard.types';
import { useEcardDesign } from '@apps/ecard/theming/ecardDesign';
import { getFormattedDate } from '@apps/ecard/Ecard.utils';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { useHistory } from '@react-router';
import { useTranslation } from '@shared/core';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { Linkify } from '@shared/components/Linkify';
import { ecardConfig } from '@apps/ecard/theming/ecardThemeConfig';
import { withWindow } from '@shared/utils/withWindow';
import { isCustomECardType } from '@apps/ecard/Ecard.utils';

const { simple } = ecardConfig.applicatorHtmlProps;
interface Props
  extends Readonly<{
    ecardData: EcardDesignInputFields;
    eventDesign?: EcardEventDesign | null;
    isEcardPreview?: boolean;
  }> {}

export const GenericEcard = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { ecardData, eventDesign, isEcardPreview = false } = props;
  const { title, subtitle, message, date, dateFormat, location, selectedThemeType, yourUrl, displayEventLink, useMyColor, guidId, eventId, ecardDraftType } = ecardData;
  useEcardDesign(eventDesign, ecardData.joyTheme?.themeId, useMyColor);
  const history = useHistory();
  const { t2 } = useTranslation('emailsAndEcards');
  const tGenericEcard = t2('genericEcard');
  const telemetry = useEcardsTelemetry();
  const isCustomECard = isCustomECardType(ecardDraftType);
  const displayDate = (isCustomECard ? date : getFormattedDate(date, dateFormat)).toUpperCase();

  const onEditCard = () => {
    if (ecardDraftType) {
      telemetry.editEcardClick(ecardDraftType);
      history.push(`${ecardDraftType}/design`);
    }
  };

  const handleWebsiteLinkClick = () => {
    telemetry.eCardLinkClick('wedding website', eventId);
    withWindow(global => global.open(`${yourUrl}${guidId ? `?guidId=${guidId}` : ''}`, '_blank'));
  };

  return (
    <GenericEcardContainer ref={ref} {...simple.prop}>
      {selectedThemeType !== EcardEditorThemeType.CUSTOM && (
        <GenericEcardContent {...styles.genericEcardContent}>
          <Box>
            <Box padding={0} textAlign="center">
              <TextV2 typographyVariant={'display7'}>{title}</TextV2>
            </Box>
            {subtitle && (
              <Box textAlign="center" padding={0} marginTop={6}>
                <TextV2 typographyVariant={'display2'}>{subtitle}</TextV2>
              </Box>
            )}
          </Box>
          {date && (
            <TextV2 lineHeight="22px" marginTop={9} typographyVariant={'label5'} fontWeight={700} fontFamily="Inter UI">
              {displayDate}
            </TextV2>
          )}
          <TextV2 lineHeight="22px" marginTop={6} typographyVariant={'label5'} fontWeight={700} fontFamily="Inter UI" textAlign="center">
            {location}
          </TextV2>
          {message ? (
            <GenericEcardMessageContainer {...styles.genericEcardMessageContainer}>
              <TextV2 as="div" typographyVariant={'body4'}>
                <Linkify>{message}</Linkify>
              </TextV2>
            </GenericEcardMessageContainer>
          ) : null}
          {displayEventLink && (
            <GenericEcardLink {...styles.inhreitedColorLink} typographyVariant={'button3'} onClick={handleWebsiteLinkClick}>
              {prettyUrl(yourUrl)}
            </GenericEcardLink>
          )}
        </GenericEcardContent>
      )}
      {isEcardPreview && (
        <JoyKitThemeProvider>
          <GenericEcardOverlay __css={styles.genericEcardOverlay}>
            <GenericEcardEditButton intent="primary" shape="rounded" variant="outline" startIcon={<EditIcon />} onClick={onEditCard} overrides={genericEcardEditButtonOverrides}>
              {tGenericEcard.editEcard}
            </GenericEcardEditButton>
          </GenericEcardOverlay>
        </JoyKitThemeProvider>
      )}
    </GenericEcardContainer>
  );
});
