import { createTheme } from '@withjoy/joykit/theme';
import { createTypography, defaultFontTokens } from '@shared/joykit/packages/core/common/themes/shared/typography';

export const JoyKitV2AdminTheme = createTheme({
  overrides: {
    ...createTypography({
      ...defaultFontTokens,
      primaryFontFamily: `'Inter UI'`
    })
  }
});
