import { LinkV2, SpacingStack, styled } from '@withjoy/joykit';

export const SignInWrapperStack = styled(SpacingStack)`
  max-width: 360px;
`;

export const CreateAccountLink = styled(LinkV2)`
  ${props => props.theme.mediaQueries.down({ viewport: 'xs' })} {
    display: block;
  }
  ${props => props.theme.mediaQueries.down({ viewport: 'sm' })} {
    display: inline-block;
  }
`;
