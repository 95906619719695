import { Box } from '@withjoy/joykit';
import React from 'react';

interface StickyCtaBarProps {
  zIndex?: number;
}

/**
 * TODO:
 *  - maybe connect this to context?
 */
export const StickyCtaBar: React.FC<StickyCtaBarProps> = ({ children, zIndex = 10 }) => {
  return (
    <Box
      backgroundColor="white"
      position={'fixed'}
      borderTop="1px solid"
      borderTopColor="mono3"
      borderLeft={{ md: '1px solid' }}
      borderLeftColor={{ md: 'mono3' }}
      bottom={0}
      left={{ _: 0, md: 'unset' }}
      rowGap={5}
      paddingX={7}
      paddingY={5}
      right={0}
      width={{ _: '100%', md: '375px' }}
      zIndex={zIndex}
      pointerEvents="auto"
    >
      {children}
    </Box>
  );
};
