import { Box, BoxProps, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import React from 'react';

const Container: React.FC<BoxProps> = ({ children, ...restProps }) => {
  return (
    <Box width="100%" {...restProps}>
      {children}
    </Box>
  );
};

const Header: React.FC<BoxProps> = props => {
  return <Box width={1280} maxWidth="100%" {...props} />;
};

const Body: React.FC = ({ children }) => {
  return (
    <Box
      __css={{
        gridArea: 'body',
        gridTemplateColumns: ['1fr', null, '1fr max-content'],
        gridColumnGap: [null, null, '24px'],
        flexDirection: ['column', null, 'row'],
        display: 'grid',
        rowGap: 9
      }}
      width={1280}
      maxWidth="100%"
    >
      {children}
    </Box>
  );
};

const STICKY_COLUMN_RULES: StyleSystemProps = {
  position: [null, null, 'sticky'],
  top: [null, null, 98],
  alignSelf: 'flex-start'
};

const rightColumnStyles: StyleSystemProps = {
  marginTop: [9, 'initial'],
  paddingX: [null, null, 6],
  // width: ['100%', null, pxToRem(400), pxToRem(512)],
  width: {
    _: '100%',
    sm2: pxToRem(360),
    sm3: pxToRem(400),
    md: pxToRem(464)
  },
  maxWidth: ['100%', null],
  minHeight: [null, null, 'calc(100vh - 116px)'],
  position: [null, null, 'sticky'],
  bottom: [null, null, '1rem'],
  alignSelf: 'flex-end'
};

const leftColumnStyles: StyleSystemProps = {
  ...STICKY_COLUMN_RULES
};

const LeftPanel: React.FC = ({ children }) => {
  return <Box __css={leftColumnStyles}>{children}</Box>;
};

const RightPanel: React.FC = ({ children }) => {
  return <Box __css={rightColumnStyles}>{children}</Box>;
};

export const Layout = {
  Container,
  Header,
  Body,
  LeftPanel,
  RightPanel
};
