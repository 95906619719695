import { EmailsAndEcardsTranslations } from '@locales/generated';

export const emailTypeType = ['generic', 'ecard', 'thankyou'] as const;
export type EmailType = typeof emailTypeType[number];

export interface BaseEmailInputFields
  extends Readonly<{
    eventId: string;
    subject: string;
    replyToEmail: string;
    headline: string;
    salutation: Maybe<string>;
    includeGuestNames: boolean;
    message: Maybe<string>;
    testName?: Maybe<string>;
    testEmail?: Maybe<string>;
    imageUrl?: Maybe<string>;
    assetId?: Maybe<string>;
    containerId?: Maybe<string>;
  }> {}

export interface EcardEmailInputFields
  extends BaseEmailInputFields,
    Readonly<{
      subheadline: Maybe<string>;
      includeEventDate: boolean;
      includeAddToCalendarLink: boolean;
      buttonText: Maybe<string>;
    }> {}

export interface GenricEmailInputFields
  extends BaseEmailInputFields,
    Readonly<{
      // selectedLink: GeneralMessageLinkType; //ready for the post office emails v2
      includeWebsiteDetails: boolean;
      includeAppInstructions: boolean;
      includeEventPassword: boolean;
      draftId: Maybe<string>;
    }> {}

type UnionKeys<T> = T extends T ? keyof T : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StrictUnionHelper<T, TAll> = T extends any ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>> : never;
type StrictUnion<T> = StrictUnionHelper<T, T>;

export type EmailInputFields = StrictUnion<EcardEmailInputFields | GenricEmailInputFields>;

export type EmailEditorTranslations = EmailsAndEcardsTranslations<string>['emailEditor'];

export type EmailEditPanelOption = {
  id: string;
  name: string;
  hed: string;
  dek: string;
  isOn: boolean;
  formikName: string;
  isDisabled?: boolean;
  onCange: () => void;
  isHidden?: boolean;
};

export enum GeneralMessageLinkType {
  calendar = 'Add to Calendar',
  rsvp = 'RSVP',
  registry = 'Browse Registry',
  schedule = 'View Schedule',
  travel = 'View Travel Details',
  website = 'View Website',
  mailingAddress = 'Enter Mailing Address',
  none = 'None'
}

export const GeneralMessageLinkTypeLabels: Record<GeneralMessageLinkType, string> = {
  [GeneralMessageLinkType.calendar]: 'Add to Calendar',
  [GeneralMessageLinkType.rsvp]: 'RSVP',
  [GeneralMessageLinkType.registry]: 'Browse Registry',
  [GeneralMessageLinkType.schedule]: 'View Schedule',
  [GeneralMessageLinkType.travel]: 'View Travel Details',
  [GeneralMessageLinkType.website]: 'View Website',
  [GeneralMessageLinkType.mailingAddress]: 'Enter Mailing Address',
  [GeneralMessageLinkType.none]: 'None'
};
