import React, { useCallback, useMemo, useRef, useState } from 'react';
import { BoxProps } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { withWindow } from '@shared/utils/withWindow';
import { pxToRem } from '@withjoy/joykit/theme';
import type { HeaderLinksType } from '../../AppNavBar.types';

const CLOSE_MENU_TIMEOUT = 300;
const OPEN_MENU_TIMEOUT = 650;
const OPEN_MENU_TRANSITION_TIMEOUT = 540;

const LINK_PADDING_CONFIG: Record<HeaderLinksType, string> = {
  planInvite: pxToRem(154),
  registry: pxToRem(306),
  expertAdvice: pxToRem(154)
};

export const useDesktopView = () => {
  const [{ headerLinksType, isOpen }, setState] = useState<{ headerLinksType: HeaderLinksType | null; isOpen: boolean }>({
    headerLinksType: null,
    isOpen: false
  });
  const linkGroupHeadersRef = useRef<HTMLDivElement>(null);
  const linksDropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuOpenTimeoutRef = useRef<number | undefined>();
  const menuCloseTimeoutRef = useRef<number | undefined>();

  const { t2 } = useTranslation('marcom');

  const getTranslations = useMemo(() => {
    return () => {
      const topLevelLinks = t2('topLevelLinks');
      const linkGroupHeaders = t2('linkGroupHeaders');
      return {
        topLevelLinks,
        linkGroupHeaders
      };
    };
  }, [t2]);

  const clearMenuCloseTimeout = useEventCallback(() => {
    withWindow(global => {
      global.clearTimeout(menuCloseTimeoutRef.current);
    });
  });
  const clearMenuOpenTimeout = useEventCallback(() => {
    withWindow(global => {
      global.clearTimeout(menuOpenTimeoutRef.current);
    });
  });

  const prepareToCloseMenu = useEventCallback((clearCurrentTimeout?: boolean) => {
    clearMenuOpenTimeout();
    withWindow(global => {
      menuCloseTimeoutRef.current = global.setTimeout(() => {
        setState(prev => ({ ...prev, isOpen: false }));
      }, CLOSE_MENU_TIMEOUT);
    });
  });
  const prepareToOpenMenu = useEventCallback((type: HeaderLinksType) => {
    clearMenuCloseTimeout();
    clearMenuOpenTimeout();
    withWindow(global => {
      menuOpenTimeoutRef.current = global.setTimeout(
        () => {
          setState({ isOpen: true, headerLinksType: type });
        },
        isOpen ? OPEN_MENU_TRANSITION_TIMEOUT : OPEN_MENU_TIMEOUT
      );
    });
  });

  const getRootProps = useCallback(() => {
    return {
      onMouseLeave: () => {
        clearMenuOpenTimeout();
        setState(prev => ({ ...prev, isOpen: false }));
      }
    };
  }, [clearMenuOpenTimeout]);

  const headerLinksKeyMapping = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    e => {
      e.preventDefault();
      const [planningGroupButton, resourceGroupButton] = Array.from(linkGroupHeadersRef.current?.children || []) as [planning: HTMLElement | null, resources: HTMLElement | null];

      if (e.key === 'ArrowUp') {
        const previousLink = e.currentTarget.parentElement?.previousElementSibling?.firstElementChild as Maybe<HTMLAnchorElement>;
        previousLink?.focus();
      }

      if (e.key === 'ArrowDown') {
        const nextLink = e.currentTarget.parentElement?.nextElementSibling?.firstElementChild as Maybe<HTMLAnchorElement>;
        nextLink?.focus();
      }

      if (e.key === 'ArrowRight') {
        setState(prev => ({ ...prev, isOpen: true, headerLinksType: 'expertAdvice' }));
        resourceGroupButton?.focus();
      }

      if (e.key === 'ArrowLeft') {
        setState(prev => ({ ...prev, isOpen: true, headerLinksType: 'planInvite' }));
        planningGroupButton?.focus();
      }

      if (e.key === 'Escape') {
        setState(prev => ({ ...prev, isOpen: false }));
        if (headerLinksType === 'planInvite') {
          planningGroupButton?.focus();
        } else {
          resourceGroupButton?.focus();
        }
      }
    },
    [headerLinksType]
  );

  const getHeaderLinkProps = useCallback(() => {
    const linkProps: BoxProps = {
      paddingLeft: headerLinksType ? LINK_PADDING_CONFIG[headerLinksType] : null
    };

    linkProps.onKeyDown = headerLinksKeyMapping;
    return linkProps;
  }, [headerLinksType, headerLinksKeyMapping]);

  const getHeaderLinkGroupButtonProps = useCallback(
    (type: HeaderLinksType) => {
      const isActive: boolean = isOpen && headerLinksType === type;
      return {
        isActive,
        onMouseEnter: () => {
          prepareToOpenMenu(type);
        },
        onMouseLeave: () => {
          prepareToCloseMenu();
        },
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === 'Escape') {
            setState(prev => ({ ...prev, isOpen: false, headerLinksType: null }));
          }
          if (e.key === 'Tab' && isOpen) {
            setState({ isOpen: false, headerLinksType: type });
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            if (isOpen) {
              const firstLink = linksDropdownRef.current?.getElementsByTagName('li')[0]?.firstChild as Maybe<HTMLAnchorElement>;
              firstLink?.focus();
            }
            setState({ isOpen: true, headerLinksType: type });
          }
        }
      };
    },
    [isOpen, prepareToCloseMenu, prepareToOpenMenu, headerLinksType]
  );

  const getLinksContainerProps = useCallback(() => {
    const propsValue: BoxProps = {
      onMouseEnter: () => {
        clearMenuCloseTimeout();
      },
      onMouseLeave: () => {
        prepareToCloseMenu();
      }
    };
    const linksDropdownEl = linksDropdownRef.current;

    if (linksDropdownEl) {
      const elHeight = linksDropdownEl.offsetHeight;
      propsValue.top = isOpen ? pxToRem(62) : `${-elHeight}px`;
    } else {
      // @ts-ignore
      propsValue.visibility = 'hidden';
    }

    return propsValue;
  }, [prepareToCloseMenu, clearMenuCloseTimeout, isOpen]);

  return {
    getLinksContainerProps,
    getHeaderLinkProps,
    getHeaderLinkGroupButtonProps,
    getRootProps,
    getTranslations,

    linkGroupHeadersRef,
    linksDropdownRef,
    containerRef,
    headerLinksType,
    isOpen
  };
};
