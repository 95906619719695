/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chevron Back',
  definitions: {
    '16': {
      viewBox: '0 0 9 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.0731 0.447608C8.32491 0.6426 8.35043 0.981328 8.13009 1.20418L1.41089 7.99994L8.13009 14.7957C8.35043 15.0186 8.32491 15.3573 8.0731 15.5523C7.82128 15.7473 7.43852 15.7247 7.21819 15.5018L0.149902 8.35301C-0.0499674 8.15086 -0.0499674 7.84902 0.149902 7.64688L7.21819 0.498046C7.43852 0.275197 7.82128 0.252615 8.0731 0.447608Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
