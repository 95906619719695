import { EcardDesignInputFields } from '@shared/components/EmailsAndEcards/components/Design/Design.controller';
import { EcardEditorType } from '@apps/ecard/Ecard.types';
import { useTranslation } from '@shared/core';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { EcardType } from '@graphql/generated';
import { useToast } from '@withjoy/joykit';

interface UseEcardEditorControllerArgs
  extends Readonly<{
    formik: FormikProps<EcardDesignInputFields>;
    toggleEcardEditor: () => void;
  }> {}

export const useEcardEditorController = (args: UseEcardEditorControllerArgs) => {
  const { toast } = useToast();
  const { t2 } = useTranslation('emailsAndEcards');
  const tEcardEditPanel = t2('ecardEditor', 'ecardEditPanel');
  const { formik, toggleEcardEditor } = args;
  const [selectedEditorType, setSelectedEditorType] = useState<EcardEditorType>(EcardEditorType.DESIGN);
  const telemetry = useEcardsTelemetry();

  const onSaveClick = () => {
    if (formik.dirty || !formik.values.id) {
      formik.submitForm();
    }
    toast(tEcardEditPanel.doneTooltipText, {
      icon: '✅'
    });
    toggleEcardEditor();
    telemetry.editECardDoneClick(formik.values.ecardDraftType as EcardType, selectedEditorType);
  };

  return {
    tEcardEditPanel,
    onSaveClick,
    selectedEditorType,
    setSelectedEditorType
  };
};
