import { animationTransition } from '@shared/joykit/packages/core/utils';
import { styled, Menu } from '@withjoy/joykit';

export const WrapperMenu = styled(Menu)`
  width: 256px;
  padding: ${props => props.theme.space[6]} ${props => props.theme.space[5]};
  gap: ${props => props.theme.space[5]};
  background-color: white;
  position: absolute;
  border-radius: 12px;
  margin-top: ${props => props.theme.space[3]};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07), 0px 7px 27px 0px rgba(44, 41, 37, 0.06);
  z-index: ${props => props.theme.zIndices.popover};
`;

export const StyledItemWrapper = styled(Menu.Item)`
  border-radius: 4px;
  padding: 0px;
  position: relative;
  left: -8px;
  padding-left: 8px;
  &:hover {
    background: ${props => props.theme.colors.mono2};
  }

  &:active {
    background: ${props => props.theme.colors.fillLightHover};
    & > svg {
      fill: ${props => props.theme.colors.linkActive};
    }
  }

  &:focus {
    border: 2px solid ${props => props.theme.colors.stroke};
  }
`;

export const BurgerIconWrapper = styled.button<{ isActive: boolean }>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.mono3 : theme.colors.transparent)};
  transition: ${animationTransition('background-color')};
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.mono2};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.mono3};
  }
`;

export const MenuDivider = styled.div`
  margin: 9px ${props => props.theme.space[4]};
  height: 1px;
  background-color: ${props => props.theme.colors.mono3};
`;
