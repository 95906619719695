import { styled, Box, Flex } from '@withjoy/joykit';
export const ECARD_THEME_THUMBNAIL_WIDTH = 180;
export const ECARD_THEME_THUMBNAIL_HEIGHT = 292;

export const StyledThemeTitleContainer = styled(Flex)`
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

export const StyledThemeTitle = styled.span`
  font-family: 'Inter UI';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18.2px;
  letter-spacing: -0.02em;
  text-align: center;
`;

export const StyledThemeWrapper = styled(Box)<{ selected: boolean }>`
  background: ${props => props.theme.colors.white};
  border: ${props => `2px solid ${props.selected ? props.theme.colors.mono14 : props.theme.colors.mono3}`};
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: border 0.4s;
  &:hover {
    border: ${props => `2px solid ${props.selected ? props.theme.colors.mono14 : props.theme.colors.linkHover}`};

    ${StyledThemeTitle} {
      color: ${props => (props.selected ? props.theme.colors.mono14 : props.theme.colors.linkHover)};
    }
  }
  &:active {
    border: ${props => `2px solid ${props.theme.colors.linkActive}`};

    ${StyledThemeTitle} {
      color: ${props => props.theme.colors.linkActive};
    }
  }
`;

export const StyledThemeContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  aspect-ratio: ${ECARD_THEME_THUMBNAIL_WIDTH / ECARD_THEME_THUMBNAIL_HEIGHT};
  border-radius: 2px;
`;

export const StyledThemeImage = styled.img`
  width: 100%;
  flex: 1;
  object-fit: cover;
`;

export const StyledThemeSelected = styled(Flex)`
  position: absolute;
  top: 12px;
  left: 16px;
  right: 16px;
  padding: 8px 6px;
  background: ${props => props.theme.colors.mono1};
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 32px;
  justify-content: center;
  align-items: center;
`;

export const StyledUseMyColor = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 63px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.mono13};
`;

export const StyledUseMyColorText = styled(Box)`
  color: ${props => props.theme.colors.mono6};
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 258px;
  background: ${props => props.theme.colors.mono6};
  justify-content: center;
  align-items: center;
`;
