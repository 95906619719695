/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Save The Date',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.84123 12.147C9.70556 11.2826 11.0359 11.1684 12.0234 11.8043C13.011 11.1684 14.3413 11.2826 15.2057 12.147C16.2016 13.1429 16.2016 14.7577 15.2057 15.7536L12.4477 18.5115C12.2134 18.7458 11.8335 18.7458 11.5992 18.5115L8.84123 15.7536C7.84529 14.7577 7.84529 13.1429 8.84123 12.147ZM9.68976 12.9955C10.217 12.4682 11.0719 12.4682 11.5992 12.9954C11.8335 13.2296 12.2133 13.2296 12.4476 12.9954C12.975 12.4682 13.8299 12.4682 14.3571 12.9955C14.8845 13.5228 14.8845 14.3778 14.3571 14.9051L12.0235 17.2387L9.68976 14.9051C9.16245 14.3778 9.16245 13.5228 9.68976 12.9955Z"
            fill="currentColor"
          />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.15001 1.87595C7.48138 1.87595 7.75001 2.14458 7.75001 2.47595V3.6117H16.2642V2.47595C16.2642 2.14458 16.5329 1.87595 16.8642 1.87595C17.1956 1.87595 17.4642 2.14458 17.4642 2.47595V3.6117H20C21.436 3.6117 22.6 4.77576 22.6 6.2117V19.5241C22.6 20.96 21.436 22.1241 20 22.1241H4.00002C2.56408 22.1241 1.40002 20.96 1.40002 19.5241V6.2117C1.40002 4.77576 2.56408 3.6117 4.00002 3.6117H6.55001V2.47595C6.55001 2.14458 6.81864 1.87595 7.15001 1.87595ZM6.55001 5.56166V4.8117H4.00002C3.22683 4.8117 2.60002 5.4385 2.60002 6.2117V7.98042H21.4L21.4 6.2117C21.4 5.4385 20.7732 4.8117 20 4.8117H17.4642V5.56166C17.4642 5.89303 17.1956 6.16166 16.8642 6.16166C16.5329 6.16166 16.2642 5.89303 16.2642 5.56166V4.8117H7.75001V5.56166C7.75001 5.89303 7.48138 6.16166 7.15001 6.16166C6.81864 6.16166 6.55001 5.89303 6.55001 5.56166ZM2.60002 19.5241V9.18042H21.4L21.4 19.5241C21.4 20.2973 20.7732 20.9241 20 20.9241H4.00002C3.22683 20.9241 2.60002 20.2973 2.60002 19.5241Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path d="M16.334 10.429a.6.6 0 1 0 0 1.2h1.329l-1.857 3.449a.6.6 0 1 0 1.057.569l2.333-4.333a.6.6 0 0 0-.528-.885h-2.333zm-1.717.071a.6.6 0 0 1 .317.529v4.333a.6.6 0 0 1-1.2 0V12.15l-.067.045a.6.6 0 1 1-.666-.998l1-.667a.6.6 0 0 1 .616-.03z" />{' '}
          <path
            d="M18.134 7.696h-4.8v-.067a.6.6 0 1 0-1.2 0v.067h-.8c-.736 0-1.333.597-1.333 1.333v8c0 .736.597 1.333 1.333 1.333h9.333c.736 0 1.333-.597 1.333-1.333v-8c0-.736-.597-1.333-1.333-1.333h-1.333v-.067a.6.6 0 0 0-1.2 0v.067zm.015 1.2h-4.83a.6.6 0 0 1-1.17 0h-.815c-.074 0-.133.06-.133.133v8c0 .074.06.133.133.133h9.333c.074 0 .133-.06.133-.133v-8c0-.074-.06-.133-.133-.133h-1.348a.6.6 0 0 1-1.17 0zM6.067 16.52V3.668c0-.515.418-.933.933-.933h18c.515 0 .933.418.933.933V16.52l2.428 1.867a.6.6 0 0 1 .234.472c.003.024.005.049.005.075v9.067c0 .7-.567 1.267-1.267 1.267H4.667c-.7 0-1.267-.567-1.267-1.267v-9.067a.6.6 0 0 1 .005-.074.6.6 0 0 1 .234-.472l2.428-1.867zm1.2 4.479V3.934h17.467v17.064l-3.684 3.249-4.394-2.652c-.403-.243-.907-.243-1.309 0l-4.395 2.652-.516-.455-3.169-2.793zm20.099 7.06l-5.239-3.162 5.274-4.651v7.755c0 .025-.014.047-.035.058zm-11.331-5.437l9.022 5.445H6.944l9.022-5.445c.021-.013.048-.013.069 0zm-6.161 2.275L4.635 28.06c-.021-.011-.035-.033-.035-.058V20.25l5.042 4.444.232.205zm-3.807-6.863l-1.156.889 1.156 1.018v-1.908zm21.021.888l-1.154-.888v1.906l1.154-1.018z"
            fillRule="evenodd"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            d="M24.5 15.9176C24.1548 15.9176 23.875 16.1974 23.875 16.5426C23.875 16.8878 24.1548 17.1676 24.5 17.1676H26.9536L23.9497 22.7463C23.7861 23.0502 23.8998 23.4293 24.2037 23.5929C24.5076 23.7566 24.8866 23.6428 25.0503 23.3389L28.5503 16.8389C28.6546 16.6452 28.6494 16.411 28.5366 16.2221C28.4238 16.0333 28.22 15.9176 28 15.9176H24.5Z"
            fill="currentColor"
          />
          <path
            d="M21.7949 15.9916C21.9981 16.1003 22.125 16.3121 22.125 16.5426V23.0426C22.125 23.3878 21.8452 23.6676 21.5 23.6676C21.1548 23.6676 20.875 23.3878 20.875 23.0426V17.7104L20.3467 18.0626C20.0595 18.2541 19.6714 18.1765 19.48 17.8893C19.2885 17.6021 19.3661 17.214 19.6533 17.0226L21.1533 16.0226C21.3451 15.8947 21.5917 15.8828 21.7949 15.9916Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.75 11.5426H20V11.1676C20 10.8224 19.7202 10.5426 19.375 10.5426C19.0298 10.5426 18.75 10.8224 18.75 11.1676V11.5426H17C15.8954 11.5426 15 12.438 15 13.5426V25.5426C15 26.6472 15.8954 27.5426 17 27.5426H31C32.1046 27.5426 33 26.6472 33 25.5426V13.5426C33 12.438 32.1046 11.5426 31 11.5426H29V11.1676C29 10.8224 28.7202 10.5426 28.375 10.5426C28.0298 10.5426 27.75 10.8224 27.75 11.1676V11.5426ZM27.75 12.7926H20V13.4176C20 13.7628 19.7202 14.0426 19.375 14.0426C19.0298 14.0426 18.75 13.7628 18.75 13.4176V12.7926H17C16.5858 12.7926 16.25 13.1284 16.25 13.5426V25.5426C16.25 25.9568 16.5858 26.2926 17 26.2926H31C31.4142 26.2926 31.75 25.9568 31.75 25.5426V13.5426C31.75 13.1284 31.4142 12.7926 31 12.7926H29V13.4176C29 13.7628 28.7202 14.0426 28.375 14.0426C28.0298 14.0426 27.75 13.7628 27.75 13.4176V12.7926Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.375 24.9144V5.5C9.375 4.87868 9.87868 4.375 10.5 4.375H37.5C38.1213 4.375 38.625 4.87868 38.625 5.5V24.9145L42.3741 27.7974C42.5651 27.9443 42.625 28.169 42.625 28.3992L42.625 42C42.625 42.8975 41.8975 43.625 41 43.625L7 43.625C6.10254 43.625 5.375 42.8975 5.375 42L5.375 28.3992C5.375 28.1689 5.43474 27.9443 5.62585 27.7974L9.375 24.9144ZM10.625 31.6214V5.625H37.375V31.6197L31.6042 36.709L24.8397 32.6263C24.3233 32.3147 23.6767 32.3147 23.1603 32.6263L16.3959 36.709L15.4711 35.8935C13.9761 34.5753 12.2883 33.0872 10.625 31.6214ZM40.9922 42.375L32.7262 37.3862L41.375 29.7587L41.375 42C41.375 42.2105 41.1994 42.375 40.9922 42.375ZM24.1938 33.6965L38.5731 42.375L9.42708 42.375L23.8062 33.6965C23.9254 33.6246 24.0746 33.6246 24.1938 33.6965ZM15.2739 37.3862L7 42.375C6.7929 42.375 6.625 42.2071 6.625 42L6.625 29.7633C9.50896 32.3031 12.3916 34.8445 15.2739 37.3862ZM9.375 26.4912L6.93308 28.369C7.70593 29.0495 8.52988 29.7753 9.375 30.5199V26.4912ZM41.0637 28.3666L38.625 26.4913V30.5173L41.0637 28.3666Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
