import React from 'react';
import { ButtonV2, Flex, TextV2, IconV2 } from '@withjoy/joykit';
import { DesignEmail } from '@withjoy/joykit/icons';
import { outlinedDarkButtonOverrides } from '../Shared.styles';
import { useDesignECardViewController } from './DesignECardView.controller';
import useBottomSheetDescriptionOffset from '@apps/postOffice/hooks/useBottomSheetDescriptionOffset';

interface DesignECardViewProps {
  onVisible?: () => void;
}

const DesignECardView: React.FC<DesignECardViewProps> = props => {
  const { tDesignECard, handleButtonClick, disableEditEmail } = useDesignECardViewController(props);
  const { transform } = useBottomSheetDescriptionOffset();

  return (
    <Flex flexDirection="column" padding={6} paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center">
        <IconV2 size="md" marginRight="12px">
          <DesignEmail />
        </IconV2>{' '}
        {tDesignECard.title()}
      </TextV2>
      <TextV2 marginBottom={6} paddingX={4} textAlign="center" typographyVariant="body1" color="mono12" flex="1" style={{ transform }}>
        {tDesignECard.description()}
      </TextV2>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={handleButtonClick} disabled={disableEditEmail}>
        {tDesignECard.editEmail()}
      </ButtonV2>
    </Flex>
  );
};

export default DesignECardView;
