import { Box, Flex, TextV2, LinkV2, styled } from '@withjoy/joykit';
import { DrawerV1 } from '@withjoy/joykit';

import { InterFonts } from '@shared/utils/fonts/interFonts';

import { StyledTextArea } from '@shared/components/EmailsAndEcards/components/shared';
export { StyledTextArea };

export const EMAIL_EDITOR_DRAWER_WIDTH = 650;

const NAV_HEIGHT = 152;

export const StyledEmailEditPanelDrawer = styled(DrawerV1)`
  height: 100%;
  width: ${EMAIL_EDITOR_DRAWER_WIDTH}px;
  max-width: 100%;
  box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.2);
  background: ${props => props.theme.colors.white};
  overflow: hidden;
  border-radius: 0;

  @media screen and (max-width: 767px) {
    input,
    textarea {
      font-size: 16px;
    }
    textarea {
      :hover,
      :focus {
        border-color: ${props => props.theme.colors.mono5};
      }
    }
  }
`;

export const ErrorFiller = styled(Box)<{ isRendered?: boolean }>`
  height: 20px;
  display: ${({ isRendered }) => (isRendered ? 'inline-block' : 'none')};
`;

export const StyledOptionsWrapper = styled(Box)`
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 4px;
`;

export const StyledOptionContainer = styled(Flex)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.colors.mono3};
  }
`;

export const FormWrapper = styled(Box)`
  height: calc(100% - ${NAV_HEIGHT}px);
  overflow-y: scroll;
`;

export const DrawerButtonsWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: ${NAV_HEIGHT}px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px -14px 30px -18px rgba(0, 0, 0, 0.08);
`;

export const StyledLabel = styled.span`
  ${InterFonts.subhead}
`;

export const StyledBodyTitle = styled(TextV2)`
  ${InterFonts.bodyTitle}
`;

export const StyledBody = styled(TextV2)`
  ${InterFonts.caption}
  margin-top: ${props => props.theme.space[1]};
`;

export const StyledCheckboxLabel = styled.span`
  ${InterFonts.body}
  margin-left: ${props => props.theme.space[2]};
`;

export const AddPhotosLink = styled(LinkV2)`
  text-decoration: none;
  font-size: 13px;
  color: ${props => props.theme.colors.primary8};

  &:hover {
    color: ${props => props.theme.colors.primary6};
  }
`;
