import React from 'react';
import { generateComponentDisplayName } from '../../../../utils';
import { OverlayProps } from '../../../Overlay/Overlay';
import { DialogHeaderContainer, DialogHeaderCloseIcon } from './styles';
import { TextV1 } from '../../../TextV1';
import { DIALOG_HEADER_CLASSNAME } from '../../constants';

export interface DialogHeaderProps {
  children?: never;
  isCloseButtonShown?: boolean;
  title: string | React.ReactNode;
  onClose?: OverlayProps['onClose'];
  transparent?: boolean;
}

const DEFAULT_PROPS: Required<Pick<DialogHeaderProps, 'isCloseButtonShown' | 'transparent'>> = {
  isCloseButtonShown: true,
  transparent: false
};

export const DialogHeader: React.FC<DialogHeaderProps> = React.memo(props => {
  const { isCloseButtonShown = DEFAULT_PROPS.isCloseButtonShown, title, onClose, transparent = DEFAULT_PROPS.transparent } = props;
  return (
    <DialogHeaderContainer className={DIALOG_HEADER_CLASSNAME} isCloseButtonShown={isCloseButtonShown} paddingY={6} paddingX={[6, null, 8]} transparent={transparent}>
      <TextV1 component="span" variant="hed2">
        {title}
      </TextV1>
      {isCloseButtonShown && <DialogHeaderCloseIcon className="dv1-close-button" source={'close'} role="button" a11yLabel="Close Dialog" onClick={onClose} />}
    </DialogHeaderContainer>
  );
});

DialogHeader.displayName = generateComponentDisplayName(`Dialog.Header`);
