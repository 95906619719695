import ImagineYourCardRoot from '@apps/imagineYourCard/Components/ImagineYourCardRoot/ImagineYourCardRoot';
import { AICardRouter } from '@apps/imagineYourCard/router';
import React from 'react';
import { CardCategoryProvider } from '../CardCategoryProvider';
import { PrimaryNav } from '../PrimaryNav';
import { Layout } from '@apps/card/components/Layout';
import { UniversalBurgerMenu } from '@apps/admin/components/UniversalBurgerMenu/UniversalBurgerMenu';
import { Hamburger } from '@withjoy/joykit/icons';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Flex } from '@withjoy/joykit';
import { useHandleAdminOnCustomizeNowClick } from './hooks/useHandleAdminOnCustomizeNowClick';

export const ImagineYourCard = () => {
  const { getShopPath } = useCardsRouterContext();
  const isMobileOrTablet = useIsMobileOrTablet();

  const { handleAdminOnCustomizeNowClick } = useHandleAdminOnCustomizeNowClick();

  return (
    <CardCategoryProvider>
      <AICardRouter>
        <Layout.Grid>
          <Layout.Main>
            <Layout.Header
              startIcon={() => (
                <UniversalBurgerMenu>
                  <Hamburger size="lg" />
                </UniversalBurgerMenu>
              )}
              alignment="flex-start"
              title="Cards"
              titleRedirectPath={getShopPath('wedding', StationeryTemplateCategoryEnum.saveTheDate)}
            >
              {!isMobileOrTablet && <PrimaryNav />}
            </Layout.Header>
            <Flex flexDirection="column">
              {isMobileOrTablet && <PrimaryNav />}
              <ImagineYourCardRoot handleCustomizeNowClickOverride={handleAdminOnCustomizeNowClick} />
            </Flex>
          </Layout.Main>
        </Layout.Grid>
      </AICardRouter>
    </CardCategoryProvider>
  );
};
