import { Box, BoxProps } from '@withjoy/joykit';
import React, { ReactNode, useEffect, useState } from 'react';

interface AspectRatioImagePreview extends BoxProps {
  src: string;
  children?: ReactNode;
}

// Goals:
// - Provide an aspect-ratio-aware frame for the child badge posiition
// - Display the image src (which also defines the aspect-ratio)
export const AspectRatioImage = (props: AspectRatioImagePreview) => {
  const { src, children, ...restProps } = props;

  const [aspectRatioProps, setAspectRatioProps] = useState<BoxProps>({});
  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setAspectRatioProps(
        image.width > image.height
          ? {
              aspectRatio: `${image.width} / ${image.height}`,
              width: '100%'
            }
          : {
              aspectRatio: `${image.width} / ${image.height}`,
              height: '100%'
            }
      );
    };
    image.src = src;
    return () => {
      image.onload = null;
    };
  }, [src]);

  return (
    <Box
      {...restProps}
      {...aspectRatioProps}
      position="relative" // for children like the premium badge
      style={{ backgroundImage: `url('${src}')` }}
      backgroundSize="cover" // prefer "contain", but safari leaves a sliver at the bottom
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
    >
      {children}
    </Box>
  );
};
