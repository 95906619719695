import { useAuth } from '@shared/components/AuthProvider';
import { stashCardTemplate } from '../../../../imagineYourCard/Components/ImagineYourCardRoot/ImageStorageManager';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useAIRouterContext } from '../../../../imagineYourCard/router';
import { CustomizeNowClickOverrideHandler } from '@apps/card/routes/Dashboard/components/DesignsGallery/DesignsGallery.types';
import { useCreateDraftAndRedirectToEditor } from '../../hooks/useCreateDraftAndRedirectToEditor';

export const usePublicShopCustomizeNowClickHandler = () => {
  const { goToLogin } = useAIRouterContext();

  const { isLoggedIn, isCurrentUserSettled, currentUser } = useAuth();
  const isAuthenticated = isCurrentUserSettled && isLoggedIn;
  const { createDraftAndRedirectToEditor } = useCreateDraftAndRedirectToEditor('pdpModal');

  const goToCustomizeDraftOrLogin = useEventCallback<CustomizeNowClickOverrideHandler>((data, onCloseCardPdpModal) => {
    if (isAuthenticated && currentUser.eventMemberships.length > 0) {
      createDraftAndRedirectToEditor(data);
    } else {
      // 1. Is not authenticated
      // 2. Is authenticated but has no events
      onCloseCardPdpModal?.();
      stashCardTemplate(data);
      goToLogin();
    }
  });

  return { goToCustomizeDraftOrLogin };
};
