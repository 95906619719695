import { animationTransition } from '@shared/utils/animationTransition';
import { CheckboxV2Props } from '@withjoy/joykit';

export const CHECKBOX_OVERRIDES: CheckboxV2Props['overrides'] = {
  Root: {
    props: {
      borderRadius: '21px',
      display: 'flex',
      width: '100%',
      paddingY: 4,
      paddingRight: 6,
      paddingLeft: 6,
      transition: animationTransition('background-color'),

      _hover: {
        backgroundColor: 'mono2'
      },
      _active: {
        backgroundColor: 'mono3'
      }
    }
  },
  Icon: {
    props: {
      color: 'black'
    }
  },
  Control: {
    props: {
      color: 'mono3',
      borderColor: 'transparent',
      backgroundColor: 'mono3',
      _groupActive: {
        color: 'black',
        backgroundColor: 'mono2',
        borderColor: 'transparent',
        _checked: {
          color: 'black'
        }
      },
      _checked: {
        color: '#333',
        backgroundColor: 'mono3',
        borderColor: 'transparent'
      }
    }
  }
};
