import React from 'react';
import { Box, Flex } from '@withjoy/joykit';
import { EmailPreviewContainer, EmailPreviewWrapper } from './EmailWrapper.styles';
import { EmailFontFamilies } from '@shared/utils/fonts/emailFonts';
import { useTranslation } from '@shared/core';
import RecipientsBlock from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailPreview/components/EmailWrapper/RecipientsBlock';

interface Props
  extends Readonly<{
    subject: string;
    isEmailEditPanelShown: boolean;
  }> {}

export const EmailWrapper: React.FC<Props> = ({ subject, isEmailEditPanelShown, children }) => {
  const { t } = useTranslation('emailsAndEcards');
  const translations = t('emailEditorPhotoSaveTheDate');

  return (
    <EmailPreviewWrapper $isEmailEditPanelShown={isEmailEditPanelShown}>
      <EmailPreviewContainer marginTop={['77px', '77px', '96px']}>
        <Flex flexDirection="column" fontFamily={EmailFontFamilies.ibmPlexSans} fontSize="15px" lineHeight="19px" paddingX="16px" paddingY={6}>
          <RecipientsBlock activeStepIndex={2} />
          <Box display="flex" paddingX="16px">
            <Box color="mono10" marginRight={5}>{`${translations.subjectLabel()}: `}</Box>
            <Box color="mono13">{subject}</Box>
          </Box>
        </Flex>
        {children}
      </EmailPreviewContainer>
    </EmailPreviewWrapper>
  );
};
