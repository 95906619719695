import React, { useRef, useState } from 'react';
import { FadeChar, PromptHintText } from './HintText.styles';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Flex } from '@withjoy/joykit';
import { useInterval } from './UseInterval';
import { msBeforeGeneration, msBetweenCharacters, msBetweenPrompts } from './FirstTimeExperienceConfig';

export const HintText = ({
  demoPrompts,
  onClick,
  onComplete,
  onDemoEnd
}: {
  demoPrompts: string[];
  onClick: () => void;
  onComplete?: (prompt: string, index: number) => void;
  onDemoEnd: () => void;
}) => {
  const mobile = useIsMobileOrTablet();
  const [showIndex, setShowIndex] = useState(0);
  const [display, setDisplay] = useState('');
  const init = useRef(false);

  const promptIndex = useRef(0);
  const reverse = useRef(false);

  const nextPromptInterval = useRef<ReturnType<typeof setInterval>>();
  const characterInterval = useRef<ReturnType<typeof setInterval>>();

  const ShowPrompt = (index: number) => {
    const prompt = demoPrompts[index];
    promptIndex.current = index;
    setDisplay(prompt);

    setTimeout(() => {
      if (onComplete) {
        onComplete(prompt, index);
      }
    }, msBeforeGeneration);

    setTimeout(() => {
      reverse.current = true;
    }, msBetweenPrompts - msBetweenCharacters * prompt.length * 2);
  };

  // Reveals each character
  useInterval(
    () => {
      const prompt = demoPrompts[promptIndex.current];
      const promptLength = prompt.length;
      if (showIndex < promptLength && !reverse.current) {
        setShowIndex(current => current + 1);
      } else if (showIndex >= 0 && reverse.current) {
        setShowIndex(current => current - 1);
      }
    },
    msBetweenCharacters,
    id => {
      characterInterval.current = id;
    }
  );

  // Cycles the prompts
  useInterval(
    () => {
      if (promptIndex.current < demoPrompts.length - 1) {
        setShowIndex(0);
        reverse.current = false;
        ShowPrompt(promptIndex.current + 1);
      } else {
        if (nextPromptInterval.current) {
          clearInterval(nextPromptInterval.current);
        }

        if (characterInterval.current) {
          clearInterval(characterInterval.current);
        }
        onDemoEnd();
      }
    },
    msBetweenPrompts,
    id => {
      nextPromptInterval.current = id;
    }
  );

  if (!init.current) {
    init.current = true;
    ShowPrompt(0);
  }

  return (
    <PromptHintText mobile={mobile} onClick={onClick}>
      <Flex>
        {display.split('').map((char, index) => {
          return (
            <FadeChar show={showIndex >= index} fadeOut={showIndex <= index && reverse.current} key={index} isSpace={char === ' '}>
              {char}
            </FadeChar>
          );
        })}
      </Flex>
    </PromptHintText>
  );
};
