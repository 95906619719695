import React from 'react';
import useFavoriting from '../../../DesignsGallery/useFavoriting';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { FavoriteButton } from '@apps/card/components/FavoriteButton/FavoriteButton';

interface CardPreviewFavoriteButtonProps {
  eventId: string;
  templateId: string;
  isFavorite: boolean;
  themeId: string;
  templateCategory: StationeryTemplateCategoryEnum;
}

export const CardPreviewFavoriteButton = ({ eventId, templateId, isFavorite: initialIsFavorite, themeId, templateCategory }: CardPreviewFavoriteButtonProps) => {
  const isFavoritesRouteEnabled = useFeatureValue('printFavoritesRouteEnabled').value === 'on';
  const { markTemplateAsFavorite } = useCardTelemetry();
  const { isFavorite, toggleFavorite, isLoading } = useFavoriting({
    isFavorite: initialIsFavorite,
    templateId,
    eventId
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    markTemplateAsFavorite({ templateCategory, themeId, isFavorite: !isFavorite, source: 'pdp' });
    toggleFavorite();
  };

  if (!eventId || !isFavoritesRouteEnabled) {
    return null;
  }

  return <FavoriteButton isFavorite={isFavorite} isLoading={isLoading} onClick={handleClick} />;
};
