import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { ApolloError } from '@apollo/client';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';

const category = 'account.me';

type PagesForTelemetry = 'events' | 'personalInfo' | 'eventRoute';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  events: (eventCount: number) => ({
    category,
    pagePrefix: 'account',
    page: 'Events',
    extraPageInfo: {
      eventCount
    }
  }),
  personalInfo: (provider: string) => ({
    category,
    pagePrefix: 'account',
    page: 'PersonalInfo',
    extraPageInfo: {
      provider
    }
  }),
  eventRoute: (eventCount: number) => ({
    category,
    pagePrefix: 'account',
    page: 'EventRedirectRoute',
    extraPageInfo: {
      eventCount
    }
  })
};

export const vendorTelemetry = createTelemetryObject({
  actions: {
    trackError: (mutation: string, error: ApolloError | Error, extra = {}) => ({
      category,
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),
    trackGeneralError: (description: string, error?: Error) => ({
      category,
      action: 'General',
      actionType: 'error',
      extraInfo: {
        description,
        error
      }
    }),
    trackEventCardClicked: () => ({
      category: 'account.events',
      action: 'OpenPageClicked',
      extraInfo: {
        label: 'Event',
        section: 'Event'
      }
    }),
    trackEventCardMenuClicked: () => ({
      category,
      action: 'EventCardMenu',
      actionType: 'click',
      extraInfo: {}
    }),
    trackEventCardMenuItemClicked: (item: string) => ({
      category,
      action: 'EventCardMenuItem',
      actionType: 'click',
      extraInfo: {
        item
      }
    }),
    trackMePageClick: (label: string, section: string) => ({
      category: 'account.events',
      action: 'OpenPageClicked',
      extraInfo: {
        label,
        section
      }
    }),
    trackEventCardMenuItemClickedV2: (label: string) => ({
      category: 'account.events',
      action: 'OpenPageClicked',
      extraInfo: {
        label,
        section: 'Event'
      }
    }),
    // the below are purposely seperate in case we change the action associated with each
    trackAccountMeNavItemClicked: (item: string) => ({
      category,
      action: 'MePageNav',
      actionType: 'click',
      extraInfo: {
        item
      }
    }),
    trackMePageButtonClick: (item: string) => ({
      category,
      action: 'MePageNav',
      actionType: 'click',
      extraInfo: {
        item
      }
    }),
    trackPersonInfoSaveClicked: () => ({
      category,
      action: 'PersonInfoSave',
      actionType: 'click'
    }),
    trackDeleteAccountClicked: () => ({
      category,
      action: 'DeleteAccount',
      actionType: 'click'
    }),
    trackChangePasswordClicked: () => ({
      category,
      action: 'ChangePassword',
      actionType: 'click'
    }),
    trackVerifyYourEmailClicked: () => ({
      category,
      action: 'VerifyYourEmail',
      actionType: 'click'
    }),
    trackLeaveEventConfirmClick: (categoryName?: string) => ({
      category: categoryName || category,
      action: 'LeaveEvent',
      actionType: 'click'
    }),
    trackMFASetUpButtonClick: () => ({
      category,
      action: 'SetUpMFA',
      actionType: 'click'
    }),
    trackMFARemoveButtonClick: () => ({
      category,
      action: 'RemoveMFA',
      actionType: 'click'
    }),
    trackDeleteEventFeedbackClick: (feedback: string, eventId?: string) => ({
      category,
      action: 'DeleteWeddingFeedback',
      label: feedback,
      extraInfo: {
        eventId: eventId
      }
    }),
    trackEventRedirectRoute: (label: string, destination: string) => ({
      category,
      action: 'EventRedirect',
      label,
      extraInfo: {
        destination
      }
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry } = createTelemetry(vendorTelemetry, {});
export { TelemetryProvider, useTelemetry as useAccountMeTelemetry };
