import { useEffect } from 'react';
import { useHistory } from '@react-router';

// Keep track of the last location key to compare with the current location key,
// so we can stop scroll to top if someone navigates forward and restore last scroll position
// if someone navigates back
let lastLocationPathname: string | undefined = undefined;

export const useScrollToTopAfterNavigation = () => {
  const history = useHistory();
  useEffect(
    () =>
      history.listen((location, action) => {
        if (action === 'PUSH' && location.pathname !== lastLocationPathname) {
          lastLocationPathname = location.pathname;
          window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
      }),
    [history]
  );
};
